import axios from 'axios';
import BigNumber from 'bignumber.js';
import { API_URL, axiosAuth } from './axiosInstance';

export const authUser = async (token, userId) => {
  const response = await axiosAuth.get(`/api/v1/users`, {
    headers: { userid: userId, authorization: token }
  });
  return response;
};

export const getTop10Owners = async product_id => {
  const response = await axiosAuth.get('/api/v1/apex/get-top-owners', {
    params: { product_id }
  });

  return response.data;
};

export const createPaymentIntent = async (amount, currency, remindCard) => {
  const response = await axiosAuth
    .post(`/api/v1/payment-orders/stripe/create-payment-intent`, {
      amount,
      currency,
      remindCard
    })
    .catch(err => err);

  return response.data;
};

export const getPaymentMethods = async () => {
  const response = await axiosAuth
    .get(`/api/v1/payment-orders/stripe/user-payment-methods`, {})
    .catch(err => err);

  return response.data;
};

export const payWithStoredCard = async (amount, paymentId) => {
  const response = await axiosAuth
    .post(`/api/v1/payment-orders/stripe/stored-card`, {
      amount,
      paymentId
    })
    .catch(err => err);

  return response.data;
};

export const getExchangeRate = async () => {
  const response = await axiosAuth
    .get(`/api/v1/payment-orders/premierpay/exchange-rate`)
    .catch(err => err);

  return response.data;
};

export const getPremierPayETransfer = async params => {
  const { amount } = params;
  const response = await axiosAuth
    .post(`/api/v1/payment-orders/premierpay/etransfer`, {
      amount
    })
    .catch(err => err);

  return response.data;
};

export const getAvailableTokens = async orderIds => {
  const response = await axiosAuth.get(`/api/v1/apex/get-order-quantity`, {
    params: { orderId: orderIds }
  });
  return response.data;
};

export const getPublicProfile = async (
  accountId,
  offset,
  limit,
  sortBy,
  direction
) => {
  // need use simple axios request, because this public api
  const response = await axios.get(
    `${API_URL}/api/v1/public/get-user-profile-info`,
    { params: { accountId, offset, limit, sortBy, direction } }
  );
  return response.data;
};

export const getPublicAssetAttributes = async productId => {
  const response = await axios.get(
    `${API_URL}/api/v1/public/get-product-attributes`,
    { params: { productId } }
  );
  return response.data;
};

export const sendBuyOutEmail = async (
  userName,
  userEmail,
  itemName,
  price,
  comment
) => {
  const response = await axiosAuth.post(
    `${API_URL}/api/v1/mail/buy-out-offer`,
    { userName, userEmail, itemName, price, comment }
  );
  return response.data;
};

export const fetchLinkToken = async () => {
  const response = await axiosAuth
    .post(`/api/v1/payment-orders/plaid/create-link-token`)
    .catch(err => err);

  return response.data;
};

export const plaidMakePayment = async (
  paymentAmount,
  publicToken,
  accountId
) => {
  const response = await axiosAuth
    .post(`/api/v1/payment-orders/plaid/payment`, {
      amount: paymentAmount,
      accountId,
      publicToken
    })
    .catch(err => err);

  return response.data;
};

export const getAutoConvertDeposit = async ticketIds => {
  const response = await axiosAuth
    .post('/api/v1/apex/get-auto-convert-deposit', { ticketIds })
    .catch(err => err);

  return response.data;
};

// [orderIds] - optional param
// [productIds or instrumentIds] - required param
export const getInstrumentsTokens = async ({
  orderIds,
  productIds,
  instrumentIds
}) => {
  const response = await axios
    .get(`${API_URL}/api/v1/apex/get-instruments-tokens`, {
      params: { orderIds, productIds, instrumentIds }
    })
    .catch(err => err);

  return response.data;
};

export const fetchWyreWidgetUrl = async amount => {
  const response = await axiosAuth
    .post('/api/v1/payment-orders/wyre/create-wallet-order', { amount })
    .catch(err => err);

  return response.data;
};

export const initiateStripeDepositTicket = async clientSecret => {
  const response = await axiosAuth
    .post('/api/v1/payment-orders/stripe/initiate-deposit-ticket', {
      clientSecret
    })
    .catch(err => err);

  return response.data;
};

export const getTopXPUsers = async () => {
  const response = await axiosAuth.get(
    `${API_URL}/api/v1/users/get-exp-top-owners`
  );
  return response.data;
};

export const getVotingStatuses = async () => {
  const response = await axiosAuth.get(
    `${API_URL}/api/v1/voting/voting-status`
  );

  return response.data;
};

export const voteForCard = async (productId, voteValue, productVotingId) => {
  const response = await axiosAuth
    .post(`${API_URL}/api/v1/voting/vote`, {
      productId,
      voteValue,
      productVotingId
    })
    .catch(err => err);

  return response.data;
};

export const getInstrumentsDCA = async instrumentIds => {
  const response = await axiosAuth
    .get('/api/v1/users/dca', {
      params: { instrumentId: instrumentIds }
    })
    .catch(err => err);

  return response.data;
};

export const subscribeForNews = async email => {
  const response = await axios
    .post(`${API_URL}/api/v1/public/subscribe`, {
      email
    })
    .catch(err => err);

  return response.data;
};

export const claimReward = async () => {
  // const response = await axios
  // // TODO(Jun 27, 2022): replace with a real endpoint when it's ready
  //   .post('https://api.liquidmarketplace.org/api/v1/public/claimReward', {
  //     email,
  //     tag
  //   })
  //   .catch(err => err);

  // return response.data;
  return Promise.resolve();
};

export const deleteStoredCard = async paymentId => {
  const response = await axiosAuth
    .delete(`${API_URL}/api/v1/payment-orders/stripe/stored-card`, {
      data: { paymentId }
    })
    .catch(err => err);

  return response.data;
};

export const getUserNotifications = async () => {
  const response = await axiosAuth
    .get('/api/v1/users/notifications')
    .catch(err => err);

  return response.data;
};

export const getUserGlobalNotification = async () => {
  const response = await axiosAuth
    .get('/api/v1/users/notifications/banner')
    .catch(err => err);
  return response.data;
};

export const viewNotification = async notificationsIds => {
  const response = await axiosAuth
    .patch(`${API_URL}/api/v1/users/notifications`, {
      notificationsIds
    })
    .catch(err => err);

  return response.data;
};

export const deleteNotification = async (
  notificationId,
  forceDelete = false
) => {
  const response = await axiosAuth
    .delete(`${API_URL}/api/v1/users/notifications`, {
      data: {
        notificationsIds: [notificationId],
        forceDelete: forceDelete
      }
    })
    .catch(err => err);

  return response.data;
};

export const getRecurringPayments = async () => {
  const response = await axiosAuth
    .get(`${API_URL}/api/v1/payment-orders/stripe/subscription`)
    .catch(err => err);

  return (
    response.data && {
      ...response.data,
      subscriptions: (response.data.subscriptions || []).map(sub => ({
        ...sub,
        nextPaymentTimestamp:
          typeof sub.nextPaymentTimestamp === 'number'
            ? sub.nextPaymentTimestamp * 1000
            : sub.nextPaymentTimestamp,
        amount: BigNumber(sub.amount)
          .dividedBy(100)
          .toNumber()
      })),
      previousPayments: (response.data.previousPayments || []).map(p => ({
        ...p,
        amount: BigNumber(p.amount)
          .dividedBy(100)
          .toNumber()
      }))
    }
  );
};

export const skipNextPayment = async () => {
  const response = await axiosAuth
    .post(`${API_URL}/api/v1/payment-orders/stripe/subscription/pause`)
    .catch(err => err);

  return response;
};

export const cancelAllSubscriptions = async () => {
  const response = await axiosAuth
    .delete(`${API_URL}/api/v1/payment-orders/stripe/subscription`)
    .catch(err => err);

  return response;
};

export const subscribeToRecurringPayment = async (
  paymentMethodId,
  amount,
  frequency
) => {
  let response;
  const weeksAmount = Number.parseInt(frequency, 0);

  if (paymentMethodId) {
    response = await axiosAuth
      .post(`${API_URL}/api/v1/payment-orders/stripe/subscription`, {
        paymentMethodId,
        amount,
        weeksAmount
      })
      .catch(err => err);
  } else {
    response = await axiosAuth
      .post(`${API_URL}/api/v1/payment-orders/stripe/subscription`, {
        amount,
        weeksAmount
      })
      .catch(err => err);
  }

  return response;
};

export const createSetupIntent = async () => {
  const response = await axiosAuth.post(
    `${API_URL}/api/v1/payment-orders/stripe/create-setup-intent`
  );

  return response;
};

export const getAffiliateTagInfo = async tag => {
  const response = await axios
    .get(`${API_URL}/api/v1/users/affiliate/check-reward`, {
      params: {
        affiliateTag: tag
      }
    })
    .catch(err => err);

  return response.data;
};

export const makeIbaneraPayment = async (tokenizedCard, amount) => {
  const fsSessionId = localStorage.getItem('fsSession') || '';
  const response = await axiosAuth
    .post(`/api/v1/payment-orders/ibanera/authorize-payment`, {
      tokenizedCard,
      fsSessionId,
      amount
    })
    .catch(err => {
      throw new Error(err);
    });
  return response.data;
};

export const generateBraintreeToken = async () => {
  const response = await axiosAuth
    .get(`/api/v1/payment-orders/braintree/generate-token`)
    .catch(err => {
      throw new Error(err);
    });
  return response.data;
};

export const sendPayPalNonceToBackend = async ({
  nonce,
  amount,
  device_data
}) => {
  const response = await axiosAuth
    .post(`/api/v1/payment-orders/braintree/`, {
      paymentMethodNonce: nonce,
      amount,
      device_data
    })
    .catch(err => {
      throw new Error(err);
    });
  return response.data;
};

export const createIbaneraWithdrawalTicket = async payload => {
  const response = await axiosAuth
    .post(`/api/v1/payment-orders/ibanera/withdrawal`, payload)
    .catch(err => {
      throw new Error(err);
    });
  return response.data;
};

export const createDotsFlow = async flowType => {
  const response = await axiosAuth
    .post(`/api/v1/payment-orders/dots/flows`, { flow_type: flowType })
    .catch(err => {
      throw new Error(err);
    });
  return response.data;
};

export const createDotsPaymentIntent = async amount => {
  const response = await axiosAuth
    .post(`/api/v1/payment-orders/dots/payment-intents`, { amount })
    .catch(err => {
      throw new Error(err);
    });
  return response.data;
};

export const createDotsAchTransfer = async (
  amount,
  accountId,
  routingId,
  accountType
) => {
  const response = await axiosAuth
    .post(`/api/v1/payment-orders/dots/payments`, {
      amount,
      ach_info: {
        account_number: accountId,
        routing_number: routingId,
        account_type: accountType
      }
    })
    .catch(err => {
      throw new Error(err);
    });
  return response.data;
};

export const createDotsWithdrawal = async payload => {
  const response = await axiosAuth
    .post(`/api/v1/payment-orders/dots/withdrawals`, {
      amount: payload.amount,
      key_2fa: payload.code2fa,
      withdrawal_info: payload.withdrawInfo
    })
    .catch(err => {
      throw new Error(err);
    });
  return response.data;
};
