import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import SVG from 'react-inlinesvg';
import { Redirect, Link } from 'react-router-dom';
import { Field } from 'redux-form';
import APButton from 'apex-web/lib/components/common/APButton';
import {
  required,
  email,
  noWhiteSpace
} from 'apex-web/lib/helpers/formValidations';
import { renderFormInputs } from 'apex-web/lib/helpers/formGeneratorHelper';
import path from 'apex-web/lib/helpers/path';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { defaultPath } from 'apex-web/lib/routeTemplates';
import APSignUpInput from '../../../components/common/APSignUpInput/APSignUpInput';
import 'apex-web/lib/styles/components/common/StandaloneForm.css';
import './SignupFormComponent.css';
import SiteLogo from '../../../images/onboarding-logo.png';
import { APTypography } from '../../../components/common/APTypography';
import {
  requiredWithoutMessage,
  validatePassword,
  validatePasswordLenght,
  validatePasswordLettersAndNumbers,
  validatePasswordSpecialCharacter,
  validatePasswordUpCaseLetter,
  matchingPassword
} from '../../../helpers/fieldValidationHelper';
import APIcon from '../../../components/common/APIcon/APIcon';
import { CheckBox } from '../../../components/common/Checkbox';
import { ShowPasswordIcon } from '../../../images/react-icons/ShowPasswordIcon';
import { HidePasswordIcon } from '../../../images/react-icons/HidePasswordIcon';
import ReferralIcon from '../../../images/icons/icon-referral.svg';
import SadFaceIcon from '../../../images/icons/icon-sad-face.svg';
import { getAffiliateTagInfo } from '../../../api/liquidApi';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

const baseClasses = getBEMClasses('standalone-form');
const signupFormClasses = getBEMClasses('signup-form');

const SignupFormComponent = (props, context) => {
  const {
    handleSubmit,
    errorMsg,
    submitting,
    pristine,
    isAuthenticated,
    passwordValue,
    emailValue,
    invalid,
    done,
    additionalFields,
    resendVerificationEmail,
    termsAndServicesLink,
    privacyPolicyLink,
    synchronousError,
    fields,
    submitErrors
  } = props;

  const handleChange = () => {
    if (props.errorMsg) {
      props.clearSignupError();
    }
  };
  const [termOfUseCheck, setTermOfUseCheck] = useState(true);
  const [privacyPolicyCheck, setPrivacyPolicyCheck] = useState(true);
  const [sendEmailsCheck, setSendEmailsCheck] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [matchingPasswordType, setMatchingPasswordType] = useState('password');

  const [affiliateInfo, setAffiliateInfo] = useState(null);
  const isAffiliateInfoLoading = !!get(affiliateInfo, 'loading');
  const isAffiliateTagExpired = !!get(affiliateInfo, 'deletedAt');
  const urlParams = new URLSearchParams(window.location.search);
  const affTag = urlParams.get('aff');
  useEffect(
    () => {
      if (affTag) {
        let isMounted = true;
        setAffiliateInfo({ loading: true });
        getAffiliateTagInfo(affTag)
          .then(info => {
            if (isMounted) {
              setAffiliateInfo(info);
            }
          })
          .catch(error => {
            console.e(error);
            if (isMounted) {
              setAffiliateInfo(null);
            }
          });
        return () => {
          isMounted = false;
        };
      }
    },
    [affTag]
  );

  if (isAuthenticated) {
    return <Redirect to={path(`${defaultPath.path}`)} />;
  }

  const showFieldValid = fieldName => {
    if (
      (synchronousError && synchronousError[fieldName]) ||
      (submitErrors && submitErrors[fieldName])
    ) {
      return false;
    } else if (fields && fields[fieldName] && fields[fieldName].touched) {
      return true;
    }
  };

  const getValidationStatus = fieldName => {
    if (!fields || !fields[fieldName] || !fields[fieldName].touched) {
      return '';
    } else {
      return showFieldValid(fieldName) ? 'valid-field' : 'invalid-field';
    }
  };

  const renderValidIcon = classModifier => {
    return (
      <div className={signupFormClasses('valid-icon-container', classModifier)}>
        <APIcon className={signupFormClasses('valid-icon')} name="validIcon" />
      </div>
    );
  };

  return (
    <div className={`${signupFormClasses('form-container')}`}>
      <div className={signupFormClasses('header-container')}>
        <div className={signupFormClasses('logo-container')}>
          <img className={signupFormClasses('logo')} src={SiteLogo} alt="" />
        </div>
        {done ? (
          <div className={signupFormClasses('title-container')}>
            <APTypography
              fontSize="title"
              weight="weight600"
              color="black8"
              className={signupFormClasses('title')}>
              {context.t('Thank you')}
            </APTypography>
            <APTypography fontSize="title" weight="weight600" color="black8">
              {context.t('for signing up.')}
            </APTypography>
          </div>
        ) : (
          <div className={signupFormClasses('title-container')}>
            <APTypography
              fontSize="title"
              weight="weight600"
              color="black8"
              className={signupFormClasses('title')}>
              {context.t('SIGN UP')}
            </APTypography>
            <APTypography
              fontSize="subtitle"
              weight="weight600"
              color="black8"
              className={signupFormClasses('sub-title')}>
              {context.t("Let's get started!")}
            </APTypography>
          </div>
        )}
      </div>
      {done ? (
        <div className={signupFormClasses('resend-wrapper')}>
          <APTypography
            fontSize="body"
            color="black8"
            weight="weight600"
            className={`${signupFormClasses('done')}`}>
            {context.t('Please check your email to activate your account.')}
          </APTypography>
          <APTypography
            fontSize="body"
            color="black8"
            weight="weight600"
            className={`${signupFormClasses('resend-info')}`}>
            {context.t(`Haven't received an email?`)}
          </APTypography>
          <APButton
            disabled={submitting}
            onClick={() => resendVerificationEmail({ Email: emailValue })}
            customClass={signupFormClasses()}>
            <APTypography fontSize="body" weight="weight600" color="white4">
              {submitting ? context.t('Processing...') : context.t('Resend')}
            </APTypography>
          </APButton>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className={`${signupFormClasses('form')}`}>
          {isAffiliateInfoLoading ? (
            <Spinner isInline />
          ) : (
            affiliateInfo && (
              <div
                className={signupFormClasses('affiliate-container', {
                  expired: isAffiliateTagExpired
                })}>
                <SVG
                  src={isAffiliateTagExpired ? SadFaceIcon : ReferralIcon}
                  className={signupFormClasses('affiliate-icon')}
                />
                <span
                  className={signupFormClasses('affiliate-info', {
                    expired: isAffiliateTagExpired
                  })}>
                  {isAffiliateTagExpired
                    ? context.t(
                        'Oh no! Looks like all the tokens have been claimed! Make sure you sign up to our newsletter and follows us on our social media platforms to keep updated on all future promotions.'
                      )
                    : affiliateInfo.productSymbol === 'USD'
                      ? context.t(
                          'Sign-up today using this referral link to Earn {n} USD!',
                          { n: affiliateInfo.amount }
                        )
                      : context.t(
                          'Sign-up today using this referral link to Earn {n} Free {currency} token!',
                          {
                            n: affiliateInfo.amount,
                            currency: affiliateInfo.productSymbol
                          }
                        )}
                </span>
              </div>
            )
          )}
          <div className={signupFormClasses('wrapper')}>
            <div className={signupFormClasses('container')}>
              <APSignUpInput
                type="text"
                name="username"
                validate={[required, noWhiteSpace]}
                customClass={signupFormClasses(getValidationStatus('username'))}
                onChange={handleChange}
                placeholderInInput={context.t('username')}
                iconComponent={
                  showFieldValid('username') ? renderValidIcon : null
                }
                required
              />

              <APSignUpInput
                type="text"
                name="email"
                validate={[required, email]}
                customClass={signupFormClasses(getValidationStatus('email'))}
                onChange={handleChange}
                placeholderInInput={context.t('email')}
                iconComponent={showFieldValid('email') ? renderValidIcon : null}
                required
              />
              <APSignUpInput
                type={passwordType}
                name="password"
                validate={[requiredWithoutMessage, validatePassword]}
                onFocus={() => props.touch('signup', 'matchingPassword')}
                customClass={signupFormClasses(getValidationStatus('password'))}
                onChange={handleChange}
                placeholderInInput={context.t('password')}
                iconComponent={() => (
                  <div className={signupFormClasses('password-icons')}>
                    {passwordType === 'password' ? (
                      <div
                        onClick={() => setPasswordType('text')}
                        className={signupFormClasses('password-icon')}>
                        <ShowPasswordIcon />
                      </div>
                    ) : (
                      <div
                        className={signupFormClasses('password-icon')}
                        onClick={() => setPasswordType('password')}>
                        <HidePasswordIcon />
                      </div>
                    )}
                    {showFieldValid('password')
                      ? renderValidIcon('password')
                      : null}
                  </div>
                )}
                required
                info={
                  getValidationStatus('password') !== '' ? (
                    <div className={signupFormClasses('password-tip')}>
                      <div className={signupFormClasses('password-tip-header')}>
                        {context.t('Must included:')}
                      </div>
                      <ul className={signupFormClasses('password-rules-list')}>
                        <li
                          className={signupFormClasses(
                            validatePasswordLenght(passwordValue)
                              ? 'password-rule'
                              : 'password-rule-error'
                          )}>
                          {context.t('At least 6 characters.')}
                        </li>
                        <li
                          className={signupFormClasses(
                            validatePasswordUpCaseLetter(passwordValue)
                              ? 'password-rule'
                              : 'password-rule-error'
                          )}>
                          {context.t(
                            'A mixture of both uppercase and lowercase letters'
                          )}
                        </li>
                        <li
                          className={signupFormClasses(
                            validatePasswordLettersAndNumbers(passwordValue)
                              ? 'password-rule'
                              : 'password-rule-error'
                          )}>
                          {context.t('A mixture of letters and numbers.')}
                        </li>
                        <li
                          className={signupFormClasses(
                            validatePasswordSpecialCharacter(passwordValue)
                              ? 'password-rule'
                              : 'password-rule-error'
                          )}>
                          {context.t(
                            'At least one special character, e.g., ! @ # ? ]'
                          )}
                        </li>
                      </ul>
                    </div>
                  ) : null
                }
              />

              <APSignUpInput
                type={matchingPasswordType}
                name="matchingPassword"
                classes="signup"
                validate={[required, matchingPassword]}
                customClass={signupFormClasses(
                  getValidationStatus('matchingPassword')
                )}
                onChange={handleChange}
                placeholderInInput={context.t('confirm password')}
                iconComponent={() => (
                  <div className={signupFormClasses('password-icons')}>
                    {matchingPasswordType === 'password' ? (
                      <div
                        onClick={() => setMatchingPasswordType('text')}
                        className={signupFormClasses('password-icon')}>
                        <ShowPasswordIcon />
                      </div>
                    ) : (
                      <div
                        className={signupFormClasses('password-icon')}
                        onClick={() => setMatchingPasswordType('password')}>
                        <HidePasswordIcon />
                      </div>
                    )}
                    {showFieldValid('matchingPassword')
                      ? renderValidIcon('password')
                      : null}
                  </div>
                )}
                required
              />
              {renderFormInputs(additionalFields, signupFormClasses(), context)}
              {errorMsg && (
                <p
                  className={`${baseClasses('error')} ${signupFormClasses(
                    'error'
                  )}`}>
                  {errorMsg}
                </p>
              )}
            </div>
          </div>

          <div className={signupFormClasses('footer')}>
            <div className={signupFormClasses('checkboxes-wrapper')}>
              <div className={signupFormClasses('checkbox-row')}>
                <CheckBox
                  id="termOfUse"
                  value={termOfUseCheck}
                  customClass={signupFormClasses('checkbox')}
                  handleClick={e => {
                    e.preventDefault();
                    setTermOfUseCheck(!termOfUseCheck);
                  }}
                />
                <div className={signupFormClasses('checkbox-text')}>
                  I have read, understood and agree to{' '}
                  <a
                    href={termsAndServicesLink}
                    className={signupFormClasses('checkbox-text-link')}>
                    Liquid Marketplace’s Terms of Use
                  </a>
                </div>
              </div>
              <div className={signupFormClasses('checkbox-row')}>
                <CheckBox
                  id="privacyPolicy"
                  value={privacyPolicyCheck}
                  customClass={signupFormClasses('checkbox')}
                  handleClick={e => {
                    e.preventDefault();
                    setPrivacyPolicyCheck(!privacyPolicyCheck);
                  }}
                />
                <div className={signupFormClasses('checkbox-text')}>
                  I understand that my personal information will be collected,
                  used and disclosed in accordance with{' '}
                  <a
                    href={privacyPolicyLink}
                    className={signupFormClasses('checkbox-text-link')}>
                    {"Liquid Marketplace's Privacy Policy"}
                  </a>
                </div>
              </div>
              <div className={signupFormClasses('checkbox-row')}>
                <Field
                  name="newsSubscription"
                  type="radio"
                  value={sendEmailsCheck}
                  component={props => (
                    <CheckBox
                      id="sendEmails"
                      value={props.value}
                      customClass={signupFormClasses('checkbox')}
                      {...props.input}
                      handleClick={() => {
                        setSendEmailsCheck(!sendEmailsCheck);
                        props.input.onChange(!sendEmailsCheck);
                      }}
                    />
                  )}
                />
                <div className={signupFormClasses('checkbox-text')}>
                  Click here if you would like to receive news, updates and
                  promotions from Liquid Marketplace. You can withdraw your
                  consent at any time. See our Privacy Policy for details.
                </div>
              </div>
            </div>
            <APButton
              type="submit"
              disabled={
                pristine ||
                submitting ||
                invalid ||
                !privacyPolicyCheck ||
                !termsAndServicesLink
              }
              customClass={signupFormClasses()}>
              <APTypography
                fontSize="body"
                weight="weight600"
                color="white4"
                className={signupFormClasses('signup')}>
                {submitting ? context.t('Processing...') : context.t('Sign Up')}
              </APTypography>
            </APButton>
            <div className={signupFormClasses('sign-in')}>
              {context.t('Already have an account?')}
            </div>
            <Link to={path('/login')}>
              <div className={signupFormClasses('sign-in-link')}>
                {context.t('Login here')}
              </div>
            </Link>
          </div>
        </form>
      )}
    </div>
  );
};

SignupFormComponent.defaultProps = {
  handleSubmit: () => {},
  submitting: false
};

SignupFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  referrer: PropTypes.string,
  search: PropTypes.string,
  shouldRedirect: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  errorMsg: PropTypes.string,
  pristine: PropTypes.bool,
  passwordValue: PropTypes.string,
  invalid: PropTypes.bool,
  done: PropTypes.bool,
  additionalFields: PropTypes.array,
  termsAndServicesLink: PropTypes.string,
  privacyPolicyLink: PropTypes.string,
  clearSignupError: PropTypes.func,
  synchronousError: PropTypes.object,
  fields: PropTypes.object,
  submitErrors: PropTypes.object,
  emailValue: PropTypes.string,
  resendVerificationEmail: PropTypes.func,
  touch: PropTypes.func,
  value: PropTypes.string,
  input: PropTypes.object
};

SignupFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SignupFormComponent;
