import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

import VerifyEmailContainer from './VerifyEmailContainer';
import redirectIfAuthorized from '../../hocs/redirectIfAuthorized';

import '../../styles/components/common/StandaloneForm.css';
import './VerifyEmailPage.css';

const verifyEmailPageClasses = getBEMClasses('verify-email-page');

export const VerifyEmailForm = () => {
  return (
    <div className={` ${verifyEmailPageClasses('container')}`}>
      <VerifyEmailContainer />
    </div>
  );
};

VerifyEmailForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  VerifyEmailForm,
  process.env.REACT_APP_DEFAULT_PATH
);
