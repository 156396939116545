import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Switch } from '../Switch';

const SwitchWithReduxForm = props => (
  <Field name={props.name} component={Switch} {...props} />
);

SwitchWithReduxForm.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  customClass: PropTypes.string
};

export default SwitchWithReduxForm;
