import React from 'react';
import PropTypes from 'prop-types';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import QuidSuccessAnimated from '../../../images/quid_success_animated.gif';
import './SimpleOrderMessage.css';

const classes = getBEMClasses('simple-order-message');

const AcceptedOrder = (props, context) => {
  return (
    <div className={classes('container')}
      data-test='acceptedRoot'>
      <img className={classes('image')}
        src={QuidSuccessAnimated}
        alt=''
      />
      {context.t('Your order is successfully placed.')}
    </div>
  );
};

AcceptedOrder.contextTypes = {
  t: PropTypes.func.isRequired
};

export default AcceptedOrder;
