import { modalDecorator } from '../../common/Modal/modalDecorator';
import { connect } from 'react-redux';
import KYCStatusModal from './KYCStatusModal';
import { MODAL_TYPES } from '../../../redux/actions/modalActions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = () => {
  const template = JSON.parse(localStorage.getItem('apexTemplate'));
  const theme = template && template.theme ? template.theme : 'light';
  return {
    isDarkTheme: theme === 'dark'
  };
};

const mapDispatchToProps = {};

const container = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(KYCStatusModal)
);

export default modalDecorator({
  name: MODAL_TYPES.KYC_STATUS_MODAL
})(container);
