import { setUserConfig } from 'apex-web/lib/redux/actions/userActions';
import { getInstrumentsTokens } from '../../api/liquidApi';

export const updateAssetsWatchList = assetId => (dispatch, getState) => {
  const {
    user: { userInfo, userConfig }
  } = getState();
  let currentWatchList = userConfig.find(item => item.Key === 'AssetWatchList');
  if (currentWatchList) {
    currentWatchList.Value = JSON.parse(currentWatchList.Value);
  } else {
    currentWatchList = { Key: 'AssetWatchList', Value: [] };
  }
  if (currentWatchList.Value.includes(assetId)) {
    currentWatchList.Value = currentWatchList.Value.filter(
      item => item !== assetId
    );
  } else {
    currentWatchList.Value = [...currentWatchList.Value, assetId];
  }

  currentWatchList.Value = JSON.stringify(currentWatchList.Value);

  dispatch(
    setUserConfig(userInfo.UserId, userInfo.UserName, [currentWatchList])
  );
};

export const SET_AVAILABLE_TOKENS = 'SET_AVAILABLE_TOKENS';
export const setAvailableTokens = payload => ({
  type: SET_AVAILABLE_TOKENS,
  payload
});
export const getAvailableTokens = attributes => async (dispatch, getState) => {
  const state = getState();

  let params;
  if (attributes) {
    const { productIds, instrumentIds } = attributes;
    if (instrumentIds) {
      params = { instrumentIds };
    } else if (productIds) {
      params = { productIds };
    }
  } else {
    const allProductIds = Object.values(state.productAttributes).flatMap(
      product => (product.Type === 'DigitalAsset' ? product.ProductId : [])
    );
    params = { productIds: allProductIds };
  }
  const tokens = await getInstrumentsTokens(params);
  if (tokens) {
    dispatch(setAvailableTokens(tokens));
    return tokens;
  }
};
