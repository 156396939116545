import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { change, destroy } from 'redux-form';
import PropTypes from 'prop-types';
import resize from 'apex-web/lib/hocs/resize';
import get from 'lodash/get';
import OrderBook from '../../components/OrderBookComponents/OrderBookContainer';
import OrderEntry from '../../components/OrderEntryForm/OrderEntryFormContainer';
import OrderEntryModal from '../../components/OrderEntryForm/OrderEntryFormModal';
import InstrumentPositions from '../../components/InstrumentPositions';
import TradingLayoutContentMobile from '../Responsive/TradingLayout/TradingLayoutContentMobile';
import TradingLayoutContent1280Px from '../Responsive/TradingLayout/TradingLayoutContent1280Px';
import TradingLayoutContent1440Px from '../Responsive/TradingLayout/TradingLayoutContent1440Px';
import config from '../../config';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { BreadCrumbsLayout } from '../BreadCrumbsLayout/BreadCrumbsLayoutContainer';
import { useGetAvailableTokens } from '../../hooks/useGetAvailableTokens';
import { getAvailableTokens } from '../../redux/actions/cardActions';
import { availableTokensSelector } from '../../redux/selectors/cardSelector';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { Link } from 'react-router-dom';

import './ProExchangeLayout.css';
import { isProductSoldOutSelector } from '../../redux/selectors/productsSelector';
import { buyValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import SelectAssetModalContainer from '../../components/SelectAssetModal/SelectAssetModalContainer';
import SelectAssetFromWatchListModalContainer from '../../components/SelectAssetFromWatchListModal/SelectAssetFromWatchListModalContainer';

const classes = getBEMClasses('pro-exchange-layout');
const tradingLayoutClasses = getBEMClasses('trading-layout');
const primaryMarketBlockerModal = getBEMClasses('primary-market-blocker');

const EXPANDED_NAV_BAR_WIDTH = 240;

const ProExchange = (props) => {
  const {
    width,
    height,
    productId,
    availableTokens,
    getAvailableTokens,
    isProductSoldOut = false,
    side,
    setSide,
    clearOrderEntryForm
  } = props;
  const currentNavBarWidth = EXPANDED_NAV_BAR_WIDTH;
  const newAvailableTokens = useGetAvailableTokens({
    productId,
    availableTokens,
    getAvailableTokens
  });

  useEffect(() => {
    return () => {
      clearOrderEntryForm();
    };
  }, []);

  const renderPrimaryMarketBlocker = React.useCallback(
    () => {
      if (!isProductSoldOut)
        return (
          <div className={primaryMarketBlockerModal()}>
            <div className={primaryMarketBlockerModal('container')}>
              <div className={primaryMarketBlockerModal('header')}>
                Asset not in{' '}
                <span>
                  <b> Pro MarketPlace</b>
                </span>.
              </div>
              <div className={primaryMarketBlockerModal('content')}>
                <div className={primaryMarketBlockerModal('content-text')}>
                  Please visit Offerings Asset page to continue your journey!
                </div>
                <div className={primaryMarketBlockerModal('content-link')}>
                  <Link
                    to="/offerings"
                    className={primaryMarketBlockerModal('link')}>
                    Go to offering
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
    },
    [isProductSoldOut, productId]
  );

  const renderTradingLayout = () => {
    if (
      width - currentNavBarWidth > 968 &&
      width - currentNavBarWidth <= 1280
    ) {
      return (
        <React.Fragment>
          {renderPrimaryMarketBlocker()}
          <div
            style={{
              filter: !isProductSoldOut && 'blur(5px)'
            }}>
            <TradingLayoutContent1280Px
              short={height <= 1080}
              side={side}
              onSideChange={setSide}
              {...{
                OrderBook,
                OrderEntry,
                InstrumentPositions
              }}
              config={config}
              {...props}
              availableTokens={newAvailableTokens}
            />
          </div>
        </React.Fragment>
      );
    }

    if (width - currentNavBarWidth > 1280) {
      return (
        <React.Fragment>
          {renderPrimaryMarketBlocker()}
          <div
            style={{
              filter: !isProductSoldOut && 'blur(5px)'
            }}>
            <TradingLayoutContent1440Px
              short={height <= 1080}
              side={side}
              onSideChange={setSide}
              {...{
                OrderBook,
                OrderEntry,
                InstrumentPositions
              }}
              config={config}
              {...props}
              availableTokens={newAvailableTokens}
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className={tradingLayoutClasses('container')}>
        {renderPrimaryMarketBlocker()}
        <div
          style={{
            filter: !isProductSoldOut && 'blur(5px)'
          }}>
          <TradingLayoutContentMobile
            side={side}
            onSideChange={setSide}
            {...{
              OrderBook,
              OrderEntryModal,
              InstrumentPositions
            }}
            config={config}
            {...props}
            availableTokens={newAvailableTokens}
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <BreadCrumbsLayout>
        <div className={classes()}>
          <div className={classes('container')} id="test-trading">
            {renderTradingLayout()}
          </div>
        </div>
      </BreadCrumbsLayout>
      <SelectAssetModalContainer />
      <SelectAssetFromWatchListModalContainer />
    </React.Fragment>
  );
};

ProExchange.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  productId: PropTypes.number,
  getAvailableTokens: PropTypes.func,
  availableTokens: PropTypes.object,
  isProductSoldOut: PropTypes.bool,
  side: PropTypes.string.isRequired,
  setSide: PropTypes.func.isRequired,
  clearOrderEntryForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const selectedInstrument = selectedInstrumentSelector(state);
  const productId = selectedInstrument.Product1;
  const { verificationLevel } = kycVerificationLevelsSelector(state);
  const isProductSoldOut = verificationLevel === 99 ? true : isProductSoldOutSelector(state, productId);

  return {
    productId,
    availableTokens: availableTokensSelector(state),
    isProductSoldOut,
    side: get(state, 'form.orderEntry.values.side', buyValue)
  };
};

const mapDispatchToProps = dispatch => ({
  getAvailableTokens: ({ productIds }) =>
    dispatch(getAvailableTokens({ productIds })),
  setSide: side => dispatch(change('orderEntry', 'side', side)),
  clearOrderEntryForm: () => dispatch(destroy('orderEntry')),
});

export const ProExchangeLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(resize(ProExchange));
