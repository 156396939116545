export const UrlFactory = {
  getDashboardUrl: () => `/dashboard`,
  getOfferingsUrl: () => `/offerings`,
  getOfferingsAssetDetailsUrl: assetId => `/offerings/asset-details/${assetId}`,
  getWalletsUrl: () => '/wallets',
  getProExchangeUrl: () => '/pro-marketplace',
  getMyCollectiblesUrl: () => '/my-collectibles',
  getMyCollectiblesAssetDetailsUrl: assetId =>
    `/my-collectibles/asset-details/${assetId}`,
  getMarketPlaceUrl: () => '/market-place',
  getMarketPlaceAssetDetailsUrl: assetId =>
    `/market-place/asset-details/${assetId}`,
  // settings
  getUserSettingsUrl: () => '/settings/user',
  getTradeReportsSettingsUrl: () => '/settings/trade-reports',
  getApiKeysSettingsUrl: () => '/settings/api-keys',
  getContactsSettingsUrl: () => '/settings/contacts',
  getAffiliateSettingsUrl: () => '/settings/affiliate',
  getLoyaltyTokenSettingsUrl: () => '/settings/loyalty-token',
  getVerificationLevelSettingsUrl: () => '/settings/verification-level',
  getDemoSettingsUrl: () => '/settings/demo-settings',
  getTiersExplainedUrl: () => '/tiers',
  getCommunmityPageUrl: () => '/community'
};
