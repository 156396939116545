import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import APButton from 'apex-web/lib/components/common/APButton';
import { required } from 'apex-web/lib/helpers/formValidations';
import passwordScore from 'apex-web/lib/helpers/passwordScore';
import path from 'apex-web/lib/helpers/path';
import { APInputWithReduxForm } from '../../../components/common/APInputWithReduxForm';
import SiteLogo from '../../../images/site-logo.png';

import './ResetPasswordFormComponent.css';
import { APTypography } from '../../../components/common/APTypography';

const baseClasses = getBEMClasses('standalone-form');
const resetPasswordClasses = getBEMClasses('reset-password');

export default class ResetPasswordFormComponent extends React.Component {
  handleChange = () => {
    if (this.props.validateError) {
      this.props.clearResetPasswordError();
    }
  };

  getPasswordStrength = () => {
    const { context } = this;
    const score = passwordScore(this.props.passwordValue);
    const passwordStrengthValues = {
      0: context.t('Very Weak'),
      1: context.t('Weak'),
      2: context.t('Medium'),
      3: context.t('Strong'),
      4: context.t('Very Strong')
    };
    return passwordStrengthValues[score];
  };

  render() {
    const {
      context,
      props: {
        processingResetPassword,
        message,
        handleSubmit,
        fetchError,
        pristine,
        passwordValue,
        invalid,
        success
      }
    } = this;

    return (
      <React.Fragment>
        <div className={resetPasswordClasses('logo-container')}>
          <img className={resetPasswordClasses('logo')} src={SiteLogo} alt="" />
        </div>
        <div className={resetPasswordClasses('title-wrapper')}>
          <APTypography
            fontSize="title"
            weight="weight600"
            color="black8"
            className={resetPasswordClasses('title')}>
            {context.t('Reset Your Password')}
          </APTypography>
        </div>
        {!success && (
          <form
            onSubmit={handleSubmit}
            className={resetPasswordClasses('form')}>
            <APInputWithReduxForm
              type="password"
              name="password"
              customClass={resetPasswordClasses()}
              placeholderInInput={context.t('New Password')}
              validate={[required]}
              readOnly={processingResetPassword}
              info={
                passwordValue && (
                  <span className={baseClasses('password-strength')}>
                    {this.getPasswordStrength()}
                  </span>
                )
              }
              onChange={this.handleChange}
              required
            />
            <APInputWithReduxForm
              type="password"
              name="matchingPassword"
              customClass={resetPasswordClasses()}
              placeholderInInput={context.t('Repeat New Password')}
              readOnly={processingResetPassword}
              validate={[required]}
              onChange={this.handleChange}
              required
            />

            {message && (
              <div className={baseClasses('password-error')}>
                {context.t(message)}
              </div>
            )}

            <APButton
              type="submit"
              disabled={pristine || processingResetPassword || invalid}
              customClass={`${resetPasswordClasses()}`}>
              <APTypography
                fontSize="body"
                weight="weight600"
                color="white4"
                className={`${resetPasswordClasses('reset')}`}>
                {processingResetPassword
                  ? context.t('Resetting your password...')
                  : context.t('Reset Password')}
              </APTypography>
            </APButton>
          </form>
        )}
        {success && (
          <React.Fragment>
            <div className={`${resetPasswordClasses('message')}`}>
              <APTypography
                fontSize="body"
                color="grey2"
                weight="weight600"
                className={`${resetPasswordClasses('message-text')}`}>
                {context.t(message)}
              </APTypography>
            </div>
            <div className={`${resetPasswordClasses('message-footer')}`}>
              {fetchError ? (
                <Link
                  to={path('/problem-logging-in')}
                  className={resetPasswordClasses('link')}>
                  <APTypography
                    fontSize="body"
                    weight="weight600"
                    color="white4">
                    {context.t('Problem resetting your password?')}
                  </APTypography>
                </Link>
              ) : (
                <Link
                  to={path('/login')}
                  className={resetPasswordClasses('link')}>
                  <APTypography
                    fontSize="body"
                    weight="weight600"
                    color="white4">
                    {context.t('Go to Exchange')}
                  </APTypography>
                </Link>
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

ResetPasswordFormComponent.defaultProps = {
  handleSubmit: () => {},
  processingResetPassword: false,
  message: '',
  fetchError: false
};

ResetPasswordFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  processingResetPassword: PropTypes.bool.isRequired,
  fetchError: PropTypes.bool,
  validateError: PropTypes.bool,
  clearResetPasswordError: PropTypes.func,
  passwordValue: PropTypes.string,
  message: PropTypes.string,
  invalid: PropTypes.bool,
  success: PropTypes.bool,
  pristine: PropTypes.bool
};

ResetPasswordFormComponent.contextTypes = {
  t: PropTypes.func
};
