import { connect } from 'react-redux';
import { formValueSelector, reduxForm, change } from 'redux-form';
import HeaderWithFilterComponent from './HeaderWithFilterComponent';

const formSelector = formValueSelector('order-history-filter');

const mapStateToProps = state => {
  const startDate = formSelector(state, 'startDate');
  return {
    startDate
  };
};

const mapDispatchToProps = dispatch => ({
  clearDate: fieldName =>
    dispatch(change('order-history-filter', fieldName, null))
});

const HeaderWithFilterContainerForm = reduxForm({
  form: 'order-history-filter',
  initialValues: {
    startDate: undefined,
    endDate: undefined
  }
})(HeaderWithFilterComponent);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderWithFilterContainerForm);
