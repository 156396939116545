import { connect } from 'react-redux';
import OpenOrdersComponent from './OpenOrdersComponent';
import { openOrdersSelectorFactory } from 'apex-web/lib/redux/selectors/orderHistorySelectors';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import {
  cancelOrders,
  getOpenOrders
} from 'apex-web/lib/redux/actions/orderHistoryActions';

const makeMapStateToProps = () => {
  const openOrdersSelector = openOrdersSelectorFactory();

  return (state, { config, selectedInstrument }) => {
    const openOrders = openOrdersSelector(state, config);
    const instruments = instrumentSelectorInstruments(state);
    const {
      activeRequests,
      openOrdersStartIndex
    } = state.apexCore.orderHistory;
    const fetching = activeRequests > 0;

    return {
      data: openOrders,
      instruments,
      fetching,
      selectedInstrument,
      openOrdersStartIndex,
      selectedAccountId: state.user.selectedAccountId,
      OMSId: state.user.userInfo.OMSId
    };
  };
};

const mapDispatchToProps = {
  getOpenOrders,
  cancelOrders
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(OpenOrdersComponent);
