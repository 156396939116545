import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../../../common/Modal/Modal';
import { getBEMClasses } from '../../../../../helpers/cssClassesHelper';
import { BUY_OUT_RESULTS } from '../BuyOutModalComponent';
import { modalDecorator } from '../../../../common/Modal/modalDecorator';
import { MODAL_TYPES } from '../../../../../redux/actions/modalActions';
import SuccessModalIcon from '../../../../../images/icons/success-modal.png';
import ErrorModalIcon from '../../../../../images/icons/error-modal.png';
import './BuyOutResultModal.css';

const classes = getBEMClasses('buy-out-result-form');

const BuyOutResultModalComponent = (props, context) => {
  const { isOpen, close, result } = props;
  const isSuccessModal = result === BUY_OUT_RESULTS.SUCCESS;
  const title = isSuccessModal
    ? context.t('Form Submitted')
    : context.t('Request Rejected');
  const description = isSuccessModal
    ? context.t(
        'Thank you for submitting your buyout request! The vote will be sent to all token holders shortly. We wish you the best of luck with your offer!'
      )
    : context.t(
        'Unfortunately, your Wallet does not have the required funds necessary to perform the buyout request. If you believe that this rejection is in error, or if you have funds outside of the platform, please contact buyouts@liquidmarketplace.io'
      );
  return (
    <Modal
      customClass={classes()}
      isOpen={isOpen}
      close={close}
      showCloseIcon={false}
      titleComponent={
        <div className={classes('header')}>
          <span className={classes('icon-wrapper')}>
            <img
              className={classes('icon')}
              src={isSuccessModal ? SuccessModalIcon : ErrorModalIcon}
              alt='logo'
            />
          </span>
          {title}
        </div>
      }>
      <div className={classes('description')}>{description}</div>
      <div className={classes('buttons')}>
        {isSuccessModal ? (
          <button
            className={classes('submit')}
            onClick={() => {
              close();
            }}>
            {context.t('Ok')}
          </button>
        ) : (
          <button
            className={classes('try-again')}
            onClick={() => {
              close();
            }}>
            {context.t('Try Again')}
          </button>
        )}
      </div>
    </Modal>
  );
};

BuyOutResultModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

BuyOutResultModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  result: PropTypes.string
};

export default modalDecorator({ name: MODAL_TYPES.BUY_OUT_RESULT_MODAL })(
  BuyOutResultModalComponent
);
