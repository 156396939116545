import { connect } from 'react-redux';
import DepthChartComponent from './DepthChartComponent';
import store from 'apex-web/lib/redux/store';
import {
  buyPriceClicked,
  sellPriceClicked
} from 'apex-web/lib/redux/actions/orderEntryActions';
import config from 'apex-web/lib/config';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';

const { DepthChart } = config;

const mapStateToProps = state => {
  const { level2 } = state.apexCore;
  const { fetching } = level2;

  const selectedInstrument = selectedInstrumentSelector(state);

  return {
    level2,
    store,
    fetching,
    DepthChart,
    selectedInstrument
  };
};

const mapDispatchToProps = {
  buyPriceClicked,
  sellPriceClicked
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepthChartComponent);
