import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import APIcon from '../common/APIcon';
import { NotificationModal } from './NotificationModals/NotifacationModal';
import { MODAL_TYPES, openModal } from '../../redux/actions/modalActions';
import { getNotifications } from '../../redux/actions/notificationsActions';
import { AllNotificationsModal } from './NotificationModals/AllNotificationsModal';
import {
  selectAllNotifications,
  selectUnseenNotificationsCount
} from '../../redux/selectors/notificationsSelector';
import format from 'date-fns/format';
import './NotificationCenter.css';

const baseClass = getBEMClasses('notification-center');

const NOTIFICATIONS_TYPES = {
  GLOBAL: 'Global Message',
  SPECIFIC_USER: 'Specific User Message',
  REMINDER: 'Reminder Message',
  REWARD: 'Reward Message'
};

export const getNotificationAttributes = notification => {
  if (
    notification.type.toLowerCase() === 'message' &&
    notification.sendToTypes.includes('global')
  ) {
    return {
      notificationTitle: NOTIFICATIONS_TYPES.GLOBAL,
      subClassName: 'global',
      iconName: 'globalNotification'
    };
  } else if (notification.type.toLowerCase() === 'message') {
    return {
      notificationTitle: NOTIFICATIONS_TYPES.SPECIFIC_USER,
      subClassName: 'specific-user',
      iconName: 'specificUserNotification'
    };
  } else if (notification.type.toLowerCase() === 'reminder') {
    return {
      notificationTitle: NOTIFICATIONS_TYPES.REMINDER,
      subClassName: 'reminder',
      iconName: 'reminderNotification'
    };
  } else {
    return {
      notificationTitle: NOTIFICATIONS_TYPES.REWARD,
      subClassName: 'reward',
      iconName: 'rewardNotification'
    };
  }
};

const Notification = props => {
  const { notification, onClick } = props;
  const notificationDate = new Date(notification.createdAt);
  const notificationDateString =
    format(notificationDate, 'MMMM d, y') +
    ' at ' +
    format(notificationDate, 'h:mm a');

  const {
    notificationTitle,
    subClassName,
    iconName
  } = getNotificationAttributes(notification);

  return (
    <div
      className={baseClass('notification-row', subClassName)}
      onClick={() =>
        onClick({
          notificationMessage: notification.message,
          date: notificationDateString,
          isSeen: notification.isSeen,
          subClassName,
          notificationTitle,
          iconName,
          notification
        })
      }>
      <div className={baseClass('notification-row-title')}>
        <APIcon name={iconName} customClass={baseClass('notification-type')} />
        {notificationTitle}
      </div>
      <div className={baseClass('notification-row-date')}>
        {notificationDateString}
      </div>
    </div>
  );
};

Notification.propTypes = {
  notification: PropTypes.object,
  onClick: PropTypes.func
};

const NotificationCenterComponent = (props, context) => {
  const [showNotificationList, setShowNotificationList] = useState(false);
  const {
    openNotificationModal,
    openAllNotificationsModal,
    getNotifications,
    notifications = [],
    unSeenNotificationsCount
  } = props;

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div
      className={baseClass('')}
      tabIndex={0}
      onBlur={() => setShowNotificationList(false)}>
      <div
        className={baseClass('icon-wrapper')}
        onClick={e => {
          e.stopPropagation();
          setShowNotificationList(!showNotificationList);
        }}>
        <APIcon name="bellNotification" customClass={baseClass('icon')} />
        {unSeenNotificationsCount > 0 ? (
          <APIcon name="unseen" customClass={baseClass('unseen-icon')} />
        ) : null}
      </div>
      {showNotificationList && (
        <div className={baseClass('notification-list-wrapper')}>
          <div className={baseClass('notification-list-header')}>
            <APIcon
              name="bellNotification"
              customClass={baseClass('notification-list-icon')}
            />
            {context.t('Notification')}
          </div>
          <div className={baseClass('notification-list-content')}>
            {unSeenNotificationsCount === 0 ? (
              <div className={baseClass('no-notifications-container')}>
                <div className={baseClass('no-notifications-title')}>
                  {context.t('Stay Tuned!')}
                </div>
                <div className={baseClass('no-notifications-text')}>
                  {context.t('Check later for notification updates')}
                </div>
              </div>
            ) : (
              <React.Fragment>
                {notifications
                  .filter(item => !item.isSeen)
                  .slice(0, 3)
                  .map((item, index) => (
                    <Notification
                      key={`notification-${index}`}
                      notification={item}
                      onClick={data => {
                        openNotificationModal(data);
                      }}
                    />
                  ))}
              </React.Fragment>
            )}

            <div
              className={baseClass('notification-list-view-all')}
              onClick={() => openAllNotificationsModal()}>
              {context.t('View all notifications')}
              {notifications.length > 0 && (
                <div className={baseClass('notification-count')}>
                  {notifications.length}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <NotificationModal />
      <AllNotificationsModal />
    </div>
  );
};

NotificationCenterComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

NotificationCenterComponent.propTypes = {
  openNotificationModal: PropTypes.func,
  openAllNotificationsModal: PropTypes.func,
  getNotifications: PropTypes.func,
  notifications: PropTypes.array,
  unSeenNotificationsCount: PropTypes.number
};

const mapStateToProps = state => {
  const notifications = selectAllNotifications(state);
  const unSeenNotificationsCount = selectUnseenNotificationsCount(state);

  return {
    notifications,
    unSeenNotificationsCount
  };
};

const mapDispatchToProps = dispatch => ({
  openNotificationModal: props =>
    dispatch(openModal(MODAL_TYPES.NOTIFICATION_MODAL, { ...props })),
  openAllNotificationsModal: () =>
    dispatch(openModal(MODAL_TYPES.ALL_NOTIFICATIONS_MODAL)),
  getNotifications: () => dispatch(getNotifications())
});

export const NotificationCenter = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationCenterComponent);
