import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { Modal } from '../../../components/common/Modal/Modal';
import { MODAL_TYPES } from '../../../redux/actions/modalActions';
import { modalDecorator } from '../../common/Modal/modalDecorator';
import APIcon from '../../common/APIcon';
import { NotificationRow } from './NotificationRow';
import { useIsTablet } from '../../../hooks/useIsMobile';
import {
  selectAllNotifications,
  selectDeletedNotifications
} from '../../../redux/selectors/notificationsSelector';
import {
  removeNotification,
  markNotificationsAsSeen
} from '../../../redux/actions/notificationsActions';
import { setActivePage } from 'apex-web/lib/redux/actions/paginationActions';
import { Select } from '../../common/Select';
import './NotificationModals.css';

const classes = getBEMClasses('all-notifications-modal');

const NOTIFICATION_FILTERS = {
  INBOX: 'Inbox',
  TRASH: 'Trash'
};

const AllNotificationsModalComponent = (props, context) => {
  const {
    isOpen,
    close,
    notifications = [],
    deletedNotifications,
    removeNotification,
    resetActivePage,
    markNotificationsAsSeen
  } = props;
  const [filter, setFilter] = useState(NOTIFICATION_FILTERS.INBOX);
  const [searchString, setSearchString] = useState('');
  const [currentSort, setCurrentSort] = useState(null);
  const sortOptions = [
    { value: 'name', label: 'Name', onClick: setCurrentSort },
    { value: 'date', label: 'Date', onClick: setCurrentSort }
  ];
  const currentNotifications = (filter === NOTIFICATION_FILTERS.INBOX
    ? notifications
    : deletedNotifications
  ).filter(
    item =>
      searchString
        ? item.message.toLowerCase().includes(searchString.toLowerCase())
        : item
  );

  const currentSortedNotifications = currentSort
    ? [...currentNotifications].sort((a, b) => {
        if (currentSort === 'name') {
          return a.message.localeCompare(b.message);
        } else if (currentSort === 'date') {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        } else {
          return true;
        }
      })
    : currentNotifications;

  const isTablet = useIsTablet();

  useEffect(
    () => {
      resetActivePage({ componentName: 'notifications', activePage: 0 });
    },
    [filter, setActivePage]
  );

  return (
    <Modal
      customClass={classes()}
      isOpen={isOpen}
      close={close}
      titleComponent={
        <div className={classes('header-left')}>
          <APIcon
            name="bellNotification"
            customClass={classes('notification-icon')}
          />
          {context.t('Notification')}
        </div>
      }>
      <div className={classes('body')}>
        <div className={classes('top')}>
          <div className={classes('filters')}>
            <div
              className={classes(
                'filter',
                filter === NOTIFICATION_FILTERS.INBOX ? 'active' : ''
              )}
              onClick={() => setFilter(NOTIFICATION_FILTERS.INBOX)}>
              {context.t('Inbox')} <div className={classes('filter-border')} />
            </div>
            <div
              className={classes(
                'filter',
                filter === NOTIFICATION_FILTERS.TRASH ? 'active' : ''
              )}
              onClick={() => setFilter(NOTIFICATION_FILTERS.TRASH)}>
              {context.t('Trash')} <div className={classes('filter-border')} />
            </div>
          </div>
          {!isTablet && (
            <div className={classes('search-container')}>
              <div className={classes('search-icon-container')}>
                <APIcon name="search" customClass={classes('search-icon')} />
              </div>
              <input
                className={classes('search-input')}
                placeholder={context.t('Search here...')}
                onChange={e => setSearchString(e.target.value)}
              />
            </div>
          )}
        </div>
        {filter === NOTIFICATION_FILTERS.TRASH && (
          <div className={classes('trash-reminder')}>
            {context.t('All messages in the trash will be deleted in 7 days.')}
          </div>
        )}

        {isTablet ? (
          <div className={classes('sort-search-wrapper')}>
            <Select currentOption={currentSort} options={sortOptions} />
            <div className={classes('search-container')}>
              <div className={classes('search-icon-container')}>
                <APIcon name="search" customClass={classes('search-icon')} />
              </div>
              <input
                className={classes('search-input')}
                placeholder={context.t('Search here...')}
                onChange={e => setSearchString(e.target.value)}
              />
            </div>
          </div>
        ) : (
          <div className={classes('sort-search-wrapper')}>
            <Select currentOption={currentSort} options={sortOptions} />
          </div>
        )}

        <div
          className={classes(
            'notifications-container',
            filter === NOTIFICATION_FILTERS.TRASH && 'trash'
          )}>
          {currentSortedNotifications.map((row, index) => (
            <NotificationRow
              notification={row}
              key={index}
              markNotificationAsSeen={() => {
                markNotificationsAsSeen([row.id]);
              }}
              removeNotification={() => {
                const isForceDelete = filter === NOTIFICATION_FILTERS.TRASH;
                removeNotification(row, isForceDelete);
              }}
              isTrash={filter === NOTIFICATION_FILTERS.TRASH}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

AllNotificationsModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

AllNotificationsModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  notifications: PropTypes.array,
  deletedNotifications: PropTypes.array,
  removeNotification: PropTypes.func,
  resetActivePage: PropTypes.func,
  markNotificationsAsSeen: PropTypes.func
};

const mapStateToProps = state => {
  const notifications = selectAllNotifications(state);
  const deletedNotifications = selectDeletedNotifications(state);
  return { notifications, deletedNotifications };
};

const mapDispatchToProps = {
  removeNotification,
  resetActivePage: setActivePage,
  markNotificationsAsSeen
};

const AllNotificationsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AllNotificationsModalComponent);

export const AllNotificationsModal = modalDecorator({
  name: MODAL_TYPES.ALL_NOTIFICATIONS_MODAL
})(AllNotificationsModalContainer);
