import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  checkIsOffering,
  currentOfferingSelector,
  selectOfferingsWithAvailability
} from '../../redux/selectors/productsSelector';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { CardOfferingBlock } from '../../components/CardOfferingBlock/CardOfferingBlock';
import UpcomingOfferings from './UpcomingOfferings/UpcomingOfferingsContainer';
import LiveOfferings from './LiveOfferings/LiveOfferingsContainer';
import { BreadCrumbsLayout } from '../../layouts/BreadCrumbsLayout/BreadCrumbsLayoutContainer';
import './OfferingsPage.css';
import { MobilePageHeader } from '../../components/MobilePageHeader/MobilePageHeaderComponent';
import { useIsMobile } from '../../hooks/useIsMobile';
import Slider from 'react-slick';
import { useDebounce } from 'react-use';
import { getAvailableTokens } from '../../redux/actions/cardActions';
import { availableTokensSelector } from '../../redux/selectors/cardSelector';
import { createSelector } from 'reselect';
import {
  NextSliderArrow,
  PrevSliderArrow
} from '../../components/common/SliderArrows';

const classes = getBEMClasses('offerings-page');

const OfferingsPageComponent = (props, context) => {
  const { currentOfferings, getAvailableTokens, offeringIds } = props;
  const isMobile = useIsMobile();
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    nextArrow: isMobile ? null : (
      <NextSliderArrow iconName="rightSliderArrow" />
    ),
    prevArrow: isMobile ? null : <PrevSliderArrow iconName="leftSliderArrow" />,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true
  };

  useDebounce(
    () => {
      if (offeringIds.length) {
        getAvailableTokens({ productIds: offeringIds });
      }
    },
    700,
    [offeringIds.length]
  );

  return (
    <React.Fragment>
      {isMobile && <MobilePageHeader title={context.t('Offerings')} />}
      <BreadCrumbsLayout>
        <div className={classes()}>
          {currentOfferings.length ? (
            <Slider {...sliderSettings}>
              {currentOfferings.map(currentOffering => (
                <CardOfferingBlock
                  key={currentOffering.ProductId}
                  cardOffering={currentOffering}
                />
              ))}
            </Slider>
          ) : null}
          <UpcomingOfferings />
          <LiveOfferings />
        </div>
      </BreadCrumbsLayout>
    </React.Fragment>
  );
};

OfferingsPageComponent.propTypes = {
  currentOfferings: PropTypes.array,
  getTokens: PropTypes.func,
  getAvailableTokens: PropTypes.func,
  offeringIds: PropTypes.array,
  availableTokens: PropTypes.object
};

OfferingsPageComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const currentOfferings = selectCurrentOfferingsWithAvailability(state);
  const offeringIds = selectOfferingIds(state);

  return {
    currentOfferings,
    offeringIds,
    availableTokens: availableTokensSelector(state)
  };
};

const selectCurrentOfferingsWithAvailability = selectOfferingsWithAvailability(
  currentOfferingSelector
);

const selectOfferingIds = createSelector(
  [state => state.productAttributes],
  allProductAttributes => {
    return Object.values(allProductAttributes)
      .filter(productAttributes => checkIsOffering(productAttributes))
      .map(offeringAttributes => offeringAttributes.ProductId)
      .filter(productId => productId);
  }
);

const mapDispatchToProps = dispatch => ({
  getAvailableTokens: params => dispatch(getAvailableTokens(params))
});

export const OfferingsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferingsPageComponent);
