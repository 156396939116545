import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import './styles.css';

const baseClass = getBEMClasses('co-own-page');

const DescriptionBlock = props => {
  const { title, descriptions } = props;
  return (
    <div className={baseClass('description-block-wrapper')}>
      <div className={baseClass('description-block-title')}>{title}</div>
      {descriptions.map((item, index) => (
        <div key={index} className={baseClass('description-block-text')}>
          {item}
        </div>
      ))}
    </div>
  );
};

DescriptionBlock.propTypes = {
  title: PropTypes.string,
  descriptions: PropTypes.arrayOf(PropTypes.string)
};

export { DescriptionBlock };
