import { createSelector } from 'reselect';

export const selectAllNotifications = createSelector(
  [state => state.notifications.notifications],
  notifications => {
    return notifications.filter(i => !i.deletedAt);
  }
);

export const selectDeletedNotifications = createSelector(
  [state => state.notifications.notifications],
  notifications => {
    return notifications.filter(i => i.deletedAt);
  }
);

export const selectUnseenNotificationsCount = createSelector(
  [state => state.notifications.notifications],
  notifications => {
    return notifications.filter(i => !i.isSeen).length;
  }
);

export const selectGlobalNotification = createSelector(
  [state => state.notifications.globalNotification],
  globalNotification => {
    return !globalNotification ? {} : globalNotification;
  }
);
