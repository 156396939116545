import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './Checkbox.css';

export const CheckBox = props => {
  const { value, handleClick, handleChange, customClass, children, id } = props;

  const bemClasses = getBEMClasses('custom-checkbox', customClass);

  return (
    <div className={bemClasses()} onClick={handleClick}>
      <div className={bemClasses('input')}>
        <input
          type="checkbox"
          id={id ? id : '_checkbox'}
          checked={value}
          className={bemClasses('checkbox')}
          onChange={handleChange}
        />
        <label htmlFor={id ? id : '_checkbox'}>
          <div id="tick_mark" />
        </label>
      </div>

      <div className={bemClasses('children')}>{children}</div>
    </div>
  );
};

CheckBox.propTypes = {
  value: PropTypes.bool,
  handleClick: PropTypes.func,
  customClass: PropTypes.string,
  children: PropTypes.element,
  id: PropTypes.string,
  handleChange: PropTypes.func
};
