import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import '../styles/components/description.css';

const Description = props => {
  const { paragraphs = [] } = props;
  let formattedParagraphs = null;
  const isDescriptionFromConstant = isArray(paragraphs);
  if (isDescriptionFromConstant) {
    formattedParagraphs = paragraphs.map((value, index) => {
      return (
        <p key={index} className="paragraph-wrapper">
          {value}
        </p>
      );
    });
  } else {
    formattedParagraphs = paragraphs;
  }

  return (
    <div className="description">
      <p className="description-title">Description</p>
      {isDescriptionFromConstant ? (
        formattedParagraphs
      ) : (
        <div
          className="paragraph-wrapper"
          dangerouslySetInnerHTML={{ __html: formattedParagraphs }}
        />
      )}
    </div>
  );
};

Description.propTypes = {
  paragraphs: PropTypes.string
};

export default Description;
