import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';

import '../../../styles/components/common/Buttons.css';

const styleNames = {
  general: 'general',
  additive: 'additive',
  subtractive: 'subtractive'
};

const styleNamePropType = PropTypes.oneOf([
  styleNames.general,
  styleNames.additive,
  styleNames.subtractive
]);

const APButton = props => {
  const {
    children,
    styleName,
    baseClass,
    customClass,
    classModifiers,
    disabled,
    dataTest,
    buttonRef,
    ...otherProps
  } = props;

  const bemClasses = getBEMClasses(
    baseClass,
    ...(customClass ? customClass.split(' ') : []), // allow a string with multiple classes
    classModifiers
  );

  return (
    <button
      ref={buttonRef}
      disabled={disabled}
      data-test={dataTest}
      className={bemClasses('btn', [styleName, classModifiers])}
      {...otherProps}>
      {children}
    </button>
  );
};

APButton.defaultProps = {
  name: '',
  value: '',
  type: '',
  autoFocus: false,
  disabled: false,
  baseClass: 'ap-button',
  styleName: styleNames.general
};

APButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  baseClass: PropTypes.string,
  customClass: PropTypes.string,
  styleName: styleNamePropType,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
    PropTypes.node
  ]),
  classModifiers: PropTypes.string,
  dataTest: PropTypes.string,
  buttonRef: PropTypes.any
};

APButton.contextTypes = {
  t: PropTypes.func.isRequired
};

export default APButton;
