import React from 'react';
import { connect } from 'react-redux';
import { selectIsDotsVerificationFinished } from '../../../../../redux/selectors/userConfigSelector';
import { DotsVerification } from '../../Verifications/dots/DotsVerification';
import { setIsDotsVerificationFinished } from '../../../../../redux/actions/userActions';

function withDotsAuth(Component) {
  const wrapped = props => {
    const { isDotsVerificationFinished, setIsDotsVerificationFinished } = props;

    if (!isDotsVerificationFinished) {
      return (
        <DotsVerification
          setIsDotsVerificationFinished={setIsDotsVerificationFinished}
        />
      );
    }

    return <Component {...props} />;
  };

  wrapped.displayName = `withDotsAuth(${Component.displayName ||
    Component.name ||
    'Component'})`;

  const mapStateToProps = state => ({
    isDotsVerificationFinished: selectIsDotsVerificationFinished(state)
  });

  const mapDispatchToProps = {
    setIsDotsVerificationFinished
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(wrapped);
}

export { withDotsAuth };
