import React, { useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { numberToLocale } from '../../helpers/numberFormatter';
import { getPublicAssetAttributes } from '../../api/liquidApi';
import BigNumber from 'bignumber.js';
import OfferingComponent from './components/Offering';
import BuyOutModal from './components/BuyOutModal';
import BuyOutResultModal from './components/BuyOutModal/BuyOutResultModal/BuyOutResultModal';
import BuyOutConfirmationModal from './components/BuyOutModal/BuyOutConfirmationModal/BuyOutConfirmationModal';
import { ASSETS } from '../../constants/assets';
import './AssetDetails.css';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

const classes = getBEMClasses('asset-details');

const AssetDetailsComponent = props => {
  const {
    assetAttributes,
    ppt,
    assetId,
    isUseZeroPPT,
    isStandalone = false,
    isCustomPptAsset,
    productShortName,
    setAssetAttributes
  } = props;
  const [isFetching, setIsFetching] = useState(true);
  const {
    ProductDetailsNumber = '000',
    ProductDetailsTitle = '',
    ProductTotalSize,
    ProductRarity,
    Grade,
    AssetDetailsVideoMobile,
    AssetDetailsVideoDesktop,
    AssetDetailsImageInsteadVideo,
    AssetDetailsTopOwnersBackgroundImage,
    AssetDetailsTitle,
    AssetDetailsText,
    AssetDetailsGradientColor1,
    AssetDetailsGradientColor2,
    AssetDetailsNumberColor,
    AssetDetailsMinimalBuyOutPrice,
    AssetDetailsIsCoOwn
  } = assetAttributes;

  useEffect(() => {
    setTimeout(() => {
      getPublicAssetAttributes(assetId)
        .then(attributes => {
          setAssetAttributes(assetId, attributes);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }, 300);
  }, []);

  const assetImages = useMemo(
    () => {
      const tempAssetImages = [];
      Object.entries(assetAttributes).forEach(entry => {
        if (
          entry[0].startsWith('AssetDetailsImage') &&
          !entry[0].startsWith('AssetDetailsImageInsteadVideo')
        ) {
          tempAssetImages.push(entry[1]);
        }
      });
      return tempAssetImages;
    },
    [assetAttributes]
  );

  const getAssetNumbers = assetData => {
    const tokenSupplyBigNumber = new BigNumber(ProductTotalSize);
    const pptBigNumber = isCustomPptAsset
      ? new BigNumber(assetData.ppt)
      : isUseZeroPPT
        ? new BigNumber(0.1)
        : new BigNumber(ppt);
    const marketValue =
      !isNaN(pptBigNumber) &&
      tokenSupplyBigNumber.times(pptBigNumber).toNumber();

    return {
      tokenSupply: numberToLocale(ProductTotalSize),
      population: ProductRarity,
      grade: Grade || '',
      ppt: isCustomPptAsset ? assetData.ppt : isUseZeroPPT ? '0.10' : ppt,
      marketValue: numberToLocale(marketValue)
    };
  };

  const onBuyOutClick = useCallback(() => props.onBuyOutClick(assetId), [
    assetId
  ]);

  if (!assetId) {
    // TODO: what to show if three are no name for particular asset
    return null;
  }
  const assetData = AssetDetailsText
    ? {
        title: AssetDetailsTitle || '',
        description: AssetDetailsText || '',
        gradientColors: [
          AssetDetailsGradientColor1,
          AssetDetailsGradientColor2
        ],
        numberColor: AssetDetailsNumberColor,
        minimalBuyOutPrice: AssetDetailsMinimalBuyOutPrice || 0,
        isCoOwn: AssetDetailsIsCoOwn,
        fromAdmin: true
      }
    : ASSETS.find(asset => asset.id === assetId) || {
        title: '',
        description: '',
        gradientColors: ['', ''],
        numberColor: '',
        minimalBuyOutPrice: 0,
        isCoOwn: false
      };

  assetData.fullName = ProductDetailsTitle;
  assetData.assetNumber = ProductDetailsNumber;
  const assetNumbers = getAssetNumbers(assetData);
  const assetImagesAndVideos = {
    assetDetailsVideoMobile: AssetDetailsVideoMobile,
    assetDetailsVideoDesktop: AssetDetailsVideoDesktop,
    assetDetailsImageInsteadVideo: AssetDetailsImageInsteadVideo,
    assetDetailsTopOwnersBackgroundImage: AssetDetailsTopOwnersBackgroundImage,
    assetImages
  };

  return (
    <div className={classes()}>
      {isFetching ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <OfferingComponent
            assetData={{
              ...assetData,
              ...assetImagesAndVideos,
              assetId,
              productShortName
            }}
            assetNumbers={assetNumbers}
            useOriginalMarket={isUseZeroPPT || isCustomPptAsset}
            isStandalone={isStandalone}
            onBuyOutClick={onBuyOutClick}
          />
          <BuyOutModal />
          <BuyOutResultModal />
          <BuyOutConfirmationModal />
        </React.Fragment>
      )}
    </div>
  );
};

AssetDetailsComponent.propTypes = {
  assetAttributes: PropTypes.object,
  ppt: PropTypes.number,
  assetId: PropTypes.number,
  isUseZeroPPT: PropTypes.bool,
  isStandalone: PropTypes.bool,
  onBuyOutClick: PropTypes.func,
  isCustomPptAsset: PropTypes.bool,
  productShortName: PropTypes.string,
  setAssetAttributes: PropTypes.func
};

export { AssetDetailsComponent };
