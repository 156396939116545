import React from 'react';
import PropTypes from 'prop-types';
import APTable from '../../common/APTable/APTable';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { getOrderPriceToDisplay } from 'apex-web/lib/helpers/orderHistoryHelper';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

const baseClass = getBEMClasses('order-history-table');

class OrderHistoryTradeReportsComponent extends React.Component {
  componentDidMount() {
    if (this.props.selectedAccountId) {
      this.props.getOpenTradeReports(
        this.props.selectedAccountId,
        this.props.OMSId,
        this.props.config.maxLines,
        0
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedAccountId !== this.props.selectedAccountId) {
      this.props.getOpenTradeReports(
        this.props.selectedAccountId,
        this.props.OMSId,
        this.props.config.maxLines,
        0
      );
    }
  }

  render() {
    const { context, props } = this;
    const { data, instruments, config, fetching, selectedInstrument } = props;
    const { maxLines, usePagination } = config;
    const rowProps = row => ({
      'data-tip': true,
      'data-for': `orderInfo-${row.OrderId}`,
      selectedInstrument:
        selectedInstrument ||
        instruments.find(i => i.InstrumentId === row.InstrumentId) ||
        {}
    });

    const columns = [
      {
        header: context.t('Pair'),
        dataTest: 'Pair',
        cell: (row, { selectedInstrument }) =>
          selectedInstrument ? selectedInstrument.Symbol : ''
      },
      {
        header: context.t('Side'),
        dataTest: 'Side',
        cell: row => (
          <div className={baseClass(row.Side === 'Buy' ? 'buy' : 'sell')}>
            {row.Side}
          </div>
        )
      },
      {
        header: context.t('Size'),
        dataTest: 'Size',
        cell: (row, { selectedInstrument }) => (
          <div className={baseClass('size')}>
            {row.Quantity &&
              formatNumberToLocale(
                row.Quantity,
                convertIncrementToIntDecimalPlaces(
                  selectedInstrument.QuantityIncrement
                )
              )}
          </div>
        )
      },
      {
        header: context.t('Price'),
        dataTest: 'Price',
        cell: (row, { selectedInstrument }) => (
          <div className={baseClass('price')}>
            {getOrderPriceToDisplay(
              row,
              convertIncrementToIntDecimalPlaces(
                selectedInstrument.PriceIncrement
              )
            )}
          </div>
        )
      },
      {
        header: context.t('Fee'),
        dataTest: 'Fee',
        cell: row => formatNumberToLocale(row.Fee, row.Fee.symbol)
      },
      {
        header: context.t('Date/Time'),
        dataTest: 'Date/Time',
        cell: row => formatDateTime(row.ReceiveTime || row.TradeTimeMS)
      },
      {
        header: context.t('Status'),
        dataTest: 'Status',
        cell: () => context.t('Complete')
      }
    ];

    return (
      <APTable
        {...{
          columns,
          rowProps,
          rows: data,
          totalPages: data.length,
          baseClass,
          fetching,
          pageSize: maxLines,
          usePagination,
          alwaysShowPagination: true,
          useSimplePagination: true,
          empty: context.t('No data is available'),
          componentName: 'OrderHistoryTradeReportsComponent',
          onRequestPrev: () => {
            this.props.getOpenTradeReports(
              this.props.selectedAccountId,
              this.props.OMSId,
              this.props.config.maxLines,
              this.props.tradeReportsStartIndex - this.props.config.maxLines
            );
          },
          onRequestNext: () => {
            this.props.getOpenTradeReports(
              this.props.selectedAccountId,
              this.props.OMSId,
              this.props.config.maxLines,
              this.props.tradeReportsStartIndex + this.props.config.maxLines
            );
          }
        }}
      />
    );
  }
}

/*
 this.context.t('Trade Reports');
 */
OrderHistoryTradeReportsComponent.title = 'Trade Reports';

OrderHistoryTradeReportsComponent.propTypes = {
  data: PropTypes.array.isRequired,
  instruments: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  cancelOrders: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  selectedAccountId: PropTypes.number,
  OMSId: PropTypes.number,
  getOpenTradeReports: PropTypes.func,
  selectedInstrument: PropTypes.any,
  tradeReportsStartIndex: PropTypes.number
};

OrderHistoryTradeReportsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default OrderHistoryTradeReportsComponent;
