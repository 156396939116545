import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

import {
  selectDigitalAssetsByIdWithAttributes,
  productPositionByIdSelector
} from '../../redux/selectors/productsSelector';
import { selectIsAssetInWatchList } from '../../redux/selectors/cardSelector';
import { BreadCrumbsLayout } from '../../layouts/BreadCrumbsLayout/BreadCrumbsLayoutContainer';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { AssetHeaderContainer } from '../../components/AssetHeader/AssetHeaderContainer';
import { setCurrentAssetId } from '../../redux/actions/assetActions';
import AssetDetails from '../../components/AssetDetails/AssetDetailsContainer';

import './MarketPlaceAssetDetailsPage.css';

const baseClasses = getBEMClasses('market-place-asset-details-page');

const MarketPlaceAssetDetailsPageComponent = props => {
  const {
    assetDetails,
    isFavorite,
    myProductTokens = 0,
    setCurrentAssetId,
    assetIdFromUrl
  } = props;
  const [isReady, setIsReady] = useState(false);

  useEffect(
    () => {
      setCurrentAssetId(assetIdFromUrl);
      setIsReady(true);
    },
    [assetIdFromUrl]
  );

  return isReady ? (
    <BreadCrumbsLayout customClass={baseClasses('breadcrumbs')}>
      <AssetHeaderContainer
        assetDetails={assetDetails}
        myProductTokens={myProductTokens}
        isFavorite={isFavorite}
      />
      <div className={baseClasses()}>
        <AssetDetails />
      </div>
    </BreadCrumbsLayout>
  ) : <Spinner customClass={baseClasses()} isInline />;
};

MarketPlaceAssetDetailsPageComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      assetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  assetDetails: PropTypes.object,
  myProductTokens: PropTypes.number,
  isFavorite: PropTypes.bool,
  currentPriceInBaseCurrency: PropTypes.string,
  setCurrentAssetId: PropTypes.func,
  assetIdFromUrl: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: { params }
  } = ownProps;
  const assetId = state.asset.currentAssetId || Number(params.assetId);
  const assetDetails = selectDigitalAssetsByIdWithAttributes(assetId)(state);
  const productPosition = productPositionByIdSelector(assetId)(state);
  const isFavorite = selectIsAssetInWatchList(state, assetId);

  return {
    assetDetails,
    isFavorite,
    myProductTokens: productPosition.Amount,
    assetIdFromUrl: Number(params.assetId)
  };
};

const mapDispatchToProps = {
  setCurrentAssetId
};

const MarketPlaceAssetDetailsPage = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MarketPlaceAssetDetailsPageComponent)
);

export { MarketPlaceAssetDetailsPage };
