import { AUTH_SUCCESS } from 'apex-web/lib/redux/actions/authActions';
import { authUserLiquid } from '../actions/liquidAuthActions';
import { USER_CONFIG_FETCHED } from 'apex-web/lib/redux/actions/userActions';
import { resolveUserCountry, setIsUserConfigReceived } from '../actions/userActions';

export const liquidLoginMiddleware = store => next => action => {
  const { dispatch } = store;

  if (action.type === AUTH_SUCCESS) {
    dispatch(authUserLiquid(action.payload));
    // Successful payload does not have an error message, but apex authReducer 
    // combines an existing state with the payload. Because of that once errorMsg
    // is set in the store it can never be cleared. To fix it we manually set errorMsg
    // in the successful action payload to null.
    action.payload.errorMsg = null
  }

  if (action.type === USER_CONFIG_FETCHED) {
    setTimeout(() => {
      dispatch(setIsUserConfigReceived());
    }, 0);
    dispatch(resolveUserCountry())
  }

  return next(action);
};
