import { connect } from 'react-redux';
import { change } from 'redux-form';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';

import {
  isProductByDefaultScenario,
  isProductByScriptScenario
} from '../../redux/selectors/productsSelector';
import ControlledBuySellTabsComponent from './ControlledBuySellTabsComponent';

const mapStateToProps = (state) => {
  const selectedInstrument = selectedInstrumentSelector(state);
  const { verificationLevel } = kycVerificationLevelsSelector(state);
  const onlyMarketAvailable = isProductByDefaultScenario(
    state,
    selectedInstrument.Product1
  );
  const onlyLimitAvailable = isProductByScriptScenario(
    state,
    selectedInstrument.Product1
  );
  const isSellDisable = onlyMarketAvailable || onlyLimitAvailable;

  return {
    isSellDisable: verificationLevel === 99 ? false : isSellDisable
  };
};

const mapDispatchToProps = {
  resetQuantity: () => change('orderEntry', 'quantity', '')
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlledBuySellTabsComponent);
