export const LIGHT_THEME = 'light';
export const DARK_THEME = 'dark';

export const getCurrentTheme = () => {
  const apexTemplate = JSON.parse(localStorage.getItem('apexTemplate'));

  return apexTemplate ? apexTemplate.theme : LIGHT_THEME;
};

export const isLightTheme = () => {
  const apexTemplate = JSON.parse(localStorage.getItem('apexTemplate'));
  if (apexTemplate && apexTemplate.theme === LIGHT_THEME) {
    return true;
  }
  return false;
};

export const isDarkTheme = () => {
  const apexTemplate = JSON.parse(localStorage.getItem('apexTemplate'));
  if (apexTemplate && apexTemplate.theme === DARK_THEME) {
    return true;
  }
  return false;
};
