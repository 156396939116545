import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { Modal } from '../common/Modal/Modal';
import base64 from 'base-64';
import utf8 from 'utf8';
import './KYCModal.css';

const baseClasses = getBEMClasses('kyc-modal');

const KYCModal = (props, context) => {
  const { isOpen, close, userEmail } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => {
      if (isOpen) {
        setIsLoading(true);
      }
    },
    [isOpen]
  );

  const utf8Email = utf8.encode(`email=${userEmail}`);
  const base64Email = base64.encode(utf8Email);

  return (
    <Modal
      customClass={baseClasses()}
      isOpen={isOpen}
      titleComponent={
        <div className={baseClasses('header')}>
          <div className={baseClasses('header-image')} />
          <div className={baseClasses('header-title')}>{context.t('KYC')}</div>
          <button
            className={baseClasses('header-close-button')}
            onClick={close}>
            {context.t('close')}
            <div className={baseClasses('header-close-button-icon')} />
          </button>
        </div>
      }
      onCancel={() => {
        close();
      }}
      close={close}>
      <div className={baseClasses()}>
        {isLoading && (
          <div className={baseClasses('loader')}>
            <img
              src="https://cd1st.identitymind.com/spinner.gif"
              alt="spinner"
            />
          </div>
        )}
        <iframe
          id={'acuant'}
          style={{ height: '600px', width: '100%', minWidth: '500px' }}
          title="KYC"
          src={`https://go.acuant.com/viewform/46388/?data=${base64Email}`}
          onLoad={() => {
            setIsLoading(false);
          }}
        />
      </div>
    </Modal>
  );
};

KYCModal.contextTypes = {
  t: PropTypes.func.isRequired
};

KYCModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userEmail: PropTypes.string
};

export default KYCModal;
