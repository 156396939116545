import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import LoginFormComponent from './LoginFormComponent';
import {
  login,
  AUTH_2FA_REQUIRED,
  ENABLE_2FA_REQUIRED
} from 'apex-web/lib/redux/actions/authActions';
import path from 'apex-web/lib/helpers/path';
import { withRouter } from 'react-router';
import { initApex, setGateway } from 'apex-web/lib/apex';
import {
  connectionClosed,
  connectionClosedCount
} from 'apex-web/lib/redux/actions/wsConnectionActions';
import { loadStartupData } from 'apex-web/lib/redux/actions/appActions';
import { onInit } from 'apex-web/lib/redux/actions/lifeCycleActions';

const mapStateToProps = state => ({
  errorMsg: state.auth.errorMsg,
  isConnected: state.wsConnection.isConnected,
  form: state.form.login ? state.form.login.values : {}
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  doSubmit: (payload, isConnected) => {
    if (!isConnected || !navigator.onLine) {
      setGateway(payload.gateway);
      localStorage.setItem('tradingServer', payload.gateway);
      initApex(
        () => {
          dispatch(login(payload)).then(action => {
            dispatch(connectionClosedCount(0));
            dispatch(loadStartupData());
            dispatch(onInit());

            if (action.type === ENABLE_2FA_REQUIRED) {
              ownProps.history.push(path('/enabletwofactorauth'));
            } else if (action.type === AUTH_2FA_REQUIRED) {
              ownProps.history.push(path('/twofactorauth'));
            }
          });
        },
        () => dispatch(connectionClosed())
      );
      return;
    }

    return dispatch(login(payload)).then(action => {
      if (action.type === ENABLE_2FA_REQUIRED) {
        ownProps.history.push(path('/enabletwofactorauth'));
      } else if (action.type === AUTH_2FA_REQUIRED) {
        ownProps.history.push(path('/twofactorauth'));
      }
    });
  }
});

const LoginFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormComponent);

const LoginFormContainerForm = reduxForm({
  form: 'login',
  onSubmit: () => {}
})(LoginFormContainer);

export default withRouter(LoginFormContainerForm);
