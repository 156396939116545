export const getBrowserHome = () => {
  const userAgent = window.navigator.userAgent;
  let browserName = ''
  if(userAgent.match(/chrome|chromium|crios/i)){
    browserName = "chrome";
  }else if(userAgent.match(/firefox|fxios/i)){
    browserName = "firefox";
  }  else if(userAgent.match(/safari/i)){
    browserName = "safari";
  }else if(userAgent.match(/opr\//i)){
    browserName = "opera";
  } else if(userAgent.match(/edg/i)){
    browserName = "edge";
  }else{
    browserName="No browser detection";
  }

  return browserName 
}


export const selectIsMac = () => {
  const userAgent = window.navigator.userAgent;
  return userAgent.includes('Macintosh')
}

export const selectIsChromeOnMac = () => {
  const browserName = getBrowserHome()
  const isMac = selectIsMac()

  return browserName === 'chrome' && isMac
}

export const selectIsSafariOnMac = () => {
  const browserName = getBrowserHome()
  const isMac = selectIsMac()

  return browserName === 'safari' && isMac
}