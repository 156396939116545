import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import PropTypes from 'prop-types';
import { createDotsFlow } from '../../../../../api/liquidApi';
import React, { useState, useEffect } from 'react';
import './DotsVerification.css';
import { getDotsIframeStyles } from '../../helpers/dotsHelper';

const DotsVerification = props => {
  const { setIsDotsVerificationFinished } = props;
  const [dotsFlowUrl, setDotsFlowUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    createDotsFlow('onboarding').then(data => {
      setDotsFlowUrl(data.link);
    });
  }, []);

  const stylesConfig = getDotsIframeStyles();

  return (
    <div className="dots">
      {isLoading && (
        <div className="dots-spinner">
          <Spinner />
        </div>
      )}
      {dotsFlowUrl && (
        <iframe
          id="dots-flow"
          src={`${dotsFlowUrl}?styles=${stylesConfig}`}
          height={600}
          width={'100%'}
          title="dots"
          onLoad={() => {
            if (isLoading) {
              setIsLoading(false);
            } else {
              setIsDotsVerificationFinished();
            }
          }}
        />
      )}
    </div>
  );
};

DotsVerification.propTypes = {
  setIsDotsVerificationFinished: PropTypes.func
};

export { DotsVerification };
