import axios from 'axios';
import { logout } from 'apex-web/lib/redux/actions/authActions';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { maintenanceStateChanged } from '../redux/actions/userActions';

export const API_URL = 'https://api.liquidmarketplace.org';

export const axiosAuth = axios.create({ baseURL: API_URL });

/**
 * Initializes axios interceptors using redux store.
 * IMPORTANT: this function helps to avoid circular module dependencies, which
 * were previously caused by importing "store" into this module. Circular dependency
 * can and some day WILL lead to css override, so we should not let this happen.
 * @param store
 */
export const initAxiosInterceptors = store => {
  axiosAuth.interceptors.request.use(async config => {
    let userId = store.getState().user.userInfo.UserId;

    //if userId does not exist we launch setInterval
    //and wait 20 retries (with 500ms delay) or when userId will be defined
    //if all retries are finished, then will be throw error
    if (!userId) {
      let intervalId;
      let countRetry = 0;
      await new Promise(resolve => {
        intervalId = setInterval(() => {
          if (++countRetry === 20 || store.getState().user.userInfo.UserId) {
            resolve('');
          }
        }, 500);
      });
      clearInterval(intervalId);
      userId = store.getState().user.userInfo.UserId;
    }

    //manual setting has higher priority, if already set this tokens, then next headers will skip
    if (!config.headers.authorization) {
      config.headers.authorization = localStorage.getItem('token') || '';
    }
    if (!config.headers.userid) {
      config.headers.userid = userId;

      if (!userId) {
        store.dispatch(
          showSnack({
            id: 'liquidAuthError',
            text: 'Error: Can not detect usedId',
            type: 'warning'
          })
        );
      }
    }

    return config;
  });

  axiosAuth.interceptors.response.use(
    response => response,
    async error => {
      let isMaintenance = false;
      if (error.response && error.response.status === 401) {
        try {
          const originalRequest = error.config;
          await new Promise(resolve => {
            setTimeout(() => resolve(''), 1000);
          });
          originalRequest.headers.authorization =
            localStorage.getItem('token') || '';
          return await axios(originalRequest);
        } catch (error) {
          console.log('logout user');
          store.dispatch(
            showSnack({
              id: 'notAuthorized',
              text:
                'Your token is expired. Please authorise to continue application using.',
              timeout: 5000,
              type: 'warning'
            })
          );
          setTimeout(() => store.dispatch(logout()), 5000);
        }
      } else if (error.response) {
        if (error.response.status === 503) {
          isMaintenance = true;
        }
        if (error.response.data) {
          const { errMsg, message } = error.response.data;
          if (errMsg || message) {
            store.dispatch(
              showSnack({
                id: 'api error',
                text: errMsg || message,
                timeout: 5000,
                type: 'warning'
              })
            );
          }
        }
      }
      if (store.getState().userConfig.isMaintenance !== isMaintenance) {
        store.dispatch(maintenanceStateChanged(isMaintenance));
      }

      return Promise.reject(error);
    }
  );
};
