export const SET_SPOTLIGHT_ID = 'SET_SPOTLIGHT_ID';

export const setSpotlightId = (spotlightId) => ({
  type: SET_SPOTLIGHT_ID,
  payload: spotlightId
});

export const SPOTLIGHT_IDS = {
  ADD_FUNDS: 'ADD_FUNDS'
};
