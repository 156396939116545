import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import APIcon from '../APIcon';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { generateNumberRangeArray } from 'apex-web/lib/helpers/paginationHelper';

import './Pagination.css';

const PaginationComponent = (props, context) => {
  const {
    content,
    activePage = 0,
    setActivePage,
    customClass = '',
    totalPages,
    maxPages,
    showOnlyActivePage,
    containerClassName = ''
  } = props;
  const currentContent = useMemo(
    () => {
      return content(activePage);
    },
    [content, activePage]
  );
  const length = maxPages > totalPages ? totalPages : maxPages;

  useEffect(
    () => {
      if (activePage > 0 && currentContent.length === 0) {
        setActivePage(activePage - 1);
      }
    },
    [activePage, currentContent.length]
  );

  const getClasses = () => {
    return getBEMClasses(['pagination', `${customClass}-pagination`]);
  };

  const paginationClasses = useMemo(() => getClasses(), [customClass]);

  const goToPage = (event, page) => {
    event.preventDefault();
    setActivePage && setActivePage(page);
  };

  const renderNextButtons = page => {
    const disabled = totalPages === 0 || page === totalPages - 1;
    return (
      <span className={paginationClasses('next')}>
        <a
          className={paginationClasses('text', 'next')}
          style={{ opacity: disabled ? 0.5 : 1 }}
          onClick={e => !disabled && goToPage(e, page + 1)}>
          {context.t('Next')}
          <APIcon
            name="caretNext"
            customClass={paginationClasses('arrow', 'next')}
          />
        </a>
        <a
          className={paginationClasses('text', 'next')}
          style={{ opacity: disabled ? 0.5 : 1 }}
          onClick={e => !disabled && goToPage(e, totalPages - 1)}>
          <APIcon
            name="doubleCaretNext"
            customClass={paginationClasses('arrow', 'next')}
          />
        </a>
      </span>
    );
  };

  const renderPreviousButtons = page => {
    const disabled = totalPages === 0 || page === 0;
    return (
      <span className={paginationClasses('previous')}>
        <a
          className={paginationClasses('text', 'prev')}
          style={{ opacity: disabled ? 0.5 : 1 }}
          onClick={e => !disabled && goToPage(e, 0)}>
          <APIcon
            name="doubleCaretPrevious"
            customClass={paginationClasses('arrow', 'previous')}
          />
        </a>
        <a
          className={paginationClasses('text', 'prev')}
          style={{ opacity: disabled ? 0.5 : 1 }}
          onClick={e => !disabled && goToPage(e, page - 1)}>
          <APIcon
            name="caretPrevious"
            customClass={paginationClasses('arrow', 'previous')}
          />
          {context.t('Prev')}
        </a>
      </span>
    );
  };

  const pages = generateNumberRangeArray(length, activePage, totalPages).map(
    i => (
      <a
        key={i}
        className={paginationClasses(
          'page',
          activePage === i ? 'active' : null
        )}
        onClick={e => goToPage(e, i)}>
        {i + 1}
      </a>
    )
  );

  return (
    <div className={containerClassName}>
      <div className={paginationClasses('content')}>{currentContent}</div>
      <div className={paginationClasses('container')}>
        {renderPreviousButtons(activePage)}
        {showOnlyActivePage ? (
          <div className={paginationClasses('pages')}>
            <div className={paginationClasses('page')}>{activePage + 1}</div>
          </div>
        ) : (
          <div className={paginationClasses('pages')}>{pages}</div>
        )}
        {renderNextButtons(activePage)}
      </div>
    </div>
  );
};

PaginationComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

PaginationComponent.propTypes = {
  totalPages: PropTypes.number.isRequired,
  content: PropTypes.func.isRequired,
  activePage: PropTypes.number,
  containerClassName: PropTypes.string,
  componentName: PropTypes.string,
  customClass: PropTypes.string,
  setActivePage: PropTypes.func,
  maxPages: PropTypes.number,
  showOnlyActivePage: PropTypes.bool
};

export { PaginationComponent };
