export const shareLink = async url => {
  if (typeof navigator === 'undefined' || !navigator.share) {
    navigator.clipboard.writeText(url);
    return;
  }

  await navigator.share({
    title: 'Share',
    text: '',
    url: url
  });
};