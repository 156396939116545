import { createSelector } from 'reselect';
import { selectBaseCurrencyDecimalPlaces } from './decimalPlacesSelector';
import isEmpty from 'lodash/isEmpty';
import { positionSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import get from 'lodash/get';
import BigNumber from 'bignumber.js';
import { formatNumberToLocale } from '../../helpers/numberFormatter';
import { selectIsSuperuser } from './userConfigSelector';

export const selectInstrumentsWithPricesInBaseCurrency = createSelector(
  [state => state.instrument.instruments, state => state.level1],
  (instruments, level1) => {
    return instruments.map(item => {
      return {
        ...item,
        BestBid: get(level1[item.InstrumentId], 'BestBid', 0),
        BestOffer: get(level1[item.InstrumentId], 'BestOffer', 0),
        LastTradedPx: get(level1[item.InstrumentId], 'LastTradedPx', 0),
        Rolling24HrPxChangePercent: get(
          level1[item.InstrumentId],
          'Rolling24HrPxChangePercent',
          0
        ),
        Rolling24NumTrades: get(
          level1[item.InstrumentId],
          'Rolling24NumTrades',
          0
        ),
        CurrentDayVolume: get(level1[item.InstrumentId], 'CurrentDayVolume', 0)
      };
    });
  }
);

export const selectInstrumentsWithLevel1Data = createSelector(
  [state => state.instrument.instruments, state => state.level1],
  (instruments, level1) => {
    return instruments.map(instr => {
      return {
        ...instr,
        level1: level1[instr.InstrumentId]
      };
    });
  }
);

const productsSelector = createSelector(
  [
    state => state.product.products,
    state => state.productAttributes,
    selectIsSuperuser
  ],
  (products, productsAttributes, isSuperuser) => {
    if (isEmpty(productsAttributes)) {
      return products;
    } else {
      return products.filter(product => {
        const productAttributes =
          Object.values(productsAttributes).find(
            item => item.ProductId === product.ProductId
          ) || {};
          return productAttributes.isHidden &&
            productAttributes.isHidden === 'true' &&
            !isSuperuser
            ? false
            : true;
      });
    }
  }
);

export const selectBaseAssetBalance = createSelector(
  [positionSelector, selectBaseCurrencyDecimalPlaces],
  (positions, baseCurrencyDecimalPlaces) => {
    const baseAssetPosition = Object.values(positions).find(
      item => item.ProductSymbol === 'USD'
    );
    if (baseAssetPosition) {
      const Amount = new BigNumber(baseAssetPosition.Amount);
      const Hold = new BigNumber(
        baseAssetPosition.Hold
      );
      const AvailableBalance = Amount.minus(Hold);
      return (
        {
          ...baseAssetPosition,
          AvailableBalance: formatNumberToLocale(
            AvailableBalance,
            baseCurrencyDecimalPlaces
          )
        } || {}
      );
    } else return {};
  }
);


export const selectPositionsWithPredicate = createSelector(
  [
    positionSelector,
    productsSelector,
    state => state.productAttributes,
    (state, options) => options.predicate
  ],
  (positions, products, productsAttributes, predicate = () => true) => {
    return Object.values(positions)
      .filter(item => {
        const product = products.find(
          product => product.ProductId === item.ProductId
        );
        const attributes = Object.values(productsAttributes).find(
          attributes => attributes.ProductId === item.ProductId
        ) || {};

        return !!product && predicate(product, attributes);
      });
  }
);


export const selectAssetsBalanceInBaseCurrencyWithPredicate = createSelector(
  [
    selectPositionsWithPredicate,
    selectInstrumentsWithPricesInBaseCurrency,
    selectBaseCurrencyDecimalPlaces,
    productsSelector,
    state => state.dca.dcas,
    (state, options) => options.useDCA
  ],
  (
    positions,
    instrumentsWithPrices,
    baseCurrencyDecimalPlace,
    products,
    dcas,
    useDCA
  ) => {
    let totalHoldInBaseCurrency = new BigNumber(0);
    let totalAmountInBaseCurrency = new BigNumber(0);
    let totalPendingDeposits = new BigNumber(0);
    let totalPendingWithdraws = new BigNumber(0);
    const assetsWithPrice = positions
      .map(item => {
        const product = products.find(
          product => product.ProductId === item.ProductId
        ) || { ProductFullName: item.ProductSymbol };
        const instrumentByPosition = instrumentsWithPrices.find(
          instrument => instrument.Product1 === item.ProductId
        ) || {
          BestOffer: 1,
          BestBid: 0,
          LastTradedPx: 0,
          Rolling24HrPxChangePercent: 0,
          SessionStatus: 'Running'
        };
        const dca = product.Product === 'USD' ? 1 : get(dcas, [instrumentByPosition.InstrumentId, 'DCA']);
        const baseCurrencyMultiplier = useDCA ? (dca || 0) : instrumentByPosition.BestOffer;
        const holdInBaseCurrency = new BigNumber(item.Hold).times(
          baseCurrencyMultiplier
        );
        totalHoldInBaseCurrency = totalHoldInBaseCurrency.plus(
          holdInBaseCurrency
        );
        const amountInBaseCurrency = new BigNumber(item.Amount).times(
          baseCurrencyMultiplier
        );
        totalAmountInBaseCurrency = totalAmountInBaseCurrency.plus(
          amountInBaseCurrency
        );
        const pendingDeposits = new BigNumber(item.PendingDeposits).times(
          baseCurrencyMultiplier
        );
        totalPendingDeposits = totalPendingDeposits.plus(pendingDeposits);
        const pendingWithdraws = new BigNumber(item.PendingWithdraws).times(
          baseCurrencyMultiplier
        );
        totalPendingWithdraws = totalPendingWithdraws.plus(pendingWithdraws);
        return {
          ...item,
          HoldInBaseCurrency: holdInBaseCurrency,
          AmountInBaseCurrency: amountInBaseCurrency.minus(pendingWithdraws),
          PendingDepositsInBaseCurrency: pendingDeposits,
          AvailableBalanceInBaseCurrency: amountInBaseCurrency.minus(
            pendingWithdraws
          ),
          BestBid: instrumentByPosition.BestBid,
          BestOffer: instrumentByPosition.BestOffer,
          LastTradedPx: instrumentByPosition.LastTradedPx,
          Rolling24HrPxChangePercent:
            instrumentByPosition.Rolling24HrPxChangePercent,
          Rolling24NumTrades: instrumentByPosition.Rolling24NumTrades,
          ProductFullName: product.ProductFullName,
          MarketValue: instrumentByPosition.CurrentDayVolume,
          DCA: dca,
          isMarketRunning: instrumentByPosition.SessionStatus === 'Running'
        };
      });

    const totalAvailableBalance = totalAmountInBaseCurrency.minus(totalPendingWithdraws);
    const assetsWithPriceAndPercent = assetsWithPrice.map(item => {
      const percentOfTotalAmount = item.AmountInBaseCurrency.times(100).div(
        totalAvailableBalance
      );
      return {
        ...item,
        rawData: {
          AmountInBaseCurrency: item.AmountInBaseCurrency,
          HoldInBaseCurrency: item.HoldInBaseCurrency,
          PendingDepositsInBaseCurrency: item.PendingDepositsInBaseCurrency,
          AvailableBalanceInBaseCurrency: item.AvailableBalanceInBaseCurrency,
          PercentOfTotalAmount: item.PercentOfTotalAmount,
          BestBid: item.BestBid,
          BestOffer: item.BestOffer,
        },
        AmountInBaseCurrency: formatNumberToLocale(
          item.AmountInBaseCurrency,
          baseCurrencyDecimalPlace
        ),
        HoldInBaseCurrency: formatNumberToLocale(
          item.HoldInBaseCurrency,
          baseCurrencyDecimalPlace
        ),
        PendingDepositsInBaseCurrency: formatNumberToLocale(
          item.PendingDepositsInBaseCurrency,
          baseCurrencyDecimalPlace
        ),
        AvailableBalanceInBaseCurrency: formatNumberToLocale(
          item.AvailableBalanceInBaseCurrency,
          baseCurrencyDecimalPlace
        ),
        PercentOfTotalAmount: formatNumberToLocale(
          percentOfTotalAmount.dp(2, BigNumber.ROUND_HALF_UP),
        ),
        BestBid: formatNumberToLocale(item.BestBid, baseCurrencyDecimalPlace),
        BestOffer: formatNumberToLocale(
          item.BestOffer,
          baseCurrencyDecimalPlace
        )
      };
    });
    return {
      assetsWithPriceAndPercent,
      rawData: {
        totalHold: totalHoldInBaseCurrency,
        totalAmount: totalAmountInBaseCurrency,
        totalPendingDeposits,
        totalAvailableBalance,
      },
      totalHold: formatNumberToLocale(
        totalHoldInBaseCurrency,
        baseCurrencyDecimalPlace
      ),
      totalAmount: formatNumberToLocale(
        totalAmountInBaseCurrency,
        baseCurrencyDecimalPlace
      ),
      totalPendingDeposits: formatNumberToLocale(
        totalPendingDeposits,
        baseCurrencyDecimalPlace
      ),
      totalAvailableBalance: formatNumberToLocale(
        totalAvailableBalance,
        baseCurrencyDecimalPlace
      )
    };
  }
);

const digitalAssetPredicate = (product, attributes) => {
  return attributes.Type === 'DigitalAsset';
};

const digitalAssetOrUsdPredicate = (product, attributes) => {
  return attributes.Type === 'DigitalAsset' || product.Product === 'USD';
};

export const selectDigitalAssetsBalanceInBaseCurrency = (state, options) =>
  selectAssetsBalanceInBaseCurrencyWithPredicate(state, {
    ...options,
    predicate: digitalAssetPredicate,
    useDCA: true
  });

export const selectAssetsBalanceInBaseCurrency = (state, options) =>
  selectAssetsBalanceInBaseCurrencyWithPredicate(state, {
    ...options,
    predicate: digitalAssetOrUsdPredicate
  });

// TODO(Jul 14, 2022): make selectAssetsBalanceInBaseCurrencyWithPredicate use this selector as a base?
export const selectAssetsWithPrices = createSelector(
  [
    selectInstrumentsWithPricesInBaseCurrency,
    selectBaseCurrencyDecimalPlaces,
    productsSelector,
  ],
  (
    instrumentsWithPrices,
    baseCurrencyDecimalPlace,
    products,
  ) => {
    return products.map(product => {
      const instrumentByPosition = instrumentsWithPrices.find(
        instrument => instrument.Product1 === product.ProductId
      ) || {
        BestOffer: 1,
        BestBid: 0,
        LastTradedPx: 0,
        Rolling24HrPxChangePercent: 0
      };
      const formattedZero = formatNumberToLocale(
        0,
        baseCurrencyDecimalPlace
      );
      return {
        ...product,
        BestBid: formatNumberToLocale(instrumentByPosition.BestBid, baseCurrencyDecimalPlace),
        BestOffer: formatNumberToLocale(instrumentByPosition.BestOffer, baseCurrencyDecimalPlace),
        LastTradedPx: instrumentByPosition.LastTradedPx,
        Rolling24HrPxChangePercent:
          instrumentByPosition.Rolling24HrPxChangePercent,
        Rolling24NumTrades: instrumentByPosition.Rolling24NumTrades,
        ProductFullName: product.ProductFullName,
        MarketValue: instrumentByPosition.CurrentDayVolume,
        // to make sure that nothing crashes
        rawData: {
          AmountInBaseCurrency: 0,
          HoldInBaseCurrency: 0,
          PendingDepositsInBaseCurrency: 0,
          AvailableBalanceInBaseCurrency: 0,
          PercentOfTotalAmount: 0,
          BestBid: instrumentByPosition.BestBid,
          BestOffer: instrumentByPosition.BestOffer,
        },
        AmountInBaseCurrency: formattedZero,
        HoldInBaseCurrency: formattedZero,
        PendingDepositsInBaseCurrency: formattedZero,
        AvailableBalanceInBaseCurrency: formattedZero,
        PercentOfTotalAmount: formattedZero
      };
    });
  }
);

export const selectAssetBalanceInBaseCurrencyById = createSelector(
  [selectAssetsBalanceInBaseCurrency, (state, assetId) => assetId],
  (assetsBalance, assetId) => {
    return (
      assetsBalance.assetsWithPriceAndPercent.find(
        item => item.ProductId === assetId
      ) || { Amount: 0 }
    );
  }
);
