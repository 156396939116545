import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import './InstrumentPositionsComponent.css';

const tradeComponentClasses = getBEMClasses('trade-component');
const instrumentPositionsClasses = getBEMClasses(
  'instrument-positions-summary'
);

const InstrumentPositionsComponent = (props, context) => (
  <div
    className={classnames(
      tradeComponentClasses(),
      instrumentPositionsClasses()
    )}>
    {props.showTitle && (
      <div className={tradeComponentClasses('header')}>
        {props.positions.position1.ProductSymbol}
        {props.positions.position2.ProductSymbol} {context.t('Summary')}
      </div>
    )}
    {props.fetching ? (
      <Spinner />
    ) : (
      <div>
        <div className={instrumentPositionsClasses('row')}>
          <div className={instrumentPositionsClasses('row-label')}>
            {context.t('Product')}
          </div>
          <div className={instrumentPositionsClasses('product-symbol')}>
            {props.positions.position1.ProductSymbol}
          </div>
          <div className={instrumentPositionsClasses('product-symbol')}>
            {props.positions.position2.ProductSymbol}
          </div>
        </div>

        <div className={instrumentPositionsClasses('row')}>
          <div className={instrumentPositionsClasses('row-label')}>
            {context.t('Available Balance')}
          </div>
          <div className={instrumentPositionsClasses('product-available')}>
            {props.positions.position1.AvailableBalance}
          </div>
          <div className={instrumentPositionsClasses('product-available')}>
            {props.positions.position2.AvailableBalance}
          </div>
        </div>

        <div className={instrumentPositionsClasses('row')}>
          <div className={instrumentPositionsClasses('row-label')}>
            {context.t('Hold')}
          </div>
          <div className={instrumentPositionsClasses('product-hold')}>
            {props.positions.position1.Hold}
          </div>
          <div className={instrumentPositionsClasses('product-hold')}>
            {props.positions.position2.Hold}
          </div>
        </div>

        <div className={instrumentPositionsClasses('row')}>
          <div className={instrumentPositionsClasses('row-label')}>
            {context.t('Pending Deposits')}
          </div>
          <div
            className={instrumentPositionsClasses('product-pending-deposits')}>
            {props.positions.position1.PendingDeposits}
          </div>
          <div
            className={instrumentPositionsClasses('product-pending-deposits')}>
            {props.positions.position2.PendingDeposits}
          </div>
        </div>

        <div className={instrumentPositionsClasses('row')}>
          <div className={instrumentPositionsClasses('row-label')}>
            {context.t('Total Balance')}
          </div>
          <div className={instrumentPositionsClasses('product-total-balance')}>
            {props.positions.position1.Amount}
          </div>
          <div className={instrumentPositionsClasses('product-total-balance')}>
            {props.positions.position2.Amount}
          </div>
        </div>
      </div>
    )}
  </div>
);

InstrumentPositionsComponent.defaultProps = {
  showTitle: true
};

InstrumentPositionsComponent.propTypes = {
  positions: PropTypes.object.isRequired,
  showTitle: PropTypes.bool,
  fetching: PropTypes.bool
};

InstrumentPositionsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default InstrumentPositionsComponent;
