import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import ResizeObserver from 'resize-observer-polyfill';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { getImageSize } from '../../../helpers/sizes';
import { BuyButton } from '../Buttons/Buttons';
import { AddToFavoriteButton, ViewDetailsButton } from '../Buttons';
import AvailabilityProgressBar from '../AvailabilityProgressBar/AvailabilityProgressBar';
import SoldOutBanner from '../../../images/sold-out.png';
import subDays from 'date-fns/subDays';
import parseISO from 'date-fns/parseISO';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import InfoIconSmall from '../../../images/icons/info-icon.svg';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { sanitizeAttributeDate } from '../../../helpers/attributeDateSanitizer';
import {
  UNPLEDGE_COUNTDOWN_DAYS,
  VOTE_COUNTDOWN_DAYS
} from '../../../constants/votingConstants';
import { useRefWithCallback } from '../../../hooks/useRefWithCallback';
import './UpcomingOfferingCard.css';

export const UpcomingOfferingCard = (props, context) => {
  const {
    baseClass = 'upcoming-offering-card',
    customClass,
    classModifiers,
    title,
    rarityCategory,
    rarity,
    cardImageURL,
    onViewDetails,
    availableTokens = 0,
    onBuyButton,
    productId,
    totalSize,
    isLiveOffering,
    isSoldOut,
    timer,
    voteStartDate
  } = props;

  const classes = getBEMClasses(baseClass, customClass, classModifiers);

  const [setShadowWidthRef, shadowWidth] = useShadowWidth();

  const isMobile = useIsMobile();

  const [remainingTime, setRemainingTime] = useState();
  useEffect(
    () => {
      if (timer && voteStartDate && !isSoldOut) {
        setRemainingTime(calcRemainingTime(voteStartDate));
        const unsub = timer.subscribe(() =>
          setRemainingTime(calcRemainingTime(voteStartDate))
        );
        return unsub;
      } else {
        setRemainingTime(null);
      }
    },
    [timer, voteStartDate, isSoldOut]
  );

  return (
    <div className={classes('', isSoldOut && 'sold-out')}>
      {isSoldOut && (
        <img
          src={SoldOutBanner}
          className={classes('sold-out-banner')}
          alt="sold-out-banner"
        />
      )}
      {remainingTime && (
        <div className={classes('timer-container')}>
          <div
            className={classes('info-container')}
            tabIndex={isMobile ? 0 : undefined}>
            <SVG src={InfoIconSmall} className={classes('info-icon')} />
            <div className={classes('info-text')}>
              <SVG src={InfoIconSmall} className={classes('info-icon')} />
              {context.t(
                'Every asset will stay in the offering for {n} days. If the asset does not sell out, you will automatically be refunded, and the asset will be removed from the platform.',
                { n: UNPLEDGE_COUNTDOWN_DAYS }
              )}
            </div>
          </div>
          <span className={classes('timer')}>
            {zeroPad(remainingTime.days, 2)}{' '}
            <span className={classes('time-unit')}>
              {context.t(['day', 'days', 'n'], { n: remainingTime.days })}
            </span>{' '}
            {zeroPad(remainingTime.hours, 2)}{' '}
            <span className={classes('time-unit')}>
              {context.t(['hour', 'hours', 'n'], { n: remainingTime.hours })}
            </span>{' '}
            {context.t('REMAINING')}
          </span>
        </div>
      )}
      <div className={classes('card-content')}>
        <div className={classes('card-content-main')}>
          <div className={classes('text-content')}>
            <div className={classes('title')}>{title}</div>
            <div className={classes('rarity-text')}>
              {rarityCategory}: {rarity}
            </div>
            {isLiveOffering && (
              <AvailabilityProgressBar
                currentValue={availableTokens}
                totalValue={totalSize}
              />
            )}
            <AddToFavoriteButton
              productId={productId}
              customClass={baseClass}
            />
          </div>
          <div className={classes('image-content')}>
            <img
              ref={setShadowWidthRef}
              className={classes('card-image')}
              src={cardImageURL}
              alt="card"
            />
            <div
              className={classes('image-shadow')}
              style={{
                '--width': `${shadowWidth}px`
              }}
            />
          </div>
        </div>
        <div className={classes('buttons-wrapper')}>
          <ViewDetailsButton onClick={onViewDetails} />
          {onBuyButton && <BuyButton onClick={onBuyButton} />}
        </div>
      </div>
    </div>
  );
};

const zeroPad = (num, places) => String(num).padStart(places, '0');

const calcRemainingTime = voteStartDate => {
  const timeLeft = {
    days: 0,
    hours: 0
  };
  if (voteStartDate) {
    voteStartDate = parseISO(sanitizeAttributeDate(voteStartDate));
    const unpledgeDate = subDays(
      voteStartDate,
      VOTE_COUNTDOWN_DAYS - UNPLEDGE_COUNTDOWN_DAYS
    );
    const now = new Date();

    let difference = differenceInMilliseconds(unpledgeDate, now);
    if (difference <= 0) {
      return null;
    }

    timeLeft.days = Math.max(Math.floor(difference / (1000 * 60 * 60 * 24)), 0);

    difference = differenceInMilliseconds(
      difference,
      timeLeft.days * 1000 * 60 * 60 * 24
    );
    timeLeft.hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  }
  return timeLeft;
};

const MAX_SHADOW_WIDTH = 200;
const calcShadowWidth = el => {
  const [width] = getImageSize(el);
  return Math.min(width + 50, MAX_SHADOW_WIDTH);
};

const useShadowWidth = () => {
  const [shadowWidth, setShadowWidth] = useState(MAX_SHADOW_WIDTH);

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(entries => {
        const el = entries[0].target;
        setShadowWidth(calcShadowWidth(el));
      }),
    []
  );

  const onMount = useCallback(
    node => {
      setShadowWidth(calcShadowWidth(node));
      resizeObserver.observe(node);
    },
    [resizeObserver]
  );
  const onUnmount = useCallback(
    () => {
      resizeObserver.disconnect();
    },
    [resizeObserver]
  );
  const setShadowWidthRef = useRefWithCallback(onMount, onUnmount);

  return [setShadowWidthRef, shadowWidth];
};

UpcomingOfferingCard.contextTypes = {
  t: PropTypes.func.isRequired
};

UpcomingOfferingCard.propTypes = {
  baseClass: PropTypes.string,
  customClass: PropTypes.string,
  classModifiers: PropTypes.string,
  title: PropTypes.string.isRequired,
  rarityCategory: PropTypes.string.isRequired,
  rarity: PropTypes.string.isRequired,
  cardImageURL: PropTypes.string.isRequired,
  addToFavorite: PropTypes.func,
  isFavorite: PropTypes.bool,
  availableTokens: PropTypes.number,
  onViewDetails: PropTypes.func,
  onBuyButton: PropTypes.func,
  productId: PropTypes.number,
  totalSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLiveOffering: PropTypes.bool,
  isSoldOut: PropTypes.bool,
  timer: PropTypes.shape({
    subscribe: PropTypes.func,
    unsubscribe: PropTypes.func,
    reset: PropTypes.func
  }),
  voteStartDate: PropTypes.string
};
