import React from 'react';
import PropTypes from 'prop-types';
import APVerticalScroller from 'apex-web/lib/components/common/APVerticalScroller/APVerticalScroller';
import APInlineButton from 'apex-web/lib/components/common/APInlineButton';
import BulkCancelButtonsComponent from './BulkCancelButtonsComponent/BulkCancelButtonsComponent';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { isFirefox } from 'apex-web/lib/helpers/browserHelper';
import Level2DataTableComponent from './Level2DataTableComponent';

import 'apex-web/lib/styles/components/TradeComponent.css';
import './OrderBookComponent.css';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import { formatNumberToLocale } from '../../helpers/numberFormatter';

const tradeComponentClasses = getBEMClasses('trade-component');
const orderBookClasses = getBEMClasses('orderbook');
const flexTable = getBEMClasses('flex-table');

export default class OrderBookComponent extends React.Component {
  constructor(props, context) {
    super(props, context);
    // default state
    this.state = {
      showCenterButton: false //  will change to true when user scrolls away from rest position
    };
    // event handler bindings
    this.onClickCenter = this.onClickCenter.bind(this);
    this.resizeEventListener = this.resizeEventListener.bind(this);
    this.onScrollRestChange = this.onScrollRestChange.bind(this);
    this.handleScrollRestChange = this.handleScrollRestChange.bind(this);

    this.buyScroller = React.createRef();
    this.sellScroller = React.createRef();
  }

  onClickCenter(overrideUserScrolled = false) {
    if (this.sellScroller && this.buyScroller) {
      const scrollToRest = () => {
        this.sellScroller.current.scrollToRest();
        this.buyScroller.current.scrollToRest();
      };

      if (overrideUserScrolled)
        return this.sellScroller.current.resetUserScrolled(() =>
          this.buyScroller.current.resetUserScrolled(scrollToRest)
        );
      scrollToRest();
    }
  }

  resizeEventListener = () => this.onClickCenter(true);

  componentDidUpdate(prevProps) {
    this.onClickCenter(
      this.props.selectedInstrumentId !== prevProps.selectedInstrumentId
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeEventListener);
    this.onClickCenter();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeEventListener);
  }

  onScrollRestChange() {
    // show "center" button when user scrolls away from rest position
    setTimeout(this.handleScrollRestChange, 230);
  }

  handleScrollRestChange() {
    if (!!this.sellScroller.current) {
      const sellIsAtRest = this.sellScroller.current.getIsAtRest();
      const buyIsAtRest = this.buyScroller.current.getIsAtRest();
      const showCenterButton = !sellIsAtRest || !buyIsAtRest;
      this.setState({
        showCenterButton
      });
    }
  }

  render() {
    const { context, onScrollRestChange } = this;

    const {
      selectedInstrument,
      cancelOrders,
      level2: { buy, sell },
      buyPriceClicked,
      buyQuantityClicked,
      sellPriceClicked,
      sellQuantityClicked,
      fetching,
      hideBulkCancelButtons,
      lastPrice,
      cancelAllOrders,
      cancelBuyOrders,
      cancelSellOrders,
      openOrders,
      buyOpenOrders,
      sellOpenOrders,
      onlyMarketAvailable,
      onlyLimitAvailable,
      isSellDisable,
      areOrderEntriesDisabled = false
    } = this.props;

    const CenterButton = ({ state, context, onClickCenter, lastPrice }) => (
      <div className={orderBookClasses('spread-row')}>
        <span className={orderBookClasses('spread-row-title')}>
          {context.t('Last Price')}
          <span className={orderBookClasses('spread-row-value')}>
            {formatNumberToLocale(
              lastPrice,
              Math.max(
                4,
                convertIncrementToIntDecimalPlaces(
                  selectedInstrument.PriceIncrement
                )
              )
            )}
          </span>
        </span>
        {state &&
          state.showCenterButton && (
            <APInlineButton
              icon="center"
              iconModifier="small"
              text={context.t('Center')}
              onClick={() => onClickCenter(true)}
              customClass={orderBookClasses('center-button')}
            />
          )}
      </div>
    );

    return (
      <div className={tradeComponentClasses()}>
        <div className={`${flexTable()} ${orderBookClasses()}`}>
          <div className={`${flexTable('body')} ${orderBookClasses('body')}`}>
            <div
              className={`${flexTable(
                'header',
                'background'
              )} ${orderBookClasses('header')}`}>
              <div className={flexTable('column')}>
                <div
                  className={`${flexTable('fixed')} ${orderBookClasses(
                    'table-header',
                    'price'
                  )}`}>
                  {context.t('Price')}
                </div>
              </div>
              <div className={flexTable('column')}>
                <div
                  className={`${flexTable('fixed')} ${orderBookClasses(
                    'table-header',
                    'qty'
                  )}`}>
                  {context.t('Qty')}
                </div>
              </div>
              <div className={flexTable('column')}>
                <div
                  className={`${flexTable('fixed')} ${orderBookClasses(
                    'table-header',
                    'my-size'
                  )}`}>
                  {context.t('My Size')}
                </div>
              </div>
            </div>
            <div className={orderBookClasses('order-book-body')}>
              <APVerticalScroller
                ref={this.sellScroller}
                restPosition="bottom"
                onScrollRestChange={onScrollRestChange}
                customClass={orderBookClasses(
                  'vertical-scroller-body',
                  isFirefox() ? 'firefox' : ''
                )}>
                <div className="orderbook__table-filler">
                  <Level2DataTableComponent
                    quantityDecimalPlaces={0}
                    priceDecimalPlaces={this.props.convertedPriceIncrement}
                    selectedInstrument={selectedInstrument}
                    cancelOrders={cancelOrders}
                    onPriceClick={
                      onlyMarketAvailable || areOrderEntriesDisabled
                        ? () => {}
                        : sellPriceClicked
                    }
                    onQuantityClick={
                      onlyLimitAvailable || areOrderEntriesDisabled
                        ? () => {}
                        : sellQuantityClicked
                    }
                    level2Data={sell}
                    classModifier={'sell'}
                    fetching={fetching}
                  />
                </div>
              </APVerticalScroller>

              {CenterButton({ ...{ ...this, context, lastPrice } })}

              <APVerticalScroller
                ref={this.buyScroller}
                restPosition="top"
                onScrollRestChange={onScrollRestChange}
                customClass={orderBookClasses(
                  'vertical-scroller-body',
                  isFirefox() ? 'firefox' : ''
                )}>
                <Level2DataTableComponent
                  quantityDecimalPlaces={0}
                  priceDecimalPlaces={this.props.convertedPriceIncrement}
                  selectedInstrument={selectedInstrument}
                  cancelOrders={cancelOrders}
                  onPriceClick={
                    isSellDisable || areOrderEntriesDisabled
                      ? () => {}
                      : buyPriceClicked
                  }
                  onQuantityClick={
                    isSellDisable || areOrderEntriesDisabled
                      ? () => {}
                      : buyQuantityClicked
                  }
                  level2Data={buy}
                  classModifier={'buy'}
                  fetching={fetching}
                />
              </APVerticalScroller>
              {!hideBulkCancelButtons && (
                <div>
                  <BulkCancelButtonsComponent
                    {...{
                      openOrders,
                      buyOpenOrders,
                      sellOpenOrders,
                      cancelAllOrders,
                      cancelBuyOrders,
                      cancelSellOrders
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OrderBookComponent.defaultProps = {
  hideBulkCancelButtons: false,
  level2: {
    buy: [],
    sell: [],
    short: [],
    unknown: []
  }
};

OrderBookComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

OrderBookComponent.propTypes = {
  selectedInstrument: PropTypes.any,
  cancelOrders: PropTypes.func,
  level2: PropTypes.any,
  buyPriceClicked: PropTypes.func,
  buyQuantityClicked: PropTypes.func,
  sellPriceClicked: PropTypes.func,
  sellQuantityClicked: PropTypes.func,
  fetching: PropTypes.bool,
  hideBulkCancelButtons: PropTypes.bool,
  lastPrice: PropTypes.any,
  cancelAllOrders: PropTypes.func,
  cancelBuyOrders: PropTypes.func,
  cancelSellOrders: PropTypes.func,
  openOrders: PropTypes.any,
  buyOpenOrders: PropTypes.any,
  sellOpenOrders: PropTypes.any,
  onlyMarketAvailable: PropTypes.bool,
  onlyLimitAvailable: PropTypes.bool,
  isSellDisable: PropTypes.bool,
  areOrderEntriesDisabled: PropTypes.bool,
  selectedInstrumentId: PropTypes.number,
  state: PropTypes.any,
  context: PropTypes.any,
  onClickCenter: PropTypes.any,
  convertedPriceIncrement: PropTypes.any
};
