import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showAffiliateProgramSelector } from '../../redux/selectors/userTierSelector';
import APNavMenuHorizontal from '../APNavMenuHorizontal/APNavMenuHorizontal';

import './SettingsMenuComponent.css';

const SettingMenuItems = [
  { text: 'Profile & Security', path: '/settings/user', icon: 'security' },
  // { text: 'Trade Settings', path: '/settings/trade', icon: 'tasks' },
  {
    text: 'Verification Level',
    path: '/settings/verification-level',
    icon: 'tasks'
  },
  { text: 'Trade Reports', path: '/settings/trade-reports', icon: 'reports' },
  // { text: 'API Keys', path: '/settings/api-keys', icon: 'key' },
  // { text: 'User Contacts', path: '/settings/contacts', icon: 'contacts' },
  { text: 'Affiliate Program', path: '/settings/affiliate', icon: 'friends' },
  // { text: 'Loyalty Token', path: '/settings/loyalty-token', icon: 'heart' }
  // { text: 'Demo Settings', path: '/settings/demo-settings', icon: 'paint' }
  { text: 'Payment', path: '/settings/payments', icon: 'deposit' }
];

const SettingsMenuComponent = props => {
  const { showAffiliateProgram } = props;
  const items = useMemo(
    () => {
      if (!showAffiliateProgram) {
        return SettingMenuItems.filter(
          item => item.path !== '/settings/affiliate'
        );
      }
      return SettingMenuItems;
    },
    [showAffiliateProgram]
  );
  return <APNavMenuHorizontal items={items} />;
};

SettingsMenuComponent.propTypes = {
  showAffiliateProgram: PropTypes.bool
};

SettingsMenuComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(state => ({
  showAffiliateProgram: showAffiliateProgramSelector(state)
}))(SettingsMenuComponent);
