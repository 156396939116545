import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import { product as ProductPropTypes } from 'apex-web/lib/propTypes/sendReceiveComponent';
import ReceiveAddressContainer from './ReceiveAddress/ReceiveAddressContainer';
import SendReceiveConfirmModal from 'apex-web/lib/components/SendReceiveSidePane/SendReceiveConfirmModal/SendReceiveConfirmModalComponent';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { Modal } from '../common/Modal/Modal';

import './Receive.css';

const bemClasses = getBEMClasses('receive');

const ReceiveForm = (props) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const {
    disableDeposit,
    verificationOnClick,
    formObj,
    product,
    receiveFunds
  } = props;

  const submitData = () => {
    const { selectedAccountId } = props;

    const data = formObj.values;
    const { Notes, ReceiverUsername, Amount } = data;
    const { ProductId } = product;

    const payload = {
      Notes: Notes || '',
      ReceiverUsername,
      Amount,
      ProductId,
      selectedAccountId
    };

    receiveFunds(payload);
    setOpenConfirmModal(false);
  };

  return (
    <section className={bemClasses()}>
      <section className={bemClasses('body')}>
        {disableDeposit ? (
          <VerificationRequiredContainer
            disabled={disableDeposit}
            onClick={verificationOnClick}
          />
        ) : (
          <ReceiveAddressContainer product={product} />
        )}
      </section>
      <SendReceiveConfirmModal
        isOpen={openConfirmModal}
        useExternalAddress={true}
        close={() => setOpenConfirmModal(false)}
        product={product}
        isSend={false}
        values={formObj.values}
        onConfirm={submitData}
        fee={0}
      />
    </section>
  );
};

const ReceiveFormModal = props => {
  const { isOpen, close, withModalWrapper } = props;

  const ContentWrapper = withModalWrapper ? Modal : React.Fragment;
  if (withModalWrapper) {
    ContentWrapper.props = {
      ...ContentWrapper.props,
      isOpen,
      title: 'Receive Modal',
      close
    };
  }

  return (
    <ContentWrapper>
      <ReceiveForm {...props} />
    </ContentWrapper>
  );
};

ReceiveFormModal.propTypes = {
  withModalWrapper: PropTypes.bool,
  isOpen: PropTypes.bool,
  close: PropTypes.func
};

ReceiveForm.propTypes = {
  product: ProductPropTypes,
  allowTransfer: PropTypes.bool.isRequired,
  disableDeposit: PropTypes.bool,
  verificationOnClick: PropTypes.func,
  hideFees: PropTypes.bool,
  receiveFunds: PropTypes.func,
  setUseExternalAddress: PropTypes.func,
  formObj: PropTypes.object,
  selectedAccountId: PropTypes.number
};

ReceiveForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReceiveFormModal;
