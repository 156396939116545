import { callAPI } from "apex-web/lib/redux/actions/apiActions";
import get from "lodash/get";

export const SET_LEDGER_ENTRIES = 'SET_LEDGER_ENTRIES';
export const UPDATE_LEDGER_ENTRIES_REQUEST_STATUS = 'UPDATE_LEDGER_ENTRIES_REQUEST_STATUS';

export const updateLedgerEntriesRequestStatus = (isFetching) => ({
  type: UPDATE_LEDGER_ENTRIES_REQUEST_STATUS,
  payload: { isFetching }
});

export const setLedgerEntries = (payload) => {
  return {
    type: SET_LEDGER_ENTRIES,
    payload
  };
};

export const getLedgerEntries = ({ accountId, limit = 100 }) => async (dispatch, getState) => {
  const AccountId = get(getState(), 'user.selectedAccountId', accountId);
  const OMSId = get(getState(), 'user.userInfo.OMSId', 1);
  try {
    dispatch(updateLedgerEntriesRequestStatus(true));
    const ledgerEntries = await dispatch(
      callAPI('GetAllLedgerEntryTickets', {
        AccountId,
        OMSId,
        Limit: limit
      })
    );
    dispatch(setLedgerEntries(ledgerEntries));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(updateLedgerEntriesRequestStatus(false));
  }
};
