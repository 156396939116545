import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { CardForVote } from './CardForVote';
import {
  VOTE_STATUSES
} from '../../constants/votingConstants';
import Logo from '../../images/icon-logo-big.png';
import { sortAssets } from '../../helpers/sortHelper';
import { parseISO } from 'date-fns';
import { sanitizeAttributeDate } from '../../helpers/attributeDateSanitizer';

const classes = getBEMClasses('vote-page');

export const VoteCardsSection = (props, context) => {
  const {
    cardsForVote,
    voteStatusFilter,
    voteTypeFilter,
    cardNameFilter,
    currentSortOption,
    openConfirmVoteModal,
    changeCardStatus,
    timer
  } = props;

  const renderNoAssets = () => {
    return (
      <div className={classes('no-assets-wrapper')}>
        {voteStatusFilter === VOTE_STATUSES.COUNTDOWN
          ? context.t('No asset is available for Vote Countdown')
          : voteStatusFilter === VOTE_STATUSES.LIVE
            ? context.t('No asset is available for Live Vote')
            : context.t('No results available')}
        <img src={Logo} alt="logo" className={classes('no-assets-logo')} />
      </div>
    );
  };

  const sortedCards = React.useMemo(
    () =>
      cardsForVote
        .filter(item => {
          if (cardNameFilter) {
            return item.ProductFullName.toLowerCase().includes(
              cardNameFilter.toLowerCase()
            );
          } else {
            return true;
          }
        })
        .sort((a, b) => comparator(a, b, currentSortOption))
        .map(i => (
          <CardForVote
            key={`${i.Product}-card-for-vote`}
            card={i}
            openConfirmVoteModal={openConfirmVoteModal}
            changeCardStatus={changeCardStatus}
            voteStatus={voteStatusFilter}
            timer={timer}
          />
        )),
    [
      currentSortOption,
      cardsForVote,
      cardNameFilter,
      voteStatusFilter,
      voteTypeFilter
    ]
  );
  return sortedCards.length ? sortedCards : renderNoAssets();
};

export const TIME_SORT = 'TIME_SORT';

const comparator = (a, b, sortOption) => {
  if (sortOption !== TIME_SORT) {
    return sortAssets(a, b, sortOption);
  } else {
    // Sorts in that order:
    // - live votes
    // - countdowns
    // - result
    // the same statuses are sorted by the signed difference between the end date
    // and the current moment:
    // - live and countdowns are ascending (closed will be on top,
    //   followed by those that will happen soon)
    // - results are descending (recently ended at the top)
    const aTime = getEndDateDiffFromNow(a);
    const aStatus = a.VoteStatus;
    const bTime = getEndDateDiffFromNow(b);
    const bStatus = b.VoteStatus;
    if (aStatus === bStatus) {
      return aStatus !== VOTE_STATUSES.RESULT ? aTime - bTime : bTime - aTime;
    } else {
      switch (aStatus) {
        case VOTE_STATUSES.LIVE:
          return 1;
        case VOTE_STATUSES.COUNTDOWN:
          return bStatus === VOTE_STATUSES.RESULT ? 1 : -1;
        default: {
          return -1;
        }
      }
    }
  }
};

const getEndDateDiffFromNow = asset => {
  try {
    const { votingEndDate } = asset.voteAttributes;
    const voteEndDate = parseISO(
      sanitizeAttributeDate(votingEndDate)
    );
    return voteEndDate - new Date();
  } catch (e) {
    console.error(e);
    return -Infinity;
  }
};

VoteCardsSection.contextTypes = {
  t: PropTypes.func.isRequired
};
