import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { UserNameTierBadge } from '../UserNameTierBadge/UserNameTierBadge';
import UserXP from '../UserNameTierBadge/UserXP/UserXP';
import './UserSummaryComponent.css';

const UserSummary = props => {
  const {
    selectedAccount,
    customClass,
    userTier,
    userExp,
    userExpHold,
    isMobile = false,
    userMonthTier,
    isGrandMaster,
    degree,
    isTierLoaded
  } = props;
  const bemClasses = getBEMClasses(['user-summary', customClass]);

  return (
    <div className={bemClasses('container')}>
      {selectedAccount && isTierLoaded ? (
        <React.Fragment>
          <div className={bemClasses('user-tier')}>
            <UserNameTierBadge
              userTier={userTier}
              userName={selectedAccount.AccountName}
              isMobile={isMobile}
              isGrandMaster={isGrandMaster}
              degree={degree}
            />
            <div className={bemClasses('user-xp-wrapper')}>
              <UserXP
                userTier={userTier}
                userMonthTier={userMonthTier}
                userExp={userExp}
                userExpHold={userExpHold}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <Spinner isInline customClass={bemClasses} />
      )}
    </div>
  );
};

UserSummary.propTypes = {
  selectedAccount: PropTypes.shape({
    AccountName: PropTypes.string.isRequired
  }),
  customClass: PropTypes.string,
  userTier: PropTypes.number.isRequired,
  userExp: PropTypes.number,
  userExpHold: PropTypes.number,
  isMobile: PropTypes.bool,
  userMonthTier: PropTypes.number,
  isGrandMaster: PropTypes.bool,
  degree: PropTypes.number,
  isTierLoaded: PropTypes.bool
};

export default UserSummary;
