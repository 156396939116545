import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { createChart, CrosshairMode, isBusinessDay } from 'lightweight-charts';
import { connect } from 'react-redux';
import get from 'lodash/get';
import config from 'apex-web/lib/config';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { getCSSVar } from 'apex-web/lib/helpers/cssVarHelper';
import { IntervalButtons } from './IntervalButtons';
import { formatTime } from 'apex-web/lib/helpers/dateHelper';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

const { LightWeightCharts, global } = config;
const { locale } = global;
const cssVar = getCSSVar();

const bullish = cssVar('--chart__bullish-color');
const bearish = cssVar('--chart__bearish-color');

function makeOHLC(frame) {
  return {
    time: frame[0] / 1000,
    high: frame[1],
    low: frame[2],
    open: frame[3],
    close: frame[4],
    volume: frame[5]
  };
}

function makeValueFrame(frame, targetIndex = 4) {
  return {
    time: frame[0] / 1000,
    value: frame[targetIndex]
  };
}

function getFrameFormatter(chartType) {
  if (chartType === 'candlestick') return makeOHLC;
  return frame => makeValueFrame(frame);
}

function getChartConstructor(chartType) {
  if (chartType === 'candlestick') return 'addCandlestickSeries';
  return 'addAreaSeries';
}

function getChartConfig(chartType) {
  if (chartType === 'candlestick') {
    return {
      upColor: bullish,
      downColor: bearish,
      wickUpColor: bullish,
      wickDownColor: bearish,
      borderVisible: false
    };
  }
  return {
    bottomColor: cssVar('--default-price-line-color'),
    topColor: bullish,
    color: bullish,
    lineColor: bullish,
    priceLineColor: bullish,
    lineStyle: 0,
    lineWidth: 2
  };
}

export class LightWeightChart extends React.Component {
  constructor() {
    super();
    this.chart = null;
    this.chartContainerRef = createRef();
    this.wrapperRef = createRef();
    this.priceSeries = null;
    this.volumeSeries = null;
  }

  resizeHandler = () => {
    this.chart.applyOptions({
      width: this.chartContainerRef.current.offsetWidth,
      height: this.chartContainerRef.current.offsetHeight
    });
  };

  componentDidMount() {
    const { selectedInstrument } = this.props;
    const decimals = priceDp(selectedInstrument);

    this.chart = createChart(this.chartContainerRef.current, {
      height: this.wrapperRef.current.offsetHeight,
      width: this.wrapperRef.current.offsetWidth,
      layout: {
        fontFamily: cssVar('--font__typeface'),
        backgroundColor: cssVar('--pro-exchange-chart-background'),
        textColor: cssVar('--font__color-secondary')
      },
      grid: {
        vertLines: {
          color: cssVar('--tv-chart__grid-vertical-color')
        },
        horzLines: {
          color: cssVar('--tv-chart__grid-horizontal-color')
        }
      },
      crosshair: {
        mode: CrosshairMode.Normal
      },
      priceScale: {
        borderColor: cssVar('--tv-chart__scale-font-color')
      },
      timeScale: {
        borderColor: cssVar('--tv-chart__scale-font-color'),
        rightBarStaysOnScroll: true,
        timeVisible: true
      },
      localization: {
        locale,
        dateFormat: LightWeightCharts.dateFormat,
        timeFormatter: businessDayOrTimestamp => {
          if (isBusinessDay(businessDayOrTimestamp)) {
            return '';
          }
          return formatTime(businessDayOrTimestamp * 1000);
        },
        priceFormatter: price => formatNumberToLocale(price, decimals)
      }
    });

    window.addEventListener('resize', this.resizeHandler);

    const func = getChartConstructor(
      this.props.chartType || config.LightWeightCharts.chartType
    );
    const chartConfig = getChartConfig(
      this.props.chartType || config.LightWeightCharts.chartType
    );

    this.priceSeries = this.chart[func](chartConfig);

    if (!this.props.hideVolume && config.LightWeightCharts.showVolume) {
      this.volumeSeries = this.chart.addHistogramSeries({
        base: 0,
        color: 'rgba(238, 238, 238, 0.2)',
        priceFormat: {
          type: 'volume'
        },
        overlay: true,
        scaleMargins: {
          top: 0.8,
          bottom: 0
        }
      });
    }

    let priceData = [];
    let volData = [];
    this.props.priceData.forEach(el => {
      priceData.push(
        getFrameFormatter(
          this.props.chartType || config.LightWeightCharts.chartType
        )(el)
      );
      volData.push(makeValueFrame(el, 5));
    });
    this.priceSeries.setData(priceData);
    this.volumeSeries && this.volumeSeries.setData(volData);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedInstrument !== this.props.selectedInstrument) {
      const decimalPlaces = priceDp(this.props.selectedInstrument);
      this.chart.applyOptions({
        localization: {
          priceFormatter: price => formatNumberToLocale(price, decimalPlaces)
        }
      });
    }

    if (prevProps.selectedInstrumentId !== this.props.selectedInstrumentId) {
      this.priceSeries.setData([]);
      this.volumeSeries && this.volumeSeries.setData([]);
    }

    if (prevProps.priceData !== this.props.priceData) {
      let priceData = [];
      let volData = [];

      this.props.priceData.forEach(el => {
        priceData.push(
          getFrameFormatter(
            this.props.chartType || config.LightWeightCharts.chartType
          )(el)
        );
        volData.push(makeValueFrame(el, 5));
      });

      this.priceSeries.setData(priceData);
      this.volumeSeries && this.volumeSeries.setData(volData);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
    this.chart.remove();
  }

  render() {
    return (
      <div id="lightweight-chart" ref={this.wrapperRef}>
        {!this.props.hideIntervalButtons && <IntervalButtons />}
        <div
          id="lightweight-chart-container"
          className="lightweight-chart"
          ref={this.chartContainerRef}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const selectedInstrument = selectedInstrumentSelector(state);
  return {
    selectedInstrument,
    selectedInstrumentId: selectedInstrument.InstrumentId,
    priceData: get(state, 'tickers.tickers', []),
    selectedInterval: state.tickers.interval
  };
};

const priceDp = instrument =>
  Math.max(4, convertIncrementToIntDecimalPlaces(instrument.PriceIncrement));

LightWeightChart.contextTypes = {
  t: PropTypes.func.isRequired
};

LightWeightChart.propTypes = {
  selectedInstrument: PropTypes.any,
  chartType: PropTypes.string,
  hideVolume: PropTypes.bool,
  priceData: PropTypes.array,
  selectedInstrumentId: PropTypes.number,
  hideIntervalButtons: PropTypes.bool
};

export default connect(mapStateToProps)(LightWeightChart);
