import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SidePaneDetailsComponent from '../../../common/SidePaneDetailsComponent/SidePaneDetailsComponent';
import { formatNumberToLocale } from '../../../../helpers/numberFormatter';

const FiatWithdrawDetailsComponent = (props, context) => {
  const {
    data: { Amount },
    product: { Product: ProductSymbol, DecimalPlaces },
    balance,
    fee,
    detailsInfo,
    TicketAmount,
    setAmount,
    isDotsWithdraw
  } = props;
  useEffect(() => {
    return () => {
      setAmount(0);
    };
  }, []);
  const { getItem } = SidePaneDetailsComponent;
  const currentBalance = balance
    ? `${formatNumberToLocale(balance, DecimalPlaces)} ${ProductSymbol}`
    : '-';
  const amount = TicketAmount
    ? `${formatNumberToLocale(TicketAmount, DecimalPlaces)} ${ProductSymbol}`
    : '-';

  /**
   * TicketAmount = `GetWithdrawFee`.TicketAmount.
   *  If (Gateway.`DeductWithdrawFee` = true), fee is already deducted.
   */
  const remainingBalance =
    balance && Amount
      ? `${formatNumberToLocale(
          balance - (TicketAmount + fee),
          DecimalPlaces
        )} ${ProductSymbol}`
      : '-';

  const items = [
    getItem(
      context.t('Your current {ProductSymbol} Balance', {
        ProductSymbol
      }),
      currentBalance
    ),
    getItem(context.t('Amount to Withdraw'), amount),
    {
      label: isDotsWithdraw ? 'See Fee Schedule' : context.t('Fee'),
      text: fee
        ? `${formatNumberToLocale(fee, DecimalPlaces)} ${ProductSymbol}`
        : '-',
      link: isDotsWithdraw
        ? 'https://www.liquidmarketplace.io/fee-schedule'
        : '',
      additionalInstructions: isDotsWithdraw
        ? '*Fees are subject to location and range between 1%-3% of amount being withdrawn'
        : 0
    },
    getItem(context.t('Remaining Balance'), remainingBalance)
  ];

  const info = detailsInfo.text;

  return (
    <SidePaneDetailsComponent
      title={context.t('Balances')}
      items={items}
      info={info}
      infoHeader={'Instructions'}
      classModifiers={'withdraw'}
    />
  );
};

FiatWithdrawDetailsComponent.propTypes = {
  data: PropTypes.object,
  product: PropTypes.object,
  balance: PropTypes.number,
  fee: PropTypes.number,
  detailsInfo: PropTypes.object,
  TicketAmount: PropTypes.number,
  setAmount: PropTypes.func,
  isDotsWithdraw: PropTypes.bool
};

FiatWithdrawDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export { FiatWithdrawDetailsComponent };
