import React from 'react';
import PropTypes from 'prop-types';
import ProfileSettingsCardComponent from 'apex-web/lib/components/Settings/ProfileAndSecurity/ProfileSettingsCard';
import RegionSettingsCardComponent from 'apex-web/lib/components/Settings/ProfileAndSecurity/RegionSettingsCard';
import ThemeSettingsCardComponent from 'apex-web/lib/components/Settings/ProfileAndSecurity/ThemeSettingsCard';
import PasswordSettingsCardComponent from 'apex-web/lib/components/Settings/ProfileAndSecurity/PasswordSettingsCard';
import TwoFactorAuthSettingsCardComponent from '../../components/Settings/ProfileAndSecurity/TwoFactorAuthSettingsCard';
import { PublicProfileCard } from '../../components/Settings/ProfileAndSecurity/PublicProfileCard/PublicProfileCard';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

import './UserSettingsPage.css';

const userSettingsPageClasses = getBEMClasses('user-settings-page');

const UserSettingsPage = () => {
  return (
    <React.Fragment>
      <div className={userSettingsPageClasses('container')}>
        <div className={userSettingsPageClasses('row')}>
          <ProfileSettingsCardComponent />
          <RegionSettingsCardComponent />
        </div>

        <div className={userSettingsPageClasses('row', 'sm')}>
          <ThemeSettingsCardComponent />
          <PasswordSettingsCardComponent />
        </div>

        <div className={userSettingsPageClasses('row', 'sm')}>
          <TwoFactorAuthSettingsCardComponent />
          <PublicProfileCard />
        </div>
      </div>
    </React.Fragment>
  );
};

UserSettingsPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default UserSettingsPage;
