import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { APTypography } from '../common/APTypography';
import { setIsReminderWasShown } from '../../redux/actions/userActions';
import APIcon from '../common/APIcon';
import { useIsMobile } from '../../hooks/useIsMobile';
import Logo from '../../images/onboarding-logo.png';
import './styles.css';

const baseClass = getBEMClasses('reminder');

const ReminderComponent = (
  { setIsReminderWasShown, isSmallPadding },
  context
) => {
  const isMobile = useIsMobile();
  return (
    <div
      className={classnames(
        baseClass('wrapper'),
        isSmallPadding && baseClass('wrapper-with-small-padding')
      )}
      data-test='wallet-reminder'>
      <img src={Logo} className={baseClass('back-img')} alt="logo" />
      <div
        className={baseClass('close-wrapper')}
        onClick={setIsReminderWasShown}
        data-test='wallet-reminder-close'>
        <APTypography color="white1">{context.t('close')}</APTypography>
        <APIcon name="close" customClass={baseClass('close-icon')} />
      </div>
      <div
        className={classnames(
          baseClass('content-wrapper'),
          isSmallPadding && baseClass('content-wrapper-with-small-padding')
        )}>
        <APTypography
          fontSize={isMobile ? 'body' : 'subtitle'}
          weight="weight600"
          color="white1"
          style={{ lineHeight: isMobile ? '34px' : '32px' }}>
          {context.t('REMINDER!')}
        </APTypography>
        <APTypography
          fontSize={isMobile ? 'caption2' : 'body'}
          color="white1"
          style={{
            lineHeight: isMobile ? '24px' : '30px',
            marginTop: isMobile ? '7px' : '2px'
          }}>
          {context.t(
            'We Have A Drop Coming Soon! Consider Adding Funds To Your Account'
          )}
        </APTypography>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setIsReminderWasShown
};

const Reminder = connect(
  null,
  mapDispatchToProps
)(ReminderComponent);

ReminderComponent.propTypes = {
  setIsReminderWasShown: PropTypes.func.isRequired,
  isSmallPadding: PropTypes.bool
};

ReminderComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export { Reminder };
