import { PropTypes } from 'prop-types';
import React from 'react';

import './VoteInfoModals.css';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { Modal } from '../../../components/common/Modal/Modal';

const classes = getBEMClasses('live-vote-modal');

export const LiveVoteModal = (props, context) => {
  const { isModalOpen, closeModal } = props;

  return (
    <Modal
      customClass={classes()}
      isOpen={isModalOpen}
      close={closeModal}
      showHeader={false}
      showCloseIcon={false}>
      <div className={classes('body')}>
        <div className={classes('title')}>{context.t('Live Vote')}</div>
        <div className={classes('description')}>
          {context.t(
            'The Live Vote will last for 36 hours. During this period, users will have the opportunity to vote either “Yes” or “No” to bring the collectible to auction. If 80% of token holders vote “Yes”, then the collectible will be listed on a reputable auction house for trading cards, memorabilia, and all other collectibles.'
          )}
        </div>

        <div className={classes('description-2')}>
          <span className={classes('warning')}>
            {context.t(
              'During the Live Vote, you cannot trade your tokens on the MarketPlace.'
            )}
          </span>
          {context.t(
            'After the 36-hour Live Vote, if the result is a “No” vote, all tokens will once again be eligible for trading. If the vote is “Yes”, then all tokens will immediately be frozen, and the collectible will be sent to auction. Once the auction closes, token holders will receive funds directly to their LMP Wallets relative to their ownership percentage. It is completely free for users to auction off the collectible!'
          )}
        </div>
        <button className={classes('back-button')} onClick={closeModal}>
          {context.t('Go Back')}
        </button>
      </div>
    </Modal>
  );
};

LiveVoteModal.contextTypes = {
  t: PropTypes.func.isRequired
};

LiveVoteModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func
};
