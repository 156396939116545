import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { getBEMClasses } from "../../../helpers/cssClassesHelper";
import Slider, { Handle } from "rc-slider";
import './PercentageSlider.css';
import { resetZoomValue } from "../../../helpers/zoomHelper";

const classes = getBEMClasses('percentage-slider');

const PercentageSlider = (props) => {
  const { isTooltipAlwaysVisible = false } = props;
  const marks = useMemo(() => {
    return props.marks.reduce((acc, mark) => {
      acc[mark] = ''; // mark without label
      return acc;
    }, {});
  }, [props.marks]);

  // eslint-disable-next-line react/display-name
  const SliderHandle = useMemo(() => props => {
    // eslint-disable-next-line react/prop-types
    const { value, index, ...restProps } = props;
    return (
      <div className={classes('handle-container')}
        key={index}>
        <Handle
          prefixCls={classes('handle')}
          value={value}
          {...restProps} />
        <div
          className={classes('tooltip', isTooltipAlwaysVisible && 'always-visible')}
          style={{ left: `${value}%` }}>
          {value}%
        </div>
      </div >
    );
  }, [isTooltipAlwaysVisible]);

  return (
    <div
      style={resetZoomStyle}
      className={classes('', isTooltipAlwaysVisible && 'always-with-tooltip')}>
      <Slider
        prefixCls={classes('slider')}
        {...props}
        marks={marks}
        handle={SliderHandle} />
    </div>
  );
};

const resetZoomStyle = { zoom: resetZoomValue };

PercentageSlider.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  marks: PropTypes.arrayOf(PropTypes.number).isRequired,
  step: PropTypes.number,
  isTooltipAlwaysVisible: PropTypes.bool
};

export default PercentageSlider;
