import { SET_AVAILABLE_TOKENS } from '../actions/cardActions';

const initialState = {
  byProductId: null,
  isFetching: false
};

export default function availableTokenReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_AVAILABLE_TOKENS: {
      const { available_tokens } = payload;

      const availableTokensToObj = available_tokens.reduce((obj, response) => {
        return {
          ...obj,
          [response.product_id]: response
        };
      }, {});

      return {
        ...state,
        byProductId: { ...state.byProductId, ...availableTokensToObj }
      };
    }

    default:
      return state;
  }
}
