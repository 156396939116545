import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Textfit } from 'react-textfit';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { Table } from '../../../components/common/Table';
import { APTypography } from '../../../components/common/APTypography';
import { formatNumberToLocale } from '../../../helpers/numberFormatter';
import './CurrenciesTableComponent.css';

const baseClass = getBEMClasses('currencies');
const MIN_ITEM_FONT_SIZE = 6;

const CurrenciesTableComponent = (props, context) => {
  const { isShowReminder, items } = props;
  const isMaxWidth576px = useMedia('(max-width: 576px)');
  const isMaxWidth400px = useMedia('(max-width: 400px)');
  const maxItemFontSize = isMaxWidth400px ? 9.6 : isMaxWidth576px ? 11.2 : 16;
  const columns = [
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={baseClass('columnHeader')}>
          {context.t('Name')}
        </APTypography>
      ),
      dataTest: 'Product',
      cell: row => (
        <Link
          className={baseClass('columnItem')}
          to={row.detailsLink}>
          <div
            className={baseClass('currencyColor')}
            style={{ backgroundColor: row.currencyColor }}
          />
          <APTypography
            fontSize="body"
            className={baseClass('columnItem-text')}>
            {row.currency}
          </APTypography>
        </Link>
      )
    },
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={baseClass('columnHeader')}>
          {context.t('Amount')}
        </APTypography>
      ),
      dataTest: 'Amount of tokens',
      cell: row => (
        <Textfit
          mode="single"
          min={MIN_ITEM_FONT_SIZE}
          max={maxItemFontSize}
          className={baseClass('columnItem-text')}>
          {row.productSymbol === 'USD' ? '-' : row.amount.toLocaleString()}
        </Textfit>
      )
    },
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={baseClass('columnHeader')}>
          {context.t('Avg Cost')}
        </APTypography>
      ),
      dataTest: 'DCA',
      cell: row => (
        <Textfit
          mode="single"
          min={MIN_ITEM_FONT_SIZE}
          max={maxItemFontSize}
          className={baseClass('columnItem-text')}>
          {!row.dca
            ? context.t('N/A')
            : row.dca >= 0
              ? `$${formatNumberToLocale(row.dca, 4)}`
              : `-$${formatNumberToLocale(-row.dca, 4)}`}
        </Textfit>
      )
    },
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={baseClass('columnHeader')}>
          {context.t('Total')}
        </APTypography>
      ),
      dataTest: 'Amount',
      cell: row => (
        <Textfit
          mode="single"
          min={MIN_ITEM_FONT_SIZE}
          max={maxItemFontSize}
          className={baseClass('columnItem-text')}>
          ${row.amountUSD.toLocaleString()}
        </Textfit>
      )
    },
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={baseClass('columnHeader')}>
          {context.t('WALLET %')}
        </APTypography>
      ),
      dataTest: 'Status',
      cell: row => (
        <APTypography fontSize="body" className={baseClass('columnItem-text')}>
          {row.percentage}%
        </APTypography>
      )
    }
  ];

  return (
    <div
      className={classnames(
        baseClass('wrapper'),
        isShowReminder && baseClass('wrapper-with-reminder')
      )}
      style={{ '--col-count': columns.length }}>
      <Table
        rows={items}
        columns={columns}
        baseClass={baseClass}
        headerOutside={true}
        empty={'You don’t have currencies yet'}
      />
    </div>
  );
};

CurrenciesTableComponent.propTypes = {
  items: PropTypes.array,
  isShowReminder: PropTypes.bool,
};

CurrenciesTableComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export { CurrenciesTableComponent };
