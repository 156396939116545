import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { DeleteIcon } from '../../../images/react-icons/DeleteIcon';
import format from 'date-fns/format';
import { getNotificationAttributes } from '../index';
import { MarkAsReadIcon } from '../../../images/react-icons/MarkAsReadIcon';

const classes = getBEMClasses('all-notifications-modal');

const NotificationRow = (props, context) => {
  const {
    notification,
    markNotificationAsSeen,
    removeNotification,
    isTrash
  } = props;
  const notificationDate = new Date(notification.createdAt);
  const { subClassName } = getNotificationAttributes(notification);

  return (
    <div className={classes('notification', subClassName)}>
      <div>
        <div
          className={classes('notification-text')}
          dangerouslySetInnerHTML={{ __html: notification.message }}
        />
        <div className={classes('notification-date')}>
          {format(notificationDate, 'MMMM d, y')} at{' '}
          {format(notificationDate, 'h:mm a')}
        </div>
      </div>
      <div className={classes('notification-bottom')}>
        <div className={classes('notification-buttons')}>
          {!isTrash
            ? !notification.isSeen && (
                <button
                  className={classes('mark-button')}
                  onClick={markNotificationAsSeen}>
                  <MarkAsReadIcon className={classes('mark-icon')} />
                  {context.t('Mark As Read')}
                </button>
              )
            : null}

          <button
            className={classes('delete-button')}
            onClick={removeNotification}>
            <DeleteIcon className={classes('delete-icon')} />
            {context.t('Delete')}
          </button>
        </div>
      </div>
    </div>
  );
};

NotificationRow.contextTypes = {
  t: PropTypes.func.isRequired
};

NotificationRow.propTypes = {
  notification: PropTypes.object.isRequired,
  markNotificationAsSeen: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  isTrash: PropTypes.bool
};

export { NotificationRow };
