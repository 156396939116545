import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { UrlFactory } from '../../../helpers/urlFactory';
import SectionHeader from '../../../components/common/SectionHeader/SectionHeader';
import Logo from '../../../images/icon-logo-big.png';
import { SectionWithFilterAndSearch } from '../../../components/common/SectionWithFilterAndSearch';
import { AssetsFilter } from '../../../components/common/AssetsFilter';
import { sortAssets } from '../../../helpers/sortHelper';
import {
  MarketPlaceButton,
  ViewWatchListButton
} from '../../../components/common/Buttons/Buttons';
import SelectAssetModal from '../../../components/SelectAssetModal/SelectAssetModalContainer';
import SelectAssetFromWatchListModal from '../../../components/SelectAssetFromWatchListModal/SelectAssetFromWatchListModalContainer';
import { BuySellProductCard } from '../../../components/common/BuySellProductCard';
import {
  NextSliderArrow,
  PrevSliderArrow
} from '../../../components/common/SliderArrows';
import './DashboardMarketPlaceComponent.css';

const baseClass = getBEMClasses('dashboard-market-place');

const DashboardMarketPlaceComponent = (props, context) => {
  const {
    assets,
    history,
    goToProMarketPlace,
    openSelectAssetModal,
    openWatchListModal
  } = props;

  const [isAscending, setIsAscending] = useState(false);
  const [currentFilterOption, setCurrentFilterOption] = useState(
    'Rolling24HrPxChangePercent'
  );

  const sliderRef = useRef();
  useEffect(
    () => {
      sliderRef.current && sliderRef.current.slickGoTo(0, true);
    },
    [isAscending, currentFilterOption]
  );

  const sortedAssets = useMemo(
    () =>
      [...assets].sort((a, b) =>
        sortAssets(a, b, currentFilterOption, isAscending)
      ),
    [currentFilterOption, assets, isAscending]
  );

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <NextSliderArrow iconName={'mobileSliderArrow'} />,
    prevArrow: <PrevSliderArrow iconName={'mobileSliderArrow'} />,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 2250,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: 0,
          centralMode: false,
          dots: true,
          nextArrow: <NextSliderArrow iconName={'mobileSliderArrow'} />,
          prevArrow: <PrevSliderArrow iconName={'mobileSliderArrow'} />
        }
      }
    ]
  };

  const renderNoAssets = () => {
    return (
      <div
        className={baseClass('no-products')}
        data-test="dashboard-marketplace-no-items">
        <img src={Logo} className={baseClass('no-products-logo')} alt="logo" />
        User to User Tokens for Sale Appear Here After Offerings Sell Out.
        <div
          className={baseClass('no-products-button')}
          onClick={() => history.push(UrlFactory.getOfferingsUrl())}>
          Go to Offerings
        </div>
      </div>
    );
  };

  return (
    <div
      className={baseClass('wrapper')}
      data-test="dashboard-marketplace-root">
      <SectionWithFilterAndSearch
        title={
          <SectionHeader
            title={`${context.t('Available Now')} (${assets.length})`}
            link="/market-place"
            backgroundColor="#5289F7"
            viewAllBackgroundColor="#6496F8"
            iconName="dashboardMarketPlaceSmall"
          />
        }
        filterComponent={
          <div className={baseClass('filter-wrapper')}>
            <div className={baseClass('header-buttons')}>
              <MarketPlaceButton
                customClass={baseClass('button', 'go-pro')}
                onClick={() => {
                  openSelectAssetModal();
                }}
              />
              <ViewWatchListButton
                customClass={baseClass('view-watchlist')}
                onClick={openWatchListModal}
              />
            </div>
            <AssetsFilter
              currentFilterOption={currentFilterOption}
              isAscending={isAscending}
              setCurrentFilterOption={setCurrentFilterOption}
              setIsAscending={setIsAscending}
            />
          </div>
        }>
        <div
          className={baseClass('cards', !assets.length && 'no-assets')}
          data-test="dashboard-marketplace-cards">
          {!sortedAssets.length ? (
            renderNoAssets()
          ) : (
            <Slider {...sliderSettings} ref={sliderRef}>
              {sortedAssets.map(item => {
                const color = item.product.productAttributes
                  ? item.product.productAttributes.ProductColor
                  : 'grey';
                return (
                  <BuySellProductCard
                    key={`${item.product.ProductFullName}`}
                    item={{
                      ProductFullName: item.ProductFullName,
                      BestOffer: item.BestOffer,
                      BestBid: item.BestBid,
                      Rolling24HrPxChangePercent:
                        item.Rolling24HrPxChangePercent,
                      ProductId: item.ProductId,
                      LastTradedPx: item.LastTradedPx
                    }}
                    color={color}
                    baseCurrency={item.baseCurrency}
                    cardImageURL={
                      item.product.productAttributes.ProductImageURL
                    }
                    cardBackgroundImage={
                      item.product.productAttributes.ProductCardBackground
                    }
                    buyOnly={item.amount === 0}
                    onCardPress={() =>
                      history.push(
                        UrlFactory.getMarketPlaceAssetDetailsUrl(item.ProductId)
                      )
                    }
                    onBuySell={goToProMarketPlace}
                  />
                );
              })}
            </Slider>
          )}
        </div>
      </SectionWithFilterAndSearch>
      <SelectAssetModal />
      <SelectAssetFromWatchListModal />
    </div>
  );
};

DashboardMarketPlaceComponent.propTypes = {
  assets: PropTypes.array,
  baseCurrency: PropTypes.string,
  history: PropTypes.object,
  goToProMarketPlace: PropTypes.func,
  openSelectAssetModal: PropTypes.func,
  openWatchListModal: PropTypes.func
};

DashboardMarketPlaceComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export { DashboardMarketPlaceComponent };
