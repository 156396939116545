import { PropTypes } from 'prop-types';
import React from 'react';

import './VoteInfoModals.css';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { Modal } from '../../../components/common/Modal/Modal';

const classes = getBEMClasses('vote-countdown-modal');

export const VoteCountdownModal = (props, context) => {
  const { isModalOpen, closeModal } = props;

  return (
    <Modal
      customClass={classes()}
      isOpen={isModalOpen}
      close={closeModal}
      showHeader={false}
      showCloseIcon={false}>
      <div className={classes('body')}>
        <div className={classes('title')}>{context.t('Vote Countdown')}</div>
        <div className={classes('description')}>
          {context.t(
            'Every 30 days, each collectible will be eligible to be auctioned. A timer will be visible for each collectible that will show how much time is left in this waiting period. After the 30-day period, the collectible will move to the voting stage.'
          )}
        </div>
        <button className={classes('back-button')} onClick={closeModal}>
          {context.t('Go Back')}
        </button>
      </div>
    </Modal>
  );
};

VoteCountdownModal.contextTypes = {
  t: PropTypes.func.isRequired
};

VoteCountdownModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func
};
