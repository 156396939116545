import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import SVG from 'react-inlinesvg';
import { Textfit } from 'react-textfit';
import Wave from './images/wave.svg';
import Highlight from './images/highlight.svg';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { SCROLL_STATE, useScrollState } from '../../../hooks/useScrollState';
import { useIsScrollable } from '../../../hooks/useIsScrollable';
import { NextCustomSliderArrow, PrevCustomSliderArrow } from '../SliderArrows';
import './WalletChart.css';

const baseClasses = getBEMClasses('wallet-chart');

const START_ANIMATION_DELAY = 30;
const ANIMATION_DELAY_STEP = 5;
const LABELS_SLIDE_STEP = 65;
const ON_HOLD_COLOR_VAR = 'var(--wallet-chart__on-hold-color)';

const WalletChart = (props, context) => {
  const { data = [], baseCurrency = 'USD', totalAvailableBalance } = props;
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const modifiedData = useMemo(() => {
    return [...data].map(item => ({
      ...item,
      color: item.isOnHold ? ON_HOLD_COLOR_VAR : item.color,
      label: item.isOnHold ? `${item.label} (${context.t('IN AUCTION')})` : item.label
    }));
  }, [data.length]);

  const listRef = useRef();
  const [setIsScrollableRef, isScrollable] = useIsScrollable(true);
  const [setScrollStateRef, scrollState] = useScrollState();
  const combinedListRef = useCallback(
    node => {
      listRef.current = node;
      setIsScrollableRef(node);
      setScrollStateRef(node);
    },
    [setIsScrollableRef, setScrollStateRef]
  );

  const scrollList = (toNext = false) => {
    const multiplier = toNext ? 1 : -1;
    listRef.current &&
      listRef.current.scrollBy({
        left: multiplier * LABELS_SLIDE_STEP,
        behavior: 'smooth'
      });
  };

  useEffect(
    () => {
      if (modifiedData.length) {
        animateAllWaves();
      }
    },
    [modifiedData.length]
  );

  const animateWave = (item, value, delay) => {
    let percent = 0;
    let interval;

    interval = setInterval(function () {
      percent++;
      const nextPercent = 100 - percent;
      item.style.transform = `translate(0, ${nextPercent}%)`;

      if (percent >= value) {
        clearInterval(interval);
      }
    }, delay);
  };

  const animateAllWaves = () => {
    const waves = modifiedData.map((item, index) =>
      document.getElementById(`water${index}`)
    );
    let delay = START_ANIMATION_DELAY;
    let value = 0;
    for (let i = 0; i < waves.length; i++) {
      delay -= ANIMATION_DELAY_STEP;
      value += modifiedData[i].value;
      if (waves[i]) {
        animateWave(waves[i], value, delay);
      }
    }
  };

  const resetAllWavesPosition = () => {
    const waves = modifiedData.map((item, index) =>
      document.getElementById(`water${index}`)
    );
    for (let i = 0; i < waves.length; i++) {
      waves[i].style.transform = 'translate(0,110%)';
    }
  };

  const handleSelectCurrency = item => {
    if (!item) {
      resetAllWavesPosition();
      setSelectedCurrency(null);
      animateAllWaves();
    } else {
      setSelectedCurrency(item);
      setTimeout(() => {
        let selectedWave = document.getElementById(`water${item.label}`);
        animateWave(selectedWave, item.value, 25);
        resetAllWavesPosition();
      }, 500);
    }
  };

  const getWaveStyles = wave => {
    return {
      height: '29px',
      width: '800px',
      '--color': wave.color,
      transform: wave.value < 2 ? 'scaleY(0.1)' : 'scaleY(1)',
      marginBottom: wave.value < 2 ? '-12px' : '-2px'
    };
  };

  const selectedLabel = get(selectedCurrency, 'label');

  const listData = useMemo(
    () => {
      let res = [...modifiedData];
      // make the base currency (usd) always the first
      res.sort((a, b) => {
        if (a.label === baseCurrency) {
          return -1;
        }
        if (b.label === baseCurrency) {
          return 1;
        }
        return 0;
      });
      res = res.map(item => ({
        cssModifier: {
          selected: item.label === selectedLabel
        },
        onClick: () => handleSelectCurrency(item),
        labelColor: item.color,
        label: item.label
      }));
      const allItem = {
        cssModifier: {
          selected: selectedCurrency === null
        },
        onClick: () => handleSelectCurrency(null),
        labelColor: 'linear-gradient(131.67deg, #3F8CFF 0%, #825AF6 100%)',
        label: 'ALL'
      };
      res.unshift(allItem);
      return res;
    },
    [modifiedData.length, baseCurrency, selectedLabel]
  );

  return (
    <div className={baseClasses()}>
      <div className={baseClasses('circle')} data-test="wallet-circle">
        <div className={baseClasses('inner-circle')}>
          {modifiedData.map((item, index) => (
            <div
              id={`water${index}`}
              className={baseClasses('water')}
              style={{
                '--color': item.color,
                zIndex: modifiedData.length - index
              }}
              key={`water${index}`}
              data-test="wallet-water-item">
              <SVG
                src={Wave}
                className={baseClasses('water-wave')}
                style={getWaveStyles(item)}
              />
            </div>
          ))}
          {modifiedData.length ? (
            <div
              className={baseClasses('total-balance')}
              data-test="wallet-total-balance">
              <Textfit mode="single" min={16} max={64}>
                <span className={baseClasses('total-balance-currency')}>$</span>
                {totalAvailableBalance}
              </Textfit>
            </div>
          ) : null}
        </div>
        {selectedCurrency && (
          <div className={baseClasses('stub-inner-circle')}>
            <div
              id={`water${selectedCurrency.label}`}
              className={baseClasses('water')}
              style={{
                '--color': selectedCurrency.color,
                zIndex: 15
              }}
              key={`water${selectedCurrency.label}`}>
              <SVG
                src={Wave}
                className={baseClasses('water-wave')}
                style={getWaveStyles(selectedCurrency)}
              />
            </div>
            <div
              className={baseClasses('selected-total-balance')}
              data-test="wallet-selected-total-balance">
              <Textfit mode="single" min={16} max={64}>
                <span className={baseClasses('total-balance-currency')}>$</span>
                {selectedCurrency.amountUSD}
              </Textfit>
            </div>
          </div>
        )}
        <SVG src={Highlight} className={baseClasses('highlight')} />
      </div>
      <div className={baseClasses('slider', isScrollable && 'with-arrows')}>
        {isScrollable && (
          <PrevCustomSliderArrow
            className={baseClasses('arrow', 'prev')}
            onClick={() => scrollList(false)}
            disabled={scrollState === SCROLL_STATE.START}
          />
        )}
        <div
          className={baseClasses('labels-wrapper')}
          ref={combinedListRef}
          data-test="wallet-labels">
          {listData.map(item => (
            <div
              key={`label${item.label}`}
              className={baseClasses('label-wrapper', item.cssModifier)}
              onClick={item.onClick}>
              <div
                className={baseClasses('label-color')}
                style={{ '--color': item.labelColor }}
              />
              <div className={baseClasses('label')}>{item.label}</div>
            </div>
          ))}
        </div>
        {isScrollable && (
          <NextCustomSliderArrow
            className={baseClasses('arrow', 'next')}
            onClick={() => scrollList(true)}
            disabled={scrollState === SCROLL_STATE.END}
          />
        )}
      </div>
    </div>
  );
};

WalletChart.contextTypes = {
  t: PropTypes.func.isRequired
};

WalletChart.propTypes = {
  data: PropTypes.array,
  baseCurrency: PropTypes.string,
  totalAvailableBalance: PropTypes.string
};

export default WalletChart;
