import React from 'react';

export const MarkAsReadIcon = props => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M15.3333 7.38631V7.99965C15.3325 9.43726 14.867 10.8361 14.0062 11.9875C13.1454 13.139 11.9355 13.9813 10.5569 14.3889C9.17829 14.7965 7.70484 14.7476 6.35631 14.2494C5.00779 13.7512 3.85644 12.8304 3.07397 11.6244C2.29151 10.4183 1.91986 8.99169 2.01445 7.55719C2.10904 6.12269 2.6648 4.7572 3.59885 3.66436C4.5329 2.57152 5.79519 1.8099 7.19746 1.49308C8.59973 1.17625 10.0668 1.3212 11.38 1.90631"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3332 2.66718L8.6665 9.34051L6.6665 7.34051"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
