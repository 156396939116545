import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { Table } from '../../../components/common/Table';
import { NavTab } from '../../../components/common/NavTab';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { formatDate, formatTime } from 'apex-web/lib/helpers/dateHelper';
import { APTypography } from '../../../components/common/APTypography';
import { getAutoConvertDeposit, skipNextPayment } from '../../../api/liquidApi';
import BigNumber from 'bignumber.js';
import classnames from 'classnames';
import './WalletsTransactionHistory.css';
import { SkipPaymentModal } from '../../PaymentsPage/PaymentsModals/SkipPaymentModal';

const baseClasses = getBEMClasses('transaction-history');

const autoConvertCurrency = process.env.REACT_APP_AUTOCONVERT_CURRENCY.split(
  ','
);

export const WalletsTransactionHistory = (props, context) => {
  const {
    selectedAccountId,
    getLedgerEntries,
    getDepositTickets,
    getWithdrawTickets,
    getAccountTrades,
    getOpenOrders,
    ledgerEntries,
    depositTickets,
    withdrawTickets,
    tradesTickets,
    openOrders,
    apexInstruments,
    apexProducts,
    isShowReminder,
    userId,
    usdDecimalPlaces,
    subscription,
    getSubscriptions,
    ticketsFetching
  } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [paymentToSkip, setPaymentToSkip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const activeSubscription = useMemo(
    () => {
      return (
        subscription &&
        subscription.subscriptions &&
        subscription.subscriptions[0]
      );
    },
    [subscription]
  );

  useEffect(() => {
    getSubscriptions();
  }, []);

  const onChange = (e, index) => {
    e.preventDefault();
    setActiveIndex(index);
  };

  useEffect(
    () => {
      if (selectedAccountId) {
        getDepositTickets(100);
        getWithdrawTickets(selectedAccountId, 1, 100);
        getAccountTrades(selectedAccountId, 1);
        getLedgerEntries({ accountId: selectedAccountId, limit: 100 });
        getOpenOrders(selectedAccountId, 1, 100);
      }
    },
    [selectedAccountId]
  );
  // create object where key = instrumentId key_value = data about this instrument
  // format: instruments = {[instrumentId]: {...data about this instrument}}
  const instruments = useMemo(
    () =>
      apexInstruments.reduce((obj, item) => {
        obj[item.InstrumentId] = item;
        return obj;
      }, {}),
    [apexInstruments.length]
  );

  // create object where key = productId key_value = data about this instrument
  // format: products = {[productId]: {...data about this product}}
  const products = useMemo(
    () =>
      apexProducts.reduce((obj, item) => {
        obj[item.ProductId] = item;
        return obj;
      }, {}),
    [apexProducts.length]
  );

  const mathAmount = (row, instruments) => {
    if (row.Amount) {
      // for deposit/withdrawals
      return Number(new BigNumber(row.Amount).minus(row.FeeAmt || 0));
    } else if (row.Quantity) {
      // for buy/sell
      const instrumentName =
        (instruments[row.InstrumentId] &&
          instruments[row.InstrumentId].Product1Symbol) ||
        `<Instrument Id=${row.InstrumentId}>`;

      return `${row.Quantity} ${instrumentName}`;
    } else {
      return 0;
    }
  };

  const mathFee = (row, products) => {
    if (row.FeeAmt) {
      // for deposit/withdrawals
      return row.FeeAmt;
    } else if (row.Fee) {
      // for buy/sell
      const productName =
        (products[row.FeeProductId] && products[row.FeeProductId].Product) ||
        `<Product Id=${row.FeeProductId}>`;

      return `${row.Fee} ${productName}`;
    } else {
      return 0;
    }
  };

  const columns = [
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={baseClasses('columnHeader')}>
          {context.t('Currency')}
        </APTypography>
      ),
      dataTest: context.t('Currency'),
      cell: row => (
        <APTypography
          fontSize="body"
          color="grey5"
          className={baseClasses('columnItem')}>
          {/* if row.autoConvertData does not exist, then show current currency */}
          {row.InstrumentId ||
            (row.autoConvertData && (
              <React.Fragment>
                US Dollar
                <div className={baseClasses('columnItem-small-mark')}>
                  (from {row.AssetName})
                </div>
              </React.Fragment>
            )) ||
            row.AssetName}
        </APTypography>
      )
    },
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={baseClasses('columnHeader')}>
          {context.t('Amount')}
        </APTypography>
      ),
      dataTest: context.t('Amount'),
      cell: row => (
        <APTypography
          fontSize="body"
          color="grey5"
          className={baseClasses('columnItem')}>
          {/* if row.autoConvertData does not exist, then show amount in current currency */}
          {(row.autoConvertData && (
            <React.Fragment>
              {BigNumber(row.autoConvertData.usd_value)
                .dp(usdDecimalPlaces, BigNumber.ROUND_HALF_UP)
                .toNumber()}
              <div className={baseClasses('columnItem-small-mark')}>
                (from {row.AssetName} {mathAmount(row, instruments)})
              </div>
            </React.Fragment>
          )) ||
            mathAmount(row, instruments)}
        </APTypography>
      )
    },
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={baseClasses('columnHeader')}>
          {context.t('Fee')}
        </APTypography>
      ),
      dataTest: context.t('Fee'),
      cell: row => (
        <APTypography
          fontSize="body"
          color="grey5"
          className={baseClasses('columnItem')}>
          {mathFee(row, products)}
        </APTypography>
      )
    },
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={baseClasses('columnHeader')}>
          {context.t('Price')}
        </APTypography>
      ),
      dataTest: context.t('Price'),
      cell: row => (
        <APTypography
          fontSize="body"
          color="grey5"
          className={baseClasses('columnItem')}>
          {/* NotionalValue - all time in usd */}
          {`${row.NotionalValue} USD` || 0}
        </APTypography>
      )
    },
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={baseClasses('columnHeader')}>
          {context.t('Date')}
        </APTypography>
      ),
      dataTest: context.t('Date/Time'),
      cell: row => {
        return row.IsOpenOrder ? (
          <APTypography
            fontSize="body"
            color="grey5"
            className={baseClasses('columnItem')}>
            {context.t('Open')}
          </APTypography>
        ) : (
          <div className={baseClasses('dateColumnWrapper')}>
            <APTypography
              fontSize="body"
              color="grey5"
              className={baseClasses('columnItem')}>
              {formatDate(row.TradeTimeMS || row.CreatedTimestamp)}
            </APTypography>
            <APTypography
              fontSize="body"
              color="grey5"
              className={baseClasses('columnItem')}>
              {formatTime(row.TradeTimeMS || row.CreatedTimestamp)}
            </APTypography>
          </div>
        );
      }
    },
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={baseClasses('columnHeader')}>
          {context.t('Status')}
        </APTypography>
      ),
      dataTest: context.t('Status'),
      cell: row => (
        <APTypography
          fontSize="body"
          color="grey5"
          className={baseClasses('columnItem')}>
          {row.Status || 'unknown'}
        </APTypography>
      )
    }
  ];

  const [allAutoConvertData, setAllAutoConvertData] = useState();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(
    () => {
      if (depositTickets.length && userId) {
        // get ticketNumbers of all currencies, which used autoConvert to USD
        const ticketsCurrencies = depositTickets.flatMap(
          ticket =>
            autoConvertCurrency.includes(ticket.AssetName)
              ? ticket.TicketNumber
              : []
        );
        if (!ticketsCurrencies.length) {
          return;
        }
        (async () => {
          try {
            setIsFetching(true);
            const result = await getAutoConvertDeposit(ticketsCurrencies);
            setAllAutoConvertData(result);
          } finally {
            setIsFetching(false);
          }
        })();
      }
    },
    [depositTickets.length, userId]
  );

  const skipPaymentCallback = useCallback(
    () => {
      skipNextPayment()
        .then(() => getSubscriptions())
        .then(() => {
          setPaymentToSkip(false);
          setTimeout(() => setIsLoading(false), 0);
        })
        .catch(err => {
          console.log('error', err);
          setPaymentToSkip(false);
        });
    },
    [paymentToSkip]
  );

  const fullDepositList = useMemo(
    () => {
      const ticketsWithConvert = !isEmpty(allAutoConvertData)
        ? depositTickets.map(ticket => {
            if (
              autoConvertCurrency.includes(ticket.AssetName) &&
              allAutoConvertData[ticket.TicketNumber]
            ) {
              ticket.autoConvertData = allAutoConvertData[ticket.TicketNumber];
            }
            return ticket;
          })
        : depositTickets;
      return ledgerEntries.concat(ticketsWithConvert).sort((a, b) => {
        return new Date(b.CreatedTimestamp) - new Date(a.CreatedTimestamp);
      });
    },
    [ledgerEntries, serializeTickets(depositTickets), allAutoConvertData]
  );

  const tradesWithOpenOrders = useMemo(
    () => {
      return tradesTickets.concat(openOrders).sort((a, b) => {
        return new Date(b.TradeTimeMS) - new Date(a.TradeTimeMS);
      });
    },
    [tradesTickets.length, openOrders]
  );

  const activeSubscribeComponent = () => {
    return (
      <div className={baseClasses('payment-history-active-row')}>
        <button
          className={baseClasses('skip-payment-button')}
          onClick={() => setPaymentToSkip(subscription.subscriptions[0])}>
          {context.t('Tap to Skip This Payment')}
        </button>
        <div className={baseClasses('payment-history-row', 'active')}>
          <div className={baseClasses('payment-history-column')}>
            {'US Dollar'}
          </div>
          <div className={baseClasses('payment-history-column', 'amount')}>
            {`${subscription.subscriptions[0].amount}`}
          </div>
          <div className={baseClasses('payment-history-column', 'date')}>
            <div className={baseClasses('payment-history-column-date')}>
              {formatDate(subscription.subscriptions[0].nextPaymentTimestamp)}
            </div>
            <div className={baseClasses('payment-history-column-date')}>
              {formatTime(subscription.subscriptions[0].nextPaymentTimestamp)}
            </div>
          </div>
          <div className={baseClasses('payment-history-column', 'status')}>
            {context.t('Recurring')}
          </div>
        </div>
      </div>
    );
  };

  const items = [
    useMemo(
      () => ({
        label: context.t('Deposits'),
        content: isFetching ? (
          <Spinner customClass={'transaction-history'} />
        ) : (
          <Table
            rows={fullDepositList}
            columns={columns.filter(
              item => item.dataTest !== 'Price' && item.dataTest !== 'Fee'
            )}
            baseClass={baseClasses}
            empty={'N/A'}
            additionalComponent={
              !isEmpty(activeSubscription) ? activeSubscribeComponent() : null
            }
          />
        )
      }),
      [fullDepositList, isFetching, activeSubscription]
    ),
    // TODO(Feb 15, 2022): maybe use `serializeTickets` for other tables as well.
    // not sure whether other tickets have the same fields as deposit tickets
    useMemo(
      () => ({
        label: context.t('Withdrawals'),
        content:
          isFetching || ticketsFetching ? (
            <Spinner customClass={'transaction-history'} />
          ) : (
            <Table
              rows={withdrawTickets}
              columns={columns.filter(
                item => item.dataTest !== 'Price' && item.dataTest !== 'Fee'
              )}
              baseClass={baseClasses}
              empty={'N/A'}
            />
          )
      }),
      [withdrawTickets.length, isFetching, ticketsFetching]
    ),
    useMemo(
      () => ({
        label: context.t('Buys'),
        content: (
          <Table
            rows={tradesWithOpenOrders.filter(item => item.Side === 'Buy')}
            columns={columns.filter(
              item => item.dataTest !== 'Status' && item.dataTest !== 'Currency'
            )}
            baseClass={baseClasses}
            empty={'N/A'}
          />
        )
      }),
      [tradesWithOpenOrders.length]
    ),
    useMemo(
      () => ({
        label: context.t('Sells'),
        content: (
          <Table
            rows={tradesWithOpenOrders.filter(item => item.Side === 'Sell')}
            columns={columns.filter(
              item => item.dataTest !== 'Status' && item.dataTest !== 'Currency'
            )}
            baseClass={baseClasses}
            empty={'N/A'}
          />
        )
      }),
      [tradesTickets.length]
    )
  ];

  return (
    <div
      className={classnames(
        baseClasses('wrapper'),
        isShowReminder && baseClasses('wrapper-with-reminder')
      )}>
      {instruments &&
        products && (
          <NavTab
            items={items}
            activeIndex={activeIndex}
            onChange={onChange}
            customClass={baseClasses()}
          />
        )}
      <SkipPaymentModal
        isModalOpen={paymentToSkip}
        closeModal={() => setPaymentToSkip(false)}
        payment={paymentToSkip}
        confirmSkip={skipPaymentCallback}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
    </div>
  );
};

WalletsTransactionHistory.propTypes = {
  selectedAccountId: PropTypes.number,
  getDepositTickets: PropTypes.func,
  getLedgerEntries: PropTypes.func,
  getWithdrawTickets: PropTypes.func,
  getAccountTrades: PropTypes.func,
  getOpenOrders: PropTypes.func,
  depositTickets: PropTypes.array,
  ledgerEntries: PropTypes.array,
  withdrawTickets: PropTypes.array,
  openOrders: PropTypes.array,
  apexInstruments: PropTypes.array,
  apexProducts: PropTypes.array,
  isShowReminder: PropTypes.bool,
  tradesTickets: PropTypes.array,
  userId: PropTypes.number,
  usdDecimalPlaces: PropTypes.number,
  getSubscriptions: PropTypes.func,
  subscription: PropTypes.object,
  ticketsFetching: PropTypes.bool
};

WalletsTransactionHistory.contextTypes = {
  t: PropTypes.func.isRequired
};

const serializeTickets = tickets =>
  tickets ? tickets.map(t => t.TicketNumber + t.Status).join() : 'null';
