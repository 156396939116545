import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../styles/components/panel.css';

const Panel = props => {
  const { size, order, style = {}, children } = props;
  return (
    <section className={classNames('panel', `panel-${size}`, `order-${order}`)}>
      <div
        className="panel-inner"
        style={{ ...style }}>
        {children}
      </div>
    </section>
  );
};

Panel.propTypes = {
  size: PropTypes.string,
  order: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Panel;
