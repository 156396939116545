import { buyValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { push } from 'connected-react-router';

import {
  START_ON_PLACE,
  START_ON_CONFIRMATION,
  SHOW_CONFIRMATION,
  SHOW_LOADING,
  SHOW_SUCCESS,
  UPDATE_FEE,
  UPDATE_PRICE,
  UPDATE_QUANTITY,
  SHOW_BELOW_MARKET_WARNING,
  startOnPlace,
  showConfirmation,
  SHOW_ACCEPTED,
  SHOW_ERROR,
  closeBuySellModal,
  placeOrder,
  UPDATE_MAX_USD_TOTAL,
} from '../actions/buySellModalActions';
import {
  OrderDetailsOnConfirmStep,
  OrderDetailsOnPlaceStep
} from '../../components/BuySellModal/components/OrderDetails';
import PendingOrder from '../../components/BuySellModal/components/PendingOrder';
import SuccessfulOrder from '../../components/BuySellModal/components/SuccessfulOrder';
import BelowMarketWarning from '../../components/BuySellModal/components/BelowMarketWarning';
import AcceptedOrder from '../../components/BuySellModal/components/AcceptedOrder';
import FailedOrder from '../../components/BuySellModal/components/FailedOrder';
import { getInputPriceFieldName } from '../../helpers/buySellHelper';

/* 
  This whole state slice and the related component (BuySellModal) is created 
  with 3 cases in mind:
   1) place and confirm a market order from anywhere;
   2) place and confirm a limit order from anywhere (should work with stop order too);
   3) confirm a market/limit/stop order from Pro Exchange.
   
  Let's go on each in terms of states:
   1) place and confirm a market order from anywhere:
   PLACE <-> CONFIRM -> [LOADING -> SUCCESS]/[ERROR -> PLACE]
   Notes:
   - CONFIRM has 'cancel' button that returns to PLACE
   - LOADING can't be closed manually and relies on result/error/timeout 
   (see buySellModalActions.js/placeOrder)
   - LOADING will be skipped entirely if request is too quick
   - ERROR can come before or after LOADING, depends on the exact reason
   - ERROR has 'try again' button that returns to PLACE
   - SUCCESS can lead to 'my collectibles' page

   2) place and confirm a limit order from anywhere - I'm not sure, but I think 
   it happens when there's no secondary market.
   PLACE <-> CONFIRM -> (BELOW_MARKET_WARNING) -> [LOADING -> ACCEPTED]/[ERROR -> PLACE]
   Three differences from 1):
   - No SUCCESS, only ACCEPTED - because we can't be sure that this limit order
   will be ever completed
   - PLACE component will have a price input
   - BELOW_MARKET_WARNING will be shown only on sell limit order if the price is 
   lower that the current market price. Although, AFAIK, there's no way to place 
   sell limit order outside of Pro Exchange

   3) confirm a market/limit/stop order from Pro Exchange.
   CONFIRM -> (BELOW_MARKET_WARNING) -> [LOADING -> SUCCESS/ACCEPTED]/[ERROR]
   Notes:
   - Pro Exchange has it's own form, so there's no need for PLACE state, we go 
   straight to CONFIRM.
   - No PLACE means no 'cancel' button on CONFIRM
   - BELOW_MARKET_WARNING will be shown only on sell limit order if the price is 
   lower that the current market price
   - LOADING can't be closed manually and relies on result/error/timeout 
   (see buySellModalActions.js/placeOrder)
   - LOADING will be skipped entirely if request is too quick
   - ERROR can come before or after LOADING, depends on the exact reason
   - ERROR has only 'close' button that effectively returns user to Pro Exchange 
   form
   - SUCCESS if it's a market order, otherwise only ACCEPTED - because we can't 
   be sure that an order will be ever completed.
*/

export const BUY_SELL_STATE_NAMES = {
  PLACE: 'PLACE',
  CONFIRM: 'CONFIRM',
  BELOW_MARKET_WARNING: 'BELOW_MARKET_WARNING',
  LOADING: 'LOADING',
  ACCEPTED: 'ACCEPTED',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

/**
 * Simplified states are used to decide which secondary UI elements to show.
 */
export const BUY_SELL_SIMPLIFIED_STATE_NAMES = {
  IDLE: 'IDLE',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
};

const initialState = {};

export default function modalReducer(state = initialState, { type, payload }) {
  if (state.isOpen) {
    switch (type) {
      case START_ON_PLACE:
        return {
          ...state,
          // modalDecorator.js will turn modalProps object into component props
          modalProps: {
            ...state.modalProps,
            ...getPlaceState(state),
            initialStateName: BUY_SELL_STATE_NAMES.PLACE
          }
        };
      case START_ON_CONFIRMATION:
        return {
          ...state,
          modalProps: {
            ...state.modalProps,
            ...getConfirmationState(state, true),
            initialStateName: BUY_SELL_STATE_NAMES.CONFIRM
          }
        };
      case SHOW_CONFIRMATION:
        return {
          ...state,
          modalProps: {
            ...state.modalProps,
            ...getConfirmationState(state, false)
          }
        };
      case SHOW_BELOW_MARKET_WARNING:
        return {
          ...state,
          modalProps: {
            ...state.modalProps,
            ...payload,
            ...getBelowMarketState(state.modalProps)
          }
        };
      case SHOW_LOADING:
        return {
          ...state,
          modalProps: {
            ...state.modalProps,
            ...loadingState
          }
        };
      case SHOW_SUCCESS:
        return {
          ...state,
          modalProps: {
            ...state.modalProps,
            confirmedOrder: payload,
            ...successState
          }
        };
      case SHOW_ACCEPTED:
        return {
          ...state,
          modalProps: {
            ...state.modalProps,
            ...acceptedState
          }
        };
      case SHOW_ERROR:
        return {
          ...state,
          modalProps: {
            ...state.modalProps,
            ...payload,
            ...getErrorState(state.modalProps, payload)
          }
        };
      case UPDATE_FEE:
        return {
          ...state,
          modalProps: {
            ...state.modalProps,
            orderFee: payload.orderFee,
          },
        };
      case UPDATE_MAX_USD_TOTAL:
        return {
          ...state,
          modalProps: {
            ...state.modalProps,
            maxUsdTotal: payload.maxUsdTotal,
          },
        };
      case UPDATE_QUANTITY:
        return {
          ...state,
          modalProps: {
            ...state.modalProps,
            form: {
              ...state.modalProps.form,
              quantity: payload.quantity
            },
          },
        };
      case UPDATE_PRICE:
        return {
          ...state,
          modalProps: {
            ...state.modalProps,
            form: {
              ...state.modalProps.form,
              [getInputPriceFieldName(state.modalProps.form.orderType)]: payload.price
            },
          },
        };
      default:
        return state;
    }
  } else {
    return state;
  }
}

const getOrderSide = state => {
  return state.modalProps.form.side === buyValue ? 'Buy' : 'Sell';
};

const getPlaceState = state => ({
  stateName: BUY_SELL_STATE_NAMES.PLACE,
  simplifiedStateName: BUY_SELL_SIMPLIFIED_STATE_NAMES.IDLE,
  containerProps: {
    getPropsForModalComponent: ({ context, dispatch, isTermsOfUseAccepted }) => ({
      title: context.t('Place Order'),
      footer: {
        buttonText: context.t(`Place ${getOrderSide(state)} Order`),
        overrideClose: true,
        disabled: !isTermsOfUseAccepted && state.modalProps.form.side === buyValue,
        onClick: () => dispatch(showConfirmation())
      }
    }),
    ChildModalComponent: OrderDetailsOnPlaceStep
  }
});

const getConfirmationState = (state, isInitial) => ({
  stateName: BUY_SELL_STATE_NAMES.CONFIRM,
  simplifiedStateName: BUY_SELL_SIMPLIFIED_STATE_NAMES.IDLE,
  containerProps: {
    getPropsForModalComponent: ({ context, dispatch, isMobile, isTermsOfUseAccepted }) => ({
      title: context.t('Confirmation'),
      footer: {
        buttonText: isMobile ?
          context.t(`Place ${getOrderSide(state)} Order`) :
          context.t('Confirm'),
        overrideClose: true,
        disabled: !isTermsOfUseAccepted && state.modalProps.form.side === buyValue,
        onClick: () => dispatch(placeOrder(false))
      },
      // if 'confirm' state is not initial, then we can show 'cancel' button
      // that returns user to 'place' state
      additionalFooterButton: !isInitial && !isMobile && {
        buttonText: context.t('Cancel'),
        onClick: () => dispatch(startOnPlace())
      },
      back: !isInitial && (() => dispatch(startOnPlace()))
    }),
    ChildModalComponent: OrderDetailsOnConfirmStep
  }
});

const getBelowMarketState = (previousModalProps) => {
  const isConfirmInitialState =
    previousModalProps.initialStateName === BUY_SELL_STATE_NAMES.CONFIRM;
  return {
    stateName: BUY_SELL_STATE_NAMES.BELOW_MARKET_WARNING,
    simplifiedStateName: BUY_SELL_SIMPLIFIED_STATE_NAMES.IDLE,
    containerProps: {
      getPropsForModalComponent: ({ context, dispatch }) => ({
        title: context.t('Confirmation'),
        footer: {
          buttonText: context.t(`Submit Order`),
          overrideClose: true,
          onClick: () => dispatch(placeOrder(true))
        },
        // if we came to 'below market' state from intermediate 'confirm' state,
        // then we can show 'cancel' button that returns user to 'place' state.
        // usually this warning can be seen only on Pro Exchange (which has initial
        // 'confirm' state modal, not intermediate), so probably the button won't
        // ever be shown
        additionalFooterButton: !isConfirmInitialState && {
          buttonText: context.t('Cancel'),
          onClick: () => dispatch(startOnPlace())
        },
        back: !isConfirmInitialState && (() => dispatch(startOnPlace()))
      }),
      ChildModalComponent: BelowMarketWarning
    }
  };
};

const loadingState = {
  stateName: BUY_SELL_STATE_NAMES.LOADING,
  simplifiedStateName: BUY_SELL_SIMPLIFIED_STATE_NAMES.IDLE,
  containerProps: {
    getPropsForModalComponent: () => ({
      // user can't close the modal while it's in the loading state. it's not a 
      // problem because the state will be changed to another state on error,
      // timeout or success
      close: () => { },
      showHeader: false
    }),
    ChildModalComponent: PendingOrder
  }
};

const successState = {
  stateName: BUY_SELL_STATE_NAMES.SUCCESS,
  simplifiedStateName: BUY_SELL_SIMPLIFIED_STATE_NAMES.SUCCESS,
  containerProps: {
    getPropsForModalComponent: ({ context, dispatch }) => ({
      title: context.t('Transaction Successful!'),
      footer: {
        buttonText: context.t('Go to My Collectibles'),
        overrideClose: false,
        onClick: () => dispatch(push('/my-collectibles'))
      },
      additionalFooterButton: {
        buttonText: context.t('Exit'),
        onClick: () => dispatch(closeBuySellModal())
      }
    }),
    ChildModalComponent: SuccessfulOrder
  }
};

const acceptedState = {
  stateName: BUY_SELL_STATE_NAMES.ACCEPTED,
  simplifiedStateName: BUY_SELL_SIMPLIFIED_STATE_NAMES.SUCCESS,
  containerProps: {
    getPropsForModalComponent: ({ context, dispatch }) => ({
      title: context.t('Order placed!'),
      additionalFooterButton: {
        buttonText: context.t('Exit'),
        onClick: () => dispatch(closeBuySellModal())
      }
    }),
    ChildModalComponent: AcceptedOrder
  }
};

const getErrorState = (previousModalProps, { errorMessage }) => ({
  stateName: BUY_SELL_STATE_NAMES.ERROR,
  simplifiedStateName: BUY_SELL_SIMPLIFIED_STATE_NAMES.FAILURE,
  containerProps: {
    getPropsForModalComponent: ({ dispatch, context }) => {
      const buttons = createErrorStateButtons(
        previousModalProps,
        errorMessage,
        dispatch,
        context
      );
      return {
        title: context.t('Transaction Unsuccessful!'),
        ...buttons
      };
    },
    ChildModalComponent: FailedOrder
  }
});

const createNotEnoughFundsButtons = (isConfirmInitialState, dispatch, context) => {
  return {
    footer: createWalletButton(dispatch, context),
    additionalFooterButton: createExitButton(dispatch, context)
  };
};
const createMarketPausedButtons = (isConfirmInitialState, dispatch, context) => {
  return {
    footer: createVotingButton(dispatch, context),
    additionalFooterButton: createCancelButton(dispatch, context)
  };
};
const createDefaultErrorButtons = (isConfirmInitialState, dispatch, context) => {
  return isConfirmInitialState ? {
    footer: createExitButton(dispatch, context)
  } : {
    footer: createTryAgainButton(dispatch, context),
    additionalFooterButton: createExitButton(dispatch, context)
  };
};
const errorToCustomButtonsFactory = {
  'Not_Enough_Funds': createNotEnoughFundsButtons,
  'Market_Paused': createMarketPausedButtons
};
const createErrorStateButtons = (previousModalProps, errorMessage, dispatch, context) => {
  const isConfirmInitialState =
    previousModalProps.initialStateName === BUY_SELL_STATE_NAMES.CONFIRM;
  const buttonsFactory = errorToCustomButtonsFactory[errorMessage] || createDefaultErrorButtons;
  return buttonsFactory(isConfirmInitialState, dispatch, context);
};

// All these buttons explicitly override `close` because they can be used as a 
// main footer button AND as an additional button. Additional buttons do not 
// close the modal by default, contrary to main buttons
const createWalletButton = (dispatch, context) => ({
  buttonText: context.t('Go to My Wallet'),
  overrideClose: true,
  onClick: () => {
    dispatch(closeBuySellModal());
    dispatch(push('/wallets'));
  }
});
const createVotingButton = (dispatch, context) => ({
  buttonText: context.t('Go to Live Vote'),
  overrideClose: true,
  onClick: () => {
    dispatch(closeBuySellModal());
    dispatch(push('/vote-page'));
  }
});
const createExitButton = (dispatch, context) => ({
  buttonText: context.t('Exit'),
  overrideClose: true,
  onClick: () => dispatch(closeBuySellModal())
});
// same as 'exit'. yeah, dunno why we need it, but figma has it 
const createCancelButton = (dispatch, context) => ({
  buttonText: context.t('Cancel'),
  overrideClose: true,
  onClick: () => dispatch(closeBuySellModal())
});
const createTryAgainButton = (dispatch, context) => ({
  buttonText: context.t('Try again'),
  overrideClose: true,
  onClick: () => dispatch(startOnPlace())
});

