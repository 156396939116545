import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import path from '../../helpers/path';
import { useIsMobile } from '../../hooks/useIsMobile';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import APIcon from '../common/APIcon';
import { APTypography } from '../common/APTypography';

import './APNavMenuHorizontal.css';

const baseClass = 'nav-menu-horizontal';

const APNavMenuHorizontal = (props, context) => {
  const isMobile = useIsMobile();
  const bemClasses = getBEMClasses(baseClass);

  return (
    <div className={bemClasses('container')} id="settings-navigation">
      <ul className={bemClasses('list')}>
        {props.items.filter(i => i.show === undefined || i.show).map(item => (
          <li key={item.path} data-test={item.text}>
            <NavLink
              className={bemClasses('setting-item', ['hoverable'])}
              activeClassName={bemClasses('setting-item', ['selected'])}
              to={path(item.path)}>
              {item.icon && (
                <APIcon
                  name={item.icon}
                  customClass={bemClasses('item-icon')}
                />
              )}
              <APTypography fontSize={!isMobile ? 'caption2' : 'small3'}>
                {context.t(item.text)}
              </APTypography>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

APNavMenuHorizontal.propTypes = {
  items: PropTypes.array
};

APNavMenuHorizontal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default APNavMenuHorizontal;
