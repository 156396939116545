import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import BigNumber from 'bignumber.js';
import ReactTooltip from 'react-tooltip';
import { useDebounce, useThrottle } from 'react-use';

import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { biggerThanOrEqualToValue, requiredNumeric } from 'apex-web/lib/helpers/formValidations';
import {
  getOrdersBySide,
  getPriceForQuantity,
  getQuantityForPrice,
  getSubmitButtonText
} from 'apex-web/lib/helpers/orderHelper.js';
import { styleNames } from 'apex-web/lib/propTypes/commonComponent';
import {
  orderTypes,
  sellValue,
  buyValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { instrumentPropType } from 'apex-web/lib/propTypes/instrumentPropTypes';
import { convertIncrementToIntDecimalPlaces as iToDp } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import { isLimitPriceFieldInForm, isStopPriceFieldInForm } from 'apex-web/lib/helpers/placeOrderHelper';

import { numberOrBigNumberType } from '../BuySellModal/buySellFormPropType';
import APSegmentedButton from '../common/APSegmentedButton/APSegmentedButton';
import APButton from '../common/APButton/APButton';
import APInput, { InnerInput as ControlledAPInput } from '../common/APInputWithReduxForm/APInput';
import PercentageSlider from '../common/PercentageSlider';
import { formatNumberToLocale } from '../../helpers/numberFormatter';
import {
  getNormalizationCallback,
  getNormalizationRegExp,
  preventSomeNumberSymbols
} from '../../helpers/buySellHelper';
import config from '../../config';

import 'apex-web/lib/styles/components/TradeComponent.css';
import './OrderEntryForm.css';

const baseClass = 'order-entry-form';
const baseClasses = getBEMClasses(baseClass);

const OrderEntryFormComponent = (props, context) => {
  const {
    orderEntryForm,
    selectedInstrument,
    submitting = false,
    fetching,
    disableTrading,
    usdBalance,
    tokenBalance,
    level2,
    lastPrice,
    updateQuantity,
    resetQuantity,
    updateStopPrice,
    updateLimitPrice,
    calcFee,
    calcMaxUsdTotal,
    isSellDisable,
    onlyMarketAvailable,
    onlyLimitAvailable,
    isTermsOfUseAccepted
  } = props;
  const formValues = orderEntryForm.values || {};
  const {
    side,
    orderType,
    quantity,
    limitPrice,
    stopPrice,
    maxUsdTotal = usdBalance,
    fee: propsFee
  } = formValues;
  const fee = propsFee && propsFee.fee && propsFee.product ?
    orderEntryForm.values.fee :
    null;

  const invalid = props.invalid || !quantity ||
    Number(quantity) < selectedInstrument.MinimumQuantity;
  const isDisablePlaceOrder =
    (side === sellValue && isSellDisable) ||
    (side === buyValue && !isTermsOfUseAccepted) ||
    (onlyMarketAvailable &&
      orderType !== orderTypes.market.displayName) ||
    (onlyLimitAvailable &&
      orderType !== orderTypes.limit.displayName);

  const styleName = side === buyValue ? styleNames.additive : styleNames.subtractive;

  const onOrderTypeChange = useCallback(() => resetQuantity(), [resetQuantity]);

  // change level2 data in four cases:
  // 1) selected instrument has changed
  // 2) level2 was empty and now we finally got the first value
  // 3) 15 minutes has passed and we have new updates
  // 4) user submits the form
  const [usableLevel2, setUsableLevel2] = useState(level2);
  // 1) and 2)
  const cachedLevel2 = useInstrumentCache(level2, selectedInstrument.InstrumentId);
  useEffect(() => {
    setUsableLevel2(cachedLevel2);
  }, [cachedLevel2]);
  // 3)
  const throttledLevel2 = useThrottle(level2, LEVEL2_UPDATE_WINDOW, [level2]);
  useEffect(() => {
    if (throttledLevel2) {
      setUsableLevel2(throttledLevel2);
    }
  }, [throttledLevel2]);
  // 4)
  const handleSubmit = e => {
    setUsableLevel2(level2);
    props.handleSubmit(e);
  };

  const cachedLastPrice = useInstrumentCache(
    lastPrice,
    selectedInstrument.InstrumentId,
  );

  const userPrice = getUserPrice(orderType, stopPrice, limitPrice);
  const orders = getOrdersBySide(side, usableLevel2 || { buy: [], sell: [] });

  useEffect(() => {
    updateLimitPrice(cachedLastPrice);
    updateStopPrice(cachedLastPrice);
  }, [Number(cachedLastPrice)]);

  useDebounce(
    () => {
      if (!fetching) {
        calcMaxUsdTotal([...orders]);
      }
    },
    700,
    [
      fetching,
      orders,
      side,
      orderType,
      Number(usdBalance)
    ]
  );

  const normalizationRegexps = useMemo(() => ({
    price: getNormalizationRegExp(iToDp(selectedInstrument.PriceIncrement)),
    quantity: getNormalizationRegExp(iToDp(selectedInstrument.QuantityIncrement))
  }), [selectedInstrument.InstrumentId]);
  const normalizePriceInput = useCallback(
    getNormalizationCallback(normalizationRegexps.price),
    [normalizationRegexps]
  );
  const normalizeQuantityInput = useCallback(
    getNormalizationCallback(normalizationRegexps.quantity),
    [normalizationRegexps]
  );

  const [total, setTotal] = useState();
  const [isTotalFocused, setIsTotalFocused] = useState(false);
  useEffect(() => {
    if (!isTotalFocused) {
      let value;
      if (!isNaN(quantity) && quantity > 0) {
        value = quantityToTotal(quantity, orders, side, orderType, userPrice)
          .dp(iToDp(selectedInstrument.PriceIncrement), BigNumber.ROUND_HALF_UP)
          .toNumber();
      }
      setTotal(value);
    }
  }, [isTotalFocused, Number(quantity), orders, side, orderType, Number(userPrice)]);
  useEffect(() => {
    // handle an empty string, otherwise BigNumber will treat it as NaN
    const numberTotal = Number(total);
    if (!isNaN(numberTotal) && userPrice > 0 && isTotalFocused) {
      let newQuantity = totalToQuantity(numberTotal, orders, side, orderType, userPrice).dp(
        iToDp(selectedInstrument.QuantityIncrement),
        side === buyValue ? BigNumber.ROUND_FLOOR : BigNumber.ROUND_CEIL
      );
      if (newQuantity.eq(0)) {
        newQuantity = '';
      }
      updateQuantity(newQuantity);
    }
  }, [total, isTotalFocused, orders, side, orderType, Number(userPrice)]);

  const totalListeners = useMemo(() => ({
    onChange: e => {
      const newValue = e.target.value;
      setTotal(prevValue =>
        normalizePriceInput(newValue, prevValue)
      );
    },
    onFocus: () => setIsTotalFocused(true),
    onBlur: () => setIsTotalFocused(false)
  }), [normalizePriceInput]);
  const totalInput = {
    value: total ? total.toString() : ''
  };

  const percentage = useMemo(() => {
    let cost, balance;
    if (side === buyValue) {
      cost = BigNumber(totalInput.value);
      balance = maxUsdTotal;
    } else {
      cost = BigNumber(quantity);
      balance = tokenBalance;
    }
    return balance > 0 ?
      cost.div(balance).times(100).dp(0, BigNumber.ROUND_HALF_UP).toNumber() :
      0;
  }, [side, totalInput.value, Number(quantity), Number(maxUsdTotal), Number(tokenBalance)]);

  const onPickerChange = useCallback(newPercentage => {
    let newQuantity;
    if (side === buyValue) {
      const total = BigNumber(maxUsdTotal)
        .div(100)
        .times(newPercentage);
      newQuantity = totalToQuantity(total, orders, side, orderType, userPrice)
        .dp(
          iToDp(selectedInstrument.QuantityIncrement),
          BigNumber.ROUND_FLOOR
        );
    } else {
      newQuantity = BigNumber(tokenBalance);
      if (newPercentage !== 100) {
        newQuantity = BigNumber.min(
          newQuantity
            .div(100)
            .times(newPercentage)
            .dp(
              iToDp(selectedInstrument.QuantityIncrement),
              BigNumber.ROUND_HALF_UP
            ),
          tokenBalance
        );
      }
    }
    updateQuantity(newQuantity);
  }, [side, Number(userPrice), Number(maxUsdTotal), Number(tokenBalance), orderType]);

  useDebounce(
    () => {
      if (!fetching) {
        calcFee();
      }
    },
    700,
    [
      fetching,
      orders,
      side,
      orderType,
      Number(quantity),
      Number(userPrice)
    ]);

  return (
    <div className={baseClasses()}>
      <form
        onSubmit={isDisablePlaceOrder ? () => { } : handleSubmit}
        className={baseClasses('form', styleName)}>
        <div className={baseClasses('body')}>
          <div className={baseClasses('order-type-wrapper')}>
            <APSegmentedButton
              name="orderType"
              items={[
                {
                  value: orderTypes.limit.displayName,
                  text: context.t('Limit'),
                  dataTest: 'Limit Order Type',
                  disabled: onlyMarketAvailable,
                  tooltip: onlyMarketAvailable
                    ? 'You can make only market order while primary order available'
                    : ''
                },
                {
                  value: orderTypes.market.displayName,
                  text: context.t('Market'),
                  dataTest: 'Market Order Type',
                  disabled: onlyLimitAvailable,
                  tooltip: onlyLimitAvailable
                    ? 'You can make only limit order while primary order available'
                    : ''
                },
                {
                  value: orderTypes.stopLimit.displayName,
                  text: context.t('Stop-limit'),
                  dataTest: 'Stop Order Type',
                  disabled: onlyMarketAvailable || onlyLimitAvailable,
                  tooltip: onlyMarketAvailable
                    ? 'You can make only market order while primary order available'
                    : onlyLimitAvailable
                      ? 'You can make only limit order while primary order available'
                      : ''
                }
              ]}
              customClass={baseClass}
              styleName={styleName}
              onChange={onOrderTypeChange}
            />
          </div>
          {side === buyValue ?
            (
              <Balance
                label={context.t('Your Available Funds')}
                value={usdBalance}
                dp={iToDp(selectedInstrument.PriceIncrement)}
                symbol={selectedInstrument.Product2Symbol}
              />
            ) : (
              (
                <Balance
                  label={context.t('Your Available Tokens')}
                  value={tokenBalance}
                  dp={iToDp(selectedInstrument.QuantityIncrement)}
                  symbol={selectedInstrument.Product1Symbol}
                />
              )
            )
          }
        </div>
        {orderType === orderTypes.market.displayName && (
          <ControlledAPInput
            disabled
            customClass={baseClasses('field')}
            classModifiers={'market'}
            label={'Price'}
            rightLabelText={selectedInstrument.Product2Symbol}
            placeholder={context.t('Market')} />
        )}
        {isStopPriceFieldInForm(orderType) && (
          <APInput
            name='stopPrice'
            normalize={normalizePriceInput}
            customClass={baseClasses('field')}
            classModifiers={'stop'}
            label={orderType === orderTypes.stopLimit.displayName ? 'Stop Price' : 'Price'}
            rightLabelText={selectedInstrument.Product2Symbol}
            type='number'
            placeholder='0'
            min={0}
            onKeyDown={preventSomeNumberSymbols}
            // TODO(May 24, 2022): validate stop price (lower than best bid or higher than best offer)
            validate={[
              requiredNumeric,
              biggerThanOrEqualToValue(selectedInstrument.MinimumPrice)
            ]}
            step={selectedInstrument.PriceIncrement}
            alwaysControlled
          />
        )}
        {isLimitPriceFieldInForm(orderType) && (
          <APInput
            name='limitPrice'
            normalize={normalizePriceInput}
            customClass={baseClasses('field')}
            classModifiers={'limit'}
            label={orderType === orderTypes.stopLimit.displayName ? 'Limit Price' : 'Price'}
            rightLabelText={selectedInstrument.Product2Symbol}
            type='number'
            placeholder='0'
            min={0}
            onKeyDown={preventSomeNumberSymbols}
            validate={[
              requiredNumeric,
              biggerThanOrEqualToValue(selectedInstrument.MinimumPrice)
            ]}
            step={selectedInstrument.PriceIncrement}
            alwaysControlled
          />
        )}

        <APInput
          name='quantity'
          normalize={normalizeQuantityInput}
          customClass={baseClasses('field')}
          classModifiers={'quantity'}
          label='Amount'
          rightLabelText={selectedInstrument.Product1Symbol}
          type='number'
          placeholder='0'
          min={0}
          onKeyDown={preventSomeNumberSymbols}
          validate={[
            requiredNumeric,
            biggerThanOrEqualToValue(selectedInstrument.MinimumQuantity)
          ]}
          step={selectedInstrument.QuantityIncrement}
          alwaysControlled
        />

        <PercentageSlider
          value={percentage}
          onChange={onPickerChange}
          step={1}
          marks={pickerMarks} />

        <ControlledAPInput
          customClass={baseClasses('field')}
          classModifiers={'total'}
          label='Total'
          rightLabelText={selectedInstrument.Product2Symbol}
          type='number'
          placeholder='0'
          min={0}
          onKeyDown={preventSomeNumberSymbols}
          step={selectedInstrument.PriceIncrement}
          alwaysControlled
          input={totalInput}
          {...totalListeners}
        />

        <div className={baseClasses('fee-container')}>
          <span className={baseClasses('fee-key')}>{context.t('Fee')}</span>
          <span className={baseClasses('fee-sign')}>{side === buyValue ? '+' : '-'}</span>
          <span className={baseClasses('fee-value')}>{
            formatNumberToLocale(
              BigNumber(get(fee, 'fee', 0)),
              iToDp(selectedInstrument.PriceIncrement)
            )
          }</span>
          <span className={baseClasses('fee-symbol')}>{
            get(fee, 'product.Product', selectedInstrument.Product2Symbol)
          }</span>
        </div>

        {side === buyValue && percentage >= 100 && (
          <div className={baseClasses('tip')}>
            <span className={baseClasses('tip-title')}>{context.t('Tip')}: </span>
            {context.t('If the available funds in your wallet are not divisible by the token value, the left over value will remain in your wallet.')}
          </div>
        )}

        <div className={baseClasses('submit-container')}>
          <APButton
            type="submit"
            disabled={
              submitting ||
              fetching ||
              invalid ||
              disableTrading ||
              isDisablePlaceOrder
            }
            data-tip="You can`t place order while primary order available"
            data-tip-disable={!isDisablePlaceOrder}
            customClass={baseClasses('submit')}
            styleName={side === buyValue ? styleNames.additive : styleNames.subtractive}>
            {submitting || !orderEntryForm.values
              ? context.t('Processing...')
              : context.t(getSubmitButtonText(orderEntryForm))}
          </APButton>
        </div>
      </form >
      <ReactTooltip />
    </div >
  );
};

// eslint-disable-next-line react/prop-types
const Balance = ({ label, value, dp, symbol }) => (
  <div className={baseClasses('balance')}>
    <span className={baseClasses('balance-key')}>
      {label}
    </span>
    <div className={baseClasses('balance-value')}>
      <span className={baseClasses('balance-value-number')}>
        {formatNumberToLocale(BigNumber(value).dp(dp, BigNumber.ROUND_FLOOR), dp)}
      </span>
      <span className={baseClasses('balance-value-symbol')}>
        {symbol}
      </span>
    </div>
  </div>
);

const { OrderCalcBuffer: { quantityBuffer, priceBuffer } = {} } = config;

const quantityToTotal = (quantity, orders, side, orderType, userPrice) => {
  if (orderType === orderTypes.market.displayName) {
    const rawTotal = getPriceForQuantity(quantity, priceBuffer, [...orders], true, side).Price;
    return !isNaN(rawTotal) ? BigNumber(rawTotal) : BigNumber(0);
  } else {
    return BigNumber(userPrice).times(quantity);
  }
};

const totalToQuantity = (total, orders, side, orderType, userPrice) => {
  if (orderType === orderTypes.market.displayName) {
    const rawQuantity = getQuantityForPrice(total, quantityBuffer, [...orders], true, side).Quantity;
    return !isNaN(rawQuantity) ? BigNumber(rawQuantity) : BigNumber(0);
  } else {
    return userPrice > 0 ? BigNumber(total).div(userPrice) : BigNumber(0);
  }
};

const getUserPrice = (orderType, stopPrice, limitPrice) => {
  const mainPrice = !isLimitPriceFieldInForm(orderType) ? stopPrice : limitPrice;
  return BigNumber(isNaN(mainPrice) ? 0 : mainPrice);
};

const useInstrumentCache = (content, instrumentId, deps = []) => {
  const [cache, setCache] = useState({
    instrumentId,
    content
  });
  useEffect(() => {
    setCache(prev => {
      return prev.instrumentId !== instrumentId ||
        !prev.content ?
        {
          instrumentId,
          content
        } :
        prev;
    });
  }, [content, instrumentId, ...deps]);
  return cache.content;
};

const pickerMarks = [0, 25, 50, 75, 100];
const LEVEL2_UPDATE_WINDOW = 1000 * 60 * 15; // 15 minutes

OrderEntryFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  selectedInstrument: instrumentPropType,
  orderEntryForm: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  fetching: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  disableTrading: PropTypes.bool.isRequired,
  usdBalance: numberOrBigNumberType,
  tokenBalance: numberOrBigNumberType,
  level2: PropTypes.shape({
    buy: PropTypes.array.isRequired,
    sell: PropTypes.array.isRequired
  }),
  lastPrice: numberOrBigNumberType,
  updateQuantity: PropTypes.func.isRequired,
  resetQuantity: PropTypes.func.isRequired,
  updateStopPrice: PropTypes.func.isRequired,
  updateLimitPrice: PropTypes.func.isRequired,
  calcFee: PropTypes.func.isRequired,
  calcMaxUsdTotal: PropTypes.func.isRequired,
  isSellDisable: PropTypes.bool,
  onlyMarketAvailable: PropTypes.bool,
  onlyLimitAvailable: PropTypes.bool,
  isTermsOfUseAccepted: PropTypes.bool
};

OrderEntryFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default OrderEntryFormComponent;
