import { callAPI } from 'apex-web/lib/redux/actions/apiActions';

export const REQUEST_TRADES = 'REQUEST_TRADES';
export const RECEIVE_TRADES = 'RECEIVE_TRADES';

const requestTrades = () => ({
  type: REQUEST_TRADES
});

const receiveTrades = trades => ({
  type: RECEIVE_TRADES,
  payload: {
    trades
  }
});

export const getRecentActivity = () => async (dispatch, getState) => {
  const {
    user: { selectedAccountId: AccountId, userInfo },
  } = getState();
  const { OMSId } = userInfo;
  dispatch(requestTrades());
  const trades = await dispatch(
    callAPI('GetAccountTrades', {
      AccountId,
      OMSId,
      Count: 300,
      StartIndex: 0
    })
  );
  dispatch(receiveTrades(trades));
};
