import React from 'react';
import PropTypes from 'prop-types';

const PaymentNotifications = props => {
  const { showNotification } = props;

  React.useEffect(() => {
    const redirectStatus = new URLSearchParams(window.location.search).get(
      'redirect_status'
    );

    if (!redirectStatus) {
      return;
    }

    switch (redirectStatus) {
      case 'succeeded':
        showNotification('Payment succeeded!', 'success');
        break;
      case 'processing':
        showNotification('Your payment is processing.', 'warning');
        break;
      case 'requires_payment_method':
        showNotification(
          'Your payment was not successful, please try again.',
          'warning'
        );
        break;
      default:
        showNotification('Something went wrong.', 'warning');
        break;
    }
  }, []);

  return null;
};

PaymentNotifications.propTypes = {
  showNotification: PropTypes.func
};

export default PaymentNotifications;
