import { loadStartupData } from 'apex-web/lib/redux/actions/appActions';
import {
  CONNECTION_OPENED,
  CONNECTION_CLOSED,
  connectionOpened,
  connectionClosed,
  connectionClosedCount
} from 'apex-web/lib/redux/actions/wsConnectionActions';
import { onInit } from 'apex-web/lib/redux/actions/lifeCycleActions';
import delay from 'lodash/delay';
import { bindActionCreators } from 'redux';
import { initApex } from '../../apex';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';

// delays[0] will be used to debounce the first reconnection attempt.
// the rest control delays between subsequent attempts and are related to wsCloseCount
const delays = [
  2000,
  10000,
  10000,
  10000,
  30000,
  30000,
  30000,
  60000,
  60000,
  60000,
  120000
];

let timeoutId = null;

export default store => next => action => {
  switch (action.type) {
    case CONNECTION_OPENED:
      // cancel a possible reconnection attempt
      clearTimeoutIfNeeded();
      store.dispatch(connectionClosedCount(0));
      store.dispatch(loadStartupData());
      store.dispatch(onInit());
      break;
    case CONNECTION_CLOSED: {
      if (store.getState().wsConnection.tries === 0) {
        // debounce the first reconnection attempt 
        clearTimeoutIfNeeded();
        timeoutId = setTimeout(() => {
          handleClosed(store);
        }, delays[0]);
      } else {
        handleClosed(store);
      }
      break;
    }
    default:
      break;
  }

  return next(action);
};

const clearTimeoutIfNeeded = () => {
  if (timeoutId) {
    clearTimeout(timeoutId);
    timeoutId = null;
  }
};

const handleClosed = (store) => {
  const wsCloseCount = store.getState().wsConnection.tries + 1;
  let delayTimer = wsCloseCount >= delays.length
    ? delays[delays.length - 1]
    : delays[wsCloseCount];

  store.dispatch(connectionClosedCount(wsCloseCount));
  store.dispatch(connectionWarningSnackbar(wsCloseCount, delayTimer));

  const open = bindActionCreators(connectionOpened, store.dispatch);
  const close = bindActionCreators(connectionClosed, store.dispatch);
  delay(initApex, delayTimer, open, close);
};

const connectionWarningSnackbar = (attemptCount, delayTimer) => {
  // context.t('Connection with server lost. Attempting reconnect...')
  let snackText = 'Connection with server lost. Attempting reconnect...';
  if (attemptCount > 1) {
    // context.t('Still unable to connect after {attemptCount} attempts, trying again...')
    snackText =
      'Still unable to connect after {attemptCount} attempts, trying again...';
  }

  return showSnack({
    id: 'websocketConnectionLost',
    text: snackText,
    textVars: { attemptCount },
    type: 'warning',
    timeout: delayTimer
  });
};
