import React from "react";
import PropTypes from 'prop-types';
import { getBEMClasses } from "../../../helpers/cssClassesHelper";
import './PriceWarningTag.css';

const classes = getBEMClasses('price-warning-tag');

const PriceWarningTag = (props, context) => (
  <div className={classes()}>
    {context.t('Above original listed price')}
  </div>
);

PriceWarningTag.contextTypes = {
  t: PropTypes.func
};

export default PriceWarningTag;
