import React from 'react';
import PropTypes from 'prop-types';
import ContentWithHeaderLayoutTemplate from '../../../common/LayoutTemplates/ContentWithHeaderLayoutTemplate/ContentWithHeaderLayoutTemplate';
import EnableTwoFactorAuthContainerFactory from '../../../EnableTwoFactorAuth/EnableTwoFactorAuthContainerFactory';
import EnableTwoFactorAuthComponent from './EnableTwoFactorAuth/EnableTwoFactorAuthComponent';
import { getBEMClasses } from '../../../../helpers/cssClassesHelper';

const EnableTwoFactorAuthContainer = EnableTwoFactorAuthContainerFactory(
  EnableTwoFactorAuthComponent
);

var profileAndSecurityWidgetClasses = getBEMClasses([
  'profile-and-security-widget'
]);

const TwoFactorAuthSettingsCardComponent = (props, context) => {
  var getLayoutHeader = function getLayoutHeader(header, classes) {
    return React.createElement(
      'div',
      { className: classes('header') },
      React.createElement('div', { className: classes('header-text') }, header)
    );
  };
  return (
    <ContentWithHeaderLayoutTemplate
      header={getLayoutHeader(
        context.t('Two-Factor Authentication'),
        profileAndSecurityWidgetClasses
      )}
      customClass={"profile-and-security-widget"}
      classModifiers={['sm']}>
      <EnableTwoFactorAuthContainer />
    </ContentWithHeaderLayoutTemplate>
  );
};

TwoFactorAuthSettingsCardComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default TwoFactorAuthSettingsCardComponent;
