import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

export const Menu = props => {
  const { onChange, activeIndex, classModifiers, items, classes } = props;
  return (
    <div className={classes('menu', classModifiers)}>
      {items.map((item, index) => {
        const activeClassName =
          activeIndex === index
            ? classes(
                'menu-item',
                ['active'].concat(classModifiers.map(i => `${i}-active`))
              )
            : '';
        return (
          <div
            className={classnames(
              activeClassName,
              classes('menu-item', classModifiers)
            )}
            onClick={e => {
              if (item.onSelect) {
                item.onSelect();
              }
              onChange(e, index);
            }}
            key={item.label}>
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

Menu.propTypes = {
  onChange: PropTypes.func.isRequired,
  activeIndex: PropTypes.number.isRequired,
  classModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
      onSelect: PropTypes.func
    })
  ).isRequired,
  classes: PropTypes.func
};
