import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './WalletsSummary.css';
import { ModalWithCurrencySelect } from '../../../components/ModalWithCurrencySelect/ModalWithCurrencySelect';

const baseClasses = getBEMClasses('wallets-summary-header-buttons');

const WalletsSummaryHeaderButtons = (props, context) => {
  const { isTermsOfUseAccepted, openUpdateTermsOfUseModal } = props;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const openModal = useCallback(
    () => {
      setIsModalOpened(!isModalOpened);
    },
    [isModalOpened]
  );

  const openModalDeposit = () => {
    if (isTermsOfUseAccepted) {
      setTabIndex(0);
      openModal();
    } else {
      openUpdateTermsOfUseModal({
        additionalHandler: () => {
          setTabIndex(0);
          openModal();
        }
      });
    }
  };

  const openModalWithdraw = () => {
    setTabIndex(1);
    openModal();
  };

  return (
    <React.Fragment>
      <div className={baseClasses('container')}>
        <button
          onClick={openModalDeposit}
          className={baseClasses('deposit')}
          disabled={!isTermsOfUseAccepted}
          type='button'>
          {context.t('deposit')}
        </button>
        <div onClick={openModalWithdraw} className={baseClasses('withdraw')}>
          {context.t('withdraw')}
        </div>
      </div>
      <ModalWithCurrencySelect
        openModal={openModal}
        isModalOpened={isModalOpened}
        tabIndex={tabIndex}
      />
    </React.Fragment>
  );
};

WalletsSummaryHeaderButtons.contextTypes = {
  t: PropTypes.func.isRequired
};

WalletsSummaryHeaderButtons.propTypes = {
  isTermsOfUseAccepted: PropTypes.bool,
  openUpdateTermsOfUseModal: PropTypes.func
};

export { WalletsSummaryHeaderButtons };
