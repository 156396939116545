import Avatar from './assets/avatar.png';
import Video from './assets/video.png';

export const darrenJackPageProps = {
  main: {
    name: 'Darren Jack',
    subName: 'Entrepreneur',
    specialty: "Motorsports",
    topCollectible: "PSA 10 Lewis Hamilton",
    significantProjects: [
      'Founder of Hall of Fame Collection',
      'Liquid MarketPlace Brand Ambassador'
    ],
    collectiblesIds: [25],
    description: `As the founder of the Hall of Fame Collection, Darren Jack owns one of the largest motorsport memorabilia collections in the world. His company includes collectibles from Formula 1, IndyCar, Sportscar, Motorcycle Race, NASCAR, and The Ron Burton collections.
    Darren Jack's dedication to his all-consuming passion for collecting and motorsports began at the age of 4. During his decades working in the industry, Darren has gained much expertise in motorsports and collecting. He has built an impressive collection in the motorsport world, leading him to establish plenty of connections that have given him various useful insights into the industry. These all help him ensure the continuous growth of his immense collection, which is why his buyers truly love and trust him; they can see not just his talent in obtaining collectibles and assets, but his passion for the industry, which is inspiring to many.`,
    avatar: Avatar,
    video: Video,
    collectiblesTitle: "Darren Jack’s Collectibles",
    videoLink: 'https://www.instagram.com/p/Cd6HG_CjkDZ/',
  },
  description: {
    title: "Darren’s Collectibles Journey",
    descriptions: [
      'At the moment, Darren has listed on Liquid MarketPlace a PSA 10 Lewis Hamilton one-of-one trading card, which is a huge deal in the racing and collectible worlds at large. This makes sense as Lewis Hamilton is, arguably, the world’s best F1 driver. He has the most Grand Prix and podium positions of any driver in history, as well as a total of seven Formula 1 World Championships.',
      'As a Brand Ambassador, Darren Jack provides Liquid MarketPlace users access to the rarest assets from his collection, providing the opportunity for fractionalized ownership for motorsport enthusiasts everywhere.'
    ],
  }
};
