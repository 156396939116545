import {
  USER_CONFIG_RECEIVED,
  REMINDER_WAS_SHOWN,
  USER_BALANCE_RECEIVED,
  VERIFICATION_REMINDER_WAS_SHOWN,
  VERIFICATION_INFO_RECEIVED,
  USER_COUNTRY_RECEIVED,
  MAINTENANCE_STATE_CHANGED,
  SET_USER_EXP,
  RESET_USER_CONFIG
} from '../actions/userActions';

const initialState = {
  fetching: false,
  isReminderWasShown: true,
  isVerificationReminderWasShown: true,
  resolvedUserCountry: null,
  isMaintenance: false,
  userTier: {
    exp: 0,
    userTier: 1,
    monthExp: 0,
    monthUserTier: 1,
    degree: 0,
    currentGrandMaster: false
  },
  isTierLoaded: false
};

export default function userConfigReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case RESET_USER_CONFIG:
      return initialState;
    case USER_CONFIG_RECEIVED:
      return {
        ...state,
        fetching: true
      };
    case REMINDER_WAS_SHOWN:
      localStorage.setItem('isReminderWasShown', true);
      return {
        ...state,
        isReminderWasShown: true
      };
    case USER_BALANCE_RECEIVED: {
      const isReminderWasShownFromLocalStorage = localStorage.getItem(
        'isReminderWasShown'
      );
      return {
        ...state,
        isReminderWasShown:
          isReminderWasShownFromLocalStorage === 'false' ? false : true
      };
    }
    case VERIFICATION_REMINDER_WAS_SHOWN:
      localStorage.setItem('isVerificationReminderWasShown', true);
      return {
        ...state,
        isVerificationReminderWasShown: true
      };
    case VERIFICATION_INFO_RECEIVED: {
      const isVerificationReminderWasShownLS = localStorage.getItem(
        'isVerificationReminderWasShown'
      );
      return {
        ...state,
        isVerificationReminderWasShown:
          isVerificationReminderWasShownLS === 'true' ? true : false
      };
    }
    case USER_COUNTRY_RECEIVED:
      return {
        ...state,
        resolvedUserCountry: payload.country
      };
    case MAINTENANCE_STATE_CHANGED:
      return {
        ...state,
        isMaintenance: payload.isMaintenance
      };
    case SET_USER_EXP:
      return {
        ...state,
        userTier: { ...state.userTier, ...payload },
        isTierLoaded: true
      };
    default:
      return state;
  }
}
