import { getDepositInfo } from 'apex-web/lib/redux/actions/depositActions';
import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { formatErrorText } from 'apex-web/lib/helpers/errorTextFormatter';

export const SUBMITTING_DEPOSIT = 'SUBMITTING_DEPOSIT';

export const createDepositTicket = ({
  ProductId,
  amount,
  ...info
}) => async (dispatch, getState) => {
  const { OMSId } = getState().user.userInfo;
  const AccountId = getState().user.selectedAccountId;
  const { UseGetDepositWorkflow } = getState().deposit.templateInfo;
  const DepositInfo = JSON.stringify(info);
  const payload = {
    OMSId,
    OperatorId: 1,
    AccountId,
    RequestUser: AccountId,
    AssetId: +ProductId,
    Amount: amount ? amount : +info.Amount,
    DepositInfo,
    Status: 'New'
  };

  dispatch(submittingDeposit);

  if (UseGetDepositWorkflow) {
    return dispatch(getDepositInfo(ProductId, DepositInfo));
  }

  const depositStatus = await dispatch(callAPI('CreateDepositTicket', payload));

  if (depositStatus.result === false) {
    return dispatch(
      showSnack({
        id: 'depositRejectSnack',
        text: formatErrorText(depositStatus),
        type: 'warning'
      })
    );
  }

  // Successful result of action
  return true;
};

export const submittingDeposit = { type: SUBMITTING_DEPOSIT };
