import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

import {
  formatFee,
  calcAndFormatTotalPrice,
  formatPrice,
  formatQuantity
} from '../../../../helpers/buySellHelper';
import ConfirmOrderDetails from './components/ConfirmOrderDetails';
import PlaceOrderDetails from './components/PlaceOrderDetails';
import BigNumber from 'bignumber.js';
import { useDebounce } from 'react-use';
import { buySellFormPropType } from '../../buySellFormPropType';

const OrderDetails = (props, context) => {
  const {
    isConfirm,
    currentInstrument,
    displayedPrice,
    approximateExp,
    isPriceFromInput,
    footerButtonProps,
    cardImageURL,
    productName,
    orderCost,
    orderFee,
    globallySubscribedInstrumentId,
    form,
    calcOrderFee,
    subscribeModalInstrument,
    isPrimaryMarketInstrument
  } = props;
  const {
    quantity,
    side,
    orderType
  } = form;
  // some other instrument that we were subscribed to before opening the modal.
  // can be `null` when we haven't subscribed to anything yet.
  const [preModalInstrumentId, setPreModalInstrumentId] = useState();
  const inputPrice = isPriceFromInput ? displayedPrice : undefined;
  // if we have some order fee from the start, then we do not request 
  // fee calculation on the first render 
  const skipNextFeeRequest = useRef(!!orderFee);
  useDebounce(
    () => {
      if (skipNextFeeRequest.current) {
        skipNextFeeRequest.current = false;
      } else {
        calcOrderFee();
      }
    },
    700,
    // TODO(Feb 03, 2022): should we add real-time fee recalculation on market price changes?
    [
      currentInstrument.InstrumentId,
      inputPrice,
      quantity
    ]
  );

  useEffect(
    () => {
      if (currentInstrument.InstrumentId !== globallySubscribedInstrumentId) {
        // global subscription state is different from what the modal needs,
        // so we save it and will restore later
        setPreModalInstrumentId(globallySubscribedInstrumentId || null);
      }
    },
    [currentInstrument.InstrumentId, globallySubscribedInstrumentId]
  );

  useEffect(
    () => {
      if (
        preModalInstrumentId !== undefined && // we do have some pre-modal subscription state to restore
        preModalInstrumentId !== currentInstrument.InstrumentId // pre-modal subscription is not what the modal needs
      ) {
        const unsub = subscribeModalInstrument(
          currentInstrument.InstrumentId,
          preModalInstrumentId
        );
        return unsub;
      }
    },
    [preModalInstrumentId, currentInstrument.InstrumentId]
  );

  const formattedQuantity = formatQuantity(currentInstrument, quantity);
  const formattedPrice = formatPrice(currentInstrument, displayedPrice);
  let formattedFee = BigNumber(quantity).gt(0) && orderFee ?
    formatFee(orderFee) :
    formatPrice(currentInstrument, 0);
  const formattedOrderCost = formatPrice(currentInstrument, orderCost);
  const formattedTotal = calcAndFormatTotalPrice(
    side,
    currentInstrument,
    orderCost,
    orderFee ? [orderFee] : []
  );
  const formattedExp = `${quantity > 0 ?
    formatNumberToLocale(
      BigNumber(approximateExp).dp(1, BigNumber.ROUND_HALF_UP)
    ) :
    '0'} ${context.t('XP')}`;
  const commonProps = {
    currentInstrument,
    cardImageURL,
    productName,
    formattedQuantity,
    formattedPrice,
    formattedOrderCost,
    formattedFee,
    formattedTotal,
    formattedExp,
    isPrimaryMarketInstrument
  };

  if (currentInstrument.InstrumentId !== globallySubscribedInstrumentId) return null;

  return isConfirm ?
    <ConfirmOrderDetails
      {...commonProps}
      side={side}
      orderType={orderType}
    /> :
    <PlaceOrderDetails
      showPriceInput={isPriceFromInput}
      isPriceWarningVisible={props.isPriceWarningVisible}
      footerButtonProps={footerButtonProps}
      form={props.form}
      {...commonProps}
    />;
};

OrderDetails.propTypes = {
  isConfirm: PropTypes.bool.isRequired,
  isPriceWarningVisible: PropTypes.bool.isRequired,
  isPrimaryMarketInstrument: PropTypes.bool.isRequired,
  displayedPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
  approximateExp: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
  isPriceFromInput: PropTypes.bool,
  cardImageURL: PropTypes.string,
  productName: PropTypes.string,
  orderCost: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
  currentInstrument: PropTypes.object.isRequired,
  orderFee: PropTypes.object,
  calcOrderFee: PropTypes.func.isRequired,
  footerButtonProps: PropTypes.object,
  globallySubscribedInstrumentId:
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subscribeModalInstrument: PropTypes.func.isRequired,
  form: buySellFormPropType.isRequired,
};

OrderDetails.contextTypes = {
  t: PropTypes.func.isRequired
};

export default OrderDetails;
