import apex from 'apex-web/lib/apex';
import { getOrdersBySide, isMakerOrTaker } from 'apex-web/lib/helpers/orderHelper';
import { getOrderTypeByName } from 'apex-web/lib/helpers/placeOrderHelper';
import {
  buyValue,
  orderTypes,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { isInstrumentSelected, orderTotalByInstrumentSelector } from '../redux/selectors/instrumentSelector';
import {
  isMarginActiveSelector,
  marginAccountSelector
} from 'apex-web/lib/redux/selectors/marginSelectors';

// Most of this logic is copied without changes from
// apex-web/lib/helpers/recalculateOrderHelper, that is used internally by 
// OrderEntryComponent on Pro Exchange

export const calculateOrderFee = async (state, options) => {
  const { form, instrumentId } = options;
  if (!isInstrumentSelected(state, instrumentId)) {
    console.warn(`Trying to calculate a fee for an instrument that is not selected. Instrument id: ${instrumentId}`);
    return null;
  }
  const orders = [...getOrdersBySide(form.side, state.level2)];
  form.side === buyValue
    ? orders.sort((a, b) => {
      if (a.Price > b.Price) return 1;
      if (a.Price < b.Price) return -1;
      return 0;
    })
    : orders.sort((a, b) => {
      if (a.Price < b.Price) return 1;
      if (a.Price > b.Price) return -1;
      return 0;
    });
  const currentInstrument = state.level1[instrumentId];
  let totalAmount = orderTotalByInstrumentSelector(state, {
    form,
    instrumentId,
  }) || 0;
  if (isNaN(totalAmount)) totalAmount = 0;
  const isMarginActive = isMarginActiveSelector(state);
  let fee;
  if (isMarginActive) {
    try {
      fee = await getMarginOrderFeeForDefaultForm(
        state,
        form,
        totalAmount,
        currentInstrument
      );

      return fee;
    } catch (error) {
      console.error('gerMarginOrderFee error: ', error);
      return 0;
    }
  } else {
    fee = await getOrderFeeForDefaultForm(
      state,
      form,
      totalAmount,
      currentInstrument
    );
    return fee;
  }
};

const getOrderFeeForDefaultForm = async (
  state,
  orderEntryForm,
  totalAmount,
  currentInstrument
) => {
  const price = getPriceForGetOrderFeeRequest(
    orderEntryForm,
    currentInstrument
  );
  const side = parseInt(orderEntryForm.side, 10);
  const quantity = getQuantityForGetOrderFeeRequest(
    orderEntryForm,
    totalAmount
  );

  let amount = getAmountForGetOrderFeeRequest(orderEntryForm, totalAmount);
  if (typeof amount === 'object') {
    amount = amount.toNumber();
  }

  const response = await apex.connection.GetOrderFee({
    ...getBaseOptionsForGetOrderFeeRequest(state),
    InstrumentId: currentInstrument.InstrumentId,
    Amount: amount,
    Quantity: quantity,
    Side: side,
    Price: price,
    OrderType: parseInt(getOrderTypeByName(orderEntryForm.orderType), 10),
    MakerTaker: isMakerOrTaker(
      orderEntryForm.side,
      price,
      currentInstrument.BestBid,
      currentInstrument.BestOffer,
      orderEntryForm.orderType
    )
  });

  return response;
};

const getMarginOrderFeeForDefaultForm = async (
  state,
  orderEntryForm,
  totalAmount,
  currentInstrument
) => {
  const price = getPriceForGetOrderFeeRequest(
    orderEntryForm,
    currentInstrument
  );
  const side = parseInt(orderEntryForm.side, 10);
  const amount = side === 1 ? totalAmount : orderEntryForm.quantity;
  const marginAccount = marginAccountSelector(state);

  const response = await apex.connection.RPCPromise('GetMarginOrderFee', {
    OMSId: 1,
    AccountId: marginAccount.AccountId,
    InstrumentId: currentInstrument.InstrumentId,
    Amount: amount,
    Quantity: orderEntryForm.quantity,
    Side: side,
    Price: price,
    OrderType: parseInt(getOrderTypeByName(orderEntryForm.orderType), 10),
    MakerTaker: isMakerOrTaker(
      orderEntryForm.side,
      price,
      currentInstrument.BestBid,
      currentInstrument.BestOffer,
      orderEntryForm.orderType
    )
  });

  return JSON.parse(response.o);
};

const getBaseOptionsForGetOrderFeeRequest = ({ user }) => ({
  OMSId: user.userInfo.OMSId,
  AccountId: user.selectedAccountId
});

const getPriceForGetOrderFeeRequest = (formValues, currentInstrument) => {
  if (formValues.orderType === orderTypes.market.displayName) {
    // TODO(Feb 03, 2022): if you need real-time fee recalculation of market
    // price changes you'll have to use the price from the form, it should contain the
    // latest best bid/offer. Exact form property name is decided by component 
    // that provides the real-time price (check BuySellDataContainer for a start).
    if (formValues.side === buyValue) {
      return currentInstrument.BestOffer;
    }
    return currentInstrument.BestBid;
  }

  if (formValues.orderType === orderTypes.stopMarket.displayName) {
    return formValues.stopPrice;
  }

  if (formValues.orderType === orderTypes.reportBlockTrade.displayName) {
    let { quantity, price } = formValues;
    if (!quantity || !price) return undefined;
    if (formValues.side === buyValue) {
      if (formValues.quantity <= 0) {
        formValues.quantity = 1;
      }

      if (formValues.useNewForm) {
        // "useNewForm" indicates that we are treating the BlockTrade form
        // like a standard order entry, where user inputs the intended price.
        return formValues.price;
      }

      return formValues.price / formValues.quantity;
    } else {
      if (formValues.price <= 0) {
        formValues.price = 1;
      }

      if (formValues.useNewForm) {
        // "useNewForm" indicates that we are treating the BlockTrade form
        // like a standard order entry, where user inputs the intended price.
        return formValues.quantity;
      }

      return formValues.quantity / formValues.price;
    }
  }

  return formValues.limitPrice ? formValues.limitPrice : undefined;
};

const getQuantityForGetOrderFeeRequest = formValues => {
  if (formValues.orderType === orderTypes.reportBlockTrade.displayName) {
    let { price } = formValues;
    if (!price) {
      formValues.quantity = 0;
      return undefined;
    }

    if (formValues.side === sellValue) {
      return formValues.price;
    }
  }
  if (typeof formValues.quantity === 'object') {
    return formValues.quantity.toNumber();
  }
  return formValues.quantity;
};

const getAmountForGetOrderFeeRequest = (formValues, totalAmount) => {
  if (formValues.orderType === orderTypes.reportBlockTrade.displayName) {
    return 0;
  }

  // use abstract equality because we're not sure what type does the side have here
  // eslint-disable-next-line eqeqeq
  return formValues.side == sellValue ? totalAmount : formValues.quantity;
};
