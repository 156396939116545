import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getBEMClasses } from '../../../../../helpers/cssClassesHelper';
import '../../../BuySellModal.css';

const classes = getBEMClasses('buy-sell-modal');

// TODO(May 18, 2022): make it work with as many order types as it makes sense to 
const ConfirmOrderDetails = (props, context) => {
  const {
    orderType,
    formattedQuantity,
    formattedPrice,
    formattedOrderCost,
    formattedTotal,
    formattedFee,
    formattedExp,
    cardImageURL,
    productName,
    isPrimaryMarketInstrument
  } = props;
  return (
    <React.Fragment>
      <div className={classes('confirm-container')} data-test="confirmRoot">
        <div className={classes('confirm-title')}>
          Please confirm the following order:
        </div>
        <div className={classes('confirm-details')}>
          <div className={classes('card-title')}>{productName}</div>
          <div className={classes('image-fields-container')}>
            <img
              src={cardImageURL}
              className={classes('card-image')}
              alt="product-card"
            />
            <div className={classes('fields-container')}>
              <div className={classes('row')}>
                <div className={classes('field-name')}>
                  {context.t('Order Type')}
                </div>
                <div className={classes('field-value')}>{orderType}</div>
              </div>
              <div className={classes('row')}>
                <div className={classes('field-name')}>
                  {context.t('Quantity')}{' '}
                </div>
                <div className={classes('field-value')}>
                  {formattedQuantity}
                </div>
              </div>
              <div className={classes('row')}>
                <div className={classes('field-name')}>
                  {context.t('Price')}{' '}
                </div>
                <div className={classes('field-value')}>{formattedPrice}</div>
              </div>
              <div className={classes('row')}>
                <div className={classes('field-name')}>
                  {context.t('Estimated Order Cost')}{' '}
                </div>
                <div className={classes('field-value')}>
                  {formattedOrderCost}
                </div>
              </div>
              <div className={classes('row')}>
                <div className={classes('field-name')}><a
                  className={classNames([
                    classes('field-name'),
                    classes('fee')
                  ])}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.liquidmarketplace.io/fees-schedule">
                  {context.t('Fee')}:
                </a></div>
                <div className={classes('field-value')}>{formattedFee}</div>
              </div>
              <div className={classes('row')}>
                <div className={classes('field-name')}>
                  {context.t('Estimated Total')}{' '}
                </div>
                <div className={classes('field-value')}>{formattedTotal}</div>
              </div>
              <div className={classes('row', isPrimaryMarketInstrument && 'highlighted')}>
                <div className={classes('field-name')}>
                  {context.t(isPrimaryMarketInstrument ? 'Rewards To Be Earned' : 'Rewards')}:
                </div>
                <div
                  className={classes('field-value', ['exp'])}
                  data-test='exp'>
                  {formattedExp}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
ConfirmOrderDetails.propTypes = {
  side: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
  currentInstrument: PropTypes.object.isRequired,
  formattedQuantity: PropTypes.string.isRequired,
  formattedPrice: PropTypes.string.isRequired,
  formattedOrderCost: PropTypes.string.isRequired,
  formattedTotal: PropTypes.string.isRequired,
  formattedFee: PropTypes.string.isRequired,
  formattedExp: PropTypes.string.isRequired,
  cardImageURL: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  isPrimaryMarketInstrument: PropTypes.bool.isRequired
};

ConfirmOrderDetails.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ConfirmOrderDetails;
