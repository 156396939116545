import { USER_CONFIG_FETCHED } from 'apex-web/lib/redux/actions/userActions';
import { RECEIVE_ACCOUNT_POSITIONS } from 'apex-web/lib/redux/actions/positionActions';
import {
  setIsUserConfigReceived,
  setIsBalanceReceived,
} from '../actions/userActions';

export const userConfigMiddleware = store => next => action => {
  const { dispatch } = store;

  if (action.type === RECEIVE_ACCOUNT_POSITIONS) {
    dispatch(setIsBalanceReceived());
  }

  if (action.type === USER_CONFIG_FETCHED) {
    setTimeout(() => {
      dispatch(setIsUserConfigReceived());
    }, 0);
  }

  if (action.type === '@@redux-form/SET_SUBMIT_SUCCEEDED') {
    localStorage.setItem('isReminderWasShown', false);
    localStorage.setItem('isGlobalNotificationWasShown', false);
  }

  return next(action);
};
