import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {
  selectAssetsBalanceInBaseCurrency,
  selectAssetsWithPrices
} from './level1Selector';
import {
  userConfigSelector,
  accountIdSelector,
  selectIsSuperuser
} from './userConfigSelector';
// eslint-disable-next-line
import { validateProductAttributesLocalization } from '../helpers/validateLocalizationHelpers';
import { positionByProductIdSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import BigNumber from 'bignumber.js';

export const checkIsCorrectAssetAttribute = (assetAttribute, attributeValue) =>
  assetAttribute && String(assetAttribute).toLowerCase() === attributeValue;

// TODO(Jul 06, 2022): ideally it should check only one attribute: "soldOut",
// but for now lots of products on staging are broken
export const checkIsOffering = assetAttributes => {
  if (!isEmpty(assetAttributes)) {
    const isOffering =
      checkIsCorrectAssetAttribute(assetAttributes.isUpcomingOffer, 'true') ||
      checkIsCorrectAssetAttribute(assetAttributes.isLiveOffer, 'true');
    return isOffering;
  }
  return false;
};

const positionsSelector = state => {
  return state.position.positions;
};

const productsSelector = createSelector(
  [
    state => state.product.products,
    state => state.productAttributes,
    selectIsSuperuser
  ],
  (products, productsAttributes, isSuperuser) => {
    if (isEmpty(productsAttributes)) {
      return products;
    } else {
      return products.filter(product => {
        const productAttributes =
          Object.values(productsAttributes).find(
            item => item.ProductId === product.ProductId
          ) || {};
        return productAttributes.isHidden &&
          productAttributes.isHidden === 'true' &&
          !isSuperuser
          ? false
          : true;
      });
    }
  }
);

export const booleanFields = [
  'isCurrentOffering',
  'isMarketPlaceOffering',
  'isOffering',
  'isHidden',
  'isUpcomingOffer'
];

const productAttributesSelector = state => {
  const { productAttributes } = state;
  const updateAttributes = Object.assign({}, productAttributes);
  booleanFields.forEach(booleanField => {
    for (let variable in updateAttributes) {
      const currentAttribute = updateAttributes[variable][booleanField];
      if (currentAttribute && typeof currentAttribute === 'string') {
        updateAttributes[variable][
          booleanField
        ] = currentAttribute.toLowerCase();
      }
    }
  });
  // Blocked until phase 2. For now app is english only
  // validateProductAttributesLocalization(productAttributes);
  return updateAttributes;
};

export const productsPositionsSelector = createSelector(
  [accountIdSelector, positionsSelector],
  (accountId, positions) => positions[accountId] || {}
);

export const selectProductsWithAttributes = createSelector(
  [productsSelector, productAttributesSelector],
  (products, productsAttributes) => {
    return products.map(product => {
      const productAttributes =
        Object.values(productsAttributes).find(
          item => item.ProductId === product.ProductId
        ) || {};

      return {
        ...product,
        productAttributes: productAttributes
      };
    });
  }
);

export const digitalAssetsProductsSelector = createSelector(
  [productsSelector, productAttributesSelector],
  (products, productsAttributes) => {
    const digitalAttributes = Object.values(productsAttributes).filter(
      i => i.Type === 'DigitalAsset'
    );
    let digitalProducts = [];
    products.forEach(i => {
      const digitalProductAttributes = digitalAttributes.find(
        a => a.ProductId === i.ProductId
      );
      if (digitalProductAttributes) {
        digitalProducts.push({
          ...i,
          ...digitalProductAttributes
        });
      }
    });
    return digitalProducts;
  }
);

export const availableNonDigitalProductsSelector = createSelector(
  [productsSelector, productAttributesSelector],
  (products, productsAttributes) => {
    let formattedProducts = [];
    products.forEach(i => {
      const attributes = productsAttributes[i.ProductId];
      let res = { ...i };
      if (attributes) {
        res = { ...res, ...attributes };
      }
      formattedProducts.push(res);
    });
    const nonDigitalProducts = formattedProducts.filter(
      i => i.Type !== 'DigitalAsset'
    );
    return nonDigitalProducts;
  }
);

export const selectAssetsWithAttributes = createSelector(
  [
    selectProductsWithAttributes,
    (state, options, ...restParams) => {
      if (get(options, 'withoutBalance')) {
        return {
          assetsWithPriceAndPercent: selectAssetsWithPrices(
            state,
            options,
            ...restParams
          )
        };
      }
      return selectAssetsBalanceInBaseCurrency(state, options, ...restParams);
    }
  ],
  (products, assets) => {
    const res = assets.assetsWithPriceAndPercent.map(asset => {
      const targetProduct = products.find(
        product => product.ProductId === asset.ProductId
      );
      return {
        ...asset,
        product: targetProduct
      };
    });
    return res;
  }
);

export const selectOfferedProducts = createSelector(
  [selectProductsWithAttributes],
  products =>
    products.filter(
      product =>
        product.productAttributes &&
        Object.values(product.productAttributes).length &&
        product.productAttributes.Type === 'DigitalAsset' &&
        checkIsCorrectAssetAttribute(
          product.productAttributes.isOffering,
          'true'
        )
    )
);

export const selectBestOfferedAssetWithAttributes = createSelector(
  [selectAssetsWithAttributes],
  assets =>
    assets.reduce(function(prev, current) {
      return Number(prev.BestOffer) > Number(current.BestOffer)
        ? prev
        : current;
    })
);

export const selectDigitalAssetsWithAttributes = createSelector(
  [selectAssetsWithAttributes],
  assets =>
    assets.filter(
      asset => asset.product.productAttributes.Type === 'DigitalAsset'
    )
);

export const selectDigitalAssetsWithAttributesNotUndefined = fieldsArray =>
  createSelector([selectDigitalAssetsWithAttributes], assets => {
    return assets.map(asset => {
      const res = { ...asset };
      fieldsArray.forEach(field => {
        if (typeof res[field] === 'undefined') res[field] = 0;
      });
      return res;
    });
  });

export const selectDigitalAssetsByIdWithAttributes = assetId =>
  createSelector([selectAssetsWithAttributes], assets => {
    return (
      assets.find(
        asset =>
          asset.product.productAttributes.Type === 'DigitalAsset' &&
          asset.product.ProductId === assetId
      ) || { product: { productAttributes: {} } }
    );
  });

// replace undefined fields that take part at sorting via selectDigitalAssetsWithAttributesNotUndefined
export const selectAssetsOfType = type => {
  return createSelector(
    [
      selectDigitalAssetsWithAttributesNotUndefined([
        'Rolling24HrPxChangePercent',
        'Rolling24NumTrades'
      ])
    ],
    assets =>
      assets.filter(
        asset =>
          asset.product.productAttributes.ProductCollectionType &&
          asset.product.productAttributes.ProductCollectionType.toLowerCase() ===
            type
      )
  );
};

const predictedTypes = ['pokemon', 'nba', 'nft', 'nhl', 'f1'];

export const selectAllAssetsTypes = () => {
  return createSelector(
    [
      selectDigitalAssetsWithAttributesNotUndefined([
        'Rolling24HrPxChangePercent',
        'Rolling24NumTrades'
      ])
    ],
    assets => {
      const allAssetTypes = assets
        .filter(
          item =>
            !!item.product.productAttributes.ProductCollectionType &&
            !predictedTypes.includes(
              item.product.productAttributes.ProductCollectionType.toLowerCase()
            )
        )
        .map(asset => asset.product.productAttributes.ProductCollectionType);
      const uniqTypes = [...new Set(allAssetTypes)];
      return uniqTypes
        .map(type => {
          const assetsOfType = assets.filter(
            item =>
              item.product.productAttributes.ProductCollectionType === type &&
              item.product.productAttributes.ProductImageURL &&
              item.product.productAttributes.ProductColor &&
              item.Amount > 0
          );
          return {
            type: type,
            assets: assetsOfType
          };
        })
        .filter(item => item.assets.length > 0);
    }
  );
};

export const selectAssetsOnVote = () => {
  return createSelector(
    [
      selectDigitalAssetsWithAttributesNotUndefined([
        'Rolling24HrPxChangePercent',
        'Rolling24NumTrades'
      ])
    ],
    assets =>
      assets.filter(
        asset =>
          asset.product.productAttributes.onVote &&
          asset.product.productAttributes.onVote.toLowerCase() === 'true'
      )
  );
};

export const currentOfferingSelector = createSelector(
  [digitalAssetsProductsSelector],
  assets => {
    const currentOfferings = assets.filter(i =>
      checkIsCorrectAssetAttribute(i.isCurrentOffering, 'true')
    );
    return currentOfferings;
  }
);

export const topCollectibleAssetWithAttribute = createSelector(
  [selectDigitalAssetsWithAttributes],
  assets => {
    const sortedAssets =
      [...assets].sort((a, b) => Number(a.Amount) < Number(b.Amount)) || [];
    return sortedAssets.filter(asset => asset.Amount > 0)[0] || {};
  }
);

export const marketplaceOfferingSelector = createSelector(
  [digitalAssetsProductsSelector],
  assets => {
    const [currentOffering] = assets.filter(i =>
      checkIsCorrectAssetAttribute(i.isMarketPlaceOffering, 'true')
    );
    return currentOffering;
  }
);
export const selectMarketplaceOfferings = createSelector(
  [digitalAssetsProductsSelector],
  assets => {
    const offerings = assets.filter(i =>
      checkIsCorrectAssetAttribute(i.isMarketPlaceOffering, 'true')
    );
    return offerings;
  }
);

export const selectMarketplaceOfferingsWithAttributes = createSelector(
  [selectDigitalAssetsWithAttributes],
  assets => {
    const offerings = assets.filter(i =>
      checkIsCorrectAssetAttribute(
        i.product.productAttributes.isMarketPlaceOffering,
        'true'
      )
    );
    return offerings;
  }
);

export const selectWatchListDigitalAssetsWithAttributes = createSelector(
  [
    userConfigSelector,
    selectDigitalAssetsWithAttributesNotUndefined([
      'Rolling24HrPxChangePercent',
      'Rolling24NumTrades'
    ])
  ],
  (userConfig, assets) => {
    const currentWatchList = userConfig.find(
      item => item.Key === 'AssetWatchList'
    );
    const watchList = currentWatchList
      ? JSON.parse(currentWatchList.Value)
      : [];
    return assets.filter(
      asset =>
        asset.product.productAttributes.Type === 'DigitalAsset' &&
        watchList.includes(asset.product.ProductId)
    );
  }
);

export const productPositionByIdSelector = assetId =>
  createSelector(
    [productsPositionsSelector],
    positions => positions[assetId] || {}
  );

export const isProductByDefaultScenario = createSelector(
  [digitalAssetsProductsSelector, (state, assetId) => assetId],
  (assets, assetId) => {
    const isProductByDefaultScenario = assets.find(
      i =>
        checkIsCorrectAssetAttribute(i.soldOut, 'false') &&
        i.ProductId === assetId &&
        checkIsCorrectAssetAttribute(i.scenarioType, 'default')
    );
    return isProductByDefaultScenario;
  }
);

export const isProductByScriptScenario = createSelector(
  [digitalAssetsProductsSelector, (state, assetId) => assetId],
  (assets, assetId) => {
    const isProductByScriptScenario = assets.find(
      i =>
        checkIsCorrectAssetAttribute(i.soldOut, 'false') &&
        i.ProductId === assetId &&
        checkIsCorrectAssetAttribute(i.scenarioType, 'token-launch-script')
    );
    return isProductByScriptScenario;
  }
);

export const digitalAssetWithAttributes = createSelector(
  [
    (state, options) =>
      selectDigitalAssetsWithAttributesNotUndefined(
        options.importantAttributes
      )(state),
    (state, options) => options.productId
  ],
  (assets, productId) => {
    return assets.find(a => a.ProductId === productId);
  }
);

export const selectUpcomingOffers = createSelector(
  [selectProductsWithAttributes],
  products =>
    products.filter(
      product =>
        product.productAttributes &&
        Object.values(product.productAttributes).length &&
        checkIsCorrectAssetAttribute(
          product.productAttributes.isUpcomingOffer,
          'true'
        )
    )
);

export const selectLiveOffers = createSelector(
  [selectProductsWithAttributes],
  products =>
    products.filter(
      product =>
        product.productAttributes &&
        Object.values(product.productAttributes).length &&
        checkIsCorrectAssetAttribute(
          product.productAttributes.isLiveOffer,
          'true'
        )
    )
);

export const isProductSoldOutSelector = createSelector(
  [selectProductsWithAttributes, (state, productId) => productId],
  (products, productId) => {
    const chosenProduct = products.find(
      item => item.ProductId === productId && item.productAttributes.soldOut
    );

    if (!chosenProduct) {
      return true;
    }

    const productSoldOutAttribute = chosenProduct.productAttributes.soldOut;
    return checkIsCorrectAssetAttribute(productSoldOutAttribute, 'true');
  }
);

export const usdProductSelector = createSelector(
  [state => state.product.products],
  products => {
    return products.find(p => p.Product === 'USD');
  }
);

export const userBalanceByProductSelector = createSelector(
  [
    (state, productId) => positionByProductIdSelector(productId)(state),
    (state, productId) => productId
  ],
  (position, productId) => {
    if (!position) {
      return BigNumber(0);
    }
    const bnAmount = BigNumber(position.Amount);
    const bnHold = BigNumber(position.Hold);
    if (bnAmount.isNaN() || bnHold.isNaN()) {
      console.error(
        `Invalid required position data (Amount, Hold) for product ${productId}. Position: ${position}`
      );
      return BigNumber(0);
    }
    let balance = bnAmount;
    if (bnHold.gt(0)) {
      balance = balance.minus(bnHold);
    }
    return BigNumber.max(0, balance);
  }
);

export const isAllProductAttributesLoaded = createSelector(
  [state => state.asset],
  asset => asset.productAttributesLoaded
);

export const selectOfferingsWithAvailability = offeringsSelector =>
  createSelector(
    [offeringsSelector, state => state.availableTokens],
    (offerings, tokens) => {
      // TODO(Jun 10, 2022): reuse this logic in the useModifyOfferings as well.
      // Careful, things may break
      if (!tokens) {
        return offerings;
      }
      return offerings.map(offering => {
        const { ProductTotalSize = 0, ProductId } = offering;

        const tokensByProduct = get(tokens, ['byProductId', ProductId]);
        if (tokensByProduct) {
          const available_tokens = tokensByProduct.available_tokens;
          const availableQuantity = BigNumber(available_tokens);
          const totalSize = BigNumber(ProductTotalSize);
          const availableTokensPercent = (totalSize.gt(0)
            ? availableQuantity.dividedBy(totalSize).multipliedBy(100)
            : BigNumber(0)
          ).toFixed(2);
          return { ...offering, availableTokensPercent, available_tokens };
        } else {
          return offering;
        }
      });
    }
  );
