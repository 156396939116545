import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './DepositWithdrawModal.css';

import { Modal } from '../common/Modal/Modal';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import DepositFormContainer from './components/DepositFormContainer/DepositFormContainer';
import { WithDrawalFormContainer } from './components/WithDrawalFormContainer/WithDrawalFormContainer';

export const DepositWithdrawModal = props => {
  const {
    openModal,
    selectTab,
    onFormChange,
    isModalOpened,
    activeTabIndex,
    withModalWrapper = true,
    setSelectedDepositPaymentProvider,
    setSelectedWithdrawProvider
  } = props;
  const [tabOption, setTabOption] = useState(activeTabIndex);

  useEffect(
    () => {
      setTabOption(activeTabIndex);
      if (selectTab) {
        selectTab(activeTabIndex);
      }
    },
    [activeTabIndex, isModalOpened]
  );

  const baseClasses = getBEMClasses('deposit-withdraw-modal');

  const ContentWrapper = withModalWrapper ? Modal : React.Fragment;
  if (withModalWrapper) {
    ContentWrapper.props = {
      ...ContentWrapper.props,
      isOpen: isModalOpened,
      title: 'Deposit/Withdraw',
      onCancel: openModal,
      close: openModal
    };
  }

  const TabsContent = {
    0: (
      <DepositFormContainer
        onFormChange={onFormChange}
        toggleModal={openModal}
        setSelectedDepositPaymentProvider={setSelectedDepositPaymentProvider}
      />
    ),
    1: (
      <WithDrawalFormContainer
        onFormChange={onFormChange}
        toggleModal={openModal}
        setSelectedWithdrawProvider={setSelectedWithdrawProvider}
      />
    )
  };

  return (
    <ContentWrapper>
      <div className={baseClasses('modalContainer')}>
        {TabsContent[tabOption]}
      </div>
    </ContentWrapper>
  );
};

DepositWithdrawModal.propTypes = {
  openModal: PropTypes.func.isRequired,
  selectTab: PropTypes.func,
  onFormChange: PropTypes.func,
  isModalOpened: PropTypes.bool.isRequired,
  activeTabIndex: PropTypes.number.isRequired,
  withModalWrapper: PropTypes.bool,
  setSelectedDepositPaymentProvider: PropTypes.func.isRequired,
  setSelectedWithdrawProvider: PropTypes.func
};

DepositWithdrawModal.defaultProps = {
  selectTab: () => {},
  onFormChange: () => {}
};
