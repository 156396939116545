import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { MainBlock } from './MainBlock';
import { DescriptionBlock } from './DescriptionBlock';
import './styles.css';

const baseClass = getBEMClasses('co-own-page');

export const CoOwnPage = ({ main, description, isPublic }) => {
  return (
    <div className={baseClass('')}>
      <MainBlock {...main} isPublic={isPublic} />
      <DescriptionBlock {...description} />
    </div>
  );
};

CoOwnPage.propTypes = {
  main: PropTypes.object,
  description: PropTypes.object,
  isPublic: PropTypes.bool
};
