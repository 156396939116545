import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BigNumber from 'bignumber.js';
import { buyValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';

import { getBEMClasses } from '../../../../../../helpers/cssClassesHelper';
import { useIsMobile } from '../../../../../../hooks/useIsMobile';
import { useDebounce } from '../../../../../../hooks/useDebounce';
import PriceWarningTag from '../../../../../common/PriceWarningTag';
import QuantityPickerContainer from './QuantityPickerContainer';
import { getInputPriceFieldName } from '../../../../../../helpers/buySellHelper';
import { InnerInput as ControlledAPInput } from '../../../../../common/APInputWithReduxForm/APInput';
import { buySellFormPropType } from '../../../../buySellFormPropType';
import '../../../../BuySellModal.css';
import './PlaceOrderDetails.css';

const classes = getBEMClasses('buy-sell-modal', 'place-order-details');

const PlaceOrderDetailsComponent = (props, context) => {
  const {
    showPriceInput,
    currentInstrument,
    formattedOrderCost,
    formattedTotal,
    formattedFee,
    formattedQuantity,
    formattedExp,
    form,
    cardImageURL,
    productName,
    realMaxProductQuantity,
    displayedMaxProductQuantity,
    footerButtonProps,
    formattedUserFunds,
    requestAvailableTokens,
    updateQuantity,
    updatePrice,
    isPrimaryMarketInstrument
  } = props;

  useEffect(() => {
    requestAvailableTokens();
  }, []);

  const side = form.side;
  const sideCssModifier = side === buyValue && 'buy';

  const formNumberQuantity = Number(form.quantity);
  const formNumberPrice = Number(form[getInputPriceFieldName(form.orderType)]);
  const isPriceValid = showPriceInput
    ? formNumberPrice >= currentInstrument.MinimumPrice
    : true;
  const isQuantityValid =
    formNumberQuantity >= currentInstrument.MinimumQuantity &&
    realMaxProductQuantity.gte(formNumberQuantity);
  const isFormValid = isQuantityValid && isPriceValid;

  const maxQuantityField =
    side === buyValue ? 'Available Tokens' : 'Your Tokens';

  const isMobile = useIsMobile();
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      if (isFormValid && !footerButtonProps.disabled) {
        footerButtonProps.onClick();
      }
      return isFormValid;
    },
    [isFormValid, footerButtonProps.onClick]
  );
  const isPriceWarningVisible = useDebounce(props.isPriceWarningVisible, 700);

  const onPriceChange = useCallback(e => {
    updatePrice(e.target.value);
  }, []);

  // use outer state to make input visibility persist across "loader-slider" render cycles
  // to be specific, it fixes the alt-tabbing issue
  const [isSliderInputVisible, setIsSliderInputVisible] = useState(false);
  const onSliderChange = useCallback(value => {
    updateQuantity(value);
  }, []);

  return (
    <React.Fragment>
      <form
        className={classes('form-container', sideCssModifier)}
        onSubmit={onSubmit}
        data-test='placeRoot'>
        <div className={classes('image-text-container')}>
          {!isMobile && (
            <img
              src={cardImageURL}
              className={classes('card-image')}
              alt="product-card"
            />
          )}
          <div className={classes('text-container')}>
            <div className={classes('info-container')}>
              <div className={classes('card-title')}>{productName}</div>
              <div className={classes('card-attribute-row')}>
                {context.t(maxQuantityField) + ': '}
                <span
                  className={classes('max-quantity')}
                  data-test='maxQuantity'>
                  {displayedMaxProductQuantity}
                </span>
              </div>
              {formattedUserFunds && (
                <div className={classes('card-attribute-row')}>
                  {context.t('Available Funds') + ': '}
                  <span data-test='availableFunds'>{formattedUserFunds}</span>
                </div>
              )}
            </div>
            {isMobile && (
              <img
                src={cardImageURL}
                className={classes('card-image')}
                alt="product-card"
              />
            )}
            {isPriceWarningVisible && (
              <div className={classes('warning-wrapper')}>
                <PriceWarningTag />
              </div>
            )}
            <div className={classes('field-name')}>
              {context.t('Quantity')}:
            </div>
            <div className={classes('percentage-slider-container')}>
              <QuantityPickerContainer
                side={side}
                currentInstrument={currentInstrument}
                isTooltipAlwaysVisible={true}
                quantity={formNumberQuantity || 0}
                onChange={onSliderChange}
                isInputVisible={isSliderInputVisible}
                setIsInputVisible={setIsSliderInputVisible} />
            </div>
            <div className={classes('fields-container')}>
              {showPriceInput && (
                <div className={classes('row')}>
                  <div className={classes('field-name')}>
                    {context.t('Price')}:
                  </div>
                  <div className={classes('field-value')}>
                    <ControlledAPInput
                      customClass={classes('input')}
                      placeholder="0"
                      type='number'
                      name='limitPrice'
                      step={currentInstrument.PriceIncrement}
                      min={currentInstrument.MinimumPrice}
                      input={{
                        value: formNumberPrice || 0,
                        onChange: onPriceChange
                      }}
                      data-test='inputPrice'
                    />
                    {currentInstrument.Product2Symbol}
                  </div>
                </div>
              )}
              <div className={classes('row')}>
                <div className={classes('field-name')}>
                  {context.t('Estimated Order Cost')}:
                </div>
                <div className={classes('field-value')} data-test='cost'>
                  {formattedOrderCost}
                </div>
              </div>
              <div className={classes('row')}>
                <a
                  className={classNames([
                    classes('field-name'),
                    classes('fee')
                  ])}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.liquidmarketplace.io/fees-schedule">
                  {context.t('Fee')}:
                </a>
                <div className={classes('field-value')} data-test='fee'>
                  {formattedFee}
                </div>
              </div>
              <div className={classes('row')}>
                <div className={classes('field-name')}>
                  {context.t('Estimated Total')}:
                </div>
                <div className={classes('field-value')} data-test='total'>
                  {formattedTotal}
                </div>
              </div>
              <div className={classes('row')}>
                <div className={classes('field-name')}>
                  {context.t('Estimated Token Amount')}:
                </div>
                <div className={classes('field-value')} data-test='totalQty'>
                  {formattedQuantity}
                </div>
              </div>
              <div className={classes('row', isPrimaryMarketInstrument && 'highlighted')}>
                <div className={classes('field-name')}>
                  {context.t(isPrimaryMarketInstrument ? 'Rewards To Be Earned' : 'Rewards')}:
                </div>
                <div
                  className={classes('field-value', ['exp'])}
                  data-test='exp'>
                  {formattedExp}
                </div>
              </div>
            </div>
            <div className={classes('submit-container')}>
              <button
                className={classes('btn', sideCssModifier)}
                type="submit"
                disabled={!isFormValid || footerButtonProps.disabled}
                data-test='place'>
                {context.t(footerButtonProps.buttonText)}
              </button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

PlaceOrderDetailsComponent.propTypes = {
  showPriceInput: PropTypes.bool.isRequired,
  currentInstrument: PropTypes.object.isRequired,
  formattedOrderCost: PropTypes.string.isRequired,
  formattedTotal: PropTypes.string.isRequired,
  formattedFee: PropTypes.string.isRequired,
  formattedQuantity: PropTypes.string.isRequired,
  formattedExp: PropTypes.string.isRequired,
  form: buySellFormPropType.isRequired,
  cardImageURL: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  realMaxProductQuantity: PropTypes.instanceOf(BigNumber).isRequired,
  displayedMaxProductQuantity: PropTypes.string.isRequired,
  isPriceWarningVisible: PropTypes.bool.isRequired,
  requestAvailableTokens: PropTypes.func.isRequired,
  updatePrice: PropTypes.func.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  footerButtonProps: PropTypes.object,
  sideCssModifier: PropTypes.string,
  formattedUserFunds: PropTypes.string,
  isPrimaryMarketInstrument: PropTypes.bool.isRequired,
};

PlaceOrderDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PlaceOrderDetailsComponent;
