import { connect } from 'react-redux';
import { buyValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import PlaceOrderDetailsComponent from './PlaceOrderDetailsComponent';
import { userBalanceByProductSelector } from '../../../../../../redux/selectors/productsSelector';
import { getAvailableTokens } from '../../../../../../redux/actions/cardActions';
import { availableTokensByProductSelector } from '../../../../../../redux/selectors/cardSelector';
import { formatPrice, formatQuantity } from '../../../../../../helpers/buySellHelper';
import { updatePrice, updateQuantity } from '../../../../../../redux/actions/buySellModalActions';

const mapStateToProps = (state, ownProps) => {
  const { form: { side }, currentInstrument } = ownProps;
  const userUsdBalance = userBalanceByProductSelector(state, currentInstrument.Product2);
  if (side === buyValue) {
    const totalTokenQuantity = availableTokensByProductSelector(state, currentInstrument.Product1);
    return {
      realMaxProductQuantity: totalTokenQuantity,
      displayedMaxProductQuantity: formatQuantity(currentInstrument, totalTokenQuantity),
      formattedUserFunds: formatPrice(currentInstrument, userUsdBalance),
    };
  } else {
    const userTokenBalance = userBalanceByProductSelector(state, currentInstrument.Product1);
    return {
      realMaxProductQuantity: userTokenBalance,
      displayedMaxProductQuantity: formatQuantity(currentInstrument, userTokenBalance),
      formattedUserFunds: formatPrice(currentInstrument, userUsdBalance),
    };
  }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  requestAvailableTokens: () => {
    if (ownProps.form.side === buyValue) {
      dispatch(
        getAvailableTokens({ productIds: [ownProps.currentInstrument.Product1] })
      );
    }
  },
  updateQuantity: (value) => dispatch(updateQuantity(value)),
  updatePrice: (value) => dispatch(updatePrice(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrderDetailsComponent);
