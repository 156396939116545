import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import ChooseIcon from '../../images/icons/icon-choose.svg';
import { AddToFavoriteButton, ViewDetailsButton } from '../common/Buttons';
import PriceWarningTag from '../common/PriceWarningTag';
import { UrlFactory } from '../../helpers/urlFactory';
import { Link, withRouter } from 'react-router-dom';
import './InstrumentSummary.css';

const baseClasses = getBEMClasses('instrument-summary');

const InstrumentSummaryComponent = (props, context) => {
  const {
    isWarningVisible = false,
    assetDetails,
    assetId,
    history,
    changeInstrument
  } = props;
  const { product } = assetDetails;
  const {
    productAttributes: {
      ProductDetailsNumber = '',
      ProductDetailsTitle = '',
      ProductCardBackground = '',
      ProductColor = 'var(--purple8)',
      InstrumentBackground = ''
    }
  } = product;

  return (
    <div
      className={baseClasses('wrapper')}
      style={{
        background: `url(${InstrumentBackground ||
          ProductCardBackground}) center 35% / cover no-repeat, ${ProductColor}`
      }}
      data-background={InstrumentBackground || ProductCardBackground}
      data-background-color={ProductColor}>
      <Link
        to={UrlFactory.getMarketPlaceAssetDetailsUrl(assetId)}
        className={baseClasses('title-wrapper')}>
        <div className={baseClasses('number')}>#{ProductDetailsNumber}</div>
        <div className={baseClasses('title')}>{ProductDetailsTitle}</div>
      </Link>
      {isWarningVisible && (
        <div className={baseClasses('warning-wrapper')}>
          <PriceWarningTag />
        </div>
      )}
      <div className={baseClasses('buttons-wrapper')}>
        <AddToFavoriteButton productId={product.ProductId} />
        <ViewDetailsButton
          onClick={() =>
            history.push(UrlFactory.getMarketPlaceAssetDetailsUrl(assetId))
          }
        />
        <button
          className={baseClasses('choose-asset')}
          onClick={changeInstrument}>
          <SVG
            className={baseClasses('choose-asset-icon')}
            src={ChooseIcon}
            alt=""
          />
          {context.t('Choose Asset')}
        </button>
      </div>
    </div>
  );
};

InstrumentSummaryComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

InstrumentSummaryComponent.propTypes = {
  isWarningVisible: PropTypes.bool,
  assetDetails: PropTypes.object,
  assetId: PropTypes.number,
  history: PropTypes.object,
  changeInstrument: PropTypes.func
};

export default withRouter(InstrumentSummaryComponent);
