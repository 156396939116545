import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import OrderHistoryComponent from '../../../components/OrderHistoryComponents';
import InstrumentSummaryContainer from '../../../components/InstrumentSummary';
import TradingLayoutCharts1280Px from './TradingLayoutCharts1280Px';
import TradingLayoutContent480Px from './TradingLayoutContent480Px';
import PriceChart from '../../../components/PriceChart/PriceChartContainer';
import DepthChart from '../../../components/DepthChart/DepthChartContainer';
import InstrumentInfoSliderContainer from '../../../components/InstrumentInfoSlider/InstrumentInfoSliderContainer';
import {
  buyValue,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import MarginClosePositionModal from 'apex-web/lib/components/MarginClosePosition/MarginClosePositionModal';
import EstimatedSettlementModal from 'apex-web/lib/components/MarginClosePosition/EstimatedSettlementModal';
import MarginTransferAssetsModal from 'apex-web/lib/components/MarginTransferAssetModal/MarginTransferAssetsModal';

const tradingLayoutClasses = getBEMClasses('trading-layout');

const TradingLayoutContentMobile = (props, context) => {
  const {
    OrderBook: InnerOrderBook,
    OrderEntryModal,
    InstrumentPositions,
    onSideChange,
    config,
    isMarginEnabledSelector
  } = props;
  const OrderBook = useCallback(
    bookProps => (
      <InnerOrderBook {...bookProps} areOrderEntriesDisabled={true} />
    ),
    [InnerOrderBook]
  );

  const [orderEntryModalState, setOrderEntryModalState] = useState(null);

  const renderActivity = () => {
    const {
      OrderHistoryData: { usePagination },
      TradingLayout: { maxLines }
    } = config;
    const orderHistoryConfig = {
      usePagination,
      maxLines,
      filterMode: 'selectedInstrument'
    };

    return <OrderHistoryComponent config={orderHistoryConfig} />;
  };

  return (
    <React.Fragment>
      <div className={tradingLayoutClasses('row')}>
        <InstrumentSummaryContainer />
      </div>
      <div className={tradingLayoutClasses('row')}>
        <InstrumentInfoSliderContainer />
      </div>

      <div className={tradingLayoutClasses('body')}>
        <div className={tradingLayoutClasses('chart-container')}>
          <TradingLayoutCharts1280Px
            config={config}
            {...{
              PriceChart,
              DepthChart,
              OrderBook,
              showDepthChart: config.TradingLayout.showDepthChart
            }}
          />
        </div>
        <div className={tradingLayoutClasses('buy-sell-buttons-container')}>
          <button
            className={tradingLayoutClasses('open-modal', 'additive')}
            onClick={() => {
              onSideChange(buyValue);
              setOrderEntryModalState(buyValue);
            }}>
            {context.t('BUY')}
          </button>
          <button
            className={tradingLayoutClasses('open-modal', 'subtractive')}
            onClick={() => {
              onSideChange(sellValue);
              setOrderEntryModalState(sellValue);
            }}>
            {context.t('SELL')}
          </button>
        </div>
        <TradingLayoutContent480Px
          config={config}
          {...{
            InstrumentPositions,
            OrderHistory: renderActivity
          }}
        />
      </div>
      <OrderEntryModal
        isOpen={!!orderEntryModalState}
        side={orderEntryModalState}
        close={() => setOrderEntryModalState(null)}
      />
      {isMarginEnabledSelector && (
        <React.Fragment>
          <MarginClosePositionModal />
          <EstimatedSettlementModal />
          <MarginTransferAssetsModal />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

TradingLayoutContentMobile.contextTypes = {
  t: PropTypes.func
};

TradingLayoutContentMobile.propTypes = {
  OrderBook: PropTypes.func,
  OrderEntryModal: PropTypes.func,
  InstrumentPositions: PropTypes.func,
  onSideChange: PropTypes.func,
  config: PropTypes.object,
  isMarginEnabledSelector: PropTypes.bool
};

export default TradingLayoutContentMobile;
