import React, { Component } from 'react';
import PropTypes from 'prop-types';
import packageJson from '../package.json';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom';
import { initApex, setGateway } from 'apex-web/lib/apex';
import {
  connectionOpened,
  connectionClosed
} from 'apex-web/lib/redux/actions/wsConnectionActions';
import { getQueryParam } from './helpers/queryParamsHelper';
import { onInit } from 'apex-web/lib/redux/actions/lifeCycleActions';
import StandAloneLayout, {
  inStandAloneLayout
} from './layouts/StandAloneLayout/StandAloneLayout';
import './styles/theme/dark.css';
import SignupPage from './pages/SignupPage/SignupPage';
import ProblemLoggingInPage from './pages/ProblemLoggingInPage/ProblemLoggingInPage';
import DepositConfirmation from 'apex-web/lib/components/DepositConfirmation/DepositConfirmationModalContainer';
import Snackbar from 'apex-web/lib/components/APSnackbar/APSnackbarContainer';
import TwoFactor from 'apex-web/lib/components/TwoFactorAuthForm/TwoFactorAuthFormContainer';
import SidePane from 'apex-web/lib/components/common/SidePane/SidePaneContainer';
import { emailLinkRoutes } from 'apex-web/lib/helpers/emailLinksHelper';
import EmailLinks from './layouts/EmailLinksLayout.js';
import InteriorPage from './pages/InteriorPage/InteriorPage';
import { getGateway } from 'apex-web/lib/helpers/wsHelper';
import Require2FA from 'apex-web/lib/components/2FA/TwoFactorAuthContainer';
import Enable2FA from 'apex-web/lib/components/EnableTwoFactorAuth/StandaloneEnable2FAModalContainer';
import LoginPage from './pages/LoginPage/LoginPage';
import { PublicPortfolio } from './pages/PublicPortfolio/PublicPortfolio';
import { PublicAssetDetailsPage } from './pages/PublicAssetDetailsPage/PublicAssetDetailsPage';
import MaintenancePage from './pages/StubPage/MaintenancePage';
import NotFoundPage from './pages/StubPage/NotFoundPage';
import { loganPaulPageProps } from './pages/CoOwnPage/LoganPaulPage/pageProps';
import { steveAokiPageProps } from './pages/CoOwnPage/SteveAokiPage/pageProps';
import { darrenJackPageProps } from './pages/CoOwnPage/DarrenJackPage/pageProps';
import PublicCoOwnPage from './pages/CoOwnPage/PublicCoOwnPage';
import SpotlightOverlay from './components/Spotlight/SpotlightOverlay';
import { ScrollToTop } from './hooks/scrollToTop';

const MAIN_ROUTES = [
  '/dashboard',
  '/offerings',
  '/offerings/asset-details/:assetId',
  '/market-place',
  '/co-own',
  '/my-collectibles',
  '/pro-marketplace',
  '/wallets',
  '/settings',
  '/exchange',
  '/settings/user',
  '/settings/trade',
  '/settings/iba',
  '/settings/trade-reports',
  '/settings/api-keys',
  '/settings/contacts',
  '/settings/affiliate',
  '/settings/loyalty-token',
  '/settings/verification-level',
  '/settings/demo-settings',
  '/tiers',
  '/community',
  '/vote-page'
];

const WrappedEmailLinks = inStandAloneLayout(EmailLinks);

class App extends Component {
  constructor(props) {
    super(props);

    if (window.location.origin.includes('vip')) {
      window.location.replace('https://trade.liquidmarketplace.io/');
    }

    const gateway = getGateway();
    if (gateway) {
      setGateway(gateway);
      initApex(this.props.connectionOpened, this.props.connectionClosed);
    }

    const AffiliateTag = getQueryParam('aff');
    if (AffiliateTag) {
      window.localStorage.setItem('affiliateTag', AffiliateTag);
    }
  }

  render() {
    const {
      location: { pathname },
      isMaintenance
    } = this.props;

    return isMaintenance ? (
      <MaintenancePage />
    ) : (
      <React.Fragment>
        <div className="App fluid container">
          <ScrollToTop />
          <Switch>
            <Route exact path={'/'}>
              <StandAloneLayout>
                <LoginPage />
              </StandAloneLayout>
            </Route>
            <Route
              path="/version"
              render={() => (
                <div>
                  <p>v3implementation-template: {packageJson.version}</p>
                  <p>apex-web: {packageJson.dependencies['apex-web']}</p>
                </div>
              )}
            />
            <Route path={'/user-portfolio/:accountId'}>
              <PublicPortfolio />
            </Route>
            <Route path={'/asset-details/:assetId'}>
              <PublicAssetDetailsPage />
            </Route>
            <Route path={'/public/co-own'}>
              <PublicCoOwnSwitch />
            </Route>
            <Route path={MAIN_ROUTES}>
              <InteriorPage location={this.props.location} />
            </Route>
            <Route path={'/enabletwofactorauth'}>
              <Enable2FA />
            </Route>
            <Route path={'/login'}>
              <StandAloneLayout>
                <LoginPage />
              </StandAloneLayout>
            </Route>
            <Route path={'/signup'}>
              <StandAloneLayout>
                <SignupPage />
              </StandAloneLayout>
            </Route>
            <Route path={'/problem-logging-in'}>
              <StandAloneLayout>
                <ProblemLoggingInPage />
              </StandAloneLayout>
            </Route>
            <Route path={'/twofactorauth'}>
              <StandAloneLayout>
                {/* TODO */}
                <TwoFactor />
              </StandAloneLayout>
            </Route>
            {emailLinkRoutes(pathname, WrappedEmailLinks)}
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </div>
        <Snackbar />
        <SidePane
          options={{
            ReportBlockTrade: {
              useNewForm: true
            }
          }}
        />
        <SpotlightOverlay />
        <DepositConfirmation />
        <Require2FA />
        <div id={'support-chat-stub'} />
      </React.Fragment>
    );
  }
}

const PublicCoOwnSwitch = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/logan-paul`}>
        <PublicCoOwnPage {...loganPaulPageProps} />
      </Route>
      <Route path={`${path}/steve-aoki`}>
        <PublicCoOwnPage {...steveAokiPageProps} />
      </Route>
      <Route path={`${path}/darren-jack`}>
        <PublicCoOwnPage {...darrenJackPageProps} />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

const mapStateToProps = ({
  auth: { isAuthenticated },
  userConfig: { isMaintenance }
}) => ({
  isAuthenticated,
  isMaintenance
});

const mapDispatchToProps = {
  onInit,
  connectionOpened,
  connectionClosed
};

App.propTypes = {
  onInit: PropTypes.func,
  connectionOpened: PropTypes.func,
  connectionClosed: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  isMaintenance: PropTypes.bool,
  location: PropTypes.object
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
