import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../../../../common/Modal/Modal';
import { getBEMClasses } from '../../../../../../helpers/cssClassesHelper';
import './DepositFromCard.css';

const classes = getBEMClasses('deposit-from-card');
export const AgreementStripeModal = (props, context) => {
  const { isOpen, close, handleConfirm, handleReject, description } = props;

  return (
    <Modal
      isOpen={isOpen}
      title={context.t('User agreement')}
      onCancel={() => {
        close();
      }}
      close={close}>
      <div className={classes('modal')}>
        <div className={classes('modal-description')}>{description}</div>

        <div className={classes('modal-buttons')}>
          <button
            onClick={handleConfirm}
            className={classes('modal-button', 'confirm')}>
            {context.t('Confirm')}
          </button>
          <button
            onClick={handleReject}
            className={classes('modal-button', 'reject')}>
            {context.t('Reject')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

AgreementStripeModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  link: PropTypes.string,
  description: PropTypes.node.isRequired
};

AgreementStripeModal.contextTypes = {
  t: PropTypes.func.isRequired
};
