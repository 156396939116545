import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { useWindowSize } from 'react-use';

import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { AssetsFilter } from '../common/AssetsFilter';
import { Modal } from '../common/Modal/Modal';
import APIcon from '../common/APIcon';
import { SearchCardInput } from '../common/SearchCardInput';
import { InstrumentCard } from '../common/InstrumentCard';
import { UrlFactory } from '../../helpers/urlFactory';
import ConfirmRemoveFromWatchListModalContainer from '../ConfrimRemoveFromWatchListModal/ConfirmRemoveFromWatchListModalContainer';
import { sortAssets } from '../../helpers/sortHelper';

import './SelectAssetFromWatchListModal.css';
import { PaginationComponent } from '../common/Pagination/PaginationComponent';

const baseClasses = getBEMClasses('select-asset-from-watch-list-modal');

const SelectAssetFromWatchListModalComponent = (props, context) => {
  const {
    assets,
    instruments,
    selectInstrument,
    clearProForm,
    selectedInstrument,
    fetching,
    isOpen,
    close,
    history,
    onBack,
    resetPage
  } = props;
  const [searchString, setSearchString] = useState('');
  const [currentFilterOption, setCurrentFilterOption] = useState(
    'Rolling24HrPxChangePercent'
  );
  const [isAscending, setIsAscending] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const { width } = useWindowSize();
  const isUseMobileLayout = width <= 800;

  const filteredAssets = assets.filter(item =>
    item.product.ProductFullName.toLowerCase().includes(
      searchString.toLowerCase()
    )
  );

  useEffect(() => {
    if (!isOpen) {
      setSearchString('');
    }
  }, [isOpen]);

  useEffect(
    () => {
      if (!isOpen) {
        resetPage(paginationComponentName);
      }
    },
    [isOpen]
  );
  useEffect(
    () => {
      resetPage(paginationComponentName);
    },
    [currentFilterOption, isAscending]
  );

  useEffect(() => {
    if (instruments.length === 0) return;

    const currentSelectedInstrument = instruments.find(instrument => {
      return instrument.InstrumentId === selectedInstrument.InstrumentId;
    });

    if (currentSelectedInstrument) return;

    const instrumentId = instruments[0].InstrumentId;
    selectInstrument(instrumentId);
  }, []);

  const handleSelectInstrument = assetId => {
    const currentInstrument = instruments.find(
      item => item.Product1 === assetId
    );
    if (currentInstrument) {
      selectInstrument(currentInstrument.InstrumentId);
    }
    if (onBack) {
      onBack();
    }
    close();
    clearProForm();
    history.push(UrlFactory.getProExchangeUrl());
  };

  const sortedAssets = useMemo(
    () =>
      [...filteredAssets].sort((a, b) =>
        sortAssets(a, b, currentFilterOption, isAscending)
      ),
    [filteredAssets.length, currentFilterOption, isAscending]
  );

  const pageSize = isUseMobileLayout ? 5 : 10;
  const totalPages = Math.ceil(
    sortedAssets.length / pageSize || sortedAssets.length
  );

  const [assetIdForRemoval, setAssetIdForRemoval] = useState(null);
  const closeConfirmRemovalModal = useCallback(
    () => setAssetIdForRemoval(null),
    []
  );
  const isConfirmRemovalModalOpen = !!assetIdForRemoval;

  const onCardRemoveClick = useCallback(
    assetId => setAssetIdForRemoval(assetId),
    []
  );

  return (
    <React.Fragment>
      <Modal
        customClass={baseClasses()}
        isOpen={isOpen}
        close={close}
        titleComponent={
          <div className={baseClasses('header-wrapper')}>
            {!!onBack && (
              <div
                className={baseClasses('header-back-wrapper')}
                onClick={() => {
                  close();
                }}>
                <APIcon
                  name="back"
                  customClass={baseClasses('header-back-icon')}
                />
              </div>
            )}

            <APIcon name="watchList" customClass={baseClasses('header-icon')} />
            {context.t('Your Watchlist')}
          </div>
        }>
        <div className={baseClasses('actions-wrapper')}>
          <SearchCardInput setSearchString={setSearchString} />
        </div>
        <div className={baseClasses('filter-wrapper')}>
          <AssetsFilter
            currentFilterOption={currentFilterOption}
            isAscending={isAscending}
            setIsAscending={setIsAscending}
            setCurrentFilterOption={setCurrentFilterOption}
            selectorOptions={selectorSortOptions}
          />
        </div>
        {fetching ? (
          <div className={baseClasses('loader-wrapper')}>
            <Spinner />
          </div>
        ) : sortedAssets.length === 0 ? (
          <div className={baseClasses('empty-assets')}>
            {context.t('No Collectible')}
          </div>
        ) : (
          <PaginationComponent
            totalPages={totalPages}
            maxPages={3}
            showOnlyActivePage={false}
            activePage={activePage}
            setActivePage={setActivePage}
            containerClassName={baseClasses('pagination-wrapper')}
            content={page => {
              const rows = sortedAssets.slice(
                pageSize * page,
                pageSize * (page + 1)
              );
              return (
                <div className={baseClasses('cards-wrapper')}>
                  {rows.map(row => (
                    <InstrumentCard
                      key={row.ProductId}
                      row={row}
                      isSelected={selectedInstrument.Product1 === row.ProductId}
                      onClick={() => {
                        handleSelectInstrument(row.ProductId);
                      }}
                      onRemove={() => {
                        onCardRemoveClick(row.ProductId);
                      }}
                      isUseMobileLayout={isUseMobileLayout}
                    />
                  ))}
                </div>
              );
            }}
            componentName={'selectAssetFromWatchListModal'}
            customClass={baseClasses()}
          />
        )}
      </Modal>
      <ConfirmRemoveFromWatchListModalContainer
        isOpen={isConfirmRemovalModalOpen}
        close={closeConfirmRemovalModal}
        assetId={assetIdForRemoval}
      />
    </React.Fragment>
  );
};

const selectorSortOptions = [
  {
    value: 'ProductFullName',
    label: 'Name'
  },
  {
    value: 'LastTradedPx',
    label: 'Last price',
    isAscendingOption: false
  }
];

const paginationComponentName = 'selectAssetFromWatchListModal';

SelectAssetFromWatchListModalComponent.propTypes = {
  assets: PropTypes.array,
  instruments: PropTypes.array,
  selectInstrument: PropTypes.func,
  clearProForm: PropTypes.func,
  selectedInstrument: PropTypes.object,
  fetching: PropTypes.bool,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  history: PropTypes.object,
  onBack: PropTypes.func,
  resetPage: PropTypes.func
};

SelectAssetFromWatchListModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export { SelectAssetFromWatchListModalComponent };
