import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { BreadCrumbsLayout } from '../../layouts/BreadCrumbsLayout/BreadCrumbsLayoutContainer';
import { USER_TIERS_ARRAY } from '../../constants/userTiers';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { selectUserTierWithExp } from '../../redux/selectors/userTierSelector';
import APIcon from '../../components/common/APIcon';
import './TiersExplainedPage.css';
import {
  ANIMATION_TYPES,
  TierAnimationWrapper
} from '../../components/common/TierAnimationWrapper/TierAnimationWrapper';

const TiersExplainedPageComponent = (props, context) => {
  const classes = getBEMClasses('tiers-explained');
  const { userTier, isGrandMaster } = props;

  const tierBadges = useMemo(
    () =>
      USER_TIERS_ARRAY.map(i => {
        const isAutoplay = i.tierNumber <= userTier;
        const grandmaster = i.tierNumber === 9 && isGrandMaster;

        return (
          <div
            className={classes('tier')}
            key={`tier-${i.tierNumber}-${userTier}`}
            style={{
              background: i.tierDescriptionBackground,
              border: `5px solid ${i.tierBadgeBackground}`
            }}>
            <div
              className={classes('tier-animation-wrapper')}
              style={{
                background: i.tierBadgeBackground
              }}>
              {!isAutoplay &&
                !grandmaster && (
                  <APIcon
                    name="tierLockIcon"
                    customClass={classes('tierLockIcon')}
                  />
                )}
              <TierAnimationWrapper
                className={classes(
                  'tier-animation',
                  !isAutoplay && !grandmaster && 'blurred'
                )}
                animationTier={i.tierNumber}
                animationType={ANIMATION_TYPES.IDLE}
                height={150}
                autoplay={isAutoplay || grandmaster}>
                <div
                  className={classnames(
                    classes('tier-name'),
                    i.tierNumber === 7 && classes('tier-name-black')
                  )}>
                  {i.tierName}
                </div>
              </TierAnimationWrapper>
            </div>

            {(userTier >= i.tierNumber || grandmaster) && (
              <div className={classes('fade')} />
            )}

            <div className={classes('tier-description')}>
              {context.t(i.tierDescription)}
            </div>
          </div>
        );
      }),
    [userTier, isGrandMaster]
  );

  return (
    <BreadCrumbsLayout>
      <div className={classes()}>{tierBadges}</div>
      <div className={classes('tier-disclaimer')}>
        {context.t(
          '*free tokens earned to be given out at the end of each calender month.'
        )}
      </div>
    </BreadCrumbsLayout>
  );
};

TiersExplainedPageComponent.propTypes = {
  userTier: PropTypes.number.isRequired,
  isGrandMaster: PropTypes.bool
};

TiersExplainedPageComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export const TiersExplainedPage = connect(
  state => {
    const { userTier, currentGrandMaster } = selectUserTierWithExp(state);
    return {
      userTier,
      isGrandMaster: !!currentGrandMaster
    };
  },
  null
)(TiersExplainedPageComponent);
