import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { showAffiliateProgramSelector } from '../../redux/selectors/userTierSelector';
import { updateAffiliate } from '../../redux/actions/affiliateActions';
import { generateAlphanumericString } from '../../helpers/randomHelper';
import SettingsMenuComponent from '../../components/SettingsMenu/SettingsMenuComponent';
import AffiliateProgramPage from '../AffiliateProgramPage/AffiliateProgramPage';
import UserSettingsPage from '../UserSettingsPage/UserSettingsPage';
import TradeReportPage from '../TradeReportPage/TradeReportPage';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import KYCSettings from '../KYCSettings';
import { BreadCrumbsLayout } from '../../layouts/BreadCrumbsLayout/BreadCrumbsLayoutContainer';
import './SettingsPage.css';
import './KycSettings.css';
import './TradeReports.css';
import { PaymentsPage } from '../PaymentsPage/PaymentsPage';

const settingsPageClasses = getBEMClasses('settings-page');

const SettingsPage = props => {
  const {
    needAffiliateTagCreated,
    showAffiliateProgram,
    createRandomAffiliateTag
  } = props;
  useEffect(
    () => {
      if (needAffiliateTagCreated) {
        createRandomAffiliateTag();
      }
    },
    [needAffiliateTagCreated]
  );

  return (
    <React.Fragment>
      <div className={settingsPageClasses('container')}>
        <div className={settingsPageClasses('content')}>
          <SettingsMenuComponent />
          <BreadCrumbsLayout
            customClass={settingsPageClasses('screens-content')}>
            <Switch>
              <Route path={'/settings/user'} component={UserSettingsPage} />
              <Route
                path={'/settings/trade-reports'}
                component={TradeReportPage}
              />
              <Route path={'/settings/affiliate'}>
                {showAffiliateProgram ? (
                  <AffiliateProgramPage />
                ) : (
                  <Redirect to="/settings/user" />
                )}
              </Route>
              <Route
                path={'/settings/verification-level'}
                component={KYCSettings}
              />
              <Route path={'/settings/payments'} component={PaymentsPage} />
              <Route>
                <Redirect to={'/not-found'} />
              </Route>
            </Switch>
          </BreadCrumbsLayout>
        </div>
      </div>
    </React.Fragment>
  );
};

SettingsPage.propTypes = {
  needAffiliateTagCreated: PropTypes.bool,
  showAffiliateProgram: PropTypes.bool,
  createRandomAffiliateTag: PropTypes.func
};

export default connect(
  state => {
    const showAffiliateProgram = showAffiliateProgramSelector(state);
    return {
      // the initial reducer state tag is '' (empty string). when the request says
      // that the user has no affiliate tag, than it becomes undefined
      needAffiliateTagCreated:
        state.affiliate &&
        state.affiliate.affiliateTag === undefined &&
        showAffiliateProgram,
      showAffiliateProgram
    };
  },
  {
    createRandomAffiliateTag: () =>
      updateAffiliate({ tag: generateAlphanumericString(8) })
  }
)(SettingsPage);
