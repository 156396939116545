import React from 'react';
import PropTypes from 'prop-types';
import '../styles/components/buy-out.css';

const BuyOut = (props, context) => {
  const { onBuyOutClick, isTermsOfUseAccepted } = props;
  return (
    <div className="buy-out">
      <div>
        <div className="buy-out-title">{context.t('Buy It Now!')}</div>
        <div className="buy-out-description">
          {context.t(
            'Submit offer for verification; once verified, offer will be presented to co-owners for consideration.'
          )}
        </div>
      </div>
      <div className="buy-out-submit-row">
        <button
          className="buy-out-submit"
          onClick={onBuyOutClick}
          disabled={!isTermsOfUseAccepted}
          type='button'>
          {context.t('Submit')}
        </button>
      </div>
    </div>
  );
};

BuyOut.propTypes = {
  onBuyOutClick: PropTypes.func,
  isTermsOfUseAccepted: PropTypes.bool
};
BuyOut.contextTypes = {
  t: PropTypes.func.isRequired
};

export { BuyOut };
