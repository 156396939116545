import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import APIcon from '../APIcon';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './SectionHeader.css';

const SectionHeader = (props, context) => {
  const {
    title,
    iconName,
    link,
    backgroundColor,
    viewAllBackgroundColor,
    customClick,
    history,
    viewAllText,
    textColor = '#ffffff',
    viewAllArrowsCustomClass = '',
    customClass = ''
  } = props;
  const baseClasses = getBEMClasses(['section-header', customClass]);

  return (
    <div className={baseClasses('')} style={{ '--color': backgroundColor }}>
      <div className={baseClasses('title-with-icon-wrapper')}>
        {iconName && (
          <APIcon name={iconName} customClass={baseClasses('title-icon')} />
        )}
        <div className={baseClasses('title')} style={{ '--color': textColor }}>
          {title}
        </div>
      </div>

      <div
        className={baseClasses('view-all-wrapper')}
        style={{ '--color': viewAllBackgroundColor }}
        onClick={
          customClick
            ? customClick
            : () => {
                history.push(link);
              }
        }>
        <div
          className={baseClasses('view-all')}
          style={{ '--color': textColor }}>
          {viewAllText ? viewAllText : context.t('view all')}
          <span className={baseClasses('view-all-arrows')}>
            <APIcon
              name="viewAllArrowRight"
              customClass={viewAllArrowsCustomClass}
            />
            <APIcon
              name="viewAllArrowRight"
              customClass={viewAllArrowsCustomClass}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

SectionHeader.contextTypes = {
  t: PropTypes.func.isRequired
};

SectionHeader.propTypes = {
  title: PropTypes.string,
  iconName: PropTypes.string,
  link: PropTypes.string,
  backgroundColor: PropTypes.string,
  viewAllBackgroundColor: PropTypes.string,
  history: PropTypes.object,
  customClick: PropTypes.func,
  viewAllText: PropTypes.string,
  textColor: PropTypes.string,
  viewAllArrowsCustomClass: PropTypes.string,
  customClass: PropTypes.string
};

export default withRouter(SectionHeader);
