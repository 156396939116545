import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { parseNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import APInput from '../APInput';
import BigNumber from 'bignumber.js';
import { Controller } from 'react-hook-form/dist/index.ie11';

const separators = ['.', ','];

const InnerNumberInput = props => {
  // state.displayValue and props.input.value aren't in sync. Why:
  // props.input.value - redux-form value from reducer and it is source of truth.
  // state.displayValue - value that shown in input
  // Conditions when input.value and state.displayValue aren't in sync:
  // 1) user prints fractional number:
  // - Imagine user wants to enter 5.9
  //    User enters `5`: reducer (props.input.value) stores `5`, state.displayValue stores `5`
  //    User enters `.`: reducer stores `5` (!!!), state.displayValue stores `5.`
  //    User enters `9`: reducer stores `5.9`, state.displayValue stores `5.9`
  // 2) input change forced from outside by dispatching redux-form's change directly (examples: clearing order form, sync price and quantity)
  const [displayValue, setDisplayValue] = useState('');

  useEffect(
    () => {
      // handle forced change based on difference between input.value and displayValue
      // exclude case with entering the separator symbol first
      // keep input.value and displayValue in sync otherwise
      if (
        props.input.value === '' ||
        (Number(displayValue) !== 0 &&
          (Number(props.input.value) === 0 ||
            Number.isNaN(Number(props.input.value))) &&
          !isSeparator(displayValue))
      ) {
        setDisplayValue('');
      } else if (!isSeparator(displayValue)) {
        setDisplayValue(new BigNumber(props.input.value).toFixed());
      }
    },
    [props.input.value]
  );

  const handleChange = e => {
    // custom onChange to parse and validate input value
    // before calling standard onChange from redux-form and customChange (if exists)

    const { allowNegative, customChange } = props;
    const inputValue = e.target.value;

    const value = parseNumberToLocale(inputValue, null, !allowNegative);
    // prevent adding two dots or two commas
    const validSeparator =
      !inputValue.includes('..') && !inputValue.includes(',,');

    if (
      !Number.isNaN(value) &&
      validateDecimalPlaces(inputValue) &&
      validSeparator
    ) {
      setDisplayValue(inputValue);
      props.input.onChange(value);

      if (customChange) {
        customChange(value);
      }
    }
  };

  const handleFocus = () => {
    if (Number(displayValue) === 0) {
      setDisplayValue(0);
    }
  };

  const validateDecimalPlaces = valueString => {
    const { decimalPlaces } = props;
    if (decimalPlaces >= 0) {
      // Handle multiple zeroes after the comma, valueString contains these
      const decimals = valueString.split('.')[1];

      return !decimals || decimals.length <= decimalPlaces;
    }

    return true;
  };

  const isSeparator = value => separators.includes(value);

  const { input, ...passedProps } = props;

  return (
    <APInput
      step="any"
      {...passedProps}
      autoComplete="off"
      type="number"
      input={{
        ...input,
        value: displayValue,
        onChange: handleChange,
        //call onBlur without args so the stored value is not changed
        onBlur: () => input.onBlur()
      }}
      onChange={handleChange}
      onClick={() => {
        input.onFocus();
        handleFocus('onClick');
      }}
      onFocus={() => {
        input.onFocus();
        handleFocus('onFocus');
      }}
    />
  );
};

const APNumberInput = props => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ onChange, onBlur, value }, { isDirty, error }) => (
        <InnerNumberInput
          {...props}
          input={{ value: value || 0, onChange, onBlur, onFocus: () => {} }}
          meta={{
            dirty: isDirty,
            error: error
          }}
        />
      )}
    />
  );
};

InnerNumberInput.propTypes = {
  input: PropTypes.any,
  allowNegative: PropTypes.bool,
  decimalPlaces: PropTypes.number,
  customChange: PropTypes.func
};

APNumberInput.defaultProps = {
  allowNegative: false
};

APNumberInput.propTypes = {
  allowNegative: PropTypes.bool,
  decimalPlaces: PropTypes.number,
  name: PropTypes.string,
  control: PropTypes.any
};

/*
Example:
          <APNumberInput
            control={control}
            name={'name'}
            decimalPlaces={2}
          />
*/

export { APNumberInput };
