import React from "react";
import StubPage from "./StubPage";

const NotFoundPage = () => (
  <StubPage
    title="404"
    subtitle="It’s a drought!"
    text="You’ve searched long and hard, but this page does not exist. Let’s get you some Liquid!" />
);

export default NotFoundPage;
