import React from 'react';
import ApexChartContainer from './ApexChartContainer';
import TVChartContainer from './TVChartContainer';
import LightWeightChartContainer from '../LightWeightCharts/LightWeightChartContainer';
import config from '../../config';

const chartOptions = {
  APEX: <ApexChartContainer />,
  TradingView: <TVChartContainer />,
  LightWeight: <LightWeightChartContainer />
};

const PriceChartContainer = props => {
  let chart = config.TradingLayout.chart;
  if (props.chart) chart = props.chart;
  return chartOptions[chart];
};

export default PriceChartContainer;
