import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import Logo from '../../images/onboarding-logo.png';
import { setSpotlightId, SPOTLIGHT_IDS } from '../../redux/actions/spotlightActions';
import './styles.css';

const baseClass = getBEMClasses('onboarding');

const OnboardingTooltip = (
  {
    index: currentStepIndex,
    step: {
      customWrapperStyle = {},
      customArrowStyle = {},
      arrowPlacement,
      content
    },
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    onCloseTooltip,
    onFinish,
    goToStep
  },
  context
) => {
  const isLastStep = currentStepIndex === 7;

  useEffect(
    () => {
      if (isLastStep) {
        window.zE('messenger', 'open');
      } else {
        window.zE('messenger', 'close');
      }
    },
    [isLastStep]
  );

  const handleClose = e => {
    closeProps.onClick(e);
    onCloseTooltip();
  };

  const handlePrev = e => {
    backProps.onClick(e);
    goToStep(currentStepIndex - 1);

    if (currentStepIndex === 7) {
      // scroll to top for wallet step
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 500);
    }
  };

  const handleNext = () => {
    if (isLastStep) {
      window.zE('messenger', 'close');
      onCloseTooltip();
      onFinish();
    } else {
      goToStep(currentStepIndex + 1);

      if (currentStepIndex === 4) {
        // scroll to top for wallet step
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500);
      }
    }
  };

  return (
    <div
      {...tooltipProps}
      className={baseClass('wrapper')}
      style={{
        marginTop: currentStepIndex === 0 ? '235px' : 0,
        ...customWrapperStyle
      }}>
      <div
        className={baseClass('arrow', arrowPlacement)}
        style={customArrowStyle}
      />
      <img src={Logo} alt="logo" className={baseClass('logo')} />
      <div className={baseClass('content')}>
        <button
          {...closeProps}
          className={baseClass('skip')}
          onClick={handleClose}>
          {context.t('Skip for Now')} {'>'}
          {'>'}
        </button>
        <p className={baseClass('text-wrapper')}>{content}</p>
      </div>
      <div className={baseClass('buttons-wrapper')}>
        {currentStepIndex > 0 && (
          <button
            {...backProps}
            className={baseClass('prev-button')}
            onClick={handlePrev}>
            {context.t('Previous')}
          </button>
        )}

        <button
          {...primaryProps}
          className={baseClass('next-button')}
          aria-label={context.t('Next')}
          title={context.t('Next')}
          onClick={handleNext}>
          {context.t(isLastStep ? 'Done' : 'Next')}
        </button>
      </div>
    </div>
  );
};

OnboardingTooltip.contextTypes = {
  t: PropTypes.func.isRequired
};

OnboardingTooltip.propTypes = {
  index: PropTypes.number,
  step: PropTypes.any,
  backProps: PropTypes.any,
  closeProps: PropTypes.any,
  primaryProps: PropTypes.any,
  tooltipProps: PropTypes.any,
  onCloseTooltip: PropTypes.func,
  onFinish: PropTypes.func,
  goToStep: PropTypes.func
}

export default connect(
  null,
  { onFinish: () => setSpotlightId(SPOTLIGHT_IDS.ADD_FUNDS) }
)(OnboardingTooltip);
