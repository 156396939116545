import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';

import './Switch.css';

export const Switch = (props, context) => {
  const { input, onClick, customClass, showText } = props;
  const value = input.value;
  const modifier = { on: value };
  const text = value ? context.t('On') : context.t('Off');
  const bemClasses = getBEMClasses('switch', customClass);

  const handleClick = () => {
    onClick(value);
    input.onChange(!value);
  };

  return (
    <div className={bemClasses('switch', modifier)} onClick={handleClick}>
      <div className={bemClasses('toggle', modifier)} />
      {showText && <div className={bemClasses('text', modifier)}>{text}</div>}
    </div>
  );
};

Switch.contextTypes = {
  t: PropTypes.func.isRequired
};
Switch.defaultProps = {
  showText: false
};

Switch.propTypes = {
  input: PropTypes.object,
  onClick: PropTypes.func,
  customClass: PropTypes.string,
  showText: PropTypes.bool
};
