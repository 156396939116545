import { connect } from 'react-redux';
import selector from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import InstrumentPositionsComponent from './InstrumentPositionsComponent';

const mapStateToProps = state => {
  const positions = selector(state);
  const { fetching } = state.apexCore.position;

  return {
    positions,
    fetching
  };
};

export default connect(mapStateToProps)(InstrumentPositionsComponent);
