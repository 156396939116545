export const DELAY_FINISHED = Symbol('DELAY_FINISHED');

export const delay = (duration) => new Promise(resolve => {
  setTimeout(() => resolve(DELAY_FINISHED), duration);
});

/**
 * Allows to display some loading if a promise takes too long, and uses throttling 
 * to avoid flickering. At first, promise is given some time to finish. Then the 
 * loading is displayed until the promise is finished, for at least some minimal duration.
 * @param {Object} sourcePromise a promise to wrap
 * @param {number} noLoadingTimeout how much time the promise have to finish before loading is shown
 * @param {number} minLoadingShowTime minimal duration for loading to be shown
 * @param { function(): void } onShowLoading called when loading should be shown
 */
export const promiseWithThrottledLoading = async (
  sourcePromise,
  noLoadingTimeout,
  minLoadingShowTime,
  onShowLoading
) => {
  const waitBeforeShowLoadingPromise = delay(noLoadingTimeout);
  const resultOrShowLoading = await Promise.race([
    sourcePromise,
    waitBeforeShowLoadingPromise
  ]);
  if (resultOrShowLoading === DELAY_FINISHED) {
    onShowLoading();
    const minLoadingTimePromise = delay(minLoadingShowTime);
    const [result] = await Promise.all([sourcePromise, minLoadingTimePromise]);
    return result;
  } else {
    return resultOrShowLoading;
  }
};