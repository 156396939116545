import { createSelector } from 'reselect';
import get from 'lodash/get';
import { productPositionAssetsSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';

export const newRecentActivitySelector = createSelector(
  [
    state => state.apexCore.orderHistory.recentActivity,
    productPositionAssetsSelector,
    ({ productManifest: { manifest } }) => manifest
  ],
  (recentActivity, products, manifest) => {
    const formattedRecentActivity = recentActivity ? recentActivity : [];
    return formattedRecentActivity.map(el => {
      const product = products.find(i => i.ProductId === el.ProductId) || {};

      return {
        ...el,
        ...product,
        iconFileName: get(
          manifest,
          [product.ProductSymbol, 'iconFileName'],
          `${product.ProductSymbol}DEFAULT_ICON`
        ),
        Product1Symbol: product.ProductSymbol,
        Action: {
          typeIcon: el.TransactionType,
          productIcon: {
            productManifest: {
              fetching: false
            },
            size: 24
          }
        }
      };
    });
  }
);
