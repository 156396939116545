import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './UserXP.css';

const classes = getBEMClasses('user-tier-xp');

const UserProgress = (props, context) => {
  const { currentTierDetails, nextTierDetails, userExp, userExpHold } = props;
  return (
    <div className={classes('progress-wrapper')}>
      <div
        className={classes('progress-level-wrapper')}
        style={{ '--levelColor': currentTierDetails.tierExpColor }}>
        <p className={classes('progress-level')}>{context.t('lv.')}</p>
        <p className={classes('progress-level-number')}>
          {currentTierDetails.tierNumber}
        </p>
      </div>
      <div className={classes('progress-line-wrapper')}>
        <progress
          id={'tier-progress'}
          max={nextTierDetails.expPoints}
          value={userExp}
          style={{
            '--currentLevelColor': currentTierDetails.tierExpColor,
            '--nextLevelColor': nextTierDetails.tierExpColor
          }}
        />
        <div className={classes('progress-value')}>
          {userExp} / {nextTierDetails.expPoints} XP
          <span className={classes('pending-progress-value')}>
            {userExpHold > 0 && ` <${userExpHold}XP Pending>`}
          </span>
        </div>
      </div>
      <div
        className={classes('progress-level-wrapper')}
        style={{ '--levelColor': nextTierDetails.tierExpColor }}>
        <p className={classes('progress-level')}>{context.t('lv.')}</p>
        <p className={classes('progress-level-number')}>
          {nextTierDetails.tierNumber}
        </p>
      </div>
    </div>
  );
};

UserProgress.contextTypes = {
  t: PropTypes.func.isRequired
};

UserProgress.propTypes = {
  currentTierDetails: PropTypes.object,
  nextTierDetails: PropTypes.object,
  userExp: PropTypes.number,
  userExpHold: PropTypes.number
};

export default UserProgress;
