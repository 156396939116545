import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  checkIsCorrectAssetAttribute,
  isProductByScriptScenario,
  selectOfferedProducts
} from '../../../redux/selectors/productsSelector';
import { selectAssetsBalanceInBaseCurrency } from '../../../redux/selectors/level1Selector';
import { CardOfferingsComponent } from './CardOfferingsComponent';
import { getAvailableTokens } from '../../../redux/actions/cardActions';
import { availableTokensSelector } from '../../../redux/selectors/cardSelector';
import { openBuySellModal } from '../../../redux/actions/buySellModalActions';

const getProductProperties = product => {
  return {
    productOfferingImage: product.productAttributes.ProductOfferingImageURL,
    productOfferingBackgroundColor:
      product.productAttributes.ProductOfferingBackgroundColor,
    productImageBackground: product.productAttributes.ProductImageBackgroundURL,
    productPreviewURL: product.productAttributes.ProductPreviewURL,
    productName: product.ProductFullName,
    productCollection: product.productAttributes.ProductCollection,
    rarity: product.productAttributes.ProductRarity,
    productDescription: product.productAttributes.ProductDescription,
    ProductId: product.ProductId,
    isSoldOut: checkIsCorrectAssetAttribute(
      product.productAttributes.soldOut,
      'true'
    ),
    ProductSizeBackgroundSecondaryColor:
      product.productAttributes.ProductSizeBackgroundSecondaryColor,
    CurrentOfferingTextColor:
      product.productAttributes.CurrentOfferingTextColor,
    Type: 'DigitalAsset',
    available_tokens: 0
  };
};

const mapStateToProps = state => {
  const assets = selectAssetsBalanceInBaseCurrency(state);
  const offeredProducts = selectOfferedProducts(state);
  // If API hasn't loaded yet
  if (!assets.assetsWithPriceAndPercent.length) {
    return {
      offerings: [],
      isReady: false
    };
  }

  if (!offeredProducts.length) {
    return {
      offerings: [],
      isReady: true
    };
  }

  const formattedOfferings = offeredProducts.map(item => {
    const product = getProductProperties(item);
    const onlyLimitAvailable =
      // TODO(Apr 05, 2022): uncomment or remove when we decide whether we need buy/sell limit orders
      isProductByScriptScenario(
        state,
        product.ProductId
      ) /*  ||
      BigNumber(
        approximateQuantitySelector(state, {
          side: buyValue,
          productId: product.ProductId
        })
      ).lte(0) */;

    return {
      ...product,
      onlyLimitAvailable
    };
  });

  return {
    availableTokens: availableTokensSelector(state),
    isReady: true,
    offerings: formattedOfferings
  };
};

const mapDispatchToProps = dispatch => ({
  makeOrder: props => {
    dispatch(openBuySellModal(props));
  },
  getAvailableTokens: params => dispatch(getAvailableTokens(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CardOfferingsComponent));
