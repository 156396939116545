import React from 'react';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

import withAuthentication from '../../hocs/withAuthentication';

import './DashboardPage.css';
import { DashboardWalletSummaryContainer } from './DashboardWalletSummary/DashboardWalletSummaryContainer';
import CardOfferingsContainer from './CardOfferings/CardOfferingsContainer';
import { DashboardMarketPlaceContainer } from './DashboardMarketPlace/DashboardMarketPlaceContainer';
import DashboardMyAssetsContainer from './DashboardMyAssets/DashboardMyAssetsContainer';
import { GlobalNotification } from '../../components/GlobalNotification';

const layoutClasses = getBEMClasses('dashboard-page');

const DashboardPage = () => {
  return (
    <React.Fragment>
      <div className={layoutClasses('notification')}>
        <GlobalNotification />
      </div>

      <div
        className={layoutClasses()}
        id="dashboard-page"
        data-test="dashboard-page-root">
        <div className={layoutClasses('content')}>
          <div className={layoutClasses('left-part')}>
            <div className={layoutClasses('section')} id="offerings">
              <CardOfferingsContainer />
            </div>
            <div
              className={layoutClasses('my-assets-section')}
              id="my-collectibles">
              <DashboardMyAssetsContainer />
            </div>
          </div>
          <div className={layoutClasses('right-part')} id="wallet">
            <DashboardWalletSummaryContainer />
          </div>
        </div>
        <div className={layoutClasses('market-place')} id="market-place">
          <DashboardMarketPlaceContainer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withAuthentication(DashboardPage);
