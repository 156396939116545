import { modalDecorator } from '../common/Modal/modalDecorator';
import { connect } from 'react-redux';
import DepositWithdrawStatusModal from './DepositWithdrawStatusModal';
import { MODAL_TYPES } from '../../redux/actions/modalActions';
import { getSubscriptions } from '../../redux/actions/subscriptionActions';

const container = connect(
  null,
  {
    getSubscriptions: () => getSubscriptions()
  }
)(DepositWithdrawStatusModal);

export default modalDecorator({
  name: MODAL_TYPES.DEPOSIT_WITHDRAW_STATUS_MODAL
})(container);
