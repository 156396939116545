import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import APIcon from '../APIcon';
import { formatNumberToLocale } from '../../../helpers/numberFormatter';
import BigNumber from 'bignumber.js';
import './InstrumentCard.css';

const baseClasses = getBEMClasses('instrument-card');

const InstrumentCard = (props, context) => {
  const {
    row: {
      product: {
        productAttributes: {
          ProductDetailsNumber = 0,
          ProductImageURL,
          ProductColor,
          InstrumentCardBackground,
          InstrumentCardImage
        }
      },
      ProductFullName,
      BestBid,
      BestOffer,
      Rolling24HrPxChangePercent = 0,
      LastTradedPx
    },
    isSelected,
    onClick,
    isUseMobileLayout,
    onRemove
  } = props;

  const renderValues = () => {
    return (
      <div className={baseClasses('rows')}>
        <div className={baseClasses('row')}>
          <div className={baseClasses('row-value')}>{LastTradedPx}</div>
          <div className={baseClasses('row-name')}>
            {context.t('Last Price')}
          </div>
        </div>
        <div className={baseClasses('row')}>
          <div className={baseClasses('row-value')}>
            {formatNumberToLocale(
              BigNumber(Rolling24HrPxChangePercent).dp(2, BigNumber.ROUND_HALF_UP)
            )}%
          </div>
          <div className={baseClasses('row-name')}>
            {context.t('24 Hour Pct Chg')}
          </div>
        </div>
        <div className={baseClasses('row')}>
          <div className={baseClasses('row-value')}>{BestBid}</div>
          <div className={baseClasses('row-name')}>{context.t('Bid')}</div>
        </div>
        <div className={baseClasses('row')}>
          <div className={baseClasses('row-value')}>{BestOffer}</div>
          <div className={baseClasses('row-name')}>{context.t('Ask')}</div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={baseClasses('border-wrapper')}
      style={{
        background: isSelected
          ? 'linear-gradient(to right, #3F8CFF, #825AF6)'
          : InstrumentCardBackground
            ? `url(${InstrumentCardBackground})
right 0% center
/ cover no-repeat, ${ProductColor}`
            : ProductColor
      }}
      onClick={onClick}>
      <div
        className={baseClasses('')}
        style={{
          background: InstrumentCardBackground
            ? `url(${InstrumentCardBackground})
      right 0% center
     / cover no-repeat, ${ProductColor}`
            : ProductColor
        }}>
        {isUseMobileLayout ? (
          <div className={baseClasses('mobile-content')}>
            <div className={baseClasses('mobile-left-content')}>
              <div className={baseClasses('mobile-title-wrapper')}>
                <span className={baseClasses('number')}>
                  #{ProductDetailsNumber}
                </span>{' '}
                {ProductFullName}
              </div>
              <div className={baseClasses('mobile-image-wrapper')}>
                <img
                  src={InstrumentCardImage || ProductImageURL}
                  className={baseClasses('mobile-image')}
                  alt="asset"
                />
              </div>
            </div>
            <div className={baseClasses('mobile-right-content')}>
              {renderValues()}
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className={baseClasses('instrument-image-wrapper')}>
              <img
                src={InstrumentCardImage || ProductImageURL}
                className={baseClasses('instrument-image')}
                alt="asset"
              />
            </div>
            <div className={baseClasses('content')}>
              <div className={baseClasses('title')}>
                <span className={baseClasses('number')}>
                  #{ProductDetailsNumber}
                </span>{' '}
                {ProductFullName}
              </div>
              {renderValues()}
            </div>
          </React.Fragment>
        )}
      </div>
      {onRemove && (
        <button
          className={baseClasses('remove-btn')}
          onClick={e => {
            e.stopPropagation();
            onRemove();
          }}>
          <APIcon name="close" />
        </button>
      )}
    </div>
  );
};

InstrumentCard.contextTypes = {
  t: PropTypes.func.isRequired
};

InstrumentCard.propTypes = {
  row: PropTypes.object,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  isUseMobileLayout: PropTypes.bool,
  onRemove: PropTypes.func
};

export { InstrumentCard };
