import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import APIcon from '../../components/common/APIcon';

import './VotePage.css';
import { InfoIcon } from '../../images/react-icons/infoIcon/infoIcon';
import { VoteCountdownModal } from './VoteModals/VoteCountdownModal';
import { LiveVoteModal } from './VoteModals/LiveVoteModal';
import { ConfirmVoteModal } from './VoteModals/ConfirmVoteModal';
import { VOTE_STATUSES } from '../../constants/votingConstants';
import { voteForCard } from '../../api/liquidApi';
import { TIME_SORT, VoteCardsSection } from './VoteCardsSection';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { Select } from '../../components/common/Select';
import { createTimer } from '../../helpers/timerFactoryHelper';

const classes = getBEMClasses('vote-page');

export const VotePageComponent = (props, context) => {
  const {
    countdownCards,
    liveVoteCards,
    resultVoteCards,
    openConfirmVoteModal,
    isLoaded,
    moveCardFromCountdownToLiveVote,
    setVoteResult
  } = props;
  const [currentSortOption, setCurrentSortOption] = useState('Sort By');
  const [cardNameFilter, setCardNameFilter] = useState('');
  const [voteCountdownModalOpen, setVoteCountdownModalOpen] = useState(false);
  const [liveVoteModalOpen, setLiveVoteModalOpen] = useState(false);
  const [voteStatusFilter, setVoteStatusFilter] = useState(
    VOTE_STATUSES.COUNTDOWN
  );
  const [timer, setTimer] = useState();
  const cardsForVote = useMemo(
    () => {
      if (voteStatusFilter === VOTE_STATUSES.COUNTDOWN) {
        return countdownCards;
      } else if (voteStatusFilter === VOTE_STATUSES.LIVE) {
        return liveVoteCards;
      } else {
        return resultVoteCards;
      }
    },
    [voteStatusFilter, countdownCards, liveVoteCards, resultVoteCards]
  );

  useEffect(() => {
    setTimer(prevTimer => {
      prevTimer && prevTimer.reset();
      return createTimer(1000);
    });
    return () => {
      setTimer(prevTimer => {
        prevTimer && prevTimer.reset();
        return undefined;
      });
    };
  }, []);

  const confirmVote = useCallback(
    (productId, vote, voteId) => {
      voteForCard(productId, vote, voteId).then(
        res => {
          if (res && res.productId) {
            setVoteResult(res)
          }
        },
        () => {}
      );
    },
    [cardsForVote]
  );

  const changeCardStatus = useCallback(
    id => {
      moveCardFromCountdownToLiveVote(id);
    },
    [cardsForVote]
  );

  const handleSetSortValue = value => setCurrentSortOption(value);

  const sortOptions = [
    {
      value: 'ProductFullName',
      label: 'Name',
      onClick: handleSetSortValue
    },
    {
      value: 'Rolling24HrPxChangePercent',
      label: 'Growth',
      onClick: handleSetSortValue
    },
    {
      value: 'BestBid',
      label: 'Price',
      onClick: handleSetSortValue
    },
    {
      value: TIME_SORT,
      label: 'Time',
      onClick: handleSetSortValue
    }
  ];

  return (
    <div className={classes()}>
      <div className={classes('header')}>
        <div
          className={classes(
            'header-item',
            voteStatusFilter === VOTE_STATUSES.COUNTDOWN && 'active'
          )}
          onClick={() => setVoteStatusFilter(VOTE_STATUSES.COUNTDOWN)}>
          {context.t('Vote Countdown')}
          <div
            onClick={e => {
              e.stopPropagation();
              setVoteCountdownModalOpen(!voteCountdownModalOpen);
            }}>
            <InfoIcon name="infoIconSmall" customClass={classes('info-icon')} />
          </div>
        </div>
        <div
          className={classes(
            'header-item',
            voteStatusFilter === VOTE_STATUSES.LIVE && 'active'
          )}
          onClick={() => setVoteStatusFilter(VOTE_STATUSES.LIVE)}>
          {context.t('Live Vote')}
          <div
            onClick={e => {
              e.stopPropagation();
              setLiveVoteModalOpen(!liveVoteModalOpen);
            }}>
            <InfoIcon name="infoIconSmall" customClass={classes('info-icon')} />
          </div>
        </div>

        <div
          className={classes(
            'header-item',
            voteStatusFilter === VOTE_STATUSES.RESULT && 'active'
          )}
          onClick={() => setVoteStatusFilter(VOTE_STATUSES.RESULT)}>
          {context.t('Vote Result')}
        </div>
      </div>
      <div className={classes('filters')}>
        <Select
          customClass={'vote-select'}
          options={sortOptions}
          currentOption={currentSortOption}
        />
        <div className={classes('search-input-container')}>
          <div className={classes('search-icon-container')}>
            <APIcon name="search" customClass={classes('search-icon')} />
          </div>
          <input
            className={classes('search-input')}
            placeholder={context.t('Search here...')}
            onChange={e => setCardNameFilter(e.target.value)}
          />
        </div>
      </div>
      <div className={classes('cards-container')}>
        {!isLoaded ? (
          <Spinner customClass={classes('cards-fetching')} />
        ) : (
          <VoteCardsSection
            cardsForVote={cardsForVote}
            voteStatusFilter={voteStatusFilter}
            cardNameFilter={cardNameFilter}
            currentSortOption={currentSortOption}
            openConfirmVoteModal={openConfirmVoteModal}
            changeCardStatus={changeCardStatus}
            timer={timer}
          />
        )}
      </div>
      <VoteCountdownModal
        isModalOpen={voteCountdownModalOpen}
        closeModal={() => setVoteCountdownModalOpen(false)}
      />
      <LiveVoteModal
        isModalOpen={liveVoteModalOpen}
        closeModal={() => setLiveVoteModalOpen(false)}
      />
      <ConfirmVoteModal confirmVote={confirmVote} />
    </div>
  );
};

VotePageComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

VotePageComponent.propTypes = {
  countdownCards: PropTypes.array,
  liveVoteCards: PropTypes.array,
  resultVoteCards: PropTypes.array,
  openConfirmVoteModal: PropTypes.func,
  isLoaded: PropTypes.bool,
  moveCardFromCountdownToLiveVote: PropTypes.func,
  setVoteResult: PropTypes.func
};
