import React from 'react';
import PropTypes from 'prop-types';

import { APSection } from '../APSection';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './SectionPresetSort.css';
import { Select } from '../Select';
const classes = getBEMClasses('section-preset-sort');

export const SectionPresetSort = (props) => {
  const {
    additionalSortComponent = null,
    children,
    title,
    sortOptions,
    currentSortOption = ''
  } = props;
  return (
    <APSection
      title={title}
      customClass={classes()}
      additionalComponent={
        <div className={classes('sortWrapper')}>
          {additionalSortComponent}
          <Select
            options={sortOptions}
            currentOption={currentSortOption}
          />
        </div>
      }>
      {children}
    </APSection>
  );
};

SectionPresetSort.propTypes = {
  additionalSortComponent: PropTypes.node,
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  ),
  currentSortOption: PropTypes.string.isRequired
};
