import { SET_SPOTLIGHT_ID } from "../actions/spotlightActions";

const initialState = {
  id: null,
};

const spotlightReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPOTLIGHT_ID:
      return {
        ...state,
        id: action.payload,
      };
    default:
      return state;
  }
};

export default spotlightReducer;
