import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { APTypography } from '../common/APTypography';
import './UserNameTierBadge.css';
import {
  ANIMATION_TYPES,
  TierAnimationWrapper
} from '../common/TierAnimationWrapper/TierAnimationWrapper';
import { degreeStringEnding } from '../../helpers/stringFormatterHelper';
import { useMedia } from 'react-use';

const classes = getBEMClasses('user-name-tier-badge');

const UserNameTierBadgeComponent = props => {
  const {
    userName,
    userTier,
    history,
    isMobile,
    degree,
    isGrandMaster
  } = props;
  const [isOpeningFinished, setIsOpeningFinished] = useState(false);
  const isTablet = useMedia('(max-width: 1060px)');

  return (
    <div className={classes()}>
      <div
        className={classes('stub-tier')}
        onClick={() => history.push('/tiers')}
      />
      {!isOpeningFinished && (
        <TierAnimationWrapper
          className={classes('tier')}
          animationTier={isGrandMaster ? 9 : userTier}
          animationType={ANIMATION_TYPES.OPENING}
          height={isMobile ? 76.8 : 110.4}
          width={isMobile ? 200 : isTablet ? 240 : 304}
          loop={false}
          autoplay
          onComplete={() => {
            setIsOpeningFinished(true);
          }}
        />
      )}
      <TierAnimationWrapper
        className={classes('tier')}
        animationTier={isGrandMaster ? 9 : userTier}
        animationType={ANIMATION_TYPES.IDLE}
        height={isMobile ? 76.8 : 110.4}
        width={isMobile ? 200 : isTablet ? 240 : 304}
        autoplay
      />
      <APTypography
        color={userTier === 1 ? 'brown1' : userTier === 7 ? 'black2' : 'white1'}
        className={classes('name')}
        onClick={() => history.push('/tiers')}>
        {userName}
      </APTypography>
      {!!degree &&
        !isGrandMaster && (
          <div className={classes('degree')}>
            {degree}
            <span className={classes('degree-ending')}>
              {degreeStringEnding(degree.toString())}
            </span>{' '}
            DEGREE
          </div>
        )}
    </div>
  );
};

UserNameTierBadgeComponent.propTypes = {
  userTier: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  history: PropTypes.object,
  isMobile: PropTypes.bool,
  degree: PropTypes.number,
  isGrandMaster: PropTypes.bool
};

const UserNameTierBadge = withRouter(UserNameTierBadgeComponent);

export { UserNameTierBadge };
