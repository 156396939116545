import {
  authSuccess,
  logout
} from 'apex-web/lib/redux/actions/authActions';
import apex from 'apex-web/lib/apex'

export const validateSessionToken = token => async dispatch => {
  const auth = await apex.connection.WebAuthenticateUser({
    SessionToken: token
  });

  if (auth.Authenticated) {
    dispatch(authSuccess(auth));
  } else {
    await dispatch(logout());
  }
};