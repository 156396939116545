import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { getDataTestAttribute } from 'apex-web/lib/helpers/dataTestAttributeHelper';
import './APInput.css';

const baseClass = 'ap-input';

export const InnerInput = (props, context) => {
  const {
    input,
    customClass,
    classModifiers,
    meta,
    rows,
    info,
    label,
    labelInInput,
    rightLabelText,
    iconComponent,
    placeholderInInput,
    inputRef,
    alwaysControlled,
    ...passedProps
  } = props;

  const isTextArea = rows > 1;
  const isError = meta && meta.touched && meta.error;

  const bemClasses = getBEMClasses([baseClass, customClass]);
  if (!alwaysControlled && input && !input.value) {
    delete input.value;
  }

  const inputProps = {
    className: classnames(
      'form-control',
      { [bemClasses('with-label')]: labelInInput },
      meta && { [bemClasses('input', 'error', '')]: meta.dirty && meta.error },
      { [bemClasses('text-area')]: isTextArea },
      bemClasses('input', rightLabelText ? 'with-right-label' : classModifiers),
      { [bemClasses('validation-error')]: isError }
    ),
    ...(inputRef && { ref: inputRef }),
    ...input,
    ...passedProps
  };

  return (
    <div
      className={`form-group ${bemClasses('input-wrapper', classModifiers)}`}>
      {props.type !== 'hidden' && (
        <label className={`ap--label ${bemClasses('label', classModifiers)}`}>
          {context.t(label)}
        </label>
      )}

      <div
        className={classnames(
          rightLabelText && bemClasses('input-with-right-label-container'),
          bemClasses('input-box')
        )}>
        {labelInInput && (
          <span className={`label-in-input ${bemClasses('label-in-input')}`}>
            {labelInInput}
          </span>
        )}

        {isTextArea ? (
          <textarea
            {...inputProps}
            data-test={getDataTestAttribute(props, 'label')}
          />
        ) : placeholderInInput ? (
          <React.Fragment>
            <input
              data-test={getDataTestAttribute(props, 'label')}
              {...inputProps}
            />
            <span
              className={bemClasses(
                'placeholder-in-input',
                input.value ? 'placeholder-in-input-value' : ''
              )}>
              {placeholderInInput}
            </span>
          </React.Fragment>
        ) : !iconComponent ? (
          <input
            data-test={getDataTestAttribute(props, 'label')}
            {...inputProps}
          />
        ) : (
          <div className={bemClasses('input-with-icon')}>
            <input
              data-test={getDataTestAttribute(props, 'label')}
              {...inputProps}
            />
            {iconComponent}
          </div>
        )}

        {rightLabelText ? (
          <div className={bemClasses('right-label')}>
            <span className={bemClasses('right-label-span')}>
              {rightLabelText}
            </span>
          </div>
        ) : null}

        {info && (
          <div className={`form-text-muted ${bemClasses('info')}`}>{info}</div>
        )}

        {isError && (
          <div className={bemClasses('error')}>{context.t(meta.error)}</div>
        )}
      </div>
    </div>
  );
};

InnerInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelInInput: PropTypes.string,
  type: PropTypes.string,
  customClass: PropTypes.string,
  classModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  placeholder: PropTypes.string,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  autoFocus: PropTypes.string,
  rightLabelText: PropTypes.string,
  rows: PropTypes.number,
  dataTest: PropTypes.string,
  iconComponent: PropTypes.func,
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholderInInput: PropTypes.string,
  alwaysControlled: PropTypes.bool,
  inputRef: PropTypes.any
};

InnerInput.contextTypes = {
  t: PropTypes.func.isRequired
};

const APInput = props => (
  <Field
    name={props.name}
    component={InnerInput}
    defaultValue={
      props.alwaysControlled ? undefined : props.type === 'number' ? 0 : ''
    }
    {...props}
  />
);

APInput.defaultProps = {
  name: '',
  label: '',
  labelInInput: '',
  type: '',
  classModifiers: '',
  placeholder: '',
  info: '',
  autoFocus: '',
  disabled: false,
  required: false,
  rows: 1,
  alwaysControlled: false
};
APInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelInInput: PropTypes.string,
  type: PropTypes.string,
  customClass: PropTypes.string,
  classModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  placeholder: PropTypes.string,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  autoFocus: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  rightLabelText: PropTypes.string,
  rows: PropTypes.number,
  dataTest: PropTypes.string,
  placeholderInInput: PropTypes.string,
  alwaysControlled: PropTypes.bool
};

APInput.contextTypes = {
  t: PropTypes.func.isRequired
};

export default APInput;
