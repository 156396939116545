import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { APLabelWithText } from '../APLabelWithText/APLabelWithText';
import './SidePaneDetails.css';

const bemClasses = getBEMClasses('sidepane-details');

class SidePaneDetailsComponent extends React.Component {
  render() {
    const {
      title,
      items,
      info,
      infoHeader,
      classModifiers,
      link,
      isCrypto
    } = this.props;
    const isHtmlString = typeof info === 'string';

    return (
      <React.Fragment>
        {isCrypto && (
          <div className={bemClasses('extra-header')}>
            Once you send us cryptocurrency, it is automatically converted to
            USD and cannot be returned. You must use the USD equivalent on our
            platform.
          </div>
        )}
        <header className={bemClasses('header', classModifiers)}>
          {title}
        </header>
        <section className={bemClasses('body')}>
          {items &&
            items.map(
              ({ label, text, link = '', additionalInstructions = '' }) => (
                <APLabelWithText
                  key={label}
                  label={label}
                  text={text}
                  customClass={bemClasses()}
                  link={link}
                  additionalInstructions={additionalInstructions}
                />
              )
            )}
        </section>
        {items === undefined || items.length === 0 ? (
          <div className={bemClasses('divider')} />
        ) : null}
        <section className={bemClasses('information', classModifiers)}>
          {infoHeader && (
            <div className={bemClasses('information-header')}>{infoHeader}</div>
          )}
          {info &&
            (isHtmlString ? (
              <div dangerouslySetInnerHTML={{ __html: info }} />
            ) : (
              info.map((item, index) => (
                <div
                  className={bemClasses('information-text', classModifiers)}
                  key={index}>
                  {item}
                </div>
              ))
            ))}
          {link && <a href={link.url}> {this.context.t(link.text)} </a>}
        </section>
      </React.Fragment>
    );
  }
}

SidePaneDetailsComponent.defaultProps = {
  link: {
    text: '',
    url: ''
  }
};

SidePaneDetailsComponent.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  info: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  infoHeader: PropTypes.string,
  link: PropTypes.object,
  classModifiers: PropTypes.string,
  isCrypto: PropTypes.bool
};

SidePaneDetailsComponent.getItem = (label, text) => ({ label, text });

SidePaneDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SidePaneDetailsComponent;
