import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { USER_TIERS_ARRAY } from '../../../constants/userTiers';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import APIcon from '../../common/APIcon';

import '../styles/components/offering.css';

const classes = getBEMClasses('offering');

export const Top10Owners = (props, context) => {
  const {
    topOwners,
    isFetching,
    productShortName,
    assetDetailsTopOwnersBackgroundImage
  } = props;

  const topOwnersItems = useMemo(
    () =>
      topOwners.map((i, index) => (
        <div key={i.name} className={classes('top-owners-table-row')}>
          <div className={classes('top-owners-table-row-left-part')}>
            <div className={classes('top-owners-table-row-number')}>
              {index + 1}
            </div>
            <APIcon
              customClass={classes('top-owners-tier-badge')}
              name={USER_TIERS_ARRAY[i.userTier - 1].iconName}
            />
            <div className={classes('top-owners-table-row-name')}>{i.name}</div>
          </div>
          <div className={classes('top-owners-table-row-value')}>
            <div className={classes('top-owners-table-row-amount')}>
              {formatNumberToLocale(i.product_amount)}
            </div>
            <div className={classes('top-owners-table-row-product')}>
              {productShortName}
            </div>
          </div>
        </div>
      )),
    [topOwners]
  );

  const RenderTop10Owners = useCallback(
    () => {
      if (topOwners.length === 0 || isFetching) {
        return (
          <div className={classes('top-owners-table-placeholder')}>
            {isFetching ? (
              <Spinner customClass={classes('top-owners-spinner')} />
            ) : (
              context.t('There are no users owning this asset')
            )}
          </div>
        );
      }
      return (
        <div className={classes('top-owners-table')}>{topOwnersItems}</div>
      );
    },
    [isFetching, topOwnersItems]
  );

  return (
    <div className={classes('top-10-owners-block')}>
      <div className={classes('top-10-owners-header')}>
        <span className={classes('top-10-owners-header-text')}>
          Top 10 Owners
        </span>
        {!!assetDetailsTopOwnersBackgroundImage && (
          <img
            src={assetDetailsTopOwnersBackgroundImage}
            alt="asset"
            className={classes('top-10-owners-header-img')}
          />
        )}
      </div>
      {RenderTop10Owners()}
    </div>
  );
};

Top10Owners.contextTypes = {
  t: PropTypes.func.isRequired
};

Top10Owners.propTypes = {
  topOwners: PropTypes.array,
  isFetching: PropTypes.bool,
  productShortName: PropTypes.string,
  assetDetailsTopOwnersBackgroundImage: PropTypes.string
};
