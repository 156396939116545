import React from 'react';
import PropTypes from 'prop-types';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import QuidFailureAnimated from '../../../images/quid_failure_animated.gif';
import './SimpleOrderMessage.css';

const classes = getBEMClasses('simple-order-message');

const FailedOrder = (props, context) => {
  return (
    <div className={classes('container')}
      data-test='failureRoot'>
      <img className={classes('image')}
        src={QuidFailureAnimated}
        alt=''
      />
      {context.t(props.errorMessage)}
    </div>
  );
};

FailedOrder.contextTypes = {
  t: PropTypes.func.isRequired
};

FailedOrder.propTypes = {
  errorMessage: PropTypes.string.isRequired
};

export default FailedOrder;
