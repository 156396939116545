import React from 'react';

export const DiscordNavIcon = props => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14.0447 3.41719C13.0249 2.94927 11.9313 2.60453 10.7879 2.40708C10.7671 2.40327 10.7463 2.41279 10.7356 2.43184C10.5949 2.68198 10.4392 3.00831 10.3301 3.26481C9.10027 3.0807 7.87679 3.0807 6.67221 3.26481C6.5631 3.00261 6.40166 2.68198 6.26038 2.43184C6.24966 2.41343 6.22886 2.4039 6.20803 2.40708C5.06527 2.6039 3.97171 2.94864 2.95129 3.41719C2.94246 3.421 2.93488 3.42736 2.92986 3.4356C0.855594 6.53451 0.287367 9.55725 0.56612 12.5425C0.567381 12.5571 0.57558 12.5711 0.586932 12.58C1.95547 13.585 3.28114 14.1951 4.58219 14.5995C4.60301 14.6059 4.62507 14.5983 4.63832 14.5811C4.94608 14.1609 5.22043 13.7177 5.45565 13.2517C5.46953 13.2244 5.45628 13.192 5.42791 13.1812C4.99275 13.0161 4.5784 12.8149 4.17982 12.5863C4.14829 12.5679 4.14577 12.5228 4.17477 12.5012C4.25865 12.4384 4.34255 12.373 4.42264 12.307C4.43713 12.2949 4.45732 12.2923 4.47435 12.3C7.09286 13.4955 9.9277 13.4955 12.5153 12.3C12.5323 12.2917 12.5525 12.2943 12.5677 12.3063C12.6478 12.3723 12.7316 12.4384 12.8161 12.5012C12.8451 12.5228 12.8433 12.5679 12.8117 12.5863C12.4131 12.8193 11.9988 13.0161 11.563 13.1806C11.5346 13.1914 11.522 13.2244 11.5359 13.2517C11.7762 13.717 12.0505 14.1602 12.3526 14.5805C12.3652 14.5983 12.3879 14.6059 12.4087 14.5995C13.7161 14.1951 15.0417 13.585 16.4103 12.58C16.4223 12.5711 16.4298 12.5577 16.4311 12.5431C16.7647 9.09185 15.8723 6.0939 14.0655 3.43623C14.0611 3.42736 14.0535 3.421 14.0447 3.41719ZM5.84668 10.7248C5.05833 10.7248 4.40876 10.001 4.40876 9.11218C4.40876 8.22332 5.04574 7.49956 5.84668 7.49956C6.65392 7.49956 7.29721 8.22968 7.28459 9.11218C7.28459 10.001 6.64761 10.7248 5.84668 10.7248ZM11.1632 10.7248C10.3748 10.7248 9.72526 10.001 9.72526 9.11218C9.72526 8.22332 10.3622 7.49956 11.1632 7.49956C11.9704 7.49956 12.6137 8.22968 12.6011 9.11218C12.6011 10.001 11.9704 10.7248 11.1632 10.7248Z"
      />
    </svg>
  );
};
