import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { modalDecorator } from '../common/Modal/modalDecorator';
import { MODAL_TYPES, openModal } from '../../redux/actions/modalActions';

import './WireTransferResultModal.css';

import APIcon from '../common/APIcon';
import { Modal } from '../common/Modal/Modal';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

const classes = getBEMClasses('wire-transfer-result-modal');

const WireTransferResultModalComponent = (props, context) => {
  const { isOpen, close } = props;

  return (
    <Modal
      isOpen={isOpen}
      customClass={classes()}
      onCancel={() => {
        close();
      }}
      close={close}
      titleComponent={
        <div className={classes('header-wrapper')}>
          <div className={classes('header-content')}>
            <APIcon
              name={'iconModalSuccess'}
              customClass={classes('icon-modal-success')}
            />
            <div className={classes('header')}>
              {context.t('Information submitted')}
            </div>
          </div>
        </div>
      }>
      <div className={classes('body')}>
        {context.t('Your information is submitted!')}
        <br />
        <br />
        {context.t(
          'Please ensure you show the above wire instructions to your bank when processing wire deposits.'
        )}
      </div>
      <div className={classes('close-button')} onClick={close}>
        Exit
      </div>
    </Modal>
  );
};

WireTransferResultModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

WireTransferResultModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  openResultModal: result =>
    dispatch(openModal(MODAL_TYPES.WIRE_TRANSFER_RESULT_MODAL, { result }))
});

const WireTransferResultModalContainer = connect(
  null,
  mapDispatchToProps
)(WireTransferResultModalComponent);

export default modalDecorator({ name: MODAL_TYPES.WIRE_TRANSFER_RESULT_MODAL })(
  WireTransferResultModalContainer
);
