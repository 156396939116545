export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';

export const openModal = (name, modalProps) => ({
  type: OPEN_MODAL,
  payload: { name, modalProps }
});

export const closeModal = name => ({
  type: CLOSE_MODAL,
  payload: { name }
});

export const MODAL_TYPES = {
  DEFAULT_MODAL: 'DEFAULT_MODAL',
  RECEIVE_MODAL: 'RECEIVE_MODAL',
  SEND_MODAL: 'SEND_MODAL',
  SEARCH_CARDS: 'SEARCH_CARDS',
  BUY_SELL_MODAL: 'BUY_SELL_MODAL',
  KYC_MODAL: 'KYC_MODAL',
  KYC_STATUS_MODAL: 'KYC_STATUS_MODAL',
  DEPOSIT_WITHDRAW_STATUS_MODAL: 'DEPOSIT_WITHDRAW_STATUS_MODAL',
  SHARE_PROFILE_MODAL: 'SHARE_PROFILE_MODAL',
  BUY_OUT_MODAL: 'BUY_OUT_MODAL',
  BUY_OUT_RESULT_MODAL: 'BUY_OUT_RESULT_MODAL',
  BUY_OUT_CONFIRMATION_MODAL: 'BUY_SELL_CONFIRMATION_MODAL',
  SELECT_ASSET_MODAL: 'SELECT_ASSET_MODAL',
  SELECT_ASSET_FROM_WATCH_LIST_MODAL: 'SELECT_ASSET_FROM_WATCH_LIST_MODAL',
  WATCH_LIST_MODAL: 'WATCH_LIST_MODAL',
  WIRE_TRANSFER_RESULT_MODAL: 'WIRE_TRANSFER_RESULT_MODAL',
  VOTE_MODAL: 'VOTE_MODAL',
  CONFIRM_VOTE_MODAL: 'CONFIRM_VOTE_MODAL',
  AFFILIATE_MODAL: 'AFFILIATE_MODAL',
  NOTIFICATION_MODAL: 'NOTIFICATION_MODAL',
  ALL_NOTIFICATIONS_MODAL: 'ALL_NOTIFICATIONS_MODAL',
  UPDATE_TERMS_OF_USE_MODAL: 'UPDATE_TERMS_OF_USE_MODAL'
};
