import React, { useCallback } from "react";
import PropTypes from 'prop-types';
import { Modal } from "../common/Modal/Modal";
import './ConfirmRemoveFromWatchListModal.css';
import { getBEMClasses } from "../../helpers/cssClassesHelper";

const classes = getBEMClasses('confirm-remove-from-watchlist-modal');

const ConfirmRemoveFromWatchlistModalComponent = (props, context) => {
  const {
    isOpen,
    close
  } = props;
  const onConfirm = useCallback(
    () => {
      props.onConfirm();
      close();
    },
    [props.onConfirm, close]
  );
  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title={context.t('Remove from Watchlist')}
      customClass={classes()}
      showCloseIcon={false}>
      <React.Fragment>
        <div className={classes('text')}>
          {context.t('Please confirm you want to remove this asset from your watchlist.')}
        </div>
        <div className={classes('buttons-container')}>
          <button
            className={classes('btn', 'cancel')}
            onClick={close}>
            {context.t('Cancel')}
          </button>
          <button
            className={classes('btn', 'confirm')}
            onClick={onConfirm}>
            {context.t('Confirm')}
          </button>
        </div>
      </React.Fragment>
    </Modal>
  );
};

ConfirmRemoveFromWatchlistModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

ConfirmRemoveFromWatchlistModalComponent.contextTypes = {
  t: PropTypes.func
};

export default ConfirmRemoveFromWatchlistModalComponent;
