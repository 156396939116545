import { AUTH_SUCCESS } from 'apex-web/lib/redux/actions/authActions';
import { SET_ACCOUNT } from 'apex-web/lib/redux/actions/userActions';
import { getLedgerEntries } from '../actions/ledgerActions';
import { getProductAttributes } from '../actions/productActions';
import { getVoteCards } from '../actions/voteActions';

const appLoadMiddleware = store => next => action => {
  const { dispatch, getState } = store;
  const {
    apexCore: {
      product: { products }
    }
  } = getState();

  if (action.type === AUTH_SUCCESS) {
    dispatch(getProductAttributes(products));
    dispatch(getVoteCards());
  }

  if (action.type === '@@router/LOCATION_CHANGE') {
    window.scrollTo(0, 0);
  }

  if (action.type === SET_ACCOUNT) {
    dispatch(getLedgerEntries({ accountId: action.payload }));
  }

  return next(action);
};

export default appLoadMiddleware;
