import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import path from 'apex-web/lib/helpers/path';
import './StandAloneLayout.css';

import { getBEMClasses } from '../../helpers/cssClassesHelper';
import APIcon from '../../components/common/APIcon/APIcon';
import { APTypography } from '../../components/common/APTypography';
import { ArrowWithLineIcon } from '../../images/react-icons/ArrowWithLineIcon';
import wordMark from './header-wordmark.png';
import bgImage from './standalone-back.png';
import LoganImage from './logan-image.png';
import LoganMobileImage from './logan-mobile-image.png';
import { useIsTablet } from '../../hooks/useIsMobile';

// using non-apex class name as a workaround for issues caused by apex css
// classes overriding our css classes with the same name
const layoutClasses = getBEMClasses('standalone-layout-custom');

const StandAloneLayout = props => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const isTabet = useIsTablet();

  return (
    <div className={layoutClasses('wrapper')}>
      <img
        className={layoutClasses('bg-image')}
        src={bgImage}
        alt="liquid background"
      />
      <div className={layoutClasses('header')}>
        <a
          href="https://www.liquidmarketplace.io/"
          className={layoutClasses('go-back-container')}>
          <ArrowWithLineIcon className={layoutClasses('go-back-icon')} />
          GO BACK
        </a>
        <div
          className={layoutClasses('hamburger')}
          onClick={() => setShowMenu(!showMenu)}>
          <APIcon
            name="menu-toggle"
            customClass={layoutClasses('social-icon')}
          />
        </div>
        {showMenu && (
          <div className={layoutClasses('hamburger-menu')}>
            <a
              className={layoutClasses('hamburger-link')}
              href="https://www.liquidmarketplace.io/">
              <APTypography
                fontSize="caption2"
                color="white4"
                weight="weight600">
                Home
              </APTypography>
            </a>
            <a
              className={layoutClasses('hamburger-link')}
              href="https://www.liquidmarketplace.io/#comp-ktex7qfs">
              <APTypography
                fontSize="caption2"
                color="white4"
                weight="weight600">
                Who We Are
              </APTypography>
            </a>
            <a
              className={layoutClasses('hamburger-link')}
              href=" https://www.liquidmarketplace.io/#comp-ktex7qim">
              <APTypography
                fontSize="caption2"
                color="white4"
                weight="weight600">
                Our Process
              </APTypography>
            </a>
            <a
              className={layoutClasses('hamburger-link')}
              href="https://www.liquidmarketplace.io/#comp-ktex7qjv3">
              <APTypography
                fontSize="caption2"
                color="white4"
                weight="weight600">
                Press
              </APTypography>
            </a>
            <Link
              to={path('login')}
              className={layoutClasses('hamburger-link')}>
              <APTypography
                fontSize="caption2"
                color="white4"
                weight="weight600">
                LOGIN
              </APTypography>
            </Link>
            <Link
              to={path('signup')}
              className={layoutClasses('hamburger-sign-up')}>
              <APTypography
                fontSize="caption2"
                color="white4"
                weight="weight600">
                Sign Up
              </APTypography>
            </Link>
          </div>
        )}
        <img
          src={wordMark}
          className={layoutClasses('wordmark-image')}
          alt="liquid marketplace"
        />
        {location.pathname === '/signup' ? (
          <Link to={path('login')} className={layoutClasses('login')}>
            Log In
          </Link>
        ) : (
          <Link to={path('signup')} className={layoutClasses('sign-up')}>
            Sign Up
          </Link>
        )}
      </div>
      <div className={layoutClasses('content', location.pathname === '/signup' && isTabet && 'with-banner')}>
        {location.pathname === '/signup' ? (
          <div className={layoutClasses('logan-wrapper')}>
            <img
              src={isTabet ? LoganMobileImage : LoganImage}
              alt="Logan"
              className={layoutClasses('logan-img')}
            />
          </div>
        ) : null}
        {props.children}
      </div>
      <div className={layoutClasses('footer')}>
        <div className={layoutClasses('links-wrapper')}>
          <a
            className={layoutClasses('link')}
            href="https://www.liquidmarketplace.io/about-us">
            About
          </a>
          <a
            className={layoutClasses('link')}
            href="https://www.liquidmarketplace.io/terms-of-use">
            Term of Use
          </a>
          <a
            className={layoutClasses('link')}
            href="https://www.liquidmarketplace.io/privacy-policy">
            Privacy Policy
          </a>
          <a
            className={layoutClasses('link')}
            href="https://www.liquidmarketplace.io/fee-schedule">
            Fee Schedule
          </a>
          <a
            className={layoutClasses('link')}
            href="https://support.liquidmarketplace.io/hc/en-us">
            Support
          </a>
        </div>
        <div className={layoutClasses('footer-email')}>
          <APTypography fontSize="small2" color="white4">
            {`© Liquid Marketplace Inc. ${new Date().getFullYear()}. All Rights Reserved.`}
          </APTypography>
        </div>
      </div>
    </div>
  );
};

export const inStandAloneLayout = Component => {
  const wrapped = props => (
    <StandAloneLayout>
      <Component {...props} />
    </StandAloneLayout>
  );
  wrapped.displayName = `InStandAloneLayout(${Component.displayName ||
    Component.name ||
    'Component'})`;
  return wrapped;
};

StandAloneLayout.propTypes = {
  children: PropTypes.node
};

export default StandAloneLayout;
