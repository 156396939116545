import React from 'react';

export const LinkedinNavIcon = props => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M14.1328 14.133H11.7621V10.4203C11.7621 9.53499 11.7463 8.3953 10.529 8.3953C9.29431 8.3953 9.10539 9.3599 9.10539 10.3559V14.1328H6.73469V6.49796H9.01056V7.54134H9.04242C9.50648 6.74786 10.3694 6.27396 11.2879 6.30805C13.6907 6.30805 14.1337 7.88855 14.1337 9.94469L14.1328 14.133ZM4.05975 5.45434C3.29995 5.45447 2.6839 4.83863 2.68376 4.07881C2.68362 3.31898 3.29946 2.70292 4.05926 2.70278C4.81906 2.70265 5.43512 3.31849 5.43525 4.07831C5.43532 4.44319 5.29044 4.79315 5.03248 5.0512C4.77453 5.30926 4.42462 5.45427 4.05975 5.45434ZM5.2451 14.133H2.87193V6.49796H5.2451V14.133ZM15.3147 0.501193H1.68066C1.03629 0.493921 0.507864 1.01009 0.5 1.65446V15.3453C0.507595 15.99 1.03598 16.5067 1.68066 16.4999H15.3147C15.9606 16.5079 16.4911 15.9913 16.5 15.3453V1.65348C16.4908 1.00783 15.9603 0.49169 15.3147 0.500101" />
    </svg>
  );
};
