export const ASSETS = [
  {
    id: 7,
    name: 'PikachuIllustrator',
    title:
      'If there ever were a holy grail of trading card games, the Pikachu Illustrator would be it. With a rich history and beautiful artwork, this is the most sought-after Pokemon card.',
    description: [
      'The Illustrator is one of the most historically important cards in the entire Pokemon Trading Card Game franchise, featuring artwork by Atsuko Nishida. Nishida is known for having designed Pikachu, Squirtle, Bulbasaur, Charmander, Charizard, and many other famous characters, and is one of the most influential Pokemon artists. This copy received a PSA 8 grade, with only 9 higher examples currently graded. The Illustrator does not sell often, but when it does it is often record-setting.',
      'More sought after than any Charizard or Lugia card out there, the Illustrator is the only card to feature the title of “Illustrator” and its text certifies that the winners of the cards are official Pokemon TCG artists. The Pokemon phenomenon can trace its roots back to November 8th, 1995, where Tsunekazu Ishihara, Koichi Ooyamam, and Takumi Akabane began the initial development of Pokemon cards under the newly-founded Creatures Inc. After a fateful meeting with CoroCoro, a Japanese pop-culture magazine, the Pokemon Trading Card Game began, using CoroCoro as their main advertising platform. CoroCoro hosted three design competitions in Japan during 1997 and 1998, where winners would receive a copy of the Illustrator promo card. Only 39 cards were distributed this way, with a former Pokemon designer selling two more - this brings the total known quantity of this card to 41 copies.',
      'The first Illustration contest was held from October to January of 1997-1998 in Japan, where a total of 23 Illustrator cards were distributed - 3 to the Grand Prize winners, and 20 to the Special Prize winners. The Grand Prize winners were also gifted 20 copies of their submitted illustration; these cards were later released to the public in Japan’s Vending Series 1.',
      'The next contest was held from April to June of 1998, where contestants were asked to not only draw a Pokemon but also come up with attacks for the card. This contest had 8 winners, one Grand Prize winner, 4 Illustration Special Prize winners, and 3 Attack Special Prize winners. The Grand Prize winner’s design was released as part of Vending Series 3.',
      'The final contest was held from May to July of 1998, and had 8 winners: 4 for Illustrations, and 4 for Attacks, bringing the total number of released cards to 39. There was no Grand Prize winner for this contest due to the magazine’s inability to contact the winner - imagine being that person who forgot to provide contact info! Over 20 years later, two additional copies were sold by a former Pokemon designer. It is rumored that these two copies were meant to be given to the Grand Prize winner for the third contest but due to the contact issue, they were kept by the designer instead.'
    ],
    gradientColors: ['#84141a', '#dd477f'],
    numberColor: '#6409B4',
    minimalBuyOutPrice: 1283040
  },
  {
    id: 14,
    name: 'UpperDeckExquisiteLebronJames',
    title:
      'The most desirable rookie card of basketball’s greatest modern player hails from one of the most important sports card collections ever produced. This is Lebron James’ /99 Exquisite Rookie Patch Autograph.',
    description: [
      'It is impossible to talk about the legacy of basketball without mentioning the name LeBron James. King James is perhaps the greatest basketball player of all time, in contention only with Michael Jordan, and rightly so: James has been the face of the NBA for over a decade. As the only player to win the NBA Finals MVP award with three different teams, James’ legacy on the court is rivaled only by his impact off the court. James has founded multiple charities to support kids in need of a stable education, providing tuition, proper meals, and career support services.',
      'The LeBron James /99 Rookie Patch Autograph from Upper Deck’s 2003/04 Exquisite NBA collection is one of the most important cards in the trade. James’ legacy has cemented the 03/04 Exquisite collection as one of the greatest sets in all of sports, and its price tag shows it: originally retailing at 500 USD per box, a November 2021 sale of three sealed boxes went for 970,000 USD.'
    ],
    gradientColors: ['#280302', '#b21c11'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 2799360
  },
  {
    id: 11,
    name: 'NationalTreasuresGiannis',
    title:
      'At only 27 years old, “Greek Freak” Giannis Antetokounmpo has an impressive resume: 2-time MVP, Finals MVP, DPOY, 6-time All-Star, 5-time All-NBA selections - the list goes on.',
    description: [
      `There is no question that Giannis Antetokounmpo is one of the NBA’s biggest forces. But while we all know that the 2-time MVP winner is one of the most talented players to ever grace the courts, it was not always so clear-cut. Antetokounmpo didn’t start playing serious basketball until he was 15 years old - merely 3 years later he was drafted 15th overall by the Milwaukee Bucks. Drafting this young international prospect was assuredly a risk, but 9 years later Giannis has proven that he was beyond worth it.
        In addition to his two MVP awards, Giannis is in company with the greatest players the NBA has ever seen: Giannis is the third player ever to win an MVP award before the age of 26, the others being LeBron James and Kareem Abdul Jabbar. He is also one of only three to win both MVP and DPOY in the same year - this record is held by Hakeem Olajuwon and Michael Jordan.`,
      'This rookie card features an authentic event-worn tri-color jersey patch, an on-card autograph, and is numbered to only 99. The card was one of the biggest hits from the 2013/14 Panini National Treasures basketball set, Panini’s luxury set: each box retailed for over 500 USD. While many balked at that price initially, any collector would wish to go back in time to purchase one at that price: the most recent sale price for a set of three 2013/2014 National Treasures basketball boxes was 19,800 USD.'
    ],
    gradientColors: ['#0a0709', '#413636'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 641520
  },
  {
    id: 20,
    name: 'NationalTreasuresKobeBryantLogomanAuto',
    title:
      'Not only did Kobe Bryant represent the NBA, his attitude resonated with people from all walks of life. From the moment he stepped foot on the court until the day he retired, Kobe Bryant aimed for one goal: to win.',
    description: [
      'Kobe’s wildly successful career, with 5 NBA Championship Titles, is countered only by his generational effect off of the court. Today the word “Kobe” is synonymous with his incredible jump shot, a symbol of victory and perseverance.',
      'Here we have one of the most incredible Kobe Bryant cards in existence: the /2 Logoman from 2013/14 Panini National Treasures. Kobe Logoman Autos are extremely difficult to find, and this one is no exception: although the initial pack price for 2013/14 National Treasures was only 500 USD, a chance of pulling this card’s counterpart would set you back at least 6500 USD now. The last sale price for a set of three 2013/14 National Treasures boxes was nearly 20,000 USD in the spring of 2020.'
    ],
    gradientColors: ['#0d0622', '#6942b0'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 0
  },
  {
    id: 16,
    name: 'NationalTreasuresStephenCurry',
    title:
      'The only unanimously-voted MVP in NBA history, Stephen Curry is widely regarded as basketball’s greatest shooter.',
    description: [
      'Stephen “Chef” Curry is one of the most important players of the last decade. The all-time leader in three-points made, Curry’s style and charisma on and off the court continues to inspire generations of viewers with each record-setting game. His uncanny ability to make clutch three-points changed the way coaches and players viewed the sport: called the “Curry effect,” three-points attempted is now one of the most important statistics in basketball. Curry could retire today and be cemented in history as the greatest three-point shooter in basketball history, but the Chef is still going strong: with three championships under his belt, and almost inhuman records, Curry may be the most impactful player in recent history.',
      'This card comes from 2009/10 Panini National Treasures Basketball and is one of the most sought-after pulls from the set. The boxes originally retailed at 500 USD, but the most recent sale was in June of 2021 for over 10,000 USD. As Curry continues to shatter expectations, one can expect this set to continue rising in value, as more people search for these elusive /99 rookie patch autographs.'
    ],
    gradientColors: ['#330c03', '#d36617'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 874800
  },
  {
    id: 13,
    name: 'PokemonBox',
    title: `"In the history of the toy industry, there has never been a hit so global, so multimedia, so rapid, so long-lasting as Pokemon." - Sean McGowan, Toy Industry Analyst, 1999.`,
    description: [
      'The Pokemon Trading Card game is a one-of-a-kind phenomenon. From its birth on November 8th, 1995, to the present day, where over 34.1 billion cards have been printed, Pokemon has ingrained itself in the social fabric of the world like nothing else before. While record-breaking sales of ultra-rare Pokemon items are hardly surprising in today’s media, Pokemon was a hit from the beginning. On November 8th of 1999, two days before the US release of Pokemon: The First Movie, a Los Angeles radio station ran a call-in contest to win tickets to the premiere. They were immediately swamped with more than 70,000 phone calls per minute. This was no surprise to fans of the newly released Pokemon Trading Card Game: Wizards of the Coast, the producers of the first English Pokemon cards, had to print more than 10 times the expected amount of Pokemon Base Set cards and even canceled print runs of other card games to meet the demand for Pokemon. Even so, the Pokemon 1st Edition Base Set was completely sold out across the country within weeks.',
      'Twenty-five years later, Pokemon is the highest-grossing media franchise of all time, worth nearly 10 billion USD more than Harry Potter, the Marvel Cinematic Universe, and Spiderman - combined. Pokemon is more than just a game: it is a historical achievement and a fundamental part of millions of childhoods. The emotional significance of Pokemon is palpable whenever you step foot into one of the thousands of Pokemon Organized Play events that take place all across the world. You can feel the love for the game whenever you hear a kid (or adult, for that matter) talk about their favorite cards that they saved up to buy. As Pokemon cards continuously set sales records, it is important to remember the game’s beginnings: the 1st Edition Base Set.',
      '1st Edition Base Set is the most collectible Pokemon set, featuring rookie cards of some of the most popular characters, including Charizard, Pikachu, Mewtwo, and many more. Values aside, the nostalgia that comes from just seeing one of the beautiful 1st Edition Base Set sealed boxes is one of the best feelings a collector can have. While the holographic and rare cards from this set are extremely sought after, even the common cards are worth a significant amount in gem mint condition. This extraordinary item definitively concludes that Pokemon is more than just a fad or game for kids: it is a part of history.'
    ],
    gradientColors: ['#0f0f2b', '#5855ae'],
    numberColor: '#6409B4',
    minimalBuyOutPrice: 408240,
    coOwn: true
  },
  {
    id: 17,
    name: 'SkyboxEX200LebronJames',
    title:
      'LeBron James is the most dominant player of his generation. The 18x All-Star has defied all expectations, and has been called the greatest player of all time.',
    description: [
      'LeBron James is truly deserving of his title as King. The third all-time points leader, James has won 4 NBA championships, 4 MVP awards, 4 Finals MVP awards, and two Olympic gold medals, while also having scored the most playoff points. Ever. At age 37, the fifth oldest player in the league, James continues to be a major force: in the 2021/22 season James is averaging more points per game, more rebounds per game, and a better overall field goal percentage, than his career averages. He continues to perform at an unprecedented level and shows no signs of slowing.',
      'The LeBron James Essentials Now Rookie from 2003/04 Fleer E-X is one of the King’s most sought after rookie cards. Initially retailing at 100 USD per box, upon release the James card sold for around 80 USD. In January 2022, a sealed box of 03/04 E-X sold for over 6240 USD, and the James card can now sell for up to 200,000 USD.'
    ],
    gradientColors: ['#0e1c28', '#598caf'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 163296
  },
  {
    id: 18,
    name: 'UpperDeckExquisiteDwayneWade',
    title:
      'Dwyane Wade brought his Miami Heat to its first and only three NBA championships, becoming the most decorated player in franchise history.',
    description: [
      'Dwyane Wade was an impactful player from the very beginning - the fifth overall pick in the 2003 draft, Dwyane was a centerpiece of the Miami Heat for more than a decade, bringing 3 NBA Championships to the team, including the franchise’s first in 2006. The 2006 finals was one to remember, as Dwyane’s Heat found themselves two games behind the Dallas Mavericks. Dwyane then ran hot, averaging 39 points throughout the next four games, taking the series 4-2 and securing himself the NBA Finals MVP title.',
      'The 2003/04 Upper Deck Exquisite collection is now heralded as one of the most significant modern sports card sets ever released, and Dwyane Wade’s /99 rookie autograph patch is one of the most coveted cards in the set. This PSA 10 copy is one of only two in its condition, with zero BGS 10s in existence. The 2003/04 Upper Deck Exquisite collection was originally priced at 500 USD per box. However, the most recent sale for a set of three sealed boxes was over 970,000 USD in November of 2021. The price of this set exemplifies the rarity and importance of the cards inside it, and the Dwyane Wade /99 Rookie is one of the chases.'
    ],
    gradientColors: ['#360b0c', '#c6414a'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 0
  },
  {
    id: 19,
    name: 'UpperDeckExquisiteKevinDurant',
    title:
      'One of the most prolific scorers in NBA history, Kevin Durant is a menace on the court. The 7-footer is often called the greatest active NBA player and has set many incredible records.',
    description: [
      'When Kevin Durant gets going, he is unstoppable. From his start as the 2nd overall pick in the 2007 draft to the now-defunct Seattle SuperSonics, Durant has made history in the league year after year: he became the second-youngest player in NBA history to reach 20,000 career points, behind only LeBron James. His impact was especially potent in the 2016/17 and 2017/18 NBA seasons, where Durant brought the Golden State Warriors to two consecutive championships against LeBron James’ Cavaliers, with Durant himself winning back-to-back NBA Finals MVP awards.',
      'Durant’s Upper Deck Exquisite rookie card is one of the most infamous cards in the hobby, with many examples featuring fake patches. This card, numbered 64/99, is one of the few legitimate copies to feature a tri-color patch, resulting in the actual rarity of this card being far less than 1 of 99. The card was a major hit out of the 2007/08 Exquisite set that retailed for 600 USD. Although there has not been a public sale of 2007/08 Exquisite for many years, it is estimated that the price of a set of three boxes is now above 30,000 USD.'
    ],
    gradientColors: ['#091133', '#3a67c3'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 0
  },
  {
    id: 12,
    name: 'UpperDeckExquisiteMichaelJordanAutoPatch',
    title:
      'Great players come and go, but Michael Jordan will always remain due to one simple fact: Michael Jordan is basketball.',
    description: [
      'As early as his rookie season some analysts believed that 1987’s third overall pick was already the best shooter in the league. Jordan continued to defy any conceivable expectations, and over a decade after his retirement, he still holds the record for the highest average points per game. Some of his achievements include winning the MVP title, Defensive Player of the Year title, and the Scoring Title, all during the same year. No other player has been as dominant on both ends of the court as Jordan was, and perhaps no player will ever be.',
      'There aren’t very many Jordan 1/1s, and this is one of the most significant versions: this card comes from the last Exquisite collection produced by Upper Deck. The set originally retailed at 825 USD per box and in January of 2021 a set of three boxes sold for 40,590 USD.'
    ],
    gradientColors: ['#080608', '#372f37'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 2099520
  },
  {
    id: 15,
    name: 'CryptoPank',
    title:
      '“Owning a CryptoPunk [signifies] just how early of an adopter you were into the world of Ethereum and its thriving digital art scene” - Jason Abbruzzese, Mashable, June 2017 - one week after CryptoPunks were created.',
    description: [
      'CryptoPunks aren’t just 576 pixels housed in a digital contract: they are an art movement with historical provenance. Credited as the inspiration to the software behind modern NFTs, the ERC-721 token, CryptoPunks are one of the earliest examples of digital art on the blockchain.',
      'Created in 2017 by two Canadian software developers, CryptoPunks are a collection of 10,000 punk rock characters living on the blockchain. Taking inspiration from the punk-rock movement of the 1970s, CryptoPunks were conceptualized during the heydays of anti-establishment rhetoric in the blockchain. The creators wanted to pose a brilliant and unique question: what is ownership in a digital age?',
      'As it turns out, ownership is more than holding tangible items. Akin to owning a piece of art that is held and displayed by a museum, owning a CryptoPunk means having the first major installment of the CryptoArt movement - a rapidly growing and exciting industry. CryptoPunks are brilliant in their simplicity and nuance, and will always exist to represent the early days of blockchain and the ingenuity of the individuals that made it what it is today. This era will be remembered as the blockchain revolution - and CryptoPunks are the face of it.',
      'This CryptoPunk is number 6837 and has three major traits: earring, cap, and eye mask. The latter two traits are extremely rare: only 4% and 3% of all CryptoPunks have a cap or an eye mask, respectively.'
    ],
    gradientColors: ['#2c586e', '#2c586e'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 291600
  },
  {
    id: 21,
    name: 'CharizardHolo',
    title:
      'You don’t have to be a fan of Pokemon to have heard the name “Charizard”. This iconic fire-breathing lizard has inspired millions of people worldwide to “catch ‘em all,” making Pokemon the most successful media franchise of all time.',
    description: [
      `When the Pokemon anime was first released, one character stood out above the rest: Charizard. The fiery lizard was main-character Ash’s strongest partner, and his relentless attacks awed children on a global scale. When the Pokemon Trading Card Game was released, first in Japan and shortly after worldwide, millions flocked to their local game stores to buy packs in hopes of getting a beautiful Charizard card for their own collections. Over 25 years later, little has changed: a quick search online will show innumerable results of kids and adults opening packs of Pokemon to hunt those Charizards. The Base Set Charizard is more than just an expensive card: this Pokemon was a part of millions of childhoods. For Pokemon collectors, owning one of these cards, with its historic artwork and fiery holofoil, brings you back to the first time you ever pulled a Charizard from a pack, and the amazing feeling that followed.`,
      'The first set of Pokemon Trading Cards was released in English on January 9th, 1999. It was an immediate success, with initial print runs selling out across the United States almost overnight. Pokemon Base Set remains the most popular set, with collectors vying for the rarest cards in the best condition. This Charizard is from Base Set Unlimited, the more plentiful of the three primary editions of Base Set. However, despite having nearly 35,000 graded copies at PSA, there are only 449 copies that have graded a Gem Mint 10: a 1.36% gem rate. This Charizard combines rarity and fame better than almost any other Pokemon card.'
    ],
    gradientColors: ['#5f3d17', '#ab8445'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 17496
  },
  {
    id: 22,
    name: 'AustonMatthews',
    title:
      'Few draft picks have had as much of an impact as Auston Matthews: scoring 4 goals in his first-ever NHL game, the offensive star has defied expectations, and is on track to become the greatest ever American hockey player.',
    description: [
      `After an electrifying start to his career, Auston Matthews kept up the momentum by scoring 40 goals and finishing second in the NHL for goals scored, winning the NHL’s rookie of the year award in the process. Five years later in 2022, Auston Matthews scored his 51st goal in 50 games - the first player to accomplish this feat since Mario Lemieux in 1995/96. Matthews is also the only American player to ever win the Maurice "Rocket" Richard Trophy, awarded to the top scorer of the season - and he’s on track to do it again this year.`,
      'This card hails from Upper Deck’s Series 1 Hockey, the company’s mainstream hockey collection. Series 1 is famous for its “Young Guns” rookie cards, a special insert that features rookie prospects. Young Guns can be found as base cards, as well as “Clear Cut” cards such as this one. The Clear Cut Young Guns are exceptionally rare and difficult to grade - it is estimated that the odds of pulling any Clear Cut Young Guns card are 1/288. With 50 different Young Guns in the 2016/17 set, the odds of finding this card in a pack are less than 0.007%.'
    ],
    gradientColors: ['#151960', '#424cad'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 17496
  },
  {
    id: 23,
    name: 'CharizardReverseFoil',
    title:
      'The Legendary Collection marked the third time Mistuhiro Arita’s Charizard was printed in the TCG. However, the unique fireworks holofoil, never to be used again in the TCG, quickly placed this Charizard above its peers.',
    description: [
      `Charizard’s artwork from Base Set is the most famous artwork in Pokemon, and perhaps in all trading card games. It is no surprise then that by 2002 it had already been printed three times: first in Base Set, next in Base Set 2, and then again in Legendary Collection. Despite how many sets have contained this artwork, the extraordinary artwork’s draw has kept collectors buying these cards. The Reverse Holo Charizard from Legendary Collection is in the top five most expensive cards to feature this artwork, and has one of the lowest GEM MINT 10 populations, at 79 total.`,
      'When this set was first released, it actually was not viewed favorably. Collectors felt that the fireworks holo was extravagant and unnecessary, and Legendary Collection did not sell very well. However, modern collectors love and appreciate Pokemon’s first type of reverse holos, and Legendary Collection is now one of the most expensive sets. Now, booster boxes containing 36 sealed packs of Legendary Collection can sell for over $30,000 USD, and the fireworks holo cards are some of the most valuable in the TCG - even common cards can sell for hundreds of dollars in Mint or Gem Mint condition!'
    ],
    gradientColors: ['#7b2117', '#bb4e40'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 15163
  },
  {
    id: 24,
    name: 'CharizardExpedition',
    title:
      'The Expedition Charizard features a gorgeous depiction of the Pokemon soaring through the air above a lake. This unique artwork was drawn by Hiromichi Sugiyama, the designer behind many Pokemon Trading Figure Game models.',
    description: [
      `Charizard’s abundance of cards definitively proves that it is the King of Pokemon. However, this is no normal Charizard card: part of the first e-Card expansion, this card features a huge swathe of easily-scratched holofoil. To grade this card in a GEM MINT 10 is exceptionally difficult: in more than 20 years there has only been 37 graded 10s. Charizard is the ultimate figurehead of Pokemon, and has been since the original video games where Charizard’s raw power and fiery attacks made an immediate fan-favorite. Decades later, Charizard is just as popular as it was before and collectors are always looking to add beautiful copies such as this one to their collections.`,
      'Expedition was the first of Pokemon’s e-Series sets, a group of three expansions that marked the end of Wizards of the Coast’s control over the Pokemon TCG. After Expedition, Aquapolis, and Skyridge, the license to the TCG switched over to The Pokemon Company International. Due to the expiring license and many production issues faced by Wizards, Expedition is one of the lowest printed sets in all of Pokemon. This makes it an incredibly difficult set to find sealed, and a sealed box of 36 packs can go for tens of thousands of dollars.'
    ],
    gradientColors: ['#602618', '#9d513b'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 23328
  },
  {
    id: 25,
    name: 'Topps Chrome Lewis',
    title:
      'Sir Lewis Hamilton is more than just the most accomplished F1 Driver in history: the Englishman is a civil rights activist, an environmentalist, a musician, a restauranteur, and an ambassador of global positivity.',
    description: [
      `One of Time Magazine’s 100 most influential people of 2020, Sir Lewis Hamilton is credited with helping Formula 1 expand to a global audience. Hamilton was an outspoken supporter of the Black Lives Matter movement and his efforts to dispel racism within Formula 1 have been crucial in facilitating change within the Formula 1 industry. He is also an avid environmentalist, and his international vegan restaurant chain Neat Burger was named PETA’s inaugural company of the year.`,
      'On the track, Hamilton is the most exceptionally talented racer to ever participate in F1. After tying the record for World Driver’s Championship titles with 7 in 2020, Hamilton has continued to impress, and currently holds the records for the most Grand Prix wins, pole positions, and podium finishes.',
      'This card is from the second year of Topps Chrome F1, a limited-release collectible card series that has in recent years become extremely popular. This card is a Red Liners insert, and features a special design that differs from Hamilton’s base card. This insert is also the Superfractor variant, meaning it is the only copy to feature a beautiful gold foil background and a distinctive 1/1 serial. This is one of the rarest and most desirable Lewis Hamilton trading cards in the world, and as F1 continues its global expansion Hamilton is expected to draw more fans to his races and collectibles.'
    ],
    gradientColors: ['#9e9a74', '#cbc8a8'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 34800,
    coOwn: true
  },
  {
    id: 30,
    name: 'Mutant Ape',
    title:
      'After drinking a special serum, Bored Ape NFTs were transformed into Mutant Apes, a series of 20,000 mutated monkeys that have propelled BAYC creators Yuga Labs to metaverse stardom.',
    description: [
      `The Bored Ape Yacht Club is the world’s biggest NFT project for one single reason: they’re more than just a bunch of monkeys. The BAYC project was created with the purpose of making a hangout spot for individuals who “aped” into cryptocurrency early. The project’s story states that in the year 2031 cryptocurrencies have taken over, and the Yacht Club is the perfect place for apes to congregate, swapping stories and sharing experiences. This idea quickly rose to fame, and the NFTs went from their starting price of a few hundred dollars to more than hundreds of thousands of dollars within months.`,
      'After the explosion in price resulted in many individuals missing out on being able to afford these monkeys, Yuga Labs released a new project: the Mutant Apes. Mutant Apes look similar to Bored Apes, but have mutated appearances, new traits, and different tiers of rarity. There were three possible mutations, called M1, M2, and M3. M1 is the most common, covering 75% of Mutant Apes, M2 is the second most common covering almost 25% of Apes, and M3 is the rarest with only 13 “Mega” Mutant Apes possible. However, it wasn’t long before Mutant Apes were similarly expensive, with M1s selling in the high 5 to 6 figures, and M3 Apes selling in the millions. Many people believe that the price of these Apes is unfounded and that the Apes carry no utility other than a unique profile picture. However, the Ape saga is so much more: in 2022 April, Yuga Labs launched The Otherside.',
      'The Otherside is, in the words of Yuga Labs, “the interoperable gaming metaverse.” Yuga Labs believes that projects creating individual metaverses are actually forming extremely limited and niche collectives that don’t embody the original beliefs of a metaverse, that all of humanity can participate in. With The Otherside, Yuga opens up the metaverse to ANY NFT, and plans to create a seamlessly integrated process for all NFT projects to act as avatars for individuals playing together. While it is a tall task, if any company can complete this dream it would be Yuga Labs: after raising $450 million on a $4 billion valuation, the company is ready to be taken seriously, and will do more than just monkey business. If NFTs and the metaverse are the future, then the Apes will be the face of that future.'
    ],
    gradientColors: ['#602618', '#9d513b'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 23328
  },
  {
    id: 29,
    name: 'Otherdeed for Otherland',
    title:
      'Otherdeeds are virtual deeds for plots of land in Yuga Lab’s Bored Ape Yacht Club metaverse. Each one comes with a series of traits and characteristics that differentiate it from the rest of the metaverse.',
    description: [
      `Yuga Labs’ latest drop was 100,000 NFTs called “Otherdeeds,” each of which represented a virtual deed for a plot of land in the upcoming BAYC metaverse. This metaverse, called the Otherside, is described as an "M-MMORPG", or a Metaverse Massively Multiplayer Online Role-Playing Game. Little is known about what these lands can actually be used for, but many users can draw hints from Yuga Lab’s recent YouTube video documenting the inception of the Otherside.`,
      'After an Ape drinks a blue potion while fishing, his world suddenly breaks apart in front of him. As volcanos and tundras erupt to life around him, a strange flying creature comes and whisks the Ape away. We soon see that the Ape has entered a new realm, and his flying companion shows him the amazing sights of this new world.',
      'The Ape then encounters a flying vehicle that has other major NFT avatars in it, including Crypto Punks, Meebits, Mutant Apes, World of Women, and more. We see that all of these projects can engage with each other in the Otherside metaverse.',
      'As secondary market sales for Otherdeeds top the charts, the world of NFTs eagerly awaits new information from Yuga Labs about what we can actually do in the Otherside, what the different types of lands are, what a Koda is, and how we can be early adopters into this new community-based world.'
    ],
    gradientColors: ['#403227', '#4b3728'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 10800
  },
  {
    id: 27,
    name: 'Panini Contenders Optic Justin',
    title:
      'Justin Herbert had a breakout rookie year, setting NFL records for the most touchdowns passed by a rookie, most passing years per game by a rookie, and most completions by a rookie.',
    description: [
      `Herbert was the 6th overall pick in the 2020 NFL Draft, selected by the Los Angeles Chargers in what is looking to become one of the franchise’s best draft years ever. After the Chargers’ starting quarterback Tyrod Tayler was injured, Herbert rose to the occasion, winning Offensive Rookie of the Year in the process.`,
      'In his second season, Justin Herbert continued at a record pace, becoming the first-ever quarterback to throw for 30 touchdowns in each of his first two seasons and beating Andrew Luck’s record for most passing yards thrown in a quarterback’s first two seasons. As Herbert and the Chargers continue to develop, fans eagerly anticipate continued success and hope to someday soon win a Superbowl.',
      'This card comes from the 2020 edition of Panini’s Contenders Optic, one of the biggest NFL collectible brands. It is part of the Rookie Ticket series and features an on-card autograph. It is the Orange variant and is numbered to only 50 copies. The PSA 10 copy of this card has a population of 4, making it one of Herbert’s most desirable and valuable cards.'
    ],
    gradientColors: ['#744515', '#a06c27'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 16200,
    coOwn: true
  },
  {
    id: 26,
    name: 'Panini Obsidian Joe',
    title:
      'Joe Burrow is one of the most exciting young players in the NFL. After bringing his team to their first playoff win since 1990, Joey B’s fans are looking forward to seeing what else the star has in store.',
    description: [
      `Joe Burrow was a standout college football star, winning both the Heisman Trophy for college MVP and bringing his team the College Football National Championship in 2020. After being selected first overall by the Cincinnati Bengals in the 2020 NFL Draft, Burrow had a shortened rookie season due to injuries. However, during the 2021 season Burrow and the Bengals roared back, heading all the way to the Superbowl final.`,
      'After only playing one full season for the Bengals, Burrow set franchise records for completion percentage, single-game passing yards, and passer rating, a numerical value assigned to quarterbacks based on their efficiency and skill.',
      'This card features an official NFL shield memorabilia and the Nike swoosh, as well as a beautiful autograph. It is also numbered to just one copy, making it one of Burrow’s most desirable but elusive cards.'
    ],
    gradientColors: ['#226266', '#3b9399'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 21600,
    coOwn: true
  },
  {
    id: 28,
    name: 'Alexander Ovechkin',
    title:
      'Alexander Ovechkin, nicknamed “Ovi”, is one of the most talented players to ever step foot on the ice. The Russian sharpshooter has won the NHL’s MVP award three times and holds the record for the most power-play goals ever scored.',
    description: [
      `Drafted first overall in the 2004 NHL Draft, Ovechkin was a star from the very beginning. In his rookie season, the marksman scored 52 goals and 106 points and took home Rookie of the Year. Only two seasons later, Ovi broke the 60-goal mark with 65 goals and 112 points, taking home the Rocket Richard trophy for most goals scored and Art Ross trophy for most points in a season.`,
      `Ovechkin has often been compared to Wayne Gretzky, and for good reason: he is tied with Gretzky for the highest number of 50-goal seasons, hitting that mark 9 times. He also has won more Rocket Richard trophies than any other NHL player in history and is third on the all-time scorers' list.`,
      'This card is from the 2020 Skybox Metal Universe set, which featured “retro” cards modeled after the famous 1997 insert set, the Precious Metal Gems (PMGs). The 2020 Metal Universe set is the first standalone Metal Universe set ever released for hockey, making it an extremely significant collection for the sport. PMGs are one of the most desirable and collectible insert sets in many different sports and genres and are always extremely limited. The set features unique serial numbering, with the first 10 cards out of 100 being Green parallels, and the remaining 90 being Red parallels. This card is one of only two PSA 7s graded, with zero copies graded higher. The 2020 SkyBox Metal Universe Alexander Ovechkin Green PMG /10 is one of the best hockey inserts ever released, for one of the history’s greatest champions of the sport.'
    ],
    gradientColors: ['#1b362b', '#376453'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 21600
  },
  {
    id: 31,
    name: '99 Originals - Original #17',
    title:
      'With the 99 Originals, Logan Paul is taking on his largest endeavor to date. The professional athlete, elite entertainer, and visionary entrepreneur is creating a global community dedicated to artists, entrepreneurs, and socialites to live life to the fullest.',
    description: [
      `The 99 Originals are a series of polaroid pictures minted as NFTs, coming from Logan Paul’s 99-day trip across the world where he engaged in personal development while experiencing the global culture. The photographs signify important moments throughout Logan’s journey that embody his feelings and beliefs as an experiential artist.`,
      `Beginning in May 2022 on the Originals website, one NFT has been listed for auction per day. The auctions last for a total of 24 hours, with a 15-minute grace period to prevent last-minute bids. Each auction is for one of the 99 NFTs, which comes with various perks often relating to the context of the image. Additionally, owning an Original grants you access to the project’s Decentralized Autonomous Organization, or DAO. A DAO often takes the form of a project or fund, in which participating members each have the ability to vote on the organization’s decisions. In the 99 Originals DAO 50% of the proceeds from all primary sales of the NFTs will enter the DAO’s treasury, and owners of the 99 each can vote on what those funds are used for.`,
      'The best description of what the DAO is capable of comes from Logan himself:',
      '“My ultimate goal is to do mastermind retreats with Members, fund independent movies and upcoming artists, strategically invest in special projects, donate to various philanthropic organizations, throw insane events, and honestly anything else our members want to do…”',
      'As a co-owner of Original #17 on Liquid MarketPlace, you are a part of this Original’s DAO voting right. You are also whitelisted to participate in the minting of the hidden Original #100.',
      'Owning and co-owning one of the 99 Originals integrates you within Logan Paul’s community. More than that, owners and co-owners can come together in a brand-new environment, becoming a part of something much bigger than themselves.',
      'On the Originals website, Logan concludes his project’s description with one final quote:',
      '“I think this is the perfect intersection of tech, art, culture, and community. This project will be my biggest endeavor to date, and I can’t wait to foster a group of people who will make everlasting impact in a revolutionary space.”'
    ],
    gradientColors: ['#dbcbd5', '#dbcbd5'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 30000,
    coOwn: true
  },
  {
    id: 32,
    name: 'Connor McDavid',
    title:
      'Whenever Connor McDavid steps foot on the ice, players know to be scared: McDavid’s lightning-fast pace, uncannily accurate passing, and ability to stickhandle his way through entire teams puts him a step above all other players in the league.',
    description: [
      `Connor McDavid is the most talented player to lace up his skates in many years, and has drawn comparisons to both Sidney Crosby and Wayne Gretzky. These comparisons are not surprising in the least: throughout his 7 active years, McDavid has led the NHL in points four times and has won league MVP twice.`,
      'McDavid debuted in the NHL in the 2015-16 season, during which he played only 45 games due to injuries. Despite missing almost half of the season, McDavid was still a finalist for the Calder Memorial Trophy, awarded to the NHL’s Rookie of the Year. During his second season, McDavid gave an unbelievable performance - in one year he was awarded the Art Ross Trophy for leading the league in points, the Hart Memorial Trophy as the league’s MVP, the Ted Lindsay Award for Most Oustanding Player, and appeared in his first NHL All-Star Game, being honored with a position on the NHL First All-Star Team.',
      'McDavid’s skill lies in his ability to weave through hordes of defenders all on his own, setting up perfect passing opportunities for his teammates to score goals. And, if his teammates lag behind, McDavid can pick up the pace, beating goaltenders and scoring on his own with ease. Having won the NHL’s Fastest Skater Competition three times (an NHL record), McDavid can quickly slip past defenders and use his unbeatable stickhandling and passing abilities to place the puck wherever it needs to be.',
      'This card comes from the 2015-16 Upper Deck The Cup, the year’s premier set of hockey cards produced by Upper Deck. This particular card is a Rookie Patch Autograph, featuring crystal-clear penmanship from McDavid, a piece of player-worn memorabilia, and stunning artwork by the Upper Deck design team. The card is numbered to 99 and is one of McDavid’s most desirable rookie cards.'
    ],
    gradientColors: ['#1c0a6e', '#4e2ebe'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 86400
  },
  {
    id: 33,
    name: 'Topps Chrome',
    title:
      'Charles Leclerc is one of the most dedicated drivers in F1. Since his entrance into the competition in 2018, Leclerc has improved dramatically and continues to prove to the world that he can drive with the best.',
    description: [
      `The world of Formula 1 has risen in popularity in recent years as all-time great Lewis Hamilton battled with the rising star Max Verstappen. However, the show didn’t stop when Verstappen won the 2021 championship: a new contender was here, and ready to challenge Max on the global stage.`,
      'Charles Leclerc is considered one of the most talented drivers in all of F1 and has become a considerable contender for the World Drivers Championship. Leclerc joined the competition in 2018 as a driver for Sauber, an affiliate of Ferrari. Alongside becoming the youngest driver to win the Pole Trophy, awarded to the driver with the most pole positions during the year’s races, Leclerc brought Sauber from 22nd place up to 8th place. Leclerc himself finished 13th with 39 points, considered a respectable position for a driver’s first year.',
      'In 2019, things began to speed up for Charles Leclerc. The Monégasque driver joined the Scuderia Ferrari team and had a hot start: in his second qualifying with Ferrari at the Bahrain Grand Prix, Leclerc qualified on pole position and set a track record in the qualifying sessions. The season continued in high-flying fashion for Leclerc as he proceeded to win two Grand Prix and the Pole Trophy, as well as a 4th place finish with 264 points.',
      'The 2022 season will be one to watch, as Leclerc vies for the championship against Red Bull competitors Max Verstappen and Sergio Perez. After achieving his first-ever grand slam (for achieving pole position, the win, the fastest lap, and leading every lap in a single race), Leclerc is ready to be a competitive force in what is looking up to be an exciting F1 season!',
      'This card comes from 2020’s Topps Chrome F1, the first year of Topp’s F1 collectible card series. The set was an unbelievable success, and demand for the cards shot past expectations almost immediately. Many cards from this set have set incredible records stretching into the high 6-figures. The base card is Leclerc’s Topps Rookie card, and the Gold variant is one of the most desirable variants, behind only the Red variant (/5) and Superfractor variant (1/1). This copy is graded a PSA 8 and there are currently zero copies graded higher.'
    ],
    gradientColors: ['#982118', '#c33730'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 5400
  },
  {
    id: 34,
    name: 'Bowman Chrome',
    title:
      'Wander Franco is one of the best young talents in baseball. His team, the Tampa Bay Rays, undoubtedly agree: in 2021, Franco was awarded the largest MLB contract ever for a player with less than one year of major league experience.',
    description: [
      `Wander Franco’s brilliance on the pitch can be compared to baseball greats, including Barry Bonds and Mickey Mantle. The Dominican-born shortstop has one of the most consistent hits in all of MLB, shown by his on-base streak: in August of 2021, Franco successfully hit on base for the 37th game in a row, beating Mickey Mantle’s 1950/51 36-game streak for players under the age of 21.`,
      'Franco isn’t just a fantastic hitter: his quick hands and brilliant foresight have complimented his abilities as a defender. It is no surprise then that the Tampa Bay Rays wanted to lock down the superstar, awarding him a $180 million contract over 12 years, making Franco the youngest player in MLB history to receive a $100 million contract. The Rays anticipate developing their team around Franco’s brilliant playstyle, and if his first two seasons are any indicator then Franco and the Rays will enjoy many successes together.',
      'This card is from 2019 Bowman Chrome Prospects, the most established line of baseball cards for each year’s prospective rookies. “1st Bowman” cards are regarded as the premier rookie cards in modern baseball collecting and carry a significant premium compared to other brands of cards. This card is a Blue Refractor, numbered to 150, and features an on-card autograph. It has been graded a 9.5 by BGS, with zero copies graded higher as of this writing.'
    ],
    gradientColors: ['#634545', '#b89899'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 12960
  },
  {
    id: 35,
    name: 'PSA 10 1998 Pokemon Pikachu Illustrator Holo',
    title:
      'With a combination of extreme rarity, rich history, fantastic artwork, and legendary status, the PSA 10 Pikachu Illustrator is the ultimate icon of the Pokémon franchise.',
    description: [
      `It doesn’t get any bigger than this.`,
      'In any grade, the Pikachu Illustrator is an incredibly valuable and ultra-rare card and is considered the holy grail of Pokémon card collecting. However, this single copy is on a pedestal above the rest—it is the only copy ever to receive the coveted PSA 10 Gem Mint grade, signifying that it is the single highest graded copy in the entire world.',
      'The Pikachu Illustrator was drawn by the legendary Atsuko Nishida, the Pokémon Company designer and artist who created many iconic characters, including Pikachu and Charizard. Nishida’s artwork and impact on the development and creation of the Pokémon franchise cannot be understated, and the Illustrator is considered one of her finest works.',
      'This card was initially given out in 1998 to 39 winners of Pokémon Card Art Competitions held in Japan between 1997 and 1998. The holy grail status of this card began early on in Pokémon’s history as people all over the world had heard about the mysterious Pikachu Illustrator. The total number of copies was initially rumoured to be only 1 until anecdotes from Japan revealed there were more out there. However, it took almost a decade for the true number of released copies to be revealed to the public. The lack of information, combined with the notoriety of being the most valuable Pokémon card, led to the Illustrator becoming a hobby enigma, something that only a few had ever seen in person.',
      'Unlike many cards that started out as commonplace and became valuable over time, such as the famous 1st Edition Base Set Charizard, this card was always the epitome of Pokémon collecting: in the year 2000, a copy of this card was reportedly sold for $20,000.00 USD (during that time, 1st Edition Charizards were selling for less than $100.00 USD!) Since then, the Illustrator has broken record after record. After selling in 2016 for roughly $50,000.00 USD, it has since shot up in value, with sales for PSA 7, PSA 8, and PSA 9 copies stretching into the hundreds of thousands of dollars.',
      'However, this PSA 10 is special. Until recently, only a select few individuals had ever seen even just a photo of this unbelievable item. While the PSA population report did confirm that a single PSA 10 Illustrator existed, many people stillregarded the card’s existence as simply rumours and theories. All that has changed now that this card has been shown publicly.',
      'With a whirlwind reveal at WrestleMania, media mogul Logan Paul presented the world with the first public viewing of the PSA 10 Illustrator, hung from a diamond-adorned gold chain. Immediately, global news outlets began reporting on the fantastic card, and it was soon revealed that Paul had purchased the Illustrator in a massive cash and trade deal. The deal was valued at $5,275,000.00 USD and contained $4 million in cash along with a PSA 9 Pikachu Illustrator valued at $1.275 million. After the reveal at WrestleMania, the transaction was confirmed by Guinness World Records to be the largest-ever private sale transaction of a Pokémon card. This publicity pushed the Illustrator to the forefront of the public eye, outside of just the Pokémon collector community.',
      'And now, in an unprecedented opportunity, you can co-own the greatest and most significant card from the biggest and most impactful media franchise on the planet. In the world of sports collecting, this would be equivalent to owning the 1952 Mickey Mantle in PSA 10, or the Honus Wagner T206. Outside of cards, this would be like owning a piece of the Mona Lisa.',
      'The Illustrator is undeniably the most significant card in the entire Pokémon media franchise, and this PSA 10 copy is certifiably the world’s greatest Pokémon card.'
    ],
    gradientColors: ['#947a30', '#937830'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 5400000,
    coOwn: true
  },
  {
    id: 36,
    name: 'PSA 10 2002 Pokémon Neo Destiny #107 Shining Charizard',
    title:
      'The very last 1st Edition Charizard brought a couple of firsts to the Pokémon TCG: this beautiful card is both the first Shiny Charizard and the first ultra-rare Charizard to be printed.',
    description: [
      `When the Pokémon Neo Destiny set was released in 2002, it was immediately a massive hit amongst the growing community of Pokémon collectors and fans. This was no surprise: Neo Destiny came onto the Pokémon Trading Card scene with a huge array of beautiful holographic cards and seven fantastic Shining Pokémon. Shiny Pokémon were introduced in the Pokémon Gold and Silver video games, and are extremely rare Pokémon that sport a different color than usual. Charizard is normally a fiery-red color, but on this card, it is a menacing black.`,
      'Shining Charizard was the first time in the Pokémon TCG that Charizard was printed in its alternate color, and it wasn’t until 2013 that this was done again. The combination of the rarity of Shiny Pokémon with the desirability of Charizard makes it one of the reasons why this card has always been one of the most sought-after Charizards out there.'
    ],
    gradientColors: ['#4d2c32', '#7d4f59'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 10800
  },
  {
    id: 37,
    name:
      'PSA 10 1999 Pokémon Jungle 1st Edition Vaporeon, Jolteon, Flareon “Eeveelution Trio“',
    title:
      'Eevee and its evolutions, known as Eeveelutions, are some of the biggest fan favorites in Pokémon. Amongst collectors, these low-pop gems are among the most desirable 1st Edition Holos in all of the TCG.',
    description: [
      `One of the most impactful moments in Pokémon history was Episode 40 of the original animated series, when the main character Ash and his partner Pikachu met the Eevee brothers and their partners, Vaporeon, Jolteon, Flareon, and an unevolved Eevee. The characters were immediate hits as kids fell in love with the powerful evolved trio and the resilient basic Pokémon. Since then, five more Eeveelutions have been introduced into the Pokémon world and the popularity of the Eevee line has continued to grow as new audiences continue to join the game.`,
      'These three cards come from the second-ever set of English Pokémon cards, the Jungle set, which was released on June 16, 1999. Featuring fantastic artwork by one of Pokémon’s original designers, Kagemaru Himeno, these three cards were collected by players and fans alike. However, due to the print quality of the Jungle set and other early Pokémon sets, these cards have proven to be very difficult to grade, and have a small quantity of PSA Gem Mint 10s available. Out of nearly 6000 copies of the 1st Edition trio that have been graded, only 391 copies have received the coveted PSA 10 grade. The desirability and low populations have elevated this trio to grail status for many Pokémon collectors.'
    ],
    gradientColors: ['#6b3b1d', '#996034'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 12960
  },
  {
    id: 38,
    name: '1st Edition Neo Genesis Booster Box Break - 1 Pack',
    title:
      'Neo Genesis is one of the most exclusive sets you can get your hands on. Originally released in 2000, this 22-year-old set features some of the most popular Generation II Pokémon including Lugia, Typhlosion, Meganium, and Feraligatr.',
    description: [
      'Neo Genesis is known for having holographic cards that are extremely difficult to grade, so mint condition cards from this set are very rare and valuable. PSA 10 graded holos in this set are almost always worth thousands, if not tens of thousands, with the main prize, a Lugia in PSA 10 being worth $40k+.',
      'This box contains 36 box-fresh packs, and with each box containing on average 12 holos, there is a 33% chance that you open a holo with each pack you purchase!',
      'That’s not all!',
      'We have a lot of bonuses available for the lucky individuals who hit a holographic card:',
      'Your cards will be protected by Kanto Shark and The Ultimate Card Collector’s Kit—Shark Sleeves! Shark Sleeves provide everything you need to grade and protect your cards.',
      'We will also send your holographic hit to PSA to get it graded for free! This service is provided by KD Collectibles—Canada’s LARGEST PSA Grading Middleman!',
      'You will receive a free GradedGuard case for your freshly graded PSA holo. GradedGuard is the premier option for protecting and displaying your valuable graded cards, and they provide cases for PSA, BGS, and CGC/CSG graded cards!'
    ],
    gradientColors: ['#717B2D', '#929A42'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 22500,
    ppt: 625
  },
  {
    id: 39,
    name:
      '2020 National Treasures Jalen Hurts Rookie Patch Autograph Silver /25 PSA 8',
    title:
      'In his first year at the University of Alabama, Jalen Hurts started as quarterback, marking the first time in 32 years that a first-year student would start as QB in Alabama history.',
    description: [
      'Jalen Hurts’ first year at Alabama made team history, as the dual-threat quarterback shattered records for rushing yards and touchdowns in a season. The Alabama Crimson Tide went on to have a perfect season that year, with Hurts being awarded the prestigious Southeastern Conference Offensive Player of the Year and Freshman of the Year awards.',
      'Two years later, Hurts transferred to Oklahoma, where he set a new record for debut single-game yardage with 508 total years. Previously, the record was held by now-Carolina Panthers quarterback Baker Mayfield who had 396 total yards in his debut game. Hurts finished second in the Heisman Trophy voting to Joe Burrow.',
      'Despite an exciting college career, Hurts’ NFL expectations have fallen short during his previous two seasons with the Philadelphia Eagles. In his first season, Hurts played backup to starter Carson Wentz. In his second season, Hurts was promoted to starter and finished 8-7 with a loss in the wildcard round to the Buccaneers. However, Hurts’ improvement across his first and second seasons was significant, with his completion percentage jumping from 52% to over 61%.',
      `Jalen Hurts is expected to have a big 2022 season after continuing to improve his skills during the off-season. As head coach Nick Sirianni said, “He’s just the type of guy that’s going to reach his maximum potential because of all the off-the-field qualities he has. I’ve also noticed just the crispness of the drop. It’s been the fundamentals – his fundamentals have improved, and he’s really worked hard at that.”`
    ],
    gradientColors: ['#2d4954', '#3e606a'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 6480
  },
  {
    id: 40,
    name:
      '2019 DBS Assault of the Saiyans Dependable Brother Son Gohan SPRS BGS 10',
    title:
      'Gohan is one of the most important characters in the Dragon Ball storyline. The son of main character Goku, Gohan is half human and half Saiyan, a race of extraterrestrial beings that possess superhuman strengths and abilities. Despite his power, Gohan dreamt of becoming a scholar, and was only eager to fight when his family and friends were in danger.',
    description: [
      'Gohan is one of the most important characters in the Dragon Ball storyline. The son of main character Goku, Gohan is half human and half Saiyan, a race of extraterrestrial beings that possess superhuman strengths and abilities. Despite his power, Gohan dreamt of becoming a scholar, and was only eager to fight when his family and friends were in danger.',
      'This card comes from the seventh main Dragon Ball Super set, Assault of the Saiyans (AOTA). AOTA is one of the most popular Dragon Ball Super sets and is known for bringing back the beloved “signature series” cards that debuted in 2018’s “The Tournament of Power”. The four signature cards present in AOTA feature beautiful renditions of characters and have a stylized signature from each character’s respective voice actor. These signature cards are incredibly difficult to pull, with reported pull rates as low as 1 signature card per 6 boxes, resulting in few BGS 10 Pristine copies.'
    ],
    gradientColors: ['#311010', '#73302e'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 1620
  },
  {
    id: 41,
    name:
      '2019 DBS Assault of the Saiyans Hit, Pride of Universe 6 SPRS BGS 10',
    title:
      'Hit, known as “never-miss Hit,” is a hitman from Universe 6 in the Dragon Ball multiverse. He is known for his stoic and calm demeanor and for always calculating the best way to win any fight. Hit strongly believes in the importance of a fair fight, and will respect all opponents who truly present a challenge to him.',
    description: [
      'Hit, known as “never-miss Hit,” is a hitman from Universe 6 in the Dragon Ball multiverse. He is known for his stoic and calm demeanor and for always calculating the best way to win any fight. Hit strongly believes in the importance of a fair fight, and will respect all opponents who truly present a challenge to him.',
      'This card is one of the hardest cards to grade from the Assault of the Saiyans set. The second lowest population signature card from Assault of the Saiyans has a population of 35 - Hit, Pride of Universe 6 has a population of only 14 BGS 10s.',
      'AOTA is one of the most popular Dragon Ball Super sets and is known for bringing back the beloved “signature series” cards that debuted in 2018’s “The Tournament of Power”. The four signature cards present in AOTA feature beautiful renditions of characters and have a stylized signature from each character’s respective voice actor. These signature cards are incredibly difficult to pull, with reported pull rates as low as 1 signature card per 6 boxes, resulting in few BGS 10 Pristine copies.'
    ],
    gradientColors: ['#3a1c3b', '#88508a'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 3240
  },
  {
    id: 42,
    name:
      '2019 DBS Assault of the Saiyans SS2 Trunks, Memories of the Past SPRS BGS 10',
    title:
      'Trunks, the son of Vegeta, is a powerful Earthling and Saiyan hybrid within the Dragonball Z multiverse. From a young age, Trunks is shown to be a skilled combatant and shares much of his father, Vegeta’s, bravado. Trunks idolizes his father and places family above almost all else; Trunks is always quick to defend his family when the need arises.',
    description: [
      'Trunks, the son of Vegeta, is a powerful Earthling and Saiyan hybrid within the Dragonball Z multiverse. From a young age, Trunks is shown to be a skilled combatant and shares much of his father, Vegeta’s, bravado. Trunks idolizes his father and places family above almost all else; Trunks is always quick to defend his family when the need arises.',
      'Trunks is one of the most popular characters in Dragonball, especially thanks to his incredible power and exciting matches. He, along with Future Trunks, his counterpart from the future, are regarded as fan favorites within the Dragonball Z fanbase, and this card is considered to be one of his very best and most collectible.',
      'AOTA is one of the most popular Dragon Ball Super sets and is known for bringing back the beloved “signature series” cards that debuted in 2018’s “The Tournament of Power”. The four signature cards present in AOTA feature beautiful renditions of characters and have a stylized signature from each character’s respective voice actor. These signature cards are incredibly difficult to pull, with reported pull rates as low as 1 signature card per 6 boxes, resulting in few BGS 10 Pristine copies.'
    ],
    gradientColors: ['#182a36', '#3b6074'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 1620
  },
  {
    id: 43,
    name:
      '2018 DBS The Tournament of Power Son Goku, Hope of Universe 7 SPR BGS 10',
    title:
      'Goku is the main character of the Dragon Ball franchise, one of the world’s most popular multimedia productions. As a manga, Dragon Ball has sold over 460 million copies worldwide, making it the second most prolific manga in the world. As an anime, Dragon Ball is broadcast in over 80 countries and is cited as one of the leading factors in the integration of Japanese media and culture worldwide.',
    description: [
      'Goku is the main character of the Dragon Ball franchise, one of the world’s most popular multimedia productions. As a manga, Dragon Ball has sold over 460 million copies worldwide, making it the second most prolific manga in the world. As an anime, Dragon Ball is broadcast in over 80 countries and is cited as one of the leading factors in the integration of Japanese media and culture worldwide.',
      'Goku is a member of the superhuman Saiyan race, and was inspired by 16th-century Chinese literature and many martial arts films featuring Jackie Chan and Bruce Lee. Initially aggressive, Goku suffered a head injury that made him into a happy and lovable individual, while still possessing incredible powers. The Dragon Ball series follows Goku’s journey across multiple worlds and universes to collect the seven titular Dragon Balls and hone his martial arts skills.',
      'This card comes from 2018’s The Tournament of Power card set. The Tournament of Power was the first themed set as cards in this set feature artwork, attacks, and abilities from The Tournament of Power arc in the Dragon Ball manga and anime. This set was hugely successful and is considered to be the first major Dragon Ball Super card set due to its global success, despite other less-popular sets existing before it. The Signature Series cards from this set were immediately desirable and collectible amongst Dragon Ball fans and card collectors alike, and are now some of the most sought-after Dragon Ball cards of all.'
    ],
    gradientColors: ['#2f2e32', '#74747a'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 3780
  },
  {
    id: 44,
    name:
      '2019 DBS Assault of the Saiyans Meteoric Energy SSB Vegito SPRS BGS 10',
    title: `Vegito is the fused form of two of Dragon Ball's most powerful creatures: Goku and Vegeta. The fusion combines their most dangerous traits into a single ultra-powerful being but is prone to burning out due to over-usage of power. As a fusion of two Dragon Ball fan favorites, Vegito is beloved as one of the best recurring characters in the series.`,
    description: [
      `Vegito is the fused form of two of Dragon Ball's most powerful creatures: Goku and Vegeta. The fusion combines their most dangerous traits into a single ultra-powerful being but is prone to burning out due to over-usage of power. As a fusion of two Dragon Ball fan favorites, Vegito is beloved as one of the best recurring characters in the series.`,
      'AOTA is one of the most popular Dragon Ball Super sets and is known for bringing back the beloved “signature series” cards that debuted in 2018’s “The Tournament of Power”. The four signature cards present in AOTA feature beautiful renditions of characters and have a stylized signature from each character’s respective voice actor. These signature cards are incredibly difficult to pull, with reported pull rates as low as 1 signature card per 6 boxes, resulting in few BGS 10 Pristine copies.'
    ],
    gradientColors: ['#101e2c', '#345671'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 2700
  },
  {
    id: 45,
    name: '10x PSA 10 2019-20 Panini Prizm Zion Williamson Silver Prizm',
    title: `Zion Williamson was one of the most anticipated prospects in modern NBA history when the multi-talented superstar was drafted 1st overall by the New Orleans Pelecans. And while his first two seasons were cut short due to injuries, the extended time off the court has given Zion the opportunity to improve his game and heal his body.`,
    description: [
      `Zion Williamson was one of the most anticipated prospects in modern NBA history when the multi-talented superstar was drafted 1st overall by the New Orleans Pelecans. And while his first two seasons were cut short due to injuries, the extended time off the court has given Zion the opportunity to improve his game and heal his body. Now, Williamson is ready to take the league by storm: his offensive prowess is one of the most formidable forces in the NBA.`,
      'Panini Prizm is the flagship collectible card release for many major sports, especially basketball. The sleek design of Prizm, along with the innumerable variations, makes it one of the most widely collectible franchises in collectibles history. The Silver Prizm is one step up from the base cards and is typically the most identifiable rookie card for NBA stars. This Offering contains 10 copies of Zion Williamson’s Silver Prizm, each one graded an incredible PSA 10, making it the perfect Offering for those looking to add some of Zion’s best cards to their collection!'
    ],
    gradientColors: ['#1b1a44', '#2c2c63'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 18900
  },
  {
    id: 46,
    name: 'BGS 8.5 2019-20 Panini Prizm Zion Williamson White Sparkle Prizm',
    title: `Despite entering only his 3rd season, Zion Williamson is already cemented in basketball history: he was the 5th youngest player ever to be selected to an All-Star game, behind legends such as Kobe Bryant and Magic Johnson. Williamson’s college play was on a whole other level compared to his peers: he was the 3rd player ever to win the National College Player of the Year during his first year in college.`,
    description: [
      `Despite entering only his 3rd season, Zion Williamson is already cemented in basketball history: he was the 5th youngest player ever to be selected to an All-Star game, behind legends such as Kobe Bryant and Magic Johnson. Williamson’s college play was on a whole other level compared to his peers: he was the 3rd player ever to win the National College Player of the Year during his first year in college. As Zion begins his 3rd season, fully recovered from all injuries, its anyone’s guess as to how bright the superstar will shine.`,
      'Panini Prizm is the flagship collectible card release for many major sports, especially basketball. The sleek design of Prizm, along with the innumerable variations, makes it one of the most widely collectible franchises in collectibles history. The White Sparkle Prizm is an extremely rare variation that is only found in special White Sparkle promotional packs. They are not serialized, but rumors state that there are only 20 copies of each player’s White Sparkle card. This is one of Williamson’s best Prizm rookie cards, and is perfect for anyone looking to add a Zion card to their collection!'
    ],
    gradientColors: ['#4e4f60', '#9c9dae'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 48600
  },
  {
    id: 47,
    name: 'BGS 8.5 2019-20 Panini National Treasures Zion Williamson Rookie Patch Auto /99',
    title: `Zion Williamson needs no introduction. 2019’s #1 overall pick has had a career highlighted by incredible performances but mired by many injuries. Despite the highs and lows, it is clear that Zion Williamson is one of the most exciting players of his generation.`,
    description: [
      `Zion Williamson needs no introduction. 2019’s #1 overall pick has had a career highlighted by incredible performances but mired by many injuries. Despite the highs and lows, it is clear that Zion Williamson is one of the most exciting players of his generation. When he’s on the court, opponents fear the incredible athleticism inside his massive build. Off the court, fans and collectors love to keep up with Zion’s movements as the young player continues to grow his game, hoping to turn into a superstar.`,
      'Since its inception, National Treasures has quickly become the most anticipated release of basketball collectibles each and every year. The highly popular set is difficult and costly to acquire, and for good reason - the set features the year’s most exciting rookies. The /99 Rookie Patch Auto is considered to be one of the best rookie cards of any particular player, and typically has even more value than many lower-numbered rookie cards. The desirability and recognizability of the /99 NT RPA is almost unmatched in the hobby, making them a must-have for collectors looking to source the best available cards.'
    ],
    gradientColors: ['#1c293a', '#4d6a82'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 64800
  },
  {
    id: 48,
    name: '2018 DBS The Tournament of Power Beerus, Universe 7 Divine Vanquisher SPR BGS',
    title: `Beerus is the God of Destruction in Universe 7 in the Dragon Ball multiverse. He is depicted as a purple cat with Ancient Egyptian symbolism and was modeled after Dragon Ball creator Akira Toriyama’s pet cat.`,
    description: [
      `Beerus is the God of Destruction in Universe 7 in the Dragon Ball multiverse. He is depicted as a purple cat with Ancient Egyptian symbolism and was modeled after Dragon Ball creator Akira Toriyama’s pet cat. Beerus shares many personality traits with his feline inspirers and is known to be both lazy and playful and also extraordinarily powerful. When he is first introduced, Beerus is shown to be the most powerful villain ever fought by Goku and the Z-Fighters.`,
      'Tied for the second-lowest BGS 10 population in The Tournament of Power, this signature series Beerus is one of the most difficult Dragon Ball cards to find. The Tournament of Power was the first themed set as cards in this set feature artwork, attacks, and abilities from The Tournament of Power arc in the Dragon Ball manga and anime. This set was hugely successful and is considered to be the first major Dragon Ball Super card set due to its global success, despite other less-popular sets existing before it. The Signature Series cards from this set were immediately desirable and collectible amongst Dragon Ball fans and card collectors alike, and are now some of the most sought-after Dragon Ball cards of all.'
    ],
    gradientColors: ['#29144f', '#693ba4'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 3780
  },
  {
    id: 49,
    name: '2018 DBS The Tournament of Power Universe 7 Saiyan Prince Vegeta SPR BGS 10',
    title: `As one of the most powerful Saiyans in Dragon Ball, the royal Prince Vegeta is among the most beloved characters in the manga and anime. He is seen as a counterpart to Goku and dedicates much of his time to attempting to best the main character in battle.`,
    description: [
      `As one of the most powerful Saiyans in Dragon Ball, the royal Prince Vegeta is among the most beloved characters in the manga and anime. He is seen as a counterpart to Goku and dedicates much of his time to attempting to best the main character in battle. Initially, Vegeta was introduced as an antagonist, but after going through significant character development he eventually becomes a hero and fights to protect his friends, family, and planet Earth.`,
      'Tied for the second-lowest BGS 10 population in The Tournament of Power, Vegeta combines high rarity with a large fanbase. The Tournament of Power was the first themed set as cards in this set feature artwork, attacks, and abilities from The Tournament of Power arc in the Dragon Ball manga and anime. This set was hugely successful and is considered to be the first major Dragon Ball Super card set due to its global success, despite other less-popular sets existing before it. The Signature Series cards from this set were immediately desirable and collectible amongst Dragon Ball fans and card collectors alike, and are now some of the most sought-after Dragon Ball cards of all.'
    ],
    gradientColors: ['#162f2b', '#3e726c'],
    numberColor: '#ad0d08',
    minimalBuyOutPrice: 4320
  }
];
