import React, { useEffect, useMemo } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { buyValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';

import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { Modal } from '../common/Modal/Modal';
import ModalHeader from './components/ModalHeader';
import { BUY_SELL_STATE_NAMES } from '../../redux/reducers/buySellModalReducer';
import { useIsMobile } from '../../hooks/useIsMobile';
import config from '../../config';
import { buySellFormPropType } from './buySellFormPropType';
import './BuySellModal.css';

const classes = getBEMClasses('buy-sell-modal');

const BuySellModalComponent = (props, context) => {
  const {
    isOpen = false,
    close,
    containerProps = {},
    dispatch,
    // eslint-disable-next-line no-unused-vars
    goToInitialState,
    // eslint-disable-next-line no-unused-vars
    initOrderHistoryListeners,
    isTermsOfUseAccepted,
    ...dataProps
  } = props;
  const {
    getPropsForModalComponent = () => { },
    ChildModalComponent = () => null
  } = containerProps;
  const {
    form,
    stateName,
    simplifiedStateName
  } = dataProps;

  const isMobile = useIsMobile();

  const { footer, ...modalProps } = useMemo(
    () => {
      if (isOpen) {
        const stateModalProps = getPropsForModalComponent({
          context,
          dispatch,
          isTermsOfUseAccepted,
          isMobile
        }) || {};
        return {
          ...stateModalProps,
          footer: stateModalProps.footer && {
            ...stateModalProps.footer,
            classModifiers: getSideCssModifier(form)
          }
        };
      } else {
        return {};
      }
    },
    [
      isOpen,
      getPropsForModalComponent,
      isMobile,
      isTermsOfUseAccepted
    ]
  );
  const contentClass =
    stateName !== BUY_SELL_STATE_NAMES.PLACE ? classes('non-input-wrapper') : undefined;
  const overrideFooterButton = stateName === BUY_SELL_STATE_NAMES.PLACE;
  return (
    <Modal
      customClass={classes()}
      isOpen={isOpen}
      close={close} // fallback 'close', state can override it
      showCloseIcon={false}
      {...modalProps}
      footer={overrideFooterButton ? undefined : footer}
      titleComponent={
        <ModalHeader
          title={modalProps.title}
          sideCssModifier={getSideCssModifier(form)}
          simplifiedStateName={simplifiedStateName}
          onCloseClick={modalProps.back || modalProps.close || close} />
      }>
      <div className={contentClass}>
        <InnerComponent {...props}>
          <ChildModalComponent
            {...dataProps}
            footerButtonProps={overrideFooterButton ? footer : undefined}
          />
        </InnerComponent>
      </div>
    </Modal>
  );
};


const InnerComponent = props => {
  const {
    close,
    form,
    stateName,
    goToInitialState,
    initOrderHistoryListeners,
    children,
  } = props;

  useEffect(() => {
    goToInitialState();
    // TODO(Feb 09, 2022): it should be called once, now it's called on every open. 
    // apex has it's own listeners that we need to override, and we don't know
    // when apex listeners are ready. because of that now we call it on every
    // open to be sure that our listeners won't be overriden by apex.
    // but ideally we should do this only once. 
    initOrderHistoryListeners();
  }, []);

  useEffect(() => {
    const isAwaitingUserInput =
      stateName === BUY_SELL_STATE_NAMES.PLACE ||
      stateName === BUY_SELL_STATE_NAMES.CONFIRM ||
      stateName === BUY_SELL_STATE_NAMES.BELOW_MARKET_WARNING;

    let handler;
    if (isAwaitingUserInput) {
      handler = setTimeout(() => {
        close();
      }, config.BuySellModal.globalInactivityTimeout);
    }

    return () => {
      handler && clearTimeout(handler);
    };
  }, [stateName, form.quantity, form.limitPrice]);

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

const getSideCssModifier = form =>
  get(form, 'side') === buyValue ? 'buy' : undefined;

BuySellModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  goToInitialState: PropTypes.func,
  containerProps: PropTypes.object,
  stateName: PropTypes.string,
  simplifiedStateName: PropTypes.string,
  form: buySellFormPropType,
  initOrderHistoryListeners: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  isTermsOfUseAccepted: PropTypes.bool
};

BuySellModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

InnerComponent.propTypes = {
  close: PropTypes.func,
  form: buySellFormPropType,
  stateName: PropTypes.string,
  goToInitialState: PropTypes.func,
  initOrderHistoryListeners: PropTypes.func,
  children: PropTypes.any
};

export default BuySellModalComponent;
