import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';

import './APSection.css';

const baseClasses = 'section';

const APSection = props => {
  const { title = '', additionalComponent, children, customClass } = props;
  const bemClasses = getBEMClasses([baseClasses, customClass]);
  return (
    <div className={bemClasses('container')}>
      <div className={bemClasses('title-container')}>
        <div className={bemClasses('title')}>{title}</div>
        {additionalComponent}
      </div>
      {children}
    </div>
  );
};

APSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  additionalComponent: PropTypes.node,
  children: PropTypes.node.isRequired,
  customClass: PropTypes.string
};

export { APSection };
