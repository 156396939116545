import { RESET_USER } from "../actions/userActions";
import apUserReducer from 'apex-web/lib/redux/reducers/userReducer';

const initialState = {
  userInfo: {},
  permissions: [],
  accounts: [],
  userConfig: [],
  selectedAccountId: undefined,
  kyc: { urlParams: null },
  qrCode: '',
  verificationLevelConfigs: {}
};

export default function extendedUserReducer(state = initialState, payload) {
  switch (payload.type) {
    case RESET_USER:
      return initialState;
    default:
      return apUserReducer(state, payload);
  }
}
