import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

import './PaymetnsModals.css';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { Modal } from '../../../components/common/Modal/Modal';
import APIcon from '../../../components/common/APIcon';
import { WarningIcon } from '../../../images/react-icons/WarningIcon';

const classes = getBEMClasses('payments-modal');

export const CancelSubscriptionsModals = (props, context) => {
  const {
    isModalOpen,
    closeModal,
    confirmCancelSubscription,
    isLoading,
    setIsLoading
  } = props;

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const onCancelConfirm = () => {
    setIsLoading(true);
    confirmCancelSubscription();
  };

  return (
    <Modal
      customClass={classes()}
      isOpen={isModalOpen}
      close={closeModal}
      showHeader={false}
      showCloseIcon={false}>
      <div className={classes('delete-card-header')}>
        <div className={classes('header-left')}>
          <WarningIcon className={classes('trash-icon')} />
          {context.t('Cancel Recurring Payment')}
        </div>
        <div onClick={closeModal}>
          <APIcon name="close" customClass={classes('close-icon')} />
        </div>
      </div>
      <div className={classes('body')}>
        <div className={classes('title')}>
          {context.t('Are you sure you want to cancel this recurring payment?')}
        </div>

        {isLoading ? (
          <Spinner customClass={classes('spinner')} />
        ) : (
          <div className={classes('buttons')}>
            <button className={classes('cancel-button')} onClick={closeModal}>
              {context.t('Go Back')}
            </button>
            <button
              className={classes('confirm-delete')}
              onClick={onCancelConfirm}>
              {context.t('Confirm')}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

CancelSubscriptionsModals.contextTypes = {
  t: PropTypes.func.isRequired
};

CancelSubscriptionsModals.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  confirmCancelSubscription: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func
};
