import { SET_LEDGER_ENTRIES, UPDATE_LEDGER_ENTRIES_REQUEST_STATUS } from "../actions/ledgerActions";

const initialState = {
  entries: [],
  fetching: false
};

const ledgerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_LEDGER_ENTRIES_REQUEST_STATUS:
      return {
        ...state,
        fetching: payload.isFetching
      };
    case SET_LEDGER_ENTRIES: {
      return {
        ...state,
        entries: payload
      };
    }
    default:
      return state;
  }
};

export default ledgerReducer;
