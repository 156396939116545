import React from 'react';
import PropTypes from 'prop-types';
import Enable2FAForm from './Enable2FAForm';
import { Modal } from '../common/Modal/Modal';

import './Enable2FAModal.css';

const Enable2FAModal = (props, context) => {
  const {
    GoogleQRCode,
    handleSubmit,
    submitting,
    isOpen,
    close,
    changeFieldValue,
    userName
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      title={context.t('Enable Two-Factor Authentication')}
      onCancel={() => {
        close();
        changeFieldValue('enableTwoFactorAuth', 'use2FA', false);
      }}
      close={close}>
      <Enable2FAForm
        userName={userName}
        GoogleQRCode={GoogleQRCode}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
    </Modal>
  );
};

Enable2FAModal.defaultProps = {
  handleSubmit: () => {},
  submitting: false
};

Enable2FAModal.propTypes = {
  close: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  GoogleQRCode: PropTypes.string,
  submitting: PropTypes.bool,
  isOpen: PropTypes.bool,
  changeFieldValue: PropTypes.func,
  userName: PropTypes.string
};

Enable2FAModal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default Enable2FAModal;
