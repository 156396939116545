import { connect } from 'react-redux';
import AffiliateCountComponent from './AffiliateCountComponent';

const mapStateToProps = state => {
  return {
    affiliate: state.affiliate
  };
};

export default connect(mapStateToProps)(AffiliateCountComponent);
