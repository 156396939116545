import './APTable.css';
import PropTypes from 'prop-types';
import React from 'react';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import PaginationContainer from '../Pagination/PaginationContainer';
import SimplePaginationContainer from '../Pagination/SimplePaginationContainer';
import APIcon from '../APIcon';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

const filterInvalid = (props= {}) => props;

const APTable = (props, context) => {
  const {
    title,
    columns,
    baseClass,
    rows,
    empty,
    onRowClicked,
    fetching,
    headerOutside,
    pageSize,
    minRow,
    alwaysShowPagination,
    rowProps,
    selectedRow,
    componentName,
    usePagination,
    showOnlyActivePage,
    useSimplePagination,
    useFullData
  } = props;

  const flexTable = getBEMClasses('flex-table');
  const customClass = baseClass || (() => '');

  const header = columns.map((col, index) => (
    <div
      data-test={col.dataTest}
      key={index}
      title={col.title || undefined}
      className={`${flexTable('column')} ${customClass(
        'header-column',
        col.classModifier
      )}`}>
      <div
        className={`${flexTable(
          'header-cell',
          headerOutside ? 'absolute' : 'fixed'
        )} ${customClass('fixed', col.classModifier)}`}>
        {col.header}
      </div>
    </div>
  ));

  const emptyRow = () => (
    <div className={`${flexTable('row', 'empty')} ${customClass('row-empty')}`}>
      <div
        className={`${flexTable('column', 'empty')} ${customClass(
          'column-empty'
        )}`}>
        <APIcon
          name="stop"
          customClass={`${flexTable('empty-icon')} ${customClass(
            'empty-icon'
          )}`}
        />
        {empty}
      </div>
    </div>
  );

  const getTableRows = data => {
    return data && data.length && data[0] === undefined
      ? data.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className={`${flexTable('row', 'no-hover')} ${customClass('row')}`}>
            {rowIndex === 0 && emptyRow()}
          </div>
        ))
      : data.map((row, rowIndex) => (
          <div
            {...row && filterInvalid(rowProps(row, rowIndex))}
            onClick={() => row && onRowClicked && onRowClicked(row)}
            key={rowIndex}
            className={`${flexTable('row', !row && 'no-hover')} ${customClass(
              'row',
              selectedRow.key && selectedRow.value === row[selectedRow.key]
                ? 'selected'
                : ''
            )}`}>
            {row &&
              columns.map((col, colIndex) => (
                <div
                  style={col.style}
                  key={col.accessor || colIndex}
                  className={`${flexTable('column')} ${customClass(
                    'column',
                    col.classModifier
                  )}`}>
                  {col.cell
                    ? col.cell(row, rowProps(row, rowIndex))
                    : row[col.accessor]}
                </div>
              ))}
          </div>
        ));
  };
  const renderTable = (data, containerClasses) => (
    <div className={flexTable('wide-row-container')}>
      <div className={`${flexTable()} ${customClass()} ${containerClasses}`}>
        {title && (
          <div
            className={`${flexTable('title')} ${customClass('table-title')}`}>
            {title}
          </div>
        )}
        <div
          className={`${flexTable('header', 'background')} ${customClass(
            'table-header',
            'background'
          )}`}
        />
        {headerOutside && (
          <div
            className={`${flexTable('header')} ${customClass(
              'table-header',
              baseClass.classModifier
            )}`}>
            {header}
          </div>
        )}
        <div className={`${flexTable('body')} ${customClass('body')}`}>
          {!headerOutside && (
            <div
              className={`${flexTable('header', 'inside')} ${customClass(
                'table-header',
                baseClass.classModifier
              )}`}>
              {header}
            </div>
          )}
          {fetching ? (
            <Spinner text={context.t('Loading...')} customClass={baseClass} />
          ) : data.length === 0 ? (
            emptyRow()
          ) : (
            getTableRows(data)
          )}
        </div>
      </div>
    </div>
  );

  const getMinimumOf = (data, min) => {
    const emptyRows = min - data.length;
    return minRow && min && emptyRows > 0
      ? [...data, ...new Array(Math.min(minRow || emptyRows, emptyRows))]
      : data;
  };

  const renderTableWithPagination = (totalPages, data) => {
    const containerClasses = customClass('body', 'multipage');

    if (useSimplePagination) {
      const rowLength = data.length;
      return (
        <SimplePaginationContainer
          totalPages={totalPages}
          containerClassName={flexTable('pagination-container')}
          content={page => {
            const correctData = useFullData
              ? data.slice(pageSize * page, pageSize * (page + 1))
              : [...data];
            return renderTable(
              getMinimumOf(correctData, minRow || pageSize),
              containerClasses
            );
          }}
          isNextDisabled={rowLength < pageSize}
          componentName={componentName}
          customClass={baseClass()}
          showOnlyActivePage={showOnlyActivePage}
          onRequestPrev={props.onRequestPrev}
          onRequestNext={props.onRequestNext}
        />
      );
    }

    return (
      <PaginationContainer
        totalPages={alwaysShowPagination ? Infinity : totalPages}
        containerClassName={flexTable('pagination-container')}
        content={page => {
          const rows = data.slice(pageSize * page, pageSize * (page + 1));
          return renderTable(
            getMinimumOf(rows, minRow || pageSize),
            containerClasses
          );
        }}
        componentName={componentName}
        customClass={baseClass()}
        showOnlyActivePage={showOnlyActivePage}
      />
    );
  };

  const totalPages =
    rows.length === pageSize
      ? 2
      : Math.ceil(rows.length / pageSize || rows.length);
  const containerClasses = customClass('body', 'single-page');

  return usePagination && (alwaysShowPagination || (pageSize && totalPages > 1))
    ? renderTableWithPagination(totalPages, rows)
    : renderTable(getMinimumOf(rows, minRow || pageSize), containerClasses);
};

APTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      header: PropTypes.string,
      cell: PropTypes.func,
      width: PropTypes.string,
      classModifier: PropTypes.string
    })
  ).isRequired,
  rows: PropTypes.array.isRequired,
  rowProps: PropTypes.func,
  empty: PropTypes.string,
  onRowClicked: PropTypes.func,
  baseClass: PropTypes.func,
  fetching: PropTypes.bool,
  minRow: PropTypes.number,
  pageSize: PropTypes.number,
  alwaysShowPagination: PropTypes.bool,
  selectedRow: PropTypes.object,
  usePagination: PropTypes.bool,
  useSimplePagination: PropTypes.bool
};

APTable.defaultProps = {
  headerOutside: true,
  rowProps: (row, rowIndex) => ({ rowIndex }),
  selectedRow: {
    key: '',
    value: ''
  },
  usePagination: true,
  useSimplePagination: false
};

APTable.contextTypes = {
  t: PropTypes.func.isRequired
};

export default APTable;
