import { connect } from 'react-redux';
import { closeModal } from '../../../redux/actions/modalActions';

export const modalDecorator = ({ name }) => {
  const mapStateToProps = state => {
    if (Object.prototype.hasOwnProperty.call(state.modal, name)) {
      const { isOpen, modalProps } = state.modal[name];

      return {
        isOpen,
        ...modalProps
      };
    }
    return {
      isOpen: false
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      close: () => dispatch(closeModal(name))
    };
  };

  return WrappedComponent => {
    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(WrappedComponent);
  };
};
