import { cloneDeep } from 'lodash';

export const validateImagesUrl = (products, searchFields) => {
  const productsCopy = cloneDeep(products);
  for (let i = 0; i < productsCopy.length; i += 1) {
    const currProduct = productsCopy[i];
    for (let j = i + 1; j < productsCopy.length; j += 1) {
      for (const attribute in productsCopy[j].productAttributes) {
        if (searchFields.includes(attribute)) {
          if (
            currProduct.productAttributes[attribute] ===
            productsCopy[j].productAttributes[attribute]
          ) {
            productsCopy[j].productAttributes[attribute] = `${
              productsCopy[j].productAttributes[attribute]
            }?${j}`;
          }
        }
      }
    }
  }
  return productsCopy;
};
