export const performJsc = () => {
  const sessionId = create_uuid();
  localStorage.setItem('fsSession', sessionId)
  window.threatmetrix.prfl(
    'ddc.worldpay.com',
    'dzppsd1h',
    sessionId,
    '1234'
  );
};
const create_uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
