import React from 'react';
import PropTypes from 'prop-types';

import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { Menu } from './Menu.js';
import './NavTab.css';

export const NavTab = props => {
  const {
    items,
    classModifiers = [],
    customClass = 'custom-tab',
    activeIndex,
    onChange,
    tabsLabel = '',
    additionalComponent = null
  } = props;

  const currentItem = items[activeIndex];
  const classes = getBEMClasses(['tab', customClass]);

  return (
    <div className={`${classes('tab', classModifiers)}`}>
      <div className={`${classes('tab-with-menu', classModifiers)}`}>
        <div className={`${classes('tab-with-menu-label', classModifiers)}`}>
          {!!tabsLabel && (
            <div className={`${classes('tabs-label', classModifiers)}`}>
              {tabsLabel}
            </div>
          )}
          <Menu
            onChange={onChange}
            activeIndex={activeIndex}
            classModifiers={classModifiers}
            items={items}
            classes={classes}
            tabsLabel={tabsLabel}
          />
        </div>
        {!!additionalComponent && additionalComponent}
      </div>

      <div className={classes('tab-content', classModifiers)}>
        {currentItem.content}
      </div>
    </div>
  );
};

NavTab.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
      onSelect: PropTypes.func
    })
  ).isRequired,
  customClass: PropTypes.string,
  classModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  activeIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  additionalComponent: PropTypes.any,
  tabsLabel: PropTypes.string
};
