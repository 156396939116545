import { createSelector } from 'reselect';
import get from 'lodash/get';
import { userConfigSelector } from './userConfigSelector';

export const selectUserTier = createSelector(
  [userConfigSelector],
  userConfig => {
    const currentUserTier = userConfig.find(item => item.Key === 'UserTier');
    if (currentUserTier) {
      return Number(currentUserTier.Value);
    } else {
      return 1;
    }
  }
);

export const selectUserTierWithExp = createSelector(
  state => state.userConfig.userTier,
  userTier => userTier
);

export const showAffiliateProgramSelector = createSelector(
  [selectUserTierWithExp],
  userTierData => {
    return get(userTierData, 'userTier', 1) >= 5;
  }
);
