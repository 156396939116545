import React from 'react';

export const FacebookNavIcon = props => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_9773_3460)">
        <path
          d="M8.5 0.5625C12.9183 0.5625 16.5 4.14422 16.5 8.5625C16.5 12.5555 13.5745 15.8652 9.75 16.4653V10.875H11.6141L11.9688 8.5625H9.75V7.06183C9.75 6.42917 10.0599 5.8125 11.0537 5.8125H12.0625V3.84375C12.0625 3.84375 11.147 3.6875 10.2717 3.6875C8.44434 3.6875 7.25 4.795 7.25 6.8V8.5625H5.21875V10.875H7.25V16.4653C3.42548 15.8652 0.5 12.5555 0.5 8.5625C0.5 4.14422 4.08172 0.5625 8.5 0.5625Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_9773_3460">
          <rect
            width="16"
            height="16"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
