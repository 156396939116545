import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../../helpers/cssClassesHelper';
import { BUTTONS } from './buttonsData';
import APIcon from '../../../common/APIcon';
import { withRouter } from 'react-router-dom';
import './styles.css';

const baseClass = getBEMClasses('co-own-button');

const CoOwnButtonComponent = props => {
  const { assetId, history, isPublic } = props;
  const assetData = BUTTONS.find(asset => asset.id === assetId) || {};
  const {
    text,
    linkPart,
    backgroundImage,
    backgroundColor,
    avatarImage
  } = assetData;
  return (
    <div
      className={baseClass('')}
      style={{
        background: backgroundColor
      }}
      onClick={() => {
        history.push(`${isPublic ? '/public' : ''}/co-own/${linkPart}`);
      }}>
      <img
        src={backgroundImage}
        alt={`background${linkPart}`}
        className={baseClass('background')}
      />
      <div className={baseClass('avatar-with-text')}>
        <img src={avatarImage} alt={linkPart} className={baseClass('avatar')} />
        <div className={baseClass('text')}>{text}</div>
      </div>
      <APIcon name="arrowRight" customClass={baseClass('arrow')} />
    </div>
  );
};

CoOwnButtonComponent.propTypes = {
  assetId: PropTypes.number,
  history: PropTypes.object,
  isPublic: PropTypes.bool
};

export const CoOwnButton = withRouter(CoOwnButtonComponent);
