import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import path from '../../helpers/path';
import SiteLogo from '../../images/site-logo.png';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

import SimpleProblemLoggingInFormContainer from './SimpleProblemLoggingIn/SimpleProblemLoggingInFormContainer';
import './ProblemLoggingInPage.css';
import { APTypography } from '../../components/common/APTypography';
import redirectIfAuthorized from '../../hocs/redirectIfAuthorized';

const problemLoggingInPageClasses = getBEMClasses('problem-logging-in-page');

const ProblemLoggingInPage = (props, context) => (
  <div className={`${problemLoggingInPageClasses('container')}`}>
    <div className={problemLoggingInPageClasses('logo-container')}>
      <img
        className={problemLoggingInPageClasses('logo')}
        src={SiteLogo}
        alt=""
      />
    </div>
    <APTypography
      fontSize="title"
      weight="weight600"
      color="black8"
      className={`${problemLoggingInPageClasses('header-text')}`}>
      {context.t('Problems Logging In?')}
    </APTypography>

    <SimpleProblemLoggingInFormContainer
      showOptions
      useEmailAsUsername={false}
    />

    <div className={`${problemLoggingInPageClasses('footer')}`}>
      <Link to={path('/login')}>
        <APTypography
          fontSize="caption2"
          color="purple2"
          className={problemLoggingInPageClasses('login')}>
          {context.t('Log In')}
        </APTypography>
      </Link>
    </div>
  </div>
);

ProblemLoggingInPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  ProblemLoggingInPage,
  process.env.REACT_APP_DEFAULT_PATH
);
