import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';

import './MyCollectiblesHeader.css';
import { MODAL_TYPES, openModal } from '../../../redux/actions/modalActions';
import { ShareButton } from '../../../components/common/Buttons';

const classes = getBEMClasses('my-collectibles-header');

const MyCollectiblesHeaderComponent = (props, context) => {
  const { accountId, openShareModal } = props;
  const sharePortfolio = () => {
    openShareModal(accountId);
  };
  return (
    <div className={classes()}>
      {context.t('My Collectibles')}
      <ShareButton onClick={sharePortfolio} />
    </div>
  );
};

MyCollectiblesHeaderComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

MyCollectiblesHeaderComponent.propTypes = {
  accountId: PropTypes.number,
  openShareModal: PropTypes.func
};

const mapStateToProps = state => {
  const { userInfo } = state.user;

  return {
    accountId: userInfo.AccountId
  };
};

const mapDispatchToProps = dispatch => ({
  openShareModal: accountId =>
    dispatch(
      openModal(MODAL_TYPES.SHARE_PROFILE_MODAL, {
        shareUrl: `${window.location.origin}/user-portfolio/${accountId}`,
        accountId
      })
    )
});

export const MyCollectiblesHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyCollectiblesHeaderComponent);
