import { REQUEST_TRADES, RECEIVE_TRADES } from '../actions/tradesActions';

const initialState = {
  trades: [],
  fetching: false
};

export default function tradesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_TRADES:
      return {
        ...state,
        fetching: true
      }
    case RECEIVE_TRADES:
      return {
        ...state,
        trades: payload.trades,
        fetching: false
      };
    default:
      return state;
  }
}
