import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import SimpleProblemLoggingInFormComponent from './SimpleProblemLoggingInFormComponent';
import {
  sendResetPasswordEmail,
  resendVerificationEmail
} from 'apex-web/lib/redux/actions/userActions';

const SimpleProblemLoggingInForm = reduxForm({
  form: 'forgotPassword',
  onSubmit: (payload, dispatch) => {
    const { resetPassword, Email } = payload;
    if (resetPassword) return dispatch(sendResetPasswordEmail(Email));

    return dispatch(resendVerificationEmail({ Email }));
  },
  enableReinitialize: true
})(SimpleProblemLoggingInFormComponent);

const mapDispatchToProps = {
  sendResetPasswordEmail,
  resendVerificationEmail
};

const SimpleProblemLoggingInFormContainer = connect(
  null,
  mapDispatchToProps
)(SimpleProblemLoggingInForm);

export default SimpleProblemLoggingInFormContainer;
