import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './PendingOrder.css';

const classes = getBEMClasses('pending-order');

const PendingOrder = (props, context) => {
  return (
    <React.Fragment>
      <div className={classes('loading-wrapper')}
        data-test='loadingRoot'>
        <Spinner
          text={context.t('Placing your order...')}
          customClass={classes()}
          isInline />
      </div>
    </React.Fragment>
  );
};

PendingOrder.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PendingOrder;
