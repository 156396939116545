import {
  REQUEST_VOTE_CARDS,
  RECEIVE_VOTE_CARDS,
  MOVE_CARD_FROM_COUNTDOWN_TO_LIVE_VOTE,
  SET_VOTE_RESULT
} from '../actions/voteActions';

const initialState = {
  liveVoteCards: [],
  countdownVoteCards: [],
  resultVoteCards: [],
  fetching: true
};

export default function voteReducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_VOTE_CARDS:
      return {
        ...state,
        fetching: true
      };
    case RECEIVE_VOTE_CARDS:
      return {
        ...state,
        liveVoteCards: [...state.liveVoteCards, ...payload.liveVotings],
        countdownVoteCards: [
          ...state.countdownVoteCards,
          ...payload.countdownVotings
        ],
        resultVoteCards: [...state.resultVoteCards, ...payload.resultVotings],
        fetching: false
      };
    case MOVE_CARD_FROM_COUNTDOWN_TO_LIVE_VOTE: {
      const newLiveVoteCard = state.countdownVoteCards.find(
        item => item.id === payload
      );
      return {
        ...state,
        countdownVoteCards: state.countdownVoteCards.filter(
          i => i.id !== payload
        ),
        liveVoteCards: [...state.liveVoteCards, newLiveVoteCard]
      };
    }
    case SET_VOTE_RESULT: {
      const liveVoteCardWithResults = state.liveVoteCards.map(item => {
        if (item.id === payload.id) {
          return {
            ...item,
            ...payload
          };
        }
        return item;
      });
      return {
        ...state,
        liveVoteCards: liveVoteCardWithResults
      };
    }
    default:
      return state;
  }
}
