/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

import Tab from 'apex-web/lib/components/common/Tab/Tab';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

const tradingLayoutClasses = getBEMClasses('trading-layout');

const TradingLayoutCharts1280Px = (props, context) => {
  const { PriceChart, DepthChart, OrderBook, showDepthChart } = props;

  const _getPanes = () => {
    const chartsArray = [
      {
        menuItem: context.t('Price Chart'),
        render: () => <PriceChart />
      }
    ];
    if (showDepthChart) {
      chartsArray.push({
        menuItem: context.t('Depth Chart'),
        render: () => <DepthChart smallScreen />
      });
    }
    if (OrderBook) {
      chartsArray.push({
        menuItem: context.t('Order Book'),
        render: () => <OrderBook />
      });
    }

    return chartsArray;
  };

  return (
    <div className={tradingLayoutClasses('chart-tabs-container')}>
      <Tab panes={_getPanes()} customClass="trading-layout" />
    </div>
  );
};

TradingLayoutCharts1280Px.propTypes = {
  PriceChart: PropTypes.func.isRequired,
  DepthChart: PropTypes.func.isRequired,
  OrderBook: PropTypes.func,
  showDepthChart: PropTypes.bool.isRequired
};

TradingLayoutCharts1280Px.contextTypes = {
  t: PropTypes.func.isRequired
};

export default TradingLayoutCharts1280Px;
