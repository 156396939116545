import LoganAvatar from './images/LoganAvatar.png';
import DarrenAvatar from './images/DarrenAvatar.png';
import SteveAvatar from './images/SteveAvatar.png';
import LoganAsset1 from './images/LoganAsset1.png';
import LoganAsset2 from './images/LoganAsset2.png';
import LoganAsset3 from './images/LoganAsset3.png';
import DarrenAsset1 from './images/DarrenAsset1.png';
import SteveAsset1 from './images/SteveAsset1.png';
import SteveAsset2 from './images/SteveAsset1.png';

export const BUTTONS = [
  {
    idStg: 1042499,
    idProd: 35,
    backgroundImage: LoganAsset3,
    avatarImage: LoganAvatar,
    backgroundColor: '#A08B2E',
    linkPart: 'logan-paul',
    text: 'Co-own with Logan Paul'
  },
  {
    idStg: 4,
    idProd: 13,
    backgroundImage: LoganAsset1,
    avatarImage: LoganAvatar,
    backgroundColor: '#545281',
    linkPart: 'logan-paul',
    text: 'Co-own with Logan Paul'
  },
  {
    idStg: 14,
    idProd: 31,
    backgroundImage: LoganAsset2,
    avatarImage: LoganAvatar,
    backgroundColor: '#1F2336',
    linkPart: 'logan-paul',
    text: 'Co-own with Logan Paul'
  },
  {
    idStg: 13,
    idProd: 25,
    backgroundImage: DarrenAsset1,
    avatarImage: DarrenAvatar,
    backgroundColor: '#878359',
    linkPart: 'darren-jack',
    text: 'Co-own with darren jack'
  },
  {
    idStg: 8,
    idProd: 26,
    backgroundImage: SteveAsset1,
    avatarImage: SteveAvatar,
    backgroundColor: '#5BA6AF',
    linkPart: 'steve-aoki',
    text: 'Co-own with steve aoki'
  },
  {
    idStg: 9,
    idProd: 27,
    backgroundImage: SteveAsset2,
    avatarImage: SteveAvatar,
    backgroundColor: '#D46E00',
    linkPart: 'steve-aoki',
    text: 'Co-own with steve aoki'
  }
];
