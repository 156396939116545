import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import elementResizeDetectorMaker from 'element-resize-detector';
import BigNumber from 'bignumber.js';
import Joyride, { STATUS, ACTIONS } from 'react-joyride';
import differenceInDays from 'date-fns/differenceInDays';
import { logout } from 'apex-web/lib/redux/actions/authActions';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './InteriorPage.css';

import { setIsOnboardingWasShown } from '../../redux/actions/userActions';
import {
  selectIsOnboardingWasShown,
  selectIsUserConfigFetched
} from '../../redux/selectors/userConfigSelector';
import PageHeaderLayout from '../../layouts/PageHeaderLayout/PageHeaderLayout';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import DashboardPage from '../DashboardPage/DashboardPage';
import WalletsPage from '../WalletsPage/WalletsPage';
import SettingsPage from '../SettingsPage/SettingsPage';
import { PageHeaderNavComponent } from '../../components/PageHeader/PageHeaderNavComponent';
import { MyCollectiblesPage } from '../MyCollectiblesPage/MyCollectiblesPage';
import { OfferingsPage } from '../OfferingsPage/OfferingsPage';
import { ProExchangeLayout } from '../../layouts/ProExchangeLayout/ProExchangeLayout';
import { useIsMobile, useIsTablet } from '../../hooks/useIsMobile';
import { MarketPlaceAssetDetailsPage } from '../MarketPlaceAssetDetailsPage/MarketPlaceAssetDetailsPage';
import { loganPaulPageProps } from '../CoOwnPage/LoganPaulPage/pageProps';
import { steveAokiPageProps } from '../CoOwnPage/SteveAokiPage/pageProps';
import { darrenJackPageProps } from '../CoOwnPage/DarrenJackPage/pageProps';
import { CoOwnPage } from '../CoOwnPage';
import BuySellModal from '../../components/BuySellModal';
import AffiliateModal from '../../components/AffiliateModal';
import { TiersExplainedPage } from '../TiersExplainedPage/TiersExplainedPage';
import OnboardingTooltip from '../../components/OnboardingTooltip';
import { OnboardingOverlay } from '../../components/OnboardingOverlay';
import { VerificationReminderModal } from '../../components/VerificationRemindModal/VerificationReminderModal';
import { setIsVerificationInfoReceived } from '../../redux/actions/userActions';
import {
  selectIsChromeOnMac,
  selectIsMac
} from '../../helpers/browserDetectHelper';
import { CommunityPage } from '../CommunityPage/CommunityPage';
import { VotePage } from '../VotePage/VotePageContainer';
import { showAffiliateProgramSelector } from '../../redux/selectors/userTierSelector';
import { selectIsTermsOfUseAccepted } from '../../redux/selectors/userConfigSelector';
import withAuthentication from '../../hocs/withAuthentication';
import { zdButton } from '../../helpers/zendeskHelper';
import { performJsc } from '../../helpers/fingerprinting';
import UpdateTermsOfUseModalModal from '../../components/UpdateTermsOfUseModal';
import { MODAL_TYPES, openModal } from '../../redux/actions/modalActions';

const interiorClasses = getBEMClasses('interior');

const NAV_ITEMS = [
  {
    text: 'Dashboard',
    icon: 'dashboard',
    path: '/dashboard',
    id: 'dashboard-link',
    highlightUrl: '/dashboard'
  },
  {
    text: 'Offerings',
    icon: 'buy-sell',
    path: '/offerings',
    id: 'offerings-link',
    highlightUrl: '/offerings'
  },
  {
    text: 'MarketPlace',
    icon: 'dashboardMarketPlace',
    path: '/pro-marketplace',
    id: 'market-place-link',
    highlightUrl: '/pro-marketplace'
  },
  {
    text: 'Vote',
    icon: 'vote',
    path: '/vote-page',
    id: 'vote-link',
    highlightUrl: '/vote-page'
  },
  {
    text: 'My Collectibles',
    icon: 'balance',
    path: '/my-collectibles',
    id: 'my-collectibles-link',
    highlightUrl: '/my-collectibles'
  },
  {
    text: 'Community',
    icon: 'community',
    path: '/community',
    id: 'community-link',
    highlightUrl: '/community'
  },
  {
    text: 'Wallets',
    icon: 'wallet',
    path: '/wallets',
    id: 'wallets-link',
    highlightUrl: '/wallets'
  },
  {
    text: 'Settings',
    icon: 'settings',
    path: '/settings/user',
    highlightUrl: '/settings',
    id: 'settings-link'
  }
];

const InteriorPage = (props, context) => {
  const {
    isOnboardingWasShown,
    isUserConfigFetched,
    setIsOnboardingWasShown,
    verificationLevel,
    createdAt,
    setIsVerificationInfoReceived,
    logout,
    isReferralButtonShown,
    isTermsOfUseAccepted,
    openModal
  } = props;
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [sidePanelWidth, setSidePanelWidth] = useState(
    isMobile || isTablet ? 0 : 221
  );
  const [navBarExpanded, setNavBarExpanded] = useState(true);
  const [mobileNavBarExpanded, setMobileNavbarExpanded] = useState(false);
  const [showVerificationReminder, setShowVerificationReminder] = useState();

  useEffect(() => {
    performJsc();
  }, []);

  useEffect(
    () => {
      if (isMobile || isTablet) {
        setSidePanelWidth(0);
        return;
      }
      const erd = elementResizeDetectorMaker({
        strategy: 'scroll' //<- For ultra performance.
      });
      const res = document.getElementById('navigationContainer');
      if (res) {
        erd.listenTo(res, element => {
          const width = element.clientWidth;
          setSidePanelWidth(width || 0);
        });
      }
      setTimeout(() => {
        const isNavBarExpanded = localStorage.getItem('navBarExpanded');
        if (isNavBarExpanded === 'false') {
          setNavBarExpanded(false);
        }
      }, 0);
      return () => {
        if (res) {
          erd.uninstall(res);
        }

        if (window.zE) {
          window.zE('messenger', 'close');
        }
      };
    },
    [isMobile, isTablet]
  );

  useEffect(
    () => {
      setTimeout(() => {
        localStorage.setItem('navBarExpanded', navBarExpanded);
      }, 100);
    },
    [navBarExpanded]
  );

  useEffect(
    () => {
      if (typeof verificationLevel !== 'undefined' && verificationLevel < 1) {
        setIsVerificationInfoReceived();
        const joinDate = new Date(createdAt);
        const daysPassed = differenceInDays(new Date(), joinDate);
        if (new BigNumber(daysPassed).gt(7)) {
          setShowVerificationReminder(false);
        }
      }
    },
    [verificationLevel, createdAt]
  );

  useEffect(
    () => {
      if (
        !isTermsOfUseAccepted &&
        !isShowOnboardingTutorial &&
        !isRunOnboarding
      ) {
        openModal(MODAL_TYPES.UPDATE_TERMS_OF_USE_MODAL);
      }
    },
    [isTermsOfUseAccepted, window.location.pathname]
  );

  const [isRunOnboarding, setIsRunOnboarding] = useState(false);
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [isShowOnboardingTutorial, setIsShowOnboardingTutorial] = useState(
    false
  );

  useEffect(
    () => {
      if (
        !isOnboardingWasShown &&
        isUserConfigFetched &&
        !isMobile &&
        !isTablet
      ) {
        setIsShowOnboardingTutorial(true);
      }
    },
    [isOnboardingWasShown, isUserConfigFetched, isMobile, isTablet]
  );

  const handleShowOnboarding = () => {
    if (!navBarExpanded) {
      setNavBarExpanded(true);
    }

    setTimeout(() => {
      setIsShowOnboardingTutorial(false);
      setIsRunOnboarding(true);
    }, 100);
  };

  const handleSkipOnboarding = () => {
    setIsShowOnboardingTutorial(false);
    setIsOnboardingWasShown();
  };

  const handleJoyrideCallback = data => {
    const { action, status } = data;

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      action === ACTIONS.CLOSE ||
      (action === ACTIONS.STOP && [STATUS.PAUSED].includes(status))
    ) {
      setIsRunOnboarding(false);
      window.zE('messenger', 'close');
    }
  };

  const isChromeOnMac = selectIsChromeOnMac();
  const isMac = selectIsMac();

  return (
    <React.Fragment>
      <div
        className={interiorClasses('container', {
          'mobile-nav': mobileNavBarExpanded
        })}>
        {isShowOnboardingTutorial && (
          <OnboardingOverlay
            handleShowOnboarding={handleShowOnboarding}
            handleSkipOnboarding={handleSkipOnboarding}
          />
        )}
        {isRunOnboarding && (
          <Joyride
            run={isRunOnboarding}
            callback={handleJoyrideCallback}
            disableScrollParentFix
            scrollOffset={140}
            stepIndex={onboardingStep}
            steps={[
              {
                target: '#dashboard-page',
                disableBeacon: true,
                content: context.t(
                  'The Dashboard gives you an overview of your account, including your collection, wallet, upcoming Offerings, and more.'
                ),
                style: {
                  spotlight: {
                    top: '500px',
                    borderRadius: '20px'
                  }
                },
                placement: 'bottom',
                arrowPlacement: 'hide'
              },
              {
                target: '#nav-menu',
                disableBeacon: true,
                content: context.t(
                  'The Menu on the left links to all your pages.\n\nHit the toggle to open or close the Menu.\n\nClick the bottom button to switch between Light and Dark mode.'
                ),
                spotlightPadding: 10,
                placement: 'right',
                arrowPlacement: 'left-top',
                customWrapperStyle: { marginLeft: '40px' }
              },
              {
                target: '#offerings',
                disableBeacon: true,
                content: context.t(
                  'This is where all our Offerings appear.\n\nHere you’ll be able to buy and co-own new items and see info on upcoming assets.'
                ),
                styles: {
                  spotlight: {
                    borderRadius: '20px',
                    marginTop: isChromeOnMac ? '33px' : '0px'
                  }
                },
                placement: 'top',
                arrowPlacement: 'top-right',
                customWrapperStyle: { marginTop: '25px' }
              },
              {
                target: '#market-place',
                disableBeacon: true,
                content:
                  'If you missed out on items in the Offering, no stress. Here in the Marketplace, you can buy and sell ownership, peer to peer, 24/7!',
                styles: {
                  spotlight: {
                    borderRadius: '20px',
                    marginTop: isChromeOnMac ? '15px' : '0px'
                  }
                },
                placement: 'bottom',
                arrowPlacement: 'bottom-right',
                customWrapperStyle: { bottom: '45px' }
              },
              {
                target: '#my-collectibles',
                disableBeacon: true,
                content: `View and admire everything you co-own in 'My Collectibles'. A shareable link allows you to show off what you own.`,
                styles: {
                  spotlight: {
                    borderRadius: '20px',
                    marginTop: isChromeOnMac ? '81px' : '0px'
                  }
                },
                placement: 'top',
                arrowPlacement: 'top-right',
                customWrapperStyle: { marginTop: '25px' }
              },
              {
                target: '#wallet',
                disableBeacon: true,
                content: context.t(
                  "See how much is in your Liquid Wallet, what assets you hold and how much USD you have available. You'll also find transaction history here."
                ),
                styles: {
                  spotlight: {
                    borderRadius: '20px',
                    marginTop: isChromeOnMac ? '50px' : '0px'
                  }
                },
                placement: 'left',
                arrowPlacement: 'right-top',
                customWrapperStyle: {
                  marginRight: '45px'
                }
              },
              {
                target: `#${zdButton.iframeId}`,
                disableBeacon: true,
                content: context.t('Need more help?\n\nI’m just a click away.'),
                spotlightPadding: 20,
                styles: {
                  spotlight: {
                    marginTop: isMac ? '102px' : '0px',
                    marginLeft: isMac ? '185px' : '0px'
                  }
                },
                placement: 'top',
                arrowPlacement: 'bottom-right',
                customWrapperStyle: {
                  bottom: isMac ? '-50px' : '45px',
                  right: isMac ? '-35px' : '15px'
                }
              },
              {
                target: '#support-chat-stub',
                disableBeacon: true,
                content: context.t(
                  'You can also find me in our live chat, where I’m available day or night.'
                ),
                spotlightPadding: 0,
                placement: 'left',
                arrowPlacement: 'right-top',
                customArrowStyle: {},
                customWrapperStyle: { bottom: '74px', right: '300px' }
              }
            ]}
            tooltipComponent={props => (
              <OnboardingTooltip
                {...props}
                onCloseTooltip={() => {
                  setIsRunOnboarding(false);
                  setIsOnboardingWasShown();
                }}
                goToStep={step => setOnboardingStep(step)}
              />
            )}
            spotlightPadding={0}
            floaterProps={{ disableAnimation: true, hideArrow: true }}
          />
        )}

        <PageHeaderLayout
          navBarExpanded={navBarExpanded}
          mobileNavBarExpanded={mobileNavBarExpanded}
          setMobileNavbarExpanded={setMobileNavbarExpanded}
          navItems={NAV_ITEMS}
          verificationLevel={verificationLevel}
          createdAt={createdAt}
          logout={logout}
        />
        {!isMobile &&
          !isTablet && (
            <PageHeaderNavComponent
              navItems={NAV_ITEMS}
              navBarExpanded={navBarExpanded}
              setNavBarExpanded={setNavBarExpanded}
              logout={logout}
            />
          )}

        <div
          className={interiorClasses(
            'main-content',
            isReferralButtonShown && 'with-referral-button'
          )}
          style={{
            marginLeft: sidePanelWidth
          }}>
          <Switch>
            <Route exact path={'/dashboard'} component={DashboardPage} />
            <Route exact path={'/wallets'} component={WalletsPage} />
            <Route exact path={'/market-place'}>
              <Redirect to={'/pro-marketplace'} />
            </Route>
            <Route path={'/pro-marketplace'} component={ProExchangeLayout} />
            <Route
              path={[
                '/market-place/asset-details/:assetId',
                '/offerings/asset-details/:assetId'
              ]}
              component={MarketPlaceAssetDetailsPage}
            />
            <Route path="/co-own/logan-paul">
              <CoOwnPage {...loganPaulPageProps} />
            </Route>
            <Route path="/co-own/steve-aoki">
              <CoOwnPage {...steveAokiPageProps} />
            </Route>
            <Route path="/co-own/darren-jack">
              <CoOwnPage {...darrenJackPageProps} />
            </Route>
            <Route path={'/settings'} component={SettingsPage} />
            <Route
              exact
              path={'/my-collectibles'}
              component={MyCollectiblesPage}
            />
            <Route
              path={'/my-collectibles/asset-details/:assetId'}
              component={MarketPlaceAssetDetailsPage}
            />
            <Route exact path={'/offerings'} component={OfferingsPage} />

            {/* Investor portal Routes */}

            <Route exact path={'/tiers'} component={TiersExplainedPage} />
            <Route exact path={'/community'} component={CommunityPage} />
            <Route path={'/vote-page'} component={VotePage} />
            <Route>
              <Redirect to={'/not-found'} />
            </Route>
          </Switch>

          <PageFooterLayout />
        </div>
      </div>
      <BuySellModal />
      <AffiliateModal />
      <VerificationReminderModal
        isOpened={
          isShowOnboardingTutorial || isRunOnboarding
            ? false
            : showVerificationReminder
        }
      />
      <UpdateTermsOfUseModalModal />
    </React.Fragment>
  );
};

InteriorPage.contextTypes = {
  t: PropTypes.func.isRequired
};

InteriorPage.propTypes = {
  isOnboardingWasShown: PropTypes.bool,
  isUserConfigFetched: PropTypes.bool,
  setIsOnboardingWasShown: PropTypes.func,
  verificationLevel: PropTypes.number,
  createdAt: PropTypes.string,
  setIsVerificationInfoReceived: PropTypes.func,
  logout: PropTypes.func,
  isReferralButtonShown: PropTypes.bool,
  isTermsOfUseAccepted: PropTypes.bool,
  openModal: PropTypes.func
};

const mapStateToProps = state => {
  const isOnboardingWasShown = selectIsOnboardingWasShown(state);
  const isUserConfigFetched = selectIsUserConfigFetched(state);
  const isTermsOfUseAccepted = selectIsTermsOfUseAccepted(state);
  const { user } = state;
  const accountId = user && user.userInfo.AccountId;
  const createdAt = user && user.userInfo.DateTimeCreated;
  const account = user && user.accounts.find(i => i.AccountId === accountId);

  return {
    isOnboardingWasShown,
    isUserConfigFetched,
    verificationLevel: account && account.VerificationLevel,
    createdAt,
    isReferralButtonShown: showAffiliateProgramSelector(state),
    isTermsOfUseAccepted
  };
};

const mapDispatchToProps = {
  logout,
  setIsOnboardingWasShown,
  setIsVerificationInfoReceived,
  openModal
};

const InteriorPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InteriorPage);

export default withAuthentication(InteriorPageContainer, {
  '/co-own': '/signup'
});
