import { createSelector } from 'reselect';
import { selectAssetsWithAttributes } from './productsSelector';

export const selectCardsForVote = createSelector(
  [selectAssetsWithAttributes],
  products => {
    const usersProduct = [];
    products.forEach(item => {
      const productAttributes = item.product.productAttributes;
      if (
        productAttributes &&
        Object.values(productAttributes).length
        // productAttributes.onVote &&
        // productAttributes.onVote.toLowerCase() === 'true'
      )
        usersProduct.push({ ...item.product, Amount: item.Amount });
    });

    return usersProduct;
  }
);

const formatVoteCards = (voteCards, allCards) => {
  const filteredVoteCards = voteCards.filter(
    card => !!allCards.find(item => item.ProductId === card.productId)
  );
  return filteredVoteCards.map(i => {
    const card = allCards.find(item => item.ProductId === i.productId);
    return {
      ...card,
      voteAttributes: i
    };
  });
};

export const selectCountdownVotingCards = createSelector(
  [state => state.vote.countdownVoteCards, selectCardsForVote],
  (countdownVoteCards, cardsForVote) => {
    return formatVoteCards(countdownVoteCards, cardsForVote);
  }
);

export const selectLiveVotingCards = createSelector(
  [state => state.vote.liveVoteCards, selectCardsForVote],
  (liveVoteCards, cardsForVote) => {
    return formatVoteCards(liveVoteCards, cardsForVote);
  }
);

export const selectResultVotingCards = createSelector(
  [state => state.vote.resultVoteCards, selectCardsForVote],
  (resultVoteCards, cardsForVote) => {
    return formatVoteCards(resultVoteCards, cardsForVote);
  }
);
