import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './ErrorMessage.css';

const baseClasses = 'error';

export const ErrorMessage = props => {
  const { errors, name, customClass } = props;
  const classes = getBEMClasses(baseClasses, customClass);
  if (isEmpty(errors)) return null;
  const error = errors[name];
  if (!error) return null;
  return <p className={classes('message')}>{error.message}</p>;
};

ErrorMessage.propTypes = {
  errors: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  customClass: PropTypes.string
};
