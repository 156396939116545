import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { getBEMClasses } from '../../../../helpers/cssClassesHelper';
import SwitchWithReduxForm from '../../../common/SwitchWithReduxForm';
import ContentWithHeaderLayoutTemplate from '../../../common/LayoutTemplates/ContentWithHeaderLayoutTemplate/ContentWithHeaderLayoutTemplate';
import {
  getAccountPublicProfile,
  setAccountPublicProfile
} from '../../../../redux/actions/accountActions';
import { selectIsHiddenFromTop10 } from '../../../../redux/selectors/userConfigSelector';
import { setIsHiddenFromTop10 } from '../../../../redux/actions/userActions';

import './PublicProfileCard.css';

const classes = getBEMClasses('public-profile-card');

var profileAndSecurityWidgetClasses = getBEMClasses([
  'profile-and-security-widget'
]);

const PublicProfileCardComponent = (props, context) => {
  const {
    publicProfile,
    userInfo,
    disablePublicProfile,
    enablePublicProfile,
    getAccountConfig,
    isHiddenFromTop10,
    setIsHiddenFromTop10
  } = props;

  useEffect(
    () => {
      if (userInfo.AccountId) {
        getAccountConfig(userInfo.AccountId);
      }
    },
    [userInfo]
  );

  const getLayoutHeader = function getLayoutHeader(header, classes) {
    return React.createElement(
      'div',
      { className: classes('header') },
      React.createElement('div', { className: classes('header-text') }, header)
    );
  };

  const handleClickOnPublicProfile = () => {
    if (publicProfile) {
      disablePublicProfile(userInfo.AccountId);
    } else {
      enablePublicProfile(userInfo.AccountId);
    }
  };

  const handleClickOnTop10Hide = () => {
    setIsHiddenFromTop10(!isHiddenFromTop10);
  };
  return (
    <ContentWithHeaderLayoutTemplate
      header={getLayoutHeader(
        context.t('Public Profile'),
        profileAndSecurityWidgetClasses
      )}
      customClass={'profile-and-security-widget'}
      classModifiers={['sm']}>
      <div className={classes()}>
        <div id="PublicProfileSwitch" className={classes('button-wrapper')}>
          <SwitchWithReduxForm
            name="publicProfile"
            onClick={handleClickOnPublicProfile}
          />
        </div>
        <div className={classes('info')}>
          <div className={classes('info-text')}>
            If your profile is public, your account snapshot is visible to all
            users.
          </div>
          <div className={classes('info-text')}>
            Currently, your profile is {publicProfile ? 'public' : 'not public'}
          </div>
        </div>
      </div>
      <div className={classes()}>
        <div id="Top10HideSwitch" className={classes('button-wrapper')}>
          <SwitchWithReduxForm
            name="isHiddenFromTop10"
            onClick={handleClickOnTop10Hide}
          />
        </div>
        <div className={classes('info')}>
          <div className={classes('info-text')}>
            If this is turned on, your token information is visible for our ‘Top
            10‘ ranking purposes.
          </div>
          <div className={classes('info-text')}>
            Currently, this is {isHiddenFromTop10 ? 'enabled' : 'disabled'}
          </div>
        </div>
      </div>
    </ContentWithHeaderLayoutTemplate>
  );
};

PublicProfileCardComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

PublicProfileCardComponent.propTypes = {
  publicProfile: PropTypes.bool,
  userInfo: PropTypes.object,
  disablePublicProfile: PropTypes.func,
  enablePublicProfile: PropTypes.func,
  getAccountConfig: PropTypes.func,
  isHiddenFromTop10: PropTypes.bool,
  setIsHiddenFromTop10: PropTypes.func
};

const mapStateToProps = state => {
  const {
    user: { userInfo },
    accountConfig
  } = state;

  const { publicProfile } = accountConfig;
  const isHiddenFromTop10 = selectIsHiddenFromTop10(state);

  return {
    userInfo,
    publicProfile,
    isHiddenFromTop10,
    initialValues: {
      publicProfile: publicProfile,
      isHiddenFromTop10
    }
  };
};

const mapDispatchToProps = dispatch => ({
  enablePublicProfile: async accountId => {
    dispatch(setAccountPublicProfile({ accountId, publicProfile: true }));
  },
  disablePublicProfile: async accountId => {
    dispatch(setAccountPublicProfile({ accountId, publicProfile: false }));
  },
  getAccountConfig: accountId => {
    dispatch(getAccountPublicProfile({ accountId }));
  },
  setIsHiddenFromTop10: isHiddenFromTop10 => {
    dispatch(setIsHiddenFromTop10(isHiddenFromTop10));
  }
});

const PublicProfileCardForm = reduxForm({
  form: 'publicProfileCard',
  enableReinitialize: true
})(PublicProfileCardComponent);

export const PublicProfileCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicProfileCardForm);
