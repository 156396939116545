import { connect } from 'react-redux';
import { change, reduxForm, untouch } from 'redux-form';
import OrderEntryFormComponent from './OrderEntryFormComponent';
import {
  buyValue,
  orderTypes
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { isProductSoldOutSelector, userBalanceByProductSelector } from '../../redux/selectors/productsSelector';

import {
  isProductByDefaultScenario,
  isProductByScriptScenario
} from '../../redux/selectors/productsSelector';
import { lastPriceSelector } from '../../redux/selectors/instrumentSelector';
import BigNumber from 'bignumber.js';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import { openBuySellModal } from '../../redux/actions/buySellModalActions';
import { calcOrderEntryFee, calcOrderEntryMaxUsdTotal } from '../../redux/actions/orderActions';
import { selectIsTermsOfUseAccepted } from '../../redux/selectors/userConfigSelector';

const FormWrapper = reduxForm({
  form: 'orderEntry',
  destroyOnUnmount: false, // keeps data if width change causes render of another trading layout
  forceUnregisterOnUnmount: true,
  onSubmit: (payload, dispatch, props) => {
    dispatch(
      openBuySellModal({
        shouldStartOnConfirm: true,
        ...payload,
      })
    );
    props.afterSubmit && props.afterSubmit();
  }
})(OrderEntryFormComponent);

const mapStateToProps = (state, ownProps) => {
  const { form } = state;

  const fetching = state.apexCore.instrument.fetching ||
    state.apexCore.level2.fetching ||
    state.apexCore.position.fetching;

  const selectedInstrument = selectedInstrumentSelector(state);
  const orderEntryForm = form.orderEntry || { values: {} };

  const { disableTrading, verificationLevel } = kycVerificationLevelsSelector(
    state
  );
  const onlyMarketAvailable = isProductByDefaultScenario(
    state,
    selectedInstrument.Product1
  );
  const onlyLimitAvailable = isProductByScriptScenario(
    state,
    selectedInstrument.Product1
  );
  const isSellDisable = onlyMarketAvailable || onlyLimitAvailable;

  const isProductSoldOut = isProductSoldOutSelector(
    state,
    selectedInstrument.Product1
  );

  let lastPrice;
  if (!fetching) {
    lastPrice = BigNumber(lastPriceSelector(state))
      .dp(
        convertIncrementToIntDecimalPlaces(selectedInstrument.PriceIncrement),
        BigNumber.ROUND_HALF_UP
      )
      .toNumber();
  }

  const usdBalance = fetching ?
    0 :
    BigNumber(userBalanceByProductSelector(state, selectedInstrument.Product2))
      .dp(
        convertIncrementToIntDecimalPlaces(selectedInstrument.PriceIncrement),
        BigNumber.ROUND_FLOOR
      );

  const tokenBalance = fetching ?
    0 :
    BigNumber(userBalanceByProductSelector(state, selectedInstrument.Product1))
      .dp(
        convertIncrementToIntDecimalPlaces(selectedInstrument.QuantityIncrement),
        BigNumber.ROUND_FLOOR
      );

  let level2;
  if (!fetching) {
    level2 = state.apexCore.level2;
  }

  return {
    orderEntryForm,
    initialValues: {
      orderType: onlyMarketAvailable ?
        orderTypes.market.displayName :
        orderTypes.limit.displayName,
      side: ownProps.initialSide || buyValue,
      limitPrice: lastPrice,
      stopPrice: lastPrice,
      timeInForce: '1',
      fee: null,
      maxUsdTotal: null
    },
    selectedInstrument,
    lastPrice,
    usdBalance,
    tokenBalance,
    level2,
    fetching,
    disableTrading:
      verificationLevel === 99 ? false : disableTrading || !isProductSoldOut,
    isSellDisable: verificationLevel === 99 ? false : isSellDisable,
    onlyMarketAvailable: verificationLevel === 99 ? false : onlyMarketAvailable,
    onlyLimitAvailable: verificationLevel === 99 ? false : onlyLimitAvailable,
    isTermsOfUseAccepted: selectIsTermsOfUseAccepted(state)
  };
};

const mapDispatchToProps = dispatch => ({
  updateQuantity: value => dispatch(change('orderEntry', 'quantity', value)),
  resetQuantity: () => {
    dispatch(change('orderEntry', 'quantity', ''));
    dispatch(untouch('orderEntry', 'quantity'));
  },
  updateStopPrice: value => dispatch(change('orderEntry', 'stopPrice', value)),
  updateLimitPrice: value => dispatch(change('orderEntry', 'limitPrice', value)),
  calcFee: () => dispatch(calcOrderEntryFee()),
  calcMaxUsdTotal: orders => dispatch(calcOrderEntryMaxUsdTotal(orders)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormWrapper);
