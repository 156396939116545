import { connect } from 'react-redux';

import { openModal, MODAL_TYPES } from '../../../redux/actions/modalActions';
import { VoteButtonsComponent } from './VoteButtons';

const mapDispatchToProps = dispatch => ({
  openVoteModal: props => {
    dispatch(openModal(MODAL_TYPES.VOTE_MODAL, props));
  }
});

export const VoteButtons = connect(
  null,
  mapDispatchToProps
)(VoteButtonsComponent);
