import { connect } from 'react-redux';
import AffiliateActiveTagComponent from './AffiliateActiveTagComponent';
import { simpleUpdateAffiliateTag } from '../../redux/actions/affiliateActions';

const mapStateToProps = (state, ownProps) => {
  const route = ownProps.route;
  return {
    route,
    affiliate: state.affiliate
  };
};

const mapDispatchToProps = {
  onSubmit: tag => simpleUpdateAffiliateTag({ tag })
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AffiliateActiveTagComponent);
