import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { loadDots } from '@dots.dev/dots-js';
import BigNumber from 'bignumber.js';

import './DepositFromCard.css';

import {
  MODAL_TYPES,
  openModal
} from '../../../../../../redux/actions/modalActions';
import { PaymentDetailsContainer } from './PaymentDetails';
import { CardDetails } from './CardDetails';
import { VerificationReminderModal } from '../../../../../VerificationRemindModal/VerificationReminderModal';

export const dotsPromise = loadDots(
  'pk_prod_wBGmvAWGumtPKF0zKUyBfrOwWyqQh',
  'production'
);

const DepositFromCard = props => {
  const {
    userId,
    closeModal,
    openDepositModal,
    timer,
    firstName,
    lastName,
    verificationLevel
  } = props;

  const [amount, setAmount] = useState(0);
  const [requiredAction, setRequiredAction] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [showVerificationReminder, setShowVerificationReminder] = useState();
  const [depositCountdown, setDepositCountdown] = useState(null);

  useEffect(() => {
    if (typeof verificationLevel !== 'undefined' && verificationLevel < 1) {
      setShowVerificationReminder(true);
    }
  }, []);

  useEffect(() => {
    const depositCountdown = window.localStorage.getItem('DEPOSIT_COUNTDOWN');
    if (depositCountdown) {
      setDepositCountdown(window.localStorage.getItem('DEPOSIT_COUNTDOWN'));
    }
  }, []);

  const setCountdownAfterDeposit = () => {
    const now = JSON.stringify(new Date());
    setDepositCountdown(now);
    window.localStorage.setItem('DEPOSIT_COUNTDOWN', now);
  };

  const finalAmount = () => {
    return +new BigNumber(amount)
      .div(100)
      .decimalPlaces(2, 2)
      .times(100)
      .toNumber();
  };

  return (
    <React.Fragment>
      <div>
        {!clientSecret ? (
          <PaymentDetailsContainer
            setClientSecret={setClientSecret}
            setRequiredAction={setRequiredAction}
            userId={userId}
            closeModal={closeModal}
            setAmount={setAmount}
            finalAmount={finalAmount()}
            amount={amount}
            openDepositModal={openDepositModal}
            timer={timer}
            depositCountdown={depositCountdown}
          />
        ) : (
          <CardDetails
            clientSecret={clientSecret}
            requiredAction={requiredAction}
            closeModal={closeModal}
            openDepositModal={openDepositModal}
            amount={finalAmount()}
            setCountdownAfterDeposit={setCountdownAfterDeposit}
            firstName={firstName}
            lastName={lastName}
          />
        )}
      </div>
      <VerificationReminderModal isOpened={showVerificationReminder} />
    </React.Fragment>
  );
};

DepositFromCard.propTypes = {
  userId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  openDepositModal: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  verificationLevel: PropTypes.any,
  timer: PropTypes.any
};

const findProperConfigValue = (userConfig, key) => {
  try {
    return userConfig.find(elem => {
      return elem.Key === key;
    }).Value;
  } catch (e) {
    return '';
  }
};

export default connect(
  state => {
    const { user } = state;
    const accountId = user && user.userInfo.AccountId;
    const account = user && user.accounts.find(i => i.AccountId === accountId);
    const userConfig = state.user.userConfig;
    return {
      userId: state.user.userInfo.UserId,
      firstName: findProperConfigValue(userConfig, 'FirstName'),
      lastName: findProperConfigValue(userConfig, 'LastName'),
      verificationLevel: account && account.VerificationLevel
    };
  },
  dispatch => {
    return {
      openDepositModal: props => {
        dispatch(openModal(MODAL_TYPES.DEPOSIT_WITHDRAW_STATUS_MODAL, props));
      }
    };
  }
)(DepositFromCard);
