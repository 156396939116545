import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Header from './Header';
import AssetDetails from '../../components/AssetDetails/AssetDetailsContainer';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { getPublicAssetAttributes } from '../../api/liquidApi';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { selectDigitalAssetsByIdWithAttributes } from '../../redux/selectors/productsSelector';
import { AssetHeaderContainer } from '../../components/AssetHeader/AssetHeaderContainer';
import { receiveProductAttributes } from 'apex-web/lib/redux/actions/productActions';
import './PublicAssetDetailsPage.css';

const classes = getBEMClasses('public-asset-details');

const PublicAssetDetailsPageComponent = props => {
  const [isFetching, setIsFetching] = useState(true);
  const { match, setAssetAttributes, assetDetails } = props;
  const assetId = Number(match.params.assetId);
  useEffect(() => {
    getPublicAssetAttributes(assetId)
      .then(attributes => {
        setAssetAttributes(assetId, attributes);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  return (
    <div className={classes()}>
      <Header />
      {isFetching ? (
        <Spinner
          customClass={classes()}
          isInline
        />
      ) : (
        <div className={classes('asset-details-wrapper')}>
          <AssetHeaderContainer
            assetDetails={assetDetails}
            isPublic
          />
          <AssetDetails
            assetId={assetId}
            isStandalone
          />
        </div>
      )}
      <PageFooterLayout />
    </div>
  );
};

PublicAssetDetailsPageComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      assetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  assetDetails: PropTypes.object,
  setAssetAttributes: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const assetId = Number(ownProps.match.params.assetId);
  return {
    assetDetails: selectDigitalAssetsByIdWithAttributes(assetId)(state, { withoutBalance: true })
  };
};

const mapDispatchToProps = {
  setAssetAttributes: (assetId, attributes) => receiveProductAttributes(
    Object.entries(attributes).map(([key, value]) => ({
      KeyName: key,
      KeyValue: value,
      OMSId: 1,
      ProductId: assetId
    })),
    assetId
  )
};

export const PublicAssetDetailsPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PublicAssetDetailsPageComponent)
);
