import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { Select } from '../Select';
import './AssetsFilter.css';

const baseClasses = 'assets-filter';

const AssetsFilter = (props, context) => {
  const {
    currentFilterOption,
    isAscending,
    setIsAscending,
    setCurrentFilterOption,
    selectorOptions,
    customClass,
    isUseAllOption
  } = props;
  let mappedSelectorOptions;
  if (selectorOptions && selectorOptions.length > 0) {
    mappedSelectorOptions = selectorOptions.map(
      ({ value, label, isAscendingOption = true }) => ({
        value,
        label,
        onClick: v => {
          setIsAscending(isAscendingOption);
          setCurrentFilterOption(v);
        }
      })
    );
  }

  const classes = getBEMClasses(baseClasses, customClass);
  const filter = (
    <div className={classes('')}>
      <div className={classes('filter-title')}>{context.t('Filters By:')}</div>
      <div className={classes('filter-row')}>
        {isUseAllOption && (
          <div
            onClick={() => {
              setCurrentFilterOption('All');
            }}
            className={classes(
              'filter-option',
              currentFilterOption === 'All' && 'active'
            )}>
            {context.t('All')}
          </div>
        )}
        <div
          onClick={() => {
            setIsAscending(false);
            setCurrentFilterOption('Rolling24HrPxChangePercent');
          }}
          className={classes(
            'filter-option',
            currentFilterOption === 'Rolling24HrPxChangePercent' &&
              !isAscending &&
              'active'
          )}>
          {context.t('Top Gainers')}
        </div>
        <div
          onClick={() => {
            setIsAscending(true);
            setCurrentFilterOption('Rolling24HrPxChangePercent');
          }}
          className={classes(
            'filter-option',
            currentFilterOption === 'Rolling24HrPxChangePercent' &&
              isAscending &&
              'active'
          )}>
          {context.t('Great Opportunity')}
        </div>
        <div
          onClick={() => {
            setIsAscending(false);
            setCurrentFilterOption('Rolling24NumTrades');
          }}
          className={classes(
            'filter-option',
            currentFilterOption === 'Rolling24NumTrades' && 'active'
          )}>
          {context.t('Most Active')}
        </div>
      </div>
    </div>
  );
  return mappedSelectorOptions ? (
    <div className={classes('top-level-wrapper')}>
      {filter}
      <div className={classes('selector-wrapper')}>
        <Select
          options={mappedSelectorOptions}
          currentOption={currentFilterOption}
          customClass={classes('select')}
        />
      </div>
    </div>
  ) : (
    filter
  );
};

AssetsFilter.propTypes = {
  currentFilterOption: PropTypes.string,
  isAscending: PropTypes.bool,
  setIsAscending: PropTypes.func,
  setCurrentFilterOption: PropTypes.func,
  selectorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      isAscendingOption: PropTypes.bool
    })
  ),
  customClass: PropTypes.string,
  isUseAllOption: PropTypes.bool
};

AssetsFilter.contextTypes = {
  t: PropTypes.func.isRequired
};

export { AssetsFilter };
