import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import QuantityPicker from '../../../../../common/QuantityPicker';
import BigNumber from 'bignumber.js';
import { buyValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import {
  usdProductSelector,
  userBalanceByProductSelector
} from '../../../../../../redux/selectors/productsSelector';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { calcMaxUsdTotal } from '../../../../../../redux/actions/buySellModalActions';
import { MODAL_TYPES } from '../../../../../../redux/actions/modalActions';
import { convertIncrementToIntDecimalPlaces as iToDp } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import { getNormalizationRegExp } from '../../../../../../helpers/buySellHelper';
import {
  getOrdersBySide,
  getPriceForQuantity,
  getQuantityForPrice
} from 'apex-web/lib/helpers/orderHelper';
import config from '../../../../../../config';
import { isInstrumentSelected } from '../../../../../../redux/selectors/instrumentSelector';

const { OrderCalcBuffer: { quantityBuffer, priceBuffer } = {} } = config;

const mapStateToProps = (state, ownProps) => {
  const { side, currentInstrument } = ownProps;
  const level2 = state.apexCore.level2;
  const isLevel2Empty = !level2.buy.length && !level2.sell.length;
  const fetching =
    state.apexCore.instrument.fetching ||
    state.apexCore.level2.fetching ||
    state.apexCore.position.fetching;
  const isBuy = side === buyValue;
  const isSelected = isInstrumentSelected(
    state,
    currentInstrument.InstrumentId
  );
  const commonProps = { isBuy, isSelected };
  const loadingProps = { isLoading: true, ...commonProps };
  if (fetching || isLevel2Empty) {
    return loadingProps;
  }
  const productId = currentInstrument.Product1;
  if (isBuy) {
    let maxUsdTotal = get(state, [
      'modal',
      MODAL_TYPES.BUY_SELL_MODAL,
      'modalProps',
      'maxUsdTotal'
    ]);
    if (maxUsdTotal === undefined) {
      return loadingProps;
    }
    maxUsdTotal = maxUsdTotal.dp(
      iToDp(currentInstrument.PriceIncrement),
      BigNumber.ROUND_FLOOR
    );
    const usdProduct = usdProductSelector(state);
    const userUsdBalance = userBalanceByProductSelector(
      state,
      usdProduct.ProductId
    );
    return {
      ...getUsdPickerProps(currentInstrument, maxUsdTotal),
      userUsdBalance,
      ...commonProps
    };
  } else {
    let tokens = userBalanceByProductSelector(state, productId);
    tokens = tokens.dp(
      iToDp(currentInstrument.QuantityIncrement),
      BigNumber.ROUND_FLOOR
    );
    return {
      ...getTokenPickerProps(currentInstrument, tokens),
      ...commonProps
    };
  }
};

const getUsdPickerProps = (currentInstrument, maxUsdTotal) => ({
  currencySymbol: currentInstrument.Product2Symbol,
  inputIncrement: currentInstrument.PriceIncrement,
  inputRegExp: getNormalizationRegExp(iToDp(currentInstrument.PriceIncrement)),
  maxCurrency: BigNumber(maxUsdTotal),
  convert: usdToQuantityConvert
});

// TODO(Sep 27, 2022): use dynamic convert functions that depend on level2 as an
// argument. that way the convert object will change with every level2 update
// and lead to re-renders, thus guaranteeing that convert results are synced
// with additional text data displayed below the quantity component. this may
// not work and/or may lead to lags. maybe lack of sync isn't really a problem
const usdToQuantityConvert = {
  fromCurrencyToQuantity: (currency, dispatch) =>
    dispatch(fromUsdToQuantity(currency)),
  fromQuantityToCurrency: (quantity, dispatch) =>
    dispatch(fromQuantityToUsd(quantity))
};

const fromUsdToQuantity = usd => (dispatch, getState) => {
  const level2 = getState().apexCore.level2 || { buy: [], sell: [] };
  const orders = getOrdersBySide(buyValue, level2);
  const quantity = getQuantityForPrice(
    usd,
    quantityBuffer,
    orders,
    true,
    buyValue
  ).Quantity;
  return !isNaN(quantity) ? BigNumber(quantity) : BigNumber(0);
};

const fromQuantityToUsd = quantity => (dispatch, getState) => {
  const level2 = getState().apexCore.level2 || { buy: [], sell: [] };
  const orders = getOrdersBySide(buyValue, level2);
  const usd = getPriceForQuantity(quantity, priceBuffer, orders, true, buyValue)
    .Price;
  return !isNaN(usd) ? BigNumber(usd) : BigNumber(0);
};

const getTokenPickerProps = (currentInstrument, tokens) => ({
  currencySymbol: currentInstrument.Product1Symbol,
  inputIncrement: currentInstrument.QuantityIncrement,
  inputRegExp: getNormalizationRegExp(
    iToDp(currentInstrument.QuantityIncrement)
  ),
  maxCurrency: BigNumber(tokens),
  minCurrency: BigNumber(currentInstrument.MinimumQuantity)
});

const mapDispatchToProps = {
  calcMaxUsdTotal
};

const Wrapper = props => {
  const {
    isLoading,
    isSelected,
    calcMaxUsdTotal,
    userUsdBalance,
    ...restProps
  } = props;

  useEffect(
    () => {
      if (restProps.isBuy && isSelected) {
        calcMaxUsdTotal();
      }
    },
    [restProps.isBuy, isSelected, Number(userUsdBalance)]
  );

  if (isLoading) {
    return <Spinner isInline />;
  }
  return <QuantityPicker {...restProps} />;
};

Wrapper.propTypes = {
  isLoading: PropTypes.bool,
  isSelected: PropTypes.bool,
  calcMaxUsdTotal: PropTypes.func,
  userUsdBalance: PropTypes.any
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapper);
