import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectDigitalAssetsWithAttributesNotUndefined } from '../../redux/selectors/productsSelector';
import { baseCurrencySelector } from 'apex-web/lib/redux/selectors/baseCurrenciesSelectors';
import MyCollectiblesBaseSection from './MyCollectiblesBaseSection';

const MyCollectiblesSection = (props, context) => {
  return (
    <MyCollectiblesBaseSection
      title={context.t('Collectibles')}
      noAssetsPlaceholderText={context.t('You own no collectibles for now.')}
      {...props}
    />
  );
};

MyCollectiblesSection.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const baseCurrency = baseCurrencySelector(state);
  const assets = selectDigitalAssetsWithAttributesNotUndefined([
    'Rolling24HrPxChangePercent',
    'Rolling24NumTrades',
  ])(state)
    .filter(asset =>
      asset.product.productAttributes.ProductImageURL &&
      asset.product.productAttributes.ProductColor &&
      asset.Amount > 0
    );
  return {
    assets,
    baseCurrency,
  };
};

export default connect(
  mapStateToProps,
  null
)(MyCollectiblesSection);
