import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { addMonths, addWeeks, format } from 'date-fns';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

import './PaymetnsModals.css';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { Modal } from '../../../components/common/Modal/Modal';
import APIcon from '../../../components/common/APIcon';
import { PauseIcon } from '../../../images/react-icons/PauseIcon';

const classes = getBEMClasses('payments-modal');

export const SkipPaymentModal = (props, context) => {
  const {
    isModalOpen,
    closeModal,
    payment,
    confirmSkip,
    isLoading,
    setIsLoading
  } = props;

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const onSkip = () => {
    setIsLoading(true);
    confirmSkip();
  };

  return (
    <Modal
      customClass={classes()}
      isOpen={isModalOpen}
      close={closeModal}
      showHeader={false}
      showCloseIcon={false}>
      <div className={classes('skip-payment-header')}>
        <div className={classes('header-left')}>
          <PauseIcon className={classes('pause-icon')} />
          {context.t('Recurring Payment')}
        </div>
        <div onClick={closeModal}>
          <APIcon name="close" customClass={classes('close-icon')} />
        </div>
      </div>
      <div className={classes('body')}>
        <div className={classes('title')}>
          {context.t('Are you sure you want to skip this payment?')}
        </div>

        <div className={classes('payment-info')}>
          <div className={classes('card-info-field')}>
            {context.t('Recurring Payment Date')}
          </div>
          <div className={classes('card-info-value')}>
            {payment &&
              format(payment.nextPaymentTimestamp, 'MM/dd/yyyy HH:mm:ss')}
          </div>
          <div className={classes('card-info-field')}>
            {context.t('New Recurring Payment Date')}
          </div>
          <div className={classes('card-info-value')}>
            {payment &&
              format(
                payment.interval === 'month'
                  ? addMonths(
                      payment.nextPaymentTimestamp,
                      payment.intervalCount
                    )
                  : addWeeks(
                      payment.nextPaymentTimestamp,
                      payment.intervalCount
                    ),
                'MM/dd/yyyy HH:mm:ss'
              )}
          </div>
          <div className={classes('card-info-field')}>
            {context.t('Payment Amount')}
          </div>
          <div className={classes('card-info-value')}>{payment.amount}</div>
          <div className={classes('card-info-field')}>
            {context.t('Payment Frequency')}
          </div>
          <div className={classes('card-info-value')}>{`${
            payment.intervalCount
          } ${payment.interval}${payment.intervalCount > 1 ? 's' : ''}`}</div>
          <div className={classes('card-info-field')}>
            {context.t('Payment Method')}
          </div>
          <div className={classes('card-info-value')}>{'Credit Card'}</div>
        </div>

        {isLoading ? (
          <Spinner customClass={classes('spinner')} />
        ) : (
          <div className={classes('buttons')}>
            <button className={classes('cancel-button')} onClick={closeModal}>
              {context.t('Cancel')}
            </button>
            <button className={classes('confirm-skip')} onClick={onSkip}>
              {context.t('Confirm')}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

SkipPaymentModal.contextTypes = {
  t: PropTypes.func.isRequired
};

SkipPaymentModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  payment: PropTypes.any,
  confirmSkip: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func
};
