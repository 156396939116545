export const COOKIE_KEYS = {
  REDIRECT_FROM_LOGIN: 'redirectFromLogin'
}

export const setCookie = (name, value, lifetimeMs, path = '/') => {
  const expires = new Date(Date.now() + lifetimeMs).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`;
};

export const getCookie = (name) => {
  const nameEQ = name + '=';
  for (const cookie of document.cookie.split('; ')) {
    if (cookie.indexOf(nameEQ) === 0) {
      const value = cookie.substring(nameEQ.length);
      return decodeURIComponent(value); // returns first found cookie
    }
  }
  return null;
};

export const deleteCookie = (name, path) => {
  document.cookie = `${name}=; expires=${new Date(0).toUTCString()}; path=${path}`;
};
