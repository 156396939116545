import React from 'react';
import StubPage from './StubPage';

const MaintenancePage = () => (
  <StubPage
    title="Maintenance"
    subtitle="It’s a drought!"
    text="You’ve searched long and hard, but this page is currently undergoing maintenance. In the meantime, let’s get you some Liquid!"
    showDashboardButton={false}
  />
);

export default MaintenancePage;
