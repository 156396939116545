import { DashboardMarketPlaceComponent } from './DashboardMarketPlaceComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { baseCurrencySelector } from 'apex-web/lib/redux/selectors/baseCurrenciesSelectors';
import {
  selectDigitalAssetsWithAttributesNotUndefined,
  selectMarketplaceOfferings
} from '../../../redux/selectors/productsSelector';
import { MODAL_TYPES, openModal } from '../../../redux/actions/modalActions';
import { selectInstrument } from 'apex-web/lib/redux/actions/instrumentActions';
import { initialize, reset } from 'redux-form';
import { orderTypes } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { push } from 'connected-react-router';
import { UrlFactory } from '../../../helpers/urlFactory';

const mapStateToProps = (state) => {
  const assets = selectDigitalAssetsWithAttributesNotUndefined([
    'Rolling24HrPxChangePercent',
    'Rolling24NumTrades'
  ])(state);
  const marketPlaceOfferings = selectMarketplaceOfferings(state);
  const filteredCollectibles = assets.filter(i =>
    marketPlaceOfferings.find(item => item.ProductId === i.ProductId)
  );
  const baseCurrency = baseCurrencySelector(state);

  return {
    assets: filteredCollectibles,
    baseCurrency,
  };
};

const mapDispatchToProps = {
  openSelectAssetModal: () => openModal(MODAL_TYPES.SELECT_ASSET_MODAL),
  openWatchListModal: () => openModal(MODAL_TYPES.SELECT_ASSET_FROM_WATCH_LIST_MODAL),
  goToProMarketPlace: (options) => goToProMarketPlace(options)
};

// TODO(Jul 05, 2022): move to some *actions.js file
const goToProMarketPlace = ({
  productId,
  side,
  orderType,
  onlyLimitAvailable
}) => (dispatch, getState) => {
  const state = getState();
  const baseCurrency = baseCurrencySelector(state);
  const instrument = (productId &&
    state.apexCore.instrument.instruments.find(
      i => i.Product1 === productId && i.Product2Symbol === baseCurrency
    ));
  if (instrument) {
    dispatch(selectInstrument(instrument.InstrumentId));
    dispatch(initialize('orderEntry', {
      orderType: orderType || (onlyLimitAvailable ?
        orderTypes.limit.displayName :
        orderTypes.market.displayName),
      side,
      limitPrice: 0,
      stopPrice: 0,
      timeInForce: '1',
      fee: null,
      maxUsdTotal: null
    }, {
      keepDirty: false,
      keepSubmitSucceeded: false,
      updateUnregisteredFields: true,
      keepValues: false
    }));
    dispatch(reset('orderEntry'));
    dispatch(push(UrlFactory.getProExchangeUrl()));
  } else {
    console.error('Cannot find instrument for productId:', productId);
  }
};

const DashboardMarketPlaceContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DashboardMarketPlaceComponent)
);

export { DashboardMarketPlaceContainer };
