import OrderDetailsContainer from "./OrderDetailsContainer";
import React from "react";

export default OrderDetailsContainer;

// TODO(May 17, 2022): does it cause unnecessary re-renders?

export const OrderDetailsOnPlaceStep = props => (
  <OrderDetailsContainer
    {...props}
    isConfirm={false}
  />
);

export const OrderDetailsOnConfirmStep = props => (
  <OrderDetailsContainer
    {...props}
    isConfirm={true} />
);
