import React from 'react';
import { InnerInput } from 'apex-web/lib/components/common/APInput/APInput';

const Input = props => {
  return <InnerInput {...props} />;
};

Input.defaultProps = {
  meta: {
    dirty: true,
    error: '',
    touched: false
  }
};

/*
Example:
<Input name="name" ref={register} />
*/

export default Input;
