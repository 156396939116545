import React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { getBEMClasses } from "../../helpers/cssClassesHelper";
import Quid from '../../images/quid_not_found.png';
import Wordmark from '../../layouts/PageHeaderLayout/images/WORDMARK.png';
import Divider from './wave-divider.svg';
import config from "../../config";
import SVG from 'react-inlinesvg';
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Textfit } from "react-textfit";
import './StubPage.css';
import './StubPageDesktop.css';

const coreClasses = getBEMClasses('stub-page');
const additionalClasses = getBEMClasses('stub-page-desktop');
const classes = (...args) => classNames([
  additionalClasses(...args),
  coreClasses(...args),
]);

const StubPageDesktop = (props, context) => {
  const {
    title,
    subtitle,
    text,
    showDashboardButton = true,
  } = props;
  return (
    <div className={classes()}>
      <div className={classes('scroll-container')}>
        <div className={classes('wordmark-container')}>
          <img
            className={classes('wordmark')}
            src={Wordmark}
            alt='LiquidMarket. Own Anything.'
          />
        </div>
        <div className={classes('content')}>
          <div className={classes('left-col')}>
            <div className={classes('left-content')}>
              <img
                className={classes('quid')}
                src={Quid}
                alt=''
              />
              <div className={classes('links-container')}>
                {get(config, 'Footer.links', []).map((column) =>
                  column.map((item, innerIdx) => {
                    let openInNewWindow =
                      config.Footer.openInNewWindowGlobal || true;
                    if ('openInNewWindow' in item) {
                      openInNewWindow = item.openInNewWindow;
                    }
                    return (
                      <a
                        target={openInNewWindow ? '_blank' : ''}
                        rel={openInNewWindow ? 'noopener noreferrer' : ''}
                        key={innerIdx}
                        href={item.link}>
                        {context.t(item.text)}
                      </a>
                    );
                  }))}
              </div>
            </div>
          </div>
          <div className={classes('right-col')}>
            <div className={classes('right-content')}>
              <div className={classes('title')}>
                <Textfit
                  mode='single'
                  max={120}>
                  {context.t(title)}
                </Textfit>
              </div>
              <p className={classes('subtitle')}>{context.t(subtitle)}</p>
              <p className={classes('text-content')}>
                {context.t(text)}
              </p>
              {showDashboardButton && (
                <Link
                  className={classes('back-button')}
                  to={'/dashboard'}>
                  {context.t('Back to dashboard')}
                </Link>
              )}
              <div className={classes('divider-container')}>
                <SVG
                  className={classes('divider')}
                  src={Divider}
                  alt=''
                />
              </div>
            </div>
            <div className={classes('copyright')}>
              {`© Liquid Marketplace Inc. ${new Date().getFullYear()}. ${context.t(
                'All Rights Reserved.'
              )}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StubPageDesktop.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  showDashboardButton: PropTypes.bool,
};

StubPageDesktop.contextTypes = {
  t: PropTypes.func
};

export default StubPageDesktop;
