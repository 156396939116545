import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

import './AffiliateComponent.css';

const affiliateClasses = getBEMClasses('affiliate');

const AffiliateCountComponent = (props, context) => {
  const { affiliate } = props;

  return (
    <section className={affiliateClasses('container-content')}>
      <p className={affiliateClasses('tag')}>
        {affiliate.affiliateCount} {context.t('Affiliates')}
      </p>
    </section>
  );
};

AffiliateCountComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

AffiliateCountComponent.propTypes = {
  affiliate: PropTypes.object.isRequired
};

export default AffiliateCountComponent;
