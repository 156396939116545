import { getInstrumentsDCA } from "../../api/liquidApi";
import { ownedInstrumentsSelector } from "../selectors/instrumentSelector";

export const SET_DCAS = 'SET_DCAS';
export const UPDATE_DCAS_REQUEST_STATUS = 'UPDATE_DCAS_REQUEST_STATUS';

export const updateDCAsRequestStatus = (isFetching) => ({
  type: UPDATE_DCAS_REQUEST_STATUS,
  payload: { isFetching }
});

/**
 * 
 * @param {{[number]: {InstrumentId: number, DCA: number}}} payload 
 */
export const setDCAs = (payload) => {
  return {
    type: SET_DCAS,
    payload
  };
};

export const updateDCAs = (instrumentIds) => async (dispatch, getState) => {
  try {
    dispatch(updateDCAsRequestStatus(true));
    instrumentIds = instrumentIds ||
      ownedInstrumentsSelector(getState()).map(i => i.InstrumentId);
    const response = await getInstrumentsDCA(instrumentIds);
    const mappedResponse = response.reduce((acc, dcaObj) => {
      acc[dcaObj.instrument_id] = {
        InstrumentId: dcaObj.instrument_id,
        DCA: dcaObj.dca
      };
      return acc;
    }, {});
    dispatch(setDCAs(mappedResponse));
  }
  catch (e) {
    console.log(e);
  }
  finally {
    dispatch(updateDCAsRequestStatus(false));
  }
};
