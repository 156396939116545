import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { getGoogleQrCode } from 'apex-web/lib/redux/actions/userActions';
import {
  openModal,
  MODAL_TYPES
} from 'apex-web/lib/redux/actions/modalActions';

const selector = formValueSelector('enableTwoFactorAuth');

const mapStateToProps = state => ({
  initialValues: {
    use2FA: !!state.user.userInfo.Use2FA
  },
  use2FA: selector(state, 'use2FA'),
  use2FAOriginal: state.user.userInfo.Use2FA
});

const mapDispatchToProps = dispatch => ({
  enable2FA: async () => {
    await dispatch(getGoogleQrCode());
    dispatch(change('enableTwoFactorAuth', 'use2FA', true));
    dispatch(openModal(MODAL_TYPES.ENABLE_2FA));
  },
  disable2FA: () => {
    dispatch(change('enableTwoFactorAuth', 'use2FA', false));
    dispatch(openModal(MODAL_TYPES.DISABLE_2FA));
  },
  getGoogle2FARecoveryKey: () => {
    dispatch(openModal(MODAL_TYPES.GET_GOOGLE_2FA_RECOVERY_CODE));
  }
});

const EnableTwoFactorAuthContainerFactory = Component => {
  const EnableTwoFactorAuthContainer = reduxForm({
    form: 'enableTwoFactorAuth',
    enableReinitialize: true
  })(Component);

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(EnableTwoFactorAuthContainer);
};

export default EnableTwoFactorAuthContainerFactory;
