import React from "react";
import PropTypes from 'prop-types';
import StubPageDesktop from "./StubPageDesktop";
import StubPageMobile from "./StubPageMobile";
import { useIsMobile } from "../../hooks/useIsMobile";

const StubPage = (props) => {
  const isMobile = useIsMobile();
  return isMobile ?
    <StubPageMobile {...props} /> :
    <StubPageDesktop {...props} />;
};

StubPage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  showDashboardButton: PropTypes.bool,
};

export default StubPage;
