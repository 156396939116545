export const VOTE_COUNTDOWN_DAYS = 30;
export const VOTE_COUNTDOWN = VOTE_COUNTDOWN_DAYS * 24 * 60 * 60 * 1000; // 28 days
export const UNPLEDGE_COUNTDOWN_DAYS = 14;

export const VOTE_STATUSES = {
  COUNTDOWN: 'COUNTDOWN',
  LIVE: 'LIVE',
  RESULT: 'RESULT'
};

export const VOTE_TYPES = {
  AUCTION: 'AUCTION',
  BUY_OUT: 'BUY OUT'
};

export const VOTE_YES = 'yes';
export const VOTE_NO = 'no';

export const VOTE_RESULTS = {
  REMAINED: 'Remained',
  MOVED_OUT: 'MovedOut'
};

//context.t('By continuing, you confirm your "Yes" vote to auction off this specific collectible. Until the vote has concluded, you will NOT be able to trade your tokens on the MarketPlace and Pro MarketPlace. If the vote concludes with 80% or greater of the aggregate token supply having voted "Yes," then the collectible will be securely delivered to Goldin and the auction will proceed.')
export const VOTE_YES_CONFIRM_AUCTION_TEXT =
  'By continuing, you confirm your "Yes" vote to auction off this specific collectible. Until the vote has concluded, you will NOT be able to trade your tokens on the MarketPlace and Pro MarketPlace. If the vote concludes with 80% or greater of the aggregate token supply having voted "Yes," then the collectible will be securely delivered to Goldin and the auction will proceed.';

//context.t('By continuing, you confirm your "No" vote to auction off this specific collectible. Until the vote has concluded, you will NOT be able to trade your tokens on the MarketPlace and Pro MarketPlace. If the vote concludes with 21% or greater of the aggregate token supply having voted "No," then trading of this instrument will resume on the MarketPlace and Pro MarketPlace. In this case, the collectible will remain in the Liquid MarketPlace vault.')
export const VOTE_NO_CONFIRM_AUCTION_TEXT =
  'By continuing, you confirm your "No" vote to auction off this specific collectible. Until the vote has concluded, you will NOT be able to trade your tokens on the MarketPlace and Pro MarketPlace. If the vote concludes with 21% or greater of the aggregate token supply having voted "No," then trading of this instrument will resume on the MarketPlace and Pro MarketPlace. In this case, the collectible will remain in the Liquid MarketPlace vault.';

//context.t('By continuing, you confirm your "Yes" vote to accept the buyout request for this specific collectible at the price stated. Until the vote has concluded, you will NOT be able to trade your tokens on the MarketPlace and Pro MarketPlace. If the vote concludes with 80% or greater of the aggregate token supply having voted "Yes," then all of the tokens will be bought out immediately at the agreed upon price.')
export const VOTE_YES_CONFIRM_BUY_OUT_TEXT =
  'By continuing, you confirm your "Yes" vote to accept the buyout request for this specific collectible at the price stated. Until the vote has concluded, you will NOT be able to trade your tokens on the MarketPlace and Pro MarketPlace. If the vote concludes with 80% or greater of the aggregate token supply having voted "Yes," then all of the tokens will be bought out immediately at the agreed upon price.';

//context.t('By continuing, you confirm your "No" vote to decline the buyout request for this specific collectible at the price stated. Until the vote has concluded, you will NOT be able to trade your tokens on the MarketPlace and Pro MarketPlace. If the vote concludes with 80% or greater of the aggregate token supply having voted "Yes," then all of the tokens will be bought out immediately at the agreed upon price.')
export const VOTE_NO_CONFIRM_BUY_OUT_TEXT =
  'By continuing, you confirm your "No" vote to decline the buyout request for this specific collectible at the price stated. Until the vote has concluded, you will NOT be able to trade your tokens on the MarketPlace and Pro MarketPlace. If the vote concludes with 80% or greater of the aggregate token supply having voted "Yes," then all of the tokens will be bought out immediately at the agreed upon price.';

//context.t("The voting stage has concluded and the collectible will be bought out. The collectible's tokens are no longer tradable. Token holders will be notified when the payout is sent.");
export const BUY_OUT_RESULT_TEXT_MOVED_OUT =
  "The voting stage has concluded and the collectible will be bought out. The collectible's tokens are no longer tradable. Token holders will be notified when the payout is sent.";

//context.t('The voting stage has concluded and the collectible will remain on the platform. Ownership can now continue to be traded.');
export const BUY_OUT_RESULT_TEXT_REMAIN =
  'The voting stage has concluded and the collectible will remain on the platform. Ownership can now continue to be traded.';

//context.t("The voting stage has concluded and the collectible will be sent to auction. The collectible's tokens are no longer tradable. More details will be sent when the auction date is confirmed.")
export const AUCTION_TEXT_MOVED_OUT =
  "The voting stage has concluded and the collectible will be sent to auction. The collectible's tokens are no longer tradable. More details will be sent when the auction date is confirmed.";

//context.t('The voting stage has concluded and the collectible will remain on the platform. Ownership can now continue to be traded.')
export const AUCTION_TEXT_REMAIN =
  'The voting stage has concluded and the collectible will remain on the platform. Ownership can now continue to be traded.';
