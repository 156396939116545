import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './KYCSettingsComponent.css';
import './KYCFormSidePaneComponent.css';
import KYCComponent from './KYC';
import KYCModalContainer from '../../components/KYCModal/KYCModalContainer';
import KYCStatusModalContainer from '../../components/KYCModal/KYCStatusModals/KYCStatusModalContainer';
import { MODAL_TYPES, openModal } from '../../redux/actions/modalActions';

const KYCSettingsComponent = props => {
  const {
    location: { search },
    openModal
  } = props;
  const kycStatus = search ? search.match(/\?status=(\w+)/)[1] : null;
  useEffect(() => {
    if (kycStatus) {
      openModal(MODAL_TYPES.KYC_STATUS_MODAL, { status: kycStatus });
    }
  }, []);
  return (
    <React.Fragment>
      <KYCComponent />
      <KYCModalContainer />
      <KYCStatusModalContainer />
    </React.Fragment>
  );
};

KYCSettingsComponent.propTypes = {
  openModal: PropTypes.func,
  location: PropTypes.object
};

const mapDispatchToProps = { openModal };

export default connect(
  null,
  mapDispatchToProps
)(withRouter(KYCSettingsComponent));
