/**
 * Helper used to subscribe to any event source, buffer the latest events and multicast
 * them later to subscribers. Useful if you're subscribing to a hot event source 
 * and risk skipping a desired event because of a race condition with the source. 
 * 
 * Example: you make a buy/sell order and need to subscribe to order state event 
 * and wait for the "order fully executed" event. Of course, you don't know which 
 * order id this event will have until you make the "send order" request. There's 
 * a race condition: by the time you process a response and get the order id 
 * backend may have already sent the desired event and you have missed it. 
 * This helper can be used to subscribe to state events ahead of time, buffer 
 * some reasonable amount of them while you're processing the response, and then 
 * give them all (and then new events) to your waiting logic.
 */
class ReplaySubscriptionHelper {
  constructor(maxQueueLength = 50) {
    this.subscribers = new Set();
    this.maxQueueLength = maxQueueLength;
    this.eventQueue = new Array(this.maxQueueLength);
  }

  onObserve = (...eventArgs) => {
    this.subscribers.forEach(sub => {
      try {
        sub(...eventArgs);
      } catch (e) {
        console.error(e);
        // remove failed subscriber to avoid potential error spam on new events
        this.subscribers.delete(sub);
      }
    });
    this.eventQueue.push(eventArgs);
    if (this.eventQueue.length > this.maxQueueLength) {
      this.eventQueue.shift();
    }
  };

  subscribe = (subscriber) => {
    this.eventQueue.forEach(eventArgs => subscriber(...eventArgs));
    this.subscribers.add(subscriber);
    return () => { this.subscribers.delete(subscriber); };
  };

  reset = () => {
    this.subscribers.clear();
    this.eventQueue = [];
  };
}

export default ReplaySubscriptionHelper;
