import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import APIcon from '../common/APIcon/APIcon';
import { getGlobalNotification } from '../../redux/actions/notificationsActions';
import { selectGlobalNotification } from '../../redux/selectors/notificationsSelector';
import { isBefore, parseISO } from 'date-fns';
import './GlobalNotification.css';

const baseClasses = getBEMClasses('global-notification');

const GlobalNotificationComponent = props => {
  const { globalNotification, getGlobalNotification } = props;
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  useEffect(() => {
    getGlobalNotification();

    setTimeout(() => {
      const isGlobalNotificationWasShown = localStorage.getItem(
        'isGlobalNotificationWasShown'
      );
      if (!isGlobalNotificationWasShown) {
        setIsNotificationVisible(true);
      } else {
        const isNotificationVisible =
          isGlobalNotificationWasShown === 'false' ? false : true;
        setIsNotificationVisible(!isNotificationVisible);
      }
    }, 500);
  }, []);

  if (
    isNotificationVisible &&
    globalNotification.message &&
    isBefore(new Date(), parseISO(globalNotification.endDate))
  ) {
    return (
      <div className={baseClasses()}>
        <div className={baseClasses('notification-wrapper')}>
          <APIcon name="notificationBell" customClass={baseClasses('icon')} />Notification
        </div>
        {globalNotification.message}
        <div
          className={baseClasses('close-wrapper')}
          onClick={() => {
            localStorage.setItem('isGlobalNotificationWasShown', true);
            setIsNotificationVisible(false);
          }}>
          <APIcon name="close" customClass={baseClasses('close')} />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

GlobalNotificationComponent.propTypes = {
  getGlobalNotification: PropTypes.func,
  globalNotification: PropTypes.object
};

const mapStateToProps = state => {
  const globalNotification = selectGlobalNotification(state);
  return { globalNotification };
};

const mapDispatchToProps = {
  getGlobalNotification
};

const GlobalNotification = connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalNotificationComponent);

export { GlobalNotification };
