import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import APButton from 'apex-web/lib/components/common/APButton';
import { required } from 'apex-web/lib/helpers/formValidations';

import { defaultPath } from 'apex-web/lib/routeTemplates';
import path from 'apex-web/lib/helpers/path';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import 'apex-web/lib/styles/components/common/StandaloneForm.css';
import './LoginFormComponent.css';
import { APInputWithReduxForm } from '../../../components/common/APInputWithReduxForm';
import { APTypography } from '../../../components/common/APTypography';
import redirectIfAuthorized from '../../../hocs/redirectIfAuthorized';

const baseClasses = getBEMClasses('standalone-form');
const loginFormClasses = getBEMClasses('login-form');

export const LoginFormComponent = (props, context) => {
  const {
    handleSubmit,
    errorMsg,
    submitting,
    doSubmit,
    isConnected,
  } = props;

  return (
    <div className={loginFormClasses('container')}>
      <div className={loginFormClasses('title-wrapper')}>
        <div className={loginFormClasses('quid-logo')} />
        <div>
          <APTypography
            className={loginFormClasses('title')}
            fontSize="title"
            weight="weight600"
            color="black8">
            {context.t('LOG IN')}
          </APTypography>

          <APTypography
            className={loginFormClasses('subtitle')}
            fontSize="subtitle"
            weight="weight600">
            {context.t('Welcome to Liquid Marketplace')}
          </APTypography>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(values => doSubmit(values, isConnected))}
        className={loginFormClasses('form')}>
        {errorMsg && <p className={baseClasses('error')}>{errorMsg}</p>}
        <APInputWithReduxForm
          type="text"
          name="username"
          placeholderInInput={context.t('username')}
          customClass={loginFormClasses()}
          validate={[required]}
          required
          alwaysControlled
        />
        <APInputWithReduxForm
          type="password"
          name="password"
          placeholderInInput={context.t('password')}
          customClass={loginFormClasses()}
          validate={[required]}
          required
          alwaysControlled
        />
        <APButton
          type="submit"
          disabled={submitting}
          customClass={loginFormClasses()}>
          <APTypography fontSize="body" weight="weight600" color="white4">
            {submitting ? context.t('Processing...') : context.t('Log In')}
          </APTypography>
        </APButton>
      </form>
      <Link to={path('/problem-logging-in')}>
        <APTypography
          fontSize="caption2"
          color="purple2"
          className={loginFormClasses('problem-login-in')}>
          {context.t('Problem Logging in?')}
        </APTypography>
      </Link>
      <div className={loginFormClasses('new-one')}>
        <APTypography
          fontSize="body"
          weight="weight600"
          color="black8"
          className={loginFormClasses('new-liquid')}>
          {context.t('New to Liquid Marketplace?')}
        </APTypography>
      </div>
      <Link to={path('/signup')}>
        <APTypography
          className={loginFormClasses('sign-up')}
          fontSize="body"
          color="purple2">
          {context.t('Create an account here!')}
        </APTypography>
      </Link>
    </div>
  );
};

LoginFormComponent.defaultProps = {
  handleSubmit: () => {},
  submitting: false
};

LoginFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  referrer: PropTypes.string,
  errorMsg: PropTypes.string,
  isConnected: PropTypes.bool,
  doSubmit: PropTypes.func,
};

LoginFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(LoginFormComponent, defaultPath.path);
