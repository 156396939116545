import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MyCollectiblesSection from './MyCollectiblesSection';
import { MyCollectionsSectionContainer } from './MyCollectionsSection/MyCollectionsSectionContainer';
import MyWatchListSection from './MyWatchListSection';
import { MyCollectiblesHeader } from './MyCollectiblesHeader/MyCollectiblesHeader';

import './MyCollectiblesPage.css';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { MyTopCollectibleSectionContainer } from './MyTopCollectibleSection/MyTopCollectibleSectionContainer';
import { BreadCrumbsLayout } from '../../layouts/BreadCrumbsLayout/BreadCrumbsLayoutContainer';
import ShareProfileModal from '../../components/ShareProfileModal';
import { openBuySellModal } from '../../redux/actions/buySellModalActions';

const classes = getBEMClasses('my-collectibles-page');

const MyCollectiblesPageComponent = props => {
  const { makeOrder, history } = props;

  return (
    <React.Fragment>
      <MyCollectiblesHeader />
      <BreadCrumbsLayout>
        <div className={classes()}>
          <MyTopCollectibleSectionContainer />
          <MyCollectiblesSection
            makeOrder={makeOrder}
            history={history}
          />
          <MyCollectionsSectionContainer
            makeOrder={makeOrder}
            history={history}
          />
          <MyWatchListSection
            makeOrder={makeOrder}
            history={history}
          />
        </div>
      </BreadCrumbsLayout>
      <ShareProfileModal />
    </React.Fragment>
  );
};

MyCollectiblesPageComponent.propTypes = {
  makeOrder: PropTypes.func,
  history: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  makeOrder: props => {
    dispatch(openBuySellModal(props));
  }
});

export const MyCollectiblesPage = connect(
  null,
  mapDispatchToProps
)(MyCollectiblesPageComponent);
