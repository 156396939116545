import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { redirectFromLogin } from 'apex-web/lib/redux/actions/authActions';
import FullPageSpinner from 'apex-web/lib/components/common/FullPageSpinner/FullPageSpinner';
import path from '../helpers/path';
import { COOKIE_KEYS, setCookie } from '../helpers/cookieHelper';
import config from '../config';

function withAuthentication(Component, redirectMap = {}) {
  const wrapped = props => {
    const {
      isAuthenticated,
      isAuthenticating,
      location,
      pendingAuth,
      redirectFromLogin
    } = props;

    if (pendingAuth || (!isAuthenticated && isAuthenticating)) {
      // TODO(Jul 08, 2022): pass props, otherwise the spinner will not be shown
      return <FullPageSpinner />;
    }

    if (!isAuthenticated) {
      const redirectMapping = getRedirectMapping(location.pathname, redirectMap);
      const redirectPath = redirectMapping ? redirectMapping[1] : '/login';
      const saveRedirectToCookies = redirectPath.includes('/signup');
      redirectFromLogin(location, saveRedirectToCookies);
      return <Redirect to={path(redirectPath)} />;
    }

    return <Component {...props} />;
  };

  wrapped.displayName = `withAuthentication(${Component.displayName ||
    Component.name ||
    'Component'})`;

  const mapStateToProps = state => ({
    /**
     * Token exists in localStorage before redux.
     */
    isAuthenticating: !!localStorage.getItem('token'),
    isAuthenticated: state.auth.isAuthenticated,
    pendingAuth: state.auth.pending
  });

  const mapDispatchToProps = dispatch => ({
    redirectFromLogin: (location, isDelayedNavigation) => {
      const minifiedLocation = {
        pathname: location.pathname,
        search: location.search
      };
      if (isDelayedNavigation) {
        setCookie(
          COOKIE_KEYS.REDIRECT_FROM_LOGIN,
          JSON.stringify(minifiedLocation),
          1000 * 60 * config.DelayedNavigation.cookieLifetimeMinutes
        );
      }
      dispatch(redirectFromLogin(minifiedLocation));
    }
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(wrapped);
}

const getRedirectMapping = (pathname, redirectMap) => {
  return Object.entries(redirectMap)
    .find(([currentPath]) => pathname.includes(currentPath));
};

export default withAuthentication;

