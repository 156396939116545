import React from 'react';
import PropTypes from 'prop-types';
import MyCollectiblesBaseSection from '../MyCollectiblesBaseSection';
import './MyCollectionsSection.css';

export const MyCollectionsSection = (props, context) => {
  const {
    pokemonAssets,
    nbaAssets,
    nftAssets,
    nhlAssets,
    f1Assets,
    otherAssetTypes,
    ...restOptions
  } = props;
  
  if (
    !pokemonAssets.length &&
    !nbaAssets.length &&
    !nftAssets.length &&
    !nhlAssets.length &&
    !f1Assets.length && !otherAssetTypes.length
  ) {
    // TODO(May 13, 2022): previous version just had 'display: none', so i guess
    // we don't need to show anything if user has no collections. uncomment this
    // if it changes or if i'm wrong
    // return (
    //   <div className={classes()}>
    //     <APSection title={context.t('Collections')}>
    //       <div className={classes('no-assets-wrapper')}>
    //         <img
    //           src={Logo}
    //           className={classes('no-assets-logo')}
    //           alt="logo"
    //         />
    //         <div className={classes('no-assets-text')}>
    //           {context.t('You own no collection cards for now.')}
    //         </div>
    //       </div>
    //     </APSection>
    //   </div>
    // );
    return null;
  }

  const sections = [
    [pokemonAssets, context.t('Pokemon cards')],
    [nbaAssets, context.t('NBA cards')],
    [nftAssets, context.t('NFTs')],
    [nhlAssets, context.t('NHL cards')],
    [f1Assets, context.t('F1 cards')],
    ...otherAssetTypes.map(item => [item.assets, `${item.type} cards`])
  ].map(([assets, title]) => (
    <MyCollectiblesBaseSection
      key={title}
      title={title}
      assets={assets}
      {...restOptions}
    />
  ));

  return <React.Fragment>{sections}</React.Fragment>;
};

MyCollectionsSection.contextTypes = {
  t: PropTypes.func.isRequired
};

MyCollectionsSection.propTypes = {
  pokemonAssets: PropTypes.arrayOf(PropTypes.object),
  nbaAssets: PropTypes.arrayOf(PropTypes.object),
  nftAssets: PropTypes.arrayOf(PropTypes.object),
  nhlAssets: PropTypes.arrayOf(PropTypes.object),
  f1Assets: PropTypes.arrayOf(PropTypes.object),
  baseCurrency: PropTypes.string.isRequired,
  makeOrder: PropTypes.func,
  history: PropTypes.object,
  otherAssetTypes: PropTypes.arrayOf(PropTypes.object),
};
