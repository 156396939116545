import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { getBEMClasses } from '../../../../../../helpers/cssClassesHelper';
import WireTransferResultModal from '../../../../../WireTransferResultModal/WireTransferResultModal';
import { APTypography } from '../../../../../../components/common/APTypography';
import './DotsACHTransfer.css';
import classNames from 'classnames';
import { createDotsAchTransfer } from '../../../../../../api/liquidApi';
import { AmountNumberInput } from '../../../../../common/AmountNumberInput/AmountNumberInput';
import BigNumber from 'bignumber.js';
import { withDotsAuth } from '../../../hocs/dots/withDotsAuth';
import { Select } from '../../../../../common/Select';
import {
  MODAL_TYPES,
  openModal
} from '../../../../../../redux/actions/modalActions';

const classes = getBEMClasses('dots-transfer');

const accountTypesOptions = [
  { value: 'checking', label: 'Checking' },
  { value: 'saving', label: 'Saving' }
];

const DotsACHTransferComponent = props => {
  const { showNotification, closeModal, openDepositModal } = props;
  const [amount, setAmount] = useState(0);
  const [accountId, setAccountId] = useState('');
  const [routingId, setRoutingId] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [accountType, setAccountType] = useState('checking');

  const feeAmount = funds => {
    // If payment amount is less than 1$ then payment will not be proceeded and so we don't calculate any fee yet.
    if (amount < 100 || isNaN(funds)) {
      return 0;
    }

    const coefficient = 0.01; // 1% fee
    const fee = new BigNumber(funds)
      .times(new BigNumber(1 + coefficient))
      .minus(new BigNumber(funds));
    if (fee.toNumber() < 100) {
      return new BigNumber(100);
    } else {
      return fee;
    }
  };

  const finalAmount = () => {
    return +new BigNumber(amount)
      .plus(feeAmount(amount))
      .div(100)
      .decimalPlaces(2, 2)
      .times(100)
      .toNumber();
  };

  const handleClick = async () => {
    if (!+amount) {
      showNotification('Amount value must be number');
      return;
    }
    if (!amount || !accountId) {
      showNotification('Fill all form fields to proceed');
      return;
    }
    const finalAmountValue = finalAmount();

    const res = await createDotsAchTransfer(
      finalAmountValue,
      accountId,
      routingId,
      accountType
    );

    if (res) {
      openDepositModal({
        isSuccessful: true,
        closeDepositModal: closeModal,
        amount: amount / 100
      });
    }

    setIsSubmitted(true);
  };

  return (
    <div className={classes()}>
      <div className={classes('deposit-ticket-wrapper')}>
        <div className={classes('deposit-inputs')}>
          <div className={classes('inputWrapper')}>
            <AmountNumberInput amount={amount} setAmount={setAmount} />
          </div>
          <div className={classes('inputWrapper')}>
            <label htmlFor="accountId">Account ID: </label>
            <input
              type="text"
              value={accountId}
              onChange={e => {
                setAccountId(e.target.value);
              }}
              className={classes('deposit-input')}
            />
          </div>
          <div className={classes('inputWrapper')}>
            <label htmlFor="routingId">Routing ID: </label>
            <input
              type="text"
              value={routingId}
              onChange={e => {
                setRoutingId(e.target.value);
              }}
              className={classes('deposit-input')}
            />
          </div>
          <div className={classes('inputWrapper')}>
            <label htmlFor="accountType">Account type: </label>
            <Select
              options={accountTypesOptions}
              currentOption={accountType}
              onSelect={type => setAccountType(type)}
              customClass={classes('select')}
            />
          </div>
        </div>
        <div
          className={classNames(
            classes('submit-deposit-button'),
            isSubmitted && classes('submit-deposit-button-disabled')
          )}
          onClick={() => {
            handleClick();
          }}>
          {isSubmitted ? 'Submitted' : `Pay $${finalAmount() / 100} now`}
        </div>
        <APTypography
          className={classes('fee-warning')}
          color={'black3'}
          fontSize={'small1'}>
          An additional processing fee of 1% will be included in the final
          charge. Minimum fee is $1
        </APTypography>
      </div>

      <WireTransferResultModal />
    </div>
  );
};

DotsACHTransferComponent.propTypes = {
  showNotification: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openDepositModal: PropTypes.func
};

DotsACHTransferComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    showNotification: message => {
      dispatch(
        showSnack({
          id: 'wireDepositNotification',
          text: message,
          timeout: 5000,
          type: 'warning'
        })
      );
    },
    openDepositModal: props => {
      dispatch(openModal(MODAL_TYPES.DEPOSIT_WITHDRAW_STATUS_MODAL, props));
    }
  };
};

export const DotsTransfer = connect(
  null,
  mapDispatchToProps
)(withDotsAuth(DotsACHTransferComponent));
