import { UrlFactory } from '../helpers/urlFactory';

const regExpForId = '(w*[0-9]*.*-*)';

const LINKS = {
  DASHBOARD: {
    getPath: () => UrlFactory.getDashboardUrl(),
    getName: () => 'Dashboard'
  },
  OFFERINGS: {
    getPath: () => UrlFactory.getOfferingsUrl(),
    getName: () => 'Offerings'
  },
  OFFERINGS_ASSET_DETAILS: {
    getPath: ({ assetId }) => UrlFactory.getOfferingsAssetDetailsUrl(assetId),
    getName: () => 'Asset Details'
  },
  WALLETS: {
    getPath: () => UrlFactory.getWalletsUrl(),
    getName: () => 'Wallets'
  },
  PRO_EXCHANGE: {
    getPath: () => UrlFactory.getProExchangeUrl(),
    getName: () => 'Pro Exchange'
  },
  MARKET_PLACE: {
    getPath: () => UrlFactory.getMarketPlaceUrl(),
    getName: () => 'Market Place'
  },
  MARKET_PLACE_ASSET_DETAILS: {
    getPath: ({ assetId }) => UrlFactory.getMarketPlaceAssetDetailsUrl(assetId),
    getName: () => 'Asset Details'
  },
  MY_COLLECTIBLES: {
    getPath: () => UrlFactory.getMyCollectiblesUrl(),
    getName: () => 'My Collectibles'
  },
  MY_COLLECTIBLES_ASSET_DETAILS: {
    getPath: ({ assetId }) =>
      UrlFactory.getMyCollectiblesAssetDetailsUrl(assetId),
    getName: () => 'Asset Details'
  },
  USER_SETTINGS: {
    getPath: () => UrlFactory.getUserSettingsUrl(),
    getName: () => ['Settings', 'Profile & Security']
  },
  IBA_SETTINGS: {
    getPath: () => UrlFactory.getIbaSettingsUrl(),
    getName: () => ['Settings', 'Iba']
  },
  TRADE_REPORTS_SETTINGS: {
    getPath: () => UrlFactory.getTradeReportsSettingsUrl(),
    getName: () => ['Settings', 'Trade Reports']
  },
  API_KEYS_SETTINGS: {
    getPath: () => UrlFactory.getApiKeysSettingsUrl(),
    getName: () => ['Settings', 'Api keys']
  },
  CONTACTS_SETTINGS: {
    getPath: () => UrlFactory.getContactsSettingsUrl(),
    getName: () => ['Settings', 'Contacts']
  },
  AFFILIATE_SETTINGS: {
    getPath: () => UrlFactory.getAffiliateSettingsUrl(),
    getName: () => ['Settings', 'Affiliate']
  },
  LOYALTY_TOKEN_SETTINGS: {
    getPath: () => UrlFactory.getLoyaltyTokenSettingsUrl(),
    getName: () => ['Settings', 'Loyalty Token']
  },
  VERIFICATION_LEVEL_SETTINGS: {
    getPath: () => UrlFactory.getVerificationLevelSettingsUrl(),
    getName: () => ['Settings', 'Verification Level']
  },
  DEMO_SETTINGS: {
    getPath: () => UrlFactory.getDemoSettingsUrl(),
    getName: () => ['Settings', 'Demo']
  },
  TIERS: {
    getPath: () => UrlFactory.getTiersExplainedUrl(),
    getName: () => "Badges"
  },
  COMMUNITY: {
    getPath: () => UrlFactory.getTiersExplainedUrl(),
    getName: () => "Community"
  }
};

export const ROUTES = [
  {
    path: '/dashboard',
    links: [LINKS.DASHBOARD]
  },
  {
    path: '/offerings',
    links: [LINKS.DASHBOARD, LINKS.OFFERINGS]
  },
  {
    path: `/offerings/asset-details/${regExpForId}`,
    links: [LINKS.DASHBOARD, LINKS.OFFERINGS, LINKS.OFFERINGS_ASSET_DETAILS]
  },
  {
    path: '/wallets',
    links: [LINKS.DASHBOARD, LINKS.WALLETS]
  },
  {
    path: '/pro-marketplace',
    links: [LINKS.DASHBOARD, LINKS.PRO_EXCHANGE]
  },
  {
    path: '/market-place',
    links: [LINKS.DASHBOARD, LINKS.MARKET_PLACE]
  },
  {
    path: `/market-place/asset-details/${regExpForId}`,
    links: [
      LINKS.DASHBOARD,
      LINKS.MARKET_PLACE,
      LINKS.MARKET_PLACE_ASSET_DETAILS
    ]
  },
  {
    path: '/my-collectibles',
    links: [LINKS.DASHBOARD, LINKS.MY_COLLECTIBLES]
  },
  {
    path: `/my-collectibles/asset-details/${regExpForId}`,
    links: [
      LINKS.DASHBOARD,
      LINKS.MY_COLLECTIBLES,
      LINKS.MY_COLLECTIBLES_ASSET_DETAILS
    ]
  },
  {
    path: '/settings/user',
    links: [LINKS.DASHBOARD, LINKS.USER_SETTINGS]
  },
  {
    path: '/settings/trade-reports',
    links: [LINKS.DASHBOARD, LINKS.TRADE_REPORTS_SETTINGS]
  },
  {
    path: '/settings/api-keys',
    links: [LINKS.DASHBOARD, LINKS.API_KEYS_SETTINGS]
  },
  {
    path: '/settings/contacts',
    links: [LINKS.DASHBOARD, LINKS.CONTACTS_SETTINGS]
  },
  {
    path: '/settings/affiliate',
    links: [LINKS.DASHBOARD, LINKS.AFFILIATE_SETTINGS]
  },
  {
    path: '/settings/loyalty-token',
    links: [LINKS.DASHBOARD, LINKS.LOYALTY_TOKEN_SETTINGS]
  },
  {
    path: '/settings/verification-level',
    links: [LINKS.DASHBOARD, LINKS.VERIFICATION_LEVEL_SETTINGS]
  },
  {
    path: '/settings/demo-settings',
    links: [LINKS.DASHBOARD, LINKS.DEMO_SETTINGS]
  },
  {
    path: '/tiers',
    links: [LINKS.DASHBOARD, LINKS.TIERS]
  }
];
