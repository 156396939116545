import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../../helpers/cssClassesHelper';
import './WithDrawalFormContainer.css';
import { userIbaneraTestingSelector } from '../../../../redux/selectors/userConfigSelector';
import { DotsWithdrawal } from './DotsWithdrawal/DotsWithdrawalContainer';
const baseClasses = getBEMClasses('withdraw-form');

const withdrawVariants = {
  WIRE: 'wire',
  DOTS: 'Dots'
};

const WithDrawalForm = props => {
  const { setSelectedWithdrawProvider } = props;
  const [chosenWithdrawal, setChosenWithdrawal] = useState(
    withdrawVariants.DOTS
  );

  useEffect(
    () => {
      setSelectedWithdrawProvider(chosenWithdrawal);
    },
    [chosenWithdrawal]
  );

  return (
    <React.Fragment>
      <div className={baseClasses('title-container')}>
        <div
          className={baseClasses(
            'title',
            chosenWithdrawal === withdrawVariants.DOTS && 'selected'
          )}
          onClick={() => setChosenWithdrawal(withdrawVariants.DOTS)}>
          Multi-Payout
        </div>
      </div>
      {chosenWithdrawal === withdrawVariants.DOTS && (
        <DotsWithdrawal
          closeModal={props.toggleModal}
          onFormChange={props.onFormChange}
        />
      )}
    </React.Fragment>
  );
};

WithDrawalForm.propTypes = {
  onFormChange: PropTypes.func,
  toggleModal: PropTypes.func,
  isTestUser: PropTypes.bool,
  setSelectedWithdrawProvider: PropTypes.func
};

WithDrawalForm.defaultProps = {
  onFormChange: () => {}
};

WithDrawalForm.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const isTestUser = userIbaneraTestingSelector(state);
  return { isTestUser };
};

export const WithDrawalFormContainer = connect(
  mapStateToProps,
  null
)(WithDrawalForm);
