import React from 'react';
import PropTypes from 'prop-types';

import './VoteButtons.css';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import {
  VOTE_NO,
  VOTE_STATUSES,
  VOTE_TYPES,
  VOTE_YES
} from '../../../constants/votingConstants';

const baseClass = 'vote-buttons';

export const VoteButtonsComponent = (props, context) => {
  const {
    voteStatus,
    voteType,
    customClasses,
    voteOnMarketCard,
    openVoteModal,
    ProductId,
    onVote,
    isVoted
  } = props;
  const classes = getBEMClasses(baseClass, customClasses);
  const disabled = voteStatus !== VOTE_STATUSES.LIVE;

  if (voteStatus === VOTE_STATUSES.LIVE) {
    if (isVoted) {
      return (
        <button disabled={disabled} className={classes('voted')}>
          {context.t('VOTED')}
        </button>
      );
    }
  }

  if (voteStatus === VOTE_STATUSES.LIVE) {
    if (voteOnMarketCard) {
      return (
        <button
          onClick={() => openVoteModal({ ProductId: ProductId })}
          className={classes('incoming')}>
          {context.t('VOTE')}
        </button>
      );
    }

    return (
      <div className={classes('on-vote-buttons')}>
        <button
          className={classes('vote-yes')}
          onClick={() => onVote(VOTE_YES)}>
          {voteType === VOTE_TYPES.AUCTION
            ? context.t('Yes, I want to auction off my collectible.')
            : context.t('Yes, I want to accept this buyout offer.')}
        </button>

        <button className={classes('vote-no')} onClick={() => onVote(VOTE_NO)}>
          {voteType === VOTE_TYPES.AUCTION
            ? context.t("No, I don't want to sell my collectible.")
            : context.t("No, I don't want to accept this buyout offer.")}
        </button>
      </div>
    );
  }

  return null;
};

VoteButtonsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

VoteButtonsComponent.propTypes = {
  voteStatus: PropTypes.string,
  voteType: PropTypes.string,
  customClasses: PropTypes.string,
  voteOnMarketCard: PropTypes.bool,
  openVoteModal: PropTypes.func,
  ProductId: PropTypes.number,
  onVote: PropTypes.func,
  isVoted: PropTypes.bool
}
