import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import APIcon from '../APIcon/APIcon';

import './Slider3D.css';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
const classes = getBEMClasses('slider3D');

export const Slider3D = props => {
  const {
    items,
    onChange = () => {},
    depthStep = -150,
    offsetXStep = 260,
    offsetYStep = 10,
    rotateAngle = -5,
    fadeStep = 1.25,
    zIndex = 10,
    baseClass = () => '',
    onSelectedItemPress
  } = props;
  const [cardsOrder, setCardsOrder] = React.useState([]);
  const startingClientX = React.useRef(-1);

  React.useEffect(
    () => {
      let obj = {};
      items.forEach((item, index) => {
        obj[item] = index;
      });
      setCardsOrder(obj);
    },
    [items.length]
  );

  const getOrderOfContent = cardsOrder => {
    let res = [];
    const cardsEntries = Object.entries(cardsOrder);
    for (let i = 0; i < cardsEntries.length; i += 1) {
      res.push(cardsEntries.find(entry => entry[1] === i)[0]);
    }
    return res;
  };

  const moveOrder = item => {
    const copy = [];
    const res = {};
    const cardsOrderValues = getOrderOfContent(cardsOrder);

    if (cardsOrder[item] === cardsOrder[cardsOrder.length - 1]) {
      for (let i = 0; i < cardsOrderValues.length - 1; i += 1) {
        copy[i + 1] = cardsOrderValues[i];
      }
      copy[0] = cardsOrderValues[cardsOrderValues.length - 1];
    } else {
      for (let i = 1; i < cardsOrderValues.length; i += 1) {
        copy[i - 1] = cardsOrderValues[i];
      }
      copy[cardsOrderValues.length - 1] = cardsOrderValues[0];
    }

    copy.forEach((elem, index) => {
      res[elem] = index;
    });
    setCardsOrder(res);
    onChange(getOrderOfContent(res)[0]);
  };

  const handleClick = item => {
    const res = {};
    const cardsOrderValues = getOrderOfContent(cardsOrder);
    const copy = [...cardsOrderValues];

    for (let i = 0; i < cardsOrderValues.length; i += 1) {
      if (cardsOrderValues[i] === item) {
        break;
      }
      copy[i + 1] = cardsOrderValues[i];
    }
    copy[0] = item;

    copy.forEach((elem, index) => {
      res[elem] = index;
    });
    setCardsOrder(res);
    onChange(getOrderOfContent(res)[0]);
  };

  const handleArrow = isRight => {
    if (isRight) {
      moveOrder(getOrderOfContent(cardsOrder)[1]);
    } else {
      moveOrder(getOrderOfContent(cardsOrder)[cardsOrder.length - 1]);
    }
  };

  const calculateWidthForNCards = n => {
    return (7 * Math.pow(n, 3) - 71 * Math.pow(n, 2) + 252 * n + 200) / 2 + 20;
  };

  const calculateWidth = () => {
    const n = items.length;
    const totalLength = calculateWidthForNCards(n) + 106;
    const for4Cards = calculateWidthForNCards(3) + 106;
    return totalLength > for4Cards ? for4Cards : totalLength;
  };

  return (
    <div
      className={`${classes('wrapper')} ${baseClass('wrapper')}`}
      style={{
        maxWidth: `${calculateWidth()}px`,
        minWidth: `${calculateWidthForNCards(1)}px`
      }}
      onTouchStart={e => {
        startingClientX.current = e.touches[0].clientX;
      }}
      onTouchEnd={e => {
        const destClientX = e.changedTouches[0].clientX;

        if (Math.abs(startingClientX.current - destClientX) < 30) {
          // return if touch wan't a gesture but was a click
          return;
        }

        if (startingClientX.current > destClientX) {
          // swipe was to the left
          handleArrow(true);
        } else {
          // swipe was to the right
          handleArrow(false);
        }
      }}>
      <div
        className={`${classes('arrowWrapper', ['left'])} ${baseClass(
          'arrowWrapper',
          ['left']
        )}`}>
        <div
          className={`${classes('arrow', ['left'])} ${baseClass('arrow', [
            'left'
          ])}`}
          onClick={() => {
            handleArrow(false);
          }}>
          <APIcon
            name="mobileSliderArrow"
            customClass={`${classes('navigation-icon')} ${baseClass(
              'navigation-icon'
            )}`}
          />
        </div>
      </div>
      <div className={`${classes('cards')} ${baseClass('cards')}`}>
        {items.map((item, index) => {
          return (
            <Item
              key={`${item}-${index}`}
              onClick={() => {
                if (cardsOrder[item]) {
                  handleClick(item);
                } else {
                  onSelectedItemPress();
                }
              }}
              depth={cardsOrder[item] * depthStep}
              offsetX={cardsOrder[item] * offsetXStep}
              offsetY={cardsOrder[item] * offsetYStep}
              rotateAngle={rotateAngle}
              fade={cardsOrder[item] * fadeStep}
              zIndex={cardsOrder[item] * zIndex}
              url={item}
              baseClass={baseClass}
            />
          );
        })}
      </div>
      <div
        className={`${classes('arrowWrapper', ['right'])} ${baseClass(
          'arrowWrapper',
          ['right']
        )}`}>
        <div
          className={`${classes('arrow', ['right'])} ${baseClass('arrow', [
            'right'
          ])}`}
          onClick={() => {
            handleArrow(true);
          }}>
          <APIcon
            name="mobileSliderArrow"
            customClass={`${classes('navigation-icon')} ${baseClass(
              'navigation-icon'
            )}`}
          />
        </div>
      </div>
    </div>
  );
};

Slider3D.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired, // array of images (url) that will be shown in carousel
  depthStep: PropTypes.number,
  offsetXStep: PropTypes.number,
  offsetYStep: PropTypes.number,
  rotateAngle: PropTypes.number,
  fadeStep: PropTypes.number,
  zIndex: PropTypes.number,
  baseClass: PropTypes.func,
  onChange: PropTypes.func,
  onSelectedItemPress: PropTypes.func
};

const Item = props => {
  const {
    url,
    depth = 0,
    offsetX = 0,
    offsetY = 0,
    rotateAngle = 0,
    fade = 0,
    zIndex,
    onClick,
    baseClass
  } = props;
  return (
    <div
      className={`${classes('card')} ${baseClass('card')}`}
      style={{
        zIndex: 1000 - zIndex || 10,
        filter: `blur(${fade > 10 ? 10 : fade}px)`,
        transform: `translateZ(${depth}px) translateX(${offsetX}px) translateY(${offsetY}px) rotateY(${
          rotateAngle < -28 ? -28 : rotateAngle
        }deg)`
      }}
      onClick={onClick}>
      <img
        src={url}
        alt="card"
        className={`${classes('image')} ${baseClass('image')}`}
      />
      <div
        className={`${classes('imageWhiteout')} ${baseClass('imageWhiteout')}`}
        style={{
          background: `rgba(255, 255, 255, ${fade / 5 > 1 ? 1 : fade / 5})`
        }}
      />
      <div
        className={classNames({
          [classes('imageShadow')]: true,
          [classes('imageShadowGone')]: fade / 5 >= 1,
          [baseClass('imageShadow')]: baseClass,
          [baseClass('imageShadowGone')]: baseClass && fade / 5 >= 1
        })}
      />
    </div>
  );
};

Item.propTypes = {
  url: PropTypes.string.isRequired,
  depth: PropTypes.number,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  rotateAngle: PropTypes.number,
  baseClass: PropTypes.func,
  zIndex: PropTypes.number,
  fade: PropTypes.number,
  onClick: PropTypes.func.isRequired
};
