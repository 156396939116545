import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

import APIcon from '../../components/common/APIcon';
import { APTypography } from '../../components/common/APTypography';

const PageHeaderNavMenuComponent = (props, context) => {
  const { customClass, setNavBarExpanded, navBarExpanded, navItems } = props;
  const location = useLocation();

  const navButtons = [{ isToggle: true }, ...navItems, { isLast: true }];

  const activeSectionIndex = useMemo(
    () => navButtons.findIndex(i => location.pathname.includes(i.highlightUrl)),
    [navButtons, location.pathname]
  );

  const handleClick = () => {
    setNavBarExpanded(!navBarExpanded);
  };

  const getCustomClassForRoundedPart = index =>
    activeSectionIndex === index
      ? 'selectedPart'
      : activeSectionIndex - 1 === index
        ? 'beforeSelectedPart'
        : activeSectionIndex + 1 === index
          ? 'afterSelectedPart'
          : '';

  return (
    <div className={customClass('navButtons')} id="navButtons">
      {navButtons.map((item, index) => (
        <div
          key={item.path || index}
          className={`${
            activeSectionIndex > -1
              ? customClass(getCustomClassForRoundedPart(index))
              : ''
          } ${item.isToggle ? customClass('toggleButtonWrapper') : ''}`}>
          {item.isToggle ? (
            <div
              className={customClass('menuToggle', ['hoverable'])}
              onClick={handleClick}>
              <APIcon
                name={navBarExpanded ? 'navArrowBack' : 'navArrowForward'}
              />
            </div>
          ) : item.isLast ? (
            <div className={customClass('item')} />
          ) : (
            <NavLink
              to={item.path}
              className={`${customClass('item', [
                'hoverable'
              ])} ${!navBarExpanded && customClass('collapsedMargin')}`}
              activeClassName={customClass('item', ['selected'])}
              isActive={(match, location) => {
                const currentNavItemIndex = navButtons.findIndex(i =>
                  location.pathname.includes(i.highlightUrl)
                )
                return index === currentNavItemIndex;
              }}>
              <div className={customClass('onboarding-wrapper')} id={item.id}>
                <div {...!navBarExpanded && { title: item.text }}>
                  <APIcon name={item.icon} customClass={customClass('icon')} />
                </div>
                {navBarExpanded && (
                  <APTypography
                    fontSize="body"
                    weight="weight500"
                    className={customClass('label')}>
                    {context.t(item.text)}
                  </APTypography>
                )}
              </div>
            </NavLink>
          )}
        </div>
      ))}
    </div>
  );
};

PageHeaderNavMenuComponent.propTypes = {
  customClass: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  items: PropTypes.array,
  setNavBarExpanded: PropTypes.func,
  navBarExpanded: PropTypes.bool,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired
    })
  ).isRequired
};

PageHeaderNavMenuComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PageHeaderNavMenuComponent;
