
import { useMedia } from 'react-use';

export const useIsMobile = () => {
  const isMobile = useMedia('(max-width: 576px)');
  return isMobile;
};

export const useIsTablet = () => {
  const isTablet = useMedia('(max-width: 800px)');
  return isTablet;
};
