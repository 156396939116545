import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { setSpotlightId } from '../../redux/actions/spotlightActions';
import './styles.css';

export const spotlightDecorator = id => {
  const mapStateToProps = state => ({
    isSpotlighted: state.spotlight.id === id
  });

  const mapDispatchToProps = dispatch => {
    return {
      close: () => dispatch(setSpotlightId(null))
    };
  };

  return WrappedComponent => {
    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(props => {
      const { isSpotlighted, close, ...restProps } = props;
      const wrapperRef = useRef();
      useEffect(
        () => {
          isSpotlighted &&
            wrapperRef.current &&
            wrapperRef.current.scrollIntoView(false);
        },
        [isSpotlighted]
      );
      // TODO(Jul 28, 2022): wrap only if isSpotlighted is true?
      return (
        <div
          ref={wrapperRef}
          onClickCapture={close}
          className={isSpotlighted ? 'spotlight-decorated' : ''}>
          <WrappedComponent {...restProps} />
        </div>
      );
    });
  };
};
