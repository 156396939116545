import React from 'react';

export const DeleteIcon = props => {
  return (
    <svg viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.409 0.735678L9.99995 0H3.99994L3.59085 0.735678H0.5V2.75859H13.5V0.735678H10.409Z" />
      <path d="M1.46875 14.024C1.46875 15.0582 2.32347 15.8965 3.37781 15.8965H10.6222C11.6765 15.8965 12.5312 15.0582 12.5312 14.024V3.75854H1.46875V14.024ZM3.79788 7.72701L4.85847 6.68662L7.00011 8.78725L9.14164 6.68674L10.2023 7.72701L8.0607 9.82764L10.2022 11.9281L9.14164 12.9685L7 10.8679L4.85847 12.9684L3.79776 11.9281L5.93941 9.82764L3.79788 7.72712V7.72701Z" />
    </svg>
  );
};
