import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tab from 'apex-web/lib/components/common/Tab/Tab';
import OpenOrdersContainer from './OpenOrders';
import FilledOrdersContainer from './FilledOrders';
import InactiveOrdersContainer from './InactiveOrders';
import TradeReportsContainer from './TradeReports';
import DepositsStatusContainer from './DepositsStatus';
import WithdrawStatusContainer from './WithdrawStatus';
import HeaderWithFilterContainer from './HeaderWithFilter';

import './OrderHistoryComponent.css';
import './OrderHistoryTables.css';
import { selectedInstrumentSelector } from 'apex-web/lib//redux/selectors/instrumentPositionSelectors';

class OrderHistoryComponent extends Component {
  _getPanes() {
    const { config, selectedInstrument } = this.props;

    const panes = [
      {
        menuItem: OpenOrdersContainer.title,
        render: () => (
          <OpenOrdersContainer
            config={config}
            selectedInstrument={selectedInstrument}
          />
        )
      },
      {
        menuItem: FilledOrdersContainer.title,
        render: () => (
          <FilledOrdersContainer
            config={config}
            selectedInstrument={selectedInstrument}
          />
        )
      },
      {
        menuItem: InactiveOrdersContainer.title,
        render: () => (
          <InactiveOrdersContainer
            config={config}
            selectedInstrument={selectedInstrument}
          />
        )
      },
      {
        menuItem: TradeReportsContainer.title,
        render: () => (
          <TradeReportsContainer
            config={config}
            selectedInstrument={selectedInstrument}
          />
        )
      },
      {
        menuItem: DepositsStatusContainer.title,
        render: () => (
          <DepositsStatusContainer
            config={config}
            selectedInstrument={selectedInstrument}
          />
        )
      },
      {
        menuItem: WithdrawStatusContainer.title,
        render: () => (
          <WithdrawStatusContainer
            config={config}
            selectedInstrument={selectedInstrument}
          />
        )
      }
    ];

    return panes;
  }

  render() {
    return (
      <React.Fragment>
        {this.props.useHeaderWithFilter && <HeaderWithFilterContainer />}
        <Tab
          panes={this._getPanes()}
          classModifiers={this.props.classModifiers}
          customClass="order-history"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, { config }) => ({
  selectedInstrument:
    config.filterMode === 'selectedInstrument'
      ? selectedInstrumentSelector(state)
      : undefined
});

OrderHistoryComponent.propTypes = {
  selectedInstrument: PropTypes.any,
  useHeaderWithFilter: PropTypes.bool,
  classModifiers: PropTypes.string,
  config: PropTypes.any
};

export default connect(mapStateToProps)(OrderHistoryComponent);
