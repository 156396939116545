import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { getPublicAssetAttributes } from '../../../api/liquidApi';
import PageFooterLayout from '../../../layouts/PageFooterLayout/PageFooterLayout';
import './styles.css';
import { connect } from 'react-redux';
import { receiveProductAttributes } from 'apex-web/lib/redux/actions/productActions';
import { CoOwnPage } from '..';
import PublicDetailsHeader from '../../PublicAssetDetailsPage/Header';

const classes = getBEMClasses('public-co-own');

const PublicCoOwnComponent = props => {
  const { setAssetAttributes, ...restProps } = props;
  const collectiblesIds = get(props, 'main.collectiblesIds', []);
  useEffect(() => {
    collectiblesIds.forEach(id => {
      getPublicAssetAttributes(id)
        .then(attributes => {
          setAssetAttributes(id, attributes);
        });
    });
  }, [collectiblesIds.length]);

  return (
    <div className={classes()}>
      <PublicDetailsHeader />
      <CoOwnPage
        {...restProps}
        isPublic
      />
      <PageFooterLayout />
    </div>
  );
};

PublicCoOwnComponent.propTypes = {
  setAssetAttributes: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setAssetAttributes: (assetId, attributes) => receiveProductAttributes(
    Object.entries(attributes).map(([key, value]) => ({
      KeyName: key,
      KeyValue: value,
      OMSId: 1,
      ProductId: assetId
    })),
    assetId
  )
};

export default connect(null, mapDispatchToProps)(PublicCoOwnComponent);
