/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import { Textfit } from 'react-textfit';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { Link } from 'react-router-dom';
import './CurrenciesSummary.css';
import { Table } from '../../../components/common/Table';
import { APTypography } from '../../../components/common/APTypography';
import { formatNumberToLocale } from '../../../helpers/numberFormatter';
import BigNumber from 'bignumber.js';

const classes = getBEMClasses('currencies-table');
const MIN_ITEM_FONT_SIZE = 6;

export const CurrenciesSummary = (props, context) => {
  const isMaxWidth576px = useMedia('(max-width: 576px)');
  const isMaxWidth400px = useMedia('(max-width: 400px)');
  const maxItemFontSize = isMaxWidth400px ? 9.6 : isMaxWidth576px ? 11.2 : 16;
  const columns = [
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={classes('columnHeader')}>
          {context.t('Name')}
        </APTypography>
      ),
      dataTest: 'Currency',
      cell: row => (
        <Link
          className={classes('columnItem')}
          to={row.detailsLink}>
          <div
            className={classes('currencyColor')}
            style={{ backgroundColor: row.currencyColor }}
          />
          <APTypography
            fontSize="body">
            {row.currency}
          </APTypography>
        </Link>
      )
    },
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={classes('columnHeader')}>
          {context.t('Amount')}
        </APTypography>
      ),
      dataTest: 'Amount of tokens',
      cell: row => (
        <Textfit
          mode="single"
          min={MIN_ITEM_FONT_SIZE}
          max={maxItemFontSize}
          className={classes('columnItem', 'fullWidth')}>
          {row.productSymbol === 'USD' ? '-' : row.amount.toLocaleString()}
        </Textfit>
      )
    },
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={classes('columnHeader')}>
          {context.t('Avg Cost')}
        </APTypography>
      ),
      dataTest: 'DCA',
      cell: row => (
        <Textfit
          mode="single"
          min={MIN_ITEM_FONT_SIZE}
          max={maxItemFontSize}
          className={classes('columnItem', 'fullWidth')}>
          {!row.dca
            ? context.t('N/A')
            : row.dca >= 0
              ? `$${formatNumberToLocale(row.dca, 4)}`
              : `-$${formatNumberToLocale(-row.dca, 4)}`}
        </Textfit>
      )
    },
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={classes('columnHeader')}>
          {context.t('Total')}
        </APTypography>
      ),
      dataTest: 'Amount',
      cell: row => (
        <Textfit
          mode="single"
          min={MIN_ITEM_FONT_SIZE}
          max={maxItemFontSize}
          className={classes('columnItem', 'fullWidth')}>
          ${row.amountUSD.toLocaleString()}
        </Textfit>
      )
    },
    {
      header: (
        <APTypography
          fontSize="body"
          weight="weight600"
          className={classes('columnHeader')}>
          {context.t('WALLET %')}
        </APTypography>
      ),
      dataTest: 'Percentage',
      cell: row => (
        <APTypography fontSize="body" className={classes('columnItem')}>
          {row.percentage}%
        </APTypography>
      )
    }
  ];

  return (
    <div
      className={classes('wrapper')}
      style={{ '--col-count': columns.length }}>
      <Table
        rows={props.items}
        columns={columns}
        baseClass={classes}
        headerOutside={true}
        empty={'N/A'}
      />
    </div>
  );
};

CurrenciesSummary.contextTypes = {
  t: PropTypes.func.isRequired
};

CurrenciesSummary.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string.isRequired,
      currencyColor: PropTypes.string.isRequired,
      amountUSD: PropTypes.string.isRequired,
      percentage: PropTypes.string.isRequired,
      dca: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.instanceOf(BigNumber)
      ]),
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          actionName: PropTypes.string.isRequired,
          action: PropTypes.func.isRequired
        })
      )
    })
  ),
};
