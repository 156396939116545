import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { Modal } from '../../../../common/Modal/Modal';
import { getBEMClasses } from '../../../../../helpers/cssClassesHelper';
import { modalDecorator } from '../../../../common/Modal/modalDecorator';
import {
  MODAL_TYPES,
  openModal
} from '../../../../../redux/actions/modalActions';
import { sendBuyOutEmail } from '../../../../../api/liquidApi';
import { BUY_OUT_RESULTS } from '../BuyOutModalComponent';
import './BuyOutConfirmationModal.css';

const classes = getBEMClasses('buy-out-confirmation-form');

const BuyOutConfirmationModalComponent = (props, context) => {
  const { isOpen, close, data, openResultModal, closeBuyOut } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSendEmail = () => {
    setIsLoading(true);
    sendBuyOutEmail(data)
      .then(() => {
        closeBuyOut();
        openResultModal(BUY_OUT_RESULTS.SUCCESS);
      })
      .catch(() => {
        openResultModal(BUY_OUT_RESULTS.REJECT);
      })
      .finally(() => {
        setIsLoading(false);
        close();
      });
  };

  return (
    <Modal
      customClass={classes()}
      isOpen={isOpen}
      close={isLoading ? () => {} : close}
      showCloseIcon={false}
      titleComponent={
        <div className={classes('header')}>{context.t('Confirmation')}</div>
      }>
      {isLoading && (
        <div
          className={classes('loading-overlay')}
          onClick={e => {
            e.stopPropagation();
          }}>
          <Spinner />
        </div>
      )}
      <div className={classes('description')}>
        {context.t(
          'Once you have submitted this form, your request is binding. Token holders will be given 48 hours to accept or decline your offer. Until the 48 hours have passed, your funds necessary to perform the buyout, including any necessary USD and this collectible’s tokens, will be frozen. If your offer is accepted, it will be processed immediately. If your offer is declined, then your funds will be active again.'
        )}
      </div>
      <div className={classes('buttons')}>
        <button className={classes('go-back')} onClick={close} type="button">
          {context.t('Go Back')}
        </button>
        <button className={classes('submit')} onClick={handleSendEmail}>
          {context.t('Submit')}
        </button>
      </div>
    </Modal>
  );
};

BuyOutConfirmationModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

BuyOutConfirmationModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  data: PropTypes.object,
  openResultModal: PropTypes.func,
  closeBuyOut: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  openResultModal: result =>
    dispatch(openModal(MODAL_TYPES.BUY_OUT_RESULT_MODAL, { result }))
});

const BuyOutConfirmationModalContainer = connect(
  null,
  mapDispatchToProps
)(BuyOutConfirmationModalComponent);

export default modalDecorator({ name: MODAL_TYPES.BUY_OUT_CONFIRMATION_MODAL })(
  BuyOutConfirmationModalContainer
);
