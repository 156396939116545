import { setUserConfig } from 'apex-web/lib/redux/actions/userActions';
import { traceUser } from '../../api/cloudflareApi';

export const USER_CONFIG_RECEIVED = 'USER_CONFIG_RECEIVED';
export const REMINDER_WAS_SHOWN = 'REMINDER_WAS_SHOWN';
export const USER_BALANCE_RECEIVED = 'USER_BALANCE_RECEIVED';
export const VERIFICATION_REMINDER_WAS_SHOWN =
  'VERIFICATION_REMINDER_WAS_SHOWN';
export const VERIFICATION_INFO_RECEIVED = 'VERIFICATION_INFO_RECEIVED';
export const USER_COUNTRY_RECEIVED = 'USER_COUNTRY_RECEIVED';
export const MAINTENANCE_STATE_CHANGED = 'MAINTENANCE_STATE_CHANGED';
export const SET_USER_EXP = 'SET_USER_EXP';
export const RESET_USER_CONFIG = 'RESET_USER_CONFIG';

export const RESET_USER = 'RESET_USER';

export const setIsDotsVerificationFinished = () => (dispatch, getState) => {
  const {
    user: { userInfo }
  } = getState();
  dispatch(
    setUserConfig(userInfo.UserId, userInfo.UserName, [
      { Key: 'IsDotsVerficationFinished', Value: true }
    ])
  );
};

export const setIsOnboardingWasShown = () => (dispatch, getState) => {
  const {
    user: { userInfo }
  } = getState();
  dispatch(
    setUserConfig(userInfo.UserId, userInfo.UserName, [
      { Key: 'IsOnboardingWasShown', Value: true }
    ])
  );
};

export const setIsHiddenFromTop10 = isHiddenFromTop10 => (
  dispatch,
  getState
) => {
  const {
    user: { userInfo }
  } = getState();
  dispatch(
    setUserConfig(userInfo.UserId, userInfo.UserName, [
      { Key: 'isHiddenFromTop10', Value: JSON.stringify(isHiddenFromTop10) }
    ])
  );
};

export const setIsTermsOfUseRead = () => (dispatch, getState) => {
  const {
    user: { userInfo }
  } = getState();
  dispatch(
    setUserConfig(userInfo.UserId, userInfo.UserName, [
      { Key: 'IsTermsOfUseAccepted', Value: true }
    ])
  );
};

export const resolveUserCountry = () => async dispatch => {
  try {
    const userInfo = await traceUser();
    dispatch(userCountryReceived(userInfo.loc));
  } catch (e) {
    console.error(e);
  }
};

export const setIsBalanceReceived = () => ({
  type: USER_BALANCE_RECEIVED
});

export const setIsReminderWasShown = () => ({
  type: REMINDER_WAS_SHOWN
});

export const setIsUserConfigReceived = () => ({
  type: USER_CONFIG_RECEIVED
});

export const setIsVerificationReminderWasShown = () => ({
  type: VERIFICATION_REMINDER_WAS_SHOWN
});

export const setIsVerificationInfoReceived = () => ({
  type: VERIFICATION_INFO_RECEIVED
});

export const userCountryReceived = country => ({
  type: USER_COUNTRY_RECEIVED,
  payload: { country }
});

export const maintenanceStateChanged = isMaintenance => ({
  type: MAINTENANCE_STATE_CHANGED,
  payload: { isMaintenance }
});

export const setUserExp = payload => ({
  type: SET_USER_EXP,
  payload
});

export const resetUserConfig = () => ({
  type: RESET_USER_CONFIG
});

export const resetUser = () => ({
  type: RESET_USER
});
