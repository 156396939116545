import { connect } from 'react-redux';
import { MyTopCollectibleSection } from './MyTopCollectibleSection';

import { topCollectibleAssetWithAttribute } from '../../../redux/selectors/productsSelector';
import { baseCurrencySelector } from 'apex-web/lib/redux/selectors/baseCurrenciesSelectors';

const mapStateToProps = state => {
  const topCollectible = topCollectibleAssetWithAttribute(state);
  const baseCurrency = baseCurrencySelector(state);
  const isReady =
    Object.keys(topCollectible).length !== 0 &&
    typeof topCollectible.product !== 'undefined' &&
    typeof topCollectible.product.productAttributes !== 'undefined';
  return { topCollectible, isReady, baseCurrency };
};

export const MyTopCollectibleSectionContainer = connect(
  mapStateToProps,
  null
)(MyTopCollectibleSection);
