import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import { receiveProductAttributes } from 'apex-web/lib/redux/actions/productActions';

export const SET_PRODUCT_ATTRIBUTES_LOADED = 'SET_PRODUCT_ATTRIBUTES_LOADED';

const setAllProductAttibutesLoaded = () => ({
  type: SET_PRODUCT_ATTRIBUTES_LOADED
});

export const getProductAttributes = products => async (dispatch, getState) => {
  products.forEach((product, index) => {
    dispatch(
      callAPI('GetProductAttributes', {
        OMSId: getState().user.userInfo.OMSId || 1,
        ProductId: product.ProductId
      })
    ).then(res => {
      if (res.length) {
        dispatch(receiveProductAttributes(res, product.ProductId));
      }

      if (products.length === index + 1) {
        dispatch(setAllProductAttibutesLoaded());
      }
    });
  });
};
