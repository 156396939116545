import React from 'react';
import PropTypes from 'prop-types';
import './BreadCrumbsLayout.css';
// import { APTypography } from '../../components/common/APTypography';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

const BreadCrumbs = props => {
  const {
    // links: { links },
    // history,
    // locationParams,
    children,
    customClass
    // style
  } = props;
  const baseClasses = getBEMClasses('breadcrumbs', customClass);

  return (
    <React.Fragment>
      {/* <div className={baseClasses()} style={style}>
        {links.map((item, index) => {
          const name = item.getName();
          const isLastElement = index === links.length - 1;
          return (
            <div
              onClick={() => history.push(item.getPath(locationParams))}
              key={index}>
              {Array.isArray(name) ? (
                name.map((item, index) => {
                  const isLastElement = index === name.length - 1;
                  return (
                    <APTypography
                      key={`route${item}${index}`}
                      inline
                      fontSize="caption2"
                      className={baseClasses('link')}>
                      {item}
                      <span className={baseClasses('arrow-right')}>
                        {isLastElement ? '' : '>'}
                      </span>
                    </APTypography>
                  );
                })
              ) : (
                <APTypography
                  inline
                  fontSize="caption2"
                  className={baseClasses('link')}>
                  {name}
                  <span className={baseClasses('arrow-right')}>
                    {isLastElement ? '' : '>'}
                  </span>
                </APTypography>
              )}
            </div>
          );
        })}
      </div> */}
      <div className={baseClasses('content')}>{children}</div>
    </React.Fragment>
  );
};

BreadCrumbs.propTypes = {
  links: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectedBrand: PropTypes.object,
  locationParams: PropTypes.object.isRequired,
  customClass: PropTypes.string,
  children: PropTypes.any
};

export { BreadCrumbs };
