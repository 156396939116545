import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

export const ANIMATION_TYPES = {
  IDLE: 'IDLE',
  OPENING: 'OPENING',
  PROFILE: 'PROFILE'
};

const lazyImportSwitch = (animationTier, animationType, setAnimation) => {
  switch (animationTier) {
    case 1:
      if (animationType === ANIMATION_TYPES.IDLE) {
        import('../../../images/tier-badges/Novice/Novice_Idle.json').then(
          module => setAnimation(module)
        );
      } else if (animationType === ANIMATION_TYPES.OPENING) {
        import('../../../images/tier-badges/Novice/Novice_Opening.json').then(
          module => setAnimation(module)
        );
      } else {
        import('../../../images/tier-badges/Novice/Novice_Profile.json').then(
          module => setAnimation(module)
        );
      }
      break;
    case 2:
      if (animationType === ANIMATION_TYPES.IDLE) {
        import('../../../images/tier-badges/Bronze/Bronze_Idle.json').then(
          module => setAnimation(module)
        );
      } else if (animationType === ANIMATION_TYPES.OPENING) {
        import('../../../images/tier-badges/Bronze/Bronze_Opening.json').then(
          module => setAnimation(module)
        );
      } else {
        import('../../../images/tier-badges/Bronze/Bronze_Profile.json').then(
          module => setAnimation(module)
        );
      }
      break;
    case 3:
      if (animationType === ANIMATION_TYPES.IDLE) {
        import('../../../images/tier-badges/Silver/Silver_Idle.json').then(
          module => setAnimation(module)
        );
      } else if (animationType === ANIMATION_TYPES.OPENING) {
        import('../../../images/tier-badges/Silver/Silver_Opening.json').then(
          module => setAnimation(module)
        );
      } else {
        import('../../../images/tier-badges/Silver/Silver_Profile.json').then(
          module => setAnimation(module)
        );
      }
      break;
    case 4:
      if (animationType === ANIMATION_TYPES.IDLE) {
        import('../../../images/tier-badges/Gold/Gold_Idle.json').then(module =>
          setAnimation(module)
        );
      } else if (animationType === ANIMATION_TYPES.OPENING) {
        import('../../../images/tier-badges/Gold/Gold_Opening.json').then(
          module => setAnimation(module)
        );
      } else {
        import('../../../images/tier-badges/Gold/Gold_Profile.json').then(
          module => setAnimation(module)
        );
      }
      break;
    case 5:
      if (animationType === ANIMATION_TYPES.IDLE) {
        import('../../../images/tier-badges/Obsidian/Obsidian_Idle.json').then(
          module => setAnimation(module)
        );
      } else if (animationType === ANIMATION_TYPES.OPENING) {
        import('../../../images/tier-badges/Obsidian/Obsidian_Opening.json').then(
          module => setAnimation(module)
        );
      } else {
        import('../../../images/tier-badges/Obsidian/Obsidian_Profile.json').then(
          module => setAnimation(module)
        );
      }
      break;
    case 6:
      if (animationType === ANIMATION_TYPES.IDLE) {
        import('../../../images/tier-badges/Platinum/Platinum_Idle.json').then(
          module => setAnimation(module)
        );
      } else if (animationType === ANIMATION_TYPES.OPENING) {
        import('../../../images/tier-badges/Platinum/Platinum_Opening.json').then(
          module => setAnimation(module)
        );
      } else {
        import('../../../images/tier-badges/Platinum/Platinum_Profile.json').then(
          module => setAnimation(module)
        );
      }
      break;
    case 7:
      if (animationType === ANIMATION_TYPES.IDLE) {
        import('../../../images/tier-badges/Diamond/Diamond_Idle.json').then(
          module => setAnimation(module)
        );
      } else if (animationType === ANIMATION_TYPES.OPENING) {
        import('../../../images/tier-badges/Diamond/Diamond_Opening.json').then(
          module => setAnimation(module)
        );
      } else {
        import('../../../images/tier-badges/Diamond/Diamond_Profile.json').then(
          module => setAnimation(module)
        );
      }
      break;
    case 8:
      if (animationType === ANIMATION_TYPES.IDLE) {
        import('../../../images/tier-badges/BlackDiamond/BlackDiamond_Idle.json').then(
          module => setAnimation(module)
        );
      } else if (animationType === ANIMATION_TYPES.OPENING) {
        import('../../../images/tier-badges/BlackDiamond/BlackDiamond_Opening.json').then(
          module => setAnimation(module)
        );
      } else {
        import('../../../images/tier-badges/BlackDiamond/BlackDiamond_Profile.json').then(
          module => setAnimation(module)
        );
      }
      break;
    case 9:
      if (animationType === ANIMATION_TYPES.IDLE) {
        import('../../../images/tier-badges/GrandMaster/GrandMaster_Idle.json').then(
          module => setAnimation(module)
        );
      } else if (animationType === ANIMATION_TYPES.OPENING) {
        import('../../../images/tier-badges/GrandMaster/GrandMaster_Opening.json').then(
          module => setAnimation(module)
        );
      } else {
        import('../../../images/tier-badges/GrandMaster/GrandMaster_Profile.json').then(
          module => setAnimation(module)
        );
      }
      break;
    default:
      break;
  }
};

export const TierAnimationWrapper = props => {
  const {
    className,
    animation,
    height,
    width,
    loop,
    autoplay,
    onComplete,
    children,
    animationTier,
    animationType
  } = props;
  const [animationData, setAnimationData] = useState();

  useEffect(
    () => {
      if (animationTier) {
        lazyImportSwitch(animationTier, animationType, setAnimationData);
      }
    },
    [animation, animationTier]
  );

  if (!animationData) {
    return null;
  }

  return (
    <Lottie
      className={className}
      animationData={animationData}
      height={height}
      style={{ height, width }}
      width={width}
      loop={loop}
      autoplay={autoplay}
      onComplete={onComplete}>
      {children}
    </Lottie>
  );
};

TierAnimationWrapper.propTypes = {
  className: PropTypes.string,
  animation: PropTypes.any,
  height: PropTypes.any,
  width: PropTypes.any,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  onComplete: PropTypes.func,
  children: PropTypes.any,
  animationTier: PropTypes.any,
  animationType: PropTypes.string
};
