import { connect } from 'react-redux';
import get from 'lodash/get';
import InactiveOrdersComponent from './InactiveOrdersComponent';
import { inactiveOrdersSelectorFactory } from 'apex-web/lib/redux/selectors/orderHistorySelectors';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import {
  cancelOrders,
  getInactiveOrders
} from 'apex-web/lib/redux/actions/orderHistoryActions';

const makeMapStateToProps = () => {
  const inactiveOrdersSelector = inactiveOrdersSelectorFactory();

  return (state, { config, selectedInstrument }) => {
    const inactiveOrders = inactiveOrdersSelector(state, config);
    const instruments = instrumentSelectorInstruments(state);
    const {
      activeRequests,
      inactiveOrdersStartIndex
    } = state.apexCore.orderHistory;
    const fetching = activeRequests > 0;

    return {
      data: inactiveOrders,
      instruments,
      fetching,
      selectedInstrument,
      selectedAccountId: get(state, 'user.selectedAccountId', 0),
      OMSId: get(state, 'user.userInfo.OMSId', 1),
      inactiveOrdersStartIndex
    };
  };
};

const mapDispatchToProps = {
  cancelOrders,
  getInactiveOrders
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(InactiveOrdersComponent);
