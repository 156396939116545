import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { changeTickersInterval } from 'apex-web/lib/redux/actions/tickersActions';
import {
  SUPPORTED_TICKER_INTERVALS,
  convertLabelToTickerInterval
} from 'apex-web/lib/helpers/tickerHelper';
import config from 'apex-web/lib/config';
import './IntervalButtons.css';

export const IntervalButtons = () => (
  <div className="interval-buttons-wrapper">
    {config.LightWeightCharts.supportedIntervals.map(i => (
      <ConnectedIntervalButton
        key={i}
        interval={i}
        value={SUPPORTED_TICKER_INTERVALS[i]}
      />
    ))}
  </div>
);

IntervalButtons.contextTypes = {
  t: PropTypes.func.isRequired
};

const IntervalButton = (props, context) => (
  <button
    className={`ap-inline-btn__btn lightweight-interval-button ${
      props.selectedInterval === props.value ? 'active' : ''
    }`}
    label={props.interval}
    onClick={props.changeTickersInterval}
    type="button">
    <span>{context.t(props.interval)}</span>
  </button>
);

IntervalButton.contextTypes = {
  t: PropTypes.func.isRequired
};

IntervalButton.propTypes = {
  selectedInterval: PropTypes.number,
  value: PropTypes.number,
  interval: PropTypes.string,
  changeTickersInterval: PropTypes.func
};

const mapStateToProps = state => ({
  selectedInterval: state.tickers.interval
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeTickersInterval: () => {
    dispatch(
      changeTickersInterval(convertLabelToTickerInterval(ownProps.interval))
    );
  }
});
const ConnectedIntervalButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntervalButton);
