import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  SubmissionError,
  touch,
  getFormSyncErrors
} from 'redux-form';
import get from 'lodash/get';
import SignupFormComponent from './SignupFormComponent';
import { preprocessErrorTextForReduxForm } from 'apex-web/lib/helpers/errorTextFormatter';
import {
  signup,
  clearSignupError
} from 'apex-web/lib/redux/actions/signupActions';
import { resendVerificationEmail } from 'apex-web/lib/redux/actions/userActions';
import config from 'apex-web/lib/config';
import { claimReward, subscribeForNews } from '../../../api/liquidApi';

const SignupFormForm = reduxForm({
  form: 'signup',
  onSubmit: (payload, dispatch) =>
    dispatch(signup(payload)).then(response => {
      if (response && response.errormsg) {
        /**
         * To leverage `redux-form` field-specific error functionality,
         *  we must throw a `SubmissionError` from decorated component's `handleSubmit` prop.
         */
        throw new SubmissionError(
          preprocessErrorTextForReduxForm(response.errormsg, payload)
        );
      } else if (!response) {
        if (payload.newsSubscription) {
          subscribeForNews(payload.email);
        }
        if (window.location.search) {
          const urlParams = new URLSearchParams(window.location.search);
          // TODO(Jun 27, 2022): change the tag when the endpoint is ready
          const rewardTag = urlParams.get('rewardTag');
          if (rewardTag) {
            claimReward(payload.email, rewardTag);
          }
        }
      }
    })
})(SignupFormComponent);

const selector = formValueSelector('signup');

const mapStateToProps = state => {
  return {
    passwordValue: selector(state, 'password'),
    emailValue: selector(state, 'email'),
    errorMsg: get(state, 'form.signup.error', ''),
    done: !!state.signup.userId,
    isAuthenticated: state.auth.isAuthenticated,
    siteName: config.global.siteName,
    synchronousError: getFormSyncErrors('signup')(state),
    fields: state.form.signup && state.form.signup.fields,
    submitErrors: state.form.signup && state.form.signup.submitErrors,
    ...config.SignupForm
  };
};

const mapDispatchToProps = {
  clearSignupError,
  resendVerificationEmail,
  touch
};

const SignupFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupFormForm);

export default SignupFormContainer;
