import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import { closeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import {
  receiveWithdrawWorkflowInfo,
  submittingWithdraw,
  receiveWithdrawTicket,
  getWithdrawTickets
} from 'apex-web/lib/redux/actions/withdrawActions';
import {
  createDotsWithdrawal,
  createIbaneraWithdrawalTicket
} from '../../api/liquidApi';

export const transferFundsWithoutSnackbar = (payload, pane) => async (
  dispatch,
  getState
) => {
  const {
    selectedAccountId: SenderAccountId,
    userInfo: { OMSId }
  } = getState().user;
  const response = await dispatch(
    callAPI('TransferFunds', {
      OMSId,
      SenderAccountId,
      ...payload
    })
  );
  dispatch(closeSidePane(pane));
  return response;
};

export const createWithdrawTicket = ({
  ProductId,
  AccountProviderId,
  ...info
}) => async (dispatch, getState) => {
  dispatch(submittingWithdraw);
  const { OMSId } = getState().user.userInfo;
  const AccountId = getState().user.selectedAccountId;
  const templateForm = info.TemplateForm || info;
  const accountProviderId =
    AccountProviderId || getState().withdraw.AccountProviderId;
  const templateTypeObj = getState().withdraw.templateTypes.find(
    template => template.AccountProviderId === parseInt(accountProviderId, 10)
  );
  // Since undefined is not serializable we default every prop to an empty string to avoid missing fields
  Object.keys(templateForm).forEach(
    prop => (templateForm[prop] = templateForm[prop] || '')
  );

  const payload = {
    OMSId,
    AccountId,
    ProductId: +ProductId,
    Amount: +info.Amount,
    TemplateForm: JSON.stringify(templateForm),
    TemplateType:
      templateTypeObj.TemplateName || getState().withdraw.template.TemplateType,
    AccountProviderId: +templateTypeObj.AccountProviderId
  };

  const withdrawStatus = await dispatch(
    callAPI('CreateWithdrawTicket', payload)
  );

  try {
    let workflowDetail = JSON.parse(withdrawStatus.detail);

    if (
      typeof workflowDetail === 'object' &&
      workflowDetail.ReplyObject &&
      workflowDetail.ReplyObject !== '0'
    ) {
      return dispatch(receiveWithdrawWorkflowInfo(workflowDetail));
    }
  } catch (e) {
    // do nothing
  }
  dispatch(receiveWithdrawTicket(withdrawStatus));
  dispatch(getWithdrawTickets(AccountId, OMSId));
  return withdrawStatus.result;
};

export const createIbaneraWithdraw = payload => async (dispatch, getState) => {
  try {
    const { OMSId } = getState().user.userInfo;
    const AccountId = getState().user.selectedAccountId;
    await createIbaneraWithdrawalTicket({
      ...payload,
      amount: +payload.amount * 100
    });
    dispatch(getWithdrawTickets(AccountId, OMSId));
    return true;
  } catch (e) {
    return false;
  }
};

export const createDotsWithdraw = payload => async (dispatch, getState) => {
  try {
    const { OMSId } = getState().user.userInfo;
    const AccountId = getState().user.selectedAccountId;
    await createDotsWithdrawal({ ...payload, amount: +payload.amount * 100 });
    dispatch(getWithdrawTickets(AccountId, OMSId));
    return true;
  } catch (e) {
    return false;
  }
};
