import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import { change } from 'redux-form';
import get from 'lodash/get';
import BigNumber from 'bignumber.js';
import { getFormValuesForSubmittingOrder } from 'apex-web/lib/helpers/placeOrderHelper';
import {
  sellValue,
  orderTypes
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { orderFormTypes } from 'apex-web/lib/constants/sendOrder/orderFormTypes';
import { accountIdSelector } from 'apex-web/lib/redux/selectors/marginSelectors';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import { level2BestBidAndOfferForInstrumentSelector } from '../selectors/instrumentSelector';
import { calculateOrderFee } from '../../helpers/calculateOrderFeeHelper';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { userBalanceByProductSelector } from '../selectors/productsSelector';
import { getQuantityForPrice } from 'apex-web/lib/helpers/orderHelper';
import config from '../../config';

export const BELOW_MARKET_WARNING = Symbol('BELOW_MARKET_WARNING');

export const placeOrderWithChecks = (orderFormType, form) => async (
  dispatch,
  getState
) => {
  if (
    form.orderType === orderTypes.limit.displayName &&
    form.side === sellValue
  ) {
    const limitPrice = new BigNumber(form.limitPrice);
    const marketPrice =
      level2BestBidAndOfferForInstrumentSelector(
        getState(),
        form.instrumentId
      ).bestOfferPrice || new BigNumber(0);
    if (limitPrice.lt(marketPrice)) {
      return {
        status: BELOW_MARKET_WARNING,
        bestPrice: marketPrice
      };
    }
  }

  return dispatch(placeOrder(orderFormType, form));
};

export const placeOrder = (orderFormType, form, { useNewForm } = {}) => async (
  dispatch,
  getState
) => {
  const state = getState();

  const selectedAccountId = accountIdSelector(state);
  const {
    user: { userInfo },
    apexCore: { product, level1, level2 },
    instrument: { instruments }
  } = getState();
  const { instrumentId } = form;
  const selectedInstrument = instruments.find(
    i => i.InstrumentId === instrumentId
  );
  const priceDecimalPlaces = convertIncrementToIntDecimalPlaces(
    selectedInstrument.PriceIncrement
  );
  const quantityDecimalPlaces = convertIncrementToIntDecimalPlaces(
    selectedInstrument.QuantityIncrement
  );
  const options = getFormValuesForSubmittingOrder(orderFormType, {
    state: getState(),
    decimalPlaces: product.decimalPlaces,
    instrument: { selectedInstrumentId: instrumentId },
    form,
    userInfo,
    selectedAccountId,
    priceDecimalPlaces,
    quantityDecimalPlaces,
    level1,
    level2,
    useNewForm
  });
  let response;
  if (orderFormType === orderFormTypes.block) {
    response = await dispatch(callAPI('SubmitBlockTrade', options));
  } else {
    response = await dispatch(callAPI('SendOrder', options));
  }

  return response;
};

export const calcFee = (formValues, instrumentId) => async (
  dispatch,
  getState
) => {
  const state = getState();
  try {
    const orderFee = await calculateOrderFee(state, {
      instrumentId,
      form: formValues
    });
    if (orderFee.result === false) {
      const { errormsg, detail } = orderFee;
      throw new Error(
        errormsg && detail
          ? `${errormsg}: ${detail}`
          : errormsg || detail
      );
    }
    if (orderFee && orderFee.OrderFee && orderFee.ProductId) {
      const {
        product: { products }
      } = getState();
      const product = products.find(i => i.ProductId === orderFee.ProductId);
      return {
        fee: BigNumber(orderFee.OrderFee),
        product
      };
    }
  } catch (e) {
    console.error(e);
    dispatch(
      showSnack({
        id: 'orderFeeError',
        text: "Can't calculate order fee",
        type: 'warning'
      })
    );
  }
};


export const calcMaxUsdTotal = (orders, formValues) => async (
  dispatch,
  getState
) => {
  const selectedInstrument = selectedInstrumentSelector(getState());
  const usdBalance = userBalanceByProductSelector(
    getState(),
    selectedInstrument.Product2
  );
  let maxQuantity;
  if (formValues.orderType === orderTypes.market.displayName) {
    orders = [...orders];
    const { OrderCalcBuffer: { quantityBuffer } = {} } = config;
    maxQuantity = getQuantityForPrice(
      usdBalance,
      quantityBuffer,
      orders,
      true,
      formValues.side
    ).Quantity;
  } else {
    const price = formValues.limitPrice || formValues.stopPrice;
    maxQuantity = usdBalance.div(price);
  }
  if (!isNaN(maxQuantity)) {
    maxQuantity = BigNumber(maxQuantity).dp(
      convertIncrementToIntDecimalPlaces(selectedInstrument.QuantityIncrement),
      BigNumber.ROUND_FLOOR
    );
    if (maxQuantity.gt(0)) {
      const newForm = {
        ...formValues,
        quantity: maxQuantity
      };
      const fee = await dispatch(calcFee(newForm, selectedInstrument.InstrumentId));
      let maxUsdTotal = BigNumber(usdBalance);
      if (get(fee, 'product.ProductId') === selectedInstrument.Product2) {
        maxUsdTotal = BigNumber.max(maxUsdTotal.minus(get(fee, 'fee', 0)), 0);
      }
      maxUsdTotal = maxUsdTotal.dp(
        convertIncrementToIntDecimalPlaces(selectedInstrument.PriceIncrement),
        BigNumber.ROUND_FLOOR
      );
      return maxUsdTotal;
    }
  }
  return BigNumber(0);
};

export const calcOrderEntryFee = () => async (dispatch, getState) => {
  const formValues = get(getState(), 'form.orderEntry.values');
  const instrumentId = selectedInstrumentSelector(getState()).InstrumentId;
  const fee = await dispatch(calcFee(formValues, instrumentId));
  dispatch(change('orderEntry', 'fee', fee || null));
};

export const calcOrderEntryMaxUsdTotal = (orders) => async (dispatch, getState) => {
  const formValues = get(getState(), 'form.orderEntry.values');
  const maxUsdTotal = await dispatch(calcMaxUsdTotal(orders, formValues));
  dispatch(change('orderEntry', 'maxUsdTotal', maxUsdTotal));
};
