import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { setSpotlightId } from '../../redux/actions/spotlightActions';
import './styles.css';

const baseClass = getBEMClasses('spotlight-overlay');

const SpotlightOverlayComponent = props => {
  const { isVisible, close } = props;
  return isVisible ? <div className={baseClass()} onClick={close} /> : null;
};

SpotlightOverlayComponent.propTypes = {
  isVisible: PropTypes.bool,
  close: PropTypes.func
};

export default connect(
  state => ({ isVisible: !!state.spotlight.id }),
  { close: () => setSpotlightId(null) }
)(SpotlightOverlayComponent);
