export const validatePassword = value =>
  (value && !/^(?=.{6,})(?=.*[A-Z])(?=.*[0-9]).*$/g.test(value)) ||
  (value && !validatePasswordSpecialCharacter(value))
    ? ' '
    : undefined;

export const validatePasswordLenght = value =>
  value && /^(?=.{6,}).*$/g.test(value);

export const validatePasswordUpCaseLetter = value =>
  value && /^(?=.*[A-Z])(?=.*[a-z])(?=\S+$).*$/g.test(value);

export const validatePasswordLettersAndNumbers = value =>
  value && /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).*$/g.test(value);

export const validatePasswordSpecialCharacter = value =>
  value && /[^a-zA-Z0-9.].*$/g.test(value);

export const requiredWithoutMessage = value => (value ? undefined : ' ');


export const matchingPassword = (value, allValues) =>
  value !== allValues.password ? 'Passwords don’t match. Please try again.' : undefined;
// context.t('Passwords don’t match. Please try again.')