import axios from "axios";

export const traceUser = async () => {
  const response = await axios.get('https://www.cloudflare.com/cdn-cgi/trace');
  // Convert key-value pairs to JSON
  // https://stackoverflow.com/a/39284735/452587
  return response.data.trim().split('\n').reduce((obj, pair) => {
    pair = pair.split('=');
    obj[pair[0]] = pair[1];
    return obj;
  }, {});
};
