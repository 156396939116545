// ws status codes:
// 3010 - no internet connection
// 3011 - server not response during 1 min (failure PING_PONG request)
// 3401 - client unauthorized

import WebSocket from 'isomorphic-ws';
import { v4 as uuidv4 } from 'uuid';

const host = 'wss://ws.liquidmarketplace.org:8443';

const actionType = {
  PING_PONG: 'PING_PONG'
};

let isTabActive = true;
window.onfocus = function() {
  console.log('isTabActive');
  isTabActive = true;
};
window.onblur = function() {
  console.log('isTabUnActive');
  isTabActive = false;
};

export class WsClient {
  constructor() {
    this.reconnectTimeout = null;
    this.tryReconnect = 0;
    this.heartbeat = true;
  }

  connect({ userId, token }, dispatch) {
    this.connection = new WebSocket(host, [userId, token]);

    this.pingPong();

    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }

    this.userId = userId;
    this.connection.onopen = () => {
      //
    };
    // close ws connection if client lost network connection
    const offlineListener = () => {
      console.error('Lost network connection');
      this.connection.close(3010, 'Lost network connection');
    };
    window.addEventListener('offline', offlineListener, { once: true });

    // reconnect to ws if client get network connection
    const onlineListener = () => {
      console.log('online');
      this.connect(
        { userId },
        dispatch
      );
    };
    window.addEventListener('online', onlineListener, { once: true });

    this.connection.onmessage = data => {
      try {
        // eslint-disable-next-line
        const { to, from, type, uuid, payload } = JSON.parse(data.data);

        /**
         * @param {Object}    data.data  - Message from ws event(need JSON.parse)
         * @param {string}    from       - UserId who send this message
         * @param {string}    to         - UserId target whom need send message
         * @param {string}    type       - action type
         * @param {uuid}      uuid       - uuid
         * @param {Object}    payload    - payload
         */
        if (payload && payload.error) {
          console.error(payload.error);
        } else if (type) {
          // if response type = "PING_PONG", then set this.heartbeat = true; (need for detect broken connections)
          if (type === actionType.PING_PONG) {
            this.heartbeat = true;
          } else {
            dispatch({ type, payload });
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    this.connection.onerror = event => {
      console.error('Error WS event', event);
      this.close();
    };

    this.connection.onclose = event => {
      this.clearPingPongInterval();
      // 3010 code it's lost internet connection, we skip relaunch ws
      console.error('Close WS event', event);
      if (event.code === 3010) {
        return;
      }
      // IMPORTANT: removeEventListener need use if status code !== 3010
      window.removeEventListener('offline', offlineListener);
      window.removeEventListener('online', onlineListener);

      //1000 code -  user logout, then we do not run reconnect
      if (event.code === 1000) {
        return;
      }
      console.log('isTabActive', isTabActive);
      this.reconnect({ userId }, dispatch);
    };
  }

  close(status = 1000, message = 'unknown reason') {
    console.log(status, message);
    this.clearPingPongInterval();
    this.connection.close(status, message);
  }

  pingPong() {
    //launch interval, which send PING_PONG request to server. If After 1 min this.heartbeat === false,
    //then response from server not been get and we should break connections and launch reconnect
    const pingRequestInterval = 2 * 60;
    // const pingRequestTimeout = 60;

    this.intervalPingListener = setInterval(() => {
      this.heartbeat = false;
      this.connection.send(
        JSON.stringify({
          type: actionType.PING_PONG,
          uuid: uuidv4()
        })
      );

      // this.timeoutPingListener = setTimeout(() => {
      //   if (!this.heartbeat) {
      //     this.close(3011, 'PING_PONG not response');
      //     console.log(
      //       `PING_PONG do not response during ${pingRequestTimeout}s, invoke close connection of ws`
      //     );
      //   }
      // }, pingRequestTimeout * 1000);
    }, pingRequestInterval * 1000);
  }

  clearPingPongInterval() {
    if (this.intervalPingListener) {
      clearInterval(this.intervalPingListener);
    }
    if (this.timeoutPingListener) {
      clearTimeout(this.timeoutPingListener);
    }
  }

  reconnect({ userId }, dispatch, interval = 30) {
    const token = localStorage.getItem('token');
    //if token not exist or tab inactive, then doesn't call reconnect
    if (!token || !isTabActive) {
      return;
    }

    this.reconnectTimeout = setTimeout(() => {
      console.log('Try reconnect webSocket', ++this.tryReconnect);
      this.connect(
        { userId, token },
        dispatch
      );
    }, interval * 1000);
  }

  /**
   * @param {Object}    message         - Message for ws
   * @param {string[]}  message.to      - UserIds target whom need send message
   * @param {string}    message.type    - action type
   * @param {uuid}      message.uuid    - uuid
   * @param {Object}    message.payload - payload
   */
  publish(message) {
    this.connection.send(JSON.stringify(message));
  }
}

export const wsClient = new WsClient();
