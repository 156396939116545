import {
  REQUEST_NOTIFICATIONS,
  RECEIVE_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  FORCE_REMOVE_NOTIFICATION,
  MARK_NOTIFICATION_AS_SEEN,
  REQUEST_GLOBAL_NOTIFICATION,
  RECEIVE_GLOBAL_NOTIFICATION
} from '../actions/notificationsActions';

const initialState = {
  notifications: [],
  globalNotification: {},
  fetching: false,
  globalNotificationFetching: false
};

export default function notificationsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case REQUEST_GLOBAL_NOTIFICATION: {
      return {
        ...state,
        globalNotificationFetching: true
      };
    }
    case REQUEST_NOTIFICATIONS:
      return {
        ...state,
        fetching: true
      };
    case RECEIVE_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
        fetching: false
      };
    case RECEIVE_GLOBAL_NOTIFICATION:
      return {
        ...state,
        globalNotification: payload,
        globalNotificationFetching: false
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map(item => {
          if (item.id === payload.id) {
            return payload;
          } else {
            return item;
          }
        })
      };
    case FORCE_REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          item => item.id !== payload.id
        )
      };
    case MARK_NOTIFICATION_AS_SEEN:
      return {
        ...state,
        notifications: state.notifications.map(item => {
          if (payload.includes(item.id)) {
            return {
              ...item,
              isSeen: true
            };
          } else {
            return item;
          }
        })
      };
    default:
      return state;
  }
}
