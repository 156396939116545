import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

import './PublicPortfolio.css';

import {
  ANIMATION_TYPES,
  TierAnimationWrapper
} from '../../components/common/TierAnimationWrapper/TierAnimationWrapper';
import PublicPortfolioLogo from '../../images/share-profile-logo.png';
import { USER_TIERS } from '../../constants/userTiers';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { MarketProductCard } from '../../components/common/MarketProductCard';
import { getPublicProfile } from '../../api/liquidApi';
import { useIsMobile } from '../../hooks/useIsMobile';
import APIcon from '../../components/common/APIcon/APIcon';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import { checkIsCorrectAssetAttribute } from '../../redux/selectors/productsSelector';
import { createdAtString } from '../../helpers/stringFormatterHelper';

const classes = getBEMClasses('public-portfolio');

const PublicPortfolioComponent = (props, context) => {
  // const [currentSortOption, setCurrentSortOption] = useState();
  const [profileData, setProfileData] = useState();
  const [fetching, setFetching] = useState(true);
  const [rowLenght, setRowLength] = useState(3);
  const width = window.screen.width;
  const { match, history, forwardedAccountId } = props;
  const accountId = forwardedAccountId || Number(match.params.accountId);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (accountId) {
      setFetching(true);
      getPublicProfile(accountId, 0, rowLenght * 2, undefined)
        .then(res => {
          setProfileData(res);
        })
        .finally(() => {
          setFetching(false);
        });
    } else {
      setProfileData();
    }
  }, []);

  useEffect(() => {
    if (width <= 1250) {
      setRowLength(2);
    } else if (width < 900) {
      setRowLength(1);
    }
  }, []);

  // const setSortOption = e => {
  //   setCurrentSortOption(e.target.value);

  //   getPublicProfile(
  //     accountId,
  //     0,
  //     profileData.productsOwned.length,
  //     e.target.value
  //   ).then(res => {
  //     setProfileData(res);
  //   });
  // };

  // const sortOptions = [
  //   {
  //     value: 'name',
  //     label: 'Name'
  //   },
  // ];

  const redirectToMain = () => {
    history.push('');
  };

  const loadMore = () => {
    if (profileData.productsOwned.length < profileData.count) {
      getPublicProfile(
        accountId,
        0,
        profileData.productsOwned.length + rowLenght * 2,
        undefined
      ).then(res => {
        setProfileData(res);
      });
    }
  };

  const createdAt = () => {
    if (profileData) {
      return createdAtString(profileData.userInfo.createdAt);
    }
  };

  const items = React.useMemo(
    () =>
      profileData && profileData.productsOwned && !forwardedAccountId
        ? profileData.productsOwned.map(elem => {
            const color = elem.ProductColor || 'grey';
            return (
              <MarketProductCard
                key={`${elem.ProductFullName}`}
                item={{
                  ProductFullName: elem.ProductFullName,
                  BestOffer: elem.BestOffer,
                  Rolling24HrPxChangePercent: elem.Rolling24HrPxChangePercent,
                  ProductId: elem.ProductId
                }}
                color={color}
                baseCurrency={elem.baseCurrency}
                cardImageURL={elem.ProductImageURL}
                cardBackgroundImage={elem.ProductCardBackground}
                onCardPress={() => {}}
                buySellAvailable={false}
                customClass={classes('card')}
              />
            );
          })
        : [],
    [profileData, forwardedAccountId]
  );

  const renderProfileData = () => {
    const userTier = profileData.userInfo.currentGrandMaster
      ? 9
      : profileData.userInfo.userTierValue
        ? profileData.userInfo.userTierValue
        : 1;
    const tier = USER_TIERS[userTier];

    return (
      <div className={classes('wrapper')}>
        <div className={classes('public-logo-wrapper')}>
          <img
            className={classes('public-logo')}
            src={PublicPortfolioLogo}
            alt="logo"
          />
        </div>

        <div className={classes('title-container')}>
          <div className={classes('wordmark')} onClick={redirectToMain} />
          <TierAnimationWrapper
            className={
              userTier === 9
                ? classes('grandmaster-animation')
                : classes('tier-animation')
            }
            animationTier={userTier}
            animationType={ANIMATION_TYPES.PROFILE}
            height={isMobile ? 250 : 233}
            width={isMobile ? 250 : 233}
            autoplay
          />
        </div>
        <div className={classes('collectibles-container')}>
          <div className={classes('table-header')}>
            <div className={classes('account-name')}>
              {profileData.userInfo.account_name}
            </div>
            <div className={classes('account-tier-wrapper')}>
              <div
                className={classes('account-tier-color')}
                style={{ background: tier.tierExpColor }}
              />
              <div className={classes('account-tier')}>
                {tier.tierName} Tier
              </div>
            </div>
            <div className={classes('joined-date')}>
              {`Joined ${createdAt()}`}
            </div>
            {/* <div className={classes('sort-select-container')}>
              <select
                className={classes('sort-select')}
                onChange={setSortOption}
                defaultValue={0}>
                <option disabled hidden value={0}>
                  {context.t('Sort By')}
                </option>
                {sortOptions.map(i => (
                  <option key={`sort-${i.value}`} value={i.value}>
                    {i.label}
                  </option>
                ))}
              </select>
            </div> */}
          </div>

          <div className={classes('menu-container')}>
            <div
              className={classNames(
                classes('menu-item'),
                classes('menu-item-cards')
              )}>
              Cards({profileData.count})
            </div>
            <div
              className={classNames(
                classes('menu-item'),
                classes('menu-item-nft')
              )}>
              NFT
            </div>
            <div
              className={classNames(
                classes('menu-item'),
                classes('menu-item-items')
              )}>
              Item
            </div>
          </div>

          <div className={classes('cards-container')}>
            {profileData.productsOwned.length > 0
              ? items
              : context.t('User doesn`t have any assets')}
          </div>

          {profileData.productsOwned.length < profileData.count && (
            <div className={classes('table-footer')} onClick={loadMore}>
              <button className={classes('more-button')}>
                {context.t('View more')}
              </button>
              <div className={classes('down-icon')}>
                <APIcon name="arrowDown" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes()}>
      {fetching ? (
        <div className={classes('spinner-wrapper')}>
          <Spinner />
        </div>
      ) : profileData &&
      checkIsCorrectAssetAttribute(
        profileData.userInfo.publicProfile,
        'true'
      ) ? (
        renderProfileData()
      ) : (
        <div className={classes('warning-message')}>
          {context.t('Account is not found')}{' '}
        </div>
      )}

      <PageFooterLayout />
    </div>
  );
};

PublicPortfolioComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

PublicPortfolioComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  history: PropTypes.object,
  forwardedAccountId: PropTypes.number,
  location: PropTypes.object
};
export const PublicPortfolio = withRouter(PublicPortfolioComponent);
