import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { i18nState } from 'redux-i18n';
import thunk from 'redux-thunk';
import authMiddleware from './middlewares/authMiddleware';
import languageMiddleware from 'apex-web/lib/redux/middlewares/languageMiddleware';
import startupMiddleware from './middlewares/startupMiddleware';
import orderFeeMiddleware from 'apex-web/lib/redux/middlewares/orderFeeMiddleware';
import marginEnableMiddleware from 'apex-web/lib/redux/middlewares/marginEnableMiddleware';
import priceQuantityIncrementMiddleware from 'apex-web/lib/redux/middlewares/priceQuantityIncrementMiddleware';
import level2monitorMiddleware from 'apex-web/lib/redux/middlewares/level2monitorMiddleware';
import wsConnectionMiddleware from './middlewares/wsConnectionMiddleware';
import positionMiddleware from './middlewares/positionMiddleware';

import appLoadMiddleware from './middlewares/appLoadMiddleware';

// reducers (sort by name)
import api from 'apex-web/lib/redux/reducers/apiReducer';
import apexCore from 'apex-web/lib/redux/apexCore';
import affiliate from 'apex-web/lib/redux/reducers/affiliateReducer';
import apiKey from 'apex-web/lib/redux/reducers/apiKeyReducer';
import apiProcessing from 'apex-web/lib/redux/reducers/apiProcessingReducer';
import asset from './reducers/assetReducer';
import auth from 'apex-web/lib/redux/reducers/authReducer';
import confirmWithdraw from 'apex-web/lib/redux/reducers/confirmWithdrawReducer';
import instrument from 'apex-web/lib/redux/reducers/instrumentReducer';
import deposit from 'apex-web/lib/redux/reducers/depositReducer';
import level1 from 'apex-web/lib/redux/reducers/level1Reducer';
import level2 from 'apex-web/lib/redux/reducers/level2Reducer';
import loyaltyFee from 'apex-web/lib/redux/reducers/loyaltyFeeReducer';
import orderHistory from 'apex-web/lib/redux/reducers/orderHistoryReducer';
import paginationMemory from 'apex-web/lib/redux/reducers/paginationReducer';
import product from 'apex-web/lib/redux/reducers/productReducer';
import productManifest from 'apex-web/lib/redux/reducers/productManifestReducer';
import position from 'apex-web/lib/redux/reducers/positionReducer';
import recentTrades from 'apex-web/lib/redux/reducers/recentTradesReducer';
import resetPassword from 'apex-web/lib/redux/reducers/resetPasswordReducer';
import report from 'apex-web/lib/redux/reducers/reportReducer';
import sidePane from 'apex-web/lib/redux/reducers/sidePaneReducer';
import signup from 'apex-web/lib/redux/reducers/signupReducer';
import snackbar from 'apex-web/lib/redux/reducers/snackbarReducer';
import tickers from 'apex-web/lib/redux/reducers/tickersReducer';
import transfer from 'apex-web/lib/redux/reducers/transferReducer';
import transferRequest from 'apex-web/lib/redux/reducers/transferRequestReducer';
import transferRequestRequested from 'apex-web/lib/redux/reducers/transferRequestRequestedReducer';
import user from './reducers/extendedUserReducer';
import verifyEmail from 'apex-web/lib/redux/reducers/verifyEmailReducer';
import verifyAddress from 'apex-web/lib/redux/reducers/verifyAddressReducer';
import verifyDevice from 'apex-web/lib/redux/reducers/verifyDeviceReducer';
import withdraw from 'apex-web/lib/redux/reducers/withdrawReducer';
import wsConnection from 'apex-web/lib/redux/reducers/wsConnectionReducer';
import eotc from 'apex-web/lib/redux/reducers/eotcReducer';
import eotcWithdraw from 'apex-web/lib/redux/reducers/eotcWithdrawReducer';
import eotcSettlement from 'apex-web/lib/redux/reducers/eotcSettlementReducer';
import eotcPrices from 'apex-web/lib/redux/reducers/eotcPriceReducer';
import userConfig from './reducers/userConfigReducer';
import { marginReducer as margin } from 'apex-web/lib/redux/reducers/marginReducers/marginReducer';
import productAttributes from 'apex-web/lib/redux/reducers/productAttributesReducer';
import interestBearingAccounts from 'apex-web/lib/redux/reducers/interestBearingAccountsReducer';
import modal from './reducers/modalReducer';
import trades from './reducers/tradesReducer';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import history from '../helpers/history';
import { liquidLoginMiddleware } from './middlewares/liquidLoginMiddleware';
import { userConfigMiddleware } from './middlewares/userConfigMiddleware';
import accountConfig from './reducers/accountConfigReducer';
import availableTokens from './reducers/tokenReducer';
import dca from './reducers/dcaReducer';
import ledger from './reducers/ledgerReducer';
import notifications from './reducers/notificationsReducer';
import spotlight from './reducers/spotlightReducer';
import subscription from './reducers/subscriptionReducer';
import vote from './reducers/voteReducer';

export const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.REACT_APP_IS_PRODUCTION !== 'true'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionsDenylist: [
          'LEVEL1_LOADED',
          'LEVEL_2_UPDATES',
          'REGISTER_FIELD',
          'ADD_RECENT_TRADE'
        ]
      })
    : compose;

export const rootReducer = combineReducers({
  accountConfig,
  affiliate,
  apexCore,
  api,
  apiKey,
  apiProcessing,
  asset,
  auth,
  availableTokens,
  confirmWithdraw,
  dca,
  deposit,
  eotc,
  eotcPrices,
  eotcSettlement,
  eotcWithdraw,
  form: formReducer,
  i18nState,
  instrument,
  interestBearingAccounts,
  ledger,
  level1,
  level2,
  loyaltyFee,
  margin,
  modal,
  notifications,
  orderHistory,
  paginationMemory,
  position,
  product,
  productAttributes,
  productManifest,
  recentTrades,
  report,
  resetPassword,
  router: connectRouter(history),
  sidePane,
  signup,
  snackbar,
  spotlight,
  subscription,
  tickers,
  trades,
  transfer,
  transferRequest,
  transferRequestRequested,
  user,
  userConfig,
  verifyAddress,
  verifyDevice,
  verifyEmail,
  vote,
  withdraw,
  wsConnection
});

const store = createStore(
  connectRouter(history)(rootReducer),
  undefined,
  composeEnhancers(
    applyMiddleware(
      appLoadMiddleware,
      level2monitorMiddleware,
      priceQuantityIncrementMiddleware,
      positionMiddleware,
      marginEnableMiddleware,
      languageMiddleware,
      authMiddleware,
      startupMiddleware,
      wsConnectionMiddleware,
      orderFeeMiddleware,
      thunk,
      routerMiddleware(history),
      liquidLoginMiddleware,
      userConfigMiddleware
    )
  )
);

export default store;
