import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { Modal } from '../common/Modal/Modal';
import './DepositWithdrawStatusModal.css';
import { APTypography } from '../common/APTypography';
import { CheckedIcon } from '../../images/react-icons/CheckedIcon';
import { paymentFrequency } from '../../constants/paymentConstants';
import APIcon from '../common/APIcon/APIcon';

const classes = getBEMClasses('deposit-withdraw-status-modal');

const DepositWithdrawStatusModal = (props, context) => {
  const {
    isOpen,
    isDeposit = true,
    close,
    isSuccessful = false,
    closeDepositModal,
    amount = 0,
    getSubscriptions,
    frequency,
    isDots
  } = props;

  const { modalHeaderText, modalBodyUpperText, modalBodyBottomText } = isDeposit
    ? getDepositTexts(context, isSuccessful, amount)
    : getWithdrawTexts(context, isSuccessful, amount, isDots);

  useEffect(
    () => {
      if (isOpen && isSuccessful && frequency) {
        getSubscriptions();
      }
    },
    [isOpen, isSuccessful, frequency]
  );

  return (
    <Modal
      customClass={classes()}
      isOpen={isOpen}
      titleComponent={
        <div className={classes('header')}>
          <CheckedIcon className={classes('success-check')} />
          <APTypography
            className={classes('header-title')}
            weight="weight600"
            color="white1">
            {modalHeaderText}
          </APTypography>
          <button
            type="button"
            className={classes('close-button')}
            onClick={() => {
              closeDepositModal();
              close();
            }}>
            <APIcon
              customClass={classes('custom-close-icon')}
              name="close"
              classModifiers="big"
            />
          </button>
        </div>
      }
      onCancel={() => {
        closeDepositModal();
        close();
      }}
      close={close}>
      <div className={classes('body')}>
        <div className={classes('body-text')}>
          <span className={classes('body-upper-text')}>
            {modalBodyUpperText}
          </span>
          <span className={classes('body-bottom-text')}>
            {modalBodyBottomText}
          </span>
        </div>

        {frequency && (
          <div className={classes('recurring-info-container')}>
            <div className={classes('recurring-info-field')}>
              {context.t('Payment Amount')}
            </div>
            <div
              className={classes(
                'recurring-info-value'
              )}>{`${amount} USD`}</div>
            <div className={classes('recurring-info-field')}>
              {context.t('Payment Frequency')}
            </div>
            <div className={classes('recurring-info-value')}>
              {paymentFrequency.find(i => i.value === frequency).label}
            </div>
            <div className={classes('recurring-info-field')}>
              {context.t('Payment Method')}
            </div>
            <div className={classes('recurring-info-value')}>
              {context.t('Credit Card')}
            </div>
          </div>
        )}

        <div className={classes('buttons')}>
          <button
            className={classes('button')}
            onClick={() => {
              close();
              closeDepositModal();
            }}>
            Go Back
          </button>
        </div>
      </div>
    </Modal>
  );
};

DepositWithdrawStatusModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isDeposit: PropTypes.bool,
  close: PropTypes.func.isRequired,
  isSuccessful: PropTypes.bool,
  closeDepositModal: PropTypes.func,
  amount: PropTypes.number,
  getSubscriptions: PropTypes.func,
  frequency: PropTypes.string,
  isDots: PropTypes.bool
};

DepositWithdrawStatusModal.contextTypes = {
  t: PropTypes.func.isRequired
};

const getDepositTexts = (context, isSuccessful, amount) => ({
  modalHeaderText: isSuccessful
    ? context.t('Successful Deposit')
    : context.t('Unsuccessful Deposit'),
  modalBodyBottomText: isSuccessful
    ? context.t(
        'Nice! Your payment of {amount} has been processed and will appear in your account within 5 minutes. If your deposit does not appear, try refreshing your webpage!',
        { amount: '$' + amount.toFixed(2) }
      )
    : context.t('It seems there was a problem processing your deposit.'),
  modalBodyUpperText: isSuccessful
    ? context.t('You’re ready to expand your collection.')
    : context.t(
        'Please make sure your payment details have been entered correctly and try again or use another payment method.'
      )
});

const getWithdrawTexts = (context, isSuccessful, amount, isDots) => ({
  modalHeaderText: isSuccessful
    ? context.t('Successful Withdrawal')
    : context.t('Unsuccessful Withdrawal'),
  modalBodyUpperText: isSuccessful
    ? context.t(
        'Nice! Your withdrawal of {amount} is on it’s way for our review.',
        { amount: '$' + amount.toFixed(2) }
      )
    : context.t('It seems there was a problem processing your withdrawal.'),
  modalBodyBottomText: isSuccessful
    ? isDots
      ? `Please check your email for a confirmation link. Once you have confirmed your withdrawal it will
    be processed within 4 business days - hang tight!`
      : context.t('The transfer might take up to 7 business days, hang tight!')
    : context.t(
        'Please make sure all the details have been entered correctly and try again.'
      )
});

export default DepositWithdrawStatusModal;
