import React, { useMemo } from "react";
import { MarketProductCard } from "../../components/common/MarketProductCard";
import { UrlFactory } from "../../helpers/urlFactory";
import { sortAssets } from "../../helpers/sortHelper";

const useAssetMapper = (
  assets,
  currentSortOption,
  isAscending,
  { baseCurrency, makeOrder, history },
) => {
  const cardsWithAssets = useMemo(
    () => assets
      .map(asset => ({
        ProductFullName: asset.product.ProductFullName,
        ProductImageURL: asset.product.productAttributes.ProductImageURL,
        ProductCardBackground:
          asset.product.productAttributes.ProductCardBackground,
        BestOffer: asset.BestOffer,
        BestBid: asset.BestBid,
        Rolling24HrPxChangePercent: asset.Rolling24HrPxChangePercent,
        color: asset.product.productAttributes.ProductColor,
        baseCurrency,
        ProductId: asset.ProductId,
        Amount: asset.Amount
      }))
      .map(asset => [(
        <MarketProductCard
          key={`${asset.ProductFullName}`}
          item={{
            ProductFullName: asset.ProductFullName,
            BestOffer: asset.BestOffer,
            BestBid: asset.BestBid,
            Rolling24HrPxChangePercent: asset.Rolling24HrPxChangePercent,
            ProductId: asset.ProductId
          }}
          color={asset.color}
          baseCurrency={asset.baseCurrency}
          cardImageURL={asset.ProductImageURL}
          cardBackgroundImage={asset.ProductCardBackground}
          buyOnly={asset.Amount === 0}
          onBuySell={makeOrder}
          onCardPress={() =>
            history.push(
              UrlFactory.getMyCollectiblesAssetDetailsUrl(asset.ProductId)
            )
          }
        />
      ), asset]),
    [assets.length]
  );
  const items = useMemo(
    () =>
      [...cardsWithAssets].sort(
        ([, a], [, b]) => sortAssets(a, b, currentSortOption, isAscending)
      )
        .map(([card]) => card),
    [cardsWithAssets, currentSortOption, isAscending]
  );
  return items;
};

export default useAssetMapper;
