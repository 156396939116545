import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import './PaymentsPage.css';

import { getBEMClasses } from '../../helpers/cssClassesHelper';
import {
  cancelAllSubscriptions,
  deleteStoredCard,
  getPaymentMethods,
  skipNextPayment
} from '../../api/liquidApi';
import { TrashbinIcon } from '../../images/react-icons/TrashbinIcon';
import APIcon from '../../components/common/APIcon';
import { ModalWithCurrencySelect } from '../../components/ModalWithCurrencySelect/ModalWithCurrencySelect';
import { DeleteCardModal } from './PaymentsModals/DeleteCardModal';
import { SkipPaymentModal } from './PaymentsModals/SkipPaymentModal';
import { CancelSubscriptionsModals } from './PaymentsModals/CancelSubscriptionsModal';
import { getSubscriptions } from '../../redux/actions/subscriptionActions';
import { MODAL_TYPES, openModal } from '../../redux/actions/modalActions';
import { selectIsTermsOfUseAccepted } from '../../redux/selectors/userConfigSelector';

const classes = getBEMClasses('payments');

const PaymentsPageComponent = (props, context) => {
  const {
    subscription,
    getSubscriptions,
    isTermsOfUseAccepted,
    openUpdateTermsOfUseModal
  } = props;
  const [payments, setPayments] = useState([]);
  const [cardForDelete, setCardForDelete] = useState(false);
  const [paymentToSkip, setPaymentToSkip] = useState(false);
  const [cancelRecurringModal, setCancelReccuringModal] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const activeSubscription = useMemo(
    () => {
      return (
        subscription &&
        subscription.subscriptions &&
        subscription.subscriptions[0]
      );
    },
    [subscription]
  );

  const previousPayments = useMemo(
    () => {
      if (subscription && subscription.previousPayments) {
        return subscription.previousPayments;
      }

      return [];
    },
    [subscription]
  );

  useEffect(
    () => {
      if (isModalOpened === false)
        getPaymentMethods().then(res => {
          setPayments(res);
        });
    },
    [isModalOpened]
  );

  useEffect(() => {
    getSubscriptions();
  }, []);

  const openModal = useCallback(
    () => {
      setIsModalOpened(!isModalOpened);
    },
    [isModalOpened]
  );

  const deleteStoredCardCallback = useCallback(
    () => {
      deleteStoredCard(cardForDelete.paymentId).then(() => {
        getPaymentMethods()
          .then(res => {
            setPayments(res);
            setCardForDelete(false);
            setTimeout(() => setIsLoading(false), 0);
          })
          .catch(err => {
            console.log('error', err);
            setCardForDelete(false);
          });
      });
    },
    [cardForDelete]
  );

  const skipPaymentCallback = useCallback(
    () => {
      skipNextPayment().then(() => {
        getPaymentMethods()
          .then(res => {
            setPayments(res);
          })
          .catch(err => {
            console.log('error', err);
            setPaymentToSkip(false);
            return;
          });

        getSubscriptions()
          .then(() => {
            setPaymentToSkip(false);
            setTimeout(() => setIsLoading(false), 0);
          })
          .catch(err => {
            console.log('error', err);
            setPaymentToSkip(false);
          });
      });
    },
    [paymentToSkip]
  );

  const cancelAllSubscriptionsCallback = () => {
    cancelAllSubscriptions().then(() => {
      getPaymentMethods()
        .then(res => {
          setPayments(res);
        })
        .catch(err => {
          console.log('error', err);
          setCancelReccuringModal(false);
          return;
        });

      getSubscriptions()
        .then(() => {
          setCancelReccuringModal(false);
          setTimeout(() => setIsLoading(false), 0);
        })
        .catch(err => {
          console.log('error', err);
          setCancelReccuringModal(false);
        });
    });
  };

  const openDepositModal = () => {
    if (isTermsOfUseAccepted) {
      setIsModalOpened(true);
    } else {
      openUpdateTermsOfUseModal({
        additionalHandler: () => {
          setIsModalOpened(true);
        }
      });
    }
  };

  return (
    <div className={classes('')}>
      <div className={classes('header')}>
        <div className={classes('header-title')}>{context.t('Payment')}</div>
        <div className={classes('header-description')}>
          {context.t('Update your Payment Methods and Setting')}
        </div>
      </div>

      <div className={classes('body')}>
        <div className={classes('payments')}>
          {context.t('Payment Methods')}
          <div className={classes('cards-container')}>
            {payments &&
              payments.map(card => (
                <div className={classes('card')} key={card.paymentId}>
                  <div className={classes('card-left')}>
                    <APIcon
                      customClass={classes('card-icon')}
                      name={card.brand}
                    />
                    {card.last4}
                  </div>

                  <div className={classes('card-right')}>
                    {card.isRecurring && (
                      <div className={classes('recurring-banner')}>
                        {context.t('RECCURING')}
                      </div>
                    )}
                    <TrashbinIcon
                      className={classes('delete-icon')}
                      onClick={() => setCardForDelete(card)}
                    />
                  </div>
                </div>
              ))}
            <button
              className={classes('add-funds-button')}
              onClick={openDepositModal}
              disabled={!isTermsOfUseAccepted}
              type='button'>
              {context.t('Add Funds')}
            </button>
          </div>

          <div className={classes('cancel-payment-container')}>
            {context.t('Recurring Payment')}
            {activeSubscription ? (
              <button
                className={classes('cancel-payment-button')}
                onClick={() => setCancelReccuringModal(true)}>
                {context.t('Cancel Recurring Payment')}
              </button>
            ) : (
              <div> {context.t('There are no recurring payments')}</div>
            )}
          </div>

          <div className={classes('payment-history')}>
            {context.t('Recurring Payment History')}
            <div className={classes('payment-history-table')}>
              <div className={classes('table-header')}>
                <div className={classes('header-column')}>
                  {context.t('Method')}
                </div>
                <div className={classes('header-column')}>
                  {context.t('Amount')}
                </div>
                <div className={classes('header-column')}>
                  {context.t('Date')}
                </div>
                <div className={classes('header-column')}>
                  {context.t('Status')}
                </div>
              </div>

              {activeSubscription && (
                <div className={classes('payment-history-active-row')}>
                  <button
                    className={classes('skip-payment-button')}
                    onClick={() =>
                      setPaymentToSkip(subscription.subscriptions[0])
                    }>
                    {context.t('Tap to Skip This Payment')}
                  </button>
                  <div className={classes('payment-history-row', 'active')}>
                    <div className={classes('payment-history-column')}>
                      {subscription.subscriptions[0].type}
                    </div>
                    <div className={classes('payment-history-column')}>
                      {`$${subscription.subscriptions[0].amount}`}
                    </div>
                    <div className={classes('payment-history-column', 'date')}>
                      {format(
                        subscription.subscriptions[0].nextPaymentTimestamp,
                        'MM/dd/yyyy HH:mm:ss'
                      )}
                    </div>
                    <div
                      className={classes('payment-history-column', 'status')}>
                      {context.t('Recurring')}
                    </div>
                  </div>
                </div>
              )}

              {previousPayments.map((i, index) => {
                return (
                  <div
                    className={classes('payment-history-row')}
                    key={`payment-${index}`}>
                    <div className={classes('payment-history-column')}>
                      {i.type}
                    </div>
                    <div className={classes('payment-history-column')}>
                      {`$${i.amount}`}
                    </div>
                    <div className={classes('payment-history-column', 'date')}>
                      {format(new Date(i.timestamp), 'MM/dd/yyyy HH:mm:ss')}
                    </div>
                    <div
                      className={classes('payment-history-column', 'status')}>
                      {context.t('FullyProcessed')}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <ModalWithCurrencySelect
        openModal={openModal}
        isModalOpened={isModalOpened}
        //for deposit tabIndex = 0, for withdraw tabIndex = 1
        tabIndex={0}
      />
      <DeleteCardModal
        isModalOpen={cardForDelete}
        closeModal={() => setCardForDelete(false)}
        card={cardForDelete}
        confirmDelete={deleteStoredCardCallback}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <SkipPaymentModal
        isModalOpen={paymentToSkip}
        closeModal={() => setPaymentToSkip(false)}
        payment={paymentToSkip}
        confirmSkip={skipPaymentCallback}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <CancelSubscriptionsModals
        isModalOpen={cancelRecurringModal}
        closeModal={() => setCancelReccuringModal(false)}
        confirmCancelSubscription={cancelAllSubscriptionsCallback}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

PaymentsPageComponent.propTypes = {
  subscription: PropTypes.object,
  getSubscriptions: PropTypes.func,
  isTermsOfUseAccepted: PropTypes.bool,
  openUpdateTermsOfUseModal: PropTypes.func
};

PaymentsPageComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export const PaymentsPage = connect(
  state => ({
    subscription: state.subscription,
    isTermsOfUseAccepted: selectIsTermsOfUseAccepted(state)
  }),
  {
    getSubscriptions,
    openUpdateTermsOfUseModal: props =>
      openModal(MODAL_TYPES.UPDATE_TERMS_OF_USE_MODAL, {
        ...props,
        isDeposit: true
      })
  }
)(PaymentsPageComponent);
