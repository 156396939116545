export const createTimer = (period = 1000) => {
  const subscribers = new Set();
  let intervalHandle = null;

  const unsubscribe = (subscriber) => {
    subscribers.delete(subscriber);
    if (subscribers.size === 0) {
      reset();
    }
  };

  const subscribe = (subscriber) => {
    if (!intervalHandle) {
      intervalHandle = setInterval(() => {
        subscribers.forEach(sub => {
          try {
            sub();
          } catch (e) {
            console.error(e);
            // remove failed subscriber to avoid potential error spam on new events
            subscribers.delete(sub);
          }
        });
      }, period);
    }
    subscribers.add(subscriber);
    return () => unsubscribe(subscriber);
  };

  const reset = () => {
    subscribers.clear();
    intervalHandle && clearInterval(intervalHandle);
    intervalHandle = null;
  };

  return {
    subscribe,
    unsubscribe,
    reset
  };
};
