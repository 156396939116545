import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BEMHelper from 'react-bem-helper';

import './APLabelWithText.css';

const baseClasses = new BEMHelper({
  name: 'ap-label-with-text',
  outputIsString: true
});

export const APLabelWithText = props => {
  const {
    label,
    text,
    customClass,
    classModifiers,
    link,
    additionalInstructions
  } = props;
  const customClasses = new BEMHelper({
    name: customClass,
    outputIsString: true
  });

  return (
    <div className={classnames(customClasses('lwt-container', classModifiers))}>
      <div className={baseClasses()}>
        {link ? (
          <a
            target={'_blank'}
            rel={'noopener noreferrer'}
            href={link}
            className={classnames(
              'ap--label',
              baseClasses('link'),
              customClasses('lwt-label', classModifiers)
            )}>
            {label}
          </a>
        ) : (
          <label
            className={classnames(
              'ap--label',
              baseClasses('label'),
              customClasses('lwt-label', classModifiers)
            )}>
            {label}
          </label>
        )}
        {link ? null : (
          <span
            className={classnames(
              baseClasses('text'),
              customClasses('lwt-text', classModifiers)
            )}>
            {text}
          </span>
        )}
      </div>
      {!!additionalInstructions && (
        <span
          className={classnames(
            'ap--label',
            baseClasses('link'),
            customClasses('lwt-label', classModifiers)
          )}>
          {additionalInstructions}
        </span>
      )}
    </div>
  );
};

APLabelWithText.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  customClass: PropTypes.string,
  classModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.objectOf(PropTypes.bool)
  ]),
  dataTest: PropTypes.string,
  link: PropTypes.string,
  additionalInstructions: PropTypes.string
};

APLabelWithText.defaultProps = {
  customClass: 'custom-label-with-text'
};
