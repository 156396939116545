import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter, useLocation } from 'react-router-dom';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { useIsMobile, useIsTablet } from '../../hooks/useIsMobile';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import UserSummaryContainer from '../../components/UserSummary/UserSummaryContainer';
import { APSearchInput } from '../../components/common/APSearchInput';
import APIcon from '../../components/common/APIcon';
import { APTypography } from '../../components/common/APTypography';
import { MODAL_TYPES, openModal } from '../../redux/actions/modalActions';
import { SearchCardsModal } from './SearchCardsModal/index';
import { digitalAssetsProductsSelector } from '../../redux/selectors/productsSelector';
import { UrlFactory } from '../../helpers/urlFactory';
import { setIsVerificationReminderWasShown } from '../../redux/actions/userActions';
import {
  selectUserTierWithExp,
  showAffiliateProgramSelector
} from '../../redux/selectors/userTierSelector';
import { USER_TIERS, USER_TIERS_ARRAY } from '../../constants/userTiers';
import UserProgress from '../../components/UserNameTierBadge/UserXP/UserProgress';
import { notMarginAccountsSelector } from 'apex-web/lib/redux/selectors/marginSelectors';
import { LightThemeIcon } from '../../components/PageHeader/themeIcons/lightThemeIcon';
import { DarkThemeIcon } from '../../components/PageHeader/themeIcons/darkThemeIcon';
import LogoWhite from './images/logo-beta-light.png';
import LogoBlack from './images/logo-beta-dark.png';
import { degreeStringEnding } from '../../helpers/stringFormatterHelper';
import { ReferralButton } from './ReferallButton';
import { NotificationCenter } from '../../components/NotificationCenter';
import './PageHeaderLayout.css';
import './UserSummaryOverrides.css';
import { ConsignButton } from './ConsignButton';
import { useMedia } from 'react-use';

const pageHeaderClasses = getBEMClasses('page-header');
const pageHeaderMobileClasses = getBEMClasses('page-header-mobile');

const PageHeaderComponent = (props, context) => {
  const {
    products,
    navItems,
    openSearchCardsModal,
    openAffiliateModal,
    history,
    verificationLevel,
    createdAt,
    isShowReminder,
    setIsVerificationReminderWasShown,
    logout,
    userExp,
    userExpHold,
    selectedAccount,
    mobileNavBarExpanded,
    setMobileNavbarExpanded,
    userMonthTier,
    isGrandMaster,
    userTier,
    degree,
    showReferralButton
  } = props;

  const isHideFullLogo = useMedia('(max-width: 1250px)');

  const [currentTemplate, setCurrentTemplate] = useState({ theme: 'light' });
  const [showNotification, setShowNotification] = useState();
  const [verificationDeadline, setVerificationDeadline] = useState('');
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const currentTierDetails = USER_TIERS[userMonthTier > 8 ? 8 : userMonthTier];
  const nextTierDetails =
    userMonthTier < 8 ? USER_TIERS[userMonthTier + 1] : USER_TIERS[8];
  const roundedUserExp = Math.floor(userExp);
  const roundedUserExpHold = Math.floor(userExpHold);
  const location = useLocation();

  const changeTheme = theme => {
    localStorage.setItem(
      'apexTemplate',
      JSON.stringify({ ...currentTemplate, theme: theme })
    );

    document.location.reload(true);
  };

  const activeSectionIndex = useMemo(
    () => navItems.findIndex(i => location.pathname.includes(i.highlightUrl)),
    [navItems, location.pathname]
  );

  useEffect(() => {
    const template = JSON.parse(localStorage.getItem('apexTemplate'));
    setCurrentTemplate(template ? template : { theme: 'light' });
  }, []);

  useEffect(
    () => {
      setShowNotification(isShowReminder);
    },
    [isShowReminder]
  );

  useEffect(
    () => {
      if (createdAt) {
        const joinDate = new Date(createdAt);
        const deadlineDate = addDays(joinDate, 7);
        setVerificationDeadline(format(deadlineDate, 'do MMMM'));
      }
    },
    [createdAt]
  );

  const productsForSearch = useMemo(
    () =>
      props.products.map(item => {
        return {
          label: item.ProductFullName,
          searchArray: [item.ProductFullName, item.Product],
          onClick: () =>
            history.push(UrlFactory.getOfferingsAssetDetailsUrl(item.ProductId))
        };
      }),
    [products.length]
  );

  const completeProfile = () => {
    setIsVerificationReminderWasShown();
    history.push(UrlFactory.getVerificationLevelSettingsUrl());
    setShowNotification(false);
  };

  const renderVereficationNotification = () => {
    return (
      <div className={pageHeaderClasses('notification-container')}>
        {verificationLevel < 1 && (
          <div
            className={pageHeaderClasses('notification-image')}
            onClick={() => setShowNotification(true)}
          />
        )}

        {showNotification && (
          <div className={pageHeaderClasses('notification-body')}>
            <div
              className={pageHeaderClasses('notification-close')}
              onClick={() => setShowNotification(false)}>
              <APIcon
                name="close"
                customClass={pageHeaderClasses('notification-close-icon')}
              />
            </div>
            <div className={pageHeaderClasses('notification-text')}>
              {context.t('I have an important message for you!')}
            </div>

            <div className={pageHeaderClasses('notification-text')}>
              {context.t(
                'Complete your profile before {deadlineDate} to continue using the platform',
                { deadlineDate: verificationDeadline }
              )}
            </div>

            <button
              className={pageHeaderClasses('notification-button')}
              onClick={completeProfile}>
              {context.t('Complete Profile')}
            </button>
          </div>
        )}
      </div>
    );
  };

  if (isMobile || isTablet) {
    return (
      <div className={pageHeaderMobileClasses()}>
        <div className={pageHeaderMobileClasses('top-bar')}>
          {mobileNavBarExpanded ? (
            <div className={pageHeaderMobileClasses('expanded-top-bar')}>
              <div className={pageHeaderMobileClasses('tier-wrapper')}>
                <APIcon
                  customClass={pageHeaderMobileClasses('tier-img')}
                  name={
                    isGrandMaster
                      ? `${USER_TIERS_ARRAY[8].iconName}WithBG`
                      : `${USER_TIERS_ARRAY[userTier - 1].iconName}WithBG`
                  }
                />
                {!!degree &&
                  !isGrandMaster && (
                    <div className={pageHeaderMobileClasses('degree')}>
                      {degree}
                      <span
                        className={pageHeaderMobileClasses('degree-ending')}>
                        {degreeStringEnding(degree.toString())}
                      </span>{' '}
                      DEGREE
                    </div>
                  )}
                <div className={pageHeaderMobileClasses('name')}>
                  {selectedAccount.AccountName}
                </div>
              </div>
              <UserProgress
                currentTierDetails={currentTierDetails}
                nextTierDetails={nextTierDetails}
                userExp={roundedUserExp}
                userExpHold={roundedUserExpHold}
              />
              <div onClick={() => setMobileNavbarExpanded(false)}>
                <APIcon
                  name="mobileClose"
                  customClass={pageHeaderMobileClasses()}
                />
              </div>
            </div>
          ) : (
            <React.Fragment>
              <button
                className={pageHeaderMobileClasses('nav-menu')}
                onClick={() => setMobileNavbarExpanded(!mobileNavBarExpanded)}>
                <div className={pageHeaderMobileClasses('burger-row')} />
                <div className={pageHeaderMobileClasses('burger-row')} />
                <div className={pageHeaderMobileClasses('burger-row')} />
              </button>
              <div className={pageHeaderMobileClasses('logo')}>
                <Link
                  to="/dashboard"
                  onClick={() => setMobileNavbarExpanded(false)}
                  className={pageHeaderMobileClasses('wordmark-link-wrapper')}>
                  <div className={pageHeaderMobileClasses('wordmark-wrapper')}>
                    <img
                      src={
                        currentTemplate.theme === 'light'
                          ? LogoBlack
                          : LogoWhite
                      }
                      alt="liquid marketplace"
                      className={pageHeaderMobileClasses('wordmark')}
                    />
                  </div>
                </Link>
              </div>
              <div className={pageHeaderMobileClasses('header-right-part')}>
                <NotificationCenter />
                <button
                  onClick={() => {
                    setMobileNavbarExpanded(false);
                    openSearchCardsModal();
                  }}
                  className={pageHeaderMobileClasses('search-icon-container')}>
                  <APIcon
                    name="search"
                    customClass={pageHeaderMobileClasses('search-icon')}
                  />
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
        {mobileNavBarExpanded && (
          <div className={pageHeaderMobileClasses('navbar-root')}>
            <div className={pageHeaderMobileClasses('navbar')}>
              <div className={pageHeaderMobileClasses('navbar-content')}>
                <div className={pageHeaderMobileClasses('nav-items')}>
                  <ConsignButton />
                  {showReferralButton && (
                    <ReferralButton
                      setMobileNavbarExpanded={setMobileNavbarExpanded}
                      openAffiliateModal={openAffiliateModal}
                    />
                  )}
                  {navItems.map((item, index) => {
                    return (
                      <Link
                        to={item.path}
                        key={`${item.text}-${index}`}
                        onClick={() => setMobileNavbarExpanded(false)}
                        className={pageHeaderMobileClasses(
                          'nav-item',
                          activeSectionIndex === index && 'active'
                        )}>
                        <APIcon
                          name={item.icon}
                          customClass={pageHeaderMobileClasses('nav-icon')}
                        />
                        <div
                          className={pageHeaderMobileClasses('nav-item-text')}>
                          <APTypography>{item.text}</APTypography>
                        </div>
                      </Link>
                    );
                  })}
                  <div className={pageHeaderMobileClasses('theme-button')}>
                    {currentTemplate.theme === 'light' ? (
                      <DarkThemeIcon
                        className={pageHeaderMobileClasses('theme-icon')}
                        onClick={() => changeTheme('dark')}
                      />
                    ) : (
                      <LightThemeIcon
                        className={pageHeaderMobileClasses('theme-icon')}
                        onClick={() => changeTheme('light')}
                      />
                    )}
                  </div>
                </div>

                <div
                  className={pageHeaderMobileClasses('sign-out-button')}
                  onClick={logout}>
                  {context.t('Sign Out')}
                </div>
              </div>
            </div>
          </div>
        )}
        <SearchCardsModal productsForSearch={productsForSearch} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={pageHeaderClasses('container')}>
        <div
          className={pageHeaderClasses('left')}
          style={{ position: 'relative', left: isHideFullLogo ? 0 : 40 }}>
          <Link to="/dashboard">
            <div className={pageHeaderClasses('wordmark-wrapper')}>
              <div className={pageHeaderClasses('wordmark-tablets')} />
              <img
                src={currentTemplate.theme === 'light' ? LogoBlack : LogoWhite}
                alt="liquid marketplace"
                className={pageHeaderClasses('wordmark')}
              />
            </div>
          </Link>
        </div>
        <div className={pageHeaderClasses('right')}>
          {renderVereficationNotification()}
          <NotificationCenter />
          <ConsignButton />
          {showReferralButton && (
            <ReferralButton
              setMobileNavbarExpanded={setMobileNavbarExpanded}
              openAffiliateModal={openAffiliateModal}
            />
          )}
          <div className={pageHeaderClasses('search-container')}>
            <APSearchInput
              placeholder={context.t('Search cards here...')}
              searchList={productsForSearch}
              customClass={pageHeaderClasses('search')}
            />
            <div className={pageHeaderClasses('search-icon-container')}>
              <APIcon
                name="search"
                customClass={pageHeaderClasses('search-icon')}
              />
            </div>
          </div>
          <UserSummaryContainer
            customClass="page-header-user-summary"
            settingsRoute="/settings/user"
            activityRoute="/asset-activity-details"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const products = digitalAssetsProductsSelector(state);
  const {
    userTier,
    monthExp,
    expHold,
    monthUserTier,
    degree,
    currentGrandMaster
  } = selectUserTierWithExp(state);
  const isShowReminder = !state.userConfig.isVerificationReminderWasShown;
  const { selectedAccountId } = state.user;
  const accounts = notMarginAccountsSelector(state);
  const selectedAccount =
    accounts.find(account => account.AccountId === selectedAccountId) || {};

  return {
    products,
    isShowReminder,
    userTier,
    userExp: monthExp,
    userExpHold: expHold,
    selectedAccount,
    userMonthTier: monthUserTier,
    isGrandMaster: !!currentGrandMaster,
    degree,
    showReferralButton: showAffiliateProgramSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  openSearchCardsModal: () => dispatch(openModal(MODAL_TYPES.SEARCH_CARDS)),
  openAffiliateModal: () => dispatch(openModal(MODAL_TYPES.AFFILIATE_MODAL)),
  setIsVerificationReminderWasShown: () =>
    dispatch(setIsVerificationReminderWasShown())
});

PageHeaderComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

PageHeaderComponent.propTypes = {
  products: PropTypes.array,
  navBarExpanded: PropTypes.bool,
  mobileNavBarExpanded: PropTypes.bool,
  setMobileNavbarExpanded: PropTypes.func,
  navItems: PropTypes.array,
  openSearchCardsModal: PropTypes.func,
  openAffiliateModal: PropTypes.func,
  history: PropTypes.object,
  verificationLevel: PropTypes.number,
  createdAt: PropTypes.string,
  isShowReminder: PropTypes.bool,
  setIsVerificationReminderWasShown: PropTypes.func,
  logout: PropTypes.func,
  userExp: PropTypes.number,
  userExpHold: PropTypes.number,
  selectedAccount: PropTypes.object,
  userMonthTier: PropTypes.number,
  isGrandMaster: PropTypes.bool,
  userTier: PropTypes.number,
  degree: PropTypes.number,
  showReferralButton: PropTypes.bool
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PageHeaderComponent)
);
