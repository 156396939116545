export const USER_TIERS = {
  1: {
    tierNumber: 1,
    //context.t('Welcome To Liquid MarketPlace, your journey starts here. Earn experience points to gain badges and show the community just how fly you really are.')
    tierDescription:
      'Welcome To Liquid MarketPlace, your journey starts here. Earn experience points to gain badges and show the community just how fly you really are.',
    tierDescriptionBackground: '#FFCC9819',
    tierName: 'Novice',
    tierBadgeBackground: '#FFCC98',
    expPoints: 0,
    tierExpColor: '#FFCC98',
    iconName: 'novice'
  },
  2: {
    tierNumber: 2,
    //context.t('Earn 435 experience points to unlock your Bronze badge.')
    tierDescription:
      'Earn 435 experience points to unlock your Bronze badge.',
    tierDescriptionBackground: '#ECD79E1A',
    tierName: 'Bronze',
    tierBadgeBackground: '#ECD79E',
    expPoints: 435,
    tierExpColor: '#ECD79E',
    iconName: 'bronze'
  },
  3: {
    tierNumber: 3,
    //context.t('Earn 870 experience points to unlock your Silver badge.')
    tierDescription:
      'Earn 870 experience points to unlock your Silver badge.',
    tierDescriptionBackground: '#E2E3E51A',
    tierName: 'Silver',
    tierBadgeBackground: '#E2E3E5',
    expPoints: 870,
    tierExpColor: '#E2E3E5',
    iconName: 'silver'
  },
  4: {
    tierNumber: 4,
    //context.t('Earn 2175 experience points to unlock your Gold badge and get showcased in our users of the month section.')
    tierDescription:
      'Earn 2175 experience points to unlock your Gold badge and get showcased in our users of the month section.',
    tierDescriptionBackground: '#FAE17F19',
    tierName: 'Gold',
    tierBadgeBackground: '#FBE17F',
    expPoints: 2175,
    tierExpColor: '#FBE17F',
    iconName: 'gold'
  },
  5: {
    tierNumber: 5,
    //context.t('Earn 4350 experience points to unlock your Obsidian badge and earn free tokens of a secret asset!')
    tierDescription:
      'Earn 4350 experience points to unlock your Obsidian badge and earn free tokens of a secret asset. You’ll also receive a custom affiliate link to share with everyone you know. After someone uses the link to make an account, you’ll receive a commission every time they trade in the MarketPlace! So, bring your friends and make some money!',
    tierDescriptionBackground: '#C1C1C21A',
    tierName: 'Obsidian',
    tierBadgeBackground: '#C1C1C2',
    expPoints: 4350,
    tierExpColor: '#C1C1C2',
    iconName: 'obsidian'
  },
  6: {
    tierNumber: 6,
    //context.t('Earn 7656 experience points to unlock your Platinum badge, and get added to the VIP guest list of our annual in-person collectibles exhibit!')
    tierDescription:
      'Earn 7656 experience points to unlock your Platinum badge, and get added to the VIP guest list of our annual in-person collectibles exhibit!',
    tierDescriptionBackground: '#D6E2EB1A',
    tierName: 'Platinum',
    tierBadgeBackground: '#D6E2EB',
    expPoints: 7656,
    tierExpColor: '#D6E2EB',
    iconName: 'platinum'
  },
  7: {
    tierNumber: 7,
    //context.t('Earn 10,875 experience points to unlock your diamond badge and be added to our monthly draw for a collectible - valued between $5,000-$15,000. Free stuff baby!')
    tierDescription:
      'Earn 10,875 experience points to unlock your diamond badge and be added to our monthly draw for a collectible - valued between $5,000-$15,000. Free stuff baby!',
    tierDescriptionBackground: '#EDECFF1A',
    tierName: 'Diamond',
    tierBadgeBackground: '#EDECFF',
    expPoints: 10875,
    tierExpColor: '#EDECFF',
    iconName: 'diamond'
  },
  8: {
    tierNumber: 8,
    //context.t('Earn 21,750 experience points to unlock your Black Diamond badge and get added to our yearly draw for an all expenses paid trip to a leading national collectibles show.')
    tierDescription:
      'Earn 21,750 experience points to unlock your Black Diamond badge and get added to our yearly draw for an all expenses paid trip to a leading national collectibles show.',
    tierDescriptionBackground: '#746E6E1A',
    tierName: 'Black Diamond',
    tierBadgeBackground: '#746E6E',
    expPoints: 21750,
    tierExpColor: '#383131',
    iconName: 'blackDiamond'
  },
  9: {
    tierNumber: 9,
    //context.t('You have arrived at your destination! This exclusive badge is open to just three users each month. Reach Grandmaster by earning the most experience points in a month. Who will reign as champions of the leaderboard? Secret badge markers unlock for users that have achieved this milestone. It’s time to stand apart from the rest of the community. Grandmasters are airdropped an exclusive Liquid MarketPlace NFT.')
    tierDescription:
      'You have arrived at your destination! This exclusive badge is open to just three users each month. Reach Grandmaster by earning the most experience points in a month. Who will reign as champions of the leaderboard? Secret badge markers unlock for users that have achieved this milestone. It’s time to stand apart from the rest of the community. Grandmasters are airdropped an exclusive Liquid MarketPlace NFT.',
    tierDescriptionBackground:
      'linear-gradient(136deg, #C9DDFF 0%, #859FF41A 100%)',
    tierName: 'Grandmaster',
    tierBadgeBackground: '#C9DDFF',
    expPoints: 0,
    tierExpColor: 'linear-gradient(136deg, #C9DDFF 0%, #859FF41A 100%)',
    iconName: 'grandmaster'
  }
};

export const USER_TIERS_ARRAY = Object.values(USER_TIERS);
