import React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { getBEMClasses } from "../../helpers/cssClassesHelper";
import Quid from '../../images/quid_not_found.png';
import Wordmark from '../../layouts/PageHeaderLayout/images/WORDMARK.png';
import Divider from './wave-divider.svg';
import config from "../../config";
import SVG from 'react-inlinesvg';
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Textfit } from "react-textfit";
import './StubPage.css';
import './StubPageMobile.css';

const coreClasses = getBEMClasses('stub-page');
const additionalClasses = getBEMClasses('stub-page-mobile');
const classes = (...args) => classNames([
  additionalClasses(...args),
  coreClasses(...args),
]);

const StubPageMobile = (props, context) => {
  const {
    title,
    subtitle,
    text,
    showDashboardButton = true,
  } = props;
  return (
    <div className={classes()}>
      <div className={classes('scroll-container')}>
        <img
          className={classes('wordmark')}
          src={Wordmark}
          alt='LiquidMarket. Own Anything.'
        />
        <img
          className={classes('quid')}
          src={Quid}
          alt=''
        />
        <div className={classes('title')}>
          <Textfit
            mode='single'
            max={64}>
            {context.t(title)}
          </Textfit>
        </div>
        <p className={classes('subtitle')}>{context.t(subtitle)}</p>
        <p className={classes('text-content')}>
          {context.t(text)}
        </p>
        <div className={classes('button-container')}>
          {showDashboardButton && (
            <Link
              className={classes('back-button')}
              to={'/dashboard'}>
              {context.t('Back to dashboard')}
            </Link>
          )}
          <SVG
            className={classes('divider')}
            src={Divider}
            alt=''
          />
        </div>
        <div className={classes('links-container')}>
          {get(config, 'Footer.links', []).map((column) =>
            column.map((item, innerIdx) => {
              let openInNewWindow =
                config.Footer.openInNewWindowGlobal || true;
              if ('openInNewWindow' in item) {
                openInNewWindow = item.openInNewWindow;
              }
              return (
                <a
                  target={openInNewWindow ? '_blank' : ''}
                  rel={openInNewWindow ? 'noopener noreferrer' : ''}
                  key={innerIdx}
                  href={item.link}>
                  {context.t(item.text)}
                </a>
              );
            }))}
        </div>
        <div className={classes('copyright')}>
          {`© Liquid Marketplace Inc. ${new Date().getFullYear()}. ${context.t(
            'All Rights Reserved.'
          )}`}
        </div>
      </div>
    </div>
  );
};

StubPageMobile.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  showDashboardButton: PropTypes.bool,
};

StubPageMobile.contextTypes = {
  t: PropTypes.func
};

export default StubPageMobile;
