import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import APTable from '../../common/APTable/APTable';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

const baseClass = getBEMClasses('order-history-table');

const DepositsStatusComponent = (props, context) => {
  useEffect(
    () => {
      props.getDepositTickets(props.config.maxLines);
    },
    [props.selectedAccountId]
  );

  const { data, decimalPlaces, config } = props;
  const { maxLines, usePagination } = config;

  const columns = [
    {
      header: context.t('Product'),
      dataTest: 'Product',
      cell: row => row.AssetName
    },
    {
      header: context.t('Amount'),
      dataTest: 'Amount',
      cell: row => formatNumberToLocale(row.Amount, decimalPlaces[row.AssetId])
    },
    {
      header: context.t('Status'),
      dataTest: 'Status',
      cell: row => context.t(row.Status)
    },
    {
      header: context.t('Created'),
      dataTest: 'Created',
      cell: row => formatDateTime(row.CreatedTimestamp)
    },
    {
      header: context.t('Fee'),
      dataTest: 'Fee',
      cell: row => row.FeeAmt
    }
  ];

  return (
    <APTable
      {...{
        columns,
        rows: data,
        totalPages: data.length,
        baseClass,
        pageSize: maxLines,
        usePagination,
        alwaysShowPagination: true,
        useSimplePagination: true,
        empty: context.t('No data is available'),
        componentName: 'DepositStatusComponent',
        onRequestPrev: () => {
          props.getDepositTickets(
            props.config.maxLines,
            props.depositStartIndex - props.config.maxLines
          );
        },
        onRequestNext: () => {
          props.getDepositTickets(
            props.config.maxLines,
            props.depositStartIndex + props.config.maxLines
          );
        }
      }}
    />
  );
};

/*
  context.t('Deposit Status');
 */
DepositsStatusComponent.title = 'Deposit Status';
DepositsStatusComponent.componentName = 'DepositsStatusComponent';

DepositsStatusComponent.propTypes = {
  data: PropTypes.array.isRequired,
  decimalPlaces: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  getDepositTickets: PropTypes.func,
  depositStartIndex: PropTypes.number,
  selectedAccountId: PropTypes.number
};

DepositsStatusComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DepositsStatusComponent;
