import { connect } from 'react-redux';
import { get } from 'lodash';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { getProductByIdSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import { requestTransferFunds } from 'apex-web/lib/redux/actions/transferActions';
import { getTransferRequestsRequested } from 'apex-web/lib/redux/actions/transferRequestActions';
import ReceiveComponent from './ReceiveComponent';
import { MODAL_TYPES } from '../../redux/actions/modalActions';
import { modalDecorator } from '../common/Modal/modalDecorator';
import config from '../../config';

const mapStateToProps = (state, ownProps) => {
  const { disableDeposit } = kycVerificationLevelsSelector(state);
  const productId = get(ownProps, 'productId', null);
  const showDetails = get(ownProps, 'showDetails', null);
  const product = getProductByIdSelector(state, productId);
  const { allowTransfer } = config.global;
  const { selectedAccountId } = state.user;

  return {
    formObj: state.form.ReceiveForm || { values: {} },
    initialValues: {},
    product,
    allowTransfer,
    selectedAccountId,
    showDetails,
    disableDeposit
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  receiveFunds: ({ selectedAccountId, ...rest }) =>
    dispatch(requestTransferFunds(rest, ownProps.name)).then(() =>
      dispatch(getTransferRequestsRequested(selectedAccountId))
    )
});

const ReceiveModal = modalDecorator({
  name: MODAL_TYPES.RECEIVE_MODAL
})(ReceiveComponent);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiveModal);
