import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import copyToClipboard from 'apex-web/lib/helpers/clipboardHelper';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import IconCopy from '../../images/icons/icon-copy-v2.svg';
import './AffiliateComponent.css';

const affiliateClasses = getBEMClasses('affiliate');

const AffiliateActiveTag = (props, context = { t: () => { } }) => {
  const { route, affiliate, onSubmit } = props;
  const [formState, setFormState] = useState(getInitialState(affiliate.affiliateTag));
  const [isCopyCompleted, setIsCopyCompleted] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    setFormState(
      getInitialState(affiliate.affiliateTag)
    );
  }, [affiliate.affiliateTag]);
  useEffect(() => {
    let handle;
    if (isCopyCompleted) {
      handle = setTimeout(() => {
        setIsCopyCompleted(false);
      }, 3000);
    }
    return () => {
      handle && clearTimeout(handle);
      handle = null;
    };
  }, [isCopyCompleted]);

  const onChange = (e) => {
    const newValue = e.target.value;
    if (newValue.startsWith(route)) {
      // TODO(Jun 24, 2022): use one regex
      const newTag = newValue.replace(route, '').replace(/\s/g, '');
      setFormState({
        state: AFFILIATE_STATE.EDITING_TAG,
        tag: newTag
      });
    }
  };
  const onCreateOrEditClick = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(-1, -1);
      }
    }, 0);
    setFormState(prevState => ({
      state: AFFILIATE_STATE.EDITING_TAG,
      tag: prevState.tag || ''
    }));
  };
  const onCancelClick = () => {
    setFormState(
      getInitialState(affiliate.affiliateTag)
    );
  };
  const onCopyClick = () => {
    copyToClipboard(`${route}${formState.tag}`);
    setIsCopyCompleted(true);
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(formState.tag)
      .catch(() => {
        setFormState(getInitialState(affiliate.affiliateTag));
      });
  };

  const btnContainerCssModifier = formState.state === AFFILIATE_STATE.NO_TAG && 'no-tag';

  return (
    <section>
      <form onSubmit={onFormSubmit} className={affiliateClasses('container-content')}>
        {formState.state !== AFFILIATE_STATE.NO_TAG ? (
          <div className={affiliateClasses('tag-container')}>
            <span>{context.t('My Link')}</span>
            <div
              className={affiliateClasses('tag-input-container')}
              disabled={formState.state === AFFILIATE_STATE.HAS_TAG}>
              <input
                className={affiliateClasses('tag-input')}
                type='text'
                disabled={formState.state === AFFILIATE_STATE.HAS_TAG}
                value={`${route}${formState.tag}`}
                onChange={onChange}
                ref={inputRef}
              />
              {/* Uncomment this if you need to re-enable tag editing */}
              {/* {formState.state === AFFILIATE_STATE.HAS_TAG && (
                <button
                  type='button'
                  className={affiliateClasses('btn-icon-wrapper')}
                  onClick={onCreateOrEditClick}>
                  <SVG
                    className={affiliateClasses('icon', 'edit')}
                    src={IconEdit}
                  />
                </button>
              )} */}
            </div>
          </div>
        ) : (
          <div className={affiliateClasses('no-tag')}>
            {context.t('No Active Tag')}
          </div>
        )}
        <div className={affiliateClasses('btn-container', btnContainerCssModifier)}>
          {formState.state === AFFILIATE_STATE.NO_TAG && (
            <button
              type='button'
              className={affiliateClasses('btn', 'create')}
              onClick={onCreateOrEditClick}>
              {context.t('Create Affiliate Link')}
            </button>
          )}
          {formState.state === AFFILIATE_STATE.EDITING_TAG && (
            <React.Fragment>
              <button
                type='button'
                className={affiliateClasses('btn', 'cancel')}
                onClick={onCancelClick}>
                {context.t('Cancel')}
              </button>
              <button
                type='submit'
                className={affiliateClasses('btn', 'save')}
                disabled={formState.tag === affiliate.affiliateTag}>
                {context.t('Save')}
              </button>
            </React.Fragment>
          )}
          {formState.state === AFFILIATE_STATE.HAS_TAG && (
            <button
              type='button'
              className={affiliateClasses('btn', 'copy')}
              onClick={!isCopyCompleted ? onCopyClick : undefined}>
              {isCopyCompleted ? context.t('Copied to Clipboard') : (
                <React.Fragment>
                  <SVG className={affiliateClasses('icon', 'copy')} src={IconCopy} />
                  {context.t('Copy Affiliate Link')}
                </React.Fragment>
              )}
            </button>
          )}
        </div>
      </form>
    </section>
  );
};

const getInitialState = (tag) => {
  return tag ? {
    state: AFFILIATE_STATE.HAS_TAG,
    tag
  } : {
    state: AFFILIATE_STATE.NO_TAG
  };
};

const AFFILIATE_STATE = {
  NO_TAG: Symbol('NO_TAG'),
  EDITING_TAG: Symbol('EDITING_TAG'),
  HAS_TAG: Symbol('HAS_TAG')
};

AffiliateActiveTag.contextTypes = {
  t: PropTypes.func.isRequired
};

AffiliateActiveTag.propTypes = {
  route: PropTypes.string.isRequired,
  affiliate: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default AffiliateActiveTag;
