/**
 * Turns local date-time ISO strings into UTC date-time ISO strings.
 * @param {string} date - ISO string
 * @returns UTC date-time ISO string or `date` if it's not a string or is empty
 */
const utcTimezonePattern = /(Z|[+-]0{2}(:?0{2})?)$/;

export const sanitizeAttributeDate = (date) => {
  if (typeof date === "string") {
    const trimmedDate = date.trim();
    if (trimmedDate.length) {
      if (utcTimezonePattern.exec(trimmedDate)) {
        return trimmedDate;
      } else {
        return trimmedDate + "Z";
      }
    } else {
      console.log(`Can't sanitize date, empty after trimming. date: ${date}`);
    }
  } else {
    console.log(`Can't sanitize date, not a string. date: ${date}`);
  }
  return date;
};
