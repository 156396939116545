import { connect } from 'react-redux';
import get from 'lodash/get';

import { MODAL_TYPES } from '../../redux/actions/modalActions';
import { modalDecorator } from '../common/Modal/modalDecorator';
import { initOrderHistoryListeners } from '../../redux/actions/orderHistoryActions';
import BuySellModalComponent from './BuySellModalComponent';
import { instrumentSelector } from '../../redux/selectors/instrumentSelector';
import { selectIsTermsOfUseAccepted } from '../../redux/selectors/userConfigSelector';

const mapStateToProps = (state, ownProps) => {
  const instrumentId = get(ownProps, 'form.instrumentId');
  return {
    currentInstrument: instrumentId && instrumentSelector(
      state,
      instrumentId
    ),
    isTermsOfUseAccepted: selectIsTermsOfUseAccepted(state)
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  initOrderHistoryListeners: () => dispatch(initOrderHistoryListeners()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    initialOpenAction,
    ...restOwnProps
  } = ownProps;
  return {
    ...restOwnProps,
    ...stateProps,
    ...dispatchProps,
    goToInitialState: () => dispatchProps.dispatch(initialOpenAction),
  };
};

/**
 * If you need to know how this component works - head to buySellModalReducer.js
 */
export default modalDecorator({
  name: MODAL_TYPES.BUY_SELL_MODAL
})(connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(BuySellModalComponent));
