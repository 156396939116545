import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import LoginFormContainer from './LoginForm/LoginFormContainer';

import '../../styles/components/common/StandaloneForm.css';
import './LoginPage.css';
import redirectIfAuthorized from '../../hocs/redirectIfAuthorized';

const loginPageClasses = getBEMClasses('login-page');

export const LoginFormComponent = () => {
  return (
    <div className={` ${loginPageClasses('container')}`}>
      <LoginFormContainer />
    </div>
  );
};

LoginFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  LoginFormComponent,
  process.env.REACT_APP_DEFAULT_PATH
);
