import { connect } from 'react-redux';
import TradeReportsComponent from './TradeReportsComponent';
import { tradeReportsSelectorFactory } from 'apex-web/lib/redux/selectors/orderHistorySelectors';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import {
  cancelOrders,
  getOpenTradeReports
} from 'apex-web/lib/redux/actions/orderHistoryActions';
import get from 'lodash/get';

const makeMapStateToProps = () => {
  const tradeReportsSelector = tradeReportsSelectorFactory();

  return (state, { config, selectedInstrument }) => {
    const tradeReports = tradeReportsSelector(state, config);
    const instruments = instrumentSelectorInstruments(state);
    const {
      activeRequests,
      tradeReportsStartIndex
    } = state.apexCore.orderHistory;
    const fetching = activeRequests > 0;

    return {
      data: tradeReports,
      instruments,
      fetching,
      selectedInstrument,
      selectedAccountId: get(state, 'user.selectedAccountId', 0),
      OMSId: get(state, 'user.userInfo.OMSId', 1),
      tradeReportsStartIndex
    };
  };
};

const mapDispatchToProps = {
  cancelOrders,
  getOpenTradeReports
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(TradeReportsComponent);
