import Avatar from './assets/avatar.png';
import Video from './assets/video.png';

export const loganPaulPageProps = {
  main: {
    name: 'Logan Paul',
    subName: 'Public Figure & Entrepreneur',
    specialty: "Pokémon",
    topCollectible: "PSA 10 Pikachu Illustrator",
    significantProjects: [
      'Founder of 99 Originals',
      'Co-Founder of Liquid MarketPlace'
    ],
    collectiblesIds: [35, 13, 31],
    description: "Starting off on Vine, the social media star quickly rose to fame thanks to his stunts and theatricals that went viral almost overnight. People appreciated his content and followed Logan through his social media journey, watching his Youtube videos, and following him on Instagram. Over the span of his career, Logan has obtained 56 million combined followers over all his platforms. Now, Logan is an entrepreneur, creating his sports drink company PRIME, his NFT project 99 Originals, and co-founding Liquid MarketPlace. As an influencer, Logan shares his passions with his fans, one of them being the world of collectibles: specifically Pokémon memorabilia. Logan Paul, along with the millions of Pokémon fans in the world, has been an enthusiast since childhood. As his stardom as a social media influencer rose, he ensured his admiration for the game was known to the public. ",
    avatar: Avatar,
    video: Video,
    collectiblesTitle: "Logan Paul’s Collectibles",
    videoLink: 'https://www.instagram.com/p/Cb-e7dIj-at/',
  },
  description: {
    title: "Logan’s Collectibles Journey",
    descriptions: [
      'Logan Paul first introduced his passion for collecting Pokémon with his viral video I Spent $150,000 On This Pokémon Card, where he purchased a First Edition PSA 10 Charizard for $150,000. The card was then sent to BGS to be regraded and received the coveted BGS 10 grade. The BGS 10 is significantly rarer than the PSA 10—while there are more than 120 copies of the PSA 10 1st Edition Charizard, there are only 3 copies of the BGS 10. Since its initial purchase in 2020, this card is now valued at over one million dollars. In September 2021, Logan Paul announced to his followers that he had purchased a 1st Edition Base Pokémon Booster Box. Following this announcement, Logan purchased five additional boxes, opening one live on stream to auction. Now, Logan has listed one of his remaining boxes on Liquid MarketPlace for users to co-own.',
      'Logan continued his endeavors in the world of Pokémon, and in April 2022 appeared on stage at WrestleMania with a diamond-encrusted, five-and-a-half million-dollar PSA 10 Pikachu Illustrator Pokémon card around his neck.',
      'To show appreciation to his many devoted fans, Logan has listed items from his collection on Liquid MarketPlace! Now, he wants to give them the chance to co-own pieces of history alongside him!',
    ],
  }
};
