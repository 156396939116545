import React from 'react';
import PropTypes from 'prop-types';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { formatPrice } from '../../../helpers/buySellHelper';
import '../BuySellModal.css';

const classes = getBEMClasses('buy-sell-modal');

const BelowMarketWarning = (props, context) => {
  const { currentInstrument, form, bestPrice } = props;
  const displayedPrice = form.limitPrice;
  return (
    <React.Fragment>
      <div className={classes('confirm-container')} data-test="belowMarketRoot">
        <div className={classes('confirm-title')}>
          {/* TODO: make translatable? */}
          Are you sure? Your &quot;Limit price&quot; is{' '}
          <span className={classes('highlighted')}>below</span> market price:
        </div>
        <div className={classes('below-market-confirm-details')}>
          <div className={classes('row')}>
            <div className={classes('field-name')}>
              {context.t('Your Limit Price')}:
            </div>
            <div className={classes('field-value')}>
              {formatPrice(currentInstrument, displayedPrice)}
            </div>
          </div>
          <div className={classes('row')}>
            <div className={classes('field-name')}>
              {context.t('Market Price')}:{' '}
            </div>
            <div className={classes('field-value')}>
              {formatPrice(currentInstrument, bestPrice)}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
BelowMarketWarning.propTypes = {
  currentInstrument: PropTypes.object.isRequired,
  bestPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
    .isRequired,
  form: PropTypes.any
};

BelowMarketWarning.contextTypes = {
  t: PropTypes.func.isRequired
};

export default BelowMarketWarning;
