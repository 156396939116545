import React from "react";
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { BUY_SELL_SIMPLIFIED_STATE_NAMES } from "../../../redux/reducers/buySellModalReducer";
import IconSuccess from "../../../images/icons/icon-modal-success.svg";
import IconFailure from "../../../images/icons/icon-modal-failure.svg";
import IconArrowBack from "../../../images/icons/nav-arrow-back-icon.svg";
import './ModalHeader.css';

const classes = getBEMClasses('modal-header');

const ModalHeader = (props, context) => {
  const {
    title,
    simplifiedStateName = BUY_SELL_SIMPLIFIED_STATE_NAMES.IDLE,
    onCloseClick,
    sideCssModifier
  } = props;
  return (
    <div className={classes('container', sideCssModifier)}>
      <button className={classes('back-button', getButtonModifiers(simplifiedStateName))}
        data-test='backButton'
        onClick={onCloseClick}>
        <ButtonIcon sideCssModifier={sideCssModifier}
          simplifiedStateName={simplifiedStateName} />
      </button>
      <div className={classes('title')}>{context.t(title)}</div>
    </div>
  );
};

const getButtonModifiers = (simplifiedStateName) =>
  simplifiedStateName === BUY_SELL_SIMPLIFIED_STATE_NAMES.IDLE ?
    ['with-outline'] :
    [];

ModalHeader.propTypes = {
  title: PropTypes.string,
  sideCssModifier: PropTypes.string,
  simplifiedStateName:
    PropTypes.oneOf(Object.values(BUY_SELL_SIMPLIFIED_STATE_NAMES)),
  onCloseClick: PropTypes.func.isRequired
};

ModalHeader.contextTypes = {
  t: PropTypes.func.isRequired
};

const ButtonIcon = ({ simplifiedStateName, sideCssModifier }) => {
  switch (simplifiedStateName) {
    case (BUY_SELL_SIMPLIFIED_STATE_NAMES.SUCCESS):
    case (BUY_SELL_SIMPLIFIED_STATE_NAMES.FAILURE):
      return (
        <SVG className={classes('finish-icon', sideCssModifier)}
          src={simplifiedStateName === BUY_SELL_SIMPLIFIED_STATE_NAMES.SUCCESS ?
            IconSuccess :
            IconFailure}
          alt='' />
      );
    default:
      return (
        <img className={classes('back-button-icon')}
          src={IconArrowBack}
          alt='Back' />
      );
  }
};

ButtonIcon.propTypes = {
  sideCssModifier: PropTypes.string,
  simplifiedStateName:
    PropTypes.oneOf(Object.values(BUY_SELL_SIMPLIFIED_STATE_NAMES)),
};

export default ModalHeader;
