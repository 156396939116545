import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from './Panel';
import HeroBanner from './HeroBanner';
import Description from './Description';
import Gallery from './Gallery';
import { BuyOut } from './BuyOut';
import { useMedia } from 'react-use';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import '../styles/components/offering.css';
import { Top10Owners } from './Top10Owners';
import { getTop10Owners } from '../../../api/liquidApi';
import { connect } from 'react-redux';
import { selectIsTermsOfUseAccepted } from '../../../redux/selectors/userConfigSelector';

const classes = getBEMClasses('offering');

const debounce = (delay, fn) => {
  let inDebounce = null;
  return (...args) => {
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => fn(...args), delay);
  };
};

const getTop10OwnersRequest = (ProductId, setTopOwners, setIsFetching) => {
  getTop10Owners(ProductId).then(
    res => {
      setTopOwners(res.data);
      setIsFetching(false);
    },
    () => {
      setTopOwners([]);
      setIsFetching(false);
    }
  );
};

const debounced = debounce(500, getTop10OwnersRequest);

const OfferingComponent = props => {
  const {
    useOriginalMarket,
    assetData,
    assetNumbers,
    isStandalone,
    onBuyOutClick,
    isTermsOfUseAccepted
  } = props;

  const [topOwners, setTopOwners] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(
    () => {
      if (assetData.assetId && !isStandalone) {
        setIsFetching(true);
        debounced(assetData.assetId, setTopOwners, setIsFetching);
      }
    },
    [assetData.assetId, isStandalone]
  );

  const numbers = [
    {
      name: 'Tokens for Sale',
      number: assetNumbers.tokenSupply,
      isUseUSDSymbol: false
    },
    {
      name: 'Population',
      number: assetNumbers.population,
      isUseUSDSymbol: false
    },
    {
      name: 'Grade',
      number: assetNumbers.grade,
      isUseUSDSymbol: false
    },
    {
      name: 'PPT',
      number: assetNumbers.ppt,
      isUseUSDSymbol: true
    },
    {
      name: 'Market Value',
      number: assetNumbers.marketValue,
      isUseUSDSymbol: true
    }
  ];
  const {
    gradientColors,
    assetDetailsVideoDesktop,
    assetDetailsVideoMobile,
    assetDetailsImageInsteadVideo,
    title,
    numberColor,
    assetImages,
    fullName,
    assetNumber,
    isCoOwn,
    assetId,
    productShortName,
    assetDetailsTopOwnersBackgroundImage,
    fromAdmin,
  } = assetData;
  const heroBannerProps = {
    gradientColors: gradientColors,
    cardVideoDesktop: assetDetailsVideoDesktop,
    cardVideoMobile: assetDetailsVideoMobile,
    description: title,
    numberColor: numberColor,
    numbers: numbers,
    imageInsteadVideo: assetDetailsImageInsteadVideo,
    fullName,
    assetNumber,
    isHtmlTitle: fromAdmin
  };

  const galleryImages = useMemo(
    () => {
      return assetImages.map(assetImage => ({
        image: assetImage,
        altText: fullName
      }));
    },
    [assetImages]
  );

  const coOwnData = useMemo(
    () => isCoOwn && { assetId, isPublic: isStandalone },
    [isCoOwn, assetId, isStandalone]
  );

  const isMobile = useMedia('(max-width: 1024px)');

  const wrappedGallery = (
    <Panel size="half">
      <Gallery images={galleryImages} />
    </Panel>
  );
  const wrappedBuyOut = (
    <Panel size="half">
      <BuyOut
        onBuyOutClick={onBuyOutClick}
        isTermsOfUseAccepted={isTermsOfUseAccepted}
      />
    </Panel>
  );
  const wrappedDescription = (
    <Panel size="half">
      <Description paragraphs={assetData.description} />
    </Panel>
  );
  const wrappedTop10 = (
    <Panel size="hald">
      <Top10Owners
        topOwners={topOwners}
        isFetching={isFetching}
        productShortName={productShortName}
        assetDetailsTopOwnersBackgroundImage={
          assetDetailsTopOwnersBackgroundImage
        }
      />
    </Panel>
  );

  return (
    <div className={classes()}>
      <Panel size="full" order="0">
        <HeroBanner
          {...heroBannerProps}
          useOriginalMarket={useOriginalMarket}
          isStandalone={isStandalone}
          coOwnData={coOwnData}
        />
      </Panel>

      {isMobile || isStandalone ? (
        <div className={classes('single-col-layout')}>
          {!isStandalone && wrappedTop10}
          {wrappedDescription}
          {!isStandalone && wrappedGallery}
          {!isStandalone && wrappedBuyOut}
        </div>
      ) : (
        <div className={classes('double-col-layout')}>
          <div className={classes('gallery-wrapper')}>
            {wrappedTop10}
            {wrappedDescription}
          </div>
          <div className={classes('description-wrapper')}>
            {wrappedGallery}
            {wrappedBuyOut}
          </div>
        </div>
      )}
    </div>
  );
};

OfferingComponent.propTypes = {
  heroBannerProps: PropTypes.object,
  galleryImages: PropTypes.array,
  useOriginalMarket: PropTypes.bool,
  assetNumbers: PropTypes.object,
  assetData: PropTypes.object,
  isDark: PropTypes.bool,
  isStandalone: PropTypes.bool,
  onBuyOutClick: PropTypes.func,
  isTermsOfUseAccepted: PropTypes.bool
};

export default connect(
  (state, ownProps) => ({
    isTermsOfUseAccepted: !ownProps.isStandalone && selectIsTermsOfUseAccepted(state)
  }),
  null
)(OfferingComponent);
