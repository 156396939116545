import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import PropTypes from 'prop-types';
import React from 'react';
import { numberToLocale } from '../../../helpers/numberFormatter';

export const AvailableTokensComponent = ({
  availableTokens,
  primaryMarketOrderId,
  availableTokensPercent
}) => {
  return (
    <React.Fragment>
      {availableTokens || availableTokens === 0 ? (
        primaryMarketOrderId ? (
          `${availableTokensPercent}%`
        ) : (
          numberToLocale(availableTokens)
        )
      ) : (
        <Spinner customClass={'global-mini-spinner'} />
      )}
    </React.Fragment>
  );
};

AvailableTokensComponent.propTypes = {
  availableTokens: PropTypes.number,
  primaryMarketOrderId: PropTypes.string,
  availableTokensPercent: PropTypes.string
};
