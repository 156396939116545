import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import './MobilePageHeader.css';
import { APTypography } from '../common/APTypography';

const baseClasses = getBEMClasses('mobile-page-header');

const MobilePageHeader = props => {
  const { title } = props;
  return (
    <div className={baseClasses()}>
      <APTypography fontSize="subtitle" weight="weight600">
        {title}
      </APTypography>
    </div>
  );
};

MobilePageHeader.propTypes = {
  title: PropTypes.string
};

export { MobilePageHeader };
