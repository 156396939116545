import React from 'react';
import PropTypes from 'prop-types';
import { required } from 'apex-web/lib/helpers/formValidations';
import APAutoTabInput from 'apex-web/lib/components/common/APAutoTabInput/APAutoTabInput';
import APQRCode from 'apex-web/lib/components/common/APQRCode/APQRCode';

import { getBEMClasses } from '../../helpers/cssClassesHelper';
import config from '../../config';

import './Enable2FAModal.css';

const baseClasses = getBEMClasses('enable-2fa-modal');

const Enable2FAForm = (props, context) => {
  const { GoogleQRCode, handleSubmit, userName } = props;

  const {
    global: { siteName }
  } = config;

  const code = `otpauth://totp/${userName}?secret=${GoogleQRCode}&issuer=${encodeURI(
    siteName
  )}`;

  return (
    <form onSubmit={handleSubmit}>
      <div className={baseClasses()}>
        <div>
          <ol className={baseClasses('text-container')}>
            <li className={baseClasses('text-item')}>
              {context.t(
                'Download a Two-Factor Authentication app to your phone such as Authy or Google Authenticator'
              )}
            </li>
            <li className={baseClasses('text-item')}>
              {context.t('Use the app to scan QR code.')}
            </li>
            <li className={baseClasses('text-item')}>
              {context.t('Type in code from your phone.')}
            </li>
          </ol>
          <APAutoTabInput
            name="code"
            type="input"
            numberOfInputs={6}
            validate={[required]}
          />
        </div>
        <div className={baseClasses('qr-code')}>
          <APQRCode value={code} />
        </div>
      </div>
    </form>
  );
};

Enable2FAForm.defaultProps = {
  handleSubmit: () => {},
  submitting: false
};

Enable2FAForm.propTypes = {
  handleSubmit: PropTypes.func,
  GoogleQRCode: PropTypes.string,
  userName: PropTypes.string
};

Enable2FAForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default Enable2FAForm;
