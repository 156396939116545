import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';

import './PaymetnsModals.css';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { Modal } from '../../../components/common/Modal/Modal';
import { TrashbinIcon } from '../../../images/react-icons/TrashbinIcon';
import APIcon from '../../../components/common/APIcon';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

const classes = getBEMClasses('payments-modal');

export const DeleteCardModal = (props, context) => {
  const {
    isModalOpen,
    closeModal,
    card,
    confirmDelete,
    isLoading,
    setIsLoading
  } = props;

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const onDelete = () => {
    setIsLoading(true);
    confirmDelete();
  };
  return (
    <Modal
      customClass={classes()}
      isOpen={isModalOpen}
      close={closeModal}
      showHeader={false}
      showCloseIcon={false}>
      <div className={classes('delete-card-header')}>
        <div className={classes('header-left')}>
          <TrashbinIcon className={classes('trash-icon')} />
          {context.t('Delete This Card')}
        </div>
        <div onClick={closeModal}>
          <APIcon name="close" customClass={classes('close-icon')} />
        </div>
      </div>
      <div className={classes('body')}>
        <div className={classes('title')}>
          {context.t('Are you sure you want to delete this card?')}
        </div>

        <div className={classes('card-info')}>
          <div className={classes('card-info-field')}>
            {context.t('Card Number')}
          </div>
          <div className={classes('card-info-value')}>{`**** **** **** ${
            card.last4
          }`}</div>
          <div className={classes('card-info-field')}>
            {context.t('Card Brand')}
          </div>
          <div className={classes('card-info-value')}>{card.brand}</div>
          <div className={classes('card-info-field')}>
            {context.t('Country')}
          </div>
          <div className={classes('card-info-value')}>{card.country}</div>
          <div className={classes('card-info-field')}>
            {context.t('Expiry Date')}
          </div>
          <div className={classes('card-info-value')}>{`${card.expiryMonth}/${
            card.expiryYear
          }`}</div>
        </div>

        {isLoading ? (
          <Spinner customClass={classes('spinner')} />
        ) : (
          <div className={classes('buttons')}>
            <button className={classes('cancel-button')} onClick={closeModal}>
              {context.t('Cancel')}
            </button>
            <button className={classes('confirm-delete')} onClick={onDelete}>
              {context.t('Confirm')}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

DeleteCardModal.contextTypes = {
  t: PropTypes.func.isRequired
};

DeleteCardModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  card: PropTypes.object,
  confirmDelete: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
}
