import { useCallback, useState } from "react";
import { useRefWithCallback } from "./useRefWithCallback";

export const SCROLL_STATE = {
  START: Symbol(),
  END: Symbol()
};

const calcScrollState = (element) => {
  if (element.scrollLeft === 0) {
    return SCROLL_STATE.START;
  } else if (element.scrollLeft + element.clientWidth === element.scrollWidth) {
    return SCROLL_STATE.END;
  } else {
    return null;
  }
};

// TODO(May 05, 2022): make it work with vertical scroll as well
export const useScrollState = () => {
  const [scrollState, setScrollState] = useState(null);
  const onScroll = useCallback(e => {
    setScrollState(calcScrollState(e.target));
  }, []);

  const onMount = useCallback(node => {
    setScrollState(calcScrollState(node));
    node.addEventListener('scroll', onScroll);
  }, [onScroll]);
  const onUnmount = useCallback(node => {
    node.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  const setScrollRef = useRefWithCallback(onMount, onUnmount);
  return [setScrollRef, scrollState];
};
