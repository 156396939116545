import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './VoteProgress.css';

const classes = getBEMClasses('vote-progress');

export const VoteProgress = (props, context) => {
  const { positiveVotes, negativeVotes } = props;
  return (
    <div className={classes('wrapper')}>
      <div className={classes('title')}>{context.t('Vote Progress:')}</div>
      <div className={classes('progress-wrapper')}>
        <div className={classes('progress')}>
          <div className={classes('progress-title')}>Yes</div>
          <div className={classes('progress-line-wrapper')}>
            <div
              className={classes('progress-line', 'positive')}
              style={{ width: `${positiveVotes}%` }}
            />
            <div className={classes('progress-value')}>
              {positiveVotes || 0}%
            </div>
          </div>
        </div>
        <div className={classes('progress')}>
          <div className={classes('progress-title')}>No</div>
          <div className={classes('progress-line-wrapper')}>
            <div
              className={classes('progress-line', 'negative')}
              style={{ width: `${negativeVotes}%` }}
            />
            <div className={classes('progress-value')}>
              {negativeVotes || 0}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VoteProgress.contextTypes = {
  t: PropTypes.func.isRequired
};

VoteProgress.propTypes = {
  positiveVotes: PropTypes.number,
  negativeVotes: PropTypes.number
};
