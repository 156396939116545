import { SET_DCAS, UPDATE_DCAS_REQUEST_STATUS } from "../actions/dcaActions";

const initialState = {
  dcas: {},
  fetching: false
};

const dcaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_DCAS_REQUEST_STATUS:
      return {
        ...state,
        fetching: payload.isFetching
      };
    case SET_DCAS: {
      return {
        ...state,
        dcas: {
          ...state.dcas,
          ...payload
        }
      };
    }
    default:
      return state;
  }
};

export default dcaReducer;
