import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';

//return modify offerings array with additional fields: [availableTokensPercent, available_tokens]
export const useModifyOffering = ({
  offerings,
  triggerUseEffect,
  getAllTokens = false,
  availableTokens,
  getAvailableTokens
}) => {
  const productIds =
    offerings[0] !== undefined &&
    offerings.flatMap(
      product => (product.Type === 'DigitalAsset' ? product.ProductId : [])
    );
  const [modifiedOfferings, setOfferings] = useState(
    modifyOfferings(offerings, availableTokens)
  );

  useEffect(
    () => {
      if (offerings && offerings.length && productIds && productIds.length) {
        fetchAvailableTokens();
      }
      return;
    },
    [triggerUseEffect]
  );
  async function fetchAvailableTokens() {
    let result;
    if (getAllTokens) {
      const res = await getAvailableTokens();
      result = res && res.available_tokens ? res.available_tokens : undefined;
    } else if (productIds.length) {
      const res = await getAvailableTokens({ productIds });
      result = res && res.available_tokens ? res.available_tokens : undefined;
    }
    if (!result) {
      return modifiedOfferings;
    }
    const availableTokensByProductId = result.reduce((obj, token) => {
      return {
        ...obj,
        [token.product_id]: token
      };
    }, {});
    const ordersWithQuantity = modifyOfferings(
      offerings,
      availableTokensByProductId
    );
    setOfferings(ordersWithQuantity);
  }

  function modifyOfferings(offerings, tokens) {
    if (!tokens) {
      return offerings;
    }

    const ordersWithQuantity = offerings.map(offering => {
      const { ProductTotalSize = 0, ProductId } = offering;

      if (tokens && tokens[ProductId]) {
        const availableQuantity = new BigNumber(
          tokens[ProductId].available_tokens
        );
        const totalSize = new BigNumber(ProductTotalSize);
        const availableTokensPercent = availableQuantity
          .dividedBy(totalSize)
          .multipliedBy(100)
          .toFixed(2);
        const available_tokens =
          tokens[ProductId] && tokens[ProductId].available_tokens;
        return { ...offering, availableTokensPercent, available_tokens };
      } else {
        return {
          ...offering,
          availableTokensPercent: '0',
          available_tokens: 0
        };
      }
    });

    return ordersWithQuantity;
  }

  return modifiedOfferings;
};
