import React from 'react';
import PropTypes from 'prop-types';
import {
  buyValue,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import {
  isProductByDefaultScenario,
  isProductByScriptScenario
} from '../../../redux/selectors/productsSelector';
import APIcon from '../APIcon/APIcon';
import ReactTooltip from 'react-tooltip';
import { BuyButton, SellButton } from '../Buttons/Buttons';
import './BuySellProductCard.css';

const classes = getBEMClasses('buy-sell-product-card');

const BuySellProductCardComponent = (props, context) => {
  const {
    item: {
      ProductFullName,
      BestOffer,
      BestBid,
      Rolling24HrPxChangePercent = 0,
      ProductId,
      LastTradedPx
    },
    color,
    cardImageURL,
    cardBackgroundImage,
    buyOnly = false,
    onCardPress = () => {},
    onBuySell,
    orderType,
    onlyLimitAvailable,
    isSellDisable,
    buySellAvailable = true,
    checkIsEnoughOnMarket
  } = props;

  const onBuySellCallback = side => {
    onBuySell({
      productId: ProductId,
      side,
      orderType,
      onlyLimitAvailable: onlyLimitAvailable || !checkIsEnoughOnMarket(side)
    });
  };

  // TODO(May 17, 2022): uncomment when we need the warning again. Tip: all commented usages have this comment.
  // const isWarningVisible = isWarningRequired(Number(BestOffer));

  return (
    <div
      className={classes('card-wrapper')}
      data-test="buy-sell-product-card-root"
      style={{
        background: cardBackgroundImage
          ? `url(${cardBackgroundImage})
          right 0% center
         / contain, ${color}`
          : color
      }}
      onClick={onCardPress}>
      <div className={classes('card-title-wrapper')} data-test="buy-sell-product-card-content">
        <div className={classes('card-title')} data-test="buy-sell-product-card-title">{ProductFullName}</div>
        <div className={classes('card-price-change')}>
          <APIcon
            name="buySellUpArrow"
            customClass={classes(
              Rolling24HrPxChangePercent >= 0 ? 'arrowUp' : 'arrowDown'
            )}
          />
          {Rolling24HrPxChangePercent
            ? Rolling24HrPxChangePercent.toFixed(2)
            : 0}%
        </div>
      </div>
      {/* <div className={classes('warning-wrapper', { hidden: !isWarningVisible })}>
          <PriceWarningTag />
        </div> */}
      <img
        src={cardImageURL}
        className={classes('card-image')}
        alt="product-card"
      />

      <div className={classes('last-price-wrapper')}>
        <div className={classes('last-price-title')}>
          {context.t('Last price')}
        </div>
        <div>
          <span className={classes('last-price-value')}>{LastTradedPx}</span>
          <span className={classes('last-price-value-currency')}>USD</span>
        </div>
      </div>

      {buySellAvailable && (
        <React.Fragment>
          <div className={classes('buttons')}>
            <BuyButton
              onClick={e => {
                e.stopPropagation();
                onBuySellCallback(buyValue);
              }}
              subTitle={
                <span className={classes('button-value')}>{BestBid} USD</span>
              }
            />
            {!buyOnly && (
              <SellButton
                data-tip="You cannot sell this product at this time."
                data-tip-disable={!isSellDisable}
                onClick={
                  isSellDisable
                    ? () => {}
                    : e => {
                        e.stopPropagation();
                        onBuySellCallback(sellValue);
                      }
                }
                subTitle={
                  <span className={classes('button-value')}>
                    {BestOffer} USD
                  </span>
                }
              />
            )}
          </div>
          <ReactTooltip />
        </React.Fragment>
      )}
    </div>
  );
};

BuySellProductCardComponent.propTypes = {
  item: PropTypes.object,
  color: PropTypes.string,
  baseCurrency: PropTypes.string,
  cardImageURL: PropTypes.string,
  buyOnly: PropTypes.bool,
  cardBackgroundImage: PropTypes.string,
  buySellAvailable: PropTypes.bool,
  checkIsEnoughOnMarket: PropTypes.func.isRequired,
  onCardPress: PropTypes.func,
  onBuySell: PropTypes.func,
  orderType: PropTypes.string,
  onlyLimitAvailable: PropTypes.bool,
  isSellDisable: PropTypes.bool
};

BuySellProductCardComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const {
    item: { ProductId }
  } = ownProps;
  const onlyMarketAvailable = Boolean(
    isProductByDefaultScenario(state, ProductId)
  );
  const onlyLimitAvailable = Boolean(
    isProductByScriptScenario(state, ProductId)
  );
  const isSellDisable = onlyMarketAvailable || onlyLimitAvailable;
  const checkIsEnoughOnMarket = () => {
    // TODO(Apr 05, 2022): uncomment or remove when we decide whether we need buy/sell limit orders
    // return BigNumber(
    //   approximateQuantitySelector(state, { side, productId: ProductId })
    // ).gt(0);
    return true;
  };
  return {
    buyOnly: ownProps.buyOnly,
    onlyLimitAvailable,
    isSellDisable,
    checkIsEnoughOnMarket
  };
};

const BuySellProductCard = connect(mapStateToProps)(
  BuySellProductCardComponent
);

export { BuySellProductCard };
