import React from 'react';
import PropTypes from 'prop-types';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { numberToLocale } from '../../../helpers/numberFormatter';
import Logo from '../../../images/icon-logo-big.png';
import './MyTopCollectibleSection.css';
const baseClasses = getBEMClasses('top-collectible');

export const MyTopCollectibleSection = (props, context) => {
  const isMobile = useIsMobile();
  const { topCollectible, isReady, baseCurrency } = props;

  if (!isReady) {
    return (
      <div
        className={`${baseClasses('NoAssetsWrapper')} ${baseClasses(
          'container'
        )}`}>
        <div className={baseClasses('no-assets-wrapper')}>
          <img
            src={Logo}
            className={baseClasses('no-assets-logo')}
            alt="logo"
          />
          <div className={baseClasses('no-assets-text')}>
            {context.t('You own no collectibles for now.')}
          </div>
        </div>
      </div>
    );
  }

  const { AvailableBalanceInBaseCurrency, Amount, BestOffer } = topCollectible;
  const { ProductFullName } = topCollectible.product;
  const {
    ProductTopCollectibleBackgroundImageURL,
    ProductTopCollectibleBackgroundImageMobileURL,
    ProductDescription,
    TopCollectibleProductSizeBackgroundColor,
    ProductTopCollectibleBackgroundColor,
    TopCollectibleTextColor,
    TopCollectibleInfoBackground
  } = topCollectible.product.productAttributes;
  const backgroundMobileImage = `url(${ProductTopCollectibleBackgroundImageMobileURL})`;
  const backgroundImage = `url(${ProductTopCollectibleBackgroundImageURL})`;

  return (
    <div
      style={{
        backgroundImage: isMobile ? backgroundMobileImage : backgroundImage,
        backgroundSize: isMobile ? 'cover' : 'contain',
        backgroundColor: ProductTopCollectibleBackgroundColor || 'black',
        '--topCollectibleTextColor': TopCollectibleTextColor
      }}
      className={baseClasses('container')}>
      <div className={baseClasses('content')}>
        <div className={baseClasses('title')}>
          {context.t('Top Collectible')}
        </div>
        <div className={baseClasses('name')}>{ProductFullName}</div>
        <div className={baseClasses('description')}>{ProductDescription}</div>
        <div
          style={{ background: TopCollectibleProductSizeBackgroundColor }}
          className={baseClasses('total-value')}>
          <div className={baseClasses('total-value-title')}>
            {context.t('Total Collectible Value')}
          </div>
          <div className={baseClasses('value-number')}>
            {`${AvailableBalanceInBaseCurrency} ${baseCurrency}`}
          </div>
          <div />
        </div>
        <div className={baseClasses('footer-info')}>
          <div
            style={{
              border: `2px solid white`,
              background: TopCollectibleInfoBackground
            }}
            className={baseClasses('footer-item')}>
            <div className={baseClasses('footer-item-title')}>
              {context.t('Total Tokens')}
            </div>
            <div className={baseClasses('footer-value')}>
              {numberToLocale(Amount)}
            </div>
          </div>
          <div
            style={{
              border: `2px solid white`,
              background: TopCollectibleInfoBackground
            }}
            className={baseClasses('footer-item')}>
            <div className={baseClasses('footer-item-title')}>
              {context.t('Highest Valued Token')}
            </div>
            <div className={baseClasses('footer-value')}>{`$${BestOffer}`}</div>
          </div>
        </div>
      </div>
      <div className={baseClasses('content')} />
    </div>
  );
};

MyTopCollectibleSection.propTypes = {
  topCollectible: PropTypes.shape({
    AvailableBalanceInBaseCurrency: PropTypes.string,
    Amount: PropTypes.number,
    BestOffer: PropTypes.string,
    product: PropTypes.shape({
      ProductFullName: PropTypes.string,
      productAttributes: PropTypes.shape({
        ProductTopCollectibleBackgroundImageURL: PropTypes.string,
        ProductTopCollectibleBackgroundImageMobileURL: PropTypes.string,
        TopCollectibleProductSizeBackgroundColor: PropTypes.string,
        ProductDescription: PropTypes.string,
        ProductColor: PropTypes.string,
        ProductTopCollectibleBackgroundColor: PropTypes.string,
        TopCollectibleTextColor: PropTypes.string,
        TopCollectibleInfoBackground: PropTypes.string
      })
    })
  }),
  isReady: PropTypes.bool.isRequired,
  baseCurrency: PropTypes.string.isRequired
};

MyTopCollectibleSection.contextTypes = {
  t: PropTypes.func.isRequired
};
