import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BigNumber from 'bignumber.js';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './AvailabilityProgressBar.css';

const AvailabilityProgressBar = props => {
  const {
    baseClass = 'availability-progress-bar',
    customClass,
    classModifiers,
    currentValue,
    totalValue
  } = props;

  const classes = getBEMClasses(baseClass, customClass, classModifiers);

  const currentValueBigNumber = useMemo(() => new BigNumber(currentValue), [
    currentValue
  ]);
  const totalValueBigNumber = useMemo(() => new BigNumber(totalValue), [
    totalValue
  ]);
  const percent = useMemo(
    () =>
      Math.floor(
        currentValueBigNumber
          .div(totalValueBigNumber)
          .times(100)
          .toNumber()
          .toFixed(2)
      ),
    [currentValue, totalValue]
  );
  return (
    <div className={classes('')}>
      <div className={classes('title')}>Availability:</div>
      <div className={classes('progress-container')}>
        <div className={classes('progress-line-wrapper')}>
          <progress id={'availability-progress'} max="100" value={percent} />
        </div>
        <div className={classes('value')}>{percent}%</div>
      </div>
    </div>
  );
};

AvailabilityProgressBar.propTypes = {
  baseClass: PropTypes.string,
  customClass: PropTypes.string,
  classModifiers: PropTypes.string,
  currentValue: PropTypes.number.isRequired,
  totalValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default AvailabilityProgressBar;
