import { APP_INIT } from 'apex-web/lib/redux/actions/lifeCycleActions';
import { getUserInfo } from 'apex-web/lib/redux/actions/userActions';
import { getUserApiKeys } from 'apex-web/lib/redux/actions/apiKeyActions';
import { getAffiliateCount } from 'apex-web/lib/redux/actions/affiliateActions';
import {
  getUserReports,
  getUserReportWriterResults
} from 'apex-web/lib/redux/actions/reportActions';
import { pingBackend } from 'apex-web/lib/redux/actions/wsConnectionActions';

import {
  AUTH_SUCCESS,
  FORCED_LOGOUT,
  subscribeToLogoutEvent
} from 'apex-web/lib/redux/actions/authActions';
import { validateSessionToken } from '../actions/authActions';
import { loadStartupData } from 'apex-web/lib/redux/actions/appActions';
import { getApiInfo } from 'apex-web/lib/redux/actions/apiActions';
import { wsClient } from '../../api/ws/wsClient';
import { resetUser, resetUserConfig } from '../actions/userActions';

const authMiddleware = store => next => action => {
  const { dispatch } = store;

  if (action.type === AUTH_SUCCESS) {
    onLogin(dispatch, action);
  } else if (action.type === APP_INIT) {
    onAppInit(dispatch);
  } else if (action.type === FORCED_LOGOUT) {
    onLogout(dispatch);
    if (wsClient.connection) {
      wsClient.connection.close(
        1000,
        JSON.stringify({
          payload: { message: 'Logout user' }
        })
      );
    }
  }

  return next(action);
};

const setOnConnectionChange = dispatch => {
  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection ||
    navigator.msConnection;
  if (connection) {
    connection.onchange = () => {
      if (navigator.onLine) {
        dispatch(pingBackend());
      }
    };
  }
};

const onAppInit = dispatch => {
  setOnConnectionChange(dispatch);
  dispatch(subscribeToLogoutEvent());

  const token = localStorage.getItem('token');
  if (token) {
    dispatch(validateSessionToken(token));
  }

  // (!oldValue) If we are creating the token key in localStorage, we want to log in on any tabs
  // (!newValue) If we are removing the token key in localStorage, we want to log out on any tabs
  window.addEventListener('storage', event => {
    const { key, oldValue, newValue } = event;
    if (key === 'token') {
      if (!oldValue) {
        //refresh once tab if user  goes to her
        document.addEventListener(
          'visibilitychange',
          function visibleFunction() {
            if (!document.hidden) {
              document.removeEventListener('visibilitychange', visibleFunction);
              window.location.reload();
            }
          }
        );
      } else if (!newValue) {
        window.location.reload();
      }
    }
  });
};

const onLogin = (dispatch, action) => {
  localStorage.setItem('token', action.payload.token);
  dispatch(initDataAfterLogin(action.payload.userId));
};

const onLogout = (dispatch) => {
  localStorage.removeItem('token');
  dispatch(resetUserConfig());
  dispatch(resetUser());
};

const initDataAfterLogin = userId => async dispatch => {
  dispatch(getApiInfo());
  dispatch(loadStartupData());
  dispatch(getUserInfo(userId));
  dispatch(getUserApiKeys(userId));
  dispatch(getAffiliateCount());
  dispatch(getUserReports(userId));
  dispatch(getUserReportWriterResults());
};

export default authMiddleware;
