import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import BigNumber from 'bignumber.js';
import APIcon from '../APIcon/APIcon';
import './AmountNumberInput.css';

const classes = getBEMClasses('amount-number-input');

export const AmountNumberInput = (props, context) => {
  const { amount, setAmount, maxValue } = props;
  const isPlusButtonDisabled =
    maxValue && new BigNumber(amount).toNumber() >= maxValue * 100;

  return (
    <div className={classes('amountInputWrapper')}>
      <label>{context.t('Enter amount:')}</label>
      <div className={classes('input-area')}>
        <button
          className={classes('amountButton')}
          onClick={() => {
            if (amount - 100 >= 50) {
              setAmount(val => new BigNumber(val).minus(100).toNumber());
            } else if (amount === 100) {
              setAmount(0);
            }
          }}>
          <APIcon name="roundedMinus" customClass={classes('icon')} />
        </button>

        <input
          className={classes('amount-input')}
          type="number"
          value={new BigNumber(amount).div(100).toNumber()}
          onClick={e => {
            // On click input will select whole number so that user will enter new amount easily
            e.target.select();
          }}
          onChange={e => {
            const newAmount = new BigNumber(e.target.value)
              .times(100)
              .toNumber();

            // If user enters a negative number than input will not react at all on this changes.
            if (e.target.value < 0 || (maxValue && e.target.value > maxValue)) {
              return;
            }

            // If new amount is not valid (user entered empty string for example) than input will be set to default value
            if (isNaN(newAmount)) {
              setAmount(0);
              return;
            }

            setAmount(Math.floor(newAmount));
          }}
        />

        <button
          className={classes(
            'amount-button',
            isPlusButtonDisabled && 'disabled'
          )}
          disabled={isPlusButtonDisabled}
          onClick={() => {
            setAmount(val => {
              return new BigNumber(val).plus(100).toNumber();
            });
          }}>
          <APIcon name="roundedPlus" customClass={classes('icon')} />
        </button>
      </div>
    </div>
  );
};

AmountNumberInput.propTypes = {
  amount: PropTypes.number.isRequired,
  setAmount: PropTypes.func.isRequired,
  // maxValue in USD
  maxValue: PropTypes.number
};

AmountNumberInput.contextTypes = {
  t: PropTypes.func
};
