import { connect } from 'react-redux';
import get from 'lodash/get';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import InstrumentInfoSliderComponent from './InstrumentInfoSliderComponent';
import { productPositionByIdSelector, userBalanceByProductSelector } from '../../redux/selectors/productsSelector';
import { lastPriceSelector, realtimeLastPriceSelector } from '../../redux/selectors/instrumentSelector';
import { availableTokensSelector } from '../../redux/selectors/cardSelector';

const mapStateToProps = (state) => {
  const selectedInstrument = selectedInstrumentSelector(state);

  const { level1 } = state.apexCore ? state.apexCore : {};
  const selectedInstrumentId = get(selectedInstrument, 'InstrumentId', '');
  let userTokens = 0, userFunds = 0, lastPrice = 0, availableTokens = 0;
  if (selectedInstrumentId) {
    userTokens = productPositionByIdSelector(selectedInstrument.Product1)(state).Amount;
    userFunds = userBalanceByProductSelector(state, selectedInstrument.Product2);
    lastPrice = realtimeLastPriceSelector(state) ||
      lastPriceSelector(state) ||
      0;
    availableTokens = get(
      availableTokensSelector(state),
      [selectedInstrument.Product1, 'available_tokens'],
      0
    );
  }

  return {
    level1: level1[selectedInstrumentId] || [],
    selectedInstrument,
    userTokens,
    userFunds,
    lastPrice,
    availableTokens
  };
};

export default connect(
  mapStateToProps, null
)(InstrumentInfoSliderComponent);
