import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../../../helpers/cssClassesHelper';
import { updateAssetsWatchList } from '../../../../redux/actions/cardActions';
import { selectIsAssetInWatchList } from '../../../../redux/selectors/cardSelector';
import APIcon from '../../APIcon';
import '../Buttons.css';

export const AddToFavoriteButtonComponent = (props, context) => {
  const {
    customClass,
    productId,
    isFavorite,
    addToFavorite,
    isUseHoverState,
    ...restProps
  } = props;
  const classes = getBEMClasses('buttons', customClass);
  return (
    <button
      data-test="add-to-favorite"
      className={classnames(
        classes('button', 'add-to-favorite'),
        isUseHoverState && classes('button', 'add-to-favorite-always-hover'),
        isFavorite && classes('button', 'add-to-favorite-active')
      )}
      onClick={() => {
        addToFavorite(productId);
      }}
      {...restProps}>
      <APIcon
        name={'favoriteSmall'}
        customClass={classes('favorite-icon', isFavorite && 'active')}
      />
      <span
        className={classes('add-to-watchlist-text', isFavorite && 'active')}>
        {context.t(isFavorite ? 'Watchlist Added' : 'Add to Watchlist')}
      </span>
    </button>
  );
};

AddToFavoriteButtonComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

AddToFavoriteButtonComponent.propTypes = {
  productId: PropTypes.any,
  isFavorite: PropTypes.bool,
  addToFavorite: PropTypes.func,
  isUseHoverState: PropTypes.bool,
  customClass: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return {
    isFavorite: selectIsAssetInWatchList(state, ownProps.productId)
  };
};

const mapDispatchToProps = dispatch => ({
  addToFavorite: productId => dispatch(updateAssetsWatchList(productId))
});

export const AddToFavoriteButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddToFavoriteButtonComponent);
