import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import BigNumber from "bignumber.js";
import { getBEMClasses } from "../../../helpers/cssClassesHelper";
import { formatNumberToLocale } from "../../../helpers/numberFormatter";
import PercentageSlider from "../PercentageSlider";
import { convertIncrementToIntDecimalPlaces as iToDp } from "apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper";
import {
  getNormalizationCallback,
  preventSomeNumberSymbols
} from '../../../helpers/buySellHelper';
import './QuantityPicker.css';
import { connect } from "react-redux";

const classes = getBEMClasses('quantity-picker');


const QuantityPicker = (props, context) => {
  const {
    dispatch,
    quantity,
    currencySymbol,
    inputIncrement,
    inputRegExp,
    maxCurrency,
    minCurrency = 0,
    convert = noopConvert,
    onChange,
    isBuy = false,
    disabled = false,
    showTrailingZeros = true
  } = props;

  const sideCssModifier = isBuy && 'buy';

  const fromQuantityToPercentage = useCallback((quantity) => {
    return maxCurrency.eq(0) ?
      0 :
      BigNumber(convert.fromQuantityToCurrency(quantity, dispatch))
        .div(maxCurrency)
        .times(100)
        .dp(0, BigNumber.ROUND_HALF_UP)
        .toNumber();
  }, [convert.fromQuantityToCurrency, Number(maxCurrency)]);

  const percentage = fromQuantityToPercentage(quantity);

  const [_isInputVisible, _setIsInputVisible] = useState(false);
  let isInputVisible, setIsInputVisible;
  if (typeof props.isInputVisible !== undefined && props.setIsInputVisible) {
    isInputVisible = props.isInputVisible;
    setIsInputVisible = props.setIsInputVisible;
  } else {
    isInputVisible = _isInputVisible;
    setIsInputVisible = _setIsInputVisible;
  }

  const [isPickerFocused, setIsPickerFocused] = useState(false);

  const onPickerChange = useCallback(percentage => {
    onChange(
      convert.fromCurrencyToQuantity(
        maxCurrency.div(100).times(percentage),
        dispatch
      )
    );
  }, [convert.fromCurrencyToQuantity, onChange, Number(maxCurrency), isPickerFocused]);

  const [input, setInput] = useState();
  const [isInputFocused, setIsInputFocused] = useState(false);
  useEffect(() => {
    if (!isInputFocused) {
      let value;
      if (!isNaN(quantity) && quantity > 0) {
        value = convert.fromQuantityToCurrency(quantity, dispatch)
          .dp(iToDp(inputIncrement), BigNumber.ROUND_HALF_UP)
          .toNumber();
      }
      setInput(value);
    }
  }, [isInputFocused, Number(quantity), convert.fromQuantityToCurrency]);
  useEffect(() => {
    // handle an empty string, otherwise BigNumber will treat it as NaN
    const numberInput = Number(input);
    if (!isNaN(numberInput) && isInputFocused) {
      const newQuantity = convert.fromCurrencyToQuantity(numberInput, dispatch);
      onChange(newQuantity);
    }
  }, [input, isInputFocused, convert.fromCurrencyToQuantity, onChange]);
  const normalizeInput = useCallback(
    getNormalizationCallback(inputRegExp),
    [String(inputRegExp)]
  );
  const inputListeners = useMemo(() => ({
    onChange: e => {
      const inputEl = e.target;
      inputEl.setCustomValidity && inputEl.setCustomValidity('');
      const newValue = inputEl.value;
      setInput(prevValue =>
        normalizeInput(newValue, prevValue)
      );
    },
    onFocus: () => setIsInputFocused(true),
    onBlur: () => setIsInputFocused(false),
    onInvalid: (e) => {
      const inputEl = e.target;
      if (inputEl && inputEl.validity.rangeOverflow && inputEl.max === '0') {
        inputEl.setCustomValidity(context.t('You can’t afford this product'));
      }
    }
  }), [normalizeInput]);

  const currency = convert.fromQuantityToCurrency(quantity, dispatch);
  const currencyDp = iToDp(inputIncrement);
  const currencyValue = showTrailingZeros ?
    formatWithTrailingZeros(currency, currencyDp) :
    format(currency, currencyDp);
  const sliderValueStyle = useMemo(() => ({
    display: 'inline-block',
    minWidth: `${countIntDigits(maxCurrency) + Number(showTrailingZeros && currencyDp + 1)}ch`
  }), [Number(maxCurrency), showTrailingZeros, currencyDp]);

  return (
    <div className={classes('', sideCssModifier)}
      data-test='quantity-picker-root'>
      <div className={classes('picker-container')}>
        <PercentageSlider
          value={percentage}
          onChange={onPickerChange}
          onFocus={() => setIsPickerFocused(true)}
          onBlur={() => setIsPickerFocused(false)}
          step={1}
          marks={marks}
          disabled={disabled}
          isTooltipAlwaysVisible={props.isTooltipAlwaysVisible} />
        <div
          className={classes('currency-value')}>
          <span style={sliderValueStyle}>
            {currencyValue}
          </span>
          {' '}
          <span className={classes('currency-value-symbol')}>
            {currencySymbol}
          </span>
        </div>
      </div>
      <div className={classes('input-container', { expanded: isInputVisible })}>
        <button
          type="button"
          className={classes('show-input')}
          onClick={() => setIsInputVisible(s => !s)}
          data-test='quantityCustom'>
          {context.t('Edit Amount')}
        </button>
        {isInputVisible && (
          <div className={classes('input-wrapper')}>
            <input
              className={classes('input')}
              type="number"
              value={input ? input.toString() : ''}
              {...inputListeners}
              placeholder={0}
              step={inputIncrement}
              min={Number(minCurrency)}
              max={Number(maxCurrency)}
              disabled={disabled}
              onKeyDown={preventSomeNumberSymbols}
              data-test='quantity' />
            <span className={classes('input-symbol')}>
              {currencySymbol}
            </span>
          </div>
        )}
      </div>
    </div>);
};

const marks = [0, 25, 50, 75, 100];
const noopConvert = {
  fromCurrencyToQuantity: (currency) => currency instanceof BigNumber ?
    currency :
    BigNumber(currency),
  fromQuantityToCurrency: (quantity) => quantity instanceof BigNumber ?
    quantity :
    BigNumber(quantity),
};

const formatWithTrailingZeros = (number, dp) => formatNumberToLocale(
  number,
  dp,
  BigNumber.ROUND_HALF_UP
);
const format = (number, dp) => formatNumberToLocale(
  BigNumber(number).dp(dp, BigNumber.ROUND_HALF_UP)
);

const countIntDigits = (number) => {
  let bn = number instanceof BigNumber ? number : BigNumber(number);
  return bn.abs().dp(0, BigNumber.ROUND_DOWN).toString().length;
};

QuantityPicker.propTypes = {
  dispatch: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  inputIncrement: PropTypes.number.isRequired,
  inputRegExp: PropTypes.instanceOf(RegExp).isRequired,
  maxCurrency: PropTypes.instanceOf(BigNumber).isRequired,
  minCurrency: PropTypes.instanceOf(BigNumber),
  convert: PropTypes.shape({
    fromCurrencyToQuantity: PropTypes.func.isRequired,
    fromQuantityToCurrency: PropTypes.func.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  isBuy: PropTypes.bool,
  disabled: PropTypes.bool,
  showTrailingZeros: PropTypes.bool,
  isInputVisible: PropTypes.bool,
  setIsInputVisible: PropTypes.func,
  isTooltipAlwaysVisible: PropTypes.bool
};

QuantityPicker.contextTypes = {
  t: PropTypes.func
};

export default connect()(QuantityPicker);

