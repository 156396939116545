import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SelectAssetFromWatchListModalComponent } from './SelectAssetFromWatchListModalComponent';
import { MODAL_TYPES } from '../../redux/actions/modalActions';
import { modalDecorator } from '../common/Modal/modalDecorator';
import { selectInstrument } from 'apex-web/lib/redux/actions/instrumentActions';
import {
  selectedInstrumentSelector,
  instrumentSelectorInstruments
} from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { selectWatchListDigitalAssetsWithAttributes } from '../../redux/selectors/productsSelector';
import { isProductSoldOutSelector } from '../../redux/selectors/productsSelector';
import { destroy } from 'redux-form';
import { setActivePage } from 'apex-web/lib/redux/actions/paginationActions';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';

const mapStateToProps = state => {
  const instrument = state.apexCore.instrument;
  const { fetching } = instrument;
  const { verificationLevel } = kycVerificationLevelsSelector(state);
  const selectedInstrument = selectedInstrumentSelector(state);
  const availableInstruments = instrumentSelectorInstruments(state).filter(
    instrument =>
      isProductSoldOutSelector(state, instrument.Product1) ||
      verificationLevel === 99
  );
  const assets = selectWatchListDigitalAssetsWithAttributes(state);
  const availableAssets = assets.filter(
    asset =>
      isProductSoldOutSelector(state, asset.ProductId) ||
      verificationLevel === 99
  );
  return {
    instruments: availableInstruments,
    assets: availableAssets,
    selectedInstrument,
    fetching
  };
};

const mapDispatchToProps = {
  selectInstrument,
  clearProForm: () => destroy('orderEntry'),
  resetPage: componentName => setActivePage({ componentName, activePage: 0 })
};

const SelectAssetFromWatchListModal = modalDecorator({
  name: MODAL_TYPES.SELECT_ASSET_FROM_WATCH_LIST_MODAL
})(SelectAssetFromWatchListModalComponent);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SelectAssetFromWatchListModal));
