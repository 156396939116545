import { connect } from 'react-redux';

import { UpcomingOfferings } from './UpcomingOfferingsComponent';
import { selectUpcomingOffers } from '../../../redux/selectors/productsSelector';
import { selectAssetsBalanceInBaseCurrency } from '../../../redux/selectors/level1Selector';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => {
  const assets = selectAssetsBalanceInBaseCurrency(state);
  const upcomingOfferedProducts = selectUpcomingOffers(state);

  const res = [];

  upcomingOfferedProducts.forEach(product => {
    const targetProduct = assets.assetsWithPriceAndPercent.find(
      asset => asset.ProductId === product.ProductId
    );
    if (targetProduct) {
      res.push({
        title: product.ProductFullName,
        rarityCategory: product.productAttributes.ProductRarityCategory,
        rarity: product.productAttributes.ProductRarity,
        cardImageURL: product.productAttributes.ProductImageURL,
        productId: product.ProductId,
        totalSize: product.productAttributes.ProductTotalSize
      });
    }
  });
  if (!res.length) {
    return {
      cards: [],
      isReady: false,
      availableTokens: state.availableTokens.byProductId
    };
  }
  return {
    cards: res,
    isReady: true,
    isLiveOffering: false,
    availableTokens: state.availableTokens.byProductId
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(UpcomingOfferings));
