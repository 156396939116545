import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { getGoogle2FARecoveryCode } from 'apex-web/lib/redux/actions/userActions';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';

import GetGoogle2FARecoveryKeyModal from './GetGoogle2FARecoveryKeyModal';

const mapStateToProps = state => {
  const { form } = state;
  const recoveryForm = form.google2FARecoveryKeyModal || { values: {} };

  return {
    recoveryForm,
    initialValues: {
      recoveryKey: ''
    }
  };
};

const mapDispatchToProps = dispatch => ({
  showSnack: text => dispatch(showSnack(text)),
  onCancel: () => dispatch(reset('google2FARecoveryKeyModal'))
});

const containerForm = reduxForm({
  destroyOnUnmount: true,
  form: 'google2FARecoveryKeyModal',
  onSubmit: (payload, dispatch) =>
    dispatch(getGoogle2FARecoveryCode(payload.code)),
  onChange: (values, dispatch, props) => {
    if (values.code && values.code.length === 6) {
      props.submit();
    }
  }
})(GetGoogle2FARecoveryKeyModal);

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(containerForm);

export default modalDecorator({
  name: MODAL_TYPES.GET_GOOGLE_2FA_RECOVERY_CODE
})(container);
