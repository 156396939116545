import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';
import {
  styleNames,
  styleNamePropType
} from 'apex-web/lib/propTypes/commonComponent';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { getDataTestAttribute } from 'apex-web/lib/helpers/dataTestAttributeHelper';

import './APRadioTab.css';

export const RadioTab = props => {
  const {
    items,
    input,
    baseClass,
    customClass,
    styleName,
    onClick,
    defaultValue
  } = props;

  const baseClasses = getBEMClasses(baseClass);
  const customClasses = getBEMClasses(customClass);

  return (
    <div
      className={classnames(
        'btn-group btn-group-toggle',
        baseClasses(),
        customClasses('rt-wrapper', styleName)
      )}
      data-toggle="buttons">
      {items.map((item) => {
        const inputValueIsSet = input.value || typeof input.value === 'boolean';
        const checked =
          item.value === (inputValueIsSet ? input.value : defaultValue);
        const disabled = item.disabled;
        return (
          <label
            data-tip={item.tooltip}
            className={classnames(
              'btn',
              getLabelClass(
                baseClasses,
                'label',
                item.styleName || styleName,
                checked,
                disabled
              ),
              getLabelClass(
                customClasses,
                'rt-label',
                item.styleName || styleName,
                checked,
                disabled
              )
            )}
            key={item.value}
            onClick={onClick}
            data-test={getDataTestAttribute(item, 'text')}>
            <input
              type="radio"
              id={item.value.toString()}
              {...input}
              disabled={disabled}
              checked={checked}
              value={item.value}
              onClick={item.onClick}
              onBlur={e => e.preventDefault()}
              onChange={e => {
                const value =
                  typeof item.value === 'boolean'
                    ? e.target.value === 'true'
                    : e.target.value;
                input.onChange(value);
              }}
            />
            {item.text}
          </label>
        );
      })}
    </div>
  );
};

const getLabelClass = (bem, element, styleName, isChecked, isDisabled) => {
  return bem({
    element,
    modifiers: classnames(styleName, {
      checked: isChecked,
      [`checked-${styleName}`]: isChecked,
      [`disabled-${styleName}`]: isDisabled
    })
  });
};

const itemType = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]).isRequired,
  text: PropTypes.string.isRequired,
  dataTest: PropTypes.string
});

RadioTab.propTypes = {
  items: PropTypes.arrayOf(itemType).isRequired,
  baseClass: PropTypes.string,
  customClass: PropTypes.string,
  onClick: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  styleName: styleNamePropType,
  input: PropTypes.any
};

RadioTab.defaultProps = {
  baseClass: 'ap-radio-tab',
  styleName: styleNames.general
};

const APRadioTab = props => <Field component={RadioTab} {...props} />;

export default APRadioTab;
