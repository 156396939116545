import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import '../styles/components/hero-banner.css';
import { CoOwnButton } from './CoOwnButton';

const HeroBanner = props => {
  const {
    numbers,
    numberColor,
    imageInsteadVideo,
    gradientColors,
    cardVideoMobile,
    cardVideoDesktop,
    description,
    fullName,
    assetNumber,
    useOriginalMarket,
    isStandalone,
    coOwnData,
    isHtmlTitle
  } = props;
  const [isMediaLoading, setMediaLoading] = useState(true);
  const onMediaStartedLoading = useCallback(() => setMediaLoading(true), []);
  const onMediaFinishedLoading = useCallback(() => setMediaLoading(false), []);
  const isOddTotal = numbers.length % 2;
  const formattedNumbers = numbers.map((value, i) => (
    <HeroNumber
      key={value.name}
      value={value}
      numberColor={numberColor}
      isHighlighted={i === 0 || (!isOddTotal && i === 1)}
      isOddTotal={isOddTotal}
      useOriginalMarket={useOriginalMarket}
    />
  ));

  return (
    <div
      className={'hero-banner'}
      style={{
        background: `linear-gradient(91deg, ${gradientColors[0]} 0%, ${
          gradientColors[1]
        } 100%)`
      }}>
      {!imageInsteadVideo && (
        <div className="hero-desktop-video-container">
          <video
            className="hero-desktop-video"
            autoPlay
            loop
            playsInline
            controls=""
            muted
            onLoadStart={onMediaStartedLoading}
            onCanPlay={onMediaFinishedLoading}
            src={cardVideoDesktop}
          />
        </div>
      )}
      <div
        className={classNames([
          'hero',
          !imageInsteadVideo && 'hero--with-video'
        ])}>
        {isStandalone ? (
          <div className={'hero-banner__asset-main-data'}>
            <div className={'hero-banner__asset-main-data-text'}>
              <div
                className={'hero-banner__asset-number'}
                style={{ color: numberColor }}>
                #{assetNumber}
              </div>
              <div className={'hero-banner__divider'} />
              <div className={'hero-banner__asset-name'}>{fullName}</div>
            </div>
            {coOwnData && <CoOwnButton {...coOwnData} />}
          </div>
        ) : (
          coOwnData && (
            <div className={'hero-banner__inner-co-own-wrapper'}>
              <CoOwnButton {...coOwnData} />
            </div>
          )
        )}
        <div className="hero-description">
          {isHtmlTitle ? (
            <p
              className="hero-description-text"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : (
            <p className="hero-description-text">{description}</p>
          )}
        </div>
        <div className="hero-video-container">
          {imageInsteadVideo ? (
            <img
              className={classNames([
                'hero-img',
                coOwnData && 'hero-img-margin'
              ])}
              src={imageInsteadVideo}
              alt="Main"
              onLoad={onMediaFinishedLoading}
            />
          ) : (
            <video
              className="hero-mobile-video"
              autoPlay
              loop
              playsInline
              controls=""
              muted
              onLoadStart={onMediaStartedLoading}
              onCanPlay={onMediaFinishedLoading}
              src={cardVideoMobile}
            />
          )}
          {isMediaLoading && <Spinner customClass="hero-video" isInline />}
        </div>
        <div className="hero-numbers">{formattedNumbers}</div>
      </div>
    </div>
  );
};

HeroBanner.propTypes = {
  numbers: PropTypes.array,
  numberColor: PropTypes.string,
  imageInsteadVideo: PropTypes.string,
  gradientColors: PropTypes.array,
  cardVideoMobile: PropTypes.string,
  cardVideoDesktop: PropTypes.string,
  description: PropTypes.string,
  fullName: PropTypes.string,
  assetNumber: PropTypes.string,
  useOriginalMarket: PropTypes.bool,
  coOwnData: PropTypes.object,
  isStandalone: PropTypes.bool,
  isHtmlTitle: PropTypes.bool,
};

const HeroNumber = props => {
  const {
    value,
    numberColor,
    isHighlighted,
    isOddTotal,
    useOriginalMarket
  } = props;
  const highlightedNumbersStyle = {
    border: `1px solid ${numberColor}`,
    backgroundColor: `${numberColor}`,
    '--spinner__color-primary': 'var(--white1)'
  };
  let name = value.name;
  if (useOriginalMarket) {
    name =
      value.name === 'Market Value'
        ? 'Original Market Value'
        : value.name === 'PPT'
          ? 'Starting PPT'
          : value.name;
  }
  return (
    <div
      className={classNames([
        'hero-number-category',
        isOddTotal && 'odd-total'
      ])}
      style={{
        '--spinner__color-primary': numberColor,
        ...(isHighlighted && highlightedNumbersStyle)
      }}>
      <div className="hero-number-category-wrapper">
        <p className="hero-number-category-title">{name}</p>
        <p className="hero-number-category-value">
          {value.number ? (
            <React.Fragment>
              {value.isUseUSDSymbol ? '$' : ''}
              {value.number}
            </React.Fragment>
          ) : (
            <Spinner customClass="hero-number" isInline />
          )}
        </p>
      </div>
    </div>
  );
};

HeroNumber.propTypes = {
  value: PropTypes.object.isRequired,
  numberColor: PropTypes.string,
  isHighlighted: PropTypes.bool,
  isOddTotal: PropTypes.bool,
  useOriginalMarket: PropTypes.bool
};

export default HeroBanner;
