import { modalDecorator } from '../common/Modal/modalDecorator';
import { connect } from 'react-redux';
import KYCModal from './KYCModal';
import { MODAL_TYPES } from '../../redux/actions/modalActions';
import get from 'lodash/get';

const mapStateToProps = state => {
  return {
    userEmail: get(state, 'user.userInfo.Email', '')
  };
};

const mapDispatchToProps = {};

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCModal);

export default modalDecorator({
  name: MODAL_TYPES.KYC_MODAL
})(container);
