import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { useWindowSize } from 'react-use';

import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { AssetsFilter } from '../common/AssetsFilter';
import { Modal } from '../common/Modal/Modal';
import APIcon from '../common/APIcon';
import { ViewWatchListButton } from '../common/Buttons/Buttons';
import { SearchCardInput } from '../common/SearchCardInput';
import { InstrumentCard } from '../common/InstrumentCard';
import { UrlFactory } from '../../helpers/urlFactory';
import { sortAssets } from '../../helpers/sortHelper';

import './SelectAssetModal.css';

const baseClasses = getBEMClasses('select-asset-modal');

const SelectAssetModalComponent = (props, context) => {
  const {
    assets,
    instruments,
    selectInstrument,
    clearProForm,
    selectedInstrument,
    fetching,
    isOpen,
    close,
    history,
    openWatchListModal,
  } = props;
  const [searchString, setSearchString] = useState('');
  const [currentFilterOption, setCurrentFilterOption] = useState('All');
  const [isAscending, setIsAscending] = useState(false);
  const { width } = useWindowSize();
  const isUseMobileLayout = width <= 800;

  const filteredAssets = assets.filter(item =>
    item.product.ProductFullName.toLowerCase().includes(
      searchString.toLowerCase()
    )
  );

  useEffect(() => {
    if (!isOpen) {
      setSearchString('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (instruments.length === 0) return;

    const currentSelectedInstrument = instruments.find(instrument => {
      return instrument.InstrumentId === selectedInstrument.InstrumentId;
    });

    if (currentSelectedInstrument) return;

    const instrumentId = instruments[0].InstrumentId;
    selectInstrument(instrumentId);
  }, []);

  const handleSelectInstrument = assetId => {
    const currentInstrument = instruments.find(
      item => item.Product1 === assetId
    );
    if (currentInstrument) {
      selectInstrument(currentInstrument.InstrumentId);
    }
    close();
    clearProForm();
    history.push(UrlFactory.getProExchangeUrl());
  };

  const sortedAssets = useMemo(
    () =>
      [...filteredAssets].sort((a, b) =>
        sortAssets(a, b, currentFilterOption, isAscending)
      ),
    [filteredAssets.length, currentFilterOption, isAscending]
  );

  return (
    <Modal
      customClass={baseClasses()}
      isOpen={isOpen}
      close={close}
      titleComponent={
        <div className={baseClasses('header-wrapper')}>
          <APIcon name="changeAsset" customClass={baseClasses('header-icon')} />
          {context.t('Select Collectible')}
        </div>
      }>
      <div className={baseClasses('actions-wrapper')}>
        <ViewWatchListButton
          onClick={() => {
            openWatchListModal(close);
          }}
        />
        <SearchCardInput setSearchString={setSearchString} />
      </div>
      <div className={baseClasses('filter-wrapper')}>
        <AssetsFilter
          currentFilterOption={currentFilterOption}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          setCurrentFilterOption={setCurrentFilterOption}
          selectorOptions={selectorSortOptions}
          isUseAllOption={true}
        />
      </div>
      {fetching ? (
        <div className={baseClasses('loader-wrapper')}>
          <Spinner />
        </div>
      ) : sortedAssets.length === 0 ? (
        <div className={baseClasses('empty-assets')}>
          {context.t('No Collectible')}
        </div>
      ) : (
        <div className={baseClasses('cards-wrapper')}>
          {sortedAssets.map(row => (
            <InstrumentCard
              key={row.ProductId}
              row={row}
              isSelected={selectedInstrument.Product1 === row.ProductId}
              onClick={() => {
                handleSelectInstrument(row.ProductId);
              }}
              isUseMobileLayout={isUseMobileLayout}
            />
          ))}
        </div>
      )}
    </Modal>
  );
};

const selectorSortOptions = [
  {
    value: 'ProductFullName',
    label: 'Name'
  },
  {
    value: 'LastTradedPx',
    label: 'Last price',
    isAscendingOption: false
  }
];

SelectAssetModalComponent.propTypes = {
  assets: PropTypes.array,
  instruments: PropTypes.array,
  selectInstrument: PropTypes.func,
  clearProForm: PropTypes.func,
  selectedInstrument: PropTypes.object,
  fetching: PropTypes.bool,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  history: PropTypes.object,
  openWatchListModal: PropTypes.func,
  resetPage: PropTypes.func,
};

SelectAssetModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export { SelectAssetModalComponent };
