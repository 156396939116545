import { orderTypes } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { orderFormTypes } from 'apex-web/lib/constants/sendOrder/orderFormTypes';
import get from 'lodash/get';
import BigNumber from 'bignumber.js';

import {
  subscribeToStateEventsReplay,
  subscribeToTradeEventsReplay
} from './orderHistoryActions';
import { closeModal, MODAL_TYPES, openModal } from '../../redux/actions/modalActions';
import {
  BELOW_MARKET_WARNING,
  placeOrderWithChecks,
  placeOrder as finalPlaceOrder,
  calcFee as calcFormFee,
  calcMaxUsdTotal as calcMaxUsdFormTotal
} from '../../redux/actions/orderActions';
import { promiseWithThrottledLoading } from '../../helpers/promiseHelper';
import {
  getExpFromPrice,
  getOrderFeesForConfirmedOrder,
  roundFormFields
} from '../../helpers/buySellHelper';
import config from '../../config';
import { getOrderEventSnackbar } from 'apex-web/lib/helpers/placeOrderHelper';
import { baseCurrencySelector } from 'apex-web/lib/redux/selectors/baseCurrenciesSelectors';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { instrumentSelector } from '../selectors/instrumentSelector';
import { getOrdersBySide } from 'apex-web/lib/helpers/orderHelper';

export const UPDATE_FEE = 'BUY_SELL_UPDATE_FEE';
export const UPDATE_MAX_USD_TOTAL = 'BUY_SELL_UPDATE_MAX_USD_TOTAL';
export const UPDATE_QUANTITY = 'BUY_SELL_UPDATE_QUANTITY';
export const UPDATE_PRICE = 'BUY_SELL_UPDATE_PRICE';
export const START_ON_PLACE = 'BUY_SELL_START_ON_PLACE';
export const START_ON_CONFIRMATION = 'BUY_SELL_START_ON_CONFIRMATION';
export const SHOW_CONFIRMATION = 'BUY_SELL_SHOW_CONFIRMATION';
export const SHOW_BELOW_MARKET_WARNING = 'BUY_SELL_SHOW_BELOW_MARKET_WARNING';
export const SHOW_LOADING = 'BUY_SELL_SHOW_LOADING';
export const SHOW_SUCCESS = 'BUY_SELL_SHOW_SUCCESS';
export const SHOW_ACCEPTED = 'BUY_SELL_SHOW_ACCEPTED';
export const SHOW_ERROR = 'BUY_SELL_SHOW_ERROR';

const MODAL_NAME = MODAL_TYPES.BUY_SELL_MODAL;

const TIME_IN_FORCE_GTC = 1;

export const updateFee = orderFee => ({
  type: UPDATE_FEE,
  payload: { orderFee }
});

export const updateMaxUsdTotal = maxUsdTotal => ({
  type: UPDATE_MAX_USD_TOTAL,
  payload: { maxUsdTotal }
});

export const updateQuantity = quantity => ({
  type: UPDATE_QUANTITY,
  payload: { quantity }
});

export const updatePrice = price => ({
  type: UPDATE_PRICE,
  payload: { price }
});

export const startOnPlace = () => ({
  type: START_ON_PLACE
});

export const startOnConfirmation = () => ({
  type: START_ON_CONFIRMATION
});

export const showConfirmation = () => ({
  type: SHOW_CONFIRMATION
});

export const showBelowMarketWarning = bestPrice => ({
  type: SHOW_BELOW_MARKET_WARNING,
  payload: { bestPrice }
});

export const showLoading = () => ({
  type: SHOW_LOADING
});

export const showSuccess = confirmedOrder => ({
  type: SHOW_SUCCESS,
  payload: confirmedOrder
});

export const showAccepted = () => ({
  type: SHOW_ACCEPTED
});

export const showError = errorMessage => ({
  type: SHOW_ERROR,
  payload: { errorMessage }
});

export const closeBuySellModal = () => closeModal(MODAL_NAME);

export const openBuySellModal = modalProps => async (dispatch, getState) => {
  const {
    onlyLimitAvailable = false,
    shouldStartOnConfirm = false,
    productId,
    fee: orderFee,
    maxUsdTotal,
    ...initialFormValues
  } = modalProps;
  const {
    quantity: initQuantity,
    limitPrice: initLPrice,
    stopPrice: initSPrice,
    timeInForce = TIME_IN_FORCE_GTC,
  } = initialFormValues;
  const state = getState();

  const baseCurrency = baseCurrencySelector(state);
  const instrument = (productId &&
    state.apexCore.instrument.instruments.find(
      i => i.Product1 === productId && i.Product2Symbol === baseCurrency
    )) || selectedInstrumentSelector(state);

  const orderType = chooseOrderType(
    initialFormValues.orderType,
    onlyLimitAvailable,
    shouldStartOnConfirm
  );

  const mappedProps = {
    initialOpenAction: shouldStartOnConfirm ? startOnConfirmation() : startOnPlace(),
    orderFee,
    maxUsdTotal,
    form: {
      ...initialFormValues,
      orderType,
      timeInForce,
      instrumentId: instrument.InstrumentId,
      quantity: sanitizeNumber(initQuantity) || 0,
      limitPrice: sanitizeNumber(initLPrice),
      stopPrice: sanitizeNumber(initSPrice)
    }
  };
  dispatch(openModal(MODAL_TYPES.BUY_SELL_MODAL, mappedProps));
};

const defaultOrderType = orderTypes.market.displayName;
const orderTypesProcessedOnPlaceStep = [
  defaultOrderType,
  orderTypes.limit.displayName,
];
const chooseOrderType = (initialOrderType, onlyLimitAvailable, shouldStartOnConfirm) => {
  if (onlyLimitAvailable) {
    return orderTypes.limit.displayName;
  } else if (!initialOrderType) {
    return defaultOrderType;
  } else if (shouldStartOnConfirm) {
    // pro-exchange path, handle anything
    return initialOrderType;
  } else {
    // marketplace path, handle only market and limit orders
    return orderTypesProcessedOnPlaceStep.find(t => t === initialOrderType) ||
      defaultOrderType;
  }
};

const sanitizeNumber = (number) => {
  return number !== undefined ?
    (isNaN(number) ? undefined : number) :
    undefined;
};

export const calcFee = () => async (
  dispatch,
  getState
) => {
  const { instrumentId, ...restForm } = getModalForm(getState());
  const instrument = instrumentSelector(getState(), instrumentId);
  const fee = await dispatch(
    calcFormFee(roundFormFields(restForm, instrument), instrumentId)
  );
  dispatch(updateFee(fee));
};

export const calcMaxUsdTotal = () => async (dispatch, getState) => {
  const form = getModalForm(getState());
  const orders = getOrdersBySide(
    form.side,
    getState().apexCore.level2 || { buy: [], sell: [] }
  );
  const instrument = instrumentSelector(getState(), form.instrumentId);
  const maxUsdTotal = await dispatch(
    calcMaxUsdFormTotal(orders, roundFormFields(form, instrument))
  );
  dispatch(updateMaxUsdTotal(maxUsdTotal));
};

export const placeOrder = (skipChecks) => async (dispatch, getState) => {
  const form = getModalForm(getState());
  const instrument = instrumentSelector(getState(), form.instrumentId);

  try {
    const placeOrderFunction = skipChecks
      ? finalPlaceOrder
      : placeOrderWithChecks;
    const placeOrderResult = await dispatch(
      placeOrderFunction(
        orderFormTypes.default, roundFormFields(form, instrument)
      )
    );
    if (placeOrderResult.status === 'Accepted') {
      if (form.orderType === orderTypes.market.displayName) {
        await processMarketOrder(placeOrderResult, dispatch, getState);
      } else {
        await processNonMarketOrder(placeOrderResult, dispatch);
      }
    } else if (placeOrderResult.status === BELOW_MARKET_WARNING) {
      dispatch(showBelowMarketWarning(placeOrderResult.bestPrice));
    } else {
      throw new InnerBuySellError(getErrorMessage(placeOrderResult));
    }
  } catch (e) {
    handleFailure(dispatch, e);
  }
};

const processNonMarketOrder = async (placeOrderResult, dispatch) => {
  const acceptPromise = waitForAccept(placeOrderResult.OrderId);
  await promiseWithThrottledLoading(
    acceptPromise,
    config.BuySellModal.waitBeforeShowLoading,
    config.BuySellModal.minLoadingTime,
    () => dispatch(showLoading())
  );
  dispatch(showAccepted());
};

const processMarketOrder = async (placeOrderResult, dispatch, getState) => {
  const successPromise = waitForSuccess(placeOrderResult.OrderId);
  const successfulOrder = await promiseWithThrottledLoading(
    successPromise,
    config.BuySellModal.waitBeforeShowLoading,
    config.BuySellModal.minLoadingTime,
    () => dispatch(showLoading())
  );
  handleSuccessfulOrder(successfulOrder, dispatch, getState);
};

const waitForSuccess = orderId =>
  new Promise((resolve, reject) => {
    const orderFees = [];
    let unsub;
    const orderTradeListener = orderTradeEvent => {
      if (orderTradeEvent.OrderId === orderId) {
        orderFees.push({
          fee: BigNumber(orderTradeEvent.Fee),
          feeProductId: orderTradeEvent.FeeProductId
        });
      }
    };
    const orderStateListener = orderStateEvent => {
      if (orderStateEvent.OrderId === orderId) {
        switch (orderStateEvent.OrderState) {
          case 'Rejected':
          case 'Canceled': {
            const snackbar = getOrderEventSnackbar(orderStateEvent);
            unsub && unsub();
            reject(new InnerBuySellError(snackbar.text));
            break;
          }
          case 'FullyExecuted': {
            orderStateEvent['orderFees'] = orderFees;
            unsub && unsub();
            resolve(orderStateEvent);
            break;
          }
          default:
            /* no-op */
            break;
        }
      }
    };
    unsub = subscribeToTradeAndStateReplay(
      orderTradeListener,
      orderStateListener
    );
    setTimeout(() => {
      unsub();
      reject(
        new InnerBuySellError(
          'Order confirmation timeout, check your internet connection'
        )
      );
    }, config.BuySellModal.confirmationTimeout);
  });

const subscribeToTradeAndStateReplay = (tradeCallback, stateCallback) => {
  // may be important: we subscribe to trade replay first, so we can be sure
  // that we have seen all buffered trade events before subscribing to state events
  const unsubTrade = subscribeToTradeEventsReplay(tradeCallback);
  const unsubState = subscribeToStateEventsReplay(stateCallback);
  return () => {
    unsubTrade();
    unsubState();
  };
};

const handleSuccessfulOrder = (confirmedOrder, dispatch, getState) => {
  const state = getState();
  const {
    product: { products }
  } = state;
  const form = getModalForm(state);
  const orderFees = getOrderFeesForConfirmedOrder(confirmedOrder, products);
  const exp = orderFees.filter(f => f.product.ProductId === 1) // hardcoded US dollars
    .reduce((prev, curr) => {
      return prev + getExpFromPrice(curr.fee, form.side);
    }, 0);
  const mappedOrder = {
    avgPrice: confirmedOrder.AvgPrice,
    originalQuantity: confirmedOrder.OrigQuantity,
    quantityExecuted: confirmedOrder.QuantityExecuted,
    grossValueExecuted: confirmedOrder.GrossValueExecuted,
    orderFees,
    numberOfTrades: confirmedOrder.orderFees.length,
    exp
  };
  dispatch(showSuccess(mappedOrder));
};

const waitForAccept = orderId =>
  new Promise((resolve, reject) => {
    let unsub;
    const orderStateListener = orderStateEvent => {
      if (orderStateEvent.OrderId === orderId) {
        switch (orderStateEvent.OrderState) {
          case 'Rejected':
          case 'Canceled': {
            const snackbar = getOrderEventSnackbar(orderStateEvent);
            unsub && unsub();
            reject(new InnerBuySellError(snackbar.text));
            break;
          }
          case 'Working': {
            unsub && unsub();
            resolve(orderStateEvent);
            break;
          }
          default:
            /* no-op */
            break;
        }
      }
    };
    unsub = subscribeToStateEventsReplay(orderStateListener);
    setTimeout(() => {
      unsub();
      reject(
        new InnerBuySellError(
          'Order confirmation timeout, check your internet connection'
        )
      );
    }, config.BuySellModal.confirmationTimeout);
  });
const getModalForm = state => get(state, `modal[${MODAL_NAME}].modalProps.form`);

const handleFailure = (dispatch, error) => {
  let message;
  if (error.name === InnerBuySellError.name) {
    // we use only inner error messages to avoid modals with something like
    // "couldn't get x from undefined", it's not user-friendly
    ({ message } = error);
  } else {
    // log only unknown errors, otherwise in future we might clutter error reporting
    // (like sentry.io or something)
    console.error(error);
  }
  dispatch(
    showError(message || 'We were unable to successfully complete your order')
  );
};

const getErrorMessage = errorObject => {
  return errorObject.errormsg && errorObject.detail
    ? `${errorObject.errormsg}: ${errorObject.detail}`
    : errorObject.errormsg || errorObject.detail;
};

// babel does not fully polyfill built-in extensions without a plugin,
// so we can't just extend Error and expect it to work completely correct
// (see: https://stackoverflow.com/a/43595019/5102726)
function InnerBuySellError(message) {
  this.name = InnerBuySellError.name;
  this.message = message;
}
