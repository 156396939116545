import { SET_CURRENT_ASSET_ID } from '../actions/assetActions';
import { SET_PRODUCT_ATTRIBUTES_LOADED } from '../actions/productActions';

const initialState = {
  currentAssetId: null,
  productAttributesLoaded: false
};

export default function assetsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CURRENT_ASSET_ID:
      return {
        ...state,
        currentAssetId: Number(payload.assetId)
      };
    case SET_PRODUCT_ATTRIBUTES_LOADED:
      return {
        ...state,
        productAttributesLoaded: true
      };
    default:
      return state;
  }
}
