export const degreeStringEnding = degree => {
  const lastNumber = degree.charAt(degree.length - 1);
  switch (lastNumber) {
    case '1':
      if (degree === '11') {
        return 'TH';
      }
      return 'ST';
    case '2':
      if (degree === '12') {
        return 'TH';
      }
      return 'ND';
    case '3':
      if (degree === '13') {
        return 'TH';
      }
      return 'RD';
    default:
      return 'TH';
  }
};

export const createdAtString = createdAtDate => {
  if (createdAtDate) {
    const date = new Date(createdAtDate);

    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const createdAt = `${month.charAt(0).toUpperCase()}${month.slice(
      1
    )} ${year}`;

    return createdAt;
  }
};
