import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../common/Modal/Modal';
import Disable2FAForm from './Disable2FAForm';

const Disable2FAModal = (props, context) => {
  const { handleSubmit, submitting, isOpen, close, changeFieldValue } = props;

  return (
    <Modal
      isOpen={isOpen}
      title={context.t('Disable Two-Factor Authentication')}
      onCancel={() => {
        close();
        changeFieldValue('enableTwoFactorAuth', 'use2FA', true);
      }}
      close={close}>
      <Disable2FAForm handleSubmit={handleSubmit} submitting={submitting} />
    </Modal>
  );
};

Disable2FAModal.defaultProps = {
  handleSubmit: () => {},
  submitting: false
};

Disable2FAModal.propTypes = {
  close: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isOpen: PropTypes.bool,
  changeFieldValue: PropTypes.func
};

Disable2FAModal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default Disable2FAModal;
