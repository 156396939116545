import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import path from '../helpers/path';
import { redirectExceptions } from 'apex-web/lib/helpers/redirectIfAuthorizedHelper';
import { COOKIE_KEYS, deleteCookie, getCookie } from '../helpers/cookieHelper';

function redirectIfAuthorized(Component, defaultPath) {
  const wrapped = props => {
    const { shouldRedirect, redirectLocation: redirectFromLoginRedux, isAuthenticated } = props;

    if (isAuthenticated) {
      if (redirectExceptions()) {
        return <Component {...props} />;
      }
      const redirectFromLoginCookie = getRedirectFromLoginCookie();
      const nextLocation = shouldRedirect ? redirectFromLoginRedux : redirectFromLoginCookie;
      if (nextLocation) {
        deleteCookie(COOKIE_KEYS.REDIRECT_FROM_LOGIN);
        return (
          <Redirect
            to={{
              pathname: nextLocation.pathname,
              search: nextLocation.search
            }}
          />
        );
      } else {
        return <Redirect to={path(`${defaultPath}?default=redirect`)} />;
      }
    }

    return <Component {...props} />;
  };

  wrapped.displayName = `redirectIfAuthorized(${Component.displayName ||
    Component.name ||
    'Component'})`;

  const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    shouldRedirect: state.auth.shouldRedirect,
    redirectLocation: state.auth.location
  });

  return connect(
    mapStateToProps,
    null
  )(wrapped);
}

const getRedirectFromLoginCookie = () => {
  try {
    return JSON.parse(getCookie(COOKIE_KEYS.REDIRECT_FROM_LOGIN));
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export default redirectIfAuthorized;
