import React from 'react';
import PropTypes from 'prop-types';
import copyToClipboard from 'apex-web/lib/helpers/clipboardHelper';
import { required } from 'apex-web/lib/helpers/formValidations';
import APAutoTabInput from 'apex-web/lib/components/common/APAutoTabInput/APAutoTabInput';

import { Modal } from '../common/Modal/Modal';
import { APLabelWithText } from '../common/APLabelWithText/APLabelWithText';
import APIcon from '../common/APIcon';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

import './GetGoogle2FARecoveryKeyModal.css';

const baseClasses = getBEMClasses('recovery-2fa-modal');

const GetGoogle2FARecoveryKeyModal = (props, context) => {
  const {
    handleSubmit,
    isOpen,
    close,
    onCancel,
    recoveryForm,
    showSnack
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      title={context.t('Get Google 2FA Recovery Code')}
      close={close}
      onCancel={() => {
        onCancel();
        close();
      }}>
      <form onSubmit={handleSubmit} className={baseClasses()}>
        <div className={baseClasses('text-item')}>{context.t('RECOVERY')}</div>
        <APAutoTabInput
          label={context.t('Type Authentication Code Here')}
          name="code"
          type="input"
          numberOfInputs={6}
          validate={[required]}
        />
        {recoveryForm.values.recoveryKey && (
          <div
            className={baseClasses('recovery-code-container')}
            onClick={() => {
              showSnack({
                id: 'RECEIVE_RECOVERY_CODE_COPY',
                text: context.t(
                  'The Recovery Code has been copied to the clipboard.'
                )
              });
              copyToClipboard(recoveryForm.values.recoveryKey);
            }}>
            <APLabelWithText
              className={baseClasses('copy-recovery-code')}
              name="recoveryKey"
              label="Copy Recovery Code to the clipboard"
            />
            {recoveryForm.values.recoveryKey}
            <APIcon name="copy" customClass={baseClasses('copy-icon')} />
          </div>
        )}
      </form>
    </Modal>
  );
};

GetGoogle2FARecoveryKeyModal.defaultProps = {
  handleSubmit: () => {},
  submitting: false
};

GetGoogle2FARecoveryKeyModal.propTypes = {
  close: PropTypes.func.isRequired,
  showSnack: PropTypes.func.isRequired,
  recoveryForm: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool
};

GetGoogle2FARecoveryKeyModal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default GetGoogle2FARecoveryKeyModal;
