import React from 'react';
import PropTypes from 'prop-types';
import APAutoTabInput from 'apex-web/lib/components/common/APAutoTabInput/APAutoTabInput';
import { required } from 'apex-web/lib/helpers/formValidations';

import { getBEMClasses } from '../../helpers/cssClassesHelper';

import './Disable2FAModal.css';

const baseClasses = getBEMClasses('disable-2fa-modal');

const Disable2FAForm = (props, context) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit} className={baseClasses()}>
      <div className={baseClasses('text-item')}>
        {`${context.t(
          'Do you want to turn off Two-Factor Authentication for this account'
        )}?`}
      </div>
      <APAutoTabInput
        label={context.t('Type Authentication Code Here')}
        name="code"
        type="input"
        numberOfInputs={6}
        validate={[required]}
      />
    </form>
  );
};

Disable2FAForm.defaultProps = {
  handleSubmit: () => {},
  submitting: false
};

Disable2FAForm.propTypes = {
  handleSubmit: PropTypes.func
};

Disable2FAForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default Disable2FAForm;
