import React, { useCallback, useState } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Textfit } from "react-textfit";
import { modalDecorator } from "../common/Modal/modalDecorator";
import { MODAL_TYPES } from "../../redux/actions/modalActions";
import { Modal } from "../common/Modal/Modal";
import { getBEMClasses } from "../../helpers/cssClassesHelper";
import { PublicPortfolio } from "../../pages/PublicPortfolio/PublicPortfolio";
import APIcon from "../common/APIcon";
import { showSnack } from "apex-web/lib/redux/actions/snackbarActions";
import './ShareProfileModal.css';

const classes = getBEMClasses('share-profile-modal');

const ShareProfileModal = (props, context) => {
  const {
    isOpen,
    close,
    shareUrl,
    accountId,
    showCopyError
  } = props;
  if (!shareUrl || !accountId) {
    return null;
  }
  const [copyStatus, setCopyStatus] = useState(null);
  const onCopy = useCallback(() => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(
        () => {
          setCopyStatus('success');
          setTimeout(() => setCopyStatus(null), 2500);
        },
        (err) => {
          console.error(err);
          showCopyError && showCopyError();
        }
      );
  }, [shareUrl, showCopyError]);
  return (
    <Modal
      customClass={classes()}
      isOpen={isOpen}
      close={close}
      titleComponent={
        <div className={classes('title')}>
          {context.t('Share Your Collections')}
        </div>
      }
      footer={{
        buttonText: context.t('Go back'),
        onClick: () => { }
      }}>
      <div className={classes('subtitle')}>
        <b>{context.t('Share Your Collections')}</b>{context.t(' has been created!')}
      </div>
      <div className={classes('preview-container')}>
        <PublicPortfolio forwardedAccountId={accountId} />
        <div className={classes('preview-overlay')}>
          <a className={classes('btn', 'open')}
            href={shareUrl}
            target="_blank">
            {context.t('Preview')}
          </a>
        </div>
      </div>
      <div className={classes('url-container')}>
        <div className={classes('url-field')}>
          <Textfit mode='single' max={12}>
            {shareUrl}
          </Textfit>
        </div>
        <button className={classes('copy-button')}
          title={context.t('Copy')}
          onClick={onCopy}>
          <APIcon name='copyV2'
            customClass={classes('copy-icon')} />
        </button>
        <div className={classes('copy-status', copyStatus)}>
          {context.t('Copied to Clipboard')}
        </div>
      </div>
    </Modal>
  );
};

ShareProfileModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  shareUrl: PropTypes.string,
  accountId: PropTypes.number,
  showCopyError: PropTypes.func
};

ShareProfileModal.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  showCopyError: () =>
    dispatch(
      showSnack({
        id: 'portfolioClippedFailure',
        //context.t('Something went wrong. Please reload the page and try again.')
        text: 'Something went wrong. Please reload the page and try again.',
        type: 'danger'
      })
    )
});

export default modalDecorator({ name: MODAL_TYPES.SHARE_PROFILE_MODAL })(
  connect(
    null,
    mapDispatchToProps
  )(ShareProfileModal)
);
