import React from 'react';

export const ShowPasswordIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5334_95579)">
        <path
          d="M1 8.33333C1 8.33333 3.66667 3 8.33333 3C13 3 15.6667 8.33333 15.6667 8.33333C15.6667 8.33333 13 13.6667 8.33333 13.6667C3.66667 13.6667 1 8.33333 1 8.33333Z"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33398 10.333C9.43855 10.333 10.334 9.43758 10.334 8.33301C10.334 7.22844 9.43855 6.33301 8.33398 6.33301C7.22941 6.33301 6.33398 7.22844 6.33398 8.33301C6.33398 9.43758 7.22941 10.333 8.33398 10.333Z"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5334_95579">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.333008 0.333984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
