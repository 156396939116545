import { connect } from 'react-redux';
import { setActivePage } from 'apex-web/lib/redux/actions/paginationActions';
import { PaginationComponent } from './PaginationComponent';
import config from '../../../config';

const mapDispatchToProps = (dispatch, ownProps) => ({
  setActivePage: activePage =>
    dispatch(
      setActivePage({ componentName: ownProps.componentName, activePage })
    )
});

const mapStateToProps = (state, ownProps) => {
  return {
    activePage: state.paginationMemory[ownProps.componentName],
    maxPages: config.Pagination.maxPages
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginationComponent);
