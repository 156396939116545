import React from 'react';

export const TwitterNavIcon = props => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M14.862 5.16305C14.8717 5.30771 14.8717 5.45237 14.8717 5.59836C14.8717 10.0467 11.5697 15.1771 5.53173 15.1771V15.1744C3.7481 15.1771 2.00152 14.6531 0.5 13.6652C0.759354 13.6972 1.02001 13.7132 1.28131 13.7139C2.75944 13.7152 4.19531 13.2065 5.35818 12.2699C3.9535 12.2426 2.72173 11.3033 2.29143 9.93209C2.78349 10.0294 3.29049 10.0094 3.77345 9.87409C2.24203 9.55678 1.14026 8.17686 1.14026 6.5743C1.14026 6.55963 1.14026 6.54563 1.14026 6.53163C1.59657 6.79228 2.10748 6.93694 2.63008 6.95294C1.18771 5.96433 0.743104 3.99646 1.61412 2.45789C3.28074 4.56109 5.73973 5.83968 8.37942 5.975C8.11487 4.80574 8.47627 3.58048 9.32909 2.75853C10.6512 1.48395 12.7306 1.54928 13.9734 2.90453C14.7086 2.75587 15.4132 2.47922 16.058 2.08724C15.8129 2.86653 15.3001 3.52849 14.615 3.94913C15.2656 3.87047 15.9013 3.69181 16.5 3.41916C16.0593 4.09645 15.5042 4.68641 14.862 5.16305Z" />
    </svg>
  );
};
