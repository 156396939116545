import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import APIcon from '../APIcon';
import './Buttons.css';

const baseClass = 'buttons';

export const BuyButton = (props, context) => {
  const { isDisabled, customClass, subTitle = null, ...restProps } = props;
  const classes = getBEMClasses(customClass, baseClass);
  return (
    <button
      className={classes('button', 'buy')}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      {...restProps}
      data-test="buy-button">
      {context.t('BUY')}
      {subTitle ? subTitle : null}
    </button>
  );
};

BuyButton.contextTypes = {
  t: PropTypes.func.isRequired
};

BuyButton.propTypes = {
  isDisabled: PropTypes.bool,
  customClass: PropTypes.string,
  subTitle: PropTypes.any
};

export const SellButton = (props, context) => {
  const { isDisabled, customClass, subTitle = null, ...restProps } = props;
  const classes = getBEMClasses(customClass, baseClass);
  return (
    <button
      className={classes('button', 'sell')}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      {...restProps}
      data-test="sell-button">
      {context.t('SELL')}
      {subTitle ? subTitle : null}
    </button>
  );
};

SellButton.contextTypes = {
  t: PropTypes.func.isRequired
};

SellButton.propTypes = {
  isDisabled: PropTypes.bool,
  customClass: PropTypes.string,
  subTitle: PropTypes.any
};

export const ViewDetailsButton = (props, context) => {
  const { isDisabled, customClass, ...restProps } = props;
  const classes = getBEMClasses(customClass, baseClass);
  return (
    <button
      className={classes('button', 'view-details')}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      {...restProps}
      data-test="view-details-button">
      {context.t('VIEW DETAILS')}
    </button>
  );
};

ViewDetailsButton.contextTypes = {
  t: PropTypes.func.isRequired
};

ViewDetailsButton.propTypes = {
  isDisabled: PropTypes.bool,
  customClass: PropTypes.string,
};

export const ShareButton = (props, context) => {
  const { customClass, ...restProps } = props;
  const classes = getBEMClasses(customClass, baseClass);
  return (
    <button
      className={classes('button', 'share')}
      {...restProps}
      data-test="share-button">
      <APIcon name={'shareV2'} customClass={classes('share-icon')} />
      {context.t('Share')}
    </button>
  );
};

ShareButton.contextTypes = {
  t: PropTypes.func.isRequired
};

ShareButton.propTypes = {
  customClass: PropTypes.string,
};

export const MarketPlaceButton = (props, context) => {
  const { isDisabled, customClass, ...restProps } = props;
  const classes = getBEMClasses(customClass, baseClass);
  return (
    <button
      className={classes('button', 'go-pro')}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      {...restProps}
      data-test="go-pro-button">
      {context.t('MarketPlace')}
    </button>
  );
};

MarketPlaceButton.contextTypes = {
  t: PropTypes.func.isRequired
};

MarketPlaceButton.propTypes = {
  isDisabled: PropTypes.bool,
  customClass: PropTypes.string,
};

export const ViewWatchListButton = (props, context) => {
  const { isDisabled, customClass, ...restProps } = props;
  const classes = getBEMClasses(customClass, baseClass);
  return (
    <button
      className={classes('button', 'view-watch-list')}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      {...restProps}
      data-test="view-watch-list-button">
      {context.t('View Watchlist')}
    </button>
  );
};

ViewWatchListButton.contextTypes = {
  t: PropTypes.func.isRequired
};

ViewWatchListButton.propTypes = {
  isDisabled: PropTypes.bool,
  customClass: PropTypes.string,
};

export const RemoveFromWatchListButton = (props, context) => {
  const { isDisabled, customClass, ...restProps } = props;
  const classes = getBEMClasses(customClass, baseClass);
  return (
    <button
      className={classes('button', 'remove-from-watch-list')}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      {...restProps}
      data-test="remove-from-watch-list">
      {context.t('Remove from Watchlist')}
    </button>
  );
};

RemoveFromWatchListButton.contextTypes = {
  t: PropTypes.func.isRequired
};

RemoveFromWatchListButton.propTypes = {
  isDisabled: PropTypes.bool,
  customClass: PropTypes.string,
};

export const BuyNowButton = (props, context) => {
  const { href, customClass, ...restProps } = props;
  const classes = getBEMClasses(customClass, baseClass);
  return (
    <a className={classes('button', 'buy-now')} href={href} {...restProps}>
      {context.t('Buy now')}
    </a>
  );
};

BuyNowButton.contextTypes = {
  t: PropTypes.func.isRequired
};

BuyNowButton.propTypes = {
  href: PropTypes.string,
  customClass: PropTypes.string,
};
