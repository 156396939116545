const alphabetWithNumbers = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

/**
 * Generates a random [a-zA-Z0-9] string of fixed length.
 * @param {number} length
 * @returns {string}
 */
export function generateAlphanumericString(length) {
  return Array.from(Array(length), () =>
    alphabetWithNumbers[Math.floor(Math.random() * (alphabetWithNumbers.length))]
  ).join("");
}
