import React from 'react';
import PropTypes from 'prop-types';
import CancelAPButton from 'apex-web/lib/components/common/CancelAPButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './BulkCancelButtonsComponent.css';

const baseClasses = getBEMClasses('bulk-cancel-buttons');

const BulkCancelButtonsComponent = (props, context) => {
  const {
    openOrders,
    buyOpenOrders,
    sellOpenOrders,
    cancelAllOrders,
    cancelBuyOrders,
    cancelSellOrders
  } = props;
  return (
    <div className={baseClasses()}>
      <span className={baseClasses('label')}>{context.t('Cancel:')}</span>
      <CancelAPButton
        customClass={baseClasses()}
        text={context.t('Sells')}
        disabled={sellOpenOrders.length === 0}
        onClick={cancelSellOrders}
      />
      <CancelAPButton
        customClass={baseClasses()}
        text={context.t('Buys')}
        disabled={buyOpenOrders.length === 0}
        onClick={cancelBuyOrders}
      />
      <CancelAPButton
        customClass={baseClasses()}
        text={context.t('All')}
        disabled={openOrders.length === 0}
        onClick={cancelAllOrders}
      />
    </div>
  );
};

BulkCancelButtonsComponent.propTypes = {
  cancelAllOrders: PropTypes.func.isRequired,
  cancelBuyOrders: PropTypes.func.isRequired,
  cancelSellOrders: PropTypes.func.isRequired,
  openOrders: PropTypes.array,
  buyOpenOrders: PropTypes.array,
  sellOpenOrders: PropTypes.array,
};

BulkCancelButtonsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default BulkCancelButtonsComponent;
