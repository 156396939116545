import React, { useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../../helpers/cssClassesHelper';
import './DepositFormContainer.css';
import { WireTransfer } from './DepositFromCard/wireTransfer/wireTransfer';
import { connect } from 'react-redux';
import { PremierpayForm } from './DepositFromCard/premierpay/PremierpayForm';
import { userIbaneraTestingSelector } from '../../../../redux/selectors/userConfigSelector';
import { DotsTransfer } from './DepositExternalForm/dots/DotsACHTransfer';
import DepositFromCard from './DepositFromCard/dots/DepositFromCard';
import { createTimer } from '../../../../helpers/timerFactoryHelper';

// TODO: move to constants folder if there is any use of it
const depositVariants = {
  EXTERNAL: 'external',
  CARD: 'card',
  ACH: 'ach'
};

const DepositFormContainer = (props, context) => {
  const {
    isPremierPayAvailable,
    setSelectedDepositPaymentProvider,
    isTestUser,
    isACHAvailable
  } = props;
  const baseClasses = getBEMClasses('deposit-form');
  const [chosenDeposit, setChosenDeposit] = useState(depositVariants.CARD);
  const [timer, setTimer] = useState();

  React.useEffect(
    () => {
      setSelectedDepositPaymentProvider(chosenDeposit);
    },
    [chosenDeposit]
  );

  React.useEffect(() => {
    setTimer(prevTimer => {
      prevTimer && prevTimer.reset();
      return createTimer(1000);
    });

    return () => {
      setTimer(prevTimer => {
        prevTimer && prevTimer.reset();
        return undefined;
      });
    };
  }, []);

  return (
    <React.Fragment>
      <section className={baseClasses('send-to')}>
        <header className={baseClasses('source')}>
          <div
            className={baseClasses('source-item', {
              selected: chosenDeposit === depositVariants.CARD
            })}
            onClick={() => setChosenDeposit(depositVariants.CARD)}>
            {context.t('Credit/Debit')}
          </div>
          <div
            className={baseClasses('source-item', {
              selected: chosenDeposit === depositVariants.EXTERNAL
            })}
            onClick={() => setChosenDeposit(depositVariants.EXTERNAL)}>
            {context.t('Wire Transfer')}
          </div>
          {isPremierPayAvailable && (
            <div
              className={baseClasses('source-item', {
                selected: chosenDeposit === depositVariants.REQUEST
              })}
              onClick={() => setChosenDeposit(depositVariants.REQUEST)}>
              {context.t('Interac-Transfer')}
            </div>
          )}
          {isTestUser &&
            isACHAvailable && (
              <div
                className={baseClasses('source-item', {
                  selected: chosenDeposit === depositVariants.ACH
                })}
                onClick={() => setChosenDeposit(depositVariants.ACH)}>
                {context.t('ACH Transfer')}
              </div>
            )}
        </header>
      </section>
      {chosenDeposit === depositVariants.REQUEST && (
        <PremierpayForm closeModal={props.toggleModal} />
      )}
      {chosenDeposit === depositVariants.CARD && (
        <DepositFromCard closeModal={props.toggleModal} timer={timer} />
      )}
      {chosenDeposit === depositVariants.EXTERNAL && (
        <WireTransfer closeModal={props.toggleModal} />
      )}
      {chosenDeposit === depositVariants.ACH &&
        isTestUser && <DotsTransfer closeModal={props.toggleModal} />}
    </React.Fragment>
  );
};

DepositFormContainer.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  isPremierPayAvailable: PropTypes.bool.isRequired,
  setSelectedDepositPaymentProvider: PropTypes.func.isRequired,
  isACHAvailable: PropTypes.bool,
  isTestUser: PropTypes.bool
};

DepositFormContainer.contextTypes = {
  t: PropTypes.func.isRequired
};

const equalsIgnoringCase = (a, b) => a.toUpperCase() === b.toUpperCase();

const mapStateToProps = state => {
  const isKycCanadian = Boolean(
    state.user.userConfig.find(
      ({ Key, Value }) =>
        equalsIgnoringCase(Key, 'Country') && equalsIgnoringCase(Value, 'CA')
    )
  );
  const isKycUs = Boolean(
    state.user.userConfig.find(
      ({ Key, Value }) =>
        equalsIgnoringCase(Key, 'Country') && equalsIgnoringCase(Value, 'US')
    )
  );
  const userCountry = get(state, 'userConfig.resolvedUserCountry') || '';
  const isCanadian = isKycCanadian || userCountry.toUpperCase() === 'CA';
  const isUS = isKycUs || userCountry.toUpperCase() === 'US';
  const isTestUser = userIbaneraTestingSelector(state);
  return {
    isPremierPayAvailable: isCanadian,
    isACHAvailable: isUS,
    isTestUser
  };
};

export default connect(
  mapStateToProps,
  null
)(DepositFormContainer);
