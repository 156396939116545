import {
  getUserNotifications,
  deleteNotification,
  viewNotification,
  getUserGlobalNotification
} from '../../api/liquidApi';

export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
export const REQUEST_GLOBAL_NOTIFICATION = 'REQUEST_GLOBAL_NOTIFICATION';
export const RECEIVE_GLOBAL_NOTIFICATION = 'RECEIVE_GLOBAL_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const FORCE_REMOVE_NOTIFICATION = 'FORCE_REMOVE_NOTIFICATION';
export const MARK_NOTIFICATION_AS_SEEN = 'MARK_NOTIFICATION_AS_SEEN';

export const requestNotifications = () => ({
  type: REQUEST_NOTIFICATIONS
});

export const receiveNotifications = notifications => ({
  type: RECEIVE_NOTIFICATIONS,
  payload: notifications
});

export const requestGlobalNotification = () => ({
  type: REQUEST_GLOBAL_NOTIFICATION
});

export const receiveGlobalNotification = notification => ({
  type: RECEIVE_GLOBAL_NOTIFICATION,
  payload: notification
});


export const updateNotification = notification => ({
  type: UPDATE_NOTIFICATION,
  payload: notification
});

export const forceRemoveNotification = notification => ({
  type: FORCE_REMOVE_NOTIFICATION,
  payload: notification
});

export const markNotificationAsSeen = notificationsIds => ({
  type: MARK_NOTIFICATION_AS_SEEN,
  payload: notificationsIds
});

export const markNotificationsAsSeen = (notificationsIds = []) => async dispatch => {
  try {
    await viewNotification(notificationsIds);
    dispatch(markNotificationAsSeen(notificationsIds));
  } catch (e) {
    return;
  }
};

export const removeNotification = (
  notification,
  forceDelete = false
) => async dispatch => {
  try {
    const updatedNotification = Object.assign({}, notification);
    updatedNotification.deletedAt = 'now';
    await deleteNotification(notification.id, forceDelete);
    if (forceDelete) {
      dispatch(forceRemoveNotification(updatedNotification));
    } else {
      dispatch(updateNotification(updatedNotification));
    }
  } catch (e) {
    return;
  }
};

export const getNotifications = () => async dispatch => {
  try {
    dispatch(requestNotifications());
    const notifications = await getUserNotifications();
    dispatch(receiveNotifications(notifications));
  } catch (e) {
    dispatch(receiveNotifications([]));
  }
};

export const getGlobalNotification = () => async dispatch => {
  try {
    dispatch(requestGlobalNotification());
    const notification = await getUserGlobalNotification();
    dispatch(receiveGlobalNotification(notification));
  } catch (e) {
    dispatch(receiveGlobalNotification([]));
  }
};
