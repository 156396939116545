import config from '../config';
import { selectIsMac } from './browserDetectHelper';
import { handleZendeskZoom } from './zendeskHelper';

export const zoomProperty = '--device-zoom';
export const stripeZoomProperty = '--device-stripe-zoom';
export const resetZoomValue = `calc(1 / var(${zoomProperty}))`;

const getZoomByDevice = () => {
  const isMac = selectIsMac();
  if (isMac) {
    return config.DeviceZoom.Mac;
  }
  return 1;
};

const getZoomByDeviceForStripe = () => {
  const isMac = selectIsMac();
  if (isMac) {
    return 1.1;
  }
  return 1;
};

export const handleDeviceZoom = () => {
  const zoom = getZoomByDevice();
  const stripeZoom = getZoomByDeviceForStripe();
  document.body.style.setProperty(zoomProperty, zoom);
  document.body.style.setProperty(stripeZoomProperty, stripeZoom);
  document.body.style.zoom = `var(${zoomProperty})`;
  if (zoom !== 1) {
    handleZendeskZoom(resetZoomValue);
  }
};
