import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { PaymentElement, useDots, useElements } from '@dots.dev/react-dots-js';
import get from 'lodash/get';
import './CheckoutForm.css';
import { getBEMClasses } from '../../../../../../helpers/cssClassesHelper';
import countryList from 'react-select-country-list';
import { Select } from '../../../../../common/Select';

const classes = getBEMClasses('checkout-form');

export const CheckoutForm = (
  {
    openDepositModal,
    closeModal,
    clientSecret,
    requiredAction,
    amount,
    firstName,
    lastName,
    setCountdownAfterDeposit
  },
  context
) => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState('US');
  const [zipCode, setZipCode] = useState('');
  const countryOptions = useMemo(() => countryList().getData(), []);
  const userFullName = `${firstName} ${lastName}`;

  const elements = useElements();
  const dots = useDots();

  useEffect(
    () => {
      if (clientSecret && requiredAction && dots) {
        dots.confirmCardPayment(clientSecret).then(function(data) {
          handleResult(data, closeModal, openDepositModal, setMessage);
        });
      }
    },
    [clientSecret, requiredAction, dots]
  );

  const handleSubmit = async e => {
    e.preventDefault();

    if (!dots || !elements) {
      // dots.js has not yet loaded.
      return;
    }

    setIsLoading(true);
    try {
      const payload = await dots.confirmCardPayment(clientSecret, {
        payment_method: {
          element: elements.getElement('payment'),
          billing_details: {
            name: userFullName,
            address: {
              country,
              zip: zipCode
            }
          }
        }
      });

      setIsLoading(false);
      handleResult(
        payload,
        closeModal,
        openDepositModal,
        setMessage,
        amount,
        setCountdownAfterDeposit
      );
    } catch (e) {
      if (setCountdownAfterDeposit) {
        setCountdownAfterDeposit();
      }
      setIsLoading(false);
      console.error(e);
      handleError(e, setMessage);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} className={classes('form')}>
      <div className={classes('inputWrapper')}>
        <label htmlFor="routingId">First Name: </label>
        <input
          type="text"
          value={firstName}
          disabled
          className={classes('deposit-input')}
        />
      </div>
      <div className={classes('inputWrapper')}>
        <label htmlFor="routingId">Last Name: </label>
        <input
          type="text"
          value={lastName}
          disabled
          className={classes('deposit-input')}
        />
      </div>
      <PaymentElement />
      <div className={classes('country-wrapper')}>
        <label htmlFor="country">Country:</label>
        <Select
          options={countryOptions}
          currentOption={country}
          onSelect={country => setCountry(country)}
          customClass={classes('select')}
        />
      </div>
      <div className={classes('inputWrapper')}>
        <label htmlFor="routingId">Zip Code: </label>
        <input
          type="text"
          value={zipCode}
          onChange={e => {
            setZipCode(e.target.value);
          }}
          className={classes('deposit-input')}
        />
      </div>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
      <button
        disabled={isLoading || !dots || !elements || requiredAction}
        id="submit"
        className={classes('button')}>
        <span id="button-text">
          {isLoading ? (
            <div className={classes('spinner')} id="spinner" />
          ) : (
            context.t('Pay Now')
          )}
        </span>
      </button>
    </form>
  );
};

CheckoutForm.propTypes = {
  openDepositModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  clientSecret: PropTypes.string,
  requiredAction: PropTypes.any,
  amount: PropTypes.any,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  setCountdownAfterDeposit: PropTypes.func
};

const handleResult = (
  paymentInfo,
  closeModal,
  openDepositModal,
  setMessage,
  amount,
  setCountdownAfterDeposit
) => {
  if (setCountdownAfterDeposit) {
    setCountdownAfterDeposit();
  }

  if (paymentInfo.id) {
    handleIntent(paymentInfo, closeModal, openDepositModal, setMessage, amount);
  } else {
    handleError(null, setMessage);
  }
};

const handleIntent = (
  paymentInfo,
  closeModal,
  openDepositModal,
  setMessage,
  amount
) => {
  switch (paymentInfo.status) {
    case 'succeeded':
      openDepositModal({
        isSuccessful: true,
        closeDepositModal: closeModal,
        amount: amount / 100
      });
      break;
    case 'processing':
      openDepositModal({
        isSuccessful: true,
        closeDepositModal: closeModal,
        amount: amount / 100
      });
      break;
    case 'requires_payment_method':
      setMessage('Your payment was not successful, please try again.');
      break;
    case 'canceled':
      setMessage('Your payment has been cancelled.');
      break;
    default:
      console.error(
        `Got Stripe payment intent with unknown status. 
        id: ${paymentInfo.id}; status: ${paymentInfo.status}`
      );
      setMessage('Something went wrong, try again.');
      break;
  }
};

const handleError = (error, setMessage) => {
  if (
    error &&
    (error.type === 'card_error' || error.type === 'validation_error')
  ) {
    setMessage(error.message);
  } else if (error.message === "Field of type 'cardNumber'' is not valid") {
    console.error(
      `Got unknown Dots error. 
      error === undefined: ${error === undefined}; type: ${get(error, 'type')}`
    );
    setMessage('Card Number is not valid');
  } else {
    console.error(
      `Got unknown Dots error. 
      error === undefined: ${error === undefined}; type: ${get(error, 'type')}`
    );
    setMessage('An unexpected error occurred.');
  }
};

CheckoutForm.contextTypes = {
  t: PropTypes.func
};
