import React from 'react';
import PropTypes from 'prop-types';
import SidePaneDetailsComponent from '../../../common/SidePaneDetailsComponent/SidePaneDetailsComponent';
import { formatNumberToLocale } from '../../../../helpers/numberFormatter';

class SendReceiveDetailsComponent extends React.Component {
  render() {
    const {
      isSend,
      useExternalAddress,
      balance,
      details: { Amount },
      product: { Product: ProductSymbol, DecimalPlaces },
      TicketAmount,
      fee
    } = this.props;
    const { getItem } = SidePaneDetailsComponent;
    const title = isSend
      ? this.context.t('Send Details')
      : this.context.t('Instructions');
    const amountText = this.context.t('Amount to Send');

    const remainingBalance = formatNumberToLocale(
      balance - (useExternalAddress ? TicketAmount + fee : Amount),
      DecimalPlaces
    );
    const classModifiers = isSend ? 'top' : '';

    let items = [];
    let info = [];
    let infoHeader = '';

    if (isSend) {
      items.push(
        getItem(
          this.context.t('Your current {ProductSymbol} Balance', {
            ProductSymbol
          }),
          balance
            ? `${formatNumberToLocale(balance, DecimalPlaces)} ${ProductSymbol}`
            : '-'
        )
      );
      items.push(
        getItem(
          amountText,
          Amount
            ? `${formatNumberToLocale(
                useExternalAddress ? TicketAmount : Amount,
                DecimalPlaces
              )} ${ProductSymbol}`
            : '-'
        )
      );
      items.push(
        getItem(
          this.context.t('Fee'),
          useExternalAddress
            ? `${formatNumberToLocale(fee, DecimalPlaces)} ${ProductSymbol}`
            : '-'
        )
      );
      items.push(
        getItem(
          this.context.t(`Remaining Balance`),
          Amount && balance ? `${remainingBalance} ${ProductSymbol}` : '-'
        )
      );
    }

    if (isSend && !useExternalAddress) {
      infoHeader = this.context.t('Note');
      info.push(
        this.context.t(
          'If the sender is not a registered user of Retail, they will receive an email invitation to create an account and claim their funds.'
        )
      );
    }

    if (!isSend && !useExternalAddress) {
      info.push(
        this.context.t(
          'Scan the QR code on your mobile device using your preferred mobile crypto wallet application, or copy and paste the wallet address as required.'
        )
      );
      info.push(
        this.context.t(
          'Select the amount of cryptocurrency you want to fund, and authorize your cryptocurrency payment as required.'
        )
      );
      info.push(
        this.context.t(
          'Your funds, net of applicable gas fees and subject to spot market conversion rates, should be credited to your account in USD as soon as they are received by us. Some blockchain networks can be congested at times; please be patient.'
        )
      );
      info.push(
        this.context.t(
          'Once you send us cryptocurrency, it is automatically converted to USD and cannot be returned. You must use the USD equivalent on our platform.'
        )
      );
    }
    if (!isSend && useExternalAddress) {
      info.push(
        this.context.t(
          'Depositing cryptocurrency into your wallet is safe and easy. The address below can always be used to deposit cryptocurrency into your account.'
        )
      );
      info.push(
        this.context.t(
          'Your account will automatically update after the cryptocurrency network confirms your transaction. The confirmation may take up to 1 hour'
        )
      );
    }

    return (
      <SidePaneDetailsComponent
        title={title}
        items={items}
        info={info}
        infoHeader={infoHeader}
        classModifiers={classModifiers}
        isCrypto={!isSend && !useExternalAddress}
      />
    );
  }
}

SendReceiveDetailsComponent.propTypes = {
  isSend: PropTypes.bool,
  useExternalAddress: PropTypes.bool,
  details: PropTypes.object,
  fee: PropTypes.number,
  total: PropTypes.number,
  balance: PropTypes.number,
  product: PropTypes.any,
  TicketAmount: PropTypes.number
};

SendReceiveDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export { SendReceiveDetailsComponent };
