import React, { useEffect } from "react";
import get from 'lodash/get';
import { Modal } from "../common/Modal/Modal";
import PropTypes from 'prop-types';
import { getBEMClasses } from "../../helpers/cssClassesHelper";
import OrderEntryFormContainer from "./OrderEntryFormContainer";
import { buyValue } from "apex-web/lib/constants/sendOrder/orderEntryFormConstants";
import { connect } from "react-redux";
import { reset } from "redux-form";
import SVG from 'react-inlinesvg';
import Close from '../../images/icons/icon-close-slim.svg';
import './OrderEntryForm.css';

const classes = getBEMClasses('order-entry-form-modal');

const OrderEntryFormModal = (props, context) => {
  const {
    isOpen = false,
    close,
    side,
    clearForm
  } = props;
  useEffect(() => {
    if (!isOpen) {
      clearForm();
    }
  }, [isOpen]);
  return (
    <Modal
      customClass={classes()}
      modifier={side === buyValue ? 'additive' : 'subtractive'}
      isOpen={isOpen}
      close={close}
      showCloseIcon={false}
      titleComponent={
        <div className={classes('header')}>
          {context.t(
            side === buyValue ?
              'Buy Order' :
              'Sell Order'
          )}
          <button
            className={classes('close-icon')}
            onClick={close}>
            <SVG src={Close} alt='Close' />
          </button>
        </div>
      }>
      <OrderEntryFormContainer
        initialSide={side}
        afterSubmit={close}
      />
    </Modal>
  );
};

OrderEntryFormModal.contextTypes = {
  t: PropTypes.func
};

OrderEntryFormModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  side: PropTypes.string.isRequired,
  clearForm: PropTypes.func.isRequired,
};

export default connect(
  state => ({ side: get(state, 'form.orderEntry.values.side', buyValue) }),
  { clearForm: () => reset('orderEntry') }
)(OrderEntryFormModal);
