import { connect } from 'react-redux';
import { get } from 'lodash';
import { getWithdrawFee } from 'apex-web/lib/redux/actions/withdrawActions';
import { positionSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import config from 'apex-web/lib/config';

import { availableNonDigitalProductsSelector } from '../../redux/selectors/productsSelector';
import { ModalWithCurrencySelectComponent } from './ModalWithCurrencySelectComponent';

const mapStateToProps = state => {
  const availableCurrencies = availableNonDigitalProductsSelector(state);
  const cryptoCuttencies = [];
  const assetsDetails = state.productManifest.manifest;
  const positions = positionSelector(state);
  const { withdrawFee: fee } = state.withdraw;
  const TicketAmount = get(state, ['withdraw', 'TicketAmount'], null);
  const withdrawDetailsInfo = config.Withdraw.WithdrawDetails;

  const USDProduct = availableCurrencies.find(i => i.Product === 'USD');
  availableCurrencies.forEach(item => {
    if (item.ProductType === 'CryptoCurrency') {
      cryptoCuttencies.push(item);
    }
  });

  const sortedAvailableCurrencies = [USDProduct, ...cryptoCuttencies];

  return {
    availableCurrencies: sortedAvailableCurrencies,
    assetsDetails,
    positions,
    fee,
    TicketAmount,
    withdrawDetailsInfo,
    USDProduct,
    cryptoCuttencies
  };
};

const mapDispatchToProps = dispatch => ({
  getWithdrawFee: (productId, amount) =>
    dispatch(getWithdrawFee(productId, amount))
});

export const ModalWithCurrencySelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalWithCurrencySelectComponent);
