import React from 'react';
import PropTypes from 'prop-types';
import APIcon from '../APIcon/APIcon';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './SearchCardInput.css';

const baseClasses = getBEMClasses('search-card-input');

const SearchCardInput = props => {
  const { setSearchString } = props;
  return (
    <div className={baseClasses('search-wrapper')}>
      <input
        className={baseClasses('search-input')}
        onChange={e => setSearchString(e.target.value)}
        placeholder={'Search cards here...'}
      />
      <div className={baseClasses('search-icon-container')}>
        <APIcon name="search" customClass={baseClasses('search-icon')} />
      </div>
    </div>
  );
};

SearchCardInput.propTypes = {
  setSearchString: PropTypes.func
};

export { SearchCardInput };
