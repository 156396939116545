import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { ConsignIcon } from '../../../images/react-icons/ConsignIcon';
import { openLinkInNewTab } from '../../../helpers/linkHelper';

const pageHeaderClasses = getBEMClasses('page-header');

export const ConsignButton = () => {
  return (
    <button
      className={pageHeaderClasses('consign-button')}
      onClick={() =>
        openLinkInNewTab('https://www.liquidmarketplace.io/consign-with-us')
      }>
      <ConsignIcon className={pageHeaderClasses('consign-icon')} />
      Consign With Us
    </button>
  );
};

ConsignButton.contextTypes = {
  t: PropTypes.func.isRequired
};
