import { getRecurringPayments } from "../../api/liquidApi";

export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const UPDATE_SUBSCRIPTIONS_REQUEST_STATUS = 'UPDATE_SUBSCRIPTIONS_REQUEST_STATUS';

export const updateSubscriptionsRequestStatus = (isFetching) => ({
  type: UPDATE_SUBSCRIPTIONS_REQUEST_STATUS,
  payload: { isFetching }
});

export const setSubscriptions = (payload) => {
  return {
    type: SET_SUBSCRIPTIONS,
    payload
  };
};

export const getSubscriptions = () => async (dispatch) => {
  try {
    dispatch(updateSubscriptionsRequestStatus(true));
    const response = await getRecurringPayments();
    dispatch(setSubscriptions(response));
  }
  finally {
    dispatch(updateSubscriptionsRequestStatus(false));
  }
};
