export const getLevel1VerificationProps = verificationLevel => {
  return {
    currentLevel: verificationLevel === 0
  };
};

export const getLevel2VerificationProps = (
  verificationLevel,
  levelIncreaseStatus
) => {
  return {
    underReview:
      verificationLevel === 0 && levelIncreaseStatus === 'underReview',
    verified: verificationLevel > 0,
    canIncrease:
      verificationLevel === 0 && levelIncreaseStatus !== 'underReview',
    currentLevel: verificationLevel === 1
  };
};
