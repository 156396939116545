import Avatar from './assets/avatar.png';
import Video from './assets/video.png';

export const steveAokiPageProps = {
  main: {
    name: 'Steve Aoki',
    subName: 'DJ, Record Producer & Entrepreneur',
    specialty: "NFTs",
    topCollectible: "Justin Herbert & Joe Burrow Rookies",
    significantProjects: [
      'Founder of A0K1VERSE',
      'Liquid MarketPlace Shareholder & Advisor'
    ],
    collectiblesIds: [26, 27],
    description: `Steve Aoki is one of the most famous electronic producers and DJs in the world.
    Over his career, several of his albums hit the Billboard charts, one gained a Grammy Award nomination, and multiple of them won and were nominated for industry awards. Steve's fans are attracted to his high-impact, inventive concerts, which showcase his remarkable ability to perform and capture the attention of a crowd. Steve is also an active contributor to the film and gaming industries, as well as well-respected for his philanthropy and charity services. He is the founder of the Aoki Fund, a foundation committed to raising awareness around the science of brain health. Thanks to all of these ventures, crazy shows, and performances, he has received enormous recognition and built an army of super-loyal fans.  His followers admire his humanitarian nature and passion for music, dance culture, and performing in general.`,
    avatar: Avatar,
    video: Video,
    collectiblesTitle: "Steve Aoki’s Collectibles",
    videoLink: 'https://www.instagram.com/p/CdoaRbBDBhj/',
  },
  description: {
    title: "Steve’s Collectibles Journey",
    descriptions: [
      'Steve Aoki is an active collector and was very into collecting in his youth. He revived his youth passion and got back into both card and NFT collecting during COVID-19. Steve’s collection is full of big names including Michael Jordan, Jackie Robinson, and Pokémon.',
      'As a collector, Steve Aoki makes sure to invest in collectibles and assets that he is passionate about and have sentimental value to him. By listing two of his cards on Liquid MarketPlace, he is showing how he wants to share this enthusiasm with collectors just like him across the globe.',
      'Steve supports Liquid MarketPlace as a Shareholder and an Advisor. This means that he supports Liquid MarketPlace not just through investments, but also by offering collectibles from his personal collection.',
      'Two cards that Steve has shared from his collection are a Justin Herbert Rookie and a Joe Burrow Rookie. By listing these cards, he is giving sports fanatics the opportunity to have fractional ownership of rare collectibles that would grow their dream collection.'
    ],
  }
};
