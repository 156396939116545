import { getVotingStatuses } from '../../api/liquidApi';

export const REQUEST_VOTE_CARDS = 'REQUEST_VOTE_CARDS';
export const RECEIVE_VOTE_CARDS = 'RECEIVE_VOTE_CARDS';
export const MOVE_CARD_FROM_COUNTDOWN_TO_LIVE_VOTE =
  'MOVE_CARD_FROM_COUNTDOWN_TO_LIVE_VOTE';
export const SET_VOTE_RESULT = 'SET_VOTE_RESULT';

const requestVoteCards = () => ({
  type: REQUEST_VOTE_CARDS
});

const receiveVoteCards = voteCards => ({
  type: RECEIVE_VOTE_CARDS,
  payload: voteCards
});

export const getVoteCards = () => async dispatch => {
  try {
    dispatch(requestVoteCards());
    const voteCards = await getVotingStatuses();
    if (voteCards) {
      dispatch(receiveVoteCards(voteCards));
    } else {
      dispatch(
        receiveVoteCards({
          liveVoteCards: [],
          countdownVoteCards: [],
          resultCoteCards: []
        })
      );
    }
  } catch (e) {
    dispatch(
      receiveVoteCards({
        liveVoteCards: [],
        countdownVoteCards: [],
        resultCoteCards: []
      })
    );
  }
};

export const moveCardFromCountdownToLiveVote = id => ({
  type: MOVE_CARD_FROM_COUNTDOWN_TO_LIVE_VOTE,
  payload: id
});

export const setVoteResult = results => ({
  type: SET_VOTE_RESULT,
  payload: results
});
