import { updateAffiliateSuccess } from 'apex-web/lib/redux/actions/affiliateActions';
import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';

export const simpleUpdateAffiliateTag = ({ tag }) => async (dispatch, getState) => {
  const AffiliateTag = tag;
  const { UserId, OMSId, AffiliateId } = getState().user.userInfo;

  await dispatch(
    callAPI('AddUserAffiliateTag', {
      OMSId,
      UserId,
      AffiliateId,
      AffiliateTag
    })
  ).then(res => {
    if (res.result) {
      dispatch(updateAffiliateSuccess(AffiliateTag));
    } else {
      const errorMsg = 'Could not create tag or tag already in use';
      dispatch(
        showSnack({
          id: 'createAffiliateError',
          // context.t('Could not create tag or tag already in use')
          text: errorMsg,
          type: 'warning'
        })
      );
      throw new Error(errorMsg);
    }
  });
};

export * from 'apex-web/lib/redux/actions/affiliateActions';
