import React from 'react';
import PropTypes from 'prop-types';

import './OfferingsSection.css';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
const classes = getBEMClasses('offerings-section');

export const OfferingsSection = props => {
  const { children, baseClass = () => '', style } = props;
  return (
    <div
      className={`${classes()} ${baseClass('offerings-section')}`}
      style={{ ...style }}
      data-test='offerings-section-root'>
      {children}
    </div>
  );
};

OfferingsSection.propTypes = {
  baseClass: PropTypes.func,
  children: PropTypes.node.isRequired,
  style: PropTypes.object
};
