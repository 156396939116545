import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { APTypography } from '../../common/APTypography';
import { Modal } from '../../common/Modal/Modal';
import KYCStatusIcon from '../../../images/kyc-status-icon.png';
import '../KYCModal.css';

const baseClasses = getBEMClasses('kyc-modal');

const KYC_STATUSES = {
  SUCCESS: 'success',
  MANUAL_REVIEW: 'manual',
  DENY: 'deny'
};

const KYCStatusModal = (props, context) => {
  const { status, isOpen, close, isDarkTheme, history } = props;
  const isSuccessStatus = status === KYC_STATUSES.SUCCESS;
  const isManualReviewStatus = status === KYC_STATUSES.MANUAL_REVIEW;
  const textColor = isDarkTheme ? 'white1' : 'black1';

  const renderSuccessText = () => {
    return (
      <React.Fragment>
        <APTypography
          fontSize="caption2"
          weight="weight600"
          color={textColor}
          className={baseClasses('status-text')}>
          {context.t('Congratulations! Your verification is approved!')}
        </APTypography>
        <APTypography
          fontSize="caption2"
          weight="weight600"
          color={textColor}
          className={baseClasses('status-text')}>
          {context.t(
            'Now you can do both deposit & withdraw to own anything in here.'
          )}
        </APTypography>
        <APTypography
          fontSize="caption2"
          weight="weight600"
          color={textColor}
          className={baseClasses('status-text')}>
          {context.t('Have fun!')}
        </APTypography>
        <APTypography
          fontSize="caption2"
          weight="weight600"
          color={textColor}
          className={baseClasses('status-text')}>
          {context.t(
            'If you have any questions, you can always find me from a click away!'
          )}
        </APTypography>
      </React.Fragment>
    );
  };

  const renderManualReviewText = () => {
    return (
      <React.Fragment>
        <APTypography
          fontSize="caption2"
          weight="weight600"
          color={textColor}
          className={baseClasses('status-text')}>
          {context.t('Your verification is still pending within 7 days!')}
        </APTypography>
        <APTypography
          fontSize="caption2"
          weight="weight600"
          color={textColor}
          className={baseClasses('status-text')}>
          {context.t('Just sit tight, your wait is worth it!')}
        </APTypography>
        <APTypography
          fontSize="caption2"
          weight="weight600"
          color={textColor}
          className={baseClasses('status-text')}>
          {context.t(
            'You still can deposit to own anything here, unfortunately withdraw won’t be available after your verification is approved.'
          )}
        </APTypography>
        <APTypography
          fontSize="caption2"
          weight="weight600"
          color={textColor}
          className={baseClasses('status-text')}>
          {context.t(
            'If you have any questions, you can always find me from a click away!'
          )}
        </APTypography>
      </React.Fragment>
    );
  };

  const renderDenyText = () => {
    return (
      <React.Fragment>
        <APTypography
          fontSize="caption2"
          weight="weight600"
          color={textColor}
          className={baseClasses('status-text')}>
          {context.t('Sorry to inform you that your verification is denied.')}
        </APTypography>
        <APTypography
          fontSize="caption2"
          weight="weight600"
          color={textColor}
          className={baseClasses('status-text')}>
          {context.t('Please, complete your profile information!')}
        </APTypography>
        <APTypography
          fontSize="caption2"
          weight="weight600"
          color={textColor}
          className={baseClasses('status-text')}>
          {context.t(
            'You still can deposit to own anything here, unfortunately withdraw won’t be available after your verification is approved.'
          )}
        </APTypography>
        <APTypography
          fontSize="caption2"
          weight="weight600"
          color={textColor}
          className={baseClasses('status-text')}>
          {context.t(
            'If you have any questions, you can always find me from a click away!'
          )}
        </APTypography>
      </React.Fragment>
    );
  };

  return (
    <Modal
      customClass={baseClasses('status-wrapper')}
      isOpen={isOpen}
      titleComponent={
        <div
          className={baseClasses('status-header')}
          style={{
            background: isSuccessStatus
              ? 'linear-gradient(to right,var(--purple8), var(--green3))'
              : isManualReviewStatus
                ? 'linear-gradient(to right,var(--blue2), var(--purple1))'
                : 'linear-gradient(to right,var(--red2), var(--purple1))'
          }}>
          {/* <div className={baseClasses('header-image')} /> */}
          <div className={baseClasses('status-header-title')}>
            {context.t(
              isSuccessStatus
                ? 'Verification Approved'
                : isManualReviewStatus
                  ? 'Verification Pending'
                  : 'Verification Denied'
            )}
          </div>
        </div>
      }
      onCancel={() => {
        close();
        history.push('/settings/verification-level');
      }}
      close={close}>
      <img
        src={KYCStatusIcon}
        className={baseClasses('status-icon')}
        alt="status"
      />
      <div className={baseClasses('status-text-wrapper')}>
        {isSuccessStatus
          ? renderSuccessText()
          : isManualReviewStatus
            ? renderManualReviewText()
            : renderDenyText()}
        <div
          className={baseClasses('status-button', status)}
          onClick={() => {
            close();
            history.push('/settings/verification-level');
          }}>
          <APTypography
            fontSize="subbody"
            weight="bold"
            color={isDarkTheme ? 'white1' : 'purple8'}
            className={baseClasses('status-button-text')}>
            {context.t('Got it')}
          </APTypography>
        </div>
      </div>
    </Modal>
  );
};

KYCStatusModal.contextTypes = {
  t: PropTypes.func.isRequired
};

KYCStatusModal.propTypes = {
  status: PropTypes.string,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  isDarkTheme: PropTypes.bool,
  history: PropTypes.any
};

export default KYCStatusModal;
