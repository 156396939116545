import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { RadioTab } from '../common/APRadioTab/APRadioTab';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import {
  sellValue,
  buyValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';

const ControlledBuySellTabsComponent = (props, context) => {
  const { baseClass, isSellDisable, value, resetQuantity } = props;

  const classes = getBEMClasses(baseClass);

  const onChange = useCallback(
    newValue => {
      props.onChange(newValue);
      if (value !== newValue) {
        resetQuantity();
      }
    },
    [props.onChange, value, resetQuantity]
  );

  return (
    <div className={classes('buy-sell-wrapper')}>
      <div className={classes('buy-sell-radio-tab-wrapper')}>
        <RadioTab
          customClass={classes()}
          items={[
            {
              text: context.t('Buy'),
              value: buyValue,
              styleName: 'additive',
              dataTest: 'Buy Side'
            },
            {
              text: context.t('Sell'),
              value: sellValue,
              styleName: 'subtractive',
              dataTest: 'Sell Side',
              disabled: isSellDisable,
              tooltip: isSellDisable
                ? 'You can`t make sell order while primary order available'
                : ''
            }
          ]}
          input={{
            value,
            onChange
          }}
          name="side"
        />
      </div>
    </div>
  );
};

ControlledBuySellTabsComponent.propTypes = {
  baseClass: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isSellDisable: PropTypes.bool,
  value: PropTypes.any,
  resetQuantity: PropTypes.func,
  onChange: PropTypes.func
};

ControlledBuySellTabsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ControlledBuySellTabsComponent;
