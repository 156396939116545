import { connect } from 'react-redux';
import { getTransfers } from 'apex-web/lib/redux/actions/transferActions';
import { createDotsWithdraw } from '../../../../../redux/actions/customTransferActions';
import {
  MODAL_TYPES,
  openModal
} from '../../../../../redux/actions/modalActions';
import get from 'lodash/get';
import { DotsWithdrawalComponent } from './DotsWithdrawal';
import { selectIsDotsVerificationFinished } from '../../../../../redux/selectors/userConfigSelector';

const findProperConfigValue = (userConfig, key) => {
  try {
    return userConfig.find(elem => {
      return elem.Key === key;
    }).Value;
  } catch (e) {
    return '';
  }
};

const mapStateToProps = state => {
  const { user } = state;
  const accountId = user && user.userInfo.AccountId;
  const account = user && user.accounts.find(i => i.AccountId === accountId);

  const userConfig = state.user.userConfig;

  return {
    verificationLevel: account && account.VerificationLevel,
    selectedAccountId: get(state, 'user.selectedAccountId', 0),
    firstName: findProperConfigValue(userConfig, 'FirstName'),
    lastName: findProperConfigValue(userConfig, 'LastName'),
    isDotsVerificationFinished: selectIsDotsVerificationFinished(state),
    is2FAEnabled: state.user.userInfo.Use2FA
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitWithdraw: async (payload, selectedAccountId) => {
    const response = await dispatch(createDotsWithdraw(payload));
    dispatch(
      openModal(MODAL_TYPES.DEPOSIT_WITHDRAW_STATUS_MODAL, {
        isDeposit: false,
        isSuccessful: response,
        amount: payload.amount,
        closeDepositModal: response ? ownProps.closeModal : () => {},
        isDots: true
      })
    );
    if (response) {
      dispatch(getTransfers(selectedAccountId));
    }
  }
});

export const DotsWithdrawal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DotsWithdrawalComponent);
