import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { Modal } from '../common/Modal/Modal';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import path from '../../helpers/path';
import { modalDecorator } from '../common/Modal/modalDecorator';
import { MODAL_TYPES } from '../../redux/actions/modalActions';
import IconReferral from '../../images/icons/icon-referral.svg';
import AffiliateActiveTagContainer from '../AffiliateComponents/AffiliateActiveTagContainer';
import AffiliateCountContainer from '../AffiliateComponents/AffiliateCountContainer';
import { useLocation, withRouter } from 'react-router-dom';
import { UrlFactory } from '../../helpers/urlFactory';
import { showAffiliateProgramSelector } from '../../redux/selectors/userTierSelector';
import { updateAffiliate } from '../../redux/actions/affiliateActions';
import { generateAlphanumericString } from '../../helpers/randomHelper';
import './AffiliateModal.css';

const classes = getBEMClasses('affiliate-modal');

const AffiliateModal = (props, context) => {
  const {
    isOpen,
    close,
    history,
    needAffiliateTagCreated,
    createRandomAffiliateTag
  } = props;
  const location = useLocation();

  useEffect(
    () => {
      if (needAffiliateTagCreated && isOpen) {
        createRandomAffiliateTag();
      }
    },
    [needAffiliateTagCreated, isOpen]
  );

  return (
    <Modal
      customClass={classes()}
      isOpen={isOpen}
      close={close}
      showCloseIcon
      titleComponent={
        <div className={classes('header')}>
          <SVG src={IconReferral} className={classes('header-icon')} />
          {context.t('Refer for Rewards')}
        </div>
      }>
      <React.Fragment>
        <div className={classes('title')}>
          {context.t('Join the Liquid MarketPlace Affiliate Program')}
        </div>
        <div className={classes('body')}>
          <div className={classes('section-header', 'number')}>
            {context.t('Number of Active Affiliates')}
          </div>
          <AffiliateCountContainer />
          <div className={classes('section-header', 'form')}>
            {context.t('Affiliate Link')}
          </div>
          <AffiliateActiveTagContainer
            route={`${window.location.origin}${path('')}/signup?aff=`}
          />
        </div>
        <button
          className={classes('settings-btn')}
          onClick={() => {
            close();
            const affiliateUrl = UrlFactory.getAffiliateSettingsUrl();
            if (!location.pathname.includes(affiliateUrl)) {
              history.push(affiliateUrl);
            }
          }}>
          {context.t('Go to Settings')}
        </button>
      </React.Fragment>
    </Modal>
  );
};

AffiliateModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  needAffiliateTagCreated: PropTypes.bool,
  createRandomAffiliateTag: PropTypes.func
};

AffiliateModal.contextTypes = {
  t: PropTypes.func
};

const mapStateToProps = state => {
  const showAffiliateProgram = showAffiliateProgramSelector(state);
  return {
    // the initial reducer state tag is '' (empty string). when the request says
    // that the user has no affiliate tag, than it becomes undefined
    needAffiliateTagCreated:
      state.affiliate &&
      state.affiliate.affiliateTag === undefined &&
      showAffiliateProgram,
    showAffiliateProgram
  };
};

const mapDispatchToProps = {
  createRandomAffiliateTag: () =>
    updateAffiliate({ tag: generateAlphanumericString(8) })
};

const AffiliateModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AffiliateModal));

export default modalDecorator({ name: MODAL_TYPES.AFFILIATE_MODAL })(
  AffiliateModalContainer
);
