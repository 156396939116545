import { useCallback, useMemo, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { useRefWithCallback } from "./useRefWithCallback";

const calcIsScrollable = (el) => el.scrollWidth > el.clientWidth;

// TODO(May 05, 2022): make it work with vertical scroll as well; use 'watchChildrenCount' as a dependency?
export const useIsScrollable = (watchChildrenCount = false) => {
  const [isScrollable, setIsScrollable] = useState(null);

  const resizeObserver = useMemo(() =>
    new ResizeObserver(entries => {
      const el = entries[0].target;
      setIsScrollable(calcIsScrollable(el));
    }), []);
  const childrenObserver = useMemo(() =>
    new MutationObserver(mutations => {
      const childListMutation = mutations.find(m => m.type === 'childList');
      if (childListMutation) {
        const el = childListMutation.target;
        setIsScrollable(calcIsScrollable(el));
      }
    }), []);

  const onMount = useCallback(node => {
    setIsScrollable(calcIsScrollable(node));
    resizeObserver.observe(node);
    if (watchChildrenCount) {
      childrenObserver.observe(node, { childList: true });
    }
  }, [resizeObserver, watchChildrenCount, childrenObserver]);
  const onUnmount = useCallback(() => {
    resizeObserver.disconnect();
    childrenObserver.disconnect();
  }, [resizeObserver, childrenObserver]);
  const setIsScrollableRef = useRefWithCallback(onMount, onUnmount);

  return [setIsScrollableRef, isScrollable];
};
