import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';

import './CommunityPage.css';
import resize from 'apex-web/lib/hocs/resize';
import Marquee from 'react-fast-marquee';

import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { useIsMobile } from '../../hooks/useIsMobile';
import grandmastersGIF from './images/grandmasters.gif';
// import GrandmasterImg from './images/grandmaster.png';
import {
  ANIMATION_TYPES,
  TierAnimationWrapper
} from '../../components/common/TierAnimationWrapper/TierAnimationWrapper';
import { getTopXPUsers } from '../../api/liquidApi';
import { DiscordIcon } from '../../images/react-icons/DiscordIcon';
import APIcon from '../../components/common/APIcon';
import { USER_TIERS_ARRAY } from '../../constants/userTiers';
import { createdAtString } from '../../helpers/stringFormatterHelper';
import {
  NextSliderArrow,
  PrevSliderArrow
} from '../../components/common/SliderArrows';
import rankImage from './images/rank_bg.png';
import firstRankImage from './images/first_rank_bg.png';
import secondRankImage from './images/second_rank_bg.png';
import thirdRankImage from './images/third_rank_bg.png';
import topSmallBackgroundDark from './images/top_small_bg_dark.png';
import topSmallBackgroundLight from './images/top_small_bg_light.png';
import topBigBackgroundDark from './images/top_big_bg_dark.png';
import topBigBackgroundLight from './images/top_big_bg_light.png';
import topMobileHeaderBackgroundDark from './images/top_mobile_header_bg_dark.png';
import topMobileHeaderBackgroundLight from './images/top_mobile_header_bg_light.png';

const classes = getBEMClasses('community-page');

const MOCK_GRANDMASTERS = [
  {
    exp: 75890.48,
    userId: 5,
    userCreatedAt: '2022-02-05T02:15:27.111Z',
    userName: 'TestUser1',
    publicProfile: 'true',
    accountId: 5
  },
  {
    exp: 50154.48,
    userId: 3,
    userCreatedAt: '2022-02-05T02:15:27.111Z',
    userName: 'TestUser2',
    publicProfile: 'true',
    accountId: 3
  },
  {
    exp: 52642.8,
    userId: 25,
    userCreatedAt: '2022-02-05T02:15:27.111Z',
    userName: 'TestUser3',
    publicProfile: 'true',
    accountId: 25
  }
];

const CommunityPageComponent = (props, context) => {
  const isMobile = useIsMobile();
  const { history, width } = props;
  const [grandmasters, setGrandmasters] = useState([]);
  const [top100Month, setTop100Month] = useState([]);
  const [top100AllTime, setTop100AllTime] = useState([]);
  const [showTop100Month, setShowTop100Month] = useState(false);
  const [showTop100AllTime, setShowTop100AllTime] = useState(false);
  const [isMonthOpened, setIsMonthOpened] = useState(false);
  const [isAllTimeOpened, setIsAllTimeOpened] = useState(false);

  const formattedTop100AllTime = showTop100AllTime
    ? top100AllTime
    : top100AllTime.slice(0, 20);
  const formattedTop100AMonth = showTop100Month
    ? top100Month
    : top100Month.slice(0, 20);

  const template = JSON.parse(localStorage.getItem('apexTemplate'));
  const theme = template && template.theme ? template.theme : 'light';
  const isDark = theme === 'dark';

  useEffect(() => {
    getTopXPUsers().then(
      res => {
        const isProd = process.env.REACT_APP_IS_PRODUCTION === 'true';
        if (res.lastMonthGrandMasters.length === 0 && !isProd) {
          setGrandmasters(MOCK_GRANDMASTERS);
        } else {
          if (res.lastMonthGrandMasters) {
            const grandmasters = res.lastMonthGrandMasters.map(item => ({
              ...item,
              accountId: item.account_id
            }));
            setGrandmasters(grandmasters);
          }
        }
        setTop100Month(res.monthExpTopOwners);
        setTop100AllTime(res.expTopOwners);
      },
      () => {
        throw new Error('Get top users failed');
      }
    );
  }, []);

  const viewDetails = id => {
    history.push({
      pathname: `/user-portfolio/${id}`,
      state: { isGrandMaster: true }
    });
  };

  const isFalse = value => value.toLowerCase() === 'false';

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    nextArrow: <NextSliderArrow iconName="mobileSliderArrow" />,
    prevArrow: <PrevSliderArrow iconName="mobileSliderArrow" />,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const getRankImage = rank => {
    switch (rank) {
      case 1:
        return firstRankImage;
      case 2:
        return secondRankImage;
      case 3:
        return thirdRankImage;
      default:
        return rankImage;
    }
  };

  const getTopBgImage = (isOpen, isMobileHeaderOpen) => {
    if (!isMobileHeaderOpen && isMobile) {
      return isDark
        ? topMobileHeaderBackgroundDark
        : topMobileHeaderBackgroundLight;
    } else if (isOpen) {
      return isDark ? topBigBackgroundDark : topBigBackgroundLight;
    } else {
      return isDark ? topSmallBackgroundDark : topSmallBackgroundLight;
    }
  };

  const getAnimationWidth = () => {
    if (width < 900) {
      return 120;
    } else {
      return 180;
    }
  };

  const grandmastersItems = grandmasters.map((i, index) => (
    <div
      key={`grandmaster-${i.userName}-${index}`}
      className={classes('grandmaster')}>
      <TierAnimationWrapper
        className={classes('grandmaster-animation')}
        animationTier={9}
        animationType={ANIMATION_TYPES.PROFILE}
        height={isMobile ? 250 : getAnimationWidth()}
        width={isMobile ? 250 : getAnimationWidth()}
        autoplay
      />

      <div className={classes('user-info-container')}>
        <div className={classes('user-info-title')}>
          {context.t('Grandmaster')}
        </div>
        <div className={classes('user-info-username')}>{i.userName}</div>

        <div className={classes('user-info-joined')}>
          {context.t('Joined ')} {createdAtString(i.userCreatedAt)}
        </div>
        <div className={classes('user-info-XP')}>
          {i.exp}
          <span className={classes('XP-span')}>{context.t('XP')}</span>
        </div>

        <button
          className={classes('user-info-details')}
          style={{
            cursor: isFalse(i.publicProfile) ? 'not-allowed' : 'pointer'
          }}
          data-tip={context.t('This user hide his profile')}
          data-tip-disable={!isFalse(i.publicProfile)}
          data-for="view-profile-tooltip"
          onClick={() =>
            isFalse(i.publicProfile) ? null : viewDetails(i.accountId)
          }>
          {context.t('VIEW DETAILS')}
        </button>
      </div>
    </div>
  ));

  return (
    <div className={classes()}>
      <div className={classes('header')}>
        <span>{context.t('Community')}</span>
        <a
          href="https://discord.com/invite/GX35JU6XZe"
          target="_blank"
          rel="noopener noreferrer">
          <div className={classes('discord-link-container')}>
            <DiscordIcon className={classes('discord-icon')} />
            {context.t('Join Our Discord')}
          </div>
        </a>
      </div>

      <div className={classes('bg-container')}>
        <div className={classes('top3-container')}>
          <div className={classes('faded-container')}>
            <div className={classes('title')}>
              <img
                className={classes('your-grandmasters')}
                src={grandmastersGIF}
                alt="Your grandmaster"
              />
            </div>
            {isMobile ? (
              <div className={classes('slider-container')}>
                <Slider {...sliderSettings}>{grandmastersItems}</Slider>
              </div>
            ) : (
              <div className={classes('grandmasters')}>{grandmastersItems}</div>
            )}
          </div>
        </div>
      </div>

      <div className={classes('breaking-line-container')}>
        <div className={classes('breaking-line-header')}>
          {context.t('Collector of the Month:')}
        </div>
        <Marquee
          className={classes('breaking-line')}
          gradient={false}
          speed={30}>
          {top100Month
            .filter(
              user =>
                Number(user.exp) >= USER_TIERS_ARRAY[3].expPoints &&
                Number(user.exp) < USER_TIERS_ARRAY[4].expPoints
            )
            .map(i => (
              <div
                key={`breaking-line-${i.userName}`}
                className={classes('breaking-line-item')}>
                {i.userName}
              </div>
            ))}
        </Marquee>
      </div>

      <div className={classes('xp-tops')}>
        <div
          className={classes(
            'top-100-container-alltime',
            !isAllTimeOpened && isMobile
              ? 'mobile'
              : showTop100AllTime
                ? 'open'
                : 'close'
          )}>
          <img
            alt="Top all time"
            src={getTopBgImage(showTop100AllTime, isAllTimeOpened)}
            className={classes('top-bg-image')}
          />
          <div className={classes('top-100-header')}>
            <div className={classes('top-100-header-left')}>
              <div className={classes('all-time-badge')}>
                {context.t('ALL TIME')}
              </div>
              {context.t('TOP #100')}
            </div>
            {isMobile && (
              <button
                className={classes('view-all-button-all-time', 'mobile')}
                onClick={() => setIsAllTimeOpened(!isAllTimeOpened)}>
                {context.t(isAllTimeOpened ? 'Close' : 'Open')}
              </button>
            )}
          </div>

          <div
            className={classes('all-time-top-content')}
            style={{
              display: !isAllTimeOpened && isMobile ? 'none' : 'flex'
            }}>
            {formattedTop100AllTime.map((i, index) => (
              <div
                key={`all-time-top-${i.userName}`}
                className={classes('all-time-top-item')}>
                <img
                  alt="ranking"
                  src={getRankImage(index + 1)}
                  className={classes('top-item-bg-image')}
                />
                <div className={classes('top-item-left')}>
                  <div className={classes('top-position')}>{`${index +
                    1}`}</div>

                  <APIcon
                    customClass={classes('top-item-tier-badge')}
                    name={USER_TIERS_ARRAY[i.userTier - 1].iconName}
                  />

                  <div className={classes('top-item-username')}>
                    {i.userName}
                  </div>
                </div>
                <div className={classes('top-item-xp')}>
                  {`${parseFloat(i.exp).toFixed(0)} ${context.t('XP')}`}
                </div>
              </div>
            ))}
            <button
              className={classes('view-all-button-all-time', 'desktop')}
              onClick={() => setShowTop100AllTime(!showTop100AllTime)}>
              {context.t(showTop100AllTime ? 'Hide' : 'View more')}
            </button>
          </div>
        </div>

        <div
          className={classes(
            'top-100-container-monthly',
            !isMonthOpened && isMobile
              ? 'mobile'
              : showTop100Month
                ? 'open'
                : 'close'
          )}>
          <img
            alt="Top monthly"
            src={getTopBgImage(showTop100Month, isMonthOpened)}
            className={classes('top-bg-image')}
          />
          <div className={classes('top-100-header')}>
            <div className={classes('top-100-header-left')}>
              <div className={classes('monthly-badge')}>
                {context.t('MONTHLY')}
              </div>
              {context.t('TOP #100')}
            </div>
            {isMobile && (
              <button
                className={classes('view-all-button-monthly', 'mobile')}
                onClick={() => setIsMonthOpened(!isMonthOpened)}>
                {context.t(isMonthOpened ? 'Close' : 'Open')}
              </button>
            )}
          </div>

          <div
            className={classes('monthly-top-content')}
            style={{
              display: !isMonthOpened && isMobile ? 'none' : 'flex'
            }}>
            {formattedTop100AMonth.map((i, index) => (
              <div
                key={`monthly-top-${i.userName}`}
                className={classes('top-item')}>
                <img
                  alt="ranking"
                  src={getRankImage()}
                  className={classes('top-item-bg-image')}
                />
                <div className={classes('top-item-left')}>
                  <div className={classes('top-position')}>{`${index +
                    1}`}</div>
                  <APIcon
                    customClass={classes('top-item-tier-badge')}
                    name={USER_TIERS_ARRAY[i.userTier - 1].iconName}
                  />
                  <div className={classes('top-item-username')}>
                    {i.userName}
                  </div>
                </div>

                <div className={classes('top-item-xp')}>
                  {`${parseFloat(i.exp).toFixed(0)} ${context.t('XP')}`}
                </div>
              </div>
            ))}
            <button
              className={classes('view-all-button-monthly', 'desktop')}
              onClick={() => setShowTop100Month(!showTop100Month)}>
              {context.t(showTop100Month ? 'Hide' : 'View more')}
            </button>
          </div>
        </div>
      </div>

      <ReactTooltip id="view-profile-tooltip" />
    </div>
  );
};

CommunityPageComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

CommunityPageComponent.propTypes = {
  history: PropTypes.object,
  width: PropTypes.number
};

export const CommunityPage = withRouter(resize(CommunityPageComponent));
