import { isDarkTheme } from '../../../../helpers/themeHelper';

export const getDotsIframeStyles = () => {
  const isDark = isDarkTheme();
  const configurations = {
    theme: isDark ? 'dark' : 'light',
    variables: {
      backgroundColor: isDark ? '#2c2f36' : '#ffffff',
      primaryTextColor: isDark ? '#ffffff' : '#000000'
    },
  };
  const stylesConfig = encodeURIComponent(JSON.stringify(configurations));

  return stylesConfig;
};
