import { connect } from 'react-redux';
import ReceiveAddressComponent from './ReceiveAddressComponent';
import { fetchProductDepositTemplate } from 'apex-web/lib/redux/actions/depositActions';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import config from 'apex-web/lib/config';

const mapStateToProps = state => ({
  deposit: state.deposit,
  WalletsDisabledMessage: config.global.WalletsDisabledMessage
});

const mapDispatchToProps = dispatch => ({
  selectDepositProduct: productId =>
    dispatch(fetchProductDepositTemplate(productId)),
  showSnack: text => dispatch(showSnack(text))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiveAddressComponent);
