import { connect } from 'react-redux';
import { AssetHeader } from './AssetHeader';
import {
  checkIsOffering,
  isProductByDefaultScenario,
  isProductByScriptScenario
} from '../../redux/selectors/productsSelector';
import { availableTokensSelector, selectIsAssetInWatchList } from '../../redux/selectors/cardSelector';
import { getAvailableTokens, updateAssetsWatchList } from '../../redux/actions/cardActions';
import { openBuySellModal } from '../../redux/actions/buySellModalActions';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { isProductSoldOutSelector } from '../../redux/selectors/productsSelector';
import { selectInstrument } from 'apex-web/lib/redux/actions/instrumentActions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state, ownProps) => {
  const { assetDetails } = ownProps;
  const onlyMarketAvailable = isProductByDefaultScenario(
    state,
    assetDetails.ProductId
  );
  const onlyLimitAvailable = isProductByScriptScenario(
    state,
    assetDetails.ProductId
  );
  const isSellDisable = onlyMarketAvailable || onlyLimitAvailable;
  const checkIsEnoughOnMarket = () => {
    // TODO(Apr 05, 2022): uncomment or remove when we decide whether we need buy/sell limit orders
    // return BigNumber(
    //   approximateQuantitySelector(state, { side, productId: assetDetails.ProductId })
    // ).gt(0);
    return true;
  };
  const instrument = state.apexCore.instrument.instruments.find(
    instr => instr.Product1 === assetDetails.ProductId
  );
  let ppt = 0;
  if (instrument) {
    ppt = get(state, [
      'apexCore',
      'level1',
      instrument.InstrumentId,
      'BestOffer'
    ]);
  }
  const availableInstruments = instrumentSelectorInstruments(state).filter(
    instrument => isProductSoldOutSelector(state, instrument.Product1)
  );

  const productAttributes = assetDetails.product.productAttributes;
  // offerings have less ui elements to display than market assets, so we use
  // true for empty objects to make sure that ui elements can only appear, not disappear
  const isOffering = isEmpty(productAttributes) || checkIsOffering(productAttributes);

  return {
    availableTokens: availableTokensSelector(state),
    isSellDisable,
    onlyLimitAvailable,
    checkIsEnoughOnMarket,
    isFavorite: selectIsAssetInWatchList(state, assetDetails.ProductId),
    ppt,
    instruments: availableInstruments,
    isOffering,
  };
};

const mapDispatchToProps = dispatch => ({
  makeOrder: props => {
    dispatch(openBuySellModal(props));
  },
  getAvailableTokens: ({ productIds }) =>
    dispatch(getAvailableTokens({ productIds })),
  selectInstrument: instrumentId => {
    dispatch(selectInstrument(instrumentId));
  },
  addToFavorite: productId => dispatch(updateAssetsWatchList(productId))
});

const AssetHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssetHeader));

export { AssetHeaderContainer };
