import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { getCSSVar } from 'apex-web/lib/helpers/cssVarHelper';
import resize from 'apex-web/lib/hocs/resize';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import 'apex-chart-react/lib/chart-style.css';
import './DepthChart.css';

const tradeComponentClasses = getBEMClasses('trade-component');
const depthChartComponentClasses = getBEMClasses('depth-chart');

let mousePos = { x: 0, y: 0 };
let mouseOver = false;
const defaultDepthChartZoom = 5;

class DepthChartComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      depthchartzoom:
        this.props.DepthChart.depthchartzoom || defaultDepthChartZoom
    };
  }

  componentDidMount() {
    this.cssVariable = getCSSVar();
    this.setListeners();
    this.drawChart();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedInstrument.InstrumentId !==
      this.props.selectedInstrument.InstrumentId
    ) {
      this.setState({
        depthchartzoom:
          this.props.DepthChart.depthchartzoom || defaultDepthChartZoom
      });
    }

    this.drawChart();
  }

  componentWillUnmount() {}

  setListeners = () => {
    const chartDom = document.getElementById('depthchart');
    chartDom.addEventListener('mouseover', () => {
      mouseOver = true;
    });
    chartDom.addEventListener('mouseout', () => {
      mouseOver = false;
      this.drawChart();
    });
    chartDom.addEventListener('mousemove', evt => {
      const rect = chartDom.getBoundingClientRect();
      mousePos = {
        x: evt.clientX - rect.left,
        y: evt.clientY - rect.top
      };

      this.drawChart();
    });
    chartDom.addEventListener('click', () => {
      if (this.mouseHoverBuy) {
        this.props.buyPriceClicked(this.closestMousePositionInfo.price);
      } else {
        this.props.sellPriceClicked(this.closestMousePositionInfo.price);
      }
    });
  };

  drawChart() {
    const { DepthChart, smallScreen, level2 } = this.props;

    const depthChartHeight = DepthChart.depthChartHeight + 50 || 300;
    const depthChartTextColor =
      DepthChart.depthChartTextColor || 'rgb(200,200,200)'; // Grey
    const depthChartTextFont = DepthChart.depthChartTextFont || '12px';
    const depthChartXAxisTextPadding =
      DepthChart.depthChartXAxisTextPadding || 60;
    const depthChartYAxisTextPadding =
      DepthChart.depthChartYAxisTextPadding || 20;
    const depthChartBottomPadding = DepthChart.depthChartBottomPadding || 20;
    const depthChartXAxisStepCnt = DepthChart.depthChartXAxisStepCnt || 9;
    const depthChartXAxisStepColor = this.cssVariable(
      '--chart__x-axis-step-color'
    );
    const lastTradeDownColor = this.cssVariable('--chart__bearish-color');

    const lastTradeDownColorTransparent = this.cssVariable(
      '--chart__bearish-color_transparent'
    );

    const lastTradeUpColor = this.cssVariable('--chart__bullish-color');
    const lastTradeUpColorTransparent = this.cssVariable(
      '--chart__bullish-color_transparent'
    );

    const buyBook = level2.buy;
    const sellBook = level2.sell;

    const c2 = document.getElementById('depthchart');
    const ch = document.getElementById('depthChartHolder');
    c2.width = ch.clientWidth;
    c2.height = smallScreen ? ch.clientHeight : depthChartHeight;
    this.chartHeight = c2.height;
    this.chartWidth = c2.width;
    let maxBidPrice = 0;
    let minAskPrice = 999999;

    const sortedSellBook = sellBook.map(order => order.Price);
    sortedSellBook.forEach(price => {
      if (price < minAskPrice) minAskPrice = price;
    });

    const sortedBuyBook = buyBook.map(order => order.Price);
    sortedBuyBook.forEach(price => {
      if (price > maxBidPrice) maxBidPrice = price;
    });

    const center = (minAskPrice + maxBidPrice) / 2;

    this.relativeDepthChartZoom = Math.abs(
      (minAskPrice - maxBidPrice) * this.state.depthchartzoom
    );

    const midMarketPrice = document.getElementById('midMarketPrice');
    midMarketPrice.innerHTML = center.toFixed(
      convertIncrementToIntDecimalPlaces(
        this.props.selectedInstrument.PriceIncrement
      )
    );
    const maxAskDisplay = center + this.relativeDepthChartZoom;
    const minBidDisplay = center - this.relativeDepthChartZoom;

    let visibleAskDepth = 0;
    let sellLevels = 0;
    for (let i = sortedSellBook.length - 1; i >= 0; --i) {
      if (sellBook[i].Price > maxAskDisplay) break;
      visibleAskDepth += sellBook[i].Quantity;
      ++sellLevels;
    }
    let visibleBidDepth = 0;
    let buyLevels = 0;
    for (let i = 0; i < sortedBuyBook.length; i++) {
      if (buyBook[i].Price < minBidDisplay) break;
      visibleBidDepth += buyBook[i].Quantity;
      ++buyLevels;
    }

    let topvisibleDepth = visibleAskDepth;
    if (visibleBidDepth > visibleAskDepth) topvisibleDepth = visibleBidDepth;

    const multX = this.chartWidth / (this.relativeDepthChartZoom * 2); // pixels per unit of price
    const multY =
      (this.chartHeight - depthChartBottomPadding) / topvisibleDepth; // pixels per unit of depth

    this.ctx2 = c2.getContext('2d');
    this.ctx2.clearRect(0, 0, this.chartWidth, this.chartHeight);
    let lastY = this.chartHeight - depthChartBottomPadding;
    let lastX = this.chartWidth / 2;

    this.ctx2.beginPath();
    this.ctx2.moveTo(lastX, 50);
    this.ctx2.save();
    this.ctx2.lineWidth = 1;
    this.ctx2.lineTo(lastX, this.chartHeight - depthChartBottomPadding);
    this.ctx2.strokeStyle = depthChartTextColor;
    this.ctx2.stroke();

    this.mouseHoverBuy = false;
    this.mouseHoverSell = false;
    if (mousePos.x < lastX) {
      this.mouseHoverBuy = true;
    } else if (mousePos.x > lastX) {
      this.mouseHoverSell = true;
    }

    let lastTextEntry = lastX;
    let lastpx = center;
    lastX =
      this.chartWidth / 2 - (center - parseFloat(sortedBuyBook[0])) * multX;

    let lastXDrawn = 0;
    let lastYDrawn = 0;

    let lastTextEntryY = lastY;
    let lastTextEntryX = lastX;
    let depthShown = 0;

    this.closestMousePositionInfo = {
      price: 0,
      volume: 0,
      x: 0,
      y: 0,
      xdiff: 999999
    };
    this.closestMousePositionInfo.mouseX = mousePos.x;

    for (let i = 0; i < depthChartXAxisStepCnt; i++) {
      const xAxisText = (
        minBidDisplay +
        ((this.relativeDepthChartZoom * 2) / (depthChartXAxisStepCnt - 1)) * i
      ).toFixed(
        convertIncrementToIntDecimalPlaces(
          this.props.selectedInstrument.PriceIncrement
        )
      );
      const xAxisStepI =
        ((this.chartWidth - 35) / (depthChartXAxisStepCnt - 1)) * i;
      this.ctx2.font = depthChartTextFont;
      this.ctx2.fillStyle = depthChartXAxisStepColor;
      this.ctx2.fillText(xAxisText, xAxisStepI, this.chartHeight - 10);
    }

    for (let i = 0; i < buyLevels; i++) {
      const lvl = buyBook[i];
      depthShown += lvl.Quantity;
      const x = parseInt(lastX - (lastpx - lvl.Price) * multX, 10);
      const y =
        this.chartHeight -
        parseInt(depthShown * multY, 10) -
        depthChartBottomPadding;

      if (this.mouseHoverBuy) {
        if (
          this.closestMousePositionInfo.xdiff > Math.abs(lastX - mousePos.x)
        ) {
          this.closestMousePositionInfo.price = lvl.Price;
          this.closestMousePositionInfo.xdiff = Math.abs(lastX - mousePos.x);
          this.closestMousePositionInfo.volume = depthShown;
          this.closestMousePositionInfo.x = lastX;
          this.closestMousePositionInfo.y = y;
        }
      }

      lastpx = lvl.Price;

      if (lastTextEntryX - x > depthChartXAxisTextPadding && x - 40 > 0) {
        lastTextEntryX = x;
      }

      if (x <= this.chartWidth && y <= this.chartHeight && x >= 0) {
        this.ctx2.beginPath();
        this.ctx2.moveTo(lastX, y);
        this.ctx2.save();
        this.ctx2.lineWidth = 1;
        this.ctx2.lineTo(x, y);
        this.ctx2.strokeStyle = lastTradeUpColor;
        this.ctx2.stroke();

        this.ctx2.beginPath();
        this.ctx2.moveTo(lastX, lastY);
        this.ctx2.lineTo(lastX, y);
        this.ctx2.stroke();
        this.ctx2.restore();
        this.ctx2.fillStyle = lastTradeUpColorTransparent;

        this.ctx2.fillRect(
          lastX,
          y,
          x - lastX,
          this.chartHeight - depthChartBottomPadding - y
        );

        if (lastTextEntryY - y >= depthChartYAxisTextPadding && y - 10 > 0) {
          lastTextEntryY = y;
          this.ctx2.save();
          this.ctx2.beginPath();
          this.ctx2.moveTo(x, y);
          this.ctx2.lineW = 1;
          this.ctx2.lineTo(0, y);
          this.ctx2.strokeStyle = lastTradeUpColor;
          this.ctx2.setLineDash([2, 3]);
          this.ctx2.stroke();
          this.ctx2.restore();
        }

        if (x - lastTextEntry >= depthChartXAxisTextPadding) {
          this.ctx2.save();
          this.ctx2.beginPath();
          this.ctx2.moveTo(x, this.chartHeight - depthChartBottomPadding);
          this.ctx2.lineW = 1;
          this.ctx2.lineTo(x, this.chartHeight - 10);
          this.ctx2.strokeStyle = lastTradeUpColor;
          this.ctx2.stroke();
          this.ctx2.restore();
          lastTextEntry = x;
        }

        lastXDrawn = x;
        lastYDrawn = y;
      }

      lastX = x;
      lastY = y;
    }

    this.ctx2.fillStyle = lastTradeUpColorTransparent;
    this.ctx2.fillRect(
      0,
      lastYDrawn,
      lastXDrawn,
      this.chartHeight - depthChartBottomPadding - lastYDrawn
    );
    this.ctx2.beginPath();
    this.ctx2.moveTo(lastXDrawn, lastYDrawn);
    this.ctx2.save();
    this.ctx2.lineWidth = 1;
    this.ctx2.lineTo(0, lastYDrawn);
    this.ctx2.strokeStyle = lastTradeUpColor;
    this.ctx2.stroke();
    this.ctx2.restore();

    lastY = this.chartHeight - depthChartBottomPadding;
    lastX = this.chartWidth / 2;
    lastTextEntry = lastX;
    lastpx = center;
    lastTextEntryY = lastY;
    lastX =
      this.chartWidth / 2 +
      (parseFloat(sortedSellBook[sortedSellBook.length - 1]) - center) * multX;
    lastTextEntryX = 0;
    let shownum = sortedSellBook.length - sellLevels - 1;
    if (shownum < 0) shownum = 0;
    let depthShownA = 0;
    for (let i = sortedSellBook.length - 1; i >= shownum; i--) {
      const lvl = sellBook[i];
      depthShownA += lvl.Quantity;
      const x = parseInt(lastX + (lvl.Price - lastpx) * multX, 10);
      const y =
        this.chartHeight -
        parseInt(depthShownA * multY, 10) -
        depthChartBottomPadding;
      lastpx = lvl.Price;

      if (this.mouseHoverSell) {
        if (
          this.closestMousePositionInfo.xdiff > Math.abs(lastX - mousePos.x)
        ) {
          this.closestMousePositionInfo.xdiff = Math.abs(lastX - mousePos.x);
          this.closestMousePositionInfo.price = lvl.Price;
          this.closestMousePositionInfo.volume = depthShownA;
          this.closestMousePositionInfo.x = lastX;
          this.closestMousePositionInfo.y = y;
        }
      }

      if (
        x - lastTextEntryX > depthChartXAxisTextPadding &&
        x + 40 < this.chartWidth
      ) {
        lastTextEntryX = x;
      }

      if (x <= this.chartWidth && y <= this.chartHeight && x >= 0) {
        this.ctx2.save();
        this.ctx2.beginPath();
        this.ctx2.moveTo(lastX, y);
        this.ctx2.lineWidth = 1;
        this.ctx2.lineTo(x, y);
        this.ctx2.strokeStyle = lastTradeDownColor;
        this.ctx2.stroke();

        this.ctx2.beginPath();
        this.ctx2.moveTo(lastX, lastY);
        this.ctx2.lineTo(lastX, y);
        this.ctx2.stroke();
        this.ctx2.restore();
        this.ctx2.fillStyle = lastTradeDownColorTransparent;

        this.ctx2.fillRect(
          lastX,
          y,
          x - lastX,
          this.chartHeight - depthChartBottomPadding - y
        );

        if (lastTextEntryY - y >= depthChartYAxisTextPadding && y - 10 > 0) {
          lastTextEntryY = y;
          this.ctx2.save();
          this.ctx2.beginPath();
          this.ctx2.moveTo(this.chartWidth, y);
          this.ctx2.lineW = 1;
          this.ctx2.lineTo(x, y);
          this.ctx2.strokeStyle = lastTradeDownColor;
          this.ctx2.setLineDash([2, 3]);
          this.ctx2.stroke();
          this.ctx2.restore();
        }
        if (lastTextEntry - x >= depthChartXAxisTextPadding) {
          this.ctx2.font = depthChartTextFont;
          this.ctx2.fillStyle = depthChartTextColor;
          this.ctx2.fillText(
            lvl.Price.toFixed(
              convertIncrementToIntDecimalPlaces(
                this.props.selectedInstrument.PriceIncrement
              )
            ),
            x,
            this.chartHeight
          );

          this.ctx2.save();
          this.ctx2.beginPath();
          this.ctx2.moveTo(x, this.chartHeight - depthChartBottomPadding);
          this.ctx2.lineW = 1;
          this.ctx2.lineTo(x, this.chartHeight - 10);
          this.ctx2.strokeStyle = lastTradeDownColor;
          this.ctx2.stroke();
          this.ctx2.restore();

          lastTextEntry = x;
        }
        lastXDrawn = x;
        lastYDrawn = y;
      }
      lastX = x;
      lastY = y;
    }

    this.ctx2.fillStyle = lastTradeDownColorTransparent;
    this.ctx2.fillRect(
      lastXDrawn,
      lastYDrawn,
      this.chartWidth,
      this.chartHeight - depthChartBottomPadding - lastYDrawn
    );
    this.ctx2.beginPath();
    this.ctx2.moveTo(lastXDrawn, lastYDrawn);
    this.ctx2.save();
    this.ctx2.lineWidth = 1;
    this.ctx2.lineTo(this.chartWidth, lastYDrawn);
    this.ctx2.strokeStyle = lastTradeDownColor;
    this.ctx2.stroke();
    this.ctx2.restore();

    this.renderTooltip(
      depthChartTextFont,
      depthChartTextColor,
      lastTradeUpColorTransparent,
      lastTradeDownColorTransparent
    );
  }

  getSellTooltipRotation = (
    tooltipSize,
    offsetCentralChartLine,
    priceAxisHeight
  ) => {
    const marginTop = this.closestMousePositionInfo.y - tooltipSize.height;
    const marginLeft = this.closestMousePositionInfo.x - tooltipSize.width;
    const marginBottom = this.closestMousePositionInfo.y + tooltipSize.height;
    const centralChartLimit = this.chartWidth / 2 + offsetCentralChartLine;
    const bottomChartLimit = this.chartHeight - priceAxisHeight;

    const rightBottomRotation =
      marginLeft <= centralChartLimit && marginTop <= 0;
    const rightTopRotation =
      marginLeft <= centralChartLimit && marginBottom >= bottomChartLimit;
    const leftBottomRotation =
      marginLeft >= centralChartLimit && marginTop <= 0;

    if (rightBottomRotation) {
      return { x: 1, y: -1 };
    } else if (rightTopRotation) {
      return { x: 1, y: 1 };
    } else if (leftBottomRotation) {
      return { x: -1, y: -1 };
    } else {
      return { x: -1, y: 1 };
    }
  };

  getBuyTooltipRotation = (
    tooltipSize,
    offsetCentralChartLine,
    priceAxisHeight
  ) => {
    const marginTop = this.closestMousePositionInfo.y - tooltipSize.height;
    const marginRight = this.closestMousePositionInfo.x + tooltipSize.width;
    const marginBottom = this.closestMousePositionInfo.y + tooltipSize.height;
    const centralChartLimit = this.chartWidth / 2 - offsetCentralChartLine;
    const bottomChartLimit = this.chartHeight - priceAxisHeight;

    const leftBottomRotation =
      marginRight >= centralChartLimit && marginTop <= 0;
    const leftTopRotation =
      marginRight >= centralChartLimit && marginBottom >= bottomChartLimit;
    const rightBottomRotation =
      marginRight <= centralChartLimit && marginTop <= 0;

    if (leftBottomRotation) {
      return { x: -1, y: -1 };
    } else if (leftTopRotation) {
      return { x: -1, y: 1 };
    } else if (rightBottomRotation) {
      return { x: 1, y: -1 };
    } else {
      return { x: 1, y: 1 };
    }
  };

  renderTooltip = (
    depthChartTextFont,
    depthChartTextColor,
    lastTradeUpColorTransparent,
    lastTradeDownColorTransparent
  ) => {
    const tooltipSize = {
      width: 100,
      height: 60,
      paddingLeft: 20,
      paddingTop: 10
    };
    const offsetCentralChartLine = 60;
    const priceAxisHeight = 10;

    const sellTooltipRotation = this.getSellTooltipRotation(
      tooltipSize,
      offsetCentralChartLine,
      priceAxisHeight
    );
    const buyTooltipRotation = this.getBuyTooltipRotation(
      tooltipSize,
      offsetCentralChartLine,
      priceAxisHeight
    );

    const getFieldPositionX = (padding = 0) => {
      const { x } = this.closestMousePositionInfo;
      const tooltipRotation = this.mouseHoverBuy
        ? buyTooltipRotation
        : sellTooltipRotation;
      const rightBottomRotation =
        tooltipRotation.x === 1 && tooltipRotation.y === -1;
      const rightTopRotation =
        tooltipRotation.x === 1 && tooltipRotation.y === 1;

      if (rightBottomRotation || rightTopRotation) {
        return x + tooltipSize.paddingLeft + padding;
      } else {
        return x - tooltipSize.width + tooltipSize.paddingLeft + padding;
      }
    };

    const getFieldPositionY = (padding = 0) => {
      const { y } = this.closestMousePositionInfo;
      const tooltipRotation = this.mouseHoverBuy
        ? buyTooltipRotation
        : sellTooltipRotation;
      const rightBottomRotation =
        tooltipRotation.x === 1 && tooltipRotation.y === -1;
      const leftBottomRotation =
        tooltipRotation.x === -1 && tooltipRotation.y === -1;

      if (rightBottomRotation || leftBottomRotation) {
        return y + tooltipSize.paddingTop + padding;
      } else {
        return y - tooltipSize.height + tooltipSize.paddingTop + padding;
      }
    };

    if (mouseOver && (this.mouseHoverBuy || this.mouseHoverSell)) {
      this.ctx2.beginPath();
      this.ctx2.arc(
        this.closestMousePositionInfo.x,
        this.closestMousePositionInfo.y,
        3,
        0,
        2 * Math.PI,
        false
      );
      this.ctx2.fillStyle = 'rgba(205,205,205, 0.3)';
      this.ctx2.fill();
      this.ctx2.lineWidth = 1;
      this.ctx2.strokeStyle = 'rgba(205,205,205, 0.3)';
      this.ctx2.stroke();

      if (this.mouseHoverBuy) {
        this.ctx2.font = depthChartTextFont;
        this.ctx2.fillStyle = depthChartTextColor;
        this.ctx2.fillText(
          this.closestMousePositionInfo.price.toFixed(
            priceDp(this.props.selectedInstrument)
          ),
          getFieldPositionX(),
          getFieldPositionY(10)
        );
        this.ctx2.fillText('Buy', getFieldPositionX(), getFieldPositionY(25));
        this.ctx2.fillText(
          this.closestMousePositionInfo.volume.toFixed(
            priceDp(this.props.selectedInstrument)
          ),
          getFieldPositionX(30),
          getFieldPositionY(25)
        );
        this.ctx2.fillText('Cost', getFieldPositionX(), getFieldPositionY(40));
        this.ctx2.fillText(
          (
            this.closestMousePositionInfo.volume *
            this.closestMousePositionInfo.price
          ).toFixed(priceDp(this.props.selectedInstrument)),
          getFieldPositionX(30),
          getFieldPositionY(40)
        );
        this.ctx2.fillStyle = lastTradeUpColorTransparent;
        this.ctx2.fillRect(
          this.closestMousePositionInfo.x,
          this.closestMousePositionInfo.y -
            tooltipSize.height * buyTooltipRotation.y,
          tooltipSize.width * buyTooltipRotation.x,
          tooltipSize.height * buyTooltipRotation.y
        );
      }

      if (this.mouseHoverSell) {
        this.ctx2.font = depthChartTextFont;
        this.ctx2.fillStyle = depthChartTextColor;
        this.ctx2.fillText(
          this.closestMousePositionInfo.price.toFixed(
            priceDp(this.props.selectedInstrument)
          ),
          getFieldPositionX(),
          getFieldPositionY(10)
        );
        this.ctx2.fillText('Buy', getFieldPositionX(), getFieldPositionY(25));
        this.ctx2.fillText(
          this.closestMousePositionInfo.volume.toFixed(
            priceDp(this.props.selectedInstrument)
          ),
          getFieldPositionX(30),
          getFieldPositionY(25)
        );
        this.ctx2.fillText('Cost', getFieldPositionX(), getFieldPositionY(40));
        this.ctx2.fillText(
          (
            this.closestMousePositionInfo.volume *
            this.closestMousePositionInfo.price
          ).toFixed(priceDp(this.props.selectedInstrument)),
          getFieldPositionX(30),
          getFieldPositionY(40)
        );
        this.ctx2.fillStyle = lastTradeDownColorTransparent;
        this.ctx2.fillRect(
          this.closestMousePositionInfo.x,
          this.closestMousePositionInfo.y -
            tooltipSize.height * sellTooltipRotation.y,
          tooltipSize.width * sellTooltipRotation.x,
          tooltipSize.height * sellTooltipRotation.y
        );
      }
    }
  };

  zoomDpethChartPlus = () => {
    const newDepthChartZoom = this.state.depthchartzoom / 1.25;
    if (newDepthChartZoom < 0.0001) {
      this.setState({
        depthchartzoom: 0.0001
      });
    } else {
      this.setState({
        depthchartzoom: newDepthChartZoom
      });
    }

    this.drawChart();
  };

  zoomDpethChartMinus = () => {
    const newDepthChartZoom = this.state.depthchartzoom * 1.25;
    if (newDepthChartZoom > 1000) {
      this.setState({
        depthchartzoom: 1000
      });
    } else {
      this.setState({
        depthchartzoom: newDepthChartZoom
      });
    }

    this.drawChart();
  };

  render() {
    const { DepthChart, smallScreen, fetching } = this.props;

    let chartContainerHeight = DepthChart.depthChartHeight
      ? DepthChart.depthChartHeight + 50
      : 350;

    if (smallScreen) {
      chartContainerHeight = '100%';
    }

    return (
      <div
        style={{
          minHeight: chartContainerHeight,
          height: chartContainerHeight
        }}
        className={`${tradeComponentClasses()} ${depthChartComponentClasses()}`}>
        {fetching && <Spinner />}
        <div
          id="depthChartHolder"
          className={`${depthChartComponentClasses('')}`}>
          <canvas
            id="depthchart"
            style={{
              position: 'absolute',
              zIndex: 2,
              width: '100%',
              height: chartContainerHeight
            }}
          />
          <div className={`${depthChartComponentClasses('buttons')}`}>
            <button
              className={`${depthChartComponentClasses('button--zoom')}`}
              onClick={() => this.zoomDpethChartPlus()}>
              +
            </button>
            <span id="midMarketPrice">0</span>
            <button
              className={`${depthChartComponentClasses('button--zoom')}`}
              onClick={() => this.zoomDpethChartMinus()}>
              -
            </button>
          </div>
          <div className={`${depthChartComponentClasses('zoom-label')}`}>
            {this.context.t(`Zoom: ${this.state.depthchartzoom.toFixed(2)}`)}
          </div>
          <div className={`${depthChartComponentClasses('label')}`}>
            {this.context.t('Mid Market Price')}
          </div>
        </div>
      </div>
    );
  }
}

const priceDp = instrument =>
  Math.max(4, convertIncrementToIntDecimalPlaces(instrument.PriceIncrement));

DepthChartComponent.defaultProps = {
  smallScreen: false
};

DepthChartComponent.propTypes = {
  store: PropTypes.object.isRequired,
  level2: PropTypes.object.isRequired,
  DepthChart: PropTypes.any,
  selectedInstrument: PropTypes.any,
  buyPriceClicked: PropTypes.func,
  sellPriceClicked: PropTypes.func,
  smallScreen: PropTypes.bool,
  fetching: PropTypes.bool
};

DepthChartComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default resize(DepthChartComponent);
