import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { authUser } from '../../api/liquidApi';
import { wsClient } from '../../api/ws/wsClient';
import history from '../../helpers/history';

export const authUserLiquid = payload => async dispatch => {
  try {
    const { userId, token } = payload;
    await authUser(payload.token, payload.userId);
    if (userId && token) {
      wsClient.connect(
        { userId, token },
        dispatch
      );
    }
  } catch (e) {
    // dispatch(logout());
    history.push('');
    dispatch(
      showSnack({
        id: 'liquidAuthError',
        text: e.message,
        type: 'warning'
      })
    );
  }
};
