import React from 'react';
import PropTypes from 'prop-types';
import APTable from '../../common/APTable/APTable';
import CancelAPButton from 'apex-web/lib/components/common/CancelAPButton';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { getOrderPriceToDisplay } from 'apex-web/lib/helpers/orderHistoryHelper';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

const baseClass = getBEMClasses('order-history-table');

class OpenOrdersComponent extends React.Component {
  componentDidMount() {
    if (this.props.selectedAccountId) {
      this.props.getOpenOrders(
        this.props.selectedAccountId,
        this.props.OMSId,
        this.props.config.maxLines,
        0
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedAccountId !== this.props.selectedAccountId) {
      this.props.getOpenOrders(
        this.props.selectedAccountId,
        this.props.OMSId,
        this.props.config.maxLines,
        0
      );
    }
  }

  render() {
    const { props, context } = this;
    const {
      data,
      instruments,
      cancelOrders,
      fetching,
      config,
      selectedInstrument
    } = props;
    const { maxLines, usePagination } = config;

    const columns = [
      {
        header: context.t('Pair'),
        dataTest: 'Pair',
        cell: (row, { selectedInstrument }) =>
          selectedInstrument ? selectedInstrument.Symbol : ''
      },
      {
        header: context.t('Side'),
        dataTest: 'Side',
        cell: row => (
          <div className={baseClass(row.Side === 'Buy' ? 'buy' : 'sell')}>
            {context.t(row.Side)}
          </div>
        )
      },
      {
        header: context.t('Type'),
        dataTest: 'Type',
        cell: row =>
          row.IsQuote ? context.t('Quote') : context.t(row.OrderType)
      },
      {
        header: context.t('Size'),
        dataTest: 'Size',
        cell: (row, { selectedInstrument }) => (
          <div className={baseClass('size')}>
            {row.quantity &&
              formatNumberToLocale(
                row.quantity,
                convertIncrementToIntDecimalPlaces(
                  selectedInstrument.QuantityIncrement
                )
              )}
          </div>
        )
      },
      {
        header: context.t('Price'),
        dataTest: 'Price',
        cell: (row, { selectedInstrument }) => (
          <div className={baseClass('price')}>
            {getOrderPriceToDisplay(
              row,
              convertIncrementToIntDecimalPlaces(
                selectedInstrument.PriceIncrement
              )
            )}
          </div>
        )
      },
      {
        header: context.t('Date/Time'),
        dataTest: 'Date/Time',
        cell: row => formatDateTime(row.ReceiveTime)
      },
      {
        header: context.t('Status'),
        dataTest: 'Status',
        cell: row => context.t(row.OrderState)
      },
      {
        header: context.t('Action'),
        dataTest: 'Action',
        cell: row => (
          <CancelAPButton
            text={context.t('Cancel')}
            customClass={baseClass()}
            dataTest="Cancel"
            onClick={() => {
              cancelOrders([row.OrderId]);
            }}
          />
        )
      }
    ];

    const rowProps = row => ({
      'data-tip': true,
      'data-for': `orderInfo-${row.OrderId}`,
      selectedInstrument:
        selectedInstrument ||
        instruments.find(item => item.InstrumentId === row.Instrument) ||
        {}
    });

    return (
      <APTable
        {...{
          columns,
          rows: data,
          totalPages: data.length,
          baseClass,
          fetching,
          rowProps,
          pageSize: maxLines,
          usePagination,
          alwaysShowPagination: true,
          useSimplePagination: true,
          useFullData: true,
          empty: context.t('No data is available'),
          componentName: 'OpenOrdersComponent',
          onRequestPrev: () => {
            this.props.getOpenOrders(
              this.props.selectedAccountId,
              this.props.OMSId,
              this.props.config.maxLines,
              this.props.openOrdersStartIndex - this.props.config.maxLines
            );
          },
          onRequestNext: () => {
            this.props.getOpenOrders(
              this.props.selectedAccountId,
              this.props.OMSId,
              this.props.config.maxLines,
              this.props.openOrdersStartIndex + this.props.config.maxLines
            );
          }
        }}
      />
    );
  }
}

/*
 this.context.t('Open Orders');
 */
OpenOrdersComponent.title = 'Open Orders';

OpenOrdersComponent.propTypes = {
  data: PropTypes.array.isRequired,
  instruments: PropTypes.array.isRequired,
  cancelOrders: PropTypes.func.isRequired,
  config: PropTypes.any,
  openOrdersStartIndex: PropTypes.number,
  OMSId: PropTypes.number,
  selectedAccountId: PropTypes.number,
  getOpenOrders: PropTypes.func,
  selectedInstrument: PropTypes.any,
  fetching: PropTypes.bool
};

OpenOrdersComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default OpenOrdersComponent;
