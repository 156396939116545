import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import SignupFormContainer from './SignUpForm/SignupFormContainer';

import '../../styles/components/common/StandaloneForm.css';
import './SignupPage.css';
import redirectIfAuthorized from '../../hocs/redirectIfAuthorized';

const signupPageClasses = getBEMClasses('signup-page');

export const SignupFormComponent = () => {
  return (
    <div className={signupPageClasses('container')}>
      <SignupFormContainer />
    </div>
  );
};

SignupFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  SignupFormComponent,
  process.env.REACT_APP_DEFAULT_PATH
);
