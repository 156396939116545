import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { getBEMClasses, addModifier } from '../../../helpers/cssClassesHelper';

import './APIcon.css';

// navigation
import buySellIcon from '../../../images/icons/icon-nav-buy-sell.svg';
import dashboardIcon from '../../../images/icons/icon-nav-dashboard-on.svg';
import tradingIcon from '../../../images/icons/icon-nav-trading.svg';
import userIcon from '../../../images/icons/icon-nav-user.svg';
import userSettingsIcon from '../../../images/icons/icon-nav-settings.svg';
import walletIcon from '../../../images/icons/icon-nav-wallet.svg';
import iconActivity from '../../../images/icons/icon-activity.svg';
import balanceIcon from '../../../images/icons/icon-nav-trophy.svg';
// user settings
import contactsIcon from '../../../images/icons/icon-contacts.svg';
import keyIcon from '../../../images/icons/icon-key.svg';
import reportsIcon from '../../../images/icons/icon-reports.svg';
import securityIcon from '../../../images/icons/icon-security.svg';
import tasksIcon from '../../../images/icons/icon-tasks.svg';
import heartIcon from '../../../images/icons/icon-heart.svg';
import friendsIcon from '../../../images/icons/icon-friends.svg';
import paintIcon from '../../../images/icons/icon-paint.svg';
// tables
import sendWithBorderIcon from '../../../images/icons/icon-send-with-border.svg';
import receiveWithBorderIcon from '../../../images/icons/icon-receive-with-border.svg';
import depositWithBorderIcon from '../../../images/icons/icon-deposit-with-border.svg';
import withdrawWithBorderIcon from '../../../images/icons/icon-withdraw-with-border.svg';
import openBuy from '../../../images/icons/icon-open-buy-big.svg';
import openSell from '../../../images/icons/icon-open-sell-big.svg';
//pagination
import caretPreviousIcon from '../../../images/icons/icon-caret-prev.svg';
import caretNextIcon from '../../../images/icons/icon-caret-next.svg';
import doubleCaretPreviousIcon from '../../../images/icons/icon-double-caret-prev.svg';
import doubleCaretNextIcon from '../../../images/icons/icon-double-caret-next.svg';
//forms
import dropdownIcon from '../../../images/icons/icon-dropdown.svg';
import barcodeIcon from '../../../images/icons/icon-barcode.svg';
import calendarIcon from '../../../images/icons/icon-calendar.svg';
import eyeIcon from '../../../images/icons/icon-eye.svg';
import infoIcon from '../../../images/icons/icon-info.svg';
import cancelIcon from '../../../images/icons/icon-cancel.svg';
import downloadIcon from '../../../images/icons/icon-download.svg';
import checkIcon from '../../../images/icons/icon-check.svg';
import simpleCheckIcon from '../../../images/icons/icon-simple-check.svg';
import refreshIcon from '../../../images/icons/icon-refresh.svg';
import closeIcon from '../../../images/icons/icon-close.svg';
import crossIcon from '../../../images/icons/cross.svg';
import buyIcon from '../../../images/icons/icon-buy.svg';
import sellIcon from '../../../images/icons/icon-sell.svg';
import sendIcon from '../../../images/icons/icon-send.svg';
import receiveIcon from '../../../images/icons/icon-receive.svg';
import depositIcon from '../../../images/icons/icon-deposit.svg';
import withdrawIcon from '../../../images/icons/icon-withdraw.svg';
import copyIcon from '../../../images/icons/icon-copy.svg';
import copyIconV2 from '../../../images/icons/icon-copy-v2.svg';
import starIcon from '../../../images/icons/icon-star.svg';
import stopIcon from '../../../images/icons/icon-stop.svg';
//ap
import logoPowered from '../../../images/icons/logo-powered.svg';
//misc
import notificationIcon from '../../../images/icons/icon-nav-notification.svg';
import centerIcon from '../../../images/icons/icon-center.svg';
import checkboxIcon from '../../../images/icons/icon-checkbox.svg';
import menuToggleIcon from '../../../images/icons/icon-menu-toggle.svg';
import bigKeyIcon from '../../../images/icons/icon-key-big.svg';
import arrowPreviousIcon from '../../../images/icons/icon-arrow-prev.svg';
import arrowNextIcon from '../../../images/icons/icon-arrow-next.svg';
import pendingIcon from '../../../images/icons/icon-pending.svg';
import settingsIcon from '../../../images/icons/icon-settings.svg';
import alertIcon from '../../../images/icons/icon-alert.svg';
import menuIcon from '../../../images/icons/icon-menu.svg';
import likeIcon from '../../../images/icons/like-icon.svg';
import navigationArrowIcon from '../../../images/icons/icon-navigation-arrow.svg';
import searchIcon from '../../../images/icons/search-icon.svg';
import facebook from '../../../images/icons/facebook.svg';
import instagram from '../../../images/icons/instagram.svg';
import linkedIn from '../../../images/icons/linked-in.svg';
import twitter from '../../../images/icons/twitter.svg';
import facebookGradient from '../../../images/icons/facebookGradient.svg';
import instagramGradient from '../../../images/icons/instagramGradient.svg';
import linkedInGradient from '../../../images/icons/linkedInGradient.svg';
import twitterGradient from '../../../images/icons/twitterGradient.svg';
import mailIcon from '../../../images/icons/icon-mail.svg';
import sliderArrow from '../../../images/icons/slider-arrow.svg';
import bellIcon from '../../../images/icons/bell.svg';
import favoriteIcon from '../../../images/icons/icon-favorites.svg';
import favoriteIconSmall from '../../../images/icons/favorite-icon-small.svg';
import activeFavoriteIcon from '../../../images/icons/active-favorite-icon.svg';
import shareIcon from '../../../images/icons/share-icon.svg';
import shareIconSmall from '../../../images/icons/share-icon-small.svg';
import shareIconV2 from '../../../images/icons/icon-share-v2.svg';
import caretPreviousIconMobile from '../../../images/icons/icon-prev-mobile.svg';
import arrowUpIcon from '../../../images/icons/icon-arrow-up.svg';
import navArrowBackIcon from '../../../images/icons/nav-arrow-back-icon.svg';
import navArrowForwardIcon from '../../../images/icons/nav-arrow-forward-icon.svg';
import arrowDownIcon from '../../../images/icons/arrow-down.svg';
import roundedPlusIcon from '../../../images/icons/rounded-plus.svg';
import roundedMinusIcon from '../../../images/icons/rounded-minus.svg';
import instrumentArrowLeftIcon from '../../../images/icons/instrument-arrow-left.svg';
import instrumentArrowRightIcon from '../../../images/icons/instrument-arrow-right.svg';
import assetSearchIcon from '../../../images/icons/asset-search.svg';
import validIcon from '../../../images/icons/valid-icon.svg';
import dashboardWalletIcon from '../../../images/icons/dashboard-wallet.svg';
import dashboardMarketPlaceIcon from '../../../images/icons/dashboard-marketplace.svg';
import dashboardMarketPlaceSmallIcon from '../../../images/icons/dashboard-market-place-white.svg';
import dashboardMyCollectionIcon from '../../../images/icons/dashboard-my-collection.svg';
import dashboardOfferingsIcon from '../../../images/icons/dashboard-offerings.svg';
import dashboardProMarketPlaceIcon from '../../../images/icons/dashboard-pro-market-place.svg';
import viewAllArrowRightIcon from '../../../images/icons/view-all-arrow-right.svg';
import mobileCloseIcon from '../../../images/icons/icon-mobile-close.svg';
import myCollectiblesIcon from '../../../images/icons/my-collectibles-icon.svg';
import mobileSliderArrowIcon from '../../../images/icons/mobile-slider-arrow.svg';
import infoIconSmall from '../../../images/icons/info-icon.svg';
import tierLockIcon from '../../../images/icons/icon-tier-lock.svg';
import leftSliderArrowIcon from '../../../images/icons/left-slider-arrow.svg';
import rightSliderArrowIcon from '../../../images/icons/right-slider-arrow.svg';
import discordIcon from '../../../images/icons/discord-icon.svg';
import buySellUpArrowIcon from '../../../images/icons/buy-sell-up-arrow.svg';
import changeAssetIcon from '../../../images/icons/change-asset-icon.svg';
import watchListIcon from '../../../images/icons/watch-list-icon.svg';
import backIcon from '../../../images/icons/back-icon.svg';
import arrowDropDownIcon from '../../../images/icons/icon-arrow-dropdown.svg';
import communityIcon from '../../../images/icons/community-icon.svg';
import noviceIcon from '../../../images/icons/novice-icon.svg';
import bronzeIcon from '../../../images/icons/bronze-icon.svg';
import silverIcon from '../../../images/icons/silver-icon.svg';
import goldIcon from '../../../images/icons/gold-icon.svg';
import obsidianIcon from '../../../images/icons/obsidian-icon.svg';
import platimunIcon from '../../../images/icons/platinum-icon.svg';
import diamondIcon from '../../../images/icons/diamond-icon.svg';
import blackDiamondIcon from '../../../images/icons/black-diamond-icon.svg';
import grandmasterIcon from '../../../images/icons/grandmaster-icon.svg';
import notificationBellIcon from '../../../images/icons/icon-notification-bell.svg';
import iconModalSuccess from '../../../images/icons/icon-modal-success.svg';
import clocksIcon from '../../../images/icons/clocks-icon.svg';
import voteIcon from '../../../images/icons/vote.svg';
import noviceIconWithBG from '../../../images/icons/novice-icon-with-bg.svg';
import bronzeIconWithBG from '../../../images/icons/bronze-icon-with-bg.svg';
import silverIconWithBG from '../../../images/icons/silver-icon-with-bg.svg';
import goldIconWithBG from '../../../images/icons/gold-icon-with-bg.svg';
import obsidianIconWithBG from '../../../images/icons/obsidian-icon-with-bg.svg';
import platimunIconWithBG from '../../../images/icons/platinum-icon-with-bg.svg';
import diamondIconWithBG from '../../../images/icons/diamond-icon-with-bg.svg';
import blackDiamondIconWithBG from '../../../images/icons/black-diamond-icon-with-bg.svg';
import grandmasterIconWithBG from '../../../images/icons/grandmaster-icon-with-bg.svg';
import bellNotificationIcon from '../../../images/icons/notification.svg';
import globalNotificationIcon from '../../../images/icons/global-notification.svg';
import specificUserNotificationIcon from '../../../images/icons/specific-user-notification.svg';
import reminderNotificationIcon from '../../../images/icons/reminder-notification.svg';
import rewardNotificationIcon from '../../../images/icons/reward-notification.svg';
import unseenIcon from '../../../images/icons/unseen-icon.svg';
import arrowRightIcon from '../../../images/icons/arrow-right.svg';

//cards
import unionpay from '../../../images/icons/unionpay.svg';
import visa from '../../../images/icons/visa.svg';
import mastercard from '../../../images/icons/mastercard.svg';
import jcb from '../../../images/icons/jcb.svg';
import amex from '../../../images/icons/amex.svg';
import discover from '../../../images/icons/discover.svg';
import diners_club from '../../../images/icons/diners_club.svg';

const baseClass = 'ap-icon';

const icons = {
  // navigation
  'buy-sell': buySellIcon,
  dashboard: dashboardIcon,
  activity: iconActivity,
  trading: tradingIcon,
  user: userIcon,
  'user-settings': userSettingsIcon,
  wallet: walletIcon,
  balance: balanceIcon,
  // user-settings
  contacts: contactsIcon,
  key: keyIcon,
  reports: reportsIcon,
  security: securityIcon,
  tasks: tasksIcon,
  heart: heartIcon,
  paint: paintIcon,
  friends: friendsIcon,
  // table
  sendWithBorder: sendWithBorderIcon,
  receiveWithBorder: receiveWithBorderIcon,
  depositWithBorder: depositWithBorderIcon,
  withdrawWithBorder: withdrawWithBorderIcon,
  'open-buy': openBuy,
  'open-sell': openSell,
  //pagination
  caretPrevious: caretPreviousIcon,
  caretNext: caretNextIcon,
  doubleCaretPrevious: doubleCaretPreviousIcon,
  doubleCaretNext: doubleCaretNextIcon,
  // forms
  dropdown: dropdownIcon,
  barcode: barcodeIcon,
  calendar: calendarIcon,
  eye: eyeIcon,
  info: infoIcon,
  cancel: cancelIcon,
  download: downloadIcon,
  check: checkIcon,
  'simple-check': simpleCheckIcon,
  refresh: refreshIcon,
  close: closeIcon,
  cross: crossIcon,
  buy: buyIcon,
  sell: sellIcon,
  receive: receiveIcon,
  send: sendIcon,
  deposit: depositIcon,
  withdraw: withdrawIcon,
  copy: copyIcon,
  copyV2: copyIconV2,
  star: starIcon,
  stop: stopIcon,
  arrowPrevious: arrowPreviousIcon,
  arrowNext: arrowNextIcon,
  //ap
  'logo-powered': logoPowered,
  //misc and possibly unused
  notification: notificationIcon,
  center: centerIcon,
  checkbox: checkboxIcon,
  'menu-toggle': menuToggleIcon,
  bigKey: bigKeyIcon,
  pending: pendingIcon,
  settings: settingsIcon,
  alert: alertIcon,
  menu: menuIcon,
  like: likeIcon,
  navigationArrow: navigationArrowIcon,
  facebook: facebook,
  instagram: instagram,
  linkedIn: linkedIn,
  twitter: twitter,
  facebookGradient: facebookGradient,
  instagramGradient: instagramGradient,
  linkedInGradient: linkedInGradient,
  twitterGradient: twitterGradient,
  search: searchIcon,
  mail: mailIcon,
  sliderArrow: sliderArrow,
  bell: bellIcon,
  favorite: favoriteIcon,
  activeFavorite: activeFavoriteIcon,
  share: shareIcon,
  shareV2: shareIconV2,
  caretPreviousMobile: caretPreviousIconMobile,
  arrowUp: arrowUpIcon,
  navArrowBack: navArrowBackIcon,
  navArrowForward: navArrowForwardIcon,
  arrowDown: arrowDownIcon,
  roundedPlus: roundedPlusIcon,
  roundedMinus: roundedMinusIcon,
  instrumentArrowLeft: instrumentArrowLeftIcon,
  instrumentArrowRight: instrumentArrowRightIcon,
  assetSearch: assetSearchIcon,
  validIcon: validIcon,
  dashboardWallet: dashboardWalletIcon,
  dashboardMarketPlace: dashboardMarketPlaceIcon,
  dashboardMarketPlaceSmall: dashboardMarketPlaceSmallIcon,
  dashboardMyCollection: dashboardMyCollectionIcon,
  dashboardOfferings: dashboardOfferingsIcon,
  dashboardProMarketPlace: dashboardProMarketPlaceIcon,
  viewAllArrowRight: viewAllArrowRightIcon,
  shareSmall: shareIconSmall,
  favoriteSmall: favoriteIconSmall,
  mobileClose: mobileCloseIcon,
  myCollectibles: myCollectiblesIcon,
  mobileSliderArrow: mobileSliderArrowIcon,
  infoIconSmall: infoIconSmall,
  tierLockIcon: tierLockIcon,
  leftSliderArrow: leftSliderArrowIcon,
  rightSliderArrow: rightSliderArrowIcon,
  discord: discordIcon,
  buySellUpArrow: buySellUpArrowIcon,
  changeAsset: changeAssetIcon,
  watchList: watchListIcon,
  back: backIcon,
  arrowDropDown: arrowDropDownIcon,
  community: communityIcon,
  novice: noviceIcon,
  bronze: bronzeIcon,
  silver: silverIcon,
  gold: goldIcon,
  obsidian: obsidianIcon,
  platinum: platimunIcon,
  diamond: diamondIcon,
  blackDiamond: blackDiamondIcon,
  grandmaster: grandmasterIcon,
  notificationBell: notificationBellIcon,
  iconModalSuccess: iconModalSuccess,
  clocks: clocksIcon,
  vote: voteIcon,
  noviceWithBG: noviceIconWithBG,
  bronzeWithBG: bronzeIconWithBG,
  silverWithBG: silverIconWithBG,
  goldWithBG: goldIconWithBG,
  obsidianWithBG: obsidianIconWithBG,
  platinumWithBG: platimunIconWithBG,
  diamondWithBG: diamondIconWithBG,
  blackDiamondWithBG: blackDiamondIconWithBG,
  grandmasterWithBG: grandmasterIconWithBG,
  arrowRight: arrowRightIcon,
  unionpay,
  visa,
  jcb,
  discover,
  mastercard,
  diners_club,
  amex,
  bellNotification: bellNotificationIcon,
  globalNotification: globalNotificationIcon,
  specificUserNotification: specificUserNotificationIcon,
  reminderNotification: reminderNotificationIcon,
  rewardNotification: rewardNotificationIcon,
  unseen: unseenIcon,
};

const APIcon = props => {
  const { name, customClass, classModifiers } = props;
  const modifiers = addModifier(classModifiers, name);
  const bemClasses = getBEMClasses([baseClass, customClass]);
  const icon = icons[name];

  return <SVG src={icon} className={bemClasses(null, modifiers)} />;
};

APIcon.defaultProps = {
  name: '',
  customClass: 'custom-icon',
  classModifiers: '',
  disabled: false
};

APIcon.propTypes = {
  name: PropTypes.string,
  customClass: PropTypes.string,
  classModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  disabled: PropTypes.bool
};

export default APIcon;
