import orderStateSubscriptionService from 'apex-web/lib/services/subscriptions/orderStateSubscriptionService';
import orderTradesSuscriptionService from 'apex-web/lib/services/subscriptions/orderTradesSuscriptionService';
import { getOrderEventSnackbar } from 'apex-web/lib/helpers/placeOrderHelper';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { addOpenOrder, addInactiveOrder, addAccountTrade } from 'apex-web/lib/redux/actions/orderHistoryActions';
import ReplaySubscriptionHelper from '../../helpers/replaySubscriptionHelper';
import { getAvailableTokens } from './cardActions';

export const initOrderHistoryListeners =
  () => (dispatch) => {
    stateSubs.clear();
    tradeSubs.clear();
    stateReplay.reset();
    tradeReplay.reset();
    subscribeToStateEvents(stateReplay.onObserve);
    subscribeToTradeEvents(tradeReplay.onObserve);
    orderStateSubscriptionService.subscribe(stateEvent => {
      handleStateEvent(stateEvent, dispatch);
    });

    orderTradesSuscriptionService.subscribe(tradeEvent => {
      handleTradeEvent(tradeEvent, dispatch);
    });
  };

const handleStateEvent = (stateEvent, dispatch) => {
  stateSubs.forEach(sub => sub(stateEvent));
  if (stateEvent.OrderState === 'Working') {
    dispatch(addOpenOrder(stateEvent));
  } else if (['Rejected', 'Canceled', 'FullyExecuted'].some(i => stateEvent.OrderState === i)) {
    dispatch(addInactiveOrder(stateEvent));
    if (stateEvent.OrderState === 'FullyExecuted') {
      dispatch(getAvailableTokens({
        instrumentIds: [stateEvent.Instrument]
      }));
    }
  }
  handleSnacks(stateEvent, dispatch);
};

const handleSnacks = (stateEvent, dispatch) => {
  const isNotMarketOrder = stateEvent.OrderType !== 'Market';
  const isNotWorkingState = stateEvent.OrderState !== 'Working';
  const isNotCanceledByUser = !(stateEvent.OrderState === 'Canceled' && stateEvent.ChangeReason === 'UserModified');
  // TODO(Feb 07, 2022): ignore 'rejected' as well? if 'rejected' always
  // comes right after 'SendOrder', then it should be safe
  if (
    // ignore market orders
    isNotMarketOrder &&
    // ignore 'working' state, we don't want to display a snack for that
    isNotWorkingState &&
    // ignore canceled by user, its effect is obvious without a snack
    isNotCanceledByUser) {
    const snackBar = getOrderEventSnackbar(stateEvent);
    dispatch(
      showSnack({
        id: 'OrderStateEventSnackbar',
        text: snackBar.text,
        type: snackBar.type,
        textVars: snackBar.textVars
      })
    );
  }
};

const handleTradeEvent = (tradeEvent, dispatch) => {
  dispatch(addAccountTrade(tradeEvent));
  tradeSubs.forEach(sub => sub(tradeEvent));
};

const stateSubs = new Set();
const tradeSubs = new Set();

const stateReplay = new ReplaySubscriptionHelper();
const tradeReplay = new ReplaySubscriptionHelper();

const subTo = (subs, callback) => {
  subs.add(callback);
  return () => subs.delete(callback);
};
const subToReplay = (replay, callback) => replay.subscribe(callback);

export const subscribeToStateEvents = (callback) => subTo(stateSubs, callback);
export const subscribeToTradeEvents = (callback) => subTo(tradeSubs, callback);
export const subscribeToStateEventsReplay = (callback) => subToReplay(stateReplay, callback);
export const subscribeToTradeEventsReplay = (callback) => subToReplay(tradeReplay, callback);
