import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import path from 'apex-web/lib/helpers/path';
import './VerifyEmailPage.css';
import { APTypography } from '../../components/common/APTypography';

const baseClasses = getBEMClasses('verify-email-page');
export default class VerifyEmailComponent extends React.Component {
  componentDidMount() {
    this.props.fetchConfirmEmail();
  }

  render() {
    const {
      context,
      props: { processingVerifyEmail, message, error }
    } = this;

    return (
      <React.Fragment>
        <div className={`${baseClasses('body')}`}>
          <APTypography
            fontSize="body"
            color="grey2"
            weight="weight600"
            className={`${baseClasses('body-text')}`}>
            {context.t(message)}
          </APTypography>
        </div>
        {message && (
          <React.Fragment>
            <div className={`${baseClasses('footer')}`}>
              {error ? (
                <Link
                  to={path('/problem-logging-in')}
                  className={baseClasses('error-btn')}>
                  <APTypography
                    fontWeight="body"
                    color="white4"
                    weight="weight600">
                    {context.t('Problem with verification?')}
                  </APTypography>
                </Link>
              ) : (
                <Link to={path('/login')}>
                  <APTypography
                    fontWeight="body"
                    color="white4"
                    weight="weight600"
                    className={baseClasses('link')}>
                    {context.t('Login')}
                  </APTypography>
                </Link>
              )}
            </div>
          </React.Fragment>
        )}
        <Spinner
          isVisible={processingVerifyEmail}
          text="Verifying your email address"
          customClass={baseClasses('spinner')}
        />
      </React.Fragment>
    );
  }
}

VerifyEmailComponent.defaultProps = {
  fetchConfirmEmail: () => {},
  processingVerifyEmail: true,
  message: '',
  error: false
};

VerifyEmailComponent.propTypes = {
  fetchConfirmEmail: PropTypes.func.isRequired,
  processingVerifyEmail: PropTypes.bool.isRequired,
  message: PropTypes.string,
  error: PropTypes.bool
};

VerifyEmailComponent.contextTypes = {
  t: PropTypes.func
};
