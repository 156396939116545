import React from 'react';

export const HidePasswordIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5334_95309)">
        <path
          d="M7.26634 2.82701C7.72523 2.7196 8.19505 2.6659 8.66634 2.66701C13.333 2.66701 15.9997 8.00034 15.9997 8.00034C15.595 8.75741 15.1124 9.47017 14.5597 10.127M10.0797 9.41368C9.89658 9.61017 9.67577 9.76778 9.43044 9.87709C9.18511 9.9864 8.92028 10.0452 8.65173 10.0499C8.38319 10.0547 8.11645 10.0053 7.86741 9.90467C7.61838 9.80408 7.39216 9.65436 7.20224 9.46444C7.01232 9.27453 6.8626 9.0483 6.76201 8.79927C6.66142 8.55023 6.61203 8.28349 6.61676 8.01495C6.6215 7.74641 6.68028 7.48157 6.78959 7.23624C6.8989 6.99091 7.05651 6.77011 7.25301 6.58701M12.6263 11.9603C11.4867 12.829 10.0991 13.3103 8.66634 13.3337C3.99967 13.3337 1.33301 8.00034 1.33301 8.00034C2.16227 6.45494 3.31243 5.10475 4.70634 4.04034L12.6263 11.9603Z"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.33301 0.666992L15.9997 15.3337"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5334_95309">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.666016)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
