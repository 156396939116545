import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { differenceInMilliseconds, isAfter } from 'date-fns';

import './VoteTimer.css';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import {
  VOTE_STATUSES
} from '../../../constants/votingConstants';

const formateTimeValue = time => {
  if (time < 0) {
    return '00';
  }
  if (time.toString().length === 1) {
    return `0${time}`;
  }
  return time;
};

const calculateTime = (voteStatus, votingStartDate, votingEndDate) => {
  let difference;

  let timeLeft = {
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  };

  if (voteStatus === VOTE_STATUSES.COUNTDOWN) {
    difference = differenceInMilliseconds(votingStartDate, new Date());
  } else if (voteStatus === VOTE_STATUSES.LIVE) {
    difference = differenceInMilliseconds(votingEndDate, new Date());
  } else {
    return;
  }

  timeLeft.days = formateTimeValue(
    Math.floor(difference / (1000 * 60 * 60 * 24))
  );

  difference = differenceInMilliseconds(
    difference,
    timeLeft.days * 1000 * 60 * 60 * 24
  );

  timeLeft.hours = formateTimeValue(
    Math.floor((difference / (1000 * 60 * 60)) % 24)
  );

  difference = differenceInMilliseconds(
    difference,
    timeLeft.hours * 1000 * 60 * 60
  );

  timeLeft.minutes = formateTimeValue(
    Math.floor((difference / 1000 / 60) % 60)
  );

  difference = differenceInMilliseconds(
    difference,
    timeLeft.minutes * 1000 * 60
  );

  timeLeft.seconds = formateTimeValue(Math.floor((difference / 1000) % 60));

  if (voteStatus === VOTE_STATUSES.LIVE) {
    timeLeft.hours = parseInt(timeLeft.hours, 0) + timeLeft.days * 24;
    timeLeft.hours = formateTimeValue(timeLeft.hours);
  }

  /*
  timeLeft = {
    days: formateTimeValue(Math.floor(difference / (1000 * 60 * 60 * 24))),
    hours: formateTimeValue(Math.floor((difference / (1000 * 60 * 60)) % 24)),
    minutes: formateTimeValue(Math.floor((difference / 1000 / 60) % 60)),
    seconds: formateTimeValue(Math.floor((difference / 1000) % 60))
  }; */
  return timeLeft;
};

function tickUpdate({
  voteStatus,
  votingStartDate,
  votingEndDate,
  setVoteClosed,
  changeCardStatus,
  setTimeLeft,
  additionalTickCallback = () => {}
}) {
  if (isAfter(new Date(), votingEndDate) && voteStatus === VOTE_STATUSES.LIVE) {
    setVoteClosed(true);
  } else if (isAfter(new Date(), votingStartDate) && voteStatus === VOTE_STATUSES.COUNTDOWN) {
    changeCardStatus();
  } else {
    setTimeLeft(calculateTime(voteStatus, votingStartDate, votingEndDate));
    return additionalTickCallback();
  }
}

export const VoteTimer = (props, context) => {
  const {
    voteStatus,
    changeCardStatus,
    setVoteClosed,
    resultText,
    voteClosed,
    votingStartDate,
    votingEndDate,
    timer
  } = props;
  const [timeLeft, setTimeLeft] = useState(
    calculateTime(voteStatus, votingStartDate, votingEndDate)
  );
  const classes = getBEMClasses('vote-timer');

  useEffect(
    () => {
      if (
        (voteStatus === VOTE_STATUSES.LIVE ||
          voteStatus === VOTE_STATUSES.COUNTDOWN) &&
        !voteClosed
      ) {
        const tickUpdateParams = {
          voteStatus,
          votingStartDate,
          votingEndDate,
          setVoteClosed,
          changeCardStatus,
          setTimeLeft
        };
        return tickUpdate({
          ...tickUpdateParams,
          additionalTickCallback: () => {
            if (timer) {
              const unsub = timer.subscribe(() => tickUpdate(tickUpdateParams));
              return unsub;
            }
          }
        });
      } else {
        setTimeLeft(undefined);
      }
    },
    [timer, votingStartDate, votingEndDate, voteStatus, voteClosed, changeCardStatus]
  );

  const getTimeLeftString = timeLeft => {
    if (voteStatus === VOTE_STATUSES.COUNTDOWN) {
      if (timeLeft.days >= 1) {
        return timeLeft && `${timeLeft.days} Days ${timeLeft.hours} Hrs`;
      } else {
        return (
          timeLeft &&
          `${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`
        );
      }
    } else {
      return (
        timeLeft && `${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`
      );
    }
  };

  if (resultText) {
    return (
      <div className={classes()}>
        <div className={classes('time')}>{context.t(resultText.title)}</div>
        <div className={classes('remaining')}>
          {context.t(resultText.subtitle)}
        </div>
      </div>
    );
  }

  if (voteClosed) {
    return null;
  }

  if (
    voteStatus === VOTE_STATUSES.COUNTDOWN ||
    voteStatus === VOTE_STATUSES.LIVE
  ) {
    return (
      <div className={classes()}>
        <div className={classes('time')}>{getTimeLeftString(timeLeft)}</div>
        <div className={classes('remaining')}>{context.t('REMAINING')} </div>
      </div>
    );
  }

  return null;
};

VoteTimer.contextTypes = {
  t: PropTypes.func.isRequired
};

VoteTimer.propTypes = {
  voteStatus: PropTypes.string,
  changeCardStatus: PropTypes.func,
  setVoteClosed: PropTypes.func,
  resultText: PropTypes.object,
  voteClosed: PropTypes.bool,
  votingStartDate: PropTypes.instanceOf(Date),
  votingEndDate: PropTypes.instanceOf(Date),
  timer: PropTypes.shape({
    subscribe: PropTypes.func
  })
};
