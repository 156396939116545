import { connect } from 'react-redux';

import { UpcomingOfferings } from '../UpcomingOfferings/UpcomingOfferingsComponent';

import { updateAssetsWatchList } from '../../../redux/actions/cardActions';
import { selectIsAssetInWatchList } from '../../../redux/selectors/cardSelector';
import { selectLiveOffers } from '../../../redux/selectors/productsSelector';
import { selectAssetsBalanceInBaseCurrency } from '../../../redux/selectors/level1Selector';
import { isProductByScriptScenario } from '../../../redux/selectors/productsSelector';
import { withRouter } from 'react-router-dom';
import { openBuySellModal } from '../../../redux/actions/buySellModalActions';

const mapStateToProps = state => {
  const assets = selectAssetsBalanceInBaseCurrency(state);
  const liveProducts = selectLiveOffers(state);

  const res = [];

  liveProducts.forEach(product => {
    const targetProduct = assets.assetsWithPriceAndPercent.find(
      asset => asset.ProductId === product.ProductId
    );
    if (targetProduct) {
      res.push({
        title: product.ProductFullName,
        rarityCategory: product.productAttributes.ProductRarityCategory,
        rarity: product.productAttributes.ProductRarity,
        cardImageURL: product.productAttributes.ProductImageURL,
        productId: product.ProductId,
        isFavorite: selectIsAssetInWatchList(state, product.ProductId),
        soldOut: product.productAttributes.soldOut,
        onlyLimitAvailable: isProductByScriptScenario(state, product.ProductId),
        totalSize: product.productAttributes.ProductTotalSize,
        voteStartDate: product.productAttributes.VoteStartDate
      });
    }
  });

  const checkIsEnoughOnMarket = () => {
    // TODO(Apr 05, 2022): uncomment or remove when we decide whether we need buy/sell limit orders
    // return BigNumber(
    //   approximateQuantitySelector(state, { side, productId })
    // ).gt(0);
    return true;
  };

  if (!res.length) {
    return {
      cards: [],
      isReady: false,
      isLiveOfferings: true,
      availableTokens: state.availableTokens.byProductId
    };
  }
  return {
    cards: res,
    isReady: true,
    isLiveOfferings: true,
    checkIsEnoughOnMarket,
    availableTokens: state.availableTokens.byProductId
  };
};

const mapDispatchToProps = dispatch => ({
  addToFavorite: productId => dispatch(updateAssetsWatchList(productId)),
  makeOrder: props => {
    dispatch(openBuySellModal(props));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpcomingOfferings));
