import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';

export const SET_ACCOUNT_PUBLIC_PROFILE = 'SET_ACCOUNT_PUBLIC_PROFILE';

export const getAccountPublicProfile = payload => async dispatch => {
  const { accountId } = payload;
  dispatch(
    callAPI('GetAccountConfig', {
      AccountId: accountId
    })
  ).then(res => {
    const data = res.find(i => i.Key === 'PublicProfile');

    if (data && data.Value.toLowerCase() === 'false') {
      dispatch(accountPublicProfileReceived({ publicProfile: false }));
    } else {
      dispatch(accountPublicProfileReceived({ publicProfile: true }));
    }
  });
};

export const setAccountPublicProfile = payload => async dispatch => {
  const { accountId, publicProfile } = payload;
  dispatch(
    callAPI('SetAccountConfig', {
      AccountId: accountId,
      Config: [
        {
          Key: 'PublicProfile',
          Value: publicProfile
        }
      ]
    })
  ).then(res => {
    const successMessage =
      publicProfile === true
        ? 'Your profile is public now'
        : 'Your profile is not public now';

    if (res.result) {
      dispatch(
        showSnack({
          id: 'SetPublicProfileSuccess',
          text: successMessage,
          type: 'success'
        })
      );
      dispatch(getAccountPublicProfile({ accountId }));
      return;
    } else {
      dispatch(
        showSnack({
          id: 'SetPublicProfileError',
          text: res.msg,
          type: 'warning'
        })
      );
      return false;
    }
  });
};

export const accountPublicProfileReceived = payload => ({
  type: SET_ACCOUNT_PUBLIC_PROFILE,
  payload
});
