import React from 'react';
import { PropTypes } from 'prop-types';
import { Elements } from '@dots.dev/react-dots-js';

import './DepositFromCard.css';

import { CheckoutForm } from './CheckoutForm';
import DepositWithdrawStatusModalContainer from '../../../../../DepositWithdrawStatusModal/DepositWithdrawStatusModalContainer';
import { dotsPromise } from './DepositFromCard';
import { isDarkTheme } from '../../../../../../helpers/themeHelper';

export const CardDetails = props => {
  const {
    clientSecret,
    closeModal,
    openDepositModal,
    requiredAction,
    recurring,
    frequency,
    amount,
    firstName,
    lastName,
    setCountdownAfterDeposit
  } = props;

  const isDark = isDarkTheme();
  const appearance = {
    theme: isDark ? 'night' : 'stripe',
    variables: {
      colorBackground: 'transparent',
      colorText: isDark ? '#FFFFFF' : '#191B20',
      spacingUnit: '2px',
      borderRadius: '20px'
    },

    rules: {
      '.Input': {
        border: '1px solid #3F8CFF',
        height: '43px',
        padding: '8px 16px',
        backgroundColor: isDark ? '#191B20' : '#FFFFFF'
      }

      // See all supported class names and selector syntax below
    }
  };

  const options = {
    clientSecret,
    appearance,
    loader: 'always'
  };
  return (
    <div>
      <DepositWithdrawStatusModalContainer />
      <Elements options={options} dots={dotsPromise}>
        <CheckoutForm
          openDepositModal={openDepositModal}
          requiredAction={requiredAction}
          closeModal={closeModal}
          clientSecret={clientSecret}
          recurring={recurring}
          frequency={frequency}
          amount={amount}
          firstName={firstName}
          lastName={lastName}
          setCountdownAfterDeposit={setCountdownAfterDeposit}
        />
      </Elements>
    </div>
  );
};

CardDetails.propTypes = {
  clientSecret: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  openDepositModal: PropTypes.func,
  requiredAction: PropTypes.any,
  recurring: PropTypes.any,
  frequency: PropTypes.any,
  amount: PropTypes.any,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  setCountdownAfterDeposit: PropTypes.func
};
