import React, { useState, useCallback, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

import './DepositFromCard.css';
import { createDotsPaymentIntent } from '../../../../../../api/liquidApi';
import { APTypography } from '../../../../../../components/common/APTypography';
import { CheckBox } from '../../../../../common/Checkbox';
import { AgreementStripeModal } from './AgreementModal';
import { AmountNumberInput } from '../../../../../common/AmountNumberInput/AmountNumberInput';
import {
  MODAL_TYPES,
  openModal
} from '../../../../../../redux/actions/modalActions';
import DepositWithdrawStatusModalContainer from '../../../../../DepositWithdrawStatusModal/DepositWithdrawStatusModalContainer';
import { getBEMClasses } from '../../../../../../helpers/cssClassesHelper';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import parseJSON from 'date-fns/parseJSON';

const classes = getBEMClasses('deposit-from-card');

const FIVE_MINUTES = 300000;

const PaymentDetails = (props, context) => {
  const {
    setClientSecret,
    openDepositModal,
    closeModal,
    finalAmount,
    setAmount,
    amount,
    timer,
    depositCountdown
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [countdownTime, setCountdownTime] = useState(null);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(
    () => {
      if (timer && depositCountdown) {
        const now = new Date();
        const depositCountdownDate = parseJSON(depositCountdown);
        const difference = differenceInMilliseconds(now, depositCountdownDate);
        if (difference > 0 && difference < FIVE_MINUTES) {
          setCountdownTime(calcRemainingTime(depositCountdownDate));
          const unsub = timer.subscribe(() => {
            return setCountdownTime(calcRemainingTime(depositCountdownDate));
          });
          return unsub;
        }
      } else {
        setCountdownTime(null);
      }
    },
    [timer, depositCountdown]
  );

  const handleSubmit = useCallback(
    () => {
      createDotsPaymentIntent(finalAmount)
        .then(response => {
          if (response.client_secret) {
            setClientSecret(response.client_secret);
          } else {
            openDepositModal({
              isSuccessful: false,
              closeDepositModal: closeModal
            });
          }
        })
        .catch(() => {
          openDepositModal({
            isSuccessful: false,
            closeDepositModal: closeModal
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [finalAmount]
  );

  const isSubmitDisabled = !agreement || isLoading;

  return (
    <div className={classes()}>
      <div className={classes('amount')}>
        <AmountNumberInput
          amount={amount}
          setAmount={setAmount}
          maxValue={100}
        />
        <div className={classes('agreement')}>
          <CheckBox
            id={'agreement-checkbox'}
            value={agreement}
            customClass={classes('checkbox')}
            handleClick={() => {
              setShowModal(true);
            }}>
            <APTypography
              className={classes('agreement-description')}
              color={'black3'}
              fontSize={'small1'}>
              {context.t('I agree with the user agreement')}
            </APTypography>
          </CheckBox>
        </div>

        {isLoading ? (
          <Spinner customClass={classes('spinner')} />
        ) : (
          <button
            disabled={isSubmitDisabled || countdownTime}
            onClick={() => {
              setIsLoading(true);
              handleSubmit();
            }}
            className={classes('amount-button', {
              disabled: isSubmitDisabled || countdownTime
            })}>
            {countdownTime ? countdownTime : `Pay ${finalAmount / 100} now`}
          </button>
        )}
        <APTypography
          className={classes('fee-warning')}
          color={'black3'}
          fontSize={'small1'}>
          An additional processing fee of 2.9% + 30 cents will be included in
          the final charge.
        </APTypography>

        <AgreementStripeModal
          description={
            <React.Fragment>
              By checking this box, you agree that you are the owner of the
              credit card or have legal right to use the credit card you are
              using and hereby waive any and all rights to claim a charge back
              from the credit card company for any reason.
            </React.Fragment>
          }
          isOpen={showModal}
          close={() => {
            setShowModal(false);
            setAgreement(agreement);
          }}
          handleConfirm={() => {
            setShowModal(false);
            setAgreement(true);
          }}
          handleReject={() => {
            setShowModal(false);
            setAgreement(false);
          }}
        />
        <DepositWithdrawStatusModalContainer />
      </div>
    </div>
  );
};

PaymentDetails.propTypes = {
  setClientSecret: PropTypes.func,
  userId: PropTypes.number.isRequired,
  openDepositModal: PropTypes.func,
  closeModal: PropTypes.func,
  finalAmount: PropTypes.any,
  setAmount: PropTypes.func,
  amount: PropTypes.any,
  timer: PropTypes.any,
  depositCountdown: PropTypes.any
};

PaymentDetails.contextTypes = {
  t: PropTypes.func.isRequired
};

export const PaymentDetailsContainer = connect(
  null,
  dispatch => {
    return {
      openDepositModal: props => {
        dispatch(openModal(MODAL_TYPES.DEPOSIT_WITHDRAW_STATUS_MODAL, props));
      }
    };
  }
)(PaymentDetails);

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

const calcRemainingTime = depositCountdown => {
  const now = new Date();
  const diff = differenceInMilliseconds(now, depositCountdown);
  const left = FIVE_MINUTES - diff;
  let seconds = Math.floor(left / 1000);
  let minutes = Math.floor(seconds / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  if (seconds >= 0 || minutes >= 0) {
    return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
  } else {
    return null;
  }
};
