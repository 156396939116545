import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../../helpers/cssClassesHelper';

import './ContentWithHeaderLayoutTemplate.css';

/**
 * This is layout for following case:
 *  ___________________________
 * | {header}                  |
 * |___________________________|
 * |                           |
 * | {content}                 |
 * |                           |
 * |___________________________|
 */
const ContentWithHeaderLayoutTemplate = props => {
  const { children, customClass, classModifiers, header } = props;

  const baseLayoutTemplateClasses = getBEMClasses('base-lt', customClass);

  return (
    <div
      className={baseLayoutTemplateClasses('layout-template', classModifiers)}>
      <div className={baseLayoutTemplateClasses('header', classModifiers)}>
        {header}
      </div>
      <div className={baseLayoutTemplateClasses('body', classModifiers)}>
        {children}
      </div>
    </div>
  );
};

ContentWithHeaderLayoutTemplate.propTypes = {
  children: PropTypes.any,
  customClass: PropTypes.string,
  classModifiers: PropTypes.arrayOf(PropTypes.string),
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]).isRequired
};

ContentWithHeaderLayoutTemplate.defaultProps = {
  customClass: 'custom-retail-widget'
};

export default ContentWithHeaderLayoutTemplate;
