import { buyValue, orderTypes, sellValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import BigNumber from 'bignumber.js';
import PropTypes from 'prop-types';

export const numberOrBigNumberType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.instanceOf(BigNumber)
]);
export const sideType = PropTypes.oneOf([buyValue, sellValue]);
export const orderTypePropType = PropTypes.oneOf(
  Object.values(orderTypes).map(o => o.displayName)
);

export const buySellFormPropType = PropTypes.shape({
  side: sideType.isRequired,
  orderType: orderTypePropType.isRequired,
  quantity: PropTypes.oneOfType([numberOrBigNumberType, PropTypes.string]).isRequired,
  limitPrice: PropTypes.oneOfType([numberOrBigNumberType, PropTypes.string]),
  stopPrice: PropTypes.oneOfType([numberOrBigNumberType, PropTypes.string]),
});
