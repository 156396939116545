import get from 'lodash/get';
import { RECEIVE_POSITION_EVENT } from "apex-web/lib/redux/actions/positionActions";
import { baseCurrencySelector } from "apex-web/lib/redux/selectors/baseCurrenciesSelectors";
import { updateDCAs } from '../actions/dcaActions';

export const positionMiddleware = store => next => action => {
  if (action.type === RECEIVE_POSITION_EVENT) {
    const baseCurrency = baseCurrencySelector(store.getState());
    const { ProductSymbol } = action.payload;
    if (ProductSymbol !== baseCurrency) {
      const instruments = get(store.getState(), 'apexCore.instrument.instruments', []);
      const instrument = instruments.find(i =>
        i.Product1Symbol === ProductSymbol
      );
      if (instrument) {
        store.dispatch(updateDCAs([instrument.InstrumentId]));
      } else {
        console.warn('No instrument for the changed position. ProductSymbol: ', ProductSymbol);
      }
    }
  }

  return next(action);
};

export default positionMiddleware;
