import React from 'react';
import PropTypes from 'prop-types';
import APIcon from '../APIcon';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './InformationButton.css';

const bemClasses = getBEMClasses('info-button');

const InformationButton = props => {
  const { text, iconColor = '#FFFFFF' } = props;
  return (
    <div className={bemClasses('')} style={{ '--icon-color': iconColor }}>
      <APIcon name="infoIconSmall" customClass={bemClasses('icon')} />
      <div className={bemClasses('icon-text-wrapper')}>{text}</div>
    </div>
  );
};

InformationButton.propTypes = {
  text: PropTypes.string,
  iconColor: PropTypes.string
};

export { InformationButton };
