import React from 'react';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import Logo from './public_page_logo.png';

const classes = getBEMClasses('public-asset-details-header');

const Header = () => {
  return (
    <header className={classes()}>
      <a
        href="https://liquidmarketplace.io"
        className={classes('logo')}>
        <img
          src={Logo}
          alt="Liquid Marketplace logo"
        />
      </a>
    </header>
  );
};

export default Header;
