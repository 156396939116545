import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './VerificationReminderModal.css';

import APIcon from '../common/APIcon';
import { APTypography } from '../common/APTypography';
import { Modal } from '../common/Modal/Modal';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

const classes = getBEMClasses('verification-remind-modal');

const VerificationReminderModalComponent = (props, context) => {
  const { history, isOpened, location } = props;
  const [isModalOpened, setIsModalOpened] = useState(isOpened);

  useEffect(
    () => {
      if (!location.pathname.includes('settings')) {
        setIsModalOpened(isOpened);
      }
    },
    [isOpened, location.pathname]
  );

  const redirectToProfile = () => {
    history.push('/settings/verification-level');
    setIsModalOpened(false);
  };
  const renderHeaderComponent = (
    <div className={classes('header')}>
      <div className={classes('image')} />
      {context.t('Reminder')}
      <div
        className={classes('close-icon-container')}
        onClick={() => setIsModalOpened(false)}>
        {context.t('close')}
        <APIcon name="close" customClass={classes('icon')} />
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={isModalOpened}
      customClass={classes()}
      close={() => {}}
      titleComponent={renderHeaderComponent}>
      <div className={classes('container')}>
        <div className={classes('text')}>
          <APTypography fontSize={'body'} weight={'weight500'}>
            {context.t(
              'To continue using Liquid Marketplace you have to complete your user profile'
            )}
          </APTypography>
        </div>
        <button onClick={redirectToProfile} className={classes('button')}>
          <APTypography fontSize={'body'} weight={'weight500'}>
            {context.t('Complete Profile')}
          </APTypography>
        </button>
      </div>
    </Modal>
  );
};

VerificationReminderModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

VerificationReminderModalComponent.propTypes = {
  history: PropTypes.any,
  isOpened: PropTypes.bool,
  location: PropTypes.any
};

const mapStateToProps = (state, ownProps) => {
  const { isOpened } = ownProps;

  return {
    isOpened
  };
};

export const VerificationReminderModal = withRouter(
  connect(mapStateToProps)(VerificationReminderModalComponent)
);
