import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../../../../../helpers/cssClassesHelper';
import {
  getPremierPayETransfer,
  getExchangeRate
} from '../../../../../../api/liquidApi';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { APTypography } from '../../../../../common/APTypography';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import BigNumber from 'bignumber.js';
import './PremierpayForm.css';
import DepositWithdrawStatusModalContainer from '../../../../../DepositWithdrawStatusModal/DepositWithdrawStatusModalContainer';
import {
  MODAL_TYPES,
  openModal
} from '../../../../../../redux/actions/modalActions';
import { Link } from 'react-router-dom';

const classes = getBEMClasses('deposit-form');

const PremierpayFormElement = (props, context) => {
  const {
    verificationLevel,
    firstName,
    lastName,
    email,
    phone,
    city,
    country,
    zipCode,
    state,
    closeModal,
    openDepositModal
  } = props;

  const [amount, setAmount] = React.useState(0.5);
  const [instructions, setInstructions] = React.useState('');
  const [exchangeRate, setExchangeRate] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    getExchangeRateFromAPI();
  }, []);

  const feeAmount = funds => {
    // If payment amount is less than .50$ then payment will not be proceeded and so we don't calculate any fee yet.
    if (amount < 0.5 || isNaN(funds)) {
      return 0;
    }

    const coefficient = 0.05;
    const fixedFee = 0;

    return new BigNumber(funds)
      .plus(new BigNumber(fixedFee))
      .div(new BigNumber(1).minus(coefficient))
      .minus(funds);
  };

  const finalAmount = () => {
    return +new BigNumber(amount)
      .plus(feeAmount(amount))
      .decimalPlaces(2, 4)
      .toNumber();
  };

  const getExchangeRateFromAPI = async () => {
    try {
      const response = await getExchangeRate();

      setExchangeRate(response.cadRate);
    } catch (error) {
      openDepositModal({
        isSuccessful: false,
        closeDepositModal: closeModal
      });
    }
  };

  const getInstructions = async () => {
    try {
      const amount = finalAmount();
      const response = await getPremierPayETransfer({
        amount: amount
      });

      setInstructions(response.message);
    } catch (error) {
      openDepositModal({
        isSuccessful: false,
        closeDepositModal: closeModal
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (verificationLevel === 0) {
    return (
      <div className={classes('noAccess')}>
        <APTypography
          className={classes('noAccessText')}
          color={'black1'}
          fontSize={'small3'}
          bold>
          You need to be <Link to="/settings/verification-level">verified</Link>{' '}
          in order to make e-transfer payment.
        </APTypography>
      </div>
    );
  }

  if (!isCanadian(country)) {
    return (
      <div className={classes('noAccess')}>
        <APTypography
          className={classes('noAccessText')}
          color={'black1'}
          fontSize={'small3'}
          bold>
          {context.t('E-transfer payment is only available in Canada.')}
        </APTypography>
      </div>
    );
  }

  return (
    <div className={classes()}>
      <div className={classes('amount')}>
        <div className={classes('inputWrapper')}>
          <label htmlFor="eTransferAmount">Amount: </label>
          <input
            type="number"
            value={amount}
            onChange={e => {
              setAmount(e.target.value);
            }}
            id="eTransferAmount"
            className={classes('eTransferInput')}
          />
        </div>
        <div className={classes('inputWrapper')}>
          <label htmlFor="eTransferFirstName">First Name: </label>
          <input
            type="text"
            value={firstName}
            id="eTransferFirstName"
            className={classes('eTransferInput')}
            disabled
          />
        </div>
        <div className={classes('inputWrapper')}>
          <label htmlFor="eTransferLastName">Last Name: </label>
          <input
            type="text"
            value={lastName}
            id="eTransferLastName"
            className={classes('eTransferInput')}
            disabled
          />
        </div>
        <div className={classes('inputWrapper')}>
          <label htmlFor="eTransferEmail">Email: </label>
          <input
            type="text"
            value={email}
            id="eTransferEmail"
            className={classes('eTransferInput')}
            disabled
          />
        </div>
        <div className={classes('inputWrapper')}>
          <label htmlFor="eTransferPhone">Phone: </label>
          <input
            type="text"
            value={phone}
            id="eTransferPhone"
            className={classes('eTransferInput')}
            disabled
          />
        </div>
        <div className={classes('inputWrapper')}>
          <label htmlFor="eTransferState">State: </label>
          <input
            type="text"
            value={state}
            id="eTransferState"
            className={classes('eTransferInput')}
            disabled
          />
        </div>
        <div className={classes('inputWrapper')}>
          <label htmlFor="eTransferCountry">Country: </label>
          <input
            type="text"
            value={country}
            id="eTransferCountry"
            className={classes('eTransferInput')}
            disabled
          />
        </div>
        <div className={classes('inputWrapper')}>
          <label htmlFor="eTransferCity">City: </label>
          <input
            type="text"
            value={city}
            id="eTransferCity"
            className={classes('eTransferInput')}
            disabled
          />
        </div>
        <div className={classes('inputWrapper')}>
          <label htmlFor="eTransferCity">Zip Code: </label>
          <input
            type="text"
            value={zipCode}
            id="eTransferZipCode"
            className={classes('eTransferInput')}
            disabled
          />
        </div>
        <input
          type="submit"
          onClick={() => {
            setIsLoading(true);
            getInstructions();
          }}
          className={classes('amount-button')}
          value={`Pay ${(finalAmount() * +exchangeRate).toFixed(2)} USD now`}
          disabled={!!instructions || isLoading}
        />

        <APTypography
          className={classes('feeWarning')}
          color={'black3'}
          fontSize={'small3'}
          bold>
          {context.t(
            'An additional processing fee of 5.26% will be included in the final charge.'
          )}
        </APTypography>
      </div>

      {isLoading && (
        <div className={classes('spinner')}>
          <Spinner text="loading..." />
        </div>
      )}
      {instructions && (
        <div
          className={classes('instructions')}
          dangerouslySetInnerHTML={{ __html: instructions }}
        />
      )}

      <DepositWithdrawStatusModalContainer />
    </div>
  );
};

PremierpayFormElement.propTypes = {
  verificationLevel: PropTypes.number,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  openDepositModal: PropTypes.func
};

PremierpayFormElement.contextTypes = {
  t: PropTypes.func.isRequired
};

const isCanadian = country => country.toUpperCase() === 'CA';

const findProperConfigValue = (userConfig, key) => {
  try {
    return userConfig.find(elem => {
      return elem.Key === key;
    }).Value;
  } catch (e) {
    // LOG: log error during getting of proper input
    return 'data is missing';
  }
};

export const PremierpayForm = connect(
  state => {
    const { verificationLevel } = kycVerificationLevelsSelector(state);

    const userConfig = state.user.userConfig;

    const phoneNumber = `${findProperConfigValue(
      userConfig,
      'AreaCode'
    )}${findProperConfigValue(userConfig, 'PhoneNumber')}`;

    return {
      firstName: findProperConfigValue(userConfig, 'FirstName'),
      lastName: findProperConfigValue(userConfig, 'LastName'),
      email: findProperConfigValue(userConfig, 'Email'),
      phone: phoneNumber,
      state: findProperConfigValue(userConfig, 'state'),
      country: findProperConfigValue(userConfig, 'Country'),
      city: findProperConfigValue(userConfig, 'city'),
      zipCode: findProperConfigValue(userConfig, 'zipcode'),
      verificationLevel
    };
  },
  dispatch => {
    return {
      openDepositModal: props => {
        dispatch(openModal(MODAL_TYPES.DEPOSIT_WITHDRAW_STATUS_MODAL, props));
      }
    };
  }
)(PremierpayFormElement);
