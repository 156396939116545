import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from '../common/Modal/Modal';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { ListIcon } from '../../images/react-icons/ListIcon';
import { CheckBox } from '../common/Checkbox';
import { openLinkInNewTab } from '../../helpers/linkHelper';
import { setIsTermsOfUseRead } from '../../redux/actions/userActions';
import { modalDecorator } from '../common/Modal/modalDecorator';
import { MODAL_TYPES } from '../../redux/actions/modalActions';
import QuidFailed from '../../images/quid_failure_simple.png';
import './styles.css';
import { useIsTablet } from '../../hooks/useIsMobile';

const classes = getBEMClasses('update-terms-of-use-modal');

const UpdateTermsOfUseModalModalComponent = props => {
  const {
    setIsTermsOfUseRead,
    additionalHandler,
    isOpen,
    close,
    isDeposit
  } = props;
  const [isTermsRead, setIsTermsRead] = useState(false);
  const isTablet = useIsTablet();

  const renderHeaderComponent = (
    <div className={classes('header')}>
      <ListIcon width={isTablet ? 16 : 24} height={isTablet ? 16 : 24} />
      <div className={classes('header-text')}>Updated Terms of Use</div>
    </div>
  );

  const onDeclineClick = () => {
    close();
  };

  const onAcceptClick = () => {
    setIsTermsOfUseRead();
    close();

    if (additionalHandler) {
      additionalHandler();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      customClass={classes()}
      close={close}
      showCloseIcon={false}
      titleComponent={renderHeaderComponent}
      shouldCloseOnOverlayClick={false}>
      <div className={classes('container')}>
        {isDeposit && (
          <img src={QuidFailed} className={classes('quid-img')} alt="failed" />
        )}
        {isDeposit ? (
          <div className={classes('text')}>
            In order to continue depositing funds to the platform please accept
            our new{' '}
            <span
              className={classes('terms')}
              onClick={() => {
                openLinkInNewTab(
                  'https://www.liquidmarketplace.io/terms-of-use'
                );
              }}>
              Term of Use
            </span>{' '}
            here.
          </div>
        ) : (
          <div className={classes('text')}>
            Our terms of use have recently changed. In order to access the
            platform, please review and accept the updated{' '}
            <span
              className={classes('terms')}
              onClick={() => {
                openLinkInNewTab(
                  'https://www.liquidmarketplace.io/terms-of-use'
                );
              }}>
              Term of Use
            </span>.
          </div>
        )}

        <CheckBox
          value={isTermsRead}
          customClass={classes('checkbox')}
          handleChange={e => {
            e.preventDefault();
            setIsTermsRead(!isTermsRead);
          }}
          handleClick={e => {
            e.preventDefault();
            setIsTermsRead(!isTermsRead);
          }}>
          <div className={classes('check-text')}>
            I have read and agree to the updated Terms of Use
          </div>
        </CheckBox>
        <div className={classes('buttons')}>
          <div onClick={onDeclineClick} className={classes('decline')}>
            Decline
          </div>
          <div
            onClick={isTermsRead ? onAcceptClick : () => {}}
            className={classes('accept')}
            style={{ cursor: isTermsRead ? 'pointer' : 'not-allowed' }}>
            Accept
          </div>
        </div>
      </div>
    </Modal>
  );
};

UpdateTermsOfUseModalModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  setIsTermsOfUseRead: PropTypes.func,
  additionalHandler: PropTypes.func,
  isDeposit: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  const { isOpened } = ownProps;

  return {
    isOpened
  };
};

const mapDispatchToProps = {
  setIsTermsOfUseRead
};

export const UpdateTermsOfUseModalModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateTermsOfUseModalModalComponent);

export default modalDecorator({ name: MODAL_TYPES.UPDATE_TERMS_OF_USE_MODAL })(
  UpdateTermsOfUseModalModal
);
