import { connect } from 'react-redux';
import OrderBookComponent from './OrderBookComponent';
import {
  cancelAllOrders,
  cancelBuyOrders,
  cancelSellOrders,
  cancelOrders
} from 'apex-web/lib/redux/actions/orderHistoryActions';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import {
  buyQuantityClicked,
  buyPriceClicked,
  sellQuantityClicked,
  sellPriceClicked
} from 'apex-web/lib/redux/actions/orderEntryActions';
import {
  level2Selector,
  spreadSelector
} from 'apex-web/lib/redux/selectors/level2Selectors';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import {
  openOrdersSelectorFactory,
  buyOpenOrdersSelector,
  sellOpenOrdersSelector
} from 'apex-web/lib/redux/selectors/orderHistorySelectors';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import {
  isProductByDefaultScenario,
  isProductByScriptScenario
} from '../../redux/selectors/productsSelector';
import { lastPriceSelector, realtimeLastPriceSelector } from '../../redux/selectors/instrumentSelector';

const makeMapStateToProps = () => {
  const openOrdersSelector = openOrdersSelectorFactory();

  return state => {
    const selectedInstrument = selectedInstrumentSelector(state);
    const selectedInstrumentId = selectedInstrument.InstrumentId;
    const { fetching } = state.apexCore.level2;
    const { decimalPlaces } = state.apexCore.product;
    const filter = { filterMode: 'selectedInstrument' };
    const level2 = level2Selector(state, filter);
    const openOrders = openOrdersSelector(state, filter);
    const buyOpenOrders = buyOpenOrdersSelector(state, filter);
    const sellOpenOrders = sellOpenOrdersSelector(state, filter);
    const spread = spreadSelector(state, filter);
    const lastPrice =
      realtimeLastPriceSelector(state) ||
      lastPriceSelector(state) ||
      0;

    const convertedQuantityIncrement = convertIncrementToIntDecimalPlaces(
      selectedInstrument.QuantityIncrement
    );
    const convertedPriceIncrement = convertIncrementToIntDecimalPlaces(
      selectedInstrument.PriceIncrement
    );
    const onlyMarketAvailable = isProductByDefaultScenario(
      state,
      selectedInstrument.Product1
    );
    const onlyLimitAvailable = isProductByScriptScenario(
      state,
      selectedInstrument.Product1
    );
    const isSellDisable = onlyMarketAvailable || onlyLimitAvailable;

    return {
      level2,
      spread: formatNumberToLocale(
        spread,
        convertIncrementToIntDecimalPlaces(selectedInstrument.PriceIncrement)
      ),
      decimalPlaces,
      fetching,
      selectedInstrumentId,
      openOrders,
      buyOpenOrders,
      sellOpenOrders,
      selectedInstrument,
      convertedQuantityIncrement,
      convertedPriceIncrement,
      onlyMarketAvailable,
      onlyLimitAvailable,
      isSellDisable,
      lastPrice
    };
  };
};

const mapDispatchToProps = {
  cancelAllOrders,
  cancelBuyOrders,
  cancelSellOrders,
  cancelOrders,
  buyQuantityClicked,
  buyPriceClicked,
  sellQuantityClicked,
  sellPriceClicked
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(OrderBookComponent);
