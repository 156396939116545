const customZendeskId = 'zdWidget';

class ZdWidget {
  constructor(id, iframeTitle) {
    this.id = id;
    this.iframeTitle = iframeTitle;
    this.iframeId = `${id}-iframe`;
  }

  setUpIds = (element) => {
    element.id = this.id;
    const iframe = element.querySelector('iframe');
    if (iframe) iframe.id = this.iframeId;
  };

  findInElements = (elements) => findOneOfZendeskWidgets(elements, this.iframeTitle, this.id);
}

export const zdButton = new ZdWidget(
  `${customZendeskId}__button`, 'Button to launch messaging window'
);
export const zdMessageWindow = new ZdWidget(
  `${customZendeskId}__message-window`, 'Messaging window'
);


// zendesk widget is kinda difficult to identify
const findOneOfZendeskWidgets = (elements, iframeTitle, id) =>
  Array.from(elements).find(el => {
    return (
      el.tagName === 'DIV' &&
      !el.id &&
      !el.classList.length &&
      el.innerHTML.includes('iframe') &&
      el.innerHTML.includes(iframeTitle)
    ) || el.id === id;

  });

const noop = () => { };

const findAndModifyImmediately = (nodes, findZendeskWidget, callback) => {
  const probableZendeskWidget = findZendeskWidget(nodes);
  if (probableZendeskWidget) {
    callback(probableZendeskWidget);
    return true;
  }
  return false;
};

// workaround for the dynamically added zendesk widget
const findAndModify = (findZendeskWidget, callback) => {
  const callbackWithUnsub = (nodes, obs) => {
    const isModified = findAndModifyImmediately(nodes, findZendeskWidget, callback);
    if (isModified) {
      obs.disconnect();
    }
    return isModified;
  };
  const obs = new MutationObserver((mutList, obs) => {
    mutList.some(mutation => callbackWithUnsub(mutation.addedNodes, obs));
  });
  obs.observe(document.body, { childList: true });
  return () => { obs && obs.disconnect(); };
};

export const modifyZendeskWidgetImmediately = (zdWidget, callback) => {
  return findAndModifyImmediately(document.body.children, zdWidget.findInElements, callback);
};

export const modifyZendeskWidget = (zdWidget, callback) => {
  const isModified = modifyZendeskWidgetImmediately(zdWidget, callback);
  if (!isModified) {
    const unsub = findAndModify(zdWidget.findInElements, callback);
    return unsub;
  }
  return noop;
};

export const handleZendeskId = () => {
  modifyZendeskWidget(zdButton, zdButton.setUpIds);
  modifyZendeskWidget(zdMessageWindow, zdMessageWindow.setUpIds);
};

export const handleZendeskZoom = (resetZoomValue) => {
  // cancel out the zoom on the button
  modifyZendeskWidget(zdButton, widget => widget.style.zoom = resetZoomValue);
  // message window seem to work alright with zoom, that's why the code below is 
  // commented out
  // modifyZendeskWidget(zdMessageWindow, widget => widget.style.zoom = resetZoomValue);
};
