import { OPEN_MODAL, CLOSE_MODAL } from '../actions/modalActions';
import { MODAL_TYPES } from '../../redux/actions/modalActions';
import buySellModalReducer from './buySellModalReducer';

const initialState = {};

export default function modalReducer(state = initialState, { type, payload }) {
  switch (type) {
    case OPEN_MODAL:
      return {
        ...state,
        [payload.name]: {
          isOpen: true,
          modalProps: payload.modalProps
        }
      };
    case CLOSE_MODAL:
      return {
        ...state,
        [payload.name]: {
          isOpen: false
        }
      };
    default: {
      // if you need you can add other modal-specific reducers here
      const newBuySellModalState =
        buySellModalReducer(state[MODAL_TYPES.BUY_SELL_MODAL], { type, payload });
      return {
        ...state,
        [MODAL_TYPES.BUY_SELL_MODAL]: newBuySellModalState
      };
    }
  }
}

