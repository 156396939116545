import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import ReferralIcon from '../../../images/icons/icon-referral.svg';
import SVG from 'react-inlinesvg';

const pageHeaderClasses = getBEMClasses('page-header');

export const ReferralButton = (props, context) => {
  const { setMobileNavbarExpanded, openAffiliateModal } = props;
  return (
    <button
      className={pageHeaderClasses('referral-button')}
      onClick={() => {
        setMobileNavbarExpanded(false);
        openAffiliateModal();
      }}>
      <SVG className={pageHeaderClasses('referral-icon')} src={ReferralIcon} />
      {context.t('Refer')}
    </button>
  );
};

ReferralButton.contextTypes = {
  t: PropTypes.func.isRequired
};

ReferralButton.propTypes = {
  setMobileNavbarExpanded: PropTypes.func,
  openAffiliateModal: PropTypes.func
};
