import React from 'react';
import PropTypes from 'prop-types';
import KYCLevelComponent from 'apex-web/lib/components/KYC/KYCLevel';
import {
  getLevel1VerificationProps,
  getLevel2VerificationProps
} from './kycLevelsVerificationPropsHelper';

const KYCLevelsComponent = props => {
  const {
    highlightStyle,
    level1,
    level2,
    levelIncreaseStatus,
    openKYCSidePane,
    verificationLevel
  } = props;

  const level1Props = getLevel1VerificationProps(verificationLevel);
  const level2Props = getLevel2VerificationProps(
    verificationLevel,
    levelIncreaseStatus
  );

  return [
    level1 && (
      <KYCLevelComponent
        key={level1.level}
        verified={true}
        config={level1}
        {...level1Props}
        highlightStyle={highlightStyle}
      />
    ),
    level2 && (
      <KYCLevelComponent
        key={level2.level}
        config={level2}
        {...level2Props}
        openPane={openKYCSidePane}
        highlightStyle={highlightStyle}
      />
    )
  ];
};

KYCLevelsComponent.propTypes = {
  highlightStyle: PropTypes.object,
  level1: PropTypes.object,
  level2: PropTypes.object,
  levelIncreaseStatus: PropTypes.string,
  openKYCSidePane: PropTypes.func,
  verificationLevel: PropTypes.number
};

export default KYCLevelsComponent;
