import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import APRadioTab from '../APRadioTab/APRadioTab';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './APSegmentedButton.css';

const baseClass = 'ap-segmented-button';

const APSegmentedButton = props => {
  const { label, customClass, ...otherProps } = props;

  const bemClasses = getBEMClasses(baseClass, customClass);

  return (
    <div className={bemClasses('sb-container')}>
      {label && (
        <div>
          <label className={classnames('ap--label', bemClasses('group-label'))}>
            {label}
          </label>
        </div>
      )}

      <APRadioTab
        baseClass={baseClass}
        customClass={customClass}
        {...otherProps}
      />
    </div>
  );
};

APSegmentedButton.propTypes = {
  label: PropTypes.string,
  customClass: PropTypes.string
};

export default APSegmentedButton;
