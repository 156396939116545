import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import Logo from '../../images/onboarding-logo.png';
import './styles.css';

const baseClass = getBEMClasses('onboarding-overlay');

export const OnboardingOverlay = (props, context) => {
  const { handleShowOnboarding, handleSkipOnboarding } = props;
  return (
    <div className={baseClass('wrapper')}>
      <div className={baseClass('content-wrapper')}>
        <img src={Logo} alt="logo" className={baseClass('logo')} />
        <div className={baseClass('content')}>
          <div className={baseClass('skip')} onClick={handleSkipOnboarding}>
            {context.t('Skip for Now')} {'>'}
            {'>'}
          </div>
          <div className={baseClass('text-wrapper')}>
            <p>{context.t('Hey! I’m Quid.')}</p>
            <p>{context.t('Let me show you around.')}</p>
          </div>
        </div>

        <div className={baseClass('button')} onClick={handleShowOnboarding}>
          {context.t('Let’s Go')}
        </div>
      </div>
    </div>
  );
};

OnboardingOverlay.contextTypes = {
  t: PropTypes.func.isRequired
};

OnboardingOverlay.propTypes = {
  handleShowOnboarding: PropTypes.func,
  handleSkipOnboarding: PropTypes.func
};
