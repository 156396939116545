import {
  LOAD_STARTUP_DATA,
  startupDataLoaded
} from 'apex-web/lib/redux/actions/appActions';
import { setTranslations } from 'redux-i18n';
import { getProducts } from 'apex-web/lib/redux/actions/productActions';
import { fetchInstruments } from 'apex-web/lib/redux/actions/instrumentActions';
import { getProductManifest } from 'apex-web/lib/redux/actions/productManifestActions';
import { retrieveLanguage } from 'apex-web/lib/redux/actions/languageActions';

export const startupMiddleware = store => next => action => {
  if (action.type === LOAD_STARTUP_DATA) {
    let selectedInstrumentId = Number(
      localStorage.getItem('selectedInstrumentId')
    );
    selectedInstrumentId = selectedInstrumentId
      ? selectedInstrumentId
      : process.env.REACT_APP_IS_PRODUCTION === 'true'
        ? 10
        : 3;

    Promise.all([
      store.dispatch(setTranslations(window.APEXTranslations)),
      store.dispatch(getProducts()),
      store.dispatch(fetchInstruments({ selectedInstrumentId })),
      store.dispatch(getProductManifest())
    ])
      .then(() => {
        store.dispatch(retrieveLanguage());
        store.dispatch(startupDataLoaded());
      })
      .catch(err => console.error(err));
  }

  return next(action);
};

export default startupMiddleware;
