import { useCallback, useRef } from "react";

/**
 * Responds to mounts and unmounts directly, so it doesn’t trigger any re-renders.
 * @param {*} onMount 
 * @param {*} onUnmount 
 * @author Vitali Zaidman
 * {@link https://medium.com/welldone-software/usecallback-might-be-what-you-meant-by-useref-useeffect-773bc0278ae}
 */
export function useRefWithCallback(onMount, onUnmount) {
  const nodeRef = useRef(null);

  const setRef = useCallback(node => {
    if (nodeRef.current) {
      onUnmount(nodeRef.current);
    }
    
    nodeRef.current = node;
    
    if (nodeRef.current) {
      onMount(nodeRef.current);
    }
  }, [onMount, onUnmount]);

  return setRef;
}
