import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectProductsWithAttributes } from '../../../redux/selectors/productsSelector';
import { selectAssetsBalanceInBaseCurrency } from '../../../redux/selectors/level1Selector';
import { baseCurrencySelector } from 'apex-web/lib/redux/selectors/baseCurrenciesSelectors';
import { DashboardMyAssets } from './DashboardMyAssetsComponent';
import resize from 'apex-web/lib/hocs/resize';
import { validateImagesUrl } from '../../../helpers/validateImagesUrl';
import { openBuySellModal } from '../../../redux/actions/buySellModalActions';

const mapStateToProps = state => {
  const products = selectProductsWithAttributes(state);
  const assets = selectAssetsBalanceInBaseCurrency(state);
  const baseCurrency = baseCurrencySelector(state);
  let digitalProducts = products.filter(
    product => Object.values(product.productAttributes).length
  );

  digitalProducts = validateImagesUrl(digitalProducts, [
    'ProductImageURL',
    'ProductPreviewURL'
  ]);
  const res = [];
  digitalProducts.forEach(product => {
    if (
      product.productAttributes.ProductImageSlide &&
      product.productAttributes.ProductPreviewURL &&
      product.productAttributes.ProductColor
    ) {
      const targetProduct = assets.assetsWithPriceAndPercent.find(
        asset => asset.ProductId === product.ProductId
      );
      const productProperties = [];
      Object.entries(product.productAttributes).forEach(entry => {
        if (entry[0].toLowerCase().startsWith('property')) {
          productProperties.push({
            [entry[0].slice(8).replace('_', ' ')]: entry[1]
          });
        }
      });
      if (targetProduct && targetProduct.Amount > 0) {
        res.push({
          productName: product.ProductFullName,
          productImageSlide: product.productAttributes.ProductImageSlide,
          productPreviewURL: product.productAttributes.ProductPreviewURL,
          productColor: product.productAttributes.ProductColor,
          productTextColor: product.productAttributes.ProductTextColor,
          productPrice:
            targetProduct && targetProduct.BestOffer
              ? targetProduct.BestOffer
              : '--',
          productGrowth:
            targetProduct && targetProduct.Rolling24HrPxChangePercent
              ? targetProduct.Rolling24HrPxChangePercent
              : 0,
          productProperties: productProperties,
          productId: product.ProductId,
          soldOut: product.productAttributes.soldOut,
          scenarioType: product.productAttributes.scenarioType
        });
      }
    }
  });
  const checkIsEnoughOnMarket = () => {
    // TODO(Apr 05, 2022): uncomment or remove when we decide whether we need buy/sell limit orders
    // return BigNumber(approximateQuantitySelector(state, { side, productId })).gt(0);
    return true;
  };
  return {
    ...getResRelatedProps(res),
    baseCurrency,
    checkIsEnoughOnMarket
  };
};

const getResRelatedProps = (res) => !res.length ?
  { items: [], isReady: false } :
  { items: res, isReady: true };

const mapDispatchToProps = dispatch => ({
  makeOrder: props => {
    dispatch(openBuySellModal(props));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(resize(DashboardMyAssets))
);
