import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import QuidSuccessAnimated from '../../../images/quid_success_animated.gif';
import './SuccessfulOrder.css';
import { numberOrBigNumberType } from '../buySellFormPropType';
import { isPrimaryMarketInstrumentSelector } from '../../../redux/selectors/instrumentSelector';

const classes = getBEMClasses('successful-order');

const SuccessfulOrder = (props, context) => {
  const { confirmedOrder: { exp }, isPrimaryMarketInstrument } = props;
  return (
    <React.Fragment>
      <div className={classes('container')}
        data-test='successRoot'>
        <div className={classes('title')}>
          {context.t('Congratulations! Your transaction is completed!')}
        </div>
        <div className={classes('xp-container')}>
          <img className={classes('xp-image')}
            src={QuidSuccessAnimated}
            alt='' />
          <div className={classes('xp-text-container')}>
            <div className={classes('xp-label')}>
              {context.t(isPrimaryMarketInstrument ? 'Rewards To Be Earned' : 'Experience Points Earned')}:
            </div>
            <div className={classes('xp-value')}>{`
            ${formatNumberToLocale(exp, 0)} ${context.t('XP')}
            `}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SuccessfulOrder.propTypes = {
  confirmedOrder: PropTypes.shape({
    exp: PropTypes.oneOfType([numberOrBigNumberType, PropTypes.string]).isRequired
  }).isRequired,
  isPrimaryMarketInstrument: PropTypes.bool.isRequired
};

SuccessfulOrder.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect((state, { currentInstrument }) => ({
  isPrimaryMarketInstrument: isPrimaryMarketInstrumentSelector(
    state, { instrumentId: currentInstrument.InstrumentId }
  )
}))(SuccessfulOrder);
