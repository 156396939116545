import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Logo from '../../../images/icon-logo-big.png';
import { APSection } from '../../../components/common/APSection';
import { SectionPresetSort } from '../../../components/common/SectionPresetSort';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import useAssetMapper from '../useAssetMapper';
import './MyCollectiblesBaseSection.css';

const classes = getBEMClasses('my-collectibles-base-section');

const MyCollectiblesBaseSection = (props) => {
  const {
    title,
    noAssetsPlaceholderText,
    sortOptions = [
      {
        value: 'ProductFullName',
        label: 'Name',
      },
      {
        value: 'Rolling24HrPxChangePercent',
        label: 'Growth',
        isAscendingOption: false
      },
      {
        value: 'BestBid',
        label: 'Price',
        isAscendingOption: false
      }
    ],
    assets,
    baseCurrency,
    makeOrder,
    history
  } = props;

  const [isAscending, setIsAscending] = useState(true);
  const [currentSortOption, setCurrentSortOption] = useState('');

  const items = useAssetMapper(
    assets,
    currentSortOption,
    isAscending,
    { baseCurrency, makeOrder, history }
  );

  const mappedSortOptions = sortOptions.map(
    ({ value, label, isAscendingOption = true }) => ({
      value,
      label,
      onClick: v => {
        setIsAscending(isAscendingOption);
        setCurrentSortOption(v);
      }
    }));

  return items.length ? (
    <div className={classes()}>
      <SectionPresetSort
        title={title}
        sortOptions={mappedSortOptions}
        currentSortOption={currentSortOption}>
        <div className={classes('content')}>
          <div className={classes('sectionWrapper')}>{items}</div>
        </div>
      </SectionPresetSort>
    </div>
  ) : (
    noAssetsPlaceholderText ? (
      <div className={classes()}>
        <APSection title={title}>
          <div className={classes('no-assets-wrapper')}>
            <img
              src={Logo}
              className={classes('no-assets-logo')}
              alt="logo"
            />
            <div className={classes('no-assets-text')}>
              {noAssetsPlaceholderText}
            </div>
          </div>
        </APSection>
      </div>
    ) :
      null
  );
};

MyCollectiblesBaseSection.propTypes = {
  title: PropTypes.string.isRequired,
  noAssetsPlaceholderText: PropTypes.string,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      isAscendingOption: PropTypes.bool
    })
  ),
  assets: PropTypes.arrayOf(PropTypes.object),
  baseCurrency: PropTypes.string.isRequired,
  makeOrder: PropTypes.func,
  history: PropTypes.object
};

export default MyCollectiblesBaseSection;
