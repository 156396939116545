import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import APIcon from '../APIcon/APIcon';
import './Select.css';

const baseClass = 'select';

const Select = (props, context) => {
  const {
    options,
    currentOption = '',
    title = 'Sort By',
    customClass,
    onSelect
  } = props;
  const classes = getBEMClasses(baseClass, customClass);
  const selectOption = options.find(
    option => option.value === currentOption
  ) || { value: title, label: title };
  const [isShowList, setIsShowList] = useState(false);
  return (
    <div
      className={classes('wrapper', isShowList && 'with-list')}
      onBlur={() => {
        setIsShowList(false);
      }}
      tabIndex={0}
      data-test="select"
      onClick={() => {
        setIsShowList(!isShowList);
      }}>
      <div className={classes('select', isShowList && 'active')}>
        {selectOption.label}
      </div>
      <APIcon
        name="arrowDropDown"
        customClass={classes('arrow', isShowList && 'active')}
      />
      {isShowList && (
        <div className={classes('list-wrapper')}>
          <div className={classes('list-inner-wrapper')}>
            {options.map((option, index) => (
              <React.Fragment key={option.value}>
                <div
                  className={classes(
                    'list-item',
                    selectOption.value === option.value && 'active'
                  )}
                  key={option.value}
                  data-test={`test-id-${option.value}`}
                  onClick={() => {
                    onSelect
                      ? onSelect(option.value)
                      : option.onClick(option.value);
                    setIsShowList(false);
                  }}>
                  {context.t(option.label)}
                </div>
                {index < options.length - 1 && (
                  <div
                    className={classes(
                      'list-item-divider',
                      selectOption.value === option.value && 'active'
                    )}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Select.contextTypes = {
  t: PropTypes.func.isRequired
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  ),
  currentOption: PropTypes.string,
  title: PropTypes.string,
  customClass: PropTypes.string,
  onSelect: PropTypes.func
};

export { Select };
