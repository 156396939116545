import { connect } from 'react-redux';
import { getDepositTickets } from 'apex-web/lib/redux/actions/depositActions';
import { getWithdrawTickets } from 'apex-web/lib/redux/actions/withdrawActions';
import { getAccountTrades, getOpenOrders } from 'apex-web/lib/redux/actions/orderHistoryActions';
import { WalletsTransactionHistory } from './WalletsTransactionHistory';
import { newRecentActivitySelector } from '../../../redux/selectors/recentActivitySelector';
import get from 'lodash/get';
import chunk from 'lodash/chunk';
import { getLedgerEntries } from '../../../redux/actions/ledgerActions';
import BigNumber from 'bignumber.js';
import { createSelector } from 'reselect';
import { openOrdersSelector } from 'apex-web/lib/redux/selectors/orderHistorySelectors';
import { getSubscriptions } from '../../../redux/actions/subscriptionActions';

const REFUND_LEDGER_ENTRY_COMMENTS = [
  'Return dollars to buyer',
  'Refund fees'
];

const mapStateToProps = state => {
  const data = newRecentActivitySelector(state);
  const usdDecimalPlaces = get(state, 'product.decimalPlaces.USD', 2)
  const { fetching } = state.transfer
  return {
    data,
    selectedAccountId: get(state, 'user.selectedAccountId', 0),
    depositTickets: get(state, 'deposit.depositsStatus'),
    ledgerEntries: ledgerEntriesSelector(state, { usdDecimalPlaces }),
    withdrawTickets: get(state, 'withdraw.withdrawTickets'),
    tradesTickets: get(state, 'orderHistory.accountTrades'),
    openOrders: mappedOpenOrdersSelector(state, { usdDecimalPlaces }),
    apexInstruments: get(state, 'instrument.instruments'),
    apexProducts: get(state, 'product.products'),
    subscription: state.subscription,
    usdDecimalPlaces,
    userId: state.user.userInfo.UserId,
    ticketsFetching: fetching
  };
};

const mappedOpenOrdersSelector = createSelector(
  [
    openOrdersSelector,
    (state, options) => options.usdDecimalPlaces
  ],
  (openOrders, usdDecimalPlaces) => {
    if (!openOrders.length) {
      return [];
    }
    return openOrders.map(order => ({
      IsOpenOrder: true,
      Side: order.Side,
      Quantity: order.Quantity,
      InstrumentId: order.Instrument,
      NotionalValue: BigNumber(order.tralilingPrice || order.Price || 0)
        .times(order.Quantity)
        .dp(usdDecimalPlaces, BigNumber.ROUND_HALF_UP)
        .toNumber(),
      FeeAmt: 'PENDING',
      TradeTimeMS: order.LastUpdatedTime,
      classModifier: 'highlighted',
    }));
  }
);

const ledgerEntriesSelector = createSelector(
  [
    state => get(state, 'ledger.entries', []),
    (state, options) => options.usdDecimalPlaces
  ],
  (entries, usdDecimalPlaces) => {
    if (!entries.length) {
      return [];
    }
    // group refund entries by two (fee and main)
    return chunk(getRefundLedgerEntries(entries), REFUND_LEDGER_ENTRY_COMMENTS.length)
      .map(([entryFee, entryMain]) => ({
        AssetName: 'US Dollar',
        // the second entry of the pair may be undefined if you get the data at 
        // the wrong time (though it's unlikely)
        Amount: BigNumber(entryFee.CR_Amt + (entryMain ? entryMain.CR_Amt : 0))
          .dp(usdDecimalPlaces, BigNumber.ROUND_HALF_UP)
          .toNumber(),
        CreatedTimestamp: new Date(entryFee.TimeStamp).toUTCString(),
        Status: 'Refund',
      }));
  });

const getRefundLedgerEntries = entries => entries.filter(entry =>
  entry.ProductId === 1 && // Refund is always in USD
  // Refund always has one of specific comments
  entry.Comments.length === 1 && REFUND_LEDGER_ENTRY_COMMENTS.includes(entry.Comments[0])
);

const mapDispatchToProps = {
  getLedgerEntries: (params) => getLedgerEntries(params),
  getDepositTickets: limit => getDepositTickets(limit),
  getWithdrawTickets: (...params) => getWithdrawTickets(...params),
  getAccountTrades: (...params) => getAccountTrades(...params),
  getOpenOrders: (...params) => getOpenOrders(...params),
  getSubscriptions: () => getSubscriptions(),
};

const WalletsTransactionHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletsTransactionHistory);

export { WalletsTransactionHistoryContainer };
