import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import APTable from '../../common/APTable/APTable';
import CancelAPButton from 'apex-web/lib/components/common/CancelAPButton';

import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

const baseClass = getBEMClasses('order-history-table');

export const WithdrawStatusComponent = (props, context) => {
  const { data, decimalPlaces, config, cancelWithdrawTicket } = props;
  const { maxLines, usePagination } = config;

  useEffect(
    () => {
      props.getWithdrawTickets(
        props.selectedAccountId,
        props.OMSId,
        props.config.maxLines,
        0
      );
    },
    [props.selectedAccountId]
  );

  const columns = [
    {
      header: context.t('Product'),
      dataTest: 'Product',
      cell: row => row.AssetName
    },
    {
      header: context.t('Amount'),
      dataTest: 'Amount',
      cell: row => formatNumberToLocale(row.Amount, decimalPlaces[row.AssetId])
    },
    {
      header: context.t('Status'),
      dataTest: 'Status',
      cell: row => context.t(row.Status)
    },
    {
      header: context.t('Created'),
      dataTest: 'Created',
      cell: row => formatDateTime(row.CreatedTimestamp)
    },
    {
      header: context.t('Fee'),
      dataTest: 'Fee',
      cell: row => row.FeeAmt
    },
    {
      header: context.t('Actions'),
      dataTest: 'Actions',
      cell: row =>
        ['Pending', 'Pending2Fa'].includes(row.Status) ? (
          <CancelAPButton
            customClass={baseClass()}
            text={context.t('Cancel')}
            onClick={() => cancelWithdrawTicket(row)}
          />
        ) : (
          ''
        )
    }
  ];

  return (
    <APTable
      {...{
        columns,
        rows: data,
        baseClass,
        totalPages: data.length,
        pageSize: maxLines,
        usePagination,
        alwaysShowPagination: true,
        useSimplePagination: true,
        empty: context.t('No data is available'),
        componentName: 'WithdrawStatusComponent',
        onRequestPrev: () => {
          props.getWithdrawTickets(
            props.selectedAccountId,
            props.OMSId,
            props.config.maxLines,
            props.withdrawTicketsStartIndex - props.config.maxLines
          );
        },
        onRequestNext: () => {
          props.getWithdrawTickets(
            props.selectedAccountId,
            props.OMSId,
            props.config.maxLines,
            props.withdrawTicketsStartIndex + props.config.maxLines
          );
        }
      }}
    />
  );
};

/*
 this.context.t('Withdraw Status');
 */
WithdrawStatusComponent.title = 'Withdraw Status';

WithdrawStatusComponent.propTypes = {
  data: PropTypes.array.isRequired,
  decimalPlaces: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  cancelWithdrawTicket: PropTypes.func.isRequired,
  withdrawTicketsStartIndex: PropTypes.number,
  selectedAccountId: PropTypes.number,
  OMSId: PropTypes.number,
  getWithdrawTickets: PropTypes.func
};

WithdrawStatusComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default WithdrawStatusComponent;
