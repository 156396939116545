import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import { enable2FA } from 'apex-web/lib/redux/actions/userActions';

import Enable2FAModalComponent from './Enable2FAModal';

const mapStateToProps = state => ({
  GoogleQRCode: state.user.qrCode.ManualCode,
  userName: state.user.userInfo.UserName
});

const mapDispatchToProps = {
  changeFieldValue: change
};

const containerForm = reduxForm({
  form: 'enable2FAModal',
  onSubmit: (payload, dispatch) => dispatch(enable2FA(payload.code)),
  onChange: (values, dispatch, props) => {
    if (values.code && values.code.length === 6) {
      props.submit();
    }
  }
})(Enable2FAModalComponent);

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(containerForm);

export default modalDecorator({
  name: MODAL_TYPES.ENABLE_2FA
})(container);
