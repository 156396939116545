import React from 'react';
import PropTypes from 'prop-types';
import APIcon from '../APIcon';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { isLightTheme } from '../../../helpers/themeHelper';
import sliderArrowBGLight from '../../../images/slider-arrow-light-bg.png';
import sliderArrowBGDark from '../../../images/slider-arrow-dark-bg.png';
import './styles.css';

const baseClasses = getBEMClasses('slider-arrows');

export const NextSliderArrow = props => {
  const {
    className,
    onClick,
    iconName,
    useShadows = false,
    customIconClass
  } = props;
  return (
    <div className={className} onClick={onClick}>
      {useShadows && (
        <img
          className={baseClasses('next-arrow-bg')}
          src={isLightTheme() ? sliderArrowBGLight : sliderArrowBGDark}
          alt="slider-next-arrow"
        />
      )}
      <APIcon name={iconName} customClass={customIconClass} />
    </div>
  );
};

NextSliderArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  useShadows: PropTypes.bool,
  customIconClass: PropTypes.string
};

export const PrevSliderArrow = props => {
  const {
    className,
    onClick,
    iconName,
    useShadow = false,
    customIconClass
  } = props;
  return (
    <div className={className} onClick={onClick}>
      {useShadow && (
        <img
          className={baseClasses('prev-arrow-bg')}
          src={isLightTheme() ? sliderArrowBGLight : sliderArrowBGDark}
          alt="slider-prev-arrow"
        />
      )}
      <APIcon name={iconName} customClass={customIconClass} />
    </div>
  );
};

PrevSliderArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  useShadow: PropTypes.bool,
  customIconClass: PropTypes.string
};

export const NextCustomSliderArrow = props => {
  const { className, iconName = 'rightSliderArrow', ...buttonProps } = props;
  return (
    <div className={className}>
      <button {...buttonProps}>
        <APIcon name={iconName} />
      </button>
    </div>
  );
};

NextCustomSliderArrow.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string
};

export const PrevCustomSliderArrow = props => {
  const { className, iconName = 'leftSliderArrow', ...buttonProps } = props;
  return (
    <div className={className}>
      <button {...buttonProps}>
        <APIcon name={iconName} />
      </button>
    </div>
  );
};

PrevCustomSliderArrow.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string
};
