import { SET_SUBSCRIPTIONS, UPDATE_SUBSCRIPTIONS_REQUEST_STATUS } from "../actions/subscriptionActions";

const initialState = {
  fetching: false
};

const subscriptionReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_SUBSCRIPTIONS_REQUEST_STATUS:
      return {
        ...state,
        fetching: payload.isFetching
      };
    case SET_SUBSCRIPTIONS: {
      return {
        ...state,
        ...payload
      };
    }
    default:
      return state;
  }
};

export default subscriptionReducers;
