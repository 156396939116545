import { connect } from 'react-redux';
import VerifyEmailComponent from './VerifyEmailComponent';
import { fetchConfirmEmail } from 'apex-web/lib/redux/actions/verifyEmailActions';
import { getQueryParam } from 'apex-web/lib/helpers/queryParamsHelper';

const mapStateToProps = state => {
  const {
    apiProcessing: { processingVerifyEmail },
    verifyEmail: { message, error }
  } = state;
  return {
    processingVerifyEmail,
    message,
    error
  };
};

const mapDispatchToProps = dispatch => {
  const d1 = getQueryParam('d1');
  const userid = getQueryParam('UserId');
  const verifycode = getQueryParam('verifycode');
  return {
    fetchConfirmEmail: () =>
      dispatch(fetchConfirmEmail({ verifycode, userid, d1 }))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmailComponent);
