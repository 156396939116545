import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import {
  buyValue,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { Slider3D } from '../../../components/common/Slider3D';
import GrowthArrow from '../../../images/icons/icon-up-arrow.svg';
import Logo from '../../../images/icon-logo-big.png';
import { MyAssetsImagePreview } from './MyAssetsImagePreview/MyAssetsImagePreview';
import SectionHeader from '../../../components/common/SectionHeader/SectionHeader';
import { UrlFactory } from '../../../helpers/urlFactory';
import { BuyButton, SellButton } from '../../../components/common/Buttons';
import { checkIsCorrectAssetAttribute } from '../../../redux/selectors/productsSelector';

import './DashboardMyAssets.css';

const classes = getBEMClasses('dashboard-my-assets');

const mobileSliderProperties = {
  offsetXStep: 120,
  rotateAngle: -10,
  fadeStep: 1
};

export const DashboardMyAssets = (props, context) => {
  const { items, isReady, width, baseCurrency, makeOrder, history } = props;
  const isMobile = width <= 576;
  if (!isReady) {
    return (
      <div className={classes()}>
        <SectionHeader
          title={`${context.t('My Collectibles')} (${items.length})`}
          link="/my-collectibles"
          backgroundColor="#C852B3"
          viewAllBackgroundColor="#963A85"
          iconName="myCollectibles"
        />
        <div className={classes('no-assets-wrapper')}>
          <img src={Logo} className={classes('no-assets-logo')} alt="logo" />
          <div className={classes('no-assets-text')}>
            {context.t('No Collectible')}
          </div>
        </div>
      </div>
    );
  }
  const [imagesArray, setImagesArray] = useState([]);
  const [currentItemImage, setCurrentItemImage] = useState(
    items[0].productImageSlide
  );
  const [currentItem, setCurrentItem] = useState(
    items.find(item => item.productImageSlide === currentItemImage)
  );

  useEffect(
    () => {
      setCurrentItem(
        items.find(item => item.productImageSlide === currentItemImage)
      );
    },
    [currentItemImage]
  );

  useEffect(
    () => {
      setImagesArray(items.map(item => item.productImageSlide));
    },
    [items]
  );

  useEffect(() => {
    // Pre-loads every image and it's preview image
    items.forEach(item => {
      const imgPreview = new Image();
      imgPreview.src = item.productPreviewURL;
      const imgCover = new Image();
      imgCover.src = item.productImageSlide;
    });
  }, []);

  const sliderAdditionalProperties = isMobile
    ? { ...mobileSliderProperties }
    : {};
  const isProductByDefaultScenario =
    checkIsCorrectAssetAttribute(currentItem.soldOut, 'false') &&
    checkIsCorrectAssetAttribute(currentItem.scenarioType, 'default');
  const isProductByScriptScenario =
    checkIsCorrectAssetAttribute(currentItem.soldOut, 'false') &&
    checkIsCorrectAssetAttribute(currentItem.scenarioType, 'script');
  const isSellDisable = isProductByDefaultScenario || isProductByScriptScenario;

  const onBuySellCallback = side => {
    makeOrder({
      productId: currentItem.productId,
      side,
      onlyLimitAvailable:
        isProductByScriptScenario ||
        !props.checkIsEnoughOnMarket(side, currentItem.productId)
    });
  };

  const onSelectedAssetPress = useCallback(
    () => {
      history.push(
        UrlFactory.getMarketPlaceAssetDetailsUrl(currentItem.productId)
      );
    },
    [currentItem.productId]
  );

  return (
    <div className={classes()}>
      <SectionHeader
        title={`${context.t('My Collectibles')} (${items.length})`}
        link="/my-collectibles"
        backgroundColor="#C852B3"
        viewAllBackgroundColor="#963A85"
        iconName="myCollectibles"
      />
      <div className={classes('content')}>
        <div
          className={classes('cardPreview')}
          style={{ backgroundColor: currentItem.productColor }}>
          <div
            className={classes('productName')}
            style={{ color: currentItem.productTextColor }}>
            {currentItem.productName}
          </div>
          <div className={classes('prices')}>
            <div className={classes('priceUSD')}>
              {isMobile ? '$' : baseCurrency} {currentItem.productPrice}
            </div>
            <div
              className={classNames({
                [classes('growth')]: true,
                [classes('growthDown')]: currentItem.productGrowth < 0
              })}>
              <img
                src={GrowthArrow}
                className={classNames({
                  [classes('growthArrowDown')]: currentItem.productGrowth < 0
                })}
                alt="growth arrow"
              />
              <span>{currentItem.productGrowth.toFixed(2)} %</span>
            </div>
          </div>
          <div className={classes('properties')}>
            {currentItem.productProperties.map(property => (
              <div
                key={Object.values(property)[0]}
                className={classes('property')}>
                <span className={classes('propertyKey')}>
                  {Object.keys(property)[0]}
                </span>
                {' = '}
                <span className={classes('propertyValue')}>
                  {Object.values(property)[0]}
                </span>
              </div>
            ))}
          </div>
          <div className={classes('previewFooter')}>
            {!isMobile && (
              <div className={classes('buttons')}>
                <BuyButton onClick={() => onBuySellCallback(buyValue)} />
                <SellButton
                  data-tip="You can make only buy order while primary order available"
                  data-tip-disable={!isSellDisable}
                  isDisabled={isSellDisable}
                  onClick={
                    isSellDisable
                      ? () => {}
                      : () => onBuySellCallback(sellValue)
                  }
                />
              </div>
            )}
          </div>
          <MyAssetsImagePreview image={currentItem.productPreviewURL} />
        </div>
        <div className={classes('slider')}>
          <div
            className={classes('sliderWrapper')}
            style={{
              backgroundImage: !isMobile ? `url(${Logo})` : 'none',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right'
            }}>
            <Slider3D
              items={imagesArray}
              baseClass={classes}
              onChange={setCurrentItemImage}
              onSelectedItemPress={onSelectedAssetPress}
              {...sliderAdditionalProperties}
            />
            {isMobile && (
              <div className={classes('buttons')}>
                <BuyButton onClick={() => onBuySellCallback(buyValue)} />
                <SellButton
                  data-tip="You can make only buy order while primary order available"
                  data-tip-disable={!isSellDisable}
                  isDisabled={isSellDisable}
                  onClick={
                    isSellDisable
                      ? () => {}
                      : () => onBuySellCallback(sellValue)
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
};

DashboardMyAssets.contextTypes = {
  t: PropTypes.func.isRequired
};

DashboardMyAssets.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      productImageSlide: PropTypes.string.isRequired,
      productPreviewURL: PropTypes.string.isRequired,
      productColor: PropTypes.string.isRequired,
      productTextColor: PropTypes.string,
      productName: PropTypes.string.isRequired,
      productPrice: PropTypes.string.isRequired,
      productGrowth: PropTypes.number.isRequired,
      productProperties: PropTypes.arrayOf(PropTypes.object)
    })
  ),
  baseCurrency: PropTypes.string.isRequired,
  isReady: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  checkIsEnoughOnMarket: PropTypes.func.isRequired,
  makeOrder: PropTypes.func,
  history: PropTypes.object
};
