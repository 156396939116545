import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'redux-form';
import { APInputWithReduxForm } from '../../../components/common/APInputWithReduxForm';
import APButton from 'apex-web/lib/components/common/APButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import APRadio from 'apex-web/lib/components/common/APRadio/APRadio';
import { required, email } from 'apex-web/lib/helpers/formValidations';
import './SimpleProblemLoggingInForm.css';
import { APTypography } from '../../../components/common/APTypography';

const simpleProblemLoggingInFormClasses = getBEMClasses('forgot-password-form');

export class SimpleProblemLoggingInFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { resetPassword: true };
  }

  toggleFormType = () =>
    this.setState({ resetPassword: !this.state.resetPassword });

  sendEmail = values => {
    const { sendResetPasswordEmail, resendVerificationEmail } = this.props;

    if (this.state.resetPassword) {
      return sendResetPasswordEmail(values.Email);
    }

    return resendVerificationEmail(values);
  };

  render() {
    const { context } = this;
    const { handleSubmit, submitting, pristine, invalid } = this.props;
    const { useEmailAsUsername, showOptions } = this.props;

    let validate = [required];
    let label = context.t('Username');

    if (useEmailAsUsername || !this.state.resetPassword) {
      validate.push(email);
      label = context.t('Email');
    }

    return (
      <Form
        onSubmit={handleSubmit(this.sendEmail)}
        className={`${simpleProblemLoggingInFormClasses('form')}`}>
        {showOptions ? (
          <React.Fragment>
            <APTypography
              fontSize="subbody"
              color="grey2"
              className={`${simpleProblemLoggingInFormClasses(
                'information-text'
              )}`}>
              {context.t(
                'Select your issue type and provide us with your {label} below. We will follow up with a solution shortly.',
                { label: label.toLowerCase() }
              )}
            </APTypography>

            <section
              className={simpleProblemLoggingInFormClasses('radio-section')}>
              <APRadio
                name="type"
                checked={this.state.resetPassword}
                onChange={this.toggleFormType}
                customClass={simpleProblemLoggingInFormClasses()}
                label={context.t(
                  'I’m an existing user and I forgot my password.'
                )}
                id="problem-login-radio1"
              />
              <APRadio
                name="type"
                checked={!this.state.resetPassword}
                onChange={this.toggleFormType}
                customClass={simpleProblemLoggingInFormClasses()}
                label={context.t(
                  'I’m a new user and I haven’t received my verification email.'
                )}
                id="problem-login-radio2"
              />
            </section>
          </React.Fragment>
        ) : (
          <APTypography
            fontSize="subbody"
            color="grey2"
            className={` ${simpleProblemLoggingInFormClasses(
              'information-text'
            )}`}>
            {context.t(
              'Provide us with your {label} below. We will follow up with reset instructions.',
              { label: label.toLowerCase() }
            )}
          </APTypography>
        )}
        <div className={simpleProblemLoggingInFormClasses('form')}>
          <APInputWithReduxForm
            type="text"
            name="Email"
            placeholderInInput={label}
            customClass={simpleProblemLoggingInFormClasses()}
            required
          />
        </div>

        <APButton
          type="submit"
          disabled={submitting || pristine || invalid}
          customClass={simpleProblemLoggingInFormClasses()}>
          <APTypography
            fontSize="body"
            weight="weight600"
            color="white4"
            className={simpleProblemLoggingInFormClasses('signup')}>
            {submitting ? context.t('Processing...') : context.t('Send Email')}
          </APTypography>
        </APButton>
      </Form>
    );
  }
}
SimpleProblemLoggingInFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  sendResetPasswordEmail: PropTypes.func,
  resendVerificationEmail: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  useEmailAsUsername: PropTypes.bool,
  showOptions: PropTypes.bool
};

SimpleProblemLoggingInFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SimpleProblemLoggingInFormComponent;
