import React from 'react';
import PropTypes from 'prop-types';

import './MyAssetsImagePreview.css';
import { getBEMClasses } from '../../../../helpers/cssClassesHelper';
const classes = getBEMClasses('my-assets-image-preview');

// Amount of ms that animation takes
const ANIMATION_DURATION = 1000;
// Amount of ms that must pass before changing image (after that amount of ms image will be not visible and we can change image's url)
const IMAGE_CHANGE_DELAY = ANIMATION_DURATION / 2;

export const MyAssetsImagePreview = props => {
  const { image } = props;
  const [currentImage, setCurrentImage] = React.useState(image);
  const [imageChangePhase, setImageChangePhase] = React.useState('idle');

  React.useEffect(
    () => {
      // On image change we place startAnimation class on image therefore starting animation
      setImageChangePhase('startAnimation');
      const currentImageTimeout = setTimeout(() => {
        // After IMAGE_CHANGE_DELAY ms change src of image
        setCurrentImage(image);
        clearTimeout(currentImageTimeout);
      }, IMAGE_CHANGE_DELAY);
      const imageChangeTimeout = setTimeout(() => {
        // Remove startAnimation class when animation ended
        setImageChangePhase('idle');
        clearTimeout(imageChangeTimeout);
      }, ANIMATION_DURATION);
    },
    [image]
  );

  return (
    <div
      className={classes('productPreview', imageChangePhase)}
      style={{
        backgroundImage: `url(${currentImage})`
      }}
    />
  );
};
MyAssetsImagePreview.contextTypes = {
  t: PropTypes.func.isRequired
};

MyAssetsImagePreview.propTypes = {
  image: PropTypes.string.isRequired
};
