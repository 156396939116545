import BigNumber from "bignumber.js";
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { convertIncrementToIntDecimalPlaces as iToDp } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import { orderTypes } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { buyValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { isLimitPriceFieldInForm, isStopPriceFieldInForm } from "apex-web/lib/helpers/placeOrderHelper";

const FEE_DP = 4;

/**
 * Sums up all fees with the same product
 * @param {{fee: BigNumber, product: Object}[]} orderFees 
 * @returns array of summed order fees or `null`
 */
export const sumSameProductFees = (orderFees) => {
  if (orderFees.length === 0) {
    return orderFees;
  }
  const feesByProduct = orderFees.reduce((acc, { fee: oFee, product }) => {
    const productEntry = acc[product.ProductId] || { fee: BigNumber(0), product };
    productEntry.fee = productEntry.fee.plus(BigNumber(oFee));
    acc[product.ProductId] = productEntry;
    return acc;
  }, {});
  const distinctProductsNumber = Object.keys(feesByProduct).length;
  if (distinctProductsNumber === 0) {
    console.error(Error(`Couldn't calculate total order fee from this: ${JSON.stringify(orderFees)}`));
    return [];
  } else {
    return Object.values(feesByProduct);
  }
};

export const formatTotalFee = (
  totalOrderFeeArray,
  separator = ' + '
) => totalOrderFeeArray.map(oFee => formatFee(oFee)).join(separator);

export const formatFee = (orderFee) => `${formatNumberToLocale(
  BigNumber(orderFee.fee).dp(
    FEE_DP,
    BigNumber.ROUND_HALF_UP
  )
)} ${orderFee.product.Product}`;

/**
 * Calculates total price/gain depending on fees and side
 * @param {*} side 
 * @param {Object} currentInstrument 
 * @param {BigNumber} initialTotalPrice 
 * @param {{fee: BigNumber, product: Object}[]} initialSummedFees 
 * @returns formatted string
 */
export const calcAndFormatTotalPrice = (
  side,
  currentInstrument,
  initialTotalPrice,
  initialSummedFees
) => {
  if (initialTotalPrice.eq(0)) {
    return formatPrice(currentInstrument, 0);
  }
  let totalPrice = BigNumber(initialTotalPrice);
  if (initialSummedFees.length > 0) {
    let summedFees = [...initialSummedFees];
    const sameProductFeeIndex = summedFees.findIndex(
      ({ product }) => product.ProductId === currentInstrument.Product2
    );
    // we presume that fees are summed and all product fees are unique
    if (sameProductFeeIndex > -1) {
      let sameProductFee = summedFees.splice(sameProductFeeIndex, 1)[0].fee;
      if (side === buyValue) {
        totalPrice = totalPrice.plus(sameProductFee);
      } else {
        totalPrice = totalPrice.minus(sameProductFee);
      }
    }
    const separator = getSeparatorBySide(side);
    const formattedFee = formatTotalFee(summedFees, separator);
    const formattedPrice = `${formatNumberToLocale(totalPrice.dp(FEE_DP))} ${currentInstrument.Product2Symbol}`;
    return formattedFee ? `${formattedPrice}${separator}${formattedFee}` : formattedPrice;
  } else {
    return formatPrice(currentInstrument, totalPrice);
  }
};

const getSeparatorBySide = (side) => side === buyValue ? ' + ' : ' - ';

export const formatPrice = (currentInstrument, price) =>
  `${formatNumberToLocale(
    BigNumber(price).dp(
      iToDp(currentInstrument.PriceIncrement),
      BigNumber.ROUND_HALF_UP
    )
  )} ${currentInstrument.Product2Symbol}`;

export const formatQuantity = (currentInstrument, quantity) =>
  `${formatNumberToLocale(
    BigNumber(quantity).dp(
      iToDp(currentInstrument.QuantityIncrement),
      BigNumber.ROUND_FLOOR
    )
  )} ${currentInstrument.Product1Symbol}`;

export const doesOrderPriceComeFromUser = (orderType) => {
  return isLimitPriceFieldInForm(orderType) || isStopPriceFieldInForm(orderType);
};

export const getInputPriceFieldName = (orderType) =>
  orderType === orderTypes.stopMarket.displayName ?
    'stopPrice' :
    'limitPrice';

export const getOrderFeesForConfirmedOrder = (confirmedOrder, products) => {
  const mappedFees = confirmedOrder.orderFees
    .map(({ fee, feeProductId }) => {
      const product = products.find(i => i.ProductId === feeProductId);
      return (fee !== undefined && fee !== null && product) ? { fee, product } : null;
    })
    .filter(fee => fee !== null);
  return sumSameProductFees(mappedFees);
};

// TODO(May 30, 2022): completely reimplement when the new admin panel is ready
export const getExpFromPrice = (price, side) => {
  const multiplier = side === buyValue ? 22.5 : 7.5;
  return BigNumber(price).times(multiplier);
};

export const getNormalizationRegExp = (maxDecimals) =>
  maxDecimals > 0 ?
    new RegExp(`^\\d+([.,]\\d{0,${maxDecimals}})?$`) :
    /^\d+$/;

export const getNormalizationCallback = regexp => (value, prevValue) => {
  return !isNaN(value) && (regexp.test(value.toString()) || value === '') ?
    value :
    prevValue;
};

export const preventSomeNumberSymbols = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

/**
 * Should be used before we handle the whole form to some business logic. It 
 * makes sure that quantity and prices have a valid tick size (a valid number 
 * of decimals). Invalid tick size causes an error.
 * @param {*} formValues 
 */
export const roundFormFields = (formValues, instrument) => {
  const roundedQuantity = formValues.quantity &&
    BigNumber(formValues.quantity)
      .dp(iToDp(instrument.QuantityIncrement), BigNumber.ROUND_FLOOR);
  const roundedStopPrice = formValues.stopPrice &&
    BigNumber(formValues.stopPrice)
      .dp(iToDp(instrument.PriceIncrement), BigNumber.ROUND_FLOOR);
  const roundedLimitPrice = formValues.limitPrice &&
    BigNumber(formValues.limitPrice)
      .dp(iToDp(instrument.PriceIncrement), BigNumber.ROUND_FLOOR);
  return {
    ...formValues,
    quantity: roundedQuantity,
    stopPrice: roundedStopPrice,
    limitPrice: roundedLimitPrice
  };
};
