import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';

const baseClass = 'info-icon';

export const InfoIcon = props => {
  const { customClass } = props;
  const classes = getBEMClasses(baseClass, customClass);
  return (
    <svg
      className={classes()}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className={classes('circle')}
        cx="15"
        cy="15"
        r="13.5"
        strokeWidth="3"
      />
      <circle className={classes('point')} cx="15" cy="8.25" r="2.25" />
      <rect
        className={classes('line')}
        x="13.5"
        y="13.5"
        width="3"
        height="10.5"
        rx="1.5"
      />
    </svg>
  );
};

InfoIcon.propTypes = {
  customClass: PropTypes.string
};
