import React from 'react';
import APDatePicker from 'apex-web/lib/components/common/APDatePicker/APDatePicker';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import APButton from 'apex-web/lib/components/common/APButton';

const baseClasses = getBEMClasses('order-history');

class HeaderWithFilterComponent extends React.Component {
  clearFilters = () => {
    const { clearDate } = this.props;
    clearDate('startDate');
    clearDate('endDate');
  };

  render() {
    const { startDate, clearDate } = this.props;
    return (
      <div className={baseClasses('header-with-filter')}>
        <div className={baseClasses('header-with-filter-title')}>
          {this.context.t('Transaction History')}
        </div>
        <div className={baseClasses('header-with-filter-datapicker')}>
          <APDatePicker
            name="startDate"
            customClass={baseClasses('from')}
            label={this.context.t('Date Range:')}
            maxDate={new Date()}
            format={value => value}
            showIcon
            iconClick={() => clearDate('startDate')}
          />

          <APDatePicker
            name="endDate"
            customClass={baseClasses('to')}
            label={this.context.t('to')}
            minDate={startDate}
            maxDate={new Date()}
            format={value => value}
            showIcon
            iconClick={() => clearDate('endDate')}
          />

          <APButton
            customClass={baseClasses('clear')}
            classModifiers="clear-filters"
            type="button"
            onClick={this.clearFilters}>
            {this.context.t('Clear Filters')}
          </APButton>
        </div>
      </div>
    );
  }
}

HeaderWithFilterComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

HeaderWithFilterComponent.propTypes = {
  clearDate: PropTypes.func,
  startDate: PropTypes.any
};

export default HeaderWithFilterComponent;
