import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import get from 'lodash/get';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import resize from 'apex-web/lib/hocs/resize';
import { InstagramNavIcon } from '../../images/react-icons/InstagramNavIcon';
import { DiscordNavIcon } from '../../images/react-icons/DiscordNavIcon';
import { TwitterNavIcon } from '../../images/react-icons/TwitterNavIcon';
import { LinkedinNavIcon } from '../../images/react-icons/LinkedinNavIcon';
import { FacebookNavIcon } from '../../images/react-icons/FacebookNavIcon';
import { SpotifyBlackNavIcon } from '../../images/react-icons/SpotifyBlackIcon';
import { SpotifyWhiteNavIcon } from '../../images/react-icons/SpotifyWhiteIcon';
import { isDarkTheme } from '../../helpers/themeHelper';
import { openLinkInNewTab } from '../../helpers/linkHelper';
import { useIsTablet } from '../../hooks/useIsMobile';
import './PageFooterLayout.css';

const pageFooterClasses = getBEMClasses('footer');

const PageFooterLayout = (props, context) => {
  const isDark = isDarkTheme();
  const isTablet = useIsTablet();

  const renderSocialIcons = () => {
    return (
      <div className={pageFooterClasses('social-icons')}>
        <InstagramNavIcon
          onClick={() =>
            openLinkInNewTab(
              'https://www.instagram.com/liquidmarketplace/?utm_campaign=EXPO%20Social%20Media&utm_source=Instragram'
            )
          }
          className={pageFooterClasses('social-icon')}
        />
        <DiscordNavIcon
          onClick={() =>
            openLinkInNewTab(
              'https://discord.com/invite/GX35JU6XZe?utm_campaign=EXPO%20Social%20Media&utm_source=Discord'
            )
          }
          className={pageFooterClasses('social-icon')}
        />
        <TwitterNavIcon
          onClick={() =>
            openLinkInNewTab(
              'https://twitter.com/LiquidMarketpl?utm_campaign=EXPO%20Social%20Media&utm_source=Twitter'
            )
          }
          className={pageFooterClasses('social-icon')}
        />
        <LinkedinNavIcon
          onClick={() =>
            openLinkInNewTab(
              'https://www.linkedin.com/company/liquid-marketplace/about/?utm_campaign=EXPO%20Social%20Media&utm_source=Linkedin'
            )
          }
          className={pageFooterClasses('social-icon')}
        />
        <FacebookNavIcon
          onClick={() =>
            openLinkInNewTab(
              'https://www.facebook.com/liquidmarketplace/?utm_campaign=EXPO%20Social%20Media&utm_source=Facebook'
            )
          }
          className={pageFooterClasses('social-icon')}
        />
        {isDark ? (
          <SpotifyWhiteNavIcon
            onClick={() =>
              openLinkInNewTab('https://merch.liquidmarketplace.io/')
            }
            className={pageFooterClasses('social-icon')}
          />
        ) : (
          <SpotifyBlackNavIcon
            onClick={() =>
              openLinkInNewTab('https://merch.liquidmarketplace.io/')
            }
            className={pageFooterClasses('social-icon')}
          />
        )}
      </div>
    );
  };
  return (
    <footer className={pageFooterClasses()}>
      <noscript>
        <iframe
          title="footer"
          src="https://www.googletagmanager.com/ns.html?id=GTM-NNZJ3Z4"
          height="0"
          width="0"
          // eslint-disable-next-line
          style="display:none;visibility:hidden"
        />
      </noscript>
      {isTablet && renderSocialIcons()}
      <div className={pageFooterClasses('left-row')}>
        <div
          className={pageFooterClasses('left-row-stub')}
          id="footer-support"
        />
        {isTablet
          ? get(config, 'Footer.links', []).map((column, idx) => (
              <React.Fragment key={idx}>
                {column.map((item, innerIdx) => {
                  let openInNewWindow =
                    config.Footer.openInNewWindowGlobal || true;
                  if ('openInNewWindow' in item) {
                    openInNewWindow = item.openInNewWindow;
                  }

                  return (
                    <a
                      className={pageFooterClasses('left-row-item')}
                      target={openInNewWindow ? '_blank' : ''}
                      rel={openInNewWindow ? 'noopener noreferrer' : ''}
                      key={`${idx}${innerIdx}`}
                      href={item.link}>
                      {context.t(item.text)}
                    </a>
                  );
                })}
              </React.Fragment>
            ))
          : get(config, 'Footer.links', []).map((column, idx) => (
              <div key={idx} className={pageFooterClasses('text')}>
                {column.map((item, innerIdx) => {
                  let openInNewWindow =
                    config.Footer.openInNewWindowGlobal || true;
                  if ('openInNewWindow' in item) {
                    openInNewWindow = item.openInNewWindow;
                  }

                  return (
                    <a
                      className={pageFooterClasses('left-row-item')}
                      target={openInNewWindow ? '_blank' : ''}
                      rel={openInNewWindow ? 'noopener noreferrer' : ''}
                      key={`${idx}${innerIdx}`}
                      href={item.link}>
                      {context.t(item.text)}
                    </a>
                  );
                })}
              </div>
            ))}
      </div>
      {!isTablet && renderSocialIcons()}
      <div className={pageFooterClasses('right-row')}>
        {`© Liquid Marketplace Inc. ${new Date().getFullYear()}. ${context.t(
          'All Rights Reserved.'
        )}`}
      </div>
    </footer>
  );
};

PageFooterLayout.contextTypes = {
  t: PropTypes.func.isRequired
};

export default resize(PageFooterLayout);
