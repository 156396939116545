import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';

import './APSearchInput.css';
import { APTypography } from '../APTypography';
import { useIsMobile } from '../../../hooks/useIsMobile';

const baseClass = 'search-input';

const APSearchInput = (props, context) => {
  const [searchText, setSearchText] = useState('');
  const isMobile = useIsMobile();
  const [showSearchResult, setShowSearchResult] = useState(false);
  const { searchList, customClass, onClose, ...restProps } = props;

  const classes = getBEMClasses([baseClass, customClass]);

  const handleChange = e => {
    setSearchText(e.target.value);
    setShowSearchResult(true);
  };

  const searchResults = useMemo(
    () => {
      return searchList.filter(item => {
        if (searchText) {
          return item.searchArray.some(item =>
            item.toLowerCase().includes(searchText.toLowerCase())
          );
        } else {
          return false;
        }
      });
    },
    [searchText]
  );

  const onItemClick = item => {
    item.onClick();
    setShowSearchResult(false);
    onClose && onClose();
  };

  const onContentBlur = e => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowSearchResult(false);
    }
  };

  const renderResult = () => {
    if (isMobile) {
      return (
        !!searchResults.length && (
          <div
            className={classes('list')}
            style={{ display: showSearchResult ? '' : 'none' }}>
            {searchResults.map((item, index) => (
              <div
                key={index}
                onClick={() => onItemClick(item)}
                className={classes('list-row')}>
                <div className={classes('list-label')}>
                  <Highlighter
                    highlightClassName={classes('highlighted-text')}
                    searchWords={[searchText]}
                    autoEscape={true}
                    textToHighlight={item.label}
                  />
                </div>
              </div>
            ))}
          </div>
        )
      );
    } else {
      return (
        !!searchResults.length && (
          <div
            className={classes('list')}
            style={{ display: showSearchResult ? '' : 'none' }}>
            {searchResults.slice(0, 4).map((item, index) => (
              <div
                key={index}
                onClick={() => onItemClick(item)}
                className={classes('list-row')}>
                <div className={classes('list-label')}>
                  <Highlighter
                    highlightClassName={classes('highlighted-text')}
                    searchWords={[searchText]}
                    autoEscape={true}
                    textToHighlight={item.label}
                  />
                </div>
              </div>
            ))}
            {searchResults.length > 4 && (
              <div className={classes('list-row-result')}>
                <APTypography
                  color="purple1"
                  fontSize="caption1"
                  className={classes('all-results')}>
                  {`${searchResults.length} ${context.t(
                    'RESULTS FOR'
                  )} "${searchText}"`}
                </APTypography>
                <span
                  className={classes('view-all-button')}
                  style={{ display: 'none' }}>
                  <APTypography color="purple1" fontSize="caption1">
                    {context.t('view all')}
                  </APTypography>
                </span>
              </div>
            )}
          </div>
        )
      );
    }
  };

  return (
    <div className={classes('container')}>
      <button className={classes('button-wrapper')} onBlur={onContentBlur}>
        <input
          className={classes('input')}
          type="text"
          {...restProps}
          value={searchText}
          onChange={handleChange}
          onFocus={() => setShowSearchResult(true)}
        />
        {renderResult()}
      </button>
    </div>
  );
};

APSearchInput.defaultProps = {
  searchList: []
};

APSearchInput.propTypes = {
  searchList: PropTypes.array,
  customClass: PropTypes.string,
  onClose: PropTypes.func
};

APSearchInput.contextTypes = {
  t: PropTypes.func.isRequired
};

export { APSearchInput };
