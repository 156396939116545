import { connect } from 'react-redux';
import KYCLevelsComponent from './KYCLevelsComponent';
import { openModal, MODAL_TYPES } from '../../../redux/actions/modalActions';

const mapDispatchToProps = dispatch => ({
  openKYCSidePane: () => {
    dispatch(openModal(MODAL_TYPES.KYC_MODAL, {}));
  }
});

const KYCLevelsContainer = connect(
  null,
  mapDispatchToProps
)(KYCLevelsComponent);

export default KYCLevelsContainer;
