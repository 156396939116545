import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import APIcon from '../APIcon';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';

import './Pagination.css';

const SimplePaginationComponent = (props, context) => {
  const {
    content,
    activePage,
    setActivePage,
    onRequestNext,
    onRequestPrev,
    customClass,
    containerClassName,
    totalPages,
    showPrevNextText = true,
    isNextDisabled = false
  } = props;
  const currentContent = useMemo(
    () => {
      return content(activePage);
    },
    [content, activePage]
  );

  useEffect(
    () => {
      if (activePage > 0 && currentContent.length === 0) {
        setActivePage(activePage - 1);
      }
    },
    [activePage, currentContent]
  );

  const getClasses = () => {
    return getBEMClasses(['liquid-pagination', `${customClass}-pagination`]);
  };
  const paginationClasses = getClasses();

  const goToPage = (event, page) => {
    event.preventDefault();
    setActivePage && setActivePage(page);
  };

  const goToNextPage = (e, page) => {
    goToPage(e, page);
    if (onRequestNext) {
      onRequestNext();
    }
  };

  const goToPrevPage = (e, page) => {
    goToPage(e, page);
    if (onRequestPrev) {
      onRequestPrev();
    }
  };

  const renderNextButtons = page => {
    const paginationClasses = getClasses();
    const disabled =
      totalPages === 0 || page === totalPages - 1 || isNextDisabled;
    return (
      <span className={paginationClasses('next')}>
        <a
          className={paginationClasses('text', 'next')}
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer'
          }}
          onClick={e => !disabled && goToNextPage(e, page + 1)}>
          {showPrevNextText && context.t('Next')}
          <APIcon
            name="caretNext"
            customClass={paginationClasses('arrow', 'next')}
          />
        </a>
      </span>
    );
  };

  const renderPreviousButtons = page => {
    const paginationClasses = getClasses();
    const disabled = page === 0;
    return (
      <span className={paginationClasses('previous')}>
        <a
          className={paginationClasses('text', 'prev')}
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer'
          }}
          onClick={e => !disabled && goToPrevPage(e, page - 1)}>
          <APIcon
            name="caretPrevious"
            customClass={paginationClasses('arrow', 'previous')}
          />
          {showPrevNextText && context.t('Prev')}
        </a>
      </span>
    );
  };

  return (
    <div className={containerClassName}>
      <div className={paginationClasses('content')}>{currentContent}</div>
      <div className={paginationClasses('container')}>
        {renderPreviousButtons(activePage)}
        <div className={paginationClasses('pages')}>
          <div className={paginationClasses('page')}>{activePage + 1}</div>
        </div>
        {renderNextButtons(activePage)}
      </div>
    </div>
  );
};

SimplePaginationComponent.defaultProps = {
  activePage: 0,
  containerClassName: '',
  customClass: ''
};

SimplePaginationComponent.propTypes = {
  totalPages: PropTypes.number.isRequired,
  content: PropTypes.func.isRequired,
  activePage: PropTypes.number,
  containerClassName: PropTypes.string,
  componentName: PropTypes.string,
  customClass: PropTypes.string,
  setActivePage: PropTypes.func,
  onRequestNext: PropTypes.func,
  onRequestPrev: PropTypes.func,
  showPrevNextText: PropTypes.bool,
  isNextDisabled: PropTypes.bool
};

SimplePaginationComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export { SimplePaginationComponent };
