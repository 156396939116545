import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

import ResetPasswordFormContainer from './ResetPasswordForm/ResetPasswordFormContainer';

import '../../styles/components/common/StandaloneForm.css';
import './ResetPasswordPage.css';
import redirectIfAuthorized from '../../hocs/redirectIfAuthorized';

const resetPasswordPageClasses = getBEMClasses('reset-password-page');

export const ResetPasswordForm = () => {
  return (
      <div className={` ${resetPasswordPageClasses('container')}`}>
        <ResetPasswordFormContainer />
      </div>
  );
};

ResetPasswordForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  ResetPasswordForm,
  process.env.REACT_APP_DEFAULT_PATH
);
