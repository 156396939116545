import { createSelector } from 'reselect';
import config from '../../config';
import get from 'lodash/get';

const userSelector = state => state.user;

export const selectIsUserConfigFetched = createSelector(
  state => state.userConfig,
  userConfig => userConfig.fetching
);

export const accountIdSelector = createSelector(
  userSelector,
  user => user.selectedAccountId
);

export const userConfigSelector = createSelector(
  userSelector,
  user => user.userConfig
);

export const selectIsSuperuser = createSelector(
  [userConfigSelector],
  userConfig => {
    const isSuperuser = userConfig.find(item => item.Key === 'isSuperuser');
    if (isSuperuser) {
      return JSON.parse(isSuperuser.Value);
    } else {
      return false;
    }
  }
);

export const selectIsHiddenFromTop10 = createSelector(
  [userConfigSelector],
  userConfig => {
    const isHiddenFromTop10 = userConfig.find(
      item => item.Key === 'isHiddenFromTop10'
    );
    if (isHiddenFromTop10) {
      return JSON.parse(isHiddenFromTop10.Value.toLowerCase());
    } else {
      return false;
    }
  }
);

export const selectIsOnboardingWasShown = createSelector(
  [userConfigSelector],
  userConfig => {
    const isOnboardingWasShown = userConfig.find(
      item => item.Key === 'IsOnboardingWasShown'
    );

    if (isOnboardingWasShown) {
      return true;
    } else {
      return false;
    }
  }
);

export const selectIsTermsOfUseAccepted = createSelector(
  [userConfigSelector, selectIsUserConfigFetched],
  () => {
    // const isTermsOfUseAccepted = userConfig.find(
    //   item => item.Key === 'IsTermsOfUseAccepted'
    // );

    // if (userConfigFetched && !isTermsOfUseAccepted) {
    //   return false;
    // } else {
    //   return true;
    // }
    return true
  }
);

export const selectWatchList = createSelector(
  [userConfigSelector],
  userConfig => {
    const currentWatchList = userConfig.find(
      item => item.Key === 'AssetWatchList'
    );
    if (currentWatchList) {
      const watchList = JSON.parse(currentWatchList.Value);
      return watchList;
    } else {
      return [];
    }
  }
);

export const currentGrandmasterSelector = createSelector(
  [userConfigSelector],
  userConfig => {
    const config = userConfig.find(item => item.Key === 'CurrentGrandMaster');
    if (config) {
      return config.Value.toLowerCase() === 'true';
    } else {
      return false;
    }
  }
);

export const userDegreeSelector = createSelector(
  [userConfigSelector],
  userConfig => {
    const config = userConfig.find(item => item.Key === 'degree');
    if (config) {
      return config.Value;
    } else {
      return false;
    }
  }
);

const userFeatureTestingSelector = (state, { featureAccessList }) => {
  return featureAccessList.includes(get(state, 'user.userInfo.UserId'));
};

export const userIbaneraTestingSelector = state =>
  userFeatureTestingSelector(state, {
    featureAccessList: config.FeatureTesting.IBANERA
  });
// export const userDCATestingSelector = state =>
//   userFeatureTestingSelector(state, {
//     featureAccessList: config.FeatureTesting.DCA
//   });

export const selectIsDotsVerificationFinished = createSelector(
  [userConfigSelector],
  userConfig => {
    const IsDotsVerficationFinished = userConfig.find(
      item => item.Key === 'IsDotsVerficationFinished'
    );
    if (IsDotsVerficationFinished) {
      return JSON.parse(IsDotsVerficationFinished.Value.toLowerCase());
    } else {
      return false;
    }
  }
);
