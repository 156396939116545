import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { accountIdSelector } from 'apex-web/lib/redux/selectors/marginSelectors';
import { createDepositTicket } from '../../../../../../redux/actions/depositActions';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { getBEMClasses } from '../../../../../../helpers/cssClassesHelper';
import WireTransferResultModal from '../../../../../WireTransferResultModal/WireTransferResultModal';

import './wireTransfer.css';
import classNames from 'classnames';
import {
  MODAL_TYPES,
  openModal
} from '../../../../../../redux/actions/modalActions';

const classes = getBEMClasses('wire-transfer');

const WireTransferComponent = props => {
  const {
    accountId,
    showNotification,
    submitDepositTicket,
    openWireTransferModal
  } = props;

  const [fullName, setFullName] = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const handleClick = async () => {
    if (!+amount) {
      showNotification('Amount value must be number');
      return;
    }
    if (!amount || !fullName) {
      showNotification('Fill all form fields to proceed');
      return;
    }

    const result = await submitDepositTicket(amount, fullName);

    if (result) {
      openWireTransferModal();
    }

    setIsSubmitted(true);
  };

  return (
    <div className={classes()}>
      <div className={classes('info-wrapper')}>
        <div className={classes('bank-information')}>
          <div className={classes('bank-info')}>
            <div className={classes('bank-info-header')}>
              Bank account information:
            </div>
            <div className={classes('bank-info-content')}>
              <div>SWIFT: BOFMCAM2</div>
              <div>Account: 4540350</div>
            </div>
          </div>
          <div className={classes('bank-info')}>
            <div className={classes('bank-info-header')}>
              Beneficiary information:
            </div>
            <div className={classes('bank-info-content')}>
              <div>Liquid MarketPlace Inc.</div>
              <div>1703 - 480 University Avenue</div>
              <div>Toronto, ON</div>
              <div>M5G 1V2</div>
            </div>
          </div>
        </div>
        <div className={classes('fee-info')}>
          A fee of 2% will be deducted from the amount credited to your account.
          If you wire something other than USD, your account will be credited an
          equivalent amount of USD calculated at the prevailing exchange rate at
          the time of the transaction.
        </div>
      </div>
      <div className={classes('deposit-ticket-wrapper')}>
        <div className={classes('deposit-inputs')}>
          <div className={classes('inputWrapper')}>
            <label htmlFor="eTransferLastName">Full Name: </label>
            <input
              type="text"
              value={fullName}
              onChange={e => {
                setFullName(e.target.value);
              }}
              id="wireDepositFullName"
              className={classes('deposit-input')}
            />
          </div>
          <div className={classes('inputWrapper')}>
            <label htmlFor="eTransferLastName">Account ID: </label>
            <input
              type="text"
              value={accountId}
              id="wireDepositAccountId"
              className={classes('deposit-input')}
              disabled
            />
          </div>
          <div className={classes('inputWrapper')}>
            <label htmlFor="eTransferLastName">Amount: </label>
            <input
              type="text"
              value={amount}
              onChange={e => {
                setAmount(e.target.value);
              }}
              id="wireDepositAmount"
              className={classes('deposit-input')}
            />
          </div>
        </div>
        <div
          className={classNames(
            classes('submit-deposit-button'),
            isSubmitted && classes('submit-deposit-button-disabled')
          )}
          onClick={() => {
            handleClick();
          }}>
          {isSubmitted ? 'Submitted' : 'Submit'}
        </div>
      </div>

      <WireTransferResultModal />
    </div>
  );
};

WireTransferComponent.propTypes = {
  submitDepositTicket: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
  openWireTransferModal: PropTypes.func.isRequired
};

WireTransferComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const selectedAccountId = accountIdSelector(state);

  return {
    accountId: selectedAccountId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showNotification: message => {
      dispatch(
        showSnack({
          id: 'wireDepositNotification',
          text: message,
          timeout: 5000,
          type: 'warning'
        })
      );
    },
    submitDepositTicket: (amount, fullName) => {
      return dispatch(createDepositTicket({ ProductId: 1, amount, fullName }));
    },
    openWireTransferModal: () => {
      dispatch(openModal(MODAL_TYPES.WIRE_TRANSFER_RESULT_MODAL));
    }
  };
};

export const WireTransfer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WireTransferComponent);
