/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

import Tab from 'apex-web/lib/components/common/Tab/Tab';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

const tradingLayoutClasses = getBEMClasses('trading-layout');

const TradingLayout468Px = (props, context) => {
  const {
    InstrumentPositions,
    OrderHistory
  } = props;

  const _getPanes = () => {
    return [
      {
        menuItem: context.t('Order History'),
        render: () => <OrderHistory />
      },
      {
        menuItem: context.t('Balances'),
        render: () => <InstrumentPositions showTitle={false} />
      }
    ];
  };

  return (
    <div className={tradingLayoutClasses('container-480')}>
      <Tab panes={_getPanes()} customClass="trading-layout" />
    </div>
  );
};

TradingLayout468Px.propTypes = {
  short: PropTypes.bool,
  OrderHistory: PropTypes.func.isRequired,
  InstrumentPositions: PropTypes.func.isRequired
};

TradingLayout468Px.defaultProps = {
  short: false
};

TradingLayout468Px.contextTypes = {
  t: PropTypes.func.isRequired
};

export default TradingLayout468Px;
