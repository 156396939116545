import get from 'lodash/get';

// TODO: modify sorting algorithms so that names are sorted from a to z
// and numbers are sorted ascending
export const sortAssets = (a, b, sortOption, isAscending = true) => {
  if (sortOption === 'All') {
    return true;
  } else {
    const aParam = get(a, sortOption);
    const bParam = get(b, sortOption);
    let result;
    if (aParam === undefined && bParam === undefined) {
      result = 0;
    } else if (isNaN(aParam) || isNaN(bParam)) {
      result = aParam !== bParam ? (aParam > bParam ? 1 : -1) : 0;
    } else {
      result = Number(aParam) - Number(bParam);
    }
    return result === 0 || isAscending ? result : -result;
  }
};
