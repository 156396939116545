import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withAuthentication from '../../hocs/withAuthentication';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { UrlFactory } from '../../helpers/urlFactory';
import { APSection } from '../../components/common/APSection';
import { WalletsSummaryHeaderButtons } from './WalletsSummary/WalletsSummaryHeaderButtons';
import { CurrenciesSummary } from './CurrenciesSummary';
import { WalletsTransactionHistoryContainer } from './WalletsTransactionHistory/WalletsTransactionHistoryContainer';
import {
  selectAssetsBalanceInBaseCurrency,
  selectBaseAssetBalance,
  selectDigitalAssetsBalanceInBaseCurrency
} from '../../redux/selectors/level1Selector';
import { digitalAssetsProductsSelector } from '../../redux/selectors/productsSelector';
import { updateDCAs } from '../../redux/actions/dcaActions';
import { BreadCrumbsLayout } from '../../layouts/BreadCrumbsLayout/BreadCrumbsLayoutContainer';
import PaymentNotifications from './PaymentNotifications';
import { Reminder } from '../../components/Reminder';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { WalletsContext } from './WalletsTransactionHistory/WalletsContext';
import WalletChart from '../../components/common/WalletChart/WalletChart';
import SectionHeader from '../../components/common/SectionHeader/SectionHeader';
import { MODAL_TYPES, openModal } from '../../redux/actions/modalActions';
import {
  selectIsTermsOfUseAccepted,
  userIbaneraTestingSelector
} from '../../redux/selectors/userConfigSelector';
import isEmpty from 'lodash/isEmpty';
import './WalletsPage.css';

const layoutClasses = getBEMClasses('wallets-page');

const WalletsPage = (props, context) => {
  const {
    digitalAssetsBalanceInBaseCurrency: {
      assetsWithPriceAndPercent,
      totalAvailableBalance
    },
    assetsDetails,
    isShowReminder,
    showNotification,
    assetsWithAttributes,
    USDBallance,
    isWalletDataFetching,
    updateAllDCAs,
    isTermsOfUseAccepted,
    openUpdateTermsOfUseModal,
    // isTestUser
  } = props;

  useEffect(
    () => {
      !isWalletDataFetching && updateAllDCAs();
    },
    [isWalletDataFetching]
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const filteredData = assetsWithPriceAndPercent
    .filter(item => Number(item.Amount))
    .sort(
      (a, b) => Number(b.PercentOfTotalAmount) - Number(a.PercentOfTotalAmount)
    );

  // do not display anything in the wallet chart if all AmountInBaseCurrency are 0.
  // it usually means that DCAs are not ready yet
  const chartData = filteredData.some(
    item => item.rawData.AmountInBaseCurrency > 0
  )
    ? filteredData.map(item => {
        const assetAttributes = assetsWithAttributes.find(
          asset => asset.ProductId === item.ProductId
        );
        const assetDetails = assetsDetails[item.ProductSymbol] || {
          productColor: assetAttributes ? assetAttributes.ProductColor : 'grey'
        };
        return {
          currency: item.ProductSymbol,
          value: Number(item.PercentOfTotalAmount),
          color: assetDetails.productColor,
          label: item.ProductSymbol,
          amountUSD: item.AmountInBaseCurrency,
          isOnHold: !item.isMarketRunning
        };
      })
    : [];
  const currenciesTable = filteredData.map(item => {
    const assetAttributes = assetsWithAttributes.find(
      asset => asset.ProductId === item.ProductId
    );
    const assetDetails = assetsDetails[item.ProductSymbol] || {
      productColor: assetAttributes ? assetAttributes.ProductColor : 'grey'
    };
    return {
      currency: item.ProductSymbol,
      currencyColor: assetDetails.productColor,
      amountUSD: item.AmountInBaseCurrency,
      percentage: item.PercentOfTotalAmount,
      amount: item.Amount,
      productSymbol: item.ProductSymbol,
      dca: item.DCA,
      detailsLink: UrlFactory.getMarketPlaceAssetDetailsUrl(item.ProductId),
      actions: [
        { actionName: 'Deposit', action: () => {} },
        { actionName: 'Withdraw', action: () => {} },
        { actionName: 'Transaction History', action: () => {} }
      ]
    };
  });

  return (
    <WalletsContext.Provider value={{ showNotification }}>
      <BreadCrumbsLayout>
        <div className={layoutClasses('container')}>
          <div className={layoutClasses('columns-container')}>
            <div
              className={layoutClasses(
                'column1',
                isExpanded ? 'expanded' : ''
              )}>
              <APSection
                title={context.t('My Wallet')}
                customClass={layoutClasses('section')}
                additionalComponent={
                  <WalletsSummaryHeaderButtons
                    isTermsOfUseAccepted={isTermsOfUseAccepted}
                    openUpdateTermsOfUseModal={openUpdateTermsOfUseModal}
                  />
                }>
                {isShowReminder && (
                  <div className={layoutClasses('reminder-wrapper')}>
                    <Reminder />
                  </div>
                )}
                <div className={layoutClasses('overview-with-chart')}>
                  <div className={layoutClasses('available-funds')}>
                    <div className={layoutClasses('available-funds-row')}>
                      {context.t('Available Funds:')}
                      {!isEmpty(USDBallance) && (
                        <div className={layoutClasses('available-funds-value')}>
                          {`${USDBallance.AvailableBalance} ${
                            USDBallance.ProductSymbol
                          }`}
                        </div>
                      )}
                    </div>
                    {/* {isTestUser && !!USDBallance.Hold && (
                      <div className={layoutClasses('available-funds-row')}>
                        {context.t('Pending Balance:')}
                        <div className={layoutClasses('available-funds-value')}>
                          {`${USDBallance.Hold} ${USDBallance.ProductSymbol}`}
                        </div>
                      </div>
                    )} */}
                  </div>
                  <WalletChart
                    data={chartData}
                    totalAvailableBalance={totalAvailableBalance}
                  />
                </div>
                <CurrenciesSummary items={currenciesTable} />
              </APSection>
            </div>
            <div
              className={layoutClasses(
                'column2',
                isExpanded ? 'expanded' : ''
              )}>
              <div className={layoutClasses('recent-transaction-wrapper')}>
                <SectionHeader
                  title={context.t('Recent Transactions')}
                  customClick={() => setIsExpanded(!isExpanded)}
                  viewAllText={context.t(isExpanded ? 'hide all' : 'view all')}
                  backgroundColor={'#E4E5E9'}
                  viewAllBackgroundColor={'#FFFFFF'}
                  textColor="#191B20"
                  viewAllArrowsCustomClass={layoutClasses('view-all-arrows')}
                  customClass={layoutClasses('section-header')}
                />
                <WalletsTransactionHistoryContainer
                  isShowReminder={isShowReminder}
                />
              </div>
            </div>
          </div>
          <PaymentNotifications showNotification={showNotification} />
        </div>
      </BreadCrumbsLayout>
    </WalletsContext.Provider>
  );
};

WalletsPage.propTypes = {
  assetsBalanceInBaseCurrency: PropTypes.object,
  assetsDetails: PropTypes.object,
  isShowReminder: PropTypes.bool,
  showNotification: PropTypes.func.isRequired,
  assetsWithAttributes: PropTypes.array,
  isWalletDataFetching: PropTypes.bool,
  updateAllDCAs: PropTypes.func.isRequired,
  digitalAssetsBalanceInBaseCurrency: PropTypes.object,
  USDBallance: PropTypes.object,
  isTermsOfUseAccepted: PropTypes.bool,
  openUpdateTermsOfUseModal: PropTypes.func,
  isTestUser: PropTypes.bool
};

WalletsPage.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const USDBallance = selectBaseAssetBalance(state);
  const digitalAssetsBalanceInBaseCurrency = selectDigitalAssetsBalanceInBaseCurrency(
    state
  );
  const { totalAvailableBalance } = selectAssetsBalanceInBaseCurrency(state);
  const assetsDetails = state.productManifest.manifest;
  const isShowReminder =
    !state.userConfig.isReminderWasShown && Number(totalAvailableBalance) < 50;
  const assetsWithAttributes = digitalAssetsProductsSelector(state);

  const isWalletDataFetching =
    state.apexCore.instrument.fetching || state.apexCore.position.fetching;
  const isTermsOfUseAccepted = selectIsTermsOfUseAccepted(state);
  const isTestUser = userIbaneraTestingSelector(state);

  return {
    digitalAssetsBalanceInBaseCurrency,
    USDBallance,
    assetsDetails,
    isShowReminder,
    assetsWithAttributes,
    isWalletDataFetching,
    isTermsOfUseAccepted,
    isTestUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showNotification: (message, type) => {
      dispatch(
        showSnack({
          id: 'paymentNotification',
          text: message,
          timeout: 10000,
          type
        })
      );
    },
    updateAllDCAs: () => dispatch(updateDCAs()),
    openUpdateTermsOfUseModal: props =>
      dispatch(
        openModal(MODAL_TYPES.UPDATE_TERMS_OF_USE_MODAL, {
          ...props,
          isDeposit: true
        })
      )
  };
};

export default withAuthentication(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WalletsPage)
);
