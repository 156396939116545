import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BuySellProductCard } from '../../components/common/BuySellProductCard';
import { selectDigitalAssetsWithAttributesNotUndefined } from '../../redux/selectors/productsSelector';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { UrlFactory } from '../../helpers/urlFactory';
import { openBuySellModal } from '../../redux/actions/buySellModalActions';
import { withRouter } from 'react-router-dom';
import { useIsTablet } from '../../hooks/useIsMobile';
import Slider from 'react-slick';
import { openLinkInNewTab } from '../../helpers/linkHelper';

const baseClass = getBEMClasses('co-own-page');

const MainBlockComponent = props => {
  const {
    name = '',
    subName = '',
    specialty = '',
    topCollectible = '',
    significantProjects = [],
    collectibles = [],
    description = '',
    avatar,
    video,
    makeOrder,
    isPublic,
    history,
    collectiblesTitle,
    videoLink
  } = props;

  const isTablet = useIsTablet();

  const sliderSettings = {
    dots: true,
    infinite: true,
    initialSlide: 0,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000
  };

  const renderCollectiblesCards = () => {
    return collectibles.map(item => {
      const goToAssetDetails = () => history.push(UrlFactory.getMarketPlaceAssetDetailsUrl(item.productId));
      return (
        <BuySellProductCard
          key={`${item.ProductFullName}`}
          item={{
            ProductFullName: item.ProductFullName,
            BestOffer: item.BestOffer,
            BestBid: item.BestBid,
            Rolling24HrPxChangePercent: item.Rolling24HrPxChangePercent,
            ProductId: item.productId,
            LastTradedPx: item.LastTradedPx
          }}
          color={item.color}
          baseCurrency={item.baseCurrency}
          cardImageURL={item.ProductImageURL}
          cardBackgroundImage={item.ProductCardBackground}
          buyOnly={isPublic || item.amount === 0}
          onCardPress={goToAssetDetails}
          onBuySell={isPublic ? goToAssetDetails : makeOrder}
        />
      );
    });
  };

  const renderCollectibles = () => {
    return (
      <div className={baseClass('main-block-collectibles')}>
        <div className={baseClass('main-block-right-part-collectibles-title')}>
          {collectiblesTitle}
        </div>
        <div className={baseClass('main-block-right-part-collectibles')}>
          {isTablet ? (
            <Slider {...sliderSettings}>{renderCollectiblesCards()}</Slider>
          ) : (
            <React.Fragment>{renderCollectiblesCards()}</React.Fragment>
          )}
        </div>
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <div className={baseClass('main-block-right-part-description')}>
        {description}
      </div>
    );
  };

  const renderNameAndSubName = () => {
    return (
      <React.Fragment>
        <div className={baseClass('main-block-right-part-name')}>{name}</div>
        <div className={baseClass('main-block-right-part-subname')}>
          {subName}
        </div>
      </React.Fragment>
    );
  };

  const renderOverview = () => {
    return (
      <React.Fragment>
        <div className={baseClass('main-block-left-part-text-wrapper')}>
          <div className={baseClass('main-block-left-part-text-title')}>
            Specialty
          </div>
          <div className={baseClass('main-block-left-part-text')}>
            {specialty}
          </div>
        </div>
        <div className={baseClass('main-block-left-part-text-wrapper')}>
          <div className={baseClass('main-block-left-part-text-title')}>
            Notable Collecible Piece
          </div>
          <div className={baseClass('main-block-left-part-text')}>
            {topCollectible}
          </div>
        </div>
        <div className={baseClass('main-block-left-part-text-wrapper')}>
          <div className={baseClass('main-block-left-part-text-title')}>
            Significant Projects
          </div>
          {significantProjects.map(item => (
            <div key={item} className={baseClass('main-block-left-part-text')}>
              {item}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className={baseClass('main-block-wrapper')}>
      {isTablet ? (
        <React.Fragment>
          <div className={baseClass('main-block-left-part')}>
            <img
              className={baseClass('main-block-avatar')}
              src={avatar}
              alt={name}
            />
            {renderNameAndSubName()}
            {renderOverview()}
            {renderDescription()}
            <div
              className={baseClass('video-wrapper')}
              onClick={() => openLinkInNewTab(videoLink)}>
              <img
                className={baseClass('main-block-left-part-video')}
                src={video}
                alt={name}
              />
            </div>
          </div>
          {renderCollectibles()}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={baseClass('main-block-left-part')}>
            <img
              className={baseClass('main-block-avatar')}
              src={avatar}
              alt={name}
            />
            {renderOverview()}
            <div
              className={baseClass('video-wrapper')}
              onClick={() => openLinkInNewTab(videoLink)}>
              <img
                className={baseClass('main-block-left-part-video')}
                src={video}

                alt={name}
              />
            </div>
          </div>
          <div className={baseClass('main-block-right-part')}>
            <div>
              {renderNameAndSubName()}
              {renderDescription()}
            </div>

            {renderCollectibles()}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

MainBlockComponent.propTypes = {
  name: PropTypes.string,
  subName: PropTypes.string,
  specialty: PropTypes.string,
  topCollectible: PropTypes.string,
  significantProjects: PropTypes.arrayOf(PropTypes.string),
  collectibles: PropTypes.arrayOf(PropTypes.number),
  description: PropTypes.string,
  avatar: PropTypes.string,
  video: PropTypes.string,
  makeOrder: PropTypes.func,
  history: PropTypes.object,
  collectiblesTitle: PropTypes.string,
  videoLink: PropTypes.string,
  isPublic: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const availableCollectibles = selectDigitalAssetsWithAttributesNotUndefined([
    'Rolling24HrPxChangePercent',
    'Rolling24NumTrades'
  ])(state, { withoutBalance: ownProps.isPublic });
  const filteredCollectibles = availableCollectibles.filter(item =>
    ownProps.collectiblesIds.includes(item.product.ProductId)
  );
  const collectibles = filteredCollectibles.map(asset => ({
    ProductFullName: asset.product.ProductFullName,
    ProductImageURL: asset.product.productAttributes.ProductImageURL,
    ProductCardBackground:
      asset.product.productAttributes.BuySellProductCardBackground ||
      asset.product.productAttributes.ProductCardBackground,
    BestOffer: asset.BestOffer,
    BestBid: asset.BestBid,
    Rolling24HrPxChangePercent: asset.Rolling24HrPxChangePercent,
    Rolling24NumTrades: asset.Rolling24NumTrades,
    color: asset.product.productAttributes.ProductColor || 'grey',
    amount: ownProps.isPublic ? 0 : asset.Amount,
    baseCurrency: 'USD',
    productId: asset.product.ProductId,
    LastTradedPx: asset.LastTradedPx
  }));
  return { collectibles: collectibles };
};

const mapDispatchToProps = dispatch => ({
  makeOrder: props => {
    dispatch(openBuySellModal(props));
  }
});

const MainBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainBlockComponent));

export { MainBlock };
