import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { Modal } from '../../../components/common/Modal/Modal';
import { MODAL_TYPES } from '../../../redux/actions/modalActions';
import { modalDecorator } from '../../common/Modal/modalDecorator';
import { DeleteIcon } from '../../../images/react-icons/DeleteIcon';
import APIcon from '../../common/APIcon';
import {
  markNotificationAsSeen,
  removeNotification
} from '../../../redux/actions/notificationsActions';
import './NotificationModals.css';
import { MarkAsReadIcon } from '../../../images/react-icons/MarkAsReadIcon';

const classes = getBEMClasses('notification-modal');

const NotificationModalComponent = (props, context) => {
  const {
    isOpen,
    close,
    notificationMessage,
    date,
    isSeen,
    subClassName,
    notificationTitle,
    iconName,
    markNotificationAsSeen,
    removeNotification,
    notification
  } = props;
  const [isConfirmRemove, setIsConfirmRemove] = useState(false);

  const onClose = () => {
    setIsConfirmRemove(false);
    close && close();
  };

  return (
    <Modal
      customClass={classes('', subClassName)}
      isOpen={isOpen}
      close={onClose}
      titleComponent={
        <div className={classes('title-wrapper')}>
          {isConfirmRemove ? (
            <div className={classes('remove-title')}>
              Are you sure you want to delete?
            </div>
          ) : (
            <React.Fragment>
              <APIcon name={iconName} customClass={classes('title-icon')} />
              <div className={classes('title')}>{notificationTitle}</div>
            </React.Fragment>
          )}
        </div>
      }>
      <div className={classes('body')}>
        <div
          className={classes('text')}
          dangerouslySetInnerHTML={{ __html: notificationMessage }}
        />
        <div className={classes('date')}>{date}</div>
        <div className={classes('buttons')}>
          {!isConfirmRemove ? (
            <React.Fragment>
              {!isSeen && (
                <button
                  className={classes('mark-button')}
                  onClick={() => {
                    markNotificationAsSeen([notification.id]);
                    close();
                  }}>
                  <MarkAsReadIcon className={classes('mark-icon')} />
                  {context.t('Mark As Read')}
                </button>
              )}

              <button
                className={classes('delete-button')}
                onClick={() => {
                  setIsConfirmRemove(true);
                }}>
                <DeleteIcon className={classes('delete-icon')} />
                {context.t('Delete')}
              </button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <button
                className={classes('cancel-button')}
                onClick={() => {
                  setIsConfirmRemove(false);
                }}>
                {context.t('Cancel')}
              </button>
              <button
                className={classes('confirm-button')}
                onClick={() => {
                  removeNotification(notification);
                  close();
                }}>
                {context.t('Confirm')}
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </Modal>
  );
};

NotificationModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

NotificationModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  notification: PropTypes.object,
  notificationMessage: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  isSeen: PropTypes.bool,
  subClassName: PropTypes.string,
  notificationTitle: PropTypes.string,
  iconName: PropTypes.string,
  markNotificationAsSeen: PropTypes.func,
  removeNotification: PropTypes.func
};

const mapDispatchToProps = {
  markNotificationAsSeen,
  removeNotification
};

const NotificationModalContainer = connect(
  null,
  mapDispatchToProps
)(NotificationModalComponent);

export const NotificationModal = modalDecorator({
  name: MODAL_TYPES.NOTIFICATION_MODAL
})(NotificationModalContainer);
