import React from 'react';

export const CurvedHeaderIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M 0 0 C 80 64, 112 0, 144 0" />
      <path d="M 140 0 C 160 0, 160 48, 304 0" />
    </svg>
  );
};
