import { createSelector } from 'reselect';
import { userConfigSelector } from './userConfigSelector';
import BigNumber from 'bignumber.js';
import get from 'lodash/get';

export const selectIsAssetInWatchList = createSelector(
  [userConfigSelector, (state, assetId) => assetId],
  (userConfig, assetId) => {
    const currentWatchList = userConfig.find(
      item => item.Key === 'AssetWatchList'
    );
    if (currentWatchList) {
      const watchList = JSON.parse(currentWatchList.Value);
      return watchList.includes(assetId);
    } else {
      return false;
    }
  }
);

export const availableTokensSelector = createSelector(
  [state => state.availableTokens.byProductId],
  tokens => tokens
);

export const availableTokensByProductSelector = (state, productId) =>
  BigNumber(
    get(state, ['availableTokens', 'byProductId', productId, 'available_tokens']) || 0
  );
