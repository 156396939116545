import React from 'react';
import PropTypes from 'prop-types';

import { APSection } from '../APSection';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './SectionWithFilterAndSearch.css';
const classes = getBEMClasses('section-with-filter-search');

export const SectionWithFilterAndSearch = (props) => {
  const {
    filterComponent = null,
    searchComponent = null,
    children,
    title,
  } = props;
  return (
    <APSection
      title={title}
      customClass={classes()}
      additionalComponent={
        <div className={classes('search-wrapper')}>{searchComponent}</div>
      }>
      {filterComponent}
      {children}
    </APSection>
  );
};

SectionWithFilterAndSearch.contextTypes = {
  t: PropTypes.func.isRequired
};

SectionWithFilterAndSearch.propTypes = {
  filterComponent: PropTypes.node,
  searchComponent: PropTypes.node,
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};
