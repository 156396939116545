import React from 'react';
import PropTypes from 'prop-types';
import SidePaneDetailsComponent from '../../../common/SidePaneDetailsComponent/SidePaneDetailsComponent';

class FiatDepositDetailsComponent extends React.Component {
  render() {
    const { info = {} } = this.props;
    const showCustomText = info && info.text && info.text.length > 0;
    const showLink = info && info.link;

    const defaultInfo = [
      this.context.t(
        'Step 1: Create the deposit ticket. Sender’s bank details should match with the wallet account. Third party and/or anonymous payments cannot be accepted.'
      ),
      this.context.t(
        'Step 2: Please deposit the stated amount to the provided bank account details.'
      ),
      this.context.t(
        'Note - The information from the deposit ticket will be emailed to you.'
      ),
      this.context.t(
        'The deposit of these funds might take up to 7 business days, and it’s subject to operator review.'
      )
    ];
    return (
      <SidePaneDetailsComponent
        title={this.context.t('Instructions')}
        info={showCustomText ? info.text : defaultInfo}
        link={showLink && info.link}
        classModifiers={'deposit'}
      />
    );
  }
}

FiatDepositDetailsComponent.propTypes = {
  info: PropTypes.object
};

FiatDepositDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export { FiatDepositDetailsComponent };
