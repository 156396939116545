import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import APQRCode from 'apex-web/lib/components/common/APQRCode/APQRCode';
import APIcon from 'apex-web/lib/components/common/APIcon';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import copyToClipboard from 'apex-web/lib/helpers/clipboardHelper';
import destinationTagHelper from 'apex-web/lib/helpers/destinationTagHelper';
import './ReceiveAddress.css';

const bemClasses = getBEMClasses('receive-address');

const ReceiveAddressComponent = (props, context) => {
  const {
    deposit: { depositInfo: addressList, isLoading, error },
    product,
    showSnack,
    WalletsDisabledMessage,
    selectDepositProduct
  } = props;

  useEffect(
    () => {
      if (product.ProductId) {
        selectDepositProduct(product.ProductId);
      }
    },
    [product.ProductId]
  );

  // Protocol prefix for the QR Code, lowercase name with no spaces.
  let selectedAddress;

  if (!isLoading && addressList.length) {
    selectedAddress = addressList[addressList.length - 1];
  }

  const [address, destinationTag] = destinationTagHelper(selectedAddress);
  const isShowCryptoText =
    product.ProductSymbol !== 'BTC' && product.ProductSymbol !== 'LTC';

  return (
    <section className={bemClasses()}>
      {selectedAddress && (
        <React.Fragment>
          <p className={bemClasses('description')}>
            {context.t('Scan this QR code to receive funds:')}
          </p>
          <p className={bemClasses('warning-description')}>
            In order to ensure the availability of our support team, any crypto
            funding must be at least $25 USD. Payments less than that will not
            be supported.
          </p>
          <div className={bemClasses('qr-code')}>
            <APQRCode value={`${selectedAddress}`} />
          </div>
          <p className={bemClasses('copy-address-text')}>
            {context.t('Or copy this address:')}
          </p>
          <div
            className={bemClasses('copy-address-payload')}
            onClick={() => {
              showSnack({
                id: 'RECEIVE_ADDRESS_COPY',
                text: context.t('The address has been copied to the clipboard.')
              });
              copyToClipboard(address);
            }}>
            <span className={bemClasses('address')}>{address}</span>
            <APIcon name="copy" customClass={bemClasses('copy-icon')} />
          </div>
          {isShowCryptoText && (
            <div className={bemClasses('crypto-text-wrapper')}>
              <div className={bemClasses('crypto-title')}>
                ERC20 NETWORK (ETHEREUM MAINNET)
              </div>
              <div className={bemClasses('crypto-text')}>
                Please limit deposits to 8 decimal places.
              </div>
            </div>
          )}
          {destinationTag && (
            <div
              onClick={() => {
                showSnack({
                  id: 'RECEIVE_ADDRESS_COPY',
                  text: context.t(
                    'The destination tag has been copied to the clipboard.'
                  )
                });
                copyToClipboard(destinationTag);
              }}>
              <p className={bemClasses('destination-tag-text')}>
                {context.t('Be sure to include this destination tag:')}
              </p>
              <span className={bemClasses('address')}>{destinationTag}</span>
              <APIcon name="copy" customClass={bemClasses('copy-icon')} />
            </div>
          )}
        </React.Fragment>
      )}
      {isLoading && (
        <Spinner text={context.t('Loading url...')} customClass={bemClasses} />
      )}
      {WalletsDisabledMessage ? (
        <span className={bemClasses('error')}>
          {context.t(WalletsDisabledMessage)}
        </span>
      ) : (
        error && <span className={bemClasses('error')}>{context.t(error)}</span>
      )}
    </section>
  );
};

ReceiveAddressComponent.defaultProps = {
  deposit: {
    templateInfo: {},
    template: {},
    isLoading: false,
    error: '',
    depositInfo: {},
    depositStatus: {}
  },
  product: {}
};

ReceiveAddressComponent.propTypes = {
  product: PropTypes.object,
  deposit: PropTypes.shape({
    templateInfo: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    product: PropTypes.number,
    depositInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    depositStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  }),
  showSnack: PropTypes.func,
  WalletsDisabledMessage: PropTypes.string,
  selectDepositProduct: PropTypes.func
};

ReceiveAddressComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReceiveAddressComponent;
