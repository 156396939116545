import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { BreadCrumbs } from './BreadCrumbsLayoutComponent';
import { getLinks } from '../../redux/selectors/historySelector';

const mapStateToProps = (state, ownProps) => {
  const links = getLinks(state);
  const { params } = ownProps.match;
  // const { assetId } = params;

  return {
    links,
    locationParams: params
  };
};

const withConnect = connect(
  mapStateToProps,
  null,
);

export const BreadCrumbsLayout = compose(
  withRouter,
  withConnect,
)(BreadCrumbs);
