import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './DotsWithdrawal.css';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup/yup';
import * as yup from 'yup';
import { APNumberInput } from '../../../../common/APNumberInput';
import { withRouter } from 'react-router-dom';
import { getBEMClasses } from '../../../../../helpers/cssClassesHelper';
import APButton from '../../../../common/APButton/APButton';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { ErrorMessage } from '../../../../common/ErrorMessage';
import { CheckBox } from '../../../../common/Checkbox';
import { APTypography } from '../../../../common/APTypography';
import { AgreementStripeModal } from '../../DepositFormContainer/DepositFromCard/stripe/AgreementModal';
import { InnerInput } from '../../../../common/APInputWithReduxForm/APInput';
import DepositWithdrawStatusModalContainer from '../../../../DepositWithdrawStatusModal/DepositWithdrawStatusModalContainer';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DollarLogo from './images/dollar.png';
import PayPalLogo from './images/payPal.png';
import VenmoLogo from './images/venmo.png';
import VisaLogo from './images/visa.png';
import BankTransferLogo from './images/bank-transfer.png';

const baseClasses = getBEMClasses('dots-withdraw');

const schema = yup.object().shape({
  amount: yup
    .number()
    .positive('Amount is required')
    .required('Amount is required'),
  code2fa: yup.string().required('2FA code is required')
});

const DotsWithdrawal = props => {
  const {
    submitWithdraw,
    is2FAEnabled,
    history,
    firstName,
    lastName,
    onFormChange
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      firstName,
      lastName
    },
    resolver: yupResolver(schema),
    shouldUnregister: true
  });

  if (!is2FAEnabled) {
    return (
      <div className={baseClasses('no-2fa-wrapper')}>
        <p className={baseClasses('no-2fa-text')}>Please Enable 2fa</p>
        <APButton
          type="submit"
          customClass={baseClasses()}
          styleName="additive"
          onClick={() => history.push('/settings/user')}>
          Go to settings
        </APButton>
      </div>
    );
  }

  const onSubmit = handleSubmit(data => {
    const { amount, code2fa } = data;

    const withdrawInfo = {
      country_code: countryCode,
      phone_number: phone.replace(countryCode, ''),
      first_name: firstName,
      last_name: lastName
    };
    setIsLoading(true);
    submitWithdraw({ amount, code2fa, withdrawInfo }).finally(() => {
      setIsLoading(false);
    });
  });

  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={baseClasses('frame')}
        onChange={onFormChange}>
        <InnerInput
          type="text"
          name="firstName"
          inputRef={register}
          label={'First Name:'}
          customClass={baseClasses()}
          disabled
        />
        <InnerInput
          type="text"
          name="lastName"
          inputRef={register}
          label={'Last Name:'}
          customClass={baseClasses()}
          disabled
        />
        <div className={baseClasses('label')}>Phone number:</div>
        <PhoneInput
          country={'us'}
          value={phone}
          onChange={(phone, country) => {
            setPhone(phone);
            setCountryCode(country.dialCode);
          }}
          inputClass={baseClasses('phone-input')}
          buttonClass={baseClasses('phone-input-dropdown')}
          containerClass={baseClasses('phone-input-container')}
        />
        <APNumberInput
          name="amount"
          label={`USD Amount:`}
          customClass={baseClasses()}
          control={control}
        />
        <ErrorMessage
          errors={errors}
          customClass={baseClasses('error-message')}
          name="amount"
        />
        <InnerInput
          type="text"
          name="code2fa"
          inputRef={register}
          label={'2FA Code:'}
          customClass={baseClasses()}
        />
        <ErrorMessage
          errors={errors}
          customClass={baseClasses('error-message')}
          name="code2fa"
        />
        <div className={baseClasses('agreement')}>
          <CheckBox
            value={agreement}
            customClass={baseClasses('checkbox')}
            handleClick={() => {
              setShowModal(true);
            }}>
            <APTypography
              className={baseClasses('agreement-description')}
              color={'black3'}
              fontSize={'small1'}>
              I agree with the Terms of Use
            </APTypography>
          </CheckBox>
        </div>
        <div className={baseClasses('footer')}>
          {isLoading ? (
            <div className={baseClasses('loader-wrapper')}>
              <Spinner customClass={baseClasses('loader')} />
            </div>
          ) : (
            <APButton
              disabled={!agreement}
              type="submit"
              customClass={baseClasses('', 'request')}
              styleName="additive">
              Request
            </APButton>
          )}
          <div className={baseClasses('brands-wrapper')}>
            <img src={DollarLogo} className={baseClasses('brand')} />
            <img src={PayPalLogo} className={baseClasses('brand')} />
            <img src={VenmoLogo} className={baseClasses('brand')} />
            <img src={VisaLogo} className={baseClasses('brand', 'visa')} />
          </div>
          <img
            src={BankTransferLogo}
            className={baseClasses('brand', 'bank-transfer')}
          />
        </div>
      </form>
      <AgreementStripeModal
        description={
          <React.Fragment>
            By checking this box, you agree to the
            <a
              className="deposit-from-card__modal-link"
              href="https://www.liquidmarketplace.io/terms-of-use">
              Terms of Use
            </a>
          </React.Fragment>
        }
        isOpen={showModal}
        close={() => {
          setShowModal(false);
          setAgreement(agreement);
        }}
        handleConfirm={() => {
          setShowModal(false);
          setAgreement(true);
        }}
        handleReject={() => {
          setShowModal(false);
          setAgreement(false);
        }}
      />
      <DepositWithdrawStatusModalContainer />
    </React.Fragment>
  );
};

DotsWithdrawal.propTypes = {
  submitWithdraw: PropTypes.func,
  isDotsVerificationFinished: PropTypes.bool,
  is2FAEnabled: PropTypes.bool,
  history: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onFormChange: PropTypes.func
};

export const DotsWithdrawalComponent = withRouter(DotsWithdrawal);
