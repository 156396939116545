import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PageHeaderNavMenuComponent from './PageHeaderNavMenuComponent';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

import './PageHeaderNavComponent.css';
import { LightThemeIcon } from './themeIcons/lightThemeIcon';
import { DarkThemeIcon } from './themeIcons/darkThemeIcon';
import { CurvedHeaderIcon } from './CurvedHeaderIcon';

const PageHeaderNavClasses = getBEMClasses('page-header-nav');

export const PageHeaderNavComponent = (props, context) => {
  const { navBarExpanded, setNavBarExpanded, navItems, logout } = props;
  const [currentTemplate, setCurrentTemplate] = useState({ theme: 'light' });

  const changeTheme = theme => {
    localStorage.setItem(
      'apexTemplate',
      JSON.stringify({ ...currentTemplate, theme: theme })
    );

    document.location.reload(true);
  };

  useEffect(() => {
    const currentTemp = JSON.parse(localStorage.getItem('apexTemplate'));
    setCurrentTemplate(currentTemp ? currentTemp : { theme: 'light' });
  }, []);

  return (
    <div
      className={PageHeaderNavClasses('navigationContainer')}
      id="navigationContainer">
      <div className={PageHeaderNavClasses('logoWrapper')} />
      <div className={PageHeaderNavClasses('container')} id="nav-menu">
        <CurvedHeaderIcon className={PageHeaderNavClasses('svg-curves')} />
        <PageHeaderNavMenuComponent
          customClass={PageHeaderNavClasses}
          setNavBarExpanded={setNavBarExpanded}
          navBarExpanded={navBarExpanded}
          navItems={navItems}
        />
        <div className={PageHeaderNavClasses('theme-button')}>
          {currentTemplate.theme === 'light' ? (
            <DarkThemeIcon
              className={PageHeaderNavClasses('theme-icon')}
              onClick={() => changeTheme('dark')}
            />
          ) : (
            <LightThemeIcon
              className={PageHeaderNavClasses('theme-icon')}
              onClick={() => changeTheme('light')}
            />
          )}
        </div>
        {navBarExpanded ? (
          <div
            className={PageHeaderNavClasses('sign-out-button')}
            onClick={logout}>
            {context.t('Sign Out')}
          </div>
        ) : null}
      </div>
    </div>
  );
};

PageHeaderNavComponent.propTypes = {
  setNavBarExpanded: PropTypes.func,
  navBarExpanded: PropTypes.bool,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired
    })
  ).isRequired,
  logout: PropTypes.func
};

PageHeaderNavComponent.contextTypes = {
  t: PropTypes.func.isRequired
};
