import React, { useCallback, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BigNumber from 'bignumber.js';
import { positionByProductIdSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import parseISO from 'date-fns/parseISO';

import { selectAssetBalanceInBaseCurrencyById } from '../../redux/selectors/level1Selector';
import { numberToLocale } from '../../helpers/numberFormatter';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { UrlFactory } from '../../helpers/urlFactory';
import { sanitizeAttributeDate } from '../../helpers/attributeDateSanitizer';
import { VoteButtons } from './VoteButtons/VoteButtonsContainer';
import { VoteTimer } from './VoteTimer/VoteTimer';
import { useIsMobile } from '../../hooks/useIsMobile';
import {
  AUCTION_TEXT_MOVED_OUT,
  AUCTION_TEXT_REMAIN,
  BUY_OUT_RESULT_TEXT_MOVED_OUT,
  BUY_OUT_RESULT_TEXT_REMAIN,
  VOTE_RESULTS,
  VOTE_STATUSES,
  VOTE_TYPES
} from '../../constants/votingConstants';

import './VotePage.css';
import { VoteProgress } from './VoteProgress/VoteProgress';

const classes = getBEMClasses('vote-page');

const CardForVoteComponent = (props, context) => {
  const {
    card,
    currentPriceInBaseCurrency,
    Amount,
    openConfirmVoteModal,
    timer,
    voteStatus,
    changeCardStatus,
    history
  } = props;

  const {
    VoteCardBackgroundImage,
    VoteCardBackground,
    VoteCardTitle,
    VoteCardDescription,
    VoteInfoBannerBackground,
    VoteResult,
    IsVoted,
    CurrentOfferingDescription,
    CardOfferingPreviewImage,
    CurrentOfferingsBackgroundProductColor,
    ProductColor,
    CardOfferingInfoBackground,
    CurrentOfferingTextColor,
    BuyOutBannerBackground,
    BuyOutBannerTextColor
  } = card.productAttributes;
  const { ProductFullName } = card;
  const cardBackground =
    VoteCardBackground ||
    CurrentOfferingsBackgroundProductColor ||
    ProductColor;
  const cardImage = VoteCardBackgroundImage || CardOfferingPreviewImage;
  const cardDescription = VoteCardDescription || CurrentOfferingDescription;
  const cardInfoBannerBackground =
    VoteInfoBannerBackground || CardOfferingInfoBackground;
  const cardTitle = VoteCardTitle || ProductFullName;

  const {
    Product,
    voteAttributes: {
      voteClosed,
      positiveTokensAmount,
      negativeTokensAmount,
      totalTokensAmount,
      isUserVoted,
      votingResult,
      votingType,
      votingStartDate,
      votingEndDate,
      votingPrice,
      id
    }
  } = card;
  const voteType = votingType.toUpperCase();
  const [isVoteClosed, setVoteClosed] = useState(voteClosed);

  const isMobile = useIsMobile();

  const [positiveProgress, negativeProgress] = useMemo(
    () => {
      const totalTokensAmountBN = new BigNumber(totalTokensAmount);
      const positiveTokensAmountBN = new BigNumber(positiveTokensAmount);
      const negativeTokensAmountBN = new BigNumber(negativeTokensAmount);

      const positiveProgress = positiveTokensAmountBN
        .div(totalTokensAmountBN)
        .times(100)
        .toNumber()
        .toFixed(2);

      const negativeProgress = negativeTokensAmountBN
        .div(totalTokensAmountBN)
        .times(100)
        .toNumber()
        .toFixed(2);
      return [
        isNaN(positiveProgress) ? 0 : positiveProgress,
        isNaN(negativeProgress) ? 0 : negativeProgress
      ];
    },
    [totalTokensAmount, positiveTokensAmount]
  );

  const tokenSupplyBigNumber = new BigNumber(Amount);
  const buyOutOfferPriceBN = new BigNumber(votingPrice);
  const proceeds = tokenSupplyBigNumber.isGreaterThan(0)
    ? buyOutOfferPriceBN.div(tokenSupplyBigNumber)
    : 0;
  const pptBigNumber = new BigNumber(currentPriceInBaseCurrency) || 0;
  const marketValue = tokenSupplyBigNumber.times(pptBigNumber).toNumber();
  const onVoteClick = vote => {
    openConfirmVoteModal({
      vote: vote,
      productId: card.ProductId,
      voteType: card.productAttributes.VoteType,
      isModalOpen: true,
      voteId: id
    });
  };

  const getResultStatusText = useCallback(
    () => {
      if (votingResult) {
        if (voteType === VOTE_TYPES.AUCTION) {
          if (votingResult === VOTE_RESULTS.MOVED_OUT) {
            return {
              title: context.t('Sending'),
              subtitle: context.t('To Auction')
            };
          } else {
            return {
              title: context.t('Stay'),
              subtitle: context.t('On the Platform')
            };
          }
        }

        if (voteType === VOTE_TYPES.BUY_OUT) {
          if (votingResult === VOTE_RESULTS.REMAINED) {
            return { title: context.t('Bought Out'), subtitle: '' };
          } else {
            return {
              title: context.t('Stay'),
              subtitle: context.t('On the Platform')
            };
          }
        }
      }
    },
    [votingResult, voteType]
  );

  const getLiveVoteActionText = useCallback(
    () => {
      if (voteStatus === VOTE_STATUSES.LIVE) {
        if (voteType === VOTE_TYPES.AUCTION) {
          if (isMobile) {
            return context.t('Auction off collectible?');
          } else {
            return context.t(
              'Are you sure you want to auction off this asset?'
            );
          }
        }

        if (voteType === VOTE_TYPES.BUY_OUT) {
          return context.t('Collectors, make your decision:');
        }
      }

      return '';
    },
    [voteStatus, voteType, isMobile]
  );

  const getResultStatusDescription = useCallback(
    () => {
      if (votingResult) {
        if (voteType === VOTE_TYPES.AUCTION) {
          if (votingResult === VOTE_RESULTS.MOVED_OUT) {
            return context.t(AUCTION_TEXT_MOVED_OUT);
          } else {
            return context.t(AUCTION_TEXT_REMAIN);
          }
        }

        if (voteType === VOTE_TYPES.BUY_OUT) {
          if (votingResult === VOTE_RESULTS.REMAINED) {
            return context.t(BUY_OUT_RESULT_TEXT_REMAIN);
          } else {
            return context.t(BUY_OUT_RESULT_TEXT_MOVED_OUT);
          }
        }
      }
    },
    [votingResult, voteType]
  );

  return (
    <div
      className={classes('card-container')}
      style={{ '--voteTextColor': CurrentOfferingTextColor || 'white' }}>
      {isVoteClosed && (
        <div className={classes('closed-baner')}>
          {context.t('Vote closed')}
        </div>
      )}
      <div
        key={card.ProductName}
        className={classes('card', isVoteClosed ? 'closed' : null)}
        style={{
          backgroundColor: cardBackground
        }}>
        <div className={classes('left')}>
          {voteType === VOTE_TYPES.BUY_OUT && (
            <div
              className={classes('buyout-banner-container')}
              style={{
                background:
                  BuyOutBannerBackground ||
                  'linear-gradient(115.44deg, #3F8CFF 0%, #825AF6 41.65%, rgba(130, 90, 246, 0.76) 51.89%, rgba(130, 90, 246, 0) 71.4%)',
                color: BuyOutBannerTextColor || 'var(--white1)'
              }}>
              {context.t('Buyout Offer')}
            </div>
          )}
          <div className={classes('left-body')}>
            <div
              className={classes('card-title')}
              onClick={() =>
                history.push(
                  UrlFactory.getMarketPlaceAssetDetailsUrl(card.ProductId)
                )
              }>
              {cardTitle}
            </div>
            <div className={classes('card-description')}>{cardDescription}</div>
            <div
              className={classes(
                'card-info-container',
                voteType === VOTE_TYPES.BUY_OUT && 'buy-out'
              )}>
              {voteType === VOTE_TYPES.BUY_OUT ? (
                <div
                  className={classes('card-info-block', 'buy-out')}
                  style={{ background: cardInfoBannerBackground }}>
                  <div className={classes('card-info-block-title')}>
                    {context.t('Buyout Offer')}
                  </div>
                  <div className={classes('card-info-block-value')}>
                    {numberToLocale(votingPrice).toString()}
                  </div>
                </div>
              ) : (
                <div
                  className={classes('card-info-block')}
                  style={{ background: cardInfoBannerBackground }}>
                  <div className={classes('card-info-block-title')}>
                    {context.t('Your Ownership')}
                  </div>
                  <div className={classes('card-info-block-value')}>
                    {numberToLocale(marketValue).toString()}
                  </div>
                </div>
              )}
              {voteType === VOTE_TYPES.BUY_OUT ? (
                <div
                  className={classes('card-info-block', 'buy-out')}
                  style={{ background: cardInfoBannerBackground }}>
                  <div className={classes('card-info-block-title')}>
                    {context.t('Your Ownership')}
                  </div>
                  <div className={classes('card-info-block-value')}>
                    {Amount.toString()}
                  </div>
                </div>
              ) : (
                <div
                  className={classes('card-info-block')}
                  style={{ background: cardInfoBannerBackground }}>
                  <div className={classes('card-info-block-title')}>
                    {context.t('Your Tokens')}
                  </div>
                  <div className={classes('card-info-block-value')}>
                    {Amount.toString()}
                  </div>
                </div>
              )}
              {voteType === VOTE_TYPES.BUY_OUT && (
                <div
                  className={classes('card-info-block', 'buy-out')}
                  style={{ background: cardInfoBannerBackground }}>
                  <div className={classes('card-info-block-title')}>
                    {context.t('Your Cost')}
                  </div>
                  <div className={classes('card-info-block-value')}>
                    {numberToLocale(proceeds).toString()}
                  </div>
                </div>
              )}
            </div>
            <div className={classes('card-subtitle')}>
              {IsVoted && context.t('Auction Vote Progress')}

              {getLiveVoteActionText()}

              {VoteResult && (
                <div className={classes('result-status-description')}>
                  {getResultStatusDescription()}
                </div>
              )}
            </div>

            <div className={classes('bottom-container')}>
              {Amount > 0 && (
                <VoteButtons
                  customClasses={classes('vote-button')}
                  voteStatus={voteStatus}
                  onVote={onVoteClick}
                  voteClosed={isVoteClosed}
                  isVoted={isUserVoted}
                  voteType={voteType}
                />
              )}

              {(isVoteClosed ||
                isUserVoted ||
                (Amount <= 0 && voteStatus !== VOTE_STATUSES.COUNTDOWN)) &&
                !VoteResult && (
                  <VoteProgress
                    positiveVotes={positiveProgress}
                    negativeVotes={negativeProgress}
                  />
                )}
            </div>
          </div>
        </div>

        <div className={classes('right')}>
          <img
            className={classes(
              'card-image',
              voteStatus === VOTE_STATUSES.RESULT ? 'blured' : ''
            )}
            alt={`${Product}-card`}
            src={cardImage}
          />
          {(!isVoteClosed || VoteResult) && (
            <VoteTimer
              voteStatus={voteStatus}
              changeCardStatus={() => {
                changeCardStatus(id);
              }}
              setVoteClosed={setVoteClosed}
              resultText={getResultStatusText()}
              voteClosed={isVoteClosed}
              votingStartDate={parseISO(sanitizeAttributeDate(votingStartDate))}
              votingEndDate={parseISO(sanitizeAttributeDate(votingEndDate))}
              timer={timer}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CardForVoteComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

CardForVoteComponent.propTypes = {
  card: PropTypes.object,
  currentPriceInBaseCurrency: PropTypes.string,
  Amount: PropTypes.number,
  openConfirmVoteModal: PropTypes.func,
  changeCardStatus: PropTypes.func,
  voteStatus: PropTypes.string,
  timer: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  const { card } = ownProps;
  const { ProductId } = card;

  const assetsBalance = selectAssetBalanceInBaseCurrencyById(state, ProductId);
  const position = positionByProductIdSelector(ProductId)(state);

  return {
    currentPriceInBaseCurrency: assetsBalance.BestOffer,
    Amount: position ? position.Amount : 0
  };
};

export const CardForVote = withRouter(
  connect(
    mapStateToProps,
    null
  )(CardForVoteComponent)
);
