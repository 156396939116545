import { useEffect, useState } from 'react';

//return available tokens by productId and set data to availableTokens reducer
export const useGetAvailableTokens = ({
  availableTokens,
  productId,
  getAvailableTokens
}) => {
  const fetchTokens = async () => {
    try {
      const tokens =
        (availableTokens &&
          availableTokens[productId] &&
          availableTokens[productId].available_tokens) ||
        null;
      setAvailableTokens(tokens);
      const [result] = (await getAvailableTokens({
        productIds: [productId]
      })).available_tokens;

      if (result) {
        setAvailableTokens(result.available_tokens);
      } else {
        setAvailableTokens(0);
      }
    } catch (error) {
      setAvailableTokens(0);
      console.error(error);
    }
  };
  const [newAvailableTokens, setAvailableTokens] = useState(0);

  useEffect(
    () => {
      if (productId) {
        fetchTokens();
      }
    },
    [productId]
  );

  return newAvailableTokens;
};
