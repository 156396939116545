import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {
  buyValue,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';

import './AssetHeader.css';

import APIcon from '../common/APIcon/APIcon';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { numberToLocale } from '../../helpers/numberFormatter';
import {
  BuyButton,
  MarketPlaceButton,
  SellButton
} from '../common/Buttons';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useGetAvailableTokens } from '../../hooks/useGetAvailableTokens';
import { AvailableTokensComponent } from '../common/AvailableTokens/avalaibleTokens';
import SelectAssetModal from '../SelectAssetModal/SelectAssetModalContainer';
import { UrlFactory } from '../../helpers/urlFactory';
import { AddToFavoriteButtonComponent } from '../common/Buttons/AddToFavorites';
import { shareLink } from '../../helpers/shareLinkHelper';

const baseClasses = getBEMClasses('market-place-asset-header');

const AssetHeader = (props, context) => {
  const {
    assetDetails,
    myProductTokens,
    ppt,
    makeOrder,
    isSellDisable,
    onlyLimitAvailable,
    checkIsEnoughOnMarket,
    availableTokens,
    getAvailableTokens,
    instruments,
    selectInstrument,
    history,
    isPublic,
    isFavorite,
    addToFavorite,
    isOffering
  } = props;
  const { product, ProductId, Amount } = assetDetails;
  const isMobile = useIsMobile();

  const {
    productAttributes: { ProductDetailsNumber = 0, ProductDetailsTitle = '' }
  } = product;
  const originUrl = window.location.origin;
  const assetDetailsLink = originUrl + `/asset-details/${ProductId}`;
  const newAvailableTokens = useGetAvailableTokens({
    productId: ProductId,
    availableTokens,
    getAvailableTokens
  });

  const onBuySellCallback = side => {
    if (isPublic) {
      goToPlatform(assetDetails.ProductId);
    } else {
      makeOrder({
        productId: assetDetails.ProductId,
        side,
        onlyLimitAvailable: onlyLimitAvailable || !checkIsEnoughOnMarket(side)
      });
    }
  };

  const onFavoriteClick = productId => {
    if (isPublic) {
      goToPlatform(productId);
    } else {
      addToFavorite(productId);
    }
  };

  const onGoPro = () => {
    const currentInstrument = instruments.find(
      item => item.Product1 === ProductId
    );
    if (currentInstrument) {
      selectInstrument(currentInstrument.InstrumentId);
    }
    history.push(UrlFactory.getProExchangeUrl());
  };

  return (
    <div id="asset-header" className={baseClasses()}>
      <div className={baseClasses('wrapper')}>
        <div className={baseClasses('asset-header-title')}>
          <div className={baseClasses('asset-main-data')}>
            <div className={baseClasses('asset-number')}>
              #{ProductDetailsNumber}
            </div>
            <div className={baseClasses('divider')} />
            <div className={baseClasses('asset-name')}>
              {ProductDetailsTitle}
            </div>
          </div>

          <div className={baseClasses('asset-header-title-buttons')}>
            <AddToFavoriteButtonComponent
              productId={assetDetails.ProductId}
              isFavorite={isFavorite}
              addToFavorite={onFavoriteClick}
            />
            <Share link={assetDetailsLink} isExpanded={isMobile} />
          </div>
        </div>
        <div className={baseClasses('asset-info-block')}>
          <div className={baseClasses('asset-info-block-left')}>
            <div className={baseClasses('info')}>
              <div className={baseClasses('info-key')}>
                {context.t('No. of Tokens for Sale')}
              </div>
              <div className={baseClasses('info-value')}>
                <AvailableTokensComponent
                  availableTokens={newAvailableTokens}
                />
              </div>
            </div>
            {!(isOffering && isPublic) && (
              <div className={baseClasses('info')}>
                <div className={baseClasses('info-key')}>
                  {context.t('Current Price')}
                </div>
                <div className={baseClasses('info-value')}>${ppt}</div>
              </div>
            )}
            {!isPublic && (
              <div className={baseClasses('info')}>
                <div className={baseClasses('info-key')}>
                  {context.t('My Tokens')}
                </div>
                <div className={baseClasses('info-value')}>
                  {numberToLocale(myProductTokens)}
                </div>
              </div>
            )}
          </div>
          <div className={baseClasses('buttons-block')}>
            {!isMobile &&
              !isPublic &&
              !isOffering && (
                <MarketPlaceButton
                  customClass={baseClasses('go-pro-button')}
                  onClick={onGoPro}
                />
              )}

            <BuyButton onClick={() => onBuySellCallback(buyValue)} />
            {Amount > 0 &&
              !isPublic &&
              !isOffering && (
                <SellButton
                  data-tip="You can make only buy order while primary order available"
                  data-tip-disable={!isSellDisable}
                  data-for="tooltip"
                  isDisabled={isSellDisable}
                  onClick={
                    isSellDisable
                      ? () => { }
                      : () => onBuySellCallback(sellValue)
                  }
                />
              )}
            {isMobile &&
              !isPublic &&
              !isOffering && (
                <MarketPlaceButton
                  customClass={baseClasses('go-pro-button')}
                  onClick={onGoPro}
                />
              )}
          </div>
        </div>
      </div>
      <SelectAssetModal />
      <ReactTooltip id="tooltip" />
    </div>
  );
};

const Share = props => {
  const { link, isExpanded } = props;
  return (
    <div
      className={baseClasses('hovering-container', [
        isExpanded ? 'expanded' : 'share'
      ])}>
      <div
        className={baseClasses('hovering-content', [
          isExpanded ? 'expanded' : 'share'
        ])}>
        <a
          href={`https://www.facebook.com/sharer.php?u=${link}`}
          target="_blank"
          rel="noopener noreferrer">
          <APIcon name="facebook" customClass={baseClasses('social-icon')} />
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${link}`}
          target="_blank"
          rel="noopener noreferrer">
          <APIcon name="twitter" customClass={baseClasses('social-icon')} />
        </a>
      </div>
      <div onClick={() => shareLink(link)}>
        <APIcon name="shareV2" customClass={baseClasses('share-icon')} />
      </div>
    </div>
  );
};

const goToPlatform = (assetId) => {
  window.location.href = `${window.location.origin}${UrlFactory.getMarketPlaceAssetDetailsUrl(assetId)}`;
};

Share.propTypes = {
  link: PropTypes.string,
  isExpanded: PropTypes.bool
};

AssetHeader.contextTypes = {
  t: PropTypes.func.isRequired
};

AssetHeader.propTypes = {
  assetDetails: PropTypes.object.isRequired,
  myProductTokens: PropTypes.number.isRequired,
  isFavorite: PropTypes.bool,
  addToFavorite: PropTypes.func.isRequired,
  checkIsEnoughOnMarket: PropTypes.func.isRequired,
  getAvailableTokens: PropTypes.func,
  availableTokens: PropTypes.object,
  ppt: PropTypes.number,
  makeOrder: PropTypes.func,
  isSellDisable: PropTypes.bool,
  onlyLimitAvailable: PropTypes.bool,
  instruments: PropTypes.array,
  selectInstrument: PropTypes.func,
  history: PropTypes.object,
  isPublic: PropTypes.bool,
  isOffering: PropTypes.bool
};

export { AssetHeader };
