import config from '../../../../config';
import { connect } from 'react-redux';
import {
  selectDigitalAssetsByIdWithAttributes,
  usdProductSelector,
  userBalanceByProductSelector
} from '../../../../redux/selectors/productsSelector';
import BuyOutModalComponent from './BuyOutModalComponent';
import BigNumber from 'bignumber.js';
import { ASSETS } from '../../../../constants/assets';
import { MODAL_TYPES, openModal } from '../../../../redux/actions/modalActions';

const mapStateToProps = (state, ownProps) => {
  const { assetId } = ownProps;
  const asset = selectDigitalAssetsByIdWithAttributes(assetId)(state);
  const usdProduct = usdProductSelector(state);
  const userUsdBalance = userBalanceByProductSelector(
    state,
    usdProduct.ProductId
  );
  const maxPrice = userUsdBalance
    .div(1 + config.BuyOutModal.feeMultiplier)
    .toFixed(2, BigNumber.ROUND_DOWN);
  const assetData = ASSETS.find(asset => asset.id === assetId);
  const {
    ProductDetailsTitle,
    AssetDetailsMinimalBuyOutPrice
  } = asset.product.productAttributes;
  return {
    assetName: ProductDetailsTitle,
    userName: state.user.userInfo.UserName,
    userEmail: state.user.userInfo.Email,
    maxPrice,
    minPrice: assetData
      ? assetData.minimalBuyOutPrice
      : AssetDetailsMinimalBuyOutPrice
  };
};

const mapDispatchToProps = dispatch => ({
  openResultModal: result =>
    dispatch(openModal(MODAL_TYPES.BUY_OUT_RESULT_MODAL, { result })),
  openConfirmationModal: (data, closeBuyOut) =>
    dispatch(
      openModal(MODAL_TYPES.BUY_OUT_CONFIRMATION_MODAL, { data, closeBuyOut })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyOutModalComponent);
