import { connect } from 'react-redux';
import get from 'lodash/get';
import BigNumber from 'bignumber.js';
import {
  getLevel2Data,
  subscribeToLevel2,
  unsubscribeLevel2
} from 'apex-web/lib/redux/actions/level2Actions';
import { setInstrument } from 'apex-web/lib/redux/actions/instrumentActions';
import { orderTypes } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';

import {
  isPrimaryMarketInstrumentSelector,
  orderTotalByInstrumentSelector,
  realtimeMarketPriceOfInstrumentSelector,
} from '../../../../redux/selectors/instrumentSelector';
import { calcFee } from '../../../../redux/actions/buySellModalActions';
import { doesOrderPriceComeFromUser, getExpFromPrice, getInputPriceFieldName, roundFormFields } from '../../../../helpers/buySellHelper';
import { digitalAssetWithAttributes } from '../../../../redux/selectors/productsSelector';
import OrderDetails from './OrderDetailsComponent';

const mapStateToProps = (state, ownProps) => {
  const { currentInstrument, orderFee, form } = ownProps;
  const {
    instrument: {
      selectedInstrumentId: globallySubscribedInstrumentId
    },
  } = state;

  const orderCost = orderTotalByInstrumentSelector(state, {
    form: roundFormFields(form, currentInstrument),
    instrumentId: currentInstrument.InstrumentId,
  }) || BigNumber(0);

  const orderTypeSpecificProps = getOrderTypeSpecificProps(state, form);

  const asset = digitalAssetWithAttributes(state, {
    importantAttributes: [],
    productId: currentInstrument.Product1
  });

  const isPrimaryMarketInstrument = isPrimaryMarketInstrumentSelector(state, { instrumentId: currentInstrument.InstrumentId });

  return {
    ...orderTypeSpecificProps,
    cardImageURL: get(asset, 'product.productAttributes.ProductImageURL'),
    productName: get(asset, 'ProductFullName'),
    orderCost,
    isPrimaryMarketInstrument,
    approximateExp: getExpFromPrice(
      get(orderFee, 'product.ProductId') === currentInstrument.Product2 &&
        orderFee.fee ? orderFee.fee : 0,
      form.side
    ),
    globallySubscribedInstrumentId
  };
};

const getOrderTypeSpecificProps = (state, form) => {
  if (form.orderType === orderTypes.market.displayName) {
    const marketPrice = realtimeMarketPriceOfInstrumentSelector(state, {
      side: form.side,
      instrumentId: form.instrumentId
    }) || BigNumber(0);
    // TODO(May 17, 2022): uncomment when we need the warning again. Tip: all commented usages have this comment.
    // const isPriceWarningVisible = isWarningRequired(marketPrice);
    const isPriceWarningVisible = false;
    return {
      displayedPrice: marketPrice,
      isPriceFromInput: false,
      isPriceWarningVisible
    };
  } else {
    const isPriceFromInput = doesOrderPriceComeFromUser(form.orderType);
    return {
      displayedPrice: form[getInputPriceFieldName(form.orderType)],
      isPriceFromInput,
      isPriceWarningVisible: false
    };
  }
};

const mapDispatchToProps = (dispatch) => ({
  calcOrderFee: () => dispatch(calcFee()),
  subscribeModalInstrument: (modalInstrumentId, preModalInstrumentId) => {
    if (preModalInstrumentId !== null) {
      dispatch(unsubscribeLevel2(preModalInstrumentId));
    }
    // TODO(Feb 03, 2022): margin instruments use some other flow, i think,
    // and it might cause problems
    dispatch(setInstrument(modalInstrumentId));
    dispatch(getLevel2Data(modalInstrumentId));
    dispatch(subscribeToLevel2());
    return () => {
      dispatch(unsubscribeLevel2(modalInstrumentId));
      // `undefined` would reset selected instrument if we had no subscription
      // before the modal
      dispatch(setInstrument(preModalInstrumentId || undefined));
      if (preModalInstrumentId !== null) {
        dispatch(getLevel2Data(preModalInstrumentId));
      }
      dispatch(subscribeToLevel2());
    };
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetails);
