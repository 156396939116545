import { DashboardWalletSummaryComponent } from './DashboardWalletSummaryComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  selectAssetsBalanceInBaseCurrency,
  selectBaseAssetBalance,
  selectDigitalAssetsBalanceInBaseCurrency
} from '../../../redux/selectors/level1Selector';
import { digitalAssetsProductsSelector } from '../../../redux/selectors/productsSelector';
import { updateDCAs } from '../../../redux/actions/dcaActions';
import { MODAL_TYPES, openModal } from '../../../redux/actions/modalActions';
import { selectIsTermsOfUseAccepted } from '../../../redux/selectors/userConfigSelector';

const mapStateToProps = state => {
  const assetsDetails = state.productManifest.manifest;

  const USDBallance = selectBaseAssetBalance(state);
  const digitalAssetsBalanceInBaseCurrency = selectDigitalAssetsBalanceInBaseCurrency(
    state
  );
  const {
    rawData: { totalAvailableBalance }
  } = selectAssetsBalanceInBaseCurrency(state);

  const isShowReminder =
    !state.userConfig.isReminderWasShown && Number(totalAvailableBalance) < 50;
  const assetsAttributes = digitalAssetsProductsSelector(state);

  const isWalletDataFetching =
    state.apexCore.instrument.fetching || state.apexCore.position.fetching;
  const isTermsOfUseAccepted = selectIsTermsOfUseAccepted(state);

  return {
    USDBallance,
    digitalAssetsBalanceInBaseCurrency,
    assetsDetails,
    isShowReminder,
    assetsAttributes,
    isWalletDataFetching,
    isTermsOfUseAccepted
  };
};

const mapDispatchToProps = {
  updateAllDCAs: () => updateDCAs(),
  openUpdateTermsOfUseModal: props =>
    openModal(MODAL_TYPES.UPDATE_TERMS_OF_USE_MODAL, {
      ...props,
      isDeposit: true
    })
};

const DashboardWalletSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardWalletSummaryComponent));

export { DashboardWalletSummaryContainer };
