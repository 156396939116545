import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { CurrenciesTableComponent } from './CurrenciesTableComponent';
import { Reminder } from '../../../components/Reminder';
import WalletChart from '../../../components/common/WalletChart/WalletChart';
import SectionHeader from '../../../components/common/SectionHeader/SectionHeader';
import { ModalWithCurrencySelect } from '../../../components/ModalWithCurrencySelect/ModalWithCurrencySelect';
import isEmpty from 'lodash/isEmpty';
import { UrlFactory } from '../../../helpers/urlFactory';
import { Textfit } from 'react-textfit';
import { spotlightDecorator } from '../../../components/Spotlight/spotlightDecorator';
import { SPOTLIGHT_IDS } from '../../../redux/actions/spotlightActions';
import './DashboardWalletSummaryComponent.css';

const baseClass = getBEMClasses('dashboard-wallet-summary');

const DashboardWalletSummaryComponent = (props, context) => {
  const {
    digitalAssetsBalanceInBaseCurrency: {
      totalAvailableBalance,
      assetsWithPriceAndPercent
    },
    USDBallance,
    assetsDetails,
    isShowReminder,
    assetsAttributes,
    isWalletDataFetching,
    updateAllDCAs,
    openUpdateTermsOfUseModal,
    isTermsOfUseAccepted
  } = props;

  useEffect(
    () => {
      !isWalletDataFetching && updateAllDCAs();
    },
    [isWalletDataFetching]
  );

  const [isModalOpened, setIsModalOpened] = useState(false);

  const openDepositModal = useCallback(
    () => {
      setIsModalOpened(!isModalOpened);
    },
    [isModalOpened]
  );

  const openTermsOfUseUpdate = () => {
    openUpdateTermsOfUseModal({
      additionalHandler: () => {
        setIsModalOpened(!isModalOpened);
      }
    });
  };

  const openDepositModalWithTermsCheck = isTermsOfUseAccepted
    ? openDepositModal
    : openTermsOfUseUpdate;

  const filteredData = assetsWithPriceAndPercent
    .filter(item => Number(item.Amount))
    .sort(
      (a, b) => Number(b.PercentOfTotalAmount) - Number(a.PercentOfTotalAmount)
    );

  // do not display anything in the wallet chart if all AmountInBaseCurrency are 0.
  // it usually means that DCAs are not ready yet
  const chartData = filteredData.some(
    item => item.rawData.AmountInBaseCurrency > 0
  )
    ? filteredData.map(item => {
      const assetAttributes = assetsAttributes.find(
        asset => asset.ProductId === item.ProductId
      );

      const assetDetails = assetsDetails[item.ProductSymbol] || {
        productColor: assetAttributes
          ? assetAttributes.ProductColor
          : '#9E9292'
      };

      return {
        label: item.ProductSymbol,
        currency: item.ProductSymbol,
        value: Number(item.PercentOfTotalAmount),
        text: '',
        color: assetDetails.productColor,
        amountUSD: item.AmountInBaseCurrency,
        isOnHold: !item.isMarketRunning
      };
    })
    : [];

  const currenciesTable = filteredData.map(item => {
    const assetAttributes = assetsAttributes.find(
      asset => asset.ProductId === item.ProductId
    );
    const assetDetails = assetsDetails[item.ProductSymbol] || {
      productColor: assetAttributes ? assetAttributes.ProductColor : '#9E9292'
    };
    return {
      currency: item.ProductSymbol,
      currencyColor: assetDetails.productColor,
      amount: item.Amount,
      amountUSD: item.AmountInBaseCurrency,
      percentage: item.PercentOfTotalAmount,
      productSymbol: item.ProductSymbol,
      dca: item.DCA,
      detailsLink: UrlFactory.getMarketPlaceAssetDetailsUrl(item.ProductId)
    };
  });

  return (
    <div
      className={baseClass('wrapper')}
      data-test="dashboard-wallet-summary-root">
      <SectionHeader
        title={context.t('Wallet Summary')}
        link="/wallets"
        iconName="dashboardWallet"
        backgroundColor="#2C2F36"
        viewAllBackgroundColor="#41444D"
      />
      <div
        className={classnames(
          baseClass('chart-with-title'),
          isShowReminder && baseClass('chart-with-title-and-reminder')
        )}>
        {isShowReminder && (
          <div className={baseClass('reminder-wrapper')}>
            <Reminder isSmallPadding />
          </div>
        )}

        <div
          className={classnames(
            baseClass('chart-wrapper'),
            baseClass('chart-wrapper-with-reminder')
          )}>
          <Textfit
            mode="single"
            min={12}
            max={20}
            className={baseClass('available-funds')}>
            {context.t('Available Funds:')}
            {!isEmpty(USDBallance) && (
              <span className={baseClass('available-funds-value')}>
                {`${USDBallance.AvailableBalance} ${USDBallance.ProductSymbol}`}
              </span>
            )}
          </Textfit>
          <AddFundsButton
            openModal={openDepositModalWithTermsCheck}
            disabled={!isTermsOfUseAccepted}
          />
          <div className={baseClass('chart')}>
            <WalletChart
              data={chartData}
              totalAvailableBalance={totalAvailableBalance}
            />
          </div>
        </div>
        <CurrenciesTableComponent
          items={currenciesTable}
          isShowReminder={isShowReminder}
        />
      </div>

      <ModalWithCurrencySelect
        openModal={openDepositModal}
        isModalOpened={isModalOpened}
        //for deposit tabIndex = 0, for withdraw tabIndex = 1
        tabIndex={0}
      />
    </div>
  );
};

DashboardWalletSummaryComponent.propTypes = {
  digitalAssetsBalanceInBaseCurrency: PropTypes.object,
  assetsDetails: PropTypes.object,
  isShowReminder: PropTypes.bool,
  assetsAttributes: PropTypes.array,
  history: PropTypes.object,
  isWalletDataFetching: PropTypes.bool,
  updateAllDCAs: PropTypes.func.isRequired,
  USDBallance: PropTypes.object,
  openUpdateTermsOfUseModal: PropTypes.func,
  isTermsOfUseAccepted: PropTypes.bool
};

DashboardWalletSummaryComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const AddFundsButtonComponent = (props, context) => {
  const { openModal, ...restProps } = props;
  return (
    <button
      className={baseClass('add-funds-button')}
      onClick={openModal}
      type='button'
      {...restProps}>
      {context.t('Add Funds')}
    </button>
  );
};

const AddFundsButton = spotlightDecorator(SPOTLIGHT_IDS.ADD_FUNDS)(
  AddFundsButtonComponent
);

AddFundsButtonComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

AddFundsButtonComponent.propTypes = {
  openModal: PropTypes.func
};

export { DashboardWalletSummaryComponent };
