import { connect } from 'react-redux';
import get from 'lodash/get';
import DepositsStatusComponent from './DepositsStatusComponent';
import { filteredDepositsByProductSelector } from 'apex-web/lib/redux/selectors/depositWithdrawalSelectors';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { decimalsByProductId } from 'apex-web/lib/redux/selectors/productsSelectors';
import { getDepositTickets } from 'apex-web/lib/redux/actions/depositActions';

const mapStateToProps = (state, ownProps) => {
  /**
   * @Todo - remove filtration
   */
  const depositsStatus = filteredDepositsByProductSelector(
    state,
    ownProps.config
  );
  const instruments = instrumentSelectorInstruments(state);
  const decimalPlaces = decimalsByProductId(state);
  const { activeRequests } = state.apexCore.orderHistory;
  const { depositStartIndex } = state.deposit;
  const fetching = activeRequests > 0;

  return {
    data: depositsStatus,
    instruments,
    fetching,
    decimalPlaces,
    selectedAccountId: get(state, 'user.selectedAccountId'),
    depositStartIndex
  };
};

export default connect(
  mapStateToProps,
  {
    getDepositTickets
  }
)(DepositsStatusComponent);
