import React from 'react';
import PropTypes from 'prop-types';
import AffiliateActiveTagContainer from '../../components/AffiliateComponents/AffiliateActiveTagContainer';
import AffiliateCountContainer from '../../components/AffiliateComponents/AffiliateCountContainer';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import path from '../../helpers/path';
import config from '../../config';
import './AffiliateProgramPage.css';

const affiliateProgramPageClasses = getBEMClasses('affiliate-program-page');

const AffiliateProgramPage = (props, context) => (
  <div className={affiliateProgramPageClasses()}>
    <div className={affiliateProgramPageClasses('main-header')}>
      <h1 className={affiliateProgramPageClasses('header')}>
        {context.t('Welcome to the {n} Affiliate Program!', {
          n: config.global.siteName
        })}
      </h1>
    </div>
    <div className={affiliateProgramPageClasses('body-wrapper')}>
      <div className={affiliateProgramPageClasses('body')}>
        <div className={affiliateProgramPageClasses('container')}>
          <div className={affiliateProgramPageClasses('container-header')}>
            <h1
              className={affiliateProgramPageClasses('container-header--title')}>
              {context.t('Affiliate Link')}
            </h1>
          </div>
          <AffiliateActiveTagContainer
            route={`${window.location.origin}${path('')}/signup?aff=`}
          />
        </div>
        <div className={affiliateProgramPageClasses('container')}>
          <div className={affiliateProgramPageClasses('container-header')}>
            <h1
              className={affiliateProgramPageClasses('container-header--title')}>
              {context.t('Number of Active Affiliates')}
            </h1>
          </div>
          <AffiliateCountContainer />
        </div>
      </div>
      <p className={affiliateProgramPageClasses('info-title')}>{context.t('Congratulations on achieving Obsidian tier at Liquid MarketPlace! We are happy to present you with your very own custom affiliate link.')}</p>
      <p className={affiliateProgramPageClasses('info-content')}>
        {context.t('This link can be shared with friends, family, and others, and on any platforms such as social media pages or websites. When individuals sign up for a Liquid MarketPlace account using your personal link, they will be added to your list of referrals. Whenever an individual on this list makes a trade on the MarketPlace, either a buy or a sell, you will receive 25% of the fees they paid, directly to your Liquid MarketPlace account.')}<br />
        {context.t('This commission will automatically be deposited into your Liquid MarketPlace account in the form of USD. You will receive a commission from your referrals for one year after they sign up. There is no limit to the amount of commission you can earn during this one-year period. The affiliate link is a great way to introduce Liquid MarketPlace to those in your network and receive commissions for your efforts! For any questions or concerns, please view our FAQ at')}
        {' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://support.liquidmarketplace.io'>
          support.liquidmarketplace.io
        </a>
        .
      </p>
    </div>
  </div>
);

AffiliateProgramPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default AffiliateProgramPage;
