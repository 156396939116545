import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { USER_TIERS } from '../../../constants/userTiers';
import './UserXP.css';
import UserProgress from './UserProgress';

const classes = getBEMClasses('user-tier-xp');

const UserXP = (props, context) => {
  const { userMonthTier, userExp, userExpHold, userTier } = props;
  const currentTierDetails = USER_TIERS[userMonthTier > 8 ? 8 : userMonthTier];
  const allTimeTierDetails = USER_TIERS[userTier > 8 ? 8 : userTier];
  const nextTierDetails =
    userMonthTier < 8 ? USER_TIERS[userMonthTier + 1] : USER_TIERS[8];
  const roundedUserExp = Math.floor(userExp);
  const roundedUserExpHold = Math.floor(userExpHold);
  return (
    <div className={classes('wrapper')}>
      <div className={classes('title-wrapper')}>
        <p className={classes('level-title')}>
          {context.t('Level')} {currentTierDetails.tierNumber}
        </p>
        <p className={classes('tier-name')}>
          {allTimeTierDetails.tierName} {context.t('Tier')}
        </p>
      </div>
      <div className={classes('divider')} />
      <UserProgress
        currentTierDetails={currentTierDetails}
        nextTierDetails={nextTierDetails}
        userExp={roundedUserExp}
        userExpHold={roundedUserExpHold}
      />
    </div>
  );
};

UserXP.contextTypes = {
  t: PropTypes.func.isRequired
};

UserXP.propTypes = {
  userMonthTier: PropTypes.number,
  userExp: PropTypes.number,
  userExpHold: PropTypes.number,
  userTier: PropTypes.number
};

export default UserXP;
