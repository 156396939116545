import { createSelector } from 'reselect';
import { ROUTES } from '../../constants/routes';

export const getLinks = createSelector(
  [state => state.router.location.pathname],
  pathname => {
    // looking for a route among the route table
    const route = ROUTES.find(route => {
      const matchedPath = pathname.match(route.path);
      // matchedPath[0] it's string which was matched from pathname
      if (matchedPath && matchedPath[0] === pathname) {
        return true;
      } else {
        return false;
      }
    });
    
    // condition for wrong url
    if (route === undefined) {
      return {
        links: [],
      };
    }

    return {
      links: route.links,
    };
  },
);
