import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './APTypography.css';
import * as CONSTANTS from './constants';

const classes = getBEMClasses('ap_typography');

export const APTypography = props => {
  const {
    color = 'inherit',
    fontSize = 'body',
    opacity = 'normal',
    weight = 'normal',
    bold = false,
    children,
    underline = false,
    className = '',
    style = {},
    noWrap = false,
    inline = false,
    notSelectable = false,
    onClick = () => {},
    dataTip
  } = props;

  const typographyProps = {
    onClick,
    style: {
      ...style
    },
    className: classNames(
      classes('color', [color]),
      classes('fontSize', [fontSize]),
      classes('opacity', [opacity]),
      classes('weight', [weight]),
      bold && classes('weight', 'bold'),
      underline && classes('underline'),
      className && className,
      noWrap && classes('noWrap'),
      notSelectable && classes('notSelectable')
    )
  };

  if (inline) {
    return (
      <span {...typographyProps} data-tip={dataTip}>
        {children}
      </span>
    );
  }

  return (
    <div {...typographyProps} data-tip={dataTip}>
      {children}
    </div>
  );
};

APTypography.propTypes = {
  color: PropTypes.oneOf(Object.values(CONSTANTS.TYPOGRAPHY_COLOR)),
  fontSize: PropTypes.oneOf(Object.values(CONSTANTS.FONT_SIZES)),
  opacity: PropTypes.oneOf(Object.values(CONSTANTS.TYPOGRAPHY_OPACITY)),
  weight: PropTypes.oneOf(Object.values(CONSTANTS.FONT_WEIGHT)),
  bold: PropTypes.bool,
  underline: PropTypes.bool,
  className: PropTypes.string,
  noWrap: PropTypes.bool,
  inline: PropTypes.bool,
  notSelectable: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  dataTip: PropTypes.string
};
