import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';

import './VoteInfoModals.css';

import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { Modal } from '../../../components/common/Modal/Modal';
import { CheckBox } from '../../../components/common/Checkbox';
import { modalDecorator } from '../../../components/common/Modal/modalDecorator';
import { MODAL_TYPES } from '../../../redux/actions/modalActions';
import {
  VOTE_NO_CONFIRM_AUCTION_TEXT,
  VOTE_NO_CONFIRM_BUY_OUT_TEXT,
  VOTE_TYPES,
  VOTE_YES,
  VOTE_YES_CONFIRM_AUCTION_TEXT,
  VOTE_YES_CONFIRM_BUY_OUT_TEXT
} from '../../../constants/votingConstants';

const classes = getBEMClasses('confirm-vote-modal');

const ConfirmVoteModalComponent = (props, context) => {
  const [confirm, setConfirm] = useState(false);
  const {
    isOpen,
    close,
    voteType,
    productId,
    vote,
    confirmVote,
    voteId
  } = props;
  let description = '';

  if (voteType === VOTE_TYPES.AUCTION) {
    description =
      vote === VOTE_YES
        ? VOTE_YES_CONFIRM_AUCTION_TEXT
        : VOTE_NO_CONFIRM_AUCTION_TEXT;
  } else {
    description =
      vote === VOTE_YES
        ? VOTE_YES_CONFIRM_BUY_OUT_TEXT
        : VOTE_NO_CONFIRM_BUY_OUT_TEXT;
  }

  useEffect(() => {
    return () => setConfirm(false);
  }, []);

  const onVoteConfirm = () => {
    confirmVote(productId, vote, voteId);
    setConfirm(false);
    close();
  };

  const onClose = () => {
    setConfirm(false);
    close();
  };

  return (
    <Modal
      customClass={classes()}
      isOpen={isOpen}
      close={close}
      onCancel={onClose}
      showHeader={false}
      showCloseIcon={false}>
      <div className={classes('body')}>
        <div className={classes('title')}>{context.t('Please confirm!')}</div>
        <div className={classes('description')}>
          {context.t('Read Carefully')}
        </div>
        <div className={classes('description')}>{context.t(description)}</div>
        <div className={classes('description')}>
          {context.t(
            'Once you have placed your vote, there is no way to change it. Liquid MarketPlace will not be able to change your vote once it has been confirmed.'
          )}
        </div>
        <div className={classes('checkbox-container')}>
          <CheckBox
            customClass={classes('checkbox')}
            id={'confirm_vote'}
            value={confirm}
            handleChange={() => {
              setConfirm(!confirm);
            }}
          />
          {vote === VOTE_YES
            ? context.t(
                'Yes, I confirm. I want to auction off this collectible on my behalf.'
              )
            : context.t("No, I don't want this to go to auction on my behalf.")}
        </div>

        <div className={classes('buttons')}>
          <button
            disabled={!confirm}
            className={classes('next-button', vote === VOTE_YES ? 'yes' : 'no')}
            onClick={onVoteConfirm}>
            {context.t('Next')}
          </button>

          <button className={classes('cancel-button')} onClick={onClose}>
            {context.t('Cancel')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmVoteModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

ConfirmVoteModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  voteType: PropTypes.string,
  productId: PropTypes.number,
  vote: PropTypes.string,
  confirmVote: PropTypes.func,
  voteId: PropTypes.string
};

export const ConfirmVoteModal = modalDecorator({
  name: MODAL_TYPES.CONFIRM_VOTE_MODAL
})(ConfirmVoteModalComponent);
