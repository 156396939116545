import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SelectAssetModalComponent } from './SelectAssetModalComponent';
import { MODAL_TYPES, openModal } from '../../redux/actions/modalActions';
import { modalDecorator } from '../common/Modal/modalDecorator';
import { selectInstrument } from 'apex-web/lib/redux/actions/instrumentActions';
import {
  selectedInstrumentSelector,
  instrumentSelectorInstruments
} from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { selectDigitalAssetsWithAttributesNotUndefined } from '../../redux/selectors/productsSelector';
import { isProductSoldOutSelector } from '../../redux/selectors/productsSelector';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { destroy } from 'redux-form';

const mapStateToProps = state => {
  const instrument = state.apexCore.instrument;
  const { fetching } = instrument;
  const { verificationLevel } = kycVerificationLevelsSelector(state);
  const selectedInstrument = selectedInstrumentSelector(state);
  const availableInstruments = instrumentSelectorInstruments(state).filter(
    instrument =>
      isProductSoldOutSelector(state, instrument.Product1) ||
      verificationLevel === 99
  );
  const assets = selectDigitalAssetsWithAttributesNotUndefined([
    'Rolling24HrPxChangePercent',
    'Rolling24NumTrades'
  ])(state);
  const availableAssets = assets.filter(
    asset =>
      isProductSoldOutSelector(state, asset.ProductId) ||
      verificationLevel === 99
  );

  return {
    instruments: availableInstruments,
    assets: availableAssets,
    selectedInstrument,
    fetching
  };
};

const mapDispatchToProps = dispatch => ({
  selectInstrument: instrumentId => {
    dispatch(selectInstrument(instrumentId));
  },
  clearProForm: () => dispatch(destroy('orderEntry')),
  openWatchListModal: onBack => {
    dispatch(
      openModal(MODAL_TYPES.SELECT_ASSET_FROM_WATCH_LIST_MODAL, { onBack })
    );
  }
});

const SelectAssetModal = modalDecorator({
  name: MODAL_TYPES.SELECT_ASSET_MODAL
})(SelectAssetModalComponent);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SelectAssetModal));
