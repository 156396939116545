import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

import { OfferingsSection } from '../../../components/common/OfferingsSection';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import SoldOutBanner from '../../../images/sold-out.png';
import { buyValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import SectionHeader from '../../../components/common/SectionHeader/SectionHeader';
import {
  AddToFavoriteButton,
  BuyButton,
  ViewDetailsButton
} from '../../../components/common/Buttons';
import { UrlFactory } from '../../../helpers/urlFactory';
import { AvailableTokensComponent } from '../../../components/common/AvailableTokens/avalaibleTokens';
import Slider from 'react-slick';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useDebounce } from '../../../hooks/useDebounce';
import { useModifyOffering } from '../../../hooks/useModifyOffering';
import NewOfferingDesktopBackground from './images/new-offerings-desktop-back.png';
import NewOfferingMobileBackground from './images/new-offerings-mobile-back.png';
import NewOfferingDesktopText from './images/new-offerings-desktop-text.png';
import NewOfferingMobileText from './images/new-offerings-mobile-text.png';
import { InformationButton } from '../../../components/common/InformationButton/InformationButton';
import {
  NextSliderArrow,
  PrevSliderArrow
} from '../../../components/common/SliderArrows';
import './CardOfferings.css';

const classes = getBEMClasses('card-offerings');

export const CardOfferingsComponent = (props, context) => {
  const {
    isReady,
    makeOrder,
    history,
    availableTokens,
    getAvailableTokens
  } = props;
  const isMobile = useIsMobile();
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    nextArrow: isMobile ? null : (
      <NextSliderArrow iconName="rightSliderArrow" />
    ),
    prevArrow: isMobile ? null : <PrevSliderArrow iconName="leftSliderArrow" />,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: isMobile
  };

  const debounceCurrentOffering = useDebounce(props.offerings.length, 100);

  const modifiedOfferings = useModifyOffering({
    offerings: props.offerings,
    triggerUseEffect: debounceCurrentOffering,
    getAllTokens: true,
    availableTokens,
    getAvailableTokens
  });

  const offerings = modifiedOfferings.length
    ? modifiedOfferings
    : props.offerings;

  return (
    <OfferingsSection baseClass={classes}>
      <SectionHeader
        title={context.t('Offerings')}
        iconName="dashboardOfferings"
        link="/offerings"
        backgroundColor="#7C5CED"
        viewAllBackgroundColor="#957bef"
      />
      {!isReady ? (
        <div className={classes('noAssets')} data-test="no-offerings">
          <Spinner />
        </div>
      ) : offerings.length ? (
        <Slider {...sliderSettings}>
          {offerings.map((product, index) => {
            const backgroundImageURL = product.productImageBackground
              ? product.productImageBackground
              : product.productPreviewURL;
            return (
              <div
                key={index}
                className={classes('offering-slide')}
                data-test="offering-item">
                <div
                  className={classes('offering-wrapper')}
                  style={{
                    background:
                      product.productOfferingBackgroundColor ||
                      'linear-gradient(var(--purple3), var(--purple2))',
                    '--currentOfferingTextColor':
                      product.CurrentOfferingTextColor
                  }}>
                  {' '}
                  {product.isSoldOut && (
                    <img
                      src={SoldOutBanner}
                      className={classes('sold-out-banner')}
                      alt="sold-out-banner"
                    />
                  )}
                  <div className={classes('backgroundWrapper')}>
                    <img
                      className={`${classes(
                        'backgroundWrapper--image'
                      )} ${!product.productImageBackground &&
                        classes('backgroundImageFaded')}`}
                      src={backgroundImageURL}
                      alt="background"
                    />
                  </div>
                  <div className={classes('cardCoverWrapper')}>
                    <div className={classes('cardCover')}>
                      <img
                        src={product.productOfferingImage}
                        alt="card"
                        className={
                          product.productOfferingImage && classes('cardImage')
                        }
                        style={{ '--opacity': product.isSoldOut ? 0.6 : 1 }}
                      />
                    </div>
                  </div>
                  <div className={classes('content')}>
                    <div className={classes('name-collection')}>
                      <div className={classes('productName')}>
                        {product.productName}
                      </div>
                      <div className={classes('productCollection')}>
                        {product.productCollection}
                      </div>
                    </div>
                    <div className={classes('rowContainer')}>
                      <div
                        className={classes('numberInfo')}
                        style={{
                          background:
                            product.ProductSizeBackgroundSecondaryColor ||
                            'transparent'
                        }}>
                        <div className={classes('numberTitle')}>
                          {context.t('Population')}
                          <div className={classes('populationInfo-icon')}>
                            <InformationButton
                              text={
                                'Total population of an asset.\nHow many are currently in circulation around the world'
                              }
                              iconColor={product.CurrentOfferingTextColor}
                            />
                          </div>
                        </div>
                        <div className={classes('numberText')}>
                          {product.rarity}
                        </div>
                      </div>
                      <div
                        className={classes('numberInfo', 'bigger')}
                        style={{
                          background:
                            product.ProductSizeBackgroundSecondaryColor ||
                            'transparent'
                        }}>
                        <div className={classes('numberTitle')}>
                          {context.t('Available tokens')}
                        </div>
                        <div className={classes('numberText')}>
                          <AvailableTokensComponent
                            availableTokens={product.available_tokens}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={classes('productDescription')}>
                      {product.productDescription
                        ? product.productDescription
                            .split(' ')
                            .slice(0, 15)
                            .join(' ')
                        : ''}{' '}
                      ...
                    </div>
                    <div className={classes('footerWrapper')}>
                      <AddToFavoriteButton
                        productId={product.ProductId}
                        isUseHoverState={product.ProductId === 35}
                      />

                      <ViewDetailsButton
                        customClass={classes('view-details-button')}
                        onClick={() =>
                          history.push(
                            UrlFactory.getOfferingsAssetDetailsUrl(
                              product.ProductId
                            )
                          )
                        }
                      />
                      <BuyButton
                        customClass={classes('buy-button')}
                        onClick={() =>
                          makeOrder({
                            productId: product.ProductId,
                            side: buyValue,
                            onlyLimitAvailable: product.onlyLimitAvailable
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      ) : (
        <div className={classes('no-offerings-wrapper')}>
          <img
            src={
              isMobile
                ? NewOfferingMobileBackground
                : NewOfferingDesktopBackground
            }
            className={classes('no-offerings-background')}
            alt="back"
          />
          <img
            src={isMobile ? NewOfferingMobileText : NewOfferingDesktopText}
            className={classes('no-offerings-text')}
            alt="text"
          />
        </div>
      )}
    </OfferingsSection>
  );
};

CardOfferingsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

CardOfferingsComponent.propTypes = {
  getAvailableTokens: PropTypes.func,
  availableTokens: PropTypes.object,
  isReady: PropTypes.bool.isRequired,
  isFavorite: PropTypes.bool,
  makeOrder: PropTypes.func,
  offerings: PropTypes.arrayOf(
    PropTypes.shape({
      productOfferingImage: PropTypes.string,
      productPreviewURL: PropTypes.string,
      productImageBackground: PropTypes.string,
      productName: PropTypes.string,
      productCollection: PropTypes.string,
      rarity: PropTypes.string,
      tokensAvailable: PropTypes.number,
      productDescription: PropTypes.string,
      ProductId: PropTypes.number,
      productOfferingBackgroundColor: PropTypes.string,
      primaryMarketOrderId: PropTypes.string,
      ProductSizeBackgroundSecondaryColor: PropTypes.string
    })
  ),
  history: PropTypes.object
};
