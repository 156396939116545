import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { SectionPresetSort } from '../../../components/common/SectionPresetSort';
import { UpcomingOfferingCard } from '../../../components/common/UpcomingOfferingCard/UpcomingOfferingCard';
import { APSection } from '../../../components/common/APSection';
import { UrlFactory } from '../../../helpers/urlFactory';
import { buyValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { checkIsCorrectAssetAttribute } from '../../../redux/selectors/productsSelector';
import { sortAssets } from '../../../helpers/sortHelper';
import { createTimer } from '../../../helpers/timerFactoryHelper';
import Logo from '../../../images/icon-logo-big.png';

import './UpcomingOfferings.css';

const classes = getBEMClasses('upcoming-offerings');

export const UpcomingOfferings = (props, context) => {
  const {
    cards,
    isReady,
    history,
    isLiveOfferings = false,
    makeOrder,
    checkIsEnoughOnMarket,
    availableTokens
  } = props;
  const [currentSortOption, setCurrentSortOption] = useState('');
  const [isAscending, setIsAscending] = useState(false);

  const [timer, setTimer] = useState();
  useEffect(
    () => {
      if (isLiveOfferings) {
        setTimer(prevTimer => {
          prevTimer && prevTimer.reset();
          return createTimer(5000);
        });
      }
      return () => {
        setTimer(prevTimer => {
          prevTimer && prevTimer.reset();
          return undefined;
        });
      };
    },
    [isLiveOfferings]
  );

  // serializing cards before sorting as it mutates the original array
  const serializedCards = serializeCards(cards);
  const items = React.useMemo(
    () =>
      cards
        .map(c => ({
          ...c,
          availableTokens:
            get(availableTokens, [c.productId, 'available_tokens']) || 0
        }))
        .sort((a, b) => sortAssets(a, b, currentSortOption, isAscending))
        .map(elem => (
          <UpcomingOfferingCard
            key={elem.productId}
            title={elem.title}
            rarityCategory={elem.rarityCategory}
            rarity={elem.rarity}
            primaryMarketOrderId={elem.primaryMarketOrderId}
            cardImageURL={elem.cardImageURL}
            availableTokens={elem.availableTokens}
            totalSize={elem.totalSize}
            timer={timer}
            voteStartDate={elem.voteStartDate}
            isLiveOffering={isLiveOfferings}
            onViewDetails={() =>
              history.push(
                UrlFactory.getOfferingsAssetDetailsUrl(elem.productId)
              )
            }
            onBuyButton={
              isLiveOfferings
                ? () => {
                    makeOrder({
                      productId: elem.productId,
                      side: buyValue,
                      onlyLimitAvailable:
                        !checkIsEnoughOnMarket(buyValue, elem.productId) ||
                        (checkIsCorrectAssetAttribute(elem.soldOut, 'false') &&
                          elem.onlyLimitAvailable)
                    });
                  }
                : undefined
            }
            productId={elem.productId}
            isSoldOut={checkIsCorrectAssetAttribute(elem.soldOut, 'true')}
          />
        )),
    [
      // serialize to allow correct reference equality checks
      serializeAvailableTokens(availableTokens),
      currentSortOption,
      serializedCards,
      timer
    ]
  );
  const handleClickAscending = value => {
    setIsAscending(true);
    setCurrentSortOption(value);
  };

  const handleClickDescending = value => {
    setIsAscending(false);
    setCurrentSortOption(value);
  };

  const sortOptions = [
    {
      value: 'title',
      //context.t("Name")
      label: 'Name',
      onClick: handleClickAscending
    },
    {
      value: 'availableTokens',
      //context.t("Tokens")
      label: 'Tokens',
      onClick: handleClickDescending
    }
  ];

  const sectionName = isLiveOfferings
    ? context.t('Live Offerings')
    : context.t('Upcoming Offerings');

  if (!isReady) {
    return isLiveOfferings ? (
      <div className={classes()}>
        <APSection title={sectionName} customClass={classes()}>
          <div className={classes('no-products')}>
            <img
              src={Logo}
              className={classes('no-products-logo')}
              alt="logo"
            />
            No Offering is available
            <div
              className={classes('no-products-button')}
              onClick={() => history.push(UrlFactory.getMarketPlaceUrl())}>
              Go to Marketplace
            </div>
          </div>
        </APSection>
      </div>
    ) : null;
  }

  if (isReady && !isLiveOfferings && items.length === 0) {
    return (
      <div className={classes()}>
        <APSection title={sectionName} customClass={classes()}>
          <div className={classes('no-products')}>
            <img
              src={Logo}
              className={classes('no-products-logo')}
              alt="logo"
            />
            No Offering is available
            <div
              className={classes('no-products-button')}
              onClick={() => history.push(UrlFactory.getMarketPlaceUrl())}>
              Go to Marketplace
            </div>
          </div>
        </APSection>
      </div>
    );
  }

  return (
    <div className={classes()}>
      <SectionPresetSort
        title={sectionName}
        sortOptions={sortOptions}
        currentSortOption={currentSortOption}>
        <div className={classes('content')}>{items}</div>
      </SectionPresetSort>
    </div>
  );
};

const serializeAvailableTokens = availableTokens =>
  availableTokens &&
  Object.values(availableTokens)
    .map(o => o.available_tokens)
    .join(';');

const serializeCards = cards => cards.map(c => c.productId).join(';');

UpcomingOfferings.contextTypes = {
  t: PropTypes.func.isRequired
};

UpcomingOfferings.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      rarityCategory: PropTypes.string.isRequired,
      rarity: PropTypes.string.isRequired,
      cardImageURL: PropTypes.string.isRequired,
      productId: PropTypes.number.isRequired
    })
  ),
  availableTokens: PropTypes.object,
  isReady: PropTypes.bool.isRequired,
  history: PropTypes.object,
  isLiveOfferings: PropTypes.bool,
  makeOrder: PropTypes.func,
  checkIsEnoughOnMarket: PropTypes.func
};
