import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import leftIcon from './gallery_left.png';
import rightIcon from './gallery_right.png';
import '../styles/components/gallery.css';

const Lightbox = props => {
  const {
    showLightbox,
    imageIndex,
    imageSet,
    closeLightbox,
    openLightbox
  } = props;
  const displayLightbox = showLightbox ? 'block' : 'none';
  if (imageIndex === false) {
    return <React.Fragment />;
  } else {
    const image = imageSet[imageIndex];
    const nextIndex = (imageIndex + 1) % imageSet.length;
    const prevIndex =
      imageIndex - 1 >= 0 ? imageIndex - 1 : imageSet.length - 1;

    return ReactDOM.createPortal(
      <div
        className="lightbox-overlay"
        style={{ display: displayLightbox }}
        onClick={closeLightbox}>
        <div className="lightbox-wrapper" onClick={closeLightbox}>
          <div
            className="lightbox-arrow"
            onClick={e => openLightbox(e, prevIndex)}>
            <img src={leftIcon} alt="" />
          </div>
          <div className="lightbox">
            <img
              src={image.image}
              alt={image.altText}
              className="lightbox-image"
            />
          </div>
          <div
            className="lightbox-arrow"
            onClick={e => openLightbox(e, nextIndex)}>
            <img src={rightIcon} alt="" />
          </div>
        </div>
      </div>,
      document.body
    );
  }
};

Lightbox.propTypes = {
  showLightbox: PropTypes.bool,
  imageIndex: PropTypes.bool,
  imageSet: PropTypes.array,
  closeLightbox: PropTypes.func,
  openLightbox: PropTypes.func
};

const Gallery = props => {
  const { images } = props;
  const [openGalleryLightbox, setOpenGalleryLightbox] = useState(false);
  const [imageIndex, setImageIndex] = useState(false);

  const formattedImages = images.map((value, i) => {
    return (
      <div
        key={i}
        className="gallery-image-container"
        onClick={e => openLightbox(e, i)}>
        <img src={value.image} alt={value.altText} className="gallery-image" />
      </div>
    );
  });

  const openLightbox = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenGalleryLightbox(true);
    setImageIndex(index);
    return false;
  };

  const closeLightbox = () => {
    setOpenGalleryLightbox(false);
  };

  return (
    <div className="gallery-wrapper">
      <p className="gallery-title">Gallery</p>
      <div className="gallery-images">{formattedImages}</div>
      <Lightbox
        showLightbox={openGalleryLightbox}
        closeLightbox={closeLightbox}
        imageSet={images}
        imageIndex={imageIndex}
        openLightbox={openLightbox}
      />
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.array
};

export default Gallery;
