import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../../common/Modal/Modal';
import { getBEMClasses } from '../../../../helpers/cssClassesHelper';
import { modalDecorator } from '../../../common/Modal/modalDecorator';
import { MODAL_TYPES } from '../../../../redux/actions/modalActions';
import './BuyOutModal.css';
import BuyOutModalContainer from './BuyOutModalContainer';

const classes = getBEMClasses('buy-out-form');

const BuyOutModal = (props, context) => {
  const { isOpen, close } = props;
  return (
    <Modal
      customClass={classes()}
      isOpen={isOpen}
      close={close}
      showCloseIcon={false}
      titleComponent={
        <div className={classes('header')}>{context.t('Submit Form')}</div>
      }>
      <BuyOutModalContainer {...props} />
    </Modal>
  );
};

BuyOutModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func
};

BuyOutModal.contextTypes = {
  t: PropTypes.func
};

export default modalDecorator({ name: MODAL_TYPES.BUY_OUT_MODAL })(BuyOutModal);
