import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../../helpers/cssClassesHelper';
import BigNumber from 'bignumber.js';
import config from '../../../../config';

const classes = getBEMClasses('buy-out-form');

export const BUY_OUT_RESULTS = {
  CONFIRMATION: 'CONFIRMATION',
  SUCCESS: 'SUCCESS',
  REJECT: 'REJECT'
};

const BuyOutModalComponent = (props, context) => {
  const {
    userName,
    userEmail,
    assetName,
    close,
    maxPrice,
    minPrice,
    openResultModal,
    openConfirmationModal
  } = props;
  const [price, setPrice] = useState('');
  const [comments, setComments] = useState('');
  const [isShowMinPriceError, setIsShowMinPriceError] = useState(false);

  const handleSubmit = () => {
    if (price > Number(maxPrice)) {
      openResultModal(BUY_OUT_RESULTS.REJECT);
    } else {
      openConfirmationModal(
        {
          userName,
          userEmail,
          assetName,
          price,
          comments
        },
        close
      );
    }
  };

  return (
    <React.Fragment>
      <form
        onSubmit={e => {
          e.preventDefault();
          if (price < minPrice) {
            setIsShowMinPriceError(true);
            return false;
          } else {
            handleSubmit();
          }
        }}>
        <div className={classes('input-wrapper')}>
          <p className={classes('input-label')}>{context.t('Full Name')}:</p>
          <div className={classes('input')}>{userName}</div>
        </div>
        <div className={classes('input-wrapper')}>
          <p className={classes('input-label')}>{context.t('Item’s Name')}:</p>
          <div className={classes('input')}>{assetName}</div>
        </div>
        <div className={classes('input-wrapper')}>
          <p className={classes('input-label')}>{context.t('Price')}:</p>
          <input
            required
            className={classes('input', isShowMinPriceError && 'input-error')}
            onChange={e => {
              if (isShowMinPriceError) {
                setIsShowMinPriceError(false);
              }
              setPrice(e.target.value);
            }}
            value={price}
            type="number"
            step={0.01}
          />
          {isShowMinPriceError ? (
            <div className={classes('input-warning')}>
              Price must be greater than or equal to {minPrice.toLocaleString()}$
            </div>
          ) : null}
        </div>
        <div className={classes('input-wrapper')}>
          <p className={classes('input-label')}>{context.t('Fee')}:</p>
          <div className={classes('input')}>{price ? calcFee(price) : ''}</div>
        </div>
        <div className={classes('input-wrapper')}>
          <p className={classes('input-label')}>{context.t('Comments')}:</p>
          <div className={classes('textarea-wrapper')}>
            <textarea
              className={classes('input-area')}
              rows={4}
              onChange={e => {
                setComments(e.target.value);
              }}
              value={comments}
            />
          </div>
        </div>
        <div className={classes('buttons')}>
          <button className={classes('go-back')} onClick={close} type="button">
            {context.t('Go Back')}
          </button>
          <button className={classes('submit')} type="submit">
            {context.t('Submit')}
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

const calcFee = price => {
  return (price * config.BuyOutModal.feeMultiplier).toFixed(2);
};

BuyOutModalComponent.propTypes = {
  close: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  assetName: PropTypes.string.isRequired,
  maxPrice: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(BigNumber)
  ]).isRequired,
  minPrice: PropTypes.number,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func.isRequired,
  openResultModal: PropTypes.func.isRequired,
  openConfirmationModal: PropTypes.func.isRequired
};

BuyOutModalComponent.contextTypes = {
  t: PropTypes.func
};

export default BuyOutModalComponent;
