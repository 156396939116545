import { SET_ACCOUNT_PUBLIC_PROFILE } from '../actions/accountActions';

const initialState = {};

export default function accountConfigReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ACCOUNT_PUBLIC_PROFILE:
      return {
        ...state,
        publicProfile: payload.publicProfile
      };
    default:
      return state;
  }
}
