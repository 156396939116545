import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import './InstrumentInfoSlider.css';

const baseClasses = getBEMClasses('instrument-info-item');

const InstrumentInfoItem = props => {
  const { value, label, classModifiers = [] } = props;

  return (
    <div className={baseClasses('', classModifiers)}>
      <span className={baseClasses('value', classModifiers)}>{value}</span>
      <span className={baseClasses('title')}>{label}</span>
    </div>
  );
};

InstrumentInfoItem.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  label: PropTypes.string.isRequired,
  classModifiers: PropTypes.arrayOf(PropTypes.string)
};

export default InstrumentInfoItem;
