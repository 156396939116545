import { AssetDetailsComponent } from './AssetDetailsComponent';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  checkIsOffering,
  selectDigitalAssetsByIdWithAttributes
} from '../../redux/selectors/productsSelector';
import { MODAL_TYPES, openModal } from '../../redux/actions/modalActions';
import { receiveProductAttributes } from 'apex-web/lib/redux/actions/productActions';

// TODO(Jun 07, 2022): get this data from the new admin panel when it's ready?
const zeroPptAssets = ['GATA', 'CHTA', 'AMTA'];
const customPptAssets = ['NEOTA'];

const mapStateToProps = (state, ownProps) => {
  const assetId = ownProps.assetId || state.asset.currentAssetId;
  const asset = selectDigitalAssetsByIdWithAttributes(assetId)(state, {
    withoutBalance: true
  });
  const assetAttributes = asset.product.productAttributes;
  const isUseZeroPPT =
    zeroPptAssets.includes(asset.ProductSymbol) ||
    checkIsOffering(assetAttributes);
  const isCustomPptAsset = customPptAssets.includes(asset.ProductSymbol);
  const instrument = state.apexCore.instrument.instruments.find(
    instr => instr.Product1 === assetId
  );
  let ppt;
  if (instrument) {
    ppt = get(state, [
      'apexCore',
      'level1',
      instrument.InstrumentId,
      'BestOffer'
    ]);
  }
  return {
    assetAttributes,
    assetId,
    isUseZeroPPT,
    ppt,
    isCustomPptAsset,
    productShortName: asset && asset.product.Product
  };
};

const mapDispatchToProps = dispatch => ({
  onBuyOutClick: assetId =>
    dispatch(openModal(MODAL_TYPES.BUY_OUT_MODAL, { assetId })),
  setAssetAttributes: (assetId, attributes) =>
    dispatch(
      receiveProductAttributes(
        Object.entries(attributes).map(([key, value]) => ({
          KeyName: key,
          KeyValue: value,
          OMSId: 1,
          ProductId: assetId
        })),
        assetId
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetDetailsComponent);
