import InstrumentSummaryComponent from './InstrumentSummaryComponent';
import { connect } from 'react-redux';
import { selectDigitalAssetsByIdWithAttributes } from '../../redux/selectors/productsSelector';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { MODAL_TYPES, openModal } from '../../redux/actions/modalActions';

const mapStateToProps = state => {
  const selectedInstrument = selectedInstrumentSelector(state);
  const assetId = selectedInstrument.Product1;
  const assetDetails = selectDigitalAssetsByIdWithAttributes(assetId)(state);
  // TODO(May 17, 2022): uncomment when we need the warning again. Tip: all commented usages have this comment.
  // const isWaringVisible = isWarningRequired(
  //   selectAssetBalanceInBaseCurrencyById(state, assetId).BestOffer
  // )
  return {
    assetDetails,
    assetId
    // isWarningVisible
  };
};

const mapDispatchToProps = {
  changeInstrument: () => openModal(MODAL_TYPES.SELECT_ASSET_MODAL)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstrumentSummaryComponent);
