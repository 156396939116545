import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import { disable2FA } from 'apex-web/lib/redux/actions/userActions';
import Disable2FAModalComponent from './Disable2FAModal';

const mapDispatchToProps = {
  changeFieldValue: change
};

const containerForm = reduxForm({
  form: 'disable2FAModal',
  onSubmit: (payload, dispatch) => dispatch(disable2FA(payload.code)),
  onChange: (values, dispatch, props) => {
    if (values.code && values.code.length === 6) {
      props.submit();
    }
  }
})(Disable2FAModalComponent);

const container = connect(
  null,
  mapDispatchToProps
)(containerForm);

export default modalDecorator({
  name: MODAL_TYPES.DISABLE_2FA
})(container);
