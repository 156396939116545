import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import elementResizeDetectorMaker from 'element-resize-detector';
import SwitchWithReduxForm from '../../../../common/SwitchWithReduxForm';
import APButton from '../../../../common/APButton/APButton';
import Enable2FAModalContainer from '../../../../EnableTwoFactorAuth/Enable2FAModalContainer';
import Disable2FAModalContainer from '../../../../EnableTwoFactorAuth/Disable2FAModalContainer';
import GetGoogle2FARecoveryKeyModalContainer from '../../../../EnableTwoFactorAuth/GetGoogle2FARecoveryKeyModalContainer';
import { getBEMClasses } from '../../../../../helpers/cssClassesHelper';

import './EnableTwoFactorAuthComponent.css';

const bemClasses = getBEMClasses('settings-enable-two-factor-auth');

const EnableTwoFactorAuthComponent = (props, context) => {
  const [minWidth, setMinWidth] = useState(0);
  const handleClick = newValue => {
    if (newValue) {
      props.disable2FA();
    } else {
      props.enable2FA();
    }
  };

  useEffect(() => {
    const erd = elementResizeDetectorMaker({
      strategy: 'scroll' //<- For ultra performance.
    });
    const res = document.getElementById('2FASwitchWrapper');
    erd.listenTo(res, element => {
      const twoFA = document.getElementById('recovery2FA');
      const width = element.clientWidth;
      setMinWidth(twoFA.clientWidth < width ? width : twoFA.clientWidth || 0);
    });
    return () => erd.uninstall(res);
  }, []);

  return (
    <div className={bemClasses()}>
      <div className={bemClasses('2fa-container')}>
        <div className={bemClasses('info-row')}>
          <div
            id="2FASwitchWrapper"
            className={bemClasses('button-wrapper')}
            style={{ minWidth }}>
            <SwitchWithReduxForm name="use2FA" onClick={handleClick} />
          </div>
          <p className={bemClasses('info')}>
            {context.t(
              'Two-Factor Authentication (2FA) adds another layer of protection to your account by requiring an additional passcode during the logging in process.'
            )}
          </p>
        </div>
        <div className={bemClasses('info-row')}>
          <div className={bemClasses('button-wrapper')} style={{ minWidth }}>
            <APButton
              name="recovery2FA"
              id="recovery2FA"
              customClass={bemClasses('')}
              className={bemClasses(
                props.use2FAOriginal ? 'recovery2FA' : 'disabled-button'
              )}
              onClick={props.getGoogle2FARecoveryKey}>
              {context.t('Recovery')}
            </APButton>
          </div>
          <p className={bemClasses('info')}>
            {context.t(
              'You will need to download Google Authenticator on your smartphone as part of the process to enable 2FA.'
            )}
          </p>
        </div>
      </div>
      <Enable2FAModalContainer />
      <Disable2FAModalContainer />
      <GetGoogle2FARecoveryKeyModalContainer />
    </div>
  );
};

EnableTwoFactorAuthComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

EnableTwoFactorAuthComponent.propTypes = {
  getGoogle2FARecoveryKey: PropTypes.func.isRequired,
  disable2FA: PropTypes.func.isRequired,
  enable2FA: PropTypes.func.isRequired,
  use2FAOriginal: PropTypes.bool
};

export default EnableTwoFactorAuthComponent;
