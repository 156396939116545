import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import {
  getAvailableBalance,
  getTotalRender
} from 'apex-web/lib/helpers/withdrawHelper';
import ProductIconContainer from 'apex-web/lib/components/common/ProductIcon/ProductIconContainer';

import { Modal } from '../common/Modal/Modal';

import {
  FiatDepositDetailsComponent,
  SendReceiveDetailsComponent,
  FiatWithdrawDetailsComponent
} from './Instructions';
import APIcon from '../common/APIcon/APIcon';

import './ModalWithCurrencySelect.css';

import ReceiveContainer from '../../components/ReceiveModal/ReceiveContainer';
import { DepositWithdrawModal } from '../../components/DepositWithdrawModal/DepositWithdrawModal';

const baseClasses = getBEMClasses('modal-with-currency');

export const ModalWithCurrencySelectComponent = props => {
  const {
    getWithdrawFee,
    fee,
    TicketAmount,
    positions,
    availableCurrencies,
    openModal,
    isModalOpened,
    tabIndex,
    assetsDetails,
    USDProduct,
    cryptoCuttencies
  } = props;
  const isWithdrawTab = tabIndex === 1;
  const correctAvailableCurrencies = availableCurrencies.filter(item => {
    if (isWithdrawTab) {
      if (item.Product === 'USD') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  });
  const [selectedCurrency, changeSelectedCurrency] = useState(
    correctAvailableCurrencies[0]
  );
  const [enteredAmount, setEnteredAmount] = useState(0);
  const [activeIndex, setActiveIndex] = useState(tabIndex);
  const [total, setTotal] = useState(0);
  const [useExternalAddress, setUseExternalAddress] = useState(false);
  const [selectOpened, setSelectOpened] = useState(false);
  const [
    selectedDepositPaymentProvider,
    setSelectedDepositPaymentProvider
  ] = React.useState('');
  const [
    selectedWithdrawProvider,
    setSelectedWithdrawProvider
  ] = React.useState('');
  const isDotsWithdraw = selectedWithdrawProvider === 'Dots';

  const isNationalCurrency = useMemo(
    () => selectedCurrency.ProductType === 'NationalCurrency',
    [selectedCurrency]
  );
  const balance = useMemo(
    () => {
      const position = positions[selectedCurrency.ProductId];
      if (!position) return;
      const result = getAvailableBalance(position.Amount, position.Hold);
      return result;
    },
    [selectedCurrency, positions]
  );
  const currencyIcon = useMemo(
    () => {
      const product = assetsDetails[selectedCurrency.Product];
      if (!product) return;
      const icon = product.iconFileName;
      return icon;
    },
    [assetsDetails, selectedCurrency]
  );
  const onTabChange = useCallback(newIndex => {
    setActiveIndex(newIndex);
  }, []);
  const onFormChange = useCallback(
    ({ target }) => {
      const { name, value } = target;
      if (name.toLowerCase() === 'amount') {
        let newAmount = Number(value);
        if (newAmount >= 0) {
          setEnteredAmount(newAmount);
        }
      }
    },
    [activeIndex, selectedCurrency]
  );

  const getAndSetTotal = useCallback(
    () => {
      const position = positions[selectedCurrency.ProductId];
      if (!position) return;
      const balance = getAvailableBalance(position.Amount, position.Hold);
      const amount = parseFloat(enteredAmount);
      let total = 0;

      if (amount) {
        total = getTotalRender(balance, amount, fee);
      }
      setTotal(total);
    },
    [positions, selectedCurrency, enteredAmount]
  );

  useEffect(
    () => {
      if (!isNaN(enteredAmount)) {
        getWithdrawFee(selectedCurrency.ProductId, enteredAmount);
        getAndSetTotal();
      }
    },
    [enteredAmount, selectedCurrency]
  );
  useEffect(
    () => {
      setActiveIndex(tabIndex);
    },
    [tabIndex, isModalOpened]
  );

  useEffect(
    () => {
      if (
        selectedCurrency.Product !== 'USD' &&
        isWithdrawTab &&
        correctAvailableCurrencies.length
      ) {
        changeSelectedCurrency(correctAvailableCurrencies[0]);
      }
    },
    [isWithdrawTab, correctAvailableCurrencies.length]
  );

  const ReceiveCryptoTabContent = useMemo(
    () => (
      <ReceiveContainer
        withModalWrapper={false}
        allowTransfer={false}
        productId={selectedCurrency.ProductId}
        showDetails={true}
        setUseExternalAddress={setUseExternalAddress}
        onFormChange={onFormChange}
      />
    ),
    [selectedCurrency, useExternalAddress]
  );

  const onCurrencySelect = useCallback(
    productId => {
      const [product] = correctAvailableCurrencies.filter(
        i => i.ProductId === productId
      );
      changeSelectedCurrency(product);
    },
    [correctAvailableCurrencies, selectedCurrency]
  );

  const depositSideText = React.useMemo(
    () => {
      switch (selectedDepositPaymentProvider) {
        case 'card':
          return [
            'Step 1: Key in the amount of your desired credit card charge, in USD, in the “Amount” field.',
            'Step 2: Click the "I agree with the User Agreement" text box and then click “pay now” to proceed to the next step. Note that, by doing so, you agree to, among other things, waive any right to initiate a charge back or refund claim with your credit card company.',
            'Step 3: Follow the subsequent prompts by providing your credit card number, expiry date, and other information as requested.\nIt’s that simple!\nOnce the charge has been successfully processed, your funds should be available immediately within your account.'
          ];
        case 'request':
          return [
            'Step 1: Key in the amount of your desired e-transfer, in CAD, in the “Amount” field.',
            'Step 2: Verify that all information shown is correct; scroll to the bottom and click on “Pay now”.',
            'It’s that simple!\nYou will receive an email “Fulfill Request” from our payment provider, PremierPay. Follow the instructions provided in the email.\nYour deposit is subject to your bank’s review, and may be delayed if the transaction is flagged. If you experience delays, please contact your bank directly.'
          ];
        case 'external':
          return [
            'Step 1: Your financial institution requires certain information in order to process a wire. This information is provided here. In the “memo” field of your wire, please provide the username associated with your Liquid Marketplace account; failure to do so will cause delays in processing your wire. Please fill out this form and click ‘Submit” before sending the wire.',
            'It’s that simple! The USD equivalent of your wire will be deposited into your Liquid Marketplace account, net of foreign exchange (if applicable), wire transfer fees, and processing fees. Wire transfers take time to process. Funds may not be credited to your Liquid Marketplace account for up to two business days.'
          ];
        case 'ach':
          return [
            '1. In order to create a deposit ticket, we require information from your bank. First, enter the amount you wish to deposit.',
            '2. Then, enter your bank account number (account ID) in the associated box.',
            '3. Next, enter your bank’s routing number (routing ID). If you are unsure where to find this number, contact your banking institution for assistance.',
            '4. Finally, select your account type, either checking or savings.',
            '5. Once satisfied with your inputted information, click “Pay Now” to complete your deposit.'
          ];
        default:
          return null;
      }
    },
    [selectedDepositPaymentProvider]
  );

  const withdrawSideText = React.useMemo(
    () => {
      switch (selectedWithdrawProvider) {
        case 'wire':
          return [
            'Currently, we only process USD withdrawals, via wire transfer. We require certain information in order to process a wire. This information we need is requested here.',
            'Beneficiary Name should be your legal name - the name on your bank account. If you don’t know your bank’s SWIFT code, ask them. Please double check all of this information prior to requesting a withdrawal.',
            'Our withdrawal fee covers the costs that we pay to process payments to you.',
            'The USD equivalent of your wire will be deposited into the account you have provided, net of foreign exchange (if applicable), your own bank’s wire transfer fees, and processing fees. Wire transfers take time to process.',
            'Note - The withdrawal and transfer of any these funds might take up to 7 business days, and is subject to our terms of use and our review.'
          ];
        case 'Dots':
          return [
            `1. Multi-Transfer allows you to withdraw funds directly into a PayPal account, a Virtual Visa
      International prepaid credit card, or through an international bank transfer. To select an
      option, proceed with the following steps.`,
            `2. Enter your phone number, ensuring that the country code is correct. Then, enter your
      withdrawal amount and 2FA code.`,
            `3. Within a few minutes, you will receive an email from Liquid MarketPlace to confirm your
      withdrawal. Once you receive it, please follow the instructions in the email to confirm
      your withdrawal.`,
            `4. Please allow up to 2 business days for your withdrawal to be approved. After it is
      approved, you will receive an email with steps to receive your withdrawal!`,
            `*Some options may not be available depending on your region.`
          ];
        default:
          return null;
      }
    },
    [selectedWithdrawProvider]
  );

  const InstructionsContent = useMemo(
    () => {
      const cryptoSendReceiveInstructions = (
        <SendReceiveDetailsComponent
          isSend={false}
          useExternalAddress={useExternalAddress}
          details={{ Amount: enteredAmount }}
          balance={balance}
          total={total}
          product={selectedCurrency}
          TicketAmount={TicketAmount}
          fee={fee}
        />
      );
      const instructionsContent = isNationalCurrency
        ? {
            0: <FiatDepositDetailsComponent info={{ text: depositSideText }} />,
            1: (
              <FiatWithdrawDetailsComponent
                TicketAmount={TicketAmount}
                data={{ Amount: enteredAmount }}
                fee={isDotsWithdraw ? 0 : fee || 20}
                product={selectedCurrency}
                balance={balance}
                detailsInfo={{ text: withdrawSideText }}
                setAmount={setEnteredAmount}
                isDotsWithdraw={isDotsWithdraw}
              />
            )
          }
        : {
            0: cryptoSendReceiveInstructions,
            1: cryptoSendReceiveInstructions
          };
      return instructionsContent[activeIndex] || null;
    },
    [
      isNationalCurrency,
      activeIndex,
      isModalOpened,
      enteredAmount,
      fee,
      selectedCurrency,
      balance,
      TicketAmount,
      useExternalAddress,
      depositSideText,
      selectedWithdrawProvider
    ]
  );

  const onContentBlur = e => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setSelectOpened(false);
    }
  };

  return (
    <Modal
      customClass={baseClasses('modal')}
      isOpen={isModalOpened}
      titleComponent={
        <div className={baseClasses('modal-header')}>
          <div>
            <div className={baseClasses('modal-header-title')}>
              {activeIndex === 0 ? 'Deposit' : 'Withdraw'}
            </div>
          </div>

          <div onClick={openModal}>
            <APIcon customClass={baseClasses('header-close')} name="close" />
          </div>
        </div>
      }
      onCancel={openModal}
      close={openModal}>
      <div className={baseClasses()}>
        <div
          className={baseClasses('content')}
          data-test={
            isNationalCurrency ? 'Deposit/Withdraw' : 'Send/Receive_Modal'
          }>
          <div
            className={baseClasses('fixed-header', {
              short: !isNationalCurrency || activeIndex === 1
            })}
          />
          {activeIndex === 0 && (
            <div className={baseClasses('currency-types')}>
              <div
                onClick={() => onCurrencySelect(USDProduct.ProductId)}
                className={baseClasses('currency-tab', {
                  selected: isNationalCurrency
                })}>
                USD Deposit
              </div>
              <div
                onClick={() => onCurrencySelect(cryptoCuttencies[0].ProductId)}
                className={baseClasses('currency-tab', {
                  selected: !isNationalCurrency
                })}>
                Crypto Deposit
              </div>
            </div>
          )}
          {isNationalCurrency ? (
            <DepositWithdrawModal
              openModal={openModal}
              isModalOpened={isModalOpened}
              selectTab={onTabChange}
              activeTabIndex={activeIndex}
              withModalWrapper={false}
              onFormChange={onFormChange}
              setSelectedDepositPaymentProvider={
                setSelectedDepositPaymentProvider
              }
              setSelectedWithdrawProvider={setSelectedWithdrawProvider}
            />
          ) : (
            <React.Fragment>
              <button
                className={baseClasses('currency-select-wrapper')}
                onClick={() => setSelectOpened(!selectOpened)}
                onBlur={onContentBlur}>
                <div className={baseClasses('product-wrapper')}>
                  <div>
                    {currencyIcon && (
                      <ProductIconContainer
                        customClass={baseClasses('curency-icon')}
                        size={30}
                        iconFileName={currencyIcon}
                      />
                    )}
                  </div>

                  <div className={baseClasses('currency-selector')}>
                    <div className={baseClasses('select')}>
                      {`${selectedCurrency.Product} ${
                        selectedCurrency.ProductFullName
                      }`}
                      <div
                        className={baseClasses('select-content')}
                        style={{ display: selectOpened ? '' : 'none' }}>
                        <div className={baseClasses('select-content-wrapper')}>
                          {cryptoCuttencies.map(option => (
                            <div
                              className={baseClasses(
                                'select-option',
                                selectedCurrency.ProductId ===
                                  option.ProductId && 'active'
                              )}
                              key={option.ProductId}
                              onClick={() =>
                                onCurrencySelect(option.ProductId)
                              }>
                              {`${option.Product} ${option.ProductFullName}`}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <APIcon
                  customClass={baseClasses('select-icon')}
                  name="arrowDown"
                />
              </button>
              {ReceiveCryptoTabContent}
            </React.Fragment>
          )}
        </div>
        {InstructionsContent && (
          <div
            className={baseClasses('side', {
              bigMargin: activeIndex === 0 && isNationalCurrency
            })}>
            {InstructionsContent}
          </div>
        )}
      </div>
    </Modal>
  );
};

ModalWithCurrencySelectComponent.propTypes = {
  isModalOpened: PropTypes.bool,
  tabIndex: PropTypes.number,
  fee: PropTypes.number.isRequired,
  TicketAmount: PropTypes.number,
  openModal: PropTypes.func,
  getWithdrawFee: PropTypes.func.isRequired,
  availableCurrencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  positions: PropTypes.object.isRequired,
  assetsDetails: PropTypes.object.isRequired,
  openSendModal: PropTypes.func,
  openReceiveModal: PropTypes.func,
  USDProduct: PropTypes.any,
  cryptoCuttencies: PropTypes.any
};
