import React from 'react';
import PropTypes from 'prop-types';
import {
  buyValue,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import {
  isProductByDefaultScenario,
  isProductByScriptScenario
} from '../../../redux/selectors/productsSelector';
import './MarketProductCard.css';
import APIcon from '../APIcon/APIcon';
import ReactTooltip from 'react-tooltip';
import { BuyButton, SellButton } from '../Buttons/Buttons';
import { useIsMobile } from '../../../hooks/useIsMobile';

const baseClasses = 'market-product-card';

const MarketProductCardComponent = props => {
  const {
    item: {
      ProductFullName,
      // BestOffer,
      BestBid,
      Rolling24HrPxChangePercent = 0,
      ProductId
    },
    color,
    baseCurrency,
    cardImageURL,
    cardBackgroundImage,
    buyOnly = false,
    onCardPress = () => {},
    onBuySell,
    orderType,
    onlyLimitAvailable,
    isSellDisable,
    buySellAvailable = true,
    customClass,
    checkIsEnoughOnMarket,
    customButtons
  } = props;
  const isMobile = useIsMobile();
  const [integerPrice, decimalPrice] = BestBid ? BestBid.split('.') : '';

  const classes = getBEMClasses([baseClasses, customClass]);

  const onBuySellCallback = side => {
    onBuySell({
      productId: ProductId,
      side,
      orderType,
      onlyLimitAvailable: onlyLimitAvailable || !checkIsEnoughOnMarket(side)
    });
  };

  // TODO(May 17, 2022): uncomment when we need the warning again. Tip: all commented usages have this comment.
  // const isWarningVisible = isWarningRequired(Number(BestOffer));

  return (
    <div
      className={classes('card-wrapper')}
      data-test="market-product-card-root">
      <div
        className={classes('card')}
        style={{ borderBottom: `8px inset ${color}` }}
        onClick={onCardPress}
        data-test="market-product-card-content">
        <div
          className={classes('card-title')}
          data-test="market-product-card-title">
          {ProductFullName}
        </div>
        <div className={classes('highlight-line')} />
        <div
          className={classes('card-price')}
          data-test="market-product-card-price">
          {integerPrice}
          <div className={classes('card-price', 'decimal')}>
            .{decimalPrice}
          </div>
          <div className={classes('card-price', 'currency')}>
            {baseCurrency}
          </div>
        </div>
        <div className={classes('card-price-change')}>
          <APIcon
            name="arrowUp"
            customClass={classes(
              Rolling24HrPxChangePercent >= 0 ? 'arrowUp' : 'arrowDown'
            )}
          />
          {Rolling24HrPxChangePercent
            ? Rolling24HrPxChangePercent.toFixed(2)
            : 0}%
        </div>
        {/* <div className={classes('warning-wrapper', { hidden: !isWarningVisible })}>
          <PriceWarningTag />
        </div> */}
        <img
          src={cardImageURL}
          className={classes('card-image')}
          alt="product-card"
        />
        <div className={classes('placeholder')}>
          <div
            className={classes('placeholder-image')}
            style={{
              background:
                cardBackgroundImage && !isMobile
                  ? `url('${cardBackgroundImage}')`
                  : color
            }}
          />
        </div>
      </div>
      {customButtons}
      {buySellAvailable && (
        <React.Fragment>
          <div className={classes('buttons')}>
            <BuyButton
              onClick={() => onBuySellCallback(buyValue)}
              style={{ width: '80px' }}
            />
            {!buyOnly && (
              <SellButton
                data-tip="You cannot sell this product at this time."
                data-tip-disable={!isSellDisable}
                onClick={
                  isSellDisable ? () => {} : () => onBuySellCallback(sellValue)
                }
                style={{ width: '80px' }}
              />
            )}
          </div>
          <ReactTooltip />
        </React.Fragment>
      )}
    </div>
  );
};

MarketProductCardComponent.propTypes = {
  item: PropTypes.object,
  color: PropTypes.string,
  baseCurrency: PropTypes.string,
  cardImageURL: PropTypes.string,
  buyOnly: PropTypes.bool,
  cardBackgroundImage: PropTypes.string,
  buySellAvailable: PropTypes.bool,
  checkIsEnoughOnMarket: PropTypes.func.isRequired,
  customButtons: PropTypes.node,
  onCardPress: PropTypes.func,
  onBuySell: PropTypes.func,
  orderType: PropTypes.string,
  customClass: PropTypes.string,
  isSellDisable: PropTypes.bool,
  onlyLimitAvailable: PropTypes.bool
};

MarketProductCardComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const {
    item: { ProductId }
  } = ownProps;
  const onlyMarketAvailable = Boolean(
    isProductByDefaultScenario(state, ProductId)
  );
  const onlyLimitAvailable = Boolean(
    isProductByScriptScenario(state, ProductId)
  );
  const isSellDisable = onlyMarketAvailable || onlyLimitAvailable;
  const checkIsEnoughOnMarket = () => {
    // TODO(Apr 05, 2022): uncomment or remove when we decide whether we need buy/sell limit orders
    // return BigNumber(
    //   approximateQuantitySelector(state, { side, productId: ProductId })
    // ).gt(0);
    return true;
  };
  return {
    buyOnly: ownProps.buyOnly,
    onlyLimitAvailable,
    isSellDisable,
    checkIsEnoughOnMarket
  };
};

const MarketProductCard = connect(mapStateToProps)(MarketProductCardComponent);

export { MarketProductCard };
