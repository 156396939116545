import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MODAL_TYPES, openModal } from '../../redux/actions/modalActions';
import {
  moveCardFromCountdownToLiveVote,
  setVoteResult
} from '../../redux/actions/voteActions';
import { VotePageComponent } from './VotePage';
import {
  selectCountdownVotingCards,
  selectLiveVotingCards,
  selectResultVotingCards
} from '../../redux/selectors/voteSelector';

const mapStateToProps = state => {
  const isLoaded = !state.vote.fetching;
  const countdownCards = selectCountdownVotingCards(state);
  const liveVoteCards = selectLiveVotingCards(state);
  const resultVoteCards = selectResultVotingCards(state);

  return {
    countdownCards,
    liveVoteCards,
    resultVoteCards,
    isLoaded
  };
};

const mapDispatchToProps = dispatch => ({
  openConfirmVoteModal: props =>
    dispatch(openModal(MODAL_TYPES.CONFIRM_VOTE_MODAL, { ...props })),
  moveCardFromCountdownToLiveVote: id =>
    dispatch(moveCardFromCountdownToLiveVote(id)),
  setVoteResult: result => dispatch(setVoteResult(result))
});

export const VotePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VotePageComponent));
