import { connect } from 'react-redux';

import {
  selectAssetsOfType,
  selectAllAssetsTypes
} from '../../../redux/selectors/productsSelector';
import { baseCurrencySelector } from 'apex-web/lib/redux/selectors/baseCurrenciesSelectors';
import { MyCollectionsSection } from './MyCollectionsSectionComponent';

const mapStateToProps = state => {
  const baseCurrency = baseCurrencySelector(state);
  const predicate = elem =>
    elem.product.productAttributes.ProductImageURL &&
    elem.product.productAttributes.ProductColor &&
    elem.Amount > 0;
  const pokemonAssets = selectAssetsOfType('pokemon')(state).filter(predicate);
  const nbaAssets = selectAssetsOfType('nba')(state).filter(predicate);
  const nftAssets = selectAssetsOfType('nft')(state).filter(predicate);
  const nhlAssets = selectAssetsOfType('nhl')(state).filter(predicate);
  const f1Assets = selectAssetsOfType('f1')(state).filter(predicate);
  const otherAssetTypes = selectAllAssetsTypes()(state);

  return {
    pokemonAssets,
    nbaAssets,
    nftAssets,
    nhlAssets,
    f1Assets,
    otherAssetTypes,
    baseCurrency
  };
};

const mapDispatchToProps = {};

const MyCollectionsSectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyCollectionsSection);

export { MyCollectionsSectionContainer };
