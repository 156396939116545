import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectWatchListDigitalAssetsWithAttributes } from '../../redux/selectors/productsSelector';
import { baseCurrencySelector } from 'apex-web/lib/redux/selectors/baseCurrenciesSelectors';
import MyCollectiblesBaseSection from './MyCollectiblesBaseSection';

const MyWatchListSection = (props, context) => {
  return (
    <MyCollectiblesBaseSection
      title={context.t('Watchlist')}
      noAssetsPlaceholderText={context.t('There are no collectibles in your watchlist.')}
      {...props}
    />
  );
};

MyWatchListSection.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const baseCurrency = baseCurrencySelector(state);
  const assets = selectWatchListDigitalAssetsWithAttributes(state)
  .filter(asset =>
    asset.product.productAttributes.ProductImageURL &&
    asset.product.productAttributes.ProductColor
  );
  return {
    assets,
    baseCurrency,
  };
};

export default connect(
  mapStateToProps,
  null
)(MyWatchListSection);
