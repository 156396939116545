import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import APButton from '../APButton/APButton';
import APIcon from '../APIcon/APIcon';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import history from '../../../helpers/history';

import './Modal.css';

const baseClass = 'ap-modal';

export const Modal = (props, context) => {
  const {
    isOpen,
    title,
    titleComponent,
    onCancel,
    close,
    customClass,
    modifier,
    footer,
    additionalFooterButton,
    children,
    showHeader = true,
    showCloseIcon = true,
    shouldCloseOnOverlayClick = true
  } = props;
  const modalRef = useRef(null);
  const footerButtonRef = useRef(null);

  useEffect(
    () => {
      ReactModal.setAppElement(modalRef.current);
      let unlisten = history.listen((location, action) => {
        const forceClose = onCancel || close;
        if (action === 'POP' && isOpen) {
          forceClose();
        }
      });
      return () => {
        unlisten();
        unlisten = null;
      };
    },
    [onCancel, close, isOpen]
  );

  const bemClasses = getBEMClasses(baseClass, customClass);

  const renderCloseIcon = () => {
    const onClick = onCancel || close;

    return (
      <div onClick={onClick} className={bemClasses('close-icon')}>
        <APIcon name="close" classModifiers="big" />
      </div>
    );
  };

  const handleFooterButtonClick = () => {
    footer.onClick();
    !footer.overrideClose && close();

    if (footer.overrideClose && footerButtonRef.current) {
      footerButtonRef.current.blur();
    }
  };

  const hasFooter = footer || additionalFooterButton;

  if (!isOpen) {
    return null;
  } else {
    return (
      <div ref={modalRef} className={bemClasses('wrapper')}>
        <ReactModal
          className={bemClasses('', modifier)}
          ariaHideApp={false}
          overlayClassName={bemClasses('overlay')}
          onRequestClose={onCancel || close}
          isOpen={isOpen}
          shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}>
          {showHeader && (
            <React.Fragment>
              {titleComponent || (
                <div className={bemClasses('header')}>{context.t(title)}</div>
              )}
              {showCloseIcon && renderCloseIcon()}
            </React.Fragment>)}
          <div className={bemClasses('content')}>{children}</div>
          {hasFooter && (
            <div className={bemClasses('footer')}>
              {footer && (
                <APButton
                  data-test='modalPrimaryButton'
                  buttonRef={footerButtonRef}
                  customClass={bemClasses()}
                  onClick={handleFooterButtonClick}
                  classModifiers={footer.classModifiers}
                  styleName={footer.buttonStyle}
                  disabled={footer.disabled}>
                  {context.t(footer.buttonText)}
                </APButton>
              )}
              {additionalFooterButton && (
                <APButton
                  data-test='modalAdditionalButton'
                  onClick={additionalFooterButton.onClick}
                  customClass={bemClasses('additional-button')}>
                  {additionalFooterButton.buttonText}
                </APButton>
              )}
            </div>
          )}
        </ReactModal>
      </div>
    );
  }
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func
  ]),
  onCancel: PropTypes.func,
  close: PropTypes.func.isRequired,
  titleComponent: PropTypes.object,
  customClass: PropTypes.string,
  modifier: PropTypes.string,
  footer: PropTypes.object,
  additionalFooterButton: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string
  ]),
  showHeader: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool
};

Modal.contextTypes = {
  t: PropTypes.func.isRequired
};
