import React from 'react';
import {
  checkForVerifyEmailParam,
  checkForResetPassParam,
  checkForConfirmWithdrawParam,
  checkForDepositConfirmationParam,
  checkForLoginParam,
  checkForVerifyDeviceParam
} from 'apex-web/lib/helpers/emailLinksHelper';
import { checkForVerifyAddressParam } from 'apex-web/lib/helpers/homePageLayoutHelper';
import { defaultPath } from 'apex-web/lib/routeTemplates';
import { STAND_ALONE_COMPONENTS } from '../constants/emailLinksLayoutsConstants';
import { Redirect } from 'react-router-dom';
import redirectIfAuthorized from '../hocs/redirectIfAuthorized';

class EmailLinksLayout extends React.Component {
  state = {
    StandAloneComponent: undefined
  };

  static getDerivedStateFromProps() {
    if (checkForVerifyEmailParam())
      return {
        StandAloneComponent: STAND_ALONE_COMPONENTS.VerifyEmailContainer
      };
    else if (checkForConfirmWithdrawParam())
      return {
        StandAloneComponent: STAND_ALONE_COMPONENTS.ConfirmWithdrawContainer
      };
    else if (checkForResetPassParam())
      return {
        StandAloneComponent:
          STAND_ALONE_COMPONENTS.ResetPasswordNoAuthFormContainer
      };
    else if (checkForDepositConfirmationParam())
      return {
        StandAloneComponent: STAND_ALONE_COMPONENTS.DepositConfirmationContainer
      };
    else if (checkForLoginParam())
      return {
        StandAloneComponent: STAND_ALONE_COMPONENTS.StandaloneLoginContainer
      };
    else if (checkForVerifyAddressParam())
      return {
        StandAloneComponent: STAND_ALONE_COMPONENTS.VerifyAddressContainer
      };
    else if (checkForVerifyDeviceParam())
      return {
        StandAloneComponent: STAND_ALONE_COMPONENTS.VerifyDeviceContainer
      };
    else {
      return { StandAloneComponent: undefined };
    }
  }

  render() {
    const { StandAloneComponent } = this.state;
    return StandAloneComponent ? <StandAloneComponent /> : <Redirect to={'/not-found'} />;
  }
}

export default redirectIfAuthorized(EmailLinksLayout, defaultPath.path);
