import React, { useCallback, useRef } from 'react';
import BigNumber from 'bignumber.js';
import PropTypes from 'prop-types';
import invoke from 'lodash/invoke';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import {
  formatNumberToLocale,
  formatPercentageValue
} from '../../helpers/numberFormatter';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import InstrumentInfoItem from './InstrumentInfoItem';
import { useIsScrollable } from '../../hooks/useIsScrollable';
import { SCROLL_STATE, useScrollState } from '../../hooks/useScrollState';
import { numberOrBigNumberType } from '../BuySellModal/buySellFormPropType';
import { instrumentPropType } from 'apex-web/lib/propTypes/instrumentPropTypes';
import {
  NextCustomSliderArrow,
  PrevCustomSliderArrow
} from '../common/SliderArrows';
import './InstrumentInfoSlider.css';

const baseClasses = getBEMClasses('instrument-info-slider');

const InstrumentInfoSliderComponent = (props, context) => {
  const {
    level1,
    selectedInstrument,
    availableTokens,
    userFunds,
    userTokens,
    lastPrice
  } = props;

  let instrumentTableItems = [];
  if (level1) {
    instrumentTableItems.push({
      key: 'userFunds',
      // eslint-disable-next-line react/display-name
      value: (
        <React.Fragment>
          {formatNumberToLocale(
            BigNumber(userFunds).dp(
              convertIncrementToIntDecimalPlaces(
                selectedInstrument.PriceIncrement
              )
            )
          )}
          <span style={{ fontSize: '12px' }}>
            {` ${selectedInstrument.Product2Symbol}`}
          </span>
        </React.Fragment>
      ),
      label: 'Your Available Funds',
      classModifiers: ['user-funds']
    });
    instrumentTableItems.push({
      key: 'availableTokens',
      value: formatNumberToLocale(
        BigNumber(availableTokens).dp(
          convertIncrementToIntDecimalPlaces(
            selectedInstrument.QuantityIncrement
          ),
          BigNumber.ROUND_FLOOR
        )
      ),
      label: 'No. of Tokens for Sale',
      classModifiers: ['available-tokens']
    });
    instrumentTableItems.push({
      key: 'userTokens',
      value: formatNumberToLocale(
        BigNumber(userTokens).dp(
          convertIncrementToIntDecimalPlaces(
            selectedInstrument.QuantityIncrement
          ),
          BigNumber.ROUND_FLOOR
        )
      ),
      label: 'My Tokens',
      classModifiers: ['user-funds']
    });
    instrumentTableItems.push({
      key: 'lastPrice',
      value: formatPrice(lastPrice, selectedInstrument),
      label: invoke(context, 't', 'Last Price'),
      classModifiers: ['last-price']
    });
    instrumentTableItems = instrumentTableItems.concat(
      level1ItemsData.map(createItem =>
        createItem(context, level1, selectedInstrument)
      )
    );
  }

  const listRef = useRef();
  const [setIsScrollableRef, isScrollable] = useIsScrollable();
  const [setScrollStateRef, scrollState] = useScrollState();
  const combinedListRef = useCallback(
    node => {
      listRef.current = node;
      setIsScrollableRef(node);
      setScrollStateRef(node);
    },
    [setIsScrollableRef, setScrollStateRef]
  );

  const scrollList = (toNext = false) => {
    const multiplier = toNext ? 1 : -1;
    listRef.current &&
      listRef.current.scrollBy({ left: multiplier * 120, behavior: 'smooth' });
  };

  return (
    <div className={baseClasses('', isScrollable && 'with-arrows')}>
      {isScrollable && (
        <PrevCustomSliderArrow
          className={baseClasses('arrow', 'prev')}
          onClick={() => scrollList(false)}
          disabled={scrollState === SCROLL_STATE.START}
        />
      )}
      <div className={baseClasses('list')} ref={combinedListRef}>
        {instrumentTableItems.map(({ key, value, label, classModifiers }) => (
          <InstrumentInfoItem
            key={key}
            value={value}
            label={label}
            classModifiers={classModifiers}
          />
        ))}
      </div>
      {isScrollable && (
        <NextCustomSliderArrow
          className={baseClasses('arrow', 'next')}
          onClick={() => scrollList(true)}
          disabled={scrollState === SCROLL_STATE.END}
        />
      )}
    </div>
  );
};

const level1ItemsData = [
  (context, { Rolling24HrPxChangePercent }) => ({
    key: 'dailyPercentageChange',
    value: formatPercentageValue(Rolling24HrPxChangePercent),
    label: invoke(context, 't', '24 Hour Pct Chg'),
    classModifiers: [
      Rolling24HrPxChangePercent >= 0 ? '24h-positive' : '24h-negative'
    ]
  }),
  (context, { BestBid }, instrument) => ({
    key: 'bid',
    value: formatPrice(BestBid, instrument),
    label: invoke(context, 't', 'Bid'),
    classModifiers: ['bid']
  }),
  (context, { BestOffer }, instrument) => ({
    key: 'ask',
    value: formatPrice(BestOffer, instrument),
    label: invoke(context, 't', 'Ask'),
    classModifiers: ['ask']
  }),
  (context, { Rolling24HrVolume }, instrument) => ({
    key: 'dailyVolume',
    value: (
      <React.Fragment>
        {formatNumberToLocale(
          BigNumber(Rolling24HrVolume).dp(
            convertIncrementToIntDecimalPlaces(instrument.QuantityIncrement)
          )
        )}
        <span style={{ fontSize: '12px' }}>
          {` ${instrument.Product1Symbol}`}
        </span>
      </React.Fragment>
    ),
    label: invoke(context, 't', '24 Hour Volume'),
    classModifiers: ['24h-volume']
  }),
  (context, { SessionLow }, instrument) => ({
    key: 'dailyMin',
    value: formatPrice(SessionLow, instrument),
    label: invoke(context, 't', '24 Hour Low'),
    classModifiers: ['24h-low']
  }),
  (context, { SessionHigh }, instrument) => ({
    key: 'dailyMax',
    value: formatPrice(SessionHigh, instrument),
    label: invoke(context, 't', '24 Hour High'),
    classModifiers: ['24h-high']
  })
];

const formatPrice = (price, instrument) =>
  formatNumberToLocale(
    BigNumber(price).dp(
      Math.max(
        4,
        convertIncrementToIntDecimalPlaces(instrument.PriceIncrement)
      ),
      BigNumber.ROUND_HALF_UP
    )
  );

InstrumentInfoSliderComponent.propTypes = {
  level1: PropTypes.any,
  selectedInstrument: instrumentPropType,
  availableTokens: numberOrBigNumberType,
  userTokens: numberOrBigNumberType,
  userFunds: numberOrBigNumberType,
  lastPrice: numberOrBigNumberType
};

InstrumentInfoSliderComponent.contextTypes = {
  t: PropTypes.func
};

export default InstrumentInfoSliderComponent;
