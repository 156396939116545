import { createSelector } from 'reselect';
import { level2Selector } from 'apex-web/lib/redux/selectors/level2Selectors';
import { orderTotalSelector } from 'apex-web/lib/redux/selectors/orderTotalSelector';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { getMarketPrice, getOrdersBySide } from 'apex-web/lib/helpers/orderHelper';
import { buyValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import last from 'lodash/last';
import get from 'lodash/get';
import BigNumber from 'bignumber.js';
import { selectInstrumentsWithLevel1Data } from './level1Selector';
import { isProductSoldOutSelector } from './productsSelector';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { positionSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import { baseCurrencySelector } from 'apex-web/lib/redux/selectors/baseCurrenciesSelectors';

export const instrumentSelector = createSelector(
  [
    state => state.apexCore.instrument.instruments,
    (state, instrumentId) => instrumentId
  ],
  (instruments, instrumentId) => {
    return instruments.find(i => i.InstrumentId === instrumentId);
  }
);

export const level2BestBidAndOfferSelector = createSelector(
  [level2Selector],
  (l2) => getBestBidAndOffer(l2)
);

export const isInstrumentSelected = createSelector(
  [
    state => state.apexCore.instrument,
    (state, instrumentId) => instrumentId
  ],
  (instrumentState, instrumentId) =>
    instrumentState.selectedInstrumentId &&
    instrumentState.selectedInstrumentId === instrumentId
);

export const level2BestBidAndOfferForInstrumentSelector = createSelector(
  [
    level2BestBidAndOfferSelector,
    (state, instrumentId) => isInstrumentSelected(state, instrumentId)
  ],
  (bestBidAndOffer, isInstrumentSelected) =>
    isInstrumentSelected ?
      bestBidAndOffer :
      null // data is unavailable
);

export const marketPriceOfInstrumentSelector = createSelector(
  [
    state => state.instrument.instruments,
    state => state.apexCore.level1,
    (state, options) => options,
    (state, instrumentId) => instrumentId
  ],
  (instruments, level1, options) => {
    const instrument = instruments.find(
      i => i.InstrumentId === options.instrumentId
    );
    const { side, formatToLocale } = options;
    const formatToLocaleOption = formatToLocale === undefined || formatToLocale;
    const l1 = instrument ? level1[instrument.InstrumentId] : null;
    const priceIncrement = instrument ? instrument.PriceIncrement : 0;

    const marketPrice = getMarketPrice(
      side,
      instrument,
      l1,
      convertIncrementToIntDecimalPlaces(priceIncrement)
    );

    if (instrument && formatToLocaleOption) {
      return formatNumberToLocale(
        marketPrice,
        convertIncrementToIntDecimalPlaces(priceIncrement)
      );
    }

    return marketPrice;
  }
);

export const orderTotalByInstrumentSelector = createSelector(
  [
    (state, options) => orderTotalSelector(state, options),
    (state, options) => isInstrumentSelected(state, options.instrumentId),
    (state, options) => options.formatToLocale ?
      priceIncrementByInstrumentIdSelector(state, options.instrumentId) : 0,
    (state, options) => options
  ],
  (selectedOrderTotal, isInstrumentSelected, priceIncrement, options) => {
    if (isInstrumentSelected) {
      const { formatToLocale } = options;
      return formatToLocale ? formatNumberToLocale(
        selectedOrderTotal,
        convertIncrementToIntDecimalPlaces(priceIncrement)
      ) : selectedOrderTotal;
    } else {
      // data is unavailable
      return null;
    }
  }
);

export const realtimeMarketPriceOfInstrumentSelector = createSelector(
  [
    (state, options) =>
      level2BestBidAndOfferForInstrumentSelector(state, options.instrumentId),
    (state, options) => options.formatToLocale ?
      priceIncrementByInstrumentIdSelector(state, options.instrumentId) : 0,
    (state, options) => options
  ],
  (bestBidAndOffer, priceIncrement, options) => {
    const { side, formatToLocale } = options;
    const marketPrice = getMarketPriceFromBestOrders(
      bestBidAndOffer,
      side
    );
    if (marketPrice) {
      return formatToLocale ? formatNumberToLocale(
        marketPrice,
        convertIncrementToIntDecimalPlaces(priceIncrement)
      ) : marketPrice;
    }

    // data is unavailable
    return null;
  }
);

export const primaryMarketDataSelector = createSelector(
  [
    level2Selector,
    kycVerificationLevelsSelector,
    (state, options) => isPrimaryMarketInstrumentSelector(state, options),
    (state, { instrumentId }) => isInstrumentSelected(state, instrumentId)
  ],
  (l2, { verificationLevel }, isPrimaryMarketInstrument, isInstrumentSelected) => {
    if (isPrimaryMarketInstrument && isInstrumentSelected && verificationLevel !== 99) {
      return { stablePrice: get(l2, 'sell[0].Price') };
    }
    return null;
  }
);

export const isPrimaryMarketInstrumentSelector = (state, { instrumentId }) => {
  const instrument = instrumentSelector(state, instrumentId);
  const productId = get(instrument, 'Product1');
  return !isProductSoldOutSelector(state, productId);
};

export const totalQuantitySelector = createSelector(
  [
    level2Selector,
    (state, options) => options.side
  ],
  (l2, side) => {
    const orders = getOrdersBySide(side, l2) || [];
    return orders.reduce((acc, order) => acc.plus(order.Quantity), BigNumber(0));
  }
);

export const approximateQuantitySelector = createSelector(
  [
    selectInstrumentsWithLevel1Data,
    (state, options) => options
  ],
  (data, { side, productId }) => {
    const productInstrument = data.find(d => d.Product1 === productId);
    if (productInstrument) {
      return side === buyValue ?
        productInstrument.level1.AskQty :
        productInstrument.level1.BidQty;
    }
    return 0;
  }
);

export const priceIncrementByInstrumentIdSelector = createSelector(
  [
    state => state.apexCore.instrument,
    (state, instrumentId) => instrumentId
  ],
  (instrumentState, instrumentId) => {
    const instrument = instrumentState.instruments.find(
      i => i.InstrumentId === instrumentId
    );
    return instrument ? instrument.PriceIncrement : 0;
  }
);

export const lastPriceSelector = createSelector(
  [
    state => selectedInstrumentSelector(state).InstrumentId,
    state => state.apexCore.level1
  ],
  (selectedInstrumentId, level1) => {
    return get(level1, [selectedInstrumentId, 'LastTradedPx'], 0);
  }
);

export const realtimeLastPriceSelector = createSelector(
  [
    state => selectedInstrumentSelector(state).InstrumentId,
    state => state.recentTrades
  ],
  (selectedInstrumentId, recentTrades) => {
    return get(recentTrades, [selectedInstrumentId, 0, 'Price'], 0);
  }
);

export const ownedInstrumentsSelector = createSelector(
  [
    state => state.apexCore.instrument.instruments,
    positionSelector,
    baseCurrencySelector
  ],
  (instruments, positions, baseCurrency) => {
    return Object.values(positions)
      .filter(pos => pos.ProductSymbol !== baseCurrency)
      .flatMap(pos => {
        const instrument = instruments.find(i => i.Product1 === pos.ProductId);
        // TODO(Jun 15, 2022): it's better to use instrument's minimum quantity, 
        // because if a user has less than that value, then he can't do anything
        // with it. it's the same as if the user had no tokens at all 
        if (instrument && BigNumber(pos.Amount).gt(0)/* gte(instrument.MinimumQuantity) */) {
          return [{
            ...instrument,
            position: pos
          }];
        }
        return [];
      });
  }
);

const getMarketPriceFromBestOrders = (bestBidAndOffer, side) => {
  // if our side is buying, then we want to find the best offer, and vice versa
  const path = side === buyValue ? 'bestOfferPrice' : 'bestBidPrice';
  const bestPrice = get(bestBidAndOffer, path);
  // `null` if data is unavailable
  return bestPrice || null;
};


const getBestBidAndOffer = (level2) => {
  return {
    // according to level2reducer.js, prices are sorted to be descending, so we 
    // must access different ends of the array. `null` if data is unavailable
    bestBidPrice: get(level2.buy[0], 'Price') || null,
    bestOfferPrice: get(last(level2.sell), 'Price') || null
  };
};
