import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useIsMobile } from '../../hooks/useIsMobile';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import SoldOutBanner from '../../images/sold-out.png';
import './CardOfferingBlock.css';
import { numberToLocale } from '../../helpers/numberFormatter';
import { connect } from 'react-redux';
import {
  BuyButton,
  SellButton,
  ViewDetailsButton,
  AddToFavoriteButton
} from '../common/Buttons';
import { UrlFactory } from '../../helpers/urlFactory';
import {
  selectDigitalAssetsWithAttributesNotUndefined,
  isProductByDefaultScenario,
  isProductByScriptScenario,
  checkIsCorrectAssetAttribute
} from '../../redux/selectors/productsSelector';
import {
  buyValue,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { AvailableTokensComponent } from '../common/AvailableTokens/avalaibleTokens';
import { InformationButton } from '../common/InformationButton/InformationButton';
import { openBuySellModal } from '../../redux/actions/buySellModalActions';

const classes = getBEMClasses('card-offering-block');

const CardOfferingComponent = (props, context) => {
  const isMobile = useIsMobile();

  const {
    cardOffering,
    title,
    withoutSoldOut = false,
    history,
    makeOrder,
    onlyLimitAvailable,
    isSellDisable,
    showSellButton,
    checkIsEnoughOnMarket,
    isShowAdditionalPrice
  } = props;
  const {
    CardOfferingPreviewImage = '',
    CurrentOfferingDescription,
    ProductRarity,
    ProductColor,
    CurrentOfferingsBackgroundProductColor,
    ProductTextColor,
    ProductTotalSize,
    soldOut,
    ProductSizeBackgroundColor,
    CardOfferingInfoBackground = 'transparent',
    ProductId,
    CurrentOfferingTextColor,
    availableTokensPercent,
    available_tokens,
    PrimaryMarketOrderId
  } = cardOffering;
  const isSoldOut =
    checkIsCorrectAssetAttribute(soldOut, 'true') && withoutSoldOut === false;

  const renderTotalSize = () => {
    return (
      <div
        className={classes('total-size')}
        style={{ backgroundColor: ProductSizeBackgroundColor }}>
        <div className={classes('total-size-title')}>
          {context.t('Total Token Supply')}
        </div>
        <div className={classes('total-size-amount')}>
          {numberToLocale(ProductTotalSize)}
        </div>
      </div>
    );
  };

  const renderPopulation = () => {
    return (
      <div
        className={classes('info')}
        style={{ backgroundColor: CardOfferingInfoBackground }}>
        <div className={classes('block-title')}>
          {context.t('Population')}
          <div className={classes('population-info')}>
            <InformationButton
              text={
                'Total population of an asset.\nHow many are currently in circulation around the world'
              }
              iconColor={CurrentOfferingTextColor}
            />
          </div>
        </div>
        <div className={classes('block-description')}>{'' + ProductRarity}</div>
      </div>
    );
  };

  const renderAvailability = () => {
    return (
      <div
        className={classes('info')}
        style={{ backgroundColor: CardOfferingInfoBackground }}>
        <div className={classes('block-title')}>
          {context.t('Tokens Available')}
        </div>
        <div className={classes('block-description')}>
          <AvailableTokensComponent
            availableTokens={available_tokens}
            primaryMarketOrderId={PrimaryMarketOrderId}
            availableTokensPercent={availableTokensPercent}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={classes('container')}
      style={{
        '--productColor': ProductColor,
        '--productTextColor': ProductTextColor,
        background: isMobile
          ? CurrentOfferingsBackgroundProductColor || ProductColor
          : `url(${CardOfferingPreviewImage})
          right 0% center
         / contain no-repeat, ${CurrentOfferingsBackgroundProductColor ||
           ProductColor}`,
        '--currentOfferingTextColor': CurrentOfferingTextColor
      }}>
      {isMobile && (
        <div className={classes('mobile-preview-image-wrapper')}>
          <img
            className={classes('mobile-preview-image')}
            src={CardOfferingPreviewImage}
            alt="preview"
          />
        </div>
      )}
      {isSoldOut && (
        <img
          src={SoldOutBanner}
          className={classes('sold-out-banner')}
          alt="sold-out-banner"
        />
      )}
      <div className={classes('content-wrapper')}>
        <div className={classes('content')}>
          <div className={classes('text-block')}>
            <div className={classes('title')}>
              {title ? title : context.t('Current Offering')}
            </div>

            {!!CurrentOfferingDescription && (
              <div className={classes('description')}>
                {CurrentOfferingDescription}
              </div>
            )}
            <div className={classes('grab-token')}>
              {context.t('Grab a Token Now')}!
            </div>
          </div>
          {isMobile ? (
            <div className={classes('mobile-content-wrapper')}>
              {renderTotalSize()}
              {renderPopulation()}
              <div className={classes('availability-wrapper')}>
                {renderAvailability()}
                <AddToFavoriteButton productId={ProductId} />
              </div>
            </div>
          ) : (
            <React.Fragment>
              {renderTotalSize()}
              <div className={classes('rarity-block')}>
                {renderPopulation()}
                {renderAvailability()}
              </div>
            </React.Fragment>
          )}
          {!isMobile && (
            <div className={classes('add-to-favorite-button')}>
              <AddToFavoriteButton
                productId={ProductId}
                isUseHoverState={ProductId === 35}
              />
            </div>
          )}
          <div className={classes('footer-buttons')}>
            <ViewDetailsButton
              onClick={() =>
                history.push(UrlFactory.getOfferingsAssetDetailsUrl(ProductId))
              }
            />

            <div className={classes('buy-sell-wrapper')}>
              <BuyButton
                onClick={() =>
                  makeOrder({
                    productId: ProductId,
                    side: buyValue,
                    onlyLimitAvailable:
                      !checkIsEnoughOnMarket(buyValue, ProductId) ||
                      (checkIsCorrectAssetAttribute(soldOut, 'false') &&
                        onlyLimitAvailable)
                  })
                }
              />
              {isShowAdditionalPrice && <div  className={classes('additional-price')}>15,000 TOKEN LIMIT PER USER</div>}
              {showSellButton && (
                <SellButton
                  onClick={
                    isSellDisable
                      ? () => {}
                      : () =>
                          makeOrder({
                            productId: ProductId,
                            side: sellValue,
                            onlyLimitAvailable:
                              onlyLimitAvailable ||
                              !checkIsEnoughOnMarket(sellValue, ProductId)
                          })
                  }
                  isDisabled={isSellDisable}
                  data-tip={'You cannot sell this product at this time.'}
                  data-tip-disable={!isSellDisable}
                />
              )}
            </div>
          </div>
        </div>
        <div className={classes('content', ['second-block'])} />
      </div>
    </div>
  );
};

CardOfferingComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

CardOfferingComponent.propTypes = {
  cardOffering: PropTypes.shape({
    CurrentOfferingDescription: PropTypes.string,
    CardOfferingPreviewImage: PropTypes.string,
    ProductRarity: PropTypes.string.isRequired,
    ProductColor: PropTypes.string.isRequired,
    ProductTextColor: PropTypes.string.isRequired,
    ProductAvailability: PropTypes.string,
    ProductTotalSize: PropTypes.string,
    withoutSoldOut: PropTypes.bool,
    available_tokens: PropTypes.number,
    soldOut: PropTypes.string,
    availableTokensPercent: PropTypes.string,
    ProductId: PropTypes.number,
    ProductSizeBackgroundColor: PropTypes.string,
    CurrentOfferingTextColor: PropTypes.string,
    PrimaryMarketOrderId: PropTypes.string,
    CurrentOfferingsBackgroundProductColor: PropTypes.string,
    CardOfferingInfoBackground: PropTypes.string
  }),
  title: PropTypes.node,
  checkIsEnoughOnMarket: PropTypes.func.isRequired,
  withoutSoldOut: PropTypes.bool,
  history: PropTypes.object,
  makeOrder: PropTypes.func,
  onlyLimitAvailable: PropTypes.bool,
  isSellDisable: PropTypes.bool,
  showSellButton: PropTypes.bool,
  isShowAdditionalPrice: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  let isSellDisable = false;
  let onlyLimitAvailable = false;

  const onlyMarketAvailable = !!isProductByDefaultScenario(
    state,
    ownProps.cardOffering.ProductId
  );
  onlyLimitAvailable = isProductByScriptScenario(
    state,
    ownProps.cardOffering.ProductId
  );

  const myCollectibles = selectDigitalAssetsWithAttributesNotUndefined([
    'Rolling24HrPxChangePercent',
    'Rolling24NumTrades'
  ])(state);
  const findInMyCollectibles = myCollectibles.find(
    asset => asset.ProductId === ownProps.cardOffering.ProductId
  );
  const userHaveProduct = findInMyCollectibles
    ? findInMyCollectibles.Amount !== 0
    : false;

  isSellDisable = onlyMarketAvailable || onlyLimitAvailable || !userHaveProduct;

  const checkIsEnoughOnMarket = () => {
    // TODO(Apr 05, 2022): uncomment or remove when we decide whether we need buy/sell limit orders
    // return BigNumber(
    //   approximateQuantitySelector(state, { side, productId })
    // ).gt(0);
    return true;
  };

  return {
    isSellDisable,
    onlyLimitAvailable,
    checkIsEnoughOnMarket
  };
};

const mapDispatchToProps = dispatch => ({
  makeOrder: props => {
    dispatch(openBuySellModal(props));
  }
});

export const CardOfferingBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CardOfferingComponent));
