import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../../components/common/Modal/Modal';
import { modalDecorator } from '../../../components/common/Modal/modalDecorator';
import { MODAL_TYPES } from '../../../redux/actions/modalActions';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { APSearchInput } from '../../../components/common/APSearchInput';
import './SearchCardsModal.css';

const classes = getBEMClasses('search-cards-modal');

const SearchCards = (props, context) => {
  const { isOpen, close, productsForSearch } = props;

  return (
    <Modal
      customClass={classes()}
      isOpen={isOpen}
      onCancel={close}
      close={close}>
      <APSearchInput
        placeholder={context.t('Search cards here...')}
        searchList={productsForSearch}
        customClass={classes('search')}
        onClose={close}
      />
    </Modal>
  );
};

SearchCards.contextTypes = {
  t: PropTypes.func.isRequired
};

SearchCards.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  productsForSearch: PropTypes.array
};

export const SearchCardsModal = modalDecorator({
  name: MODAL_TYPES.SEARCH_CARDS
})(SearchCards);
